import {
  ConfigWrapper,
  DesktopFooter,
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  Logo,
} from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import type { EnterpriseRatesLayoutProps } from '.';
import BookCallButton from './BookCallButton';

const EnterpriseRatesLayoutDesktop: FunctionComponent<EnterpriseRatesLayoutProps> =
  function ({ children }) {
    return (
      <ConfigWrapper desktopFooter={DesktopFooter} desktopHeader={undefined}>
        <DesktopLayoutDefault>
          <DesktopLayoutDefaultContent>
            <div className="-mt-22">
              <div className="flex w-full bg-neutral-000 mb-8 sticky top-0 py-6 z-50">
                <div className="flex flex-row justify-between w-full max-w-216 mx-auto">
                  <Link className="my-auto" to={URLS.home}>
                    <Logo width={95} />
                  </Link>
                  <BookCallButton />
                </div>
              </div>
              {children}
            </div>
          </DesktopLayoutDefaultContent>
        </DesktopLayoutDefault>
      </ConfigWrapper>
    );
  };

export default EnterpriseRatesLayoutDesktop;
