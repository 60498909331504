export const VAT_COMPONENT_PROPS = {
  placeholder: 'Select VAT',
  className: '',
  label: 'VAT',
  modal: true,
};

export const OPTIONS = [
  { name: 'No VAT', value: 'exempted' },
  { name: '0% VAT', value: '0%' },
  { name: '+5% VAT', value: '5%' },
  { name: '+20% VAT', value: '20%' },
];
