import type { ValidationItems } from '@kanda-libs/ks-component-ts';

interface BankDetailsFieldsHooks {
  sortCodeValidation: ValidationItems;
}

export default function useBankDetailsFields(): BankDetailsFieldsHooks {
  const validatePattern = (sortcode: string | number | boolean) => {
    if (typeof sortcode !== 'string') return false;
    if (!sortcode) return false;
    const formatted = sortcode?.replace(/undefined/g, '');
    return /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(formatted);
  };

  const validateLength = (sortcode: string | number | boolean) => {
    if (typeof sortcode !== 'string') return false;
    if (!sortcode) return false;
    return sortcode?.replace(/-/g, '')?.replace(/undefined/g, '')?.length === 6;
  };

  const sortCodeValidation: ValidationItems = {
    validate: {
      value: {
        length: (value: string | number | boolean) => validateLength(value),
        pattern: (value: string | number | boolean) => validatePattern(value),
      },
      message: {
        length: 'Sort code must be 6 digits',
        pattern: 'You must enter a valid sort code',
      },
    },
    required: {
      value: true,
      message: 'You must provide the your sort code',
    },
  };

  return {
    sortCodeValidation,
  };
}
