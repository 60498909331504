import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { formatCustomer } from './Customer-functions';

import { ADD_A_JOB_STEPS } from '../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { data, setStep } = useCreateJobContextValue();

  const customer = data?.job?.customer;

  return children({
    customer: formatCustomer(customer),
    onClick: () => {
      if (setStep) setStep(ADD_A_JOB_STEPS.CUSTOMER);
    },
  });
};

ContainerComponent.displayName = 'JobDetails-Customer-container';

export default ContainerComponent;
