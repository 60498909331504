import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { Company, UserType } from '@kanda-libs/ks-frontend-services';
import { formatDate } from 'pages/Identify/IdentifyForm/DirectorForm/helpers';
import { cleanObject } from 'utils';

const formatUsers = (
  companyUsers: UserType[] | undefined,
  formUsers: StringIndexedObject[] | undefined,
): UserType[] => {
  if (!companyUsers) return [];
  if (!formUsers) return companyUsers;
  const mapped = companyUsers.map((user: UserType) => {
    const match = formUsers.filter(
      (formUser) =>
        formUser.first_name === user.first_name &&
        formUser?.last_name === user?.last_name,
    )?.[0];
    if (!match) return user;
    return cleanObject({
      ...user,
      email: match.email || user.email,
      mobile: match.mobile,
      ...(user?.director_info
        ? {
            director_info: {
              ...user?.director_info,
              date_of_birth: formatDate(match?.director_info?.date_of_birth),
              home_address: cleanObject({
                building_name: match?.building_name,
                building_number: match?.building_number,
                line_1: match?.line_1,
                line_2: match?.line_2,
                postcode: match?.postcode,
                city: match?.city,
                county: match?.county,
                country: match?.country,
              }),
            },
          }
        : {}),
    });
  });
  return mapped;
};

const formatSoleUser = (user?: StringIndexedObject): UserType[] => {
  if (!user) return [];
  return [
    {
      first_name: user.first_name,
      last_name: user.last_name,
      role: 'company-admin',
      email: user.email,
      mobile: user.mobile,
      director_info: {
        ...user?.director_info,
        date_of_birth: formatDate(user?.director_info?.date_of_birth),
        home_address: cleanObject({
          building_name: user?.director_info?.home_address?.building_name,
          building_number: user?.director_info?.home_address?.building_number,
          line_1: user?.director_info?.home_address?.line_1,
          line_2: user?.director_info?.home_address?.line_2,
          postcode: user?.director_info?.home_address?.postcode,
          city: user?.director_info?.home_address?.city,
          county: user?.director_info?.home_address?.county,
          country: user?.director_info?.home_address?.country,
        }),
      },
    },
  ];
};

const formatSoleTrader = (
  company: Company,
  formData: StringIndexedObject,
): Company => ({
  ...company,
  users: formatSoleUser(formData?.company?.users?.[0]),
});

const formatLimitedCompany = (
  company: Company,
  formData: StringIndexedObject,
): Company => ({
  ...company,
  users: formatUsers(company?.users, formData?.company?.users),
});

export const formatFormData = (
  company: Company,
  formData: StringIndexedObject,
): Company => {
  const limited = company?.company_type === 'limited_company';
  if (!limited) return formatSoleTrader(company, formData);
  return formatLimitedCompany(company, formData);
};
