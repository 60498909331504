import { useFormContext } from '@kanda-libs/ks-component-ts';

const ContainerComponent = ({ children }) => {
  const { getValues } = useFormContext();

  const passwordValidation = {
    required: {
      value: true,
      message: 'Password is required',
    },
    validate: {
      value: () => getValues('password_strength') > 2,
      message: 'Password is not strong enough',
    },
  };

  const confirmPasswordValidation = {
    required: {
      value: true,
      message: 'Password confirmation is required',
    },
    validate: {
      value: (confirmation: string) => getValues('password') === confirmation,
      message: "Passwords don't match",
    },
  };

  return children({
    passwordValidation,
    confirmPasswordValidation,
  });
};

ContainerComponent.displayName = 'ResetPassword-Landing-Content-container';

export default ContainerComponent;
