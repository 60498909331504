import { Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Buttons, FormFields, Layout, Success } from './components';
import useSignUpForm from './useSignUpForm';

const SignUpForm: React.FunctionComponent = function () {
  const { form, display, onSubmit, showFooter, disabled, isSubmitting } =
    useSignUpForm();

  return (
    <Form id="sign-up" form={form} onSubmit={onSubmit}>
      <FormTheme variant="streamline">
        <Layout
          mobileFooter={
            showFooter ? (
              <Button.Text
                id="sign-up-submit"
                submit
                className="w-full"
                label="Sign Up"
                icon="arrow-right"
                variant="gradient"
                disabled={disabled || isSubmitting}
              />
            ) : null
          }
        >
          <Helmet title="Sign Up" />
          <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
            Create your Kanda account
          </p>
          {display === 'buttons' ? <Buttons /> : null}
          {display === 'form' ? <FormFields /> : null}
          {display === 'success' ? <Success /> : null}
          <Loader isLoading={isSubmitting} />
        </Layout>
      </FormTheme>
    </Form>
  );
};

export default SignUpForm;
