import { Button } from '@kanda-libs/ks-design-library';

import { BUTTON_PROPS } from './Back-constants';
import Container from './Back-container';

const ViewComponent = () => (
  <Container>
    {({ onClick }) => <Button.Icon onClick={onClick} {...BUTTON_PROPS} />}
  </Container>
);

ViewComponent.displayName = 'Back-view';

export default ViewComponent;
