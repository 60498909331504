import { Price, Text } from '@kanda-libs/ks-design-library';
import {
  PERCENTAGE_CLASS_NAME,
  PERCENTAGE_EMPHASISED_CLASS_NAME,
  PRICE_EMPHASISED_PROPS,
  PRICE_PROPS,
} from '../constants';

interface LineItemProps {
  emphasised?: boolean;
  label: string;
  price: number;
  percentage?: boolean;
}

const LineItem = ({
  emphasised,
  label,
  price,
  percentage = false,
}: LineItemProps) => (
  <div className="flex items-center justify-between mb-2">
    <div
      className={
        emphasised
          ? 'text-14-22-em text-neutral-600'
          : 'text-12-18 text-light text-neutral-600'
      }
    >
      {label}
    </div>
    {!percentage && price === 0 && (
      <Text
        text="Free"
        className={`text-turquoise-300 ${
          emphasised
            ? PRICE_EMPHASISED_PROPS.poundsClassName
            : PRICE_PROPS.poundsClassName
        }`}
      />
    )}
    {!percentage && price !== 0 && (
      <Price
        amount={price}
        {...(emphasised ? PRICE_EMPHASISED_PROPS : PRICE_PROPS)}
      />
    )}
    {percentage && (
      <div className="flex flex-row">
        <Text
          text={(price / 100).toString()}
          className={
            emphasised
              ? PERCENTAGE_EMPHASISED_CLASS_NAME
              : PERCENTAGE_CLASS_NAME
          }
        />
        <Text
          text="%"
          className={
            emphasised
              ? PERCENTAGE_EMPHASISED_CLASS_NAME
              : PERCENTAGE_CLASS_NAME
          }
        />
      </div>
    )}
  </div>
);

LineItem.defaultProps = {
  emphasised: false,
};

export default LineItem;
