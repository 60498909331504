import { Field } from '@kanda-libs/ks-component-ts';
import { FIRST_NAME_PROPS, FIRST_NAME_VALIDATION } from './constants';

const SignUpFormFieldFirstName: React.FunctionComponent = function () {
  return (
    <Field.Validator validation={FIRST_NAME_VALIDATION}>
      <Field.Input {...FIRST_NAME_PROPS} />
    </Field.Validator>
  );
};

export default SignUpFormFieldFirstName;
