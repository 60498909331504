import type { MutableRefObject } from 'react';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';

import UserMenu from '../UserMenu';

interface ViewComponentProps {
  id: string;
  /**
   * Job id
   */
  index: number;
  /**
   * Job status
   */
  setUserToDelete(...args: unknown[]): unknown;
  /**
   * director
   */
  director?: boolean;
}

const ViewComponent = ({
  id: modalId,
  index,
  setUserToDelete,
  director,
}: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <UserMenu
          index={index}
          setUserToDelete={setUserToDelete}
          handleClose={handleClose}
          director={director}
        />
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'UserModal-view';

export default ViewComponent;
