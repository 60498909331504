import { Link } from 'react-router-dom';

import Container from './SideBarItem-container';

interface ViewComponentProps {
  /**
   * Modal id
   */
  name: string;
  /**
   * On submit callback
   */
  description: string;
  /**
   * Title
   */
  active?: object | boolean;
  /**
   * To
   */
  to: string;
}

const ViewComponent = ({
  to,
  name,
  description,
  active,
}: ViewComponentProps) => (
  <Container active={active}>
    {({ classNames }) => (
      <Link to={to} className={classNames.container}>
        <p className={classNames.name}>{name}</p>
        <p className={classNames.description}>{description}</p>
      </Link>
    )}
  </Container>
);

ViewComponent.displayName = 'SideBarItem-view';

ViewComponent.defaultProps = {
  active: null,
};

export default ViewComponent;
