import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content } from '../components';

import { URLS } from '../../../../config';

const ViewComponent = ({}) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <div className="absolute top-6 left-6">
        <Link to={URLS.login}>
          <Button.Icon id="forgot-password-desktop-back" icon="arrow-left" />
        </Link>
      </div>
      <Content />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'ForgotPassword-Confirmation-Desktop-view';

export default ViewComponent;
