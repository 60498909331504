export const getCompanyName = (company) => {
  if (!company) return null;
  if (company?.company_type === 'limited_company')
    return company?.limited_company_info?.company_name;
  if (company?.company_type === 'sole_trader')
    return company?.sole_trader_info?.trading_name;
  return null;
};

export const getUserName = (user) => {
  if (!user) return null;
  if (user?.name) return user.name;
  return null;
};
