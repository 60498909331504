import { PHONE_REGEX } from '../../../../../../constants';

export const FIELD_NAMES = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
};

const FIRST_NAME = {
  firstName: {
    name: FIELD_NAMES.firstName,
    label: 'First Name',
    placeholder: 'Your first name',
    wrapperProps: {
      className: 'mr-0 md:mr-6',
    },
    autoCapitalize: 'on',
    autoCorrect: 'off',
    autoComplete: 'given-name',
  },
};

export const FIRST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'First name is required',
  },
  minLength: {
    value: 1,
    message: 'First name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'First name cannot be blank',
  },
};

const LAST_NAME = {
  lastName: {
    name: FIELD_NAMES.lastName,
    autoCapitalize: 'on',
    autoCorrect: 'off',
    autoComplete: 'family-name',
    label: 'Last Name',
    placeholder: 'Your last name',
  },
};

export const LAST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'Last name is required',
  },
  minLength: {
    value: 1,
    message: 'Last name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'Last name cannot be blank',
  },
};

const PHONE_NUMBER = {
  phoneNumber: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'tel',
    label: 'Phone Number',
    name: FIELD_NAMES.phoneNumber,
    placeholder: 'Your phone number',
  },
};

export const PHONE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};

export const WRAP_FIELDS = {
  ...FIRST_NAME,
  ...LAST_NAME,
  ...PHONE_NUMBER,
};
