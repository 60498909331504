import useCompanyLifecycle from 'hooks/useCompanyLifecycle';
import { useMemo } from 'react';

import { useCurrentCompany } from 'hooks';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { COMPANY_INFO_STEPS, STEPS } from './Form-constants';
import { getContractFile } from './helpers';

/**
 * Decides the initialStepIndex to be shown depending on whether the user
 * has filled out their insurance document
 * @returns {{initialStepIndex: (*|number)}}
 */
export default function useCompanyInfoForm() {
  const lifecycle = useCompanyLifecycle();
  const { company } = useCurrentCompany();
  const documents = useSelector(selectors.document.getEntitiesAsArray);
  const docsHaveFetched = useSelector(selectors.document.getHasFetched);

  const initialStepIndex = useMemo(() => {
    if (!lifecycle) return null;
    if (lifecycle === 'online_presence')
      return STEPS.indexOf(COMPANY_INFO_STEPS.ONLINE_PRESENCE);
    if (lifecycle === 'onboarded')
      return STEPS.indexOf(COMPANY_INFO_STEPS.INSURANCE_INFO);
    if (lifecycle === 'insurance_uploaded')
      return STEPS.indexOf(COMPANY_INFO_STEPS.TERMS_AND_CONDITIONS);
    return 0;
  }, [lifecycle]);

  const initialData = useMemo(() => {
    if (!docsHaveFetched || !company) return {};
    const contract = getContractFile(documents, company);
    if (!contract) return {};
    return {
      contract,
    };
  }, [company, documents, docsHaveFetched]);

  return {
    initialStepIndex,
    initialData,
  };
}
