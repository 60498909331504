import { Layout } from '@kanda-libs/ks-design-library';

import { Header, PaymentOptions } from './components';

import {
  JobHeader,
  Items,
  FinanceApplicationProgress,
  MarkAsFinished,
  Notes,
} from '../components';
import SendReminder from '../components/SendReminder';

const ViewComponent = ({}) => (
  <Layout
    stickyFooter
    footer={
      <>
        <MarkAsFinished />
        <SendReminder />
      </>
    }
    header={<Header />}
    noBorder
  >
    <JobHeader />
    <FinanceApplicationProgress />
    <Items />
    <PaymentOptions />
    <Notes />
  </Layout>
);

ViewComponent.displayName = 'Job-Mobile-view';

export default ViewComponent;
