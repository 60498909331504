const ReferralsInviteFormHeading: React.FunctionComponent = function () {
  return (
    <>
      <p className="mb-5 text-20-24-em text-neutral-900">Refer Users</p>
      <p className="text-16-20 text-neutral-700 mb-4">
        Enter the contact details of the user you would like to invite to join
        Kanda, and we&apos;ll reach out to them.
      </p>
    </>
  );
};

export default ReferralsInviteFormHeading;
