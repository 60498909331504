import { Form } from '@kanda-libs/ks-component-ts';

import BasePage from '../BasePage';
import { TITLE, BULLETS } from './JobInfo-constants';
import useJobInfo from './useJobInfo';
import JobInfoForm from './Form';

const ViewComponent = ({}) => {
  const { form, onSubmit, isLoading, isSubmitting } = useJobInfo();

  return (
    <Form id="onboarding-company-info-job-info" form={form} onSubmit={onSubmit}>
      <BasePage sidebarBullets={BULLETS} sidebarTitle={TITLE}>
        <JobInfoForm isLoading={isLoading} isSubmitting={isSubmitting} />
      </BasePage>
    </Form>
  );
};

ViewComponent.displayName = 'JobInfo-view';

export default ViewComponent;
