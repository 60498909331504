import { Field } from '@kanda-libs/ks-component-ts';

import Container from './Content-container';
import { CLASS_NAMES, FIELDS, EMAIL_VALIDATION } from './Content-constants';

interface ViewComponentProps {
  /**
   * Function to sign in with Google
   */
  display?: string;
}

const ViewComponent = ({ display }: ViewComponentProps) => (
  <Container display={display}>
    {({ title, description, showField, onContactBlur }) => (
      <>
        <div className={CLASS_NAMES.headerWrapper}>
          <p className={CLASS_NAMES.title}>{title}</p>
          <p className={CLASS_NAMES.description}>{description}</p>
        </div>
        {showField && (
          <Field.Validator validation={EMAIL_VALIDATION}>
            <Field.Input
              {...FIELDS.email}
              onBlur={() => onContactBlur(FIELDS.email.name)}
            />
          </Field.Validator>
        )}
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Login-Content-view';

ViewComponent.defaultProps = {
  display: undefined,
};

export default ViewComponent;
