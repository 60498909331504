// - not_applied
// - under_review
// - applied_for_finance
// - finance_deposit_paid
// - finance_not_approved
// - financed
// - sat_note_sent
// - sat_note_viewed
// - sat_note_signed
// - paid

export const getIcon = (status) => {
  if (status === 'financed')
    return {
      icon: 'check-circle-color',
      color: undefined,
    };

  if (
    status === 'paid' ||
    status === 'payout_pending' ||
    status === 'sat_note_signed'
  )
    return {
      icon: 'payment-color',
      color: undefined,
    };

  if (status === 'finance_not_approved')
    return {
      icon: 'error',
      color: 'neutral-500',
    };

  if (status === 'finance_cancelled')
    return {
      icon: 'error',
      color: 'red-200',
    };

  return {
    icon: 'clock',
    color: 'neutral-500',
  };
};
