import type { MutableRefObject } from 'react';
import { Text } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';

import Container from './Modal-container';

interface ViewComponentProps {
  /**
   *
   */
  bucket?: string;
  /**
   *
   */
  modalId: string;
}

const ViewComponent = ({ bucket, modalId }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <Container bucket={bucket}>
          {({ heading, body }) => (
            <>
              <Text text="Notifications" className="mb-8 text-18-22-em" />
              <div className="px-4 py-5 mb-6 rounded-lg bg-neutral-100">
                <span className="text-16-20-em text-neutral-600">
                  {heading}
                </span>
              </div>
              <p>{body}</p>
            </>
          )}
        </Container>
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'New-Role-Popover-Modal-view';

export default ViewComponent;
