import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  LoadingSpinner,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import useBillingSuccess from './useBillingSuccess';

const BillingSuccess: FunctionComponent = function () {
  useBillingSuccess();
  return (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent>
        <LoadingSpinner
          stroke="green-400"
          className="m-auto animate-spin"
          duration={0}
        />
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );
};

export default BillingSuccess;
