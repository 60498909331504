export const CLASS_NAMES = {
  wrapper: 'flex flex-row w-full  mb-3',
  title: 'text-style-g text-neutral-600 ml-3',
};

export const ICON_PROPS = {
  className:
    'mt-0.5 p-1 bg-turquoise-300 md:bg-neutral-300 rounded-full flex items-center justify-center mb-auto text-neutral-000 md:text-neutral-500',
  icon: 'check',
  size: 20,
};

export const SKELETONS = {
  title: {
    width: 140,
  },
};
