import { useEffect } from 'react';
import {
  useMutate,
  FirebaseAuthService,
} from '@kanda-libs/ks-frontend-services';
import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';

const ContainerComponent = ({}) => {
  const { mutate: logout } = useMutate(
    FirebaseAuthService.logout.bind(FirebaseAuthService),
  );

  const { reset } = useAmplitude();

  useEffect(() => {
    reset();
    logout();
  }, [logout, reset]);

  return null;
};

ContainerComponent.displayName = 'Logout-container';

export default ContainerComponent;
