import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { MODAL_ID } from './constants';

export default function useMobileModal(financeOption: FinanceRate) {
  const { showModal, hideModal } = useContext(ModalsWrapperContext);
  const modalId = `${MODAL_ID}-${financeOption.name}`;

  const onClick = useCallback(() => {
    showModal(modalId);
  }, [showModal, modalId]);

  const onClose = useCallback(() => {
    hideModal(modalId);
  }, [hideModal, modalId]);

  return { onClick, onClose };
}
