import { Button } from '@kanda-libs/ks-design-library';
import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import SplashScreen from '../../../components/SplashScreen';
import { BUTTON, LOGGED_OUT_SUBTEXT, SUB_TEXT, TITLE } from './constants';

const Verified = () => {
  const { isUserLoggedIn, isValidating } = useCurrentUser();

  const showLoggedInState = isUserLoggedIn && !isValidating;

  return (
    <div>
      <SplashScreen
        icon="check-circle-color"
        title={TITLE}
        subText={showLoggedInState ? SUB_TEXT : LOGGED_OUT_SUBTEXT}
      />
      {showLoggedInState && (
        <div className="mt-4">
          <a href="/onboarding">
            <Button.Text
              id="identify-verified-next"
              label={BUTTON}
              icon="arrow-right"
            />
          </a>
        </div>
      )}
      <div id="veriff" className="hidden" />
    </div>
  );
};

export default Verified;
