import { useCallback } from 'react';
import { useForm } from '@kanda-libs/ks-component-ts';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';

export default function useSolarTradeInfo() {
  const form = useForm({
    mode: 'onBlur',
  });

  const { data, handleContinue } = useOnboardingContextValue();

  const onSubmit = useCallback(
    (submittedData) => {
      // Base info
      const baseCompanyInfo = {
        trade_type: 'other_trade',
        trade_type_name: 'solar',
        trade_body: 'none',
        trade_body_name: 'none',
      };
      // Format data
      const formattedData = {
        company: {
          solar_company_info: {
            ...(submittedData?.company?.solar_company_info || {}),
          },
          company_info: {
            ...baseCompanyInfo,
            ...(data?.company?.company_info || {}),
            ...(submittedData?.company?.company_info || {}),
          },
          ...((submittedData?.company?.sole_trader_info ||
            data?.company?.sole_trader_info) && {
            sole_trader_info: {
              ...(data?.company?.sole_trader_info || {}),
              ...(submittedData?.company?.sole_trader_info || {}),
            },
          }),
        },
      };
      handleContinue(formattedData);
    },
    [data, handleContinue],
  );

  return {
    form,
    onSubmit,
  };
}
