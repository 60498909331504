import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

export const groupAllRatesByInterest = (
  rates: FinanceRate[],
): StringIndexedObject => {
  const rateKeys = rates
    .map((rate: FinanceRate) => rate.apr)
    .filter(
      (value, index, array: (number | undefined)[]) =>
        array.indexOf(value) === index,
    );

  if (!rateKeys || rateKeys.length === 0) return [];

  return rateKeys.map((key: number | undefined) => {
    if (key === undefined) return {};
    const durations = rates
      .filter((rate: FinanceRate) => rate?.apr === key)
      .map((rate: FinanceRate) =>
        rate?.deferred_duration
          ? `${rate.deferred_duration} + ${rate?.duration}`
          : String(rate.duration),
      );
    const apr = String(key / 100);
    return {
      apr,
      durations,
    };
  });
};
