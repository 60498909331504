import type {
  FinanceRate,
  Job,
  JobItem,
} from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { canApplySecondLine, hasAppliedSecondLine } from 'utils';

import { SKELETON_ITEMS } from './FinanceOptions-constants';

import useCurrentJob from '../../../../JobDetails-useCurrentJob';

export interface ContainerComponentChildrenArgs {
  lineItems: JobItem[];
  isLoading: boolean;
  items: FinanceRate[] | undefined;
  bnpl: FinanceRate[] | undefined;
  chosenRate: FinanceRate | undefined;
  jobType: Job['job_type'];
  deposit: number | undefined;
  declined: boolean;
  canApplySecond: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { job, isLoading } = useCurrentJob();

  const lineItems = job?.job_items || [];
  const canApplySecond = canApplySecondLine(job);

  const chosenRate = useMemo(() => {
    if (!job || job.finance_status === 'not_applied') return undefined;
    const hasAppliedSecond = hasAppliedSecondLine(job);
    const option = job.customer_options?.finance_option;
    if (canApplySecond && !hasAppliedSecond) return undefined;
    return option;
  }, [job, canApplySecond]);

  const declined = useMemo(() => {
    if (!job) return false;
    return job.finance_status === 'finance_not_approved';
  }, [job]);

  const allOptions = useMemo(() => {
    if (!job) return [];
    return (
      job?.finance_options?.filter((rate: FinanceRate) => rate.enabled) || []
    );
  }, [job]);

  const paymentMethods = useMemo(() => {
    if (!job) return undefined;
    const applied = job?.finance_status !== 'not_applied';
    if (!applied) return allOptions;
    return job?.customer_options?.checkout_option === 'apply_for_finance'
      ? [job.customer_options.finance_option]
      : allOptions;
  }, [job, allOptions]);

  const interestBearing = useMemo(() => {
    if (!paymentMethods) return undefined;
    const filtered = paymentMethods.filter(
      (rate: FinanceRate) => rate?.apr_type !== 'BUYNOW_PAYLATER',
    );
    if (filtered.length === 0) return undefined;
    return filtered;
  }, [paymentMethods]);

  const buyNowPayLater = useMemo(() => {
    if (!paymentMethods) return undefined;
    const filtered = paymentMethods.filter(
      (rate: FinanceRate) => rate?.apr_type === 'BUYNOW_PAYLATER',
    );
    if (filtered.length === 0) return undefined;
    return filtered;
  }, [paymentMethods]);

  const items: FinanceRate[] | undefined = isLoading
    ? SKELETON_ITEMS
    : interestBearing;
  const bnpl: FinanceRate[] | undefined = isLoading
    ? undefined
    : buyNowPayLater;

  const jobType = job?.job_type || 'standard';

  const deposit = useMemo(() => {
    if (job?.customer_options?.deposit_value?.amount)
      return job.customer_options.deposit_value.amount;
    if (
      job?.deposit_type === 'fixed_deposit' ||
      job?.deposit_type === 'partial_deposit'
    )
      return job?.deposit_value?.amount;
    if (job?.customer_options?.deposit_value?.amount === 0) return 0;
    if (job?.customer_options?.deposit_value?.amount)
      return job.customer_options.deposit_value.amount;
    return (job?.total?.amount_vat_inclusive?.amount || 0) * 0.5;
  }, [job]);

  return children({
    lineItems,
    isLoading,
    items,
    bnpl,
    chosenRate,
    jobType,
    deposit,
    declined,
    canApplySecond,
  });
};

ContainerComponent.displayName =
  'PaymentMethods-Desktop-FinanceOptions-container';

export default ContainerComponent;
