import type { FunctionComponent } from 'react';
import { type UpSell as UpSellProps } from './constants';
import UpSell from './UpSell';
import useUpSells from './useUpSells';

interface UpSellsProps {
  className?: string;
}

const UpSells: FunctionComponent<UpSellsProps> = function ({ className }) {
  const { upsells, isLoading } = useUpSells();
  if (!upsells) return null;
  return (
    <div
      className={['w-full max-w-120 md:w-full md:max-w-full mx-auto', className]
        .filter(Boolean)
        .join(' ')}
    >
      <div className="flex flex-col flex-1">
        <p className="text-style-b text-neutral-900 mr-auto mb-6 md:mb-12">
          Upgrade Options
        </p>
        <div className="flex -mx-2 md:mx-0 border-[8px] md:border-[16px] border-lavender-100 rounded-lg py-4 px-3 md:px-12 lg:px-20 md:py-8 lg:py-14">
          {upsells.map((upsell: UpSellProps) => (
            <UpSell key={upsell.id} isLoading={isLoading} {...upsell} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpSells;
