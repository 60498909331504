import { useMemo } from 'react';

import useCurrentJob from '../../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { job, isLoading } = useCurrentJob();

  const total = useMemo(() => {
    if (!job) return 0;
    return job?.total?.amount_vat_inclusive?.amount || 0;
  }, [job]);

  return children({
    total,
    isLoading,
  });
};

ContainerComponent.displayName = 'Job-Items-Footer-container';

export default ContainerComponent;
