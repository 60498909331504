import { useFormContext } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { formatPayload, shouldShowSave } from './CloseModal-functions';
import { URLS } from '../../../../config';

const ContainerComponent = ({ children, handleClose }) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const { push } = useHistory();
  const formContext = useFormContext();
  const { data: formData } = useCreateJobContextValue();
  const { showSuccess } = useToast();
  const onError = useApiError('Error saving draft');

  const values = useMemo(() => {
    const enteredData = formContext?.getValues() || {};
    const compiledData = {
      ...formData,
      job: {
        ...formData?.job,
        ...enteredData?.job,
      },
    };
    return compiledData;
  }, [formContext, formData]);

  const showSave = shouldShowSave(values);

  /**
   * Function to show delete item modal
   */
  const onClickExit = useCallback(() => {
    handleClose();
    push(URLS.home);
  }, [handleClose, push]);

  const onSaveClick = useCallback(() => {
    const body = formatPayload(values);
    const id = body?.id;

    const onSuccess = () => {
      showSuccess('Draft saved');
      push(URLS.home);
      handleClose();
    };

    if (id) {
      dispatch(
        actions.putJob({
          body,
          params: { id },
          onError,
          onSuccess,
        }),
      );
    } else {
      dispatch(
        actions.postJob({
          body,
          onError,
          onSuccess,
        }),
      );
    }
  }, [values, dispatch, showSuccess, push, handleClose, onError]);

  return children({
    onClickExit,
    onSaveClick,
    showSave,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'CreateJob-CloseModal-Container';

export default ContainerComponent;
