import { Field, FormTheme } from '@kanda-libs/ks-component-ts';
import {
  EMAIL_PROPS,
  FIELD_NAMES,
  FIRST_NAME_PROPS,
  FIRST_NAME_VALIDATION,
  LAST_NAME_PROPS,
  LAST_NAME_VALIDATION,
  PHONE_NUMBER_PROPS,
  PHONE_NUMBER_VALIDATION,
} from './constants';
import useMeFields from './useMeFields';

const MeFields: React.FunctionComponent = function () {
  const { onNameBlur, onContactBlur } = useMeFields();
  return (
    <div className="flex flex-col">
      <p className="text-18-22-em mb-4">Your details</p>
      <FormTheme variant="streamline">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
          <Field.Validator validation={FIRST_NAME_VALIDATION}>
            <Field.Input
              {...FIRST_NAME_PROPS}
              onBlur={() => onNameBlur(FIELD_NAMES.firstName)}
            />
          </Field.Validator>
          <Field.Validator validation={LAST_NAME_VALIDATION}>
            <Field.Input
              {...LAST_NAME_PROPS}
              onBlur={() => onNameBlur(FIELD_NAMES.lastName)}
            />
          </Field.Validator>
          <Field.Validator validation={PHONE_NUMBER_VALIDATION}>
            <Field.PhoneNumberInput
              {...PHONE_NUMBER_PROPS}
              onBlur={() => onContactBlur(FIELD_NAMES.phoneNumber)}
            />
          </Field.Validator>
          <Field.Input
            {...EMAIL_PROPS}
            onBlur={() => onContactBlur(FIELD_NAMES.email)}
          />
        </div>
      </FormTheme>
      <div className="mb-8 mt-4 border-b border-neutral-300" />
    </div>
  );
};

export default MeFields;
