import {
  Field,
  Form,
  FormTheme,
  type ArrayWrapperChildrenArgs,
} from '@kanda-libs/ks-component-ts';

import { Loader } from '@kanda-libs/ks-design-library';
import ReferralsInviteFormButtons from './Buttons';
import { INITIAL_DATA } from './constants';
import ReferralsInviteFormHeading from './Heading';
import ReferralsInviteFormReferral from './Referral';
import useReferralInviteForm from './useReferralInviteForm';

const ReferralsInviteForm: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useReferralInviteForm();
  return (
    <div className="flex-col flex text-neutral-600 w-full rounded-xl md:border border-neutral-200 md:py-5 md:px-4 mt-12">
      <ReferralsInviteFormHeading />
      <FormTheme variant="streamline">
        <Form
          id="referral-invite-form"
          form={form}
          onSubmit={onSubmit}
          className="flex flex-col w-full"
        >
          <Field.Array.Wrapper arrayName="referrals" initialData={INITIAL_DATA}>
            {({
              fields,
              arrayProps: { remove, showRemove, append },
            }: ArrayWrapperChildrenArgs) => (
              <>
                <div className="flex flex-col text-neutral-600 w-full md:border border-neutral-200 rounded-xl md:pt-5 md:px-4 gap-y-4 md:gap-y-2">
                  {fields.map((field, index) => (
                    <ReferralsInviteFormReferral
                      key={field.id}
                      index={index}
                      remove={remove}
                      showRemove={Boolean(showRemove)}
                    />
                  ))}
                </div>
                <ReferralsInviteFormButtons
                  append={append}
                  plural={fields.length > 1}
                  isSubmitting={isSubmitting}
                />
              </>
            )}
          </Field.Array.Wrapper>
        </Form>
      </FormTheme>
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

export default ReferralsInviteForm;
