import { useEffect } from 'react';
import {
  FirebaseAuthService,
  useMutate,
  useCurrentUser,
} from '@kanda-libs/ks-frontend-services';
import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';

const ContainerComponent = ({ children }) => {
  const {
    isUserLoggedIn,
    isValidating,
    revalidate: revalidateLoggedIn,
  } = useCurrentUser();

  const { reset } = useAmplitude();

  const { mutate: logout } = useMutate(FirebaseAuthService.logout);

  useEffect(() => {
    if (!isUserLoggedIn || isValidating) return;
    reset();
    logout(false).then(({ error }) => {
      if (error) return;
      revalidateLoggedIn();
    });
  }, [isUserLoggedIn, isValidating, logout, revalidateLoggedIn, reset]);

  return children;
};

ContainerComponent.displayName = 'ClaimResent-container';

export default ContainerComponent;
