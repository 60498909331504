import Email from './Email';
import FirstName from './FirstName';
import LastName from './LastName';
import Phone from './Phone';
import TermsAndConditions from './TermsAndConditions';

const Fields = {
  FirstName,
  LastName,
  Email,
  Phone,
  TermsAndConditions,
};

export default Fields;
