import Context from 'pages/Jobs/Context';
import { useCallback, useContext, useMemo } from 'react';
import { capitaliseWords } from 'utils';
import type { JobTableFilterPopoverItemType } from './types';

export interface JobTableFilterPopoverItemPropsHook {
  active: boolean;
  onClick: () => void;
  label: string;
}

export default function useJobTableFilterPopoverItemProps(
  type: JobTableFilterPopoverItemType,
  status: string,
): JobTableFilterPopoverItemPropsHook {
  const { filter, onAddFilter, onRemoveFilter } = useContext(Context);

  const currentFilters = useMemo(
    () => filter?.[type]?.split(';') || [],
    [filter, type],
  );

  const active = useMemo(
    () => currentFilters.includes(status),
    [status, currentFilters],
  );

  const onClick = useCallback(() => {
    if (active) {
      onRemoveFilter(type, status);
    } else {
      onAddFilter(type, status);
    }
  }, [status, active, type, onAddFilter, onRemoveFilter]);

  const label = capitaliseWords(status.replace(/_/g, ' '));

  return {
    active,
    onClick,
    label,
  };
}
