import { Button, Header, HelpButton } from '@kanda-libs/ks-design-library';

import Container from './Header-container';
import { HEADER_TITLE } from '../../Page-constants';

import { SaveAsDraftButton } from '../../../../../components';

interface ViewComponentProps {
  /**
   * Simple version
   */
  simple?: boolean;
  /**
   * Show save Button
   */
  saveButton?: boolean;
}

const ViewComponent = ({ simple, saveButton }: ViewComponentProps) => (
  <Container>
    {({ onClick }) => (
      <Header.Base
        className="text-style-f-em text-neutral-700"
        options={[
          ...(saveButton ? [<SaveAsDraftButton key="save" />] : []),
          <HelpButton key="help" />,
          <Button.Icon
            id="create-job-mobile-header-close"
            key="close"
            icon="close"
            onClick={onClick}
          />,
        ]}
      >
        <>{!simple && HEADER_TITLE}</>
      </Header.Base>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  simple: undefined,
  saveButton: undefined,
};

ViewComponent.displayName = 'CreateJob-Header-view';

export default ViewComponent;
