import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { useForm, type StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { URLS } from '../../../../../config';
import useCurrentCompany from '../../../../../hooks/useCurrentCompany';
import useMe from '../../../../../hooks/useMe';
import { getAllDirectorsAreNotMe } from '../../../Onboarding-helpers';
import { ONBOARDING_STEPS } from '../AccountVerificationExplanation/AccountVerificationExplanation-constants';
import { formatFormData } from './helpers';

const parsePhone = (phone) => {
  if (!phone) return '';
  if (!phone.includes('+44')) return phone;
  return phone?.replace('+44', '0');
};

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);
  const { setCurrentStepIndex, setData } = useOnboardingContextValue();

  const { logEvent } = useAmplitude();

  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const { me } = useMe();

  const { company } = useCurrentCompany();

  const defaultValues = useMemo(() => {
    if (!company) return {};
    if (company?.company_type === 'sole_trader') {
      const [firstName, lastName] = me?.name?.split(' ') || [];
      const phone = parsePhone(me?.phone);
      return {
        company: {
          users: [
            {
              email: me?.email || '',
              mobile: phone || '',
              first_name: firstName || '',
              last_name: lastName || '',
              role: 'company-admin',
              key: 'user-0',
            },
          ],
        },
      };
    }
    const users =
      company?.users?.map((user, index) => ({
        ...user,
        email: '',
        key: `user-${index}`,
      })) || [];

    return {
      company: { users },
    };
  }, [company, me]);

  const form = useForm({
    mode: `onBlur`,
    defaultValues: defaultValues as StringIndexedObject,
  });

  const { reset } = form;

  const onSubmit = useCallback(
    (formData) => {
      if (!company) return;

      const { id } = company;
      const body = formatFormData(company, formData);

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            if (formData.company.users.length === 1) {
              const [firstUser] = formData.company.users || [];

              setData({
                myEmail: firstUser.email,
                isSingleDirector: firstUser.email === me?.email,
              });
            }

            const allDirectorsAreNotMe = getAllDirectorsAreNotMe(
              formData.company.users,
              me?.email,
            );
            const identifyStepIndex =
              Object.keys(ONBOARDING_STEPS).indexOf('IDENTIFY');
            const statusStepIndex = Object.keys(ONBOARDING_STEPS).indexOf(
              'VERIFICATION_STATUS',
            );

            if (setCurrentStepIndex)
              setCurrentStepIndex(
                allDirectorsAreNotMe ? statusStepIndex : identifyStepIndex,
              );
          },
        }),
      );
    },
    [dispatch, setCurrentStepIndex, onError, company, me, setData],
  );

  const isLoading = !company || !me;

  const resetRef = useRef<boolean>(false);
  useEffect(() => {
    if (resetRef.current || Object.keys(defaultValues).length === 0) return;
    reset(defaultValues);
    resetRef.current = true;
  }, [defaultValues, reset]);

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'verification');
    window.history.pushState({}, '', `${url}?step=director-info`);
    logEvent('page-view');
  }, [logEvent]);

  return children({
    form,
    onSubmit,
    isSubmitting,
    isLoading,
    company,
    me,
  });
};

ContainerComponent.displayName = 'SetupCompany-DirectorInfo-container';

export default ContainerComponent;
