import { Button, Popover } from '@kanda-libs/ks-design-library';

import { JobMenu } from '../../../../../../components';

interface ViewComponentProps {
  /**
   * Job id
   */
  jobId?: string;
  /**
   * Job status
   */
  status?: string;
  /**
   * Job status
   */
  downloadPdf?: (id: string) => void;
}

const ViewComponent = ({ jobId, status, downloadPdf }: ViewComponentProps) => (
  <Popover.Standard
    id="job-details-header-more"
    className="mr-4"
    button={<Button.Icon id="job-details-header-more" icon="more" />}
    right
  >
    {({ handleClose }) => (
      <JobMenu
        jobId={jobId}
        status={status}
        handleClose={handleClose}
        downloadPdf={downloadPdf}
      />
    )}
  </Popover.Standard>
);

ViewComponent.defaultProps = {
  jobId: undefined,
  status: undefined,
};

ViewComponent.displayName = 'Job-Menu-view';

export default ViewComponent;
