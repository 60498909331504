export const TITLE = 'Subscription';

export const CLASS_NAMES = {
  title: 'text-16-20-em mb-6 text-neutral-900',
  description: 'tex-14-22 text-neutral-600 mb-6',
};

export const FORM_CLASS_NAMES = {
  title: 'text-style-c mb-4 text-neutral-900 md:text-style-b',
  description:
    'text-style-g mb-8 text-neutral-700 md:text-style-f md:text-neutral-600 md:mb-12',
  footer: 'flex-1 flex justify-end md:block md:pt-5',
  fields: 'text-left',
};

export const CONFIRMATION_CLASS_NAMES = {
  container:
    'flex flex-1 items-center justify-center flex-col w-80 mx-auto -mt-20 md:mt-0',
  title: 'text-style-c text-neutral-900 mb-4 text-center whitespace-pre-line',
  description: 'text-style-f text-neutral-600 text-center whitespace-pre-line',
};

export const SUCCESS_CLASS_NAMES = {
  container:
    'flex flex-1 items-center justify-center flex-col mx-auto -mt-20 md:mt-0',
  title: 'text-style-c text-neutral-900 mb-4 text-center whitespace-pre-line',
  description:
    'text-style-f text-neutral-600 text-center whitespace-pre-line mb-8',
};
