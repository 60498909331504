import { Button } from '@kanda-libs/ks-design-library';

import Container from './SMSButton-container';
import Modal from './Modal';
import { SMS_BUTTON_PROPS, IDS } from './SMSButton-constants';

const ViewComponent = () => (
  <Container>
    {({ customer, number, handleShowModal }) => (
      <>
        {number && (
          <Button.Link
            id={IDS.button}
            onClick={handleShowModal}
            {...SMS_BUTTON_PROPS}
            size={14}
          />
        )}
        <Modal modalId={IDS.modal} customer={customer} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-SMSButton-view';

export default ViewComponent;
