import { Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../config';
import useIsSubscribed from '../../../../hooks/useIsSubscribed';
import { SubscribeModal } from '../../../../pages/MyAccount/components';

const ViewComponent = ({}) => {
  const { isSubscribed } = useIsSubscribed();

  if (isSubscribed) {
    return <></>;
  }

  return (
    <div className="flex flex-col p-4 bg-neutral-50">
      <span className="mb-3 text-style-g-em text-neutral-900">
        Upgrade to send unlimited jobs
      </span>
      <Link to={URLS.subscription}>
        <Button.Text
          id="subscription-upgrade-now"
          variant="gradient"
          size={40}
          icon="arrow-right"
          label="Upgrade Now"
        />
      </Link>
      <SubscribeModal />
    </div>
  );
};

ViewComponent.displayName = 'DesktopHeader-SubscriptionNotice-view';

export default ViewComponent;
