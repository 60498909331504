const FLAG_KEY = 'skip-insurance-doc';
const FLAG_EXPIRY_TIMEOUT_IN_MINUTES = 12 * 60;

export const getFlagKey = (companyId) => `${FLAG_KEY}-${companyId}`;

export const getFlagValue = (companyId) => {
  const value = localStorage.getItem(getFlagKey(companyId));

  if (!value) {
    return false;
  }

  const now = Date.now();

  const differenceInSeconds = (now - parseInt(value, 10)) / 1000;

  return differenceInSeconds / 60 <= FLAG_EXPIRY_TIMEOUT_IN_MINUTES;
};
