import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { FunctionComponent, ReactNode } from 'react';
import EnterpriseRatesLayoutDesktop from './Desktop';
import EnterpriseRatesLayoutMobile from './Mobile';

export interface EnterpriseRatesLayoutProps {
  children: ReactNode;
  footer?: ReactNode;
}

const EnterpriseRatesLayout: FunctionComponent<EnterpriseRatesLayoutProps> =
  function ({ children, footer }) {
    return (
      <BreakPoints
        mobile={
          <EnterpriseRatesLayoutMobile footer={footer}>
            {children}
          </EnterpriseRatesLayoutMobile>
        }
        desktop={
          <EnterpriseRatesLayoutDesktop>
            {children}
          </EnterpriseRatesLayoutDesktop>
        }
      />
    );
  };

export default EnterpriseRatesLayout;
