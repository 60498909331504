import {
  FinanceStatusTag,
  SkeletonLoader,
  type StatusTagStatus,
} from '@kanda-libs/ks-design-library';

import { SKELETONS } from '../../JobHeader-constants';

interface ViewComponentProps {
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Customer
   */
  financeStatus?: StatusTagStatus;
  /**
   * Customer
   */
  timestamp?: string | number;
}

const ViewComponent = ({
  isLoading,
  financeStatus,
  timestamp,
}: ViewComponentProps) => (
  <SkeletonLoader
    {...SKELETONS.status}
    isLoading={isLoading}
    afterLoading={
      financeStatus ? (
        <FinanceStatusTag
          status={financeStatus as StatusTagStatus}
          timestamp={timestamp as number}
          size={28}
          variant="solid"
          className="ml-4 md:ml-0 md:mr-6"
        />
      ) : (
        <></>
      )
    }
  />
);

ViewComponent.displayName = 'JobHeader-FinanceStatus-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  financeStatus: undefined,
  timestamp: undefined,
};

export default ViewComponent;
