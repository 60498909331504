import { useCallback } from 'react';
import { useFormContext } from '@kanda-libs/ks-component-ts';
import { removeWhitespaceAndNewline } from 'utils';

import {
  TITLE,
  EXPIRED_TITLE,
  DESCRIPTION,
  EXPIRED_DESCRIPTION,
} from './Content-constants';

const ContainerComponent = ({ children, display }) => {
  const { getValues, setValue } = useFormContext();

  const title = display === 'expired' ? EXPIRED_TITLE : TITLE;

  const description = display === 'expired' ? EXPIRED_DESCRIPTION : DESCRIPTION;

  const showField = display !== 'expired';

  const onContactBlur = useCallback(
    (name: string) =>
      setValue(name, removeWhitespaceAndNewline(getValues(name))),
    [setValue, getValues],
  );

  return children({
    title,
    description,
    showField,
    onContactBlur,
  });
};

ContainerComponent.displayName = 'Login-Content-container';

export default ContainerComponent;
