import { Widget } from '@kanda-libs/ks-component-ts';
import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './VAT-container';
import {
  VAT_COMPONENT_PROPS,
  VAT_AMOUNT_COMPONENT_PROPS,
  VAT_COMPONENT_MOBILE_PROPS,
  VAT_AMOUNT_COMPONENT_MOBILE_PROPS,
} from './VAT-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Container {...props}>
    {({ showAmountField, vatSelectSharedProps }) => (
      <BreakPoints
        desktop={
          <>
            <Widget.JobJobItemsVatRateArraySelect
              {...VAT_COMPONENT_PROPS}
              {...props}
              {...vatSelectSharedProps}
            />
            {showAmountField && (
              <Widget.JobJobItemsVatAmountArrayInput
                name="vat.amount"
                {...VAT_AMOUNT_COMPONENT_PROPS}
                {...props}
              />
            )}
          </>
        }
        mobile={
          <>
            <Widget.JobJobItemsVatRateArraySelect
              {...VAT_COMPONENT_MOBILE_PROPS}
              {...props}
              {...vatSelectSharedProps}
            />
            {showAmountField && (
              <Widget.JobJobItemsVatAmountArrayInput
                name="vat.amount"
                {...VAT_AMOUNT_COMPONENT_MOBILE_PROPS}
                {...props}
              />
            )}
          </>
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Item-VAT-view';

export default ViewComponent;
