import type { FunctionComponent } from 'react';

import { BreakPoints, Timeline } from '@kanda-libs/ks-design-library';
import { StageTimeline } from '@kanda-libs/ks-design-library-new';

export interface ReviewTimelineProps {
  activeKey: number;
  showFca: boolean;
}

const ReviewTimeline: FunctionComponent<ReviewTimelineProps> = function ({
  activeKey,
  showFca,
}) {
  const fcaLabel = `${showFca ? 'FCA and ' : ''}Lender Approval`;
  return (
    <BreakPoints
      desktop={
        <StageTimeline
          stages={[
            <div key="submitted">
              Submitted
              <br />
              &nbsp;
            </div>,
            <div key="reviewing">
              Kanda Reviewing
              <br />
              2-4 days
            </div>,
            <div key="fca">
              {fcaLabel}
              <br />
              2-3 weeks
            </div>,
          ]}
          activeKey={activeKey}
        />
      }
      mobile={
        <Timeline
          compact
          items={[
            {
              title: 'Submitted',
              fill: 'grey',
            },
            {
              title: 'Kanda Review: 2-4 days',
              fill: activeKey === 1 ? 'green' : 'grey',
            },
            {
              title: 'FCA and Lender Approval: 2-3 weeks',
              fill: activeKey === 1 ? 'grey' : 'green',
            },
          ]}
        />
      }
    />
  );
};

export default ReviewTimeline;
