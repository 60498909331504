import { Icon, Popover } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import type { MutableRefObject } from 'react';
import Content from '../Content';
import useOptionHover from '../useOptionHover';

interface DesktopProps {
  financeOption: FinanceRate;
  totalCost: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  rateWithinBounds: boolean;
  depositIsAboveMinimum?: boolean;
}

const Desktop = ({
  financeOption,
  totalCost,
  percentage,
  baseRate,
  baseFee,
  rateWithinBounds,
  depositIsAboveMinimum = true,
}: DesktopProps) => {
  const { visible, elementRef, ...wrapperProps } = useOptionHover();

  return (
    <div
      ref={elementRef as unknown as MutableRefObject<HTMLDivElement>}
      {...wrapperProps}
      className="z-50 mb-px"
    >
      <Popover.Standard
        id={`finance-option-details-${financeOption.name}`}
        button={
          <Icon
            icon="info"
            className="w-3 -mt-1 cursor-pointer min-w-3 text-neutral-500"
          />
        }
        above
        right
        visible={visible}
      >
        {() => (
          <Content
            financeOption={financeOption}
            totalCost={totalCost}
            percentage={percentage}
            baseRate={baseRate}
            baseFee={baseFee}
            rateWithinBounds={rateWithinBounds}
            depositIsAboveMinimum={depositIsAboveMinimum}
          />
        )}
      </Popover.Standard>
    </div>
  );
};

export default Desktop;
