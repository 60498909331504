import { MultiStepFormRouter } from '@kanda-libs/ks-component-ts';
import { Header, Layout, Loader } from '@kanda-libs/ks-design-library';

import Pages from '../../Pages';

interface MobileProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
}

const Mobile: React.FunctionComponent<MobileProps> = ({ isLoading }) => (
  <Layout noPadding noBorder header={<Header.Base lines />}>
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <MultiStepFormRouter pages={Pages} />
    </>
  </Layout>
);

export default Mobile;
