import Container from './Wrapper-container';
import { CLASS_NAMES } from '../../../../AccountVerificationExplanation-constants';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element | JSX.Element[];
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <Container>
    <div className="flex flex-col flex-1 p-16">
      <div className={CLASS_NAMES.content}>{children}</div>
    </div>
  </Container>
);

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.displayName = 'Wrapper-view';

export default ViewComponent;
