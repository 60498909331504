import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import { COMPANY_ADDRESS_FIELD_NAMES } from './constants';
import useCompanyName from './useCompanyName';

interface CompanyNameProps {
  noCompanyCallback?: () => void;
}

const CompanyName: React.FunctionComponent<CompanyNameProps> = function ({
  noCompanyCallback,
}) {
  const { companyType } = useCompanyName();
  if (companyType === 'sole_trader')
    return (
      <FormTheme variant="streamline">
        <Widget.CompanySoleTraderInfoTradingName
          label="Enter your company trading name"
          placeholder="Company Trading Name"
        />
      </FormTheme>
    );
  return (
    <FormTheme variant="streamline">
      <Widget.CompanyLimitedCompanyInfoCompanyName
        label="Search for your company"
        placeholder="Search Companies House"
        noCompanyCallback={noCompanyCallback}
        {...COMPANY_ADDRESS_FIELD_NAMES}
      />
    </FormTheme>
  );
};

export default CompanyName;
