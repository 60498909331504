import { useCallback, useContext, useMemo } from 'react';
import {
  ModalsWrapperContext,
  useIsDesktop,
} from '@kanda-libs/ks-design-library';

import {
  DELETE_USER_MODAL_ID,
  NOTIFICATIONS_MODAL_ID,
} from '../../Users-constants';

const ContainerComponent = ({ children, index, setUserToDelete, director }) => {
  const { showModal } = useContext(ModalsWrapperContext);
  const isDesktop = useIsDesktop();

  /**
   * Function to show delete item modal
   */
  const handleShowDeleteModal = useCallback(
    () => showModal(DELETE_USER_MODAL_ID),
    [showModal],
  );

  /**
   * Function to show delete item modal
   */
  const handleShowNotificationsModal = useCallback(
    () => showModal(NOTIFICATIONS_MODAL_ID),
    [showModal],
  );

  const items = useMemo(
    () => [
      ...(!director
        ? [
            {
              name: 'Delete user',
              icon: 'remove',
              onClick: () => {
                setUserToDelete(index);
                handleShowDeleteModal();
              },
            },
          ]
        : []),
      ...(!isDesktop
        ? [
            {
              name: 'Notification preferences',
              icon: 'email',
              onClick: () => {
                setUserToDelete(index);
                handleShowNotificationsModal();
              },
            },
          ]
        : []),
    ],
    [
      director,
      index,
      handleShowDeleteModal,
      handleShowNotificationsModal,
      isDesktop,
      setUserToDelete,
    ],
  );

  const show = items?.length > 0;

  return children({
    items,
    show,
  });
};

ContainerComponent.displayName = 'UserMenu-container';

export default ContainerComponent;
