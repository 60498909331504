import type { JobItem } from '@kanda-libs/ks-frontend-services';
import type { CreateJobContextValue } from './types';

export const ADD_A_JOB_STEPS = {
  DETAILS: 'details',
  EXTRA_DETAILS: 'extra-details',
  CUSTOMER: 'customer',
  DEPOSIT: 'deposit',
  PAYMENT_AND_FINANCE_OPTIONS: 'payment-and-finance-options',
  SUMMARY: 'summary',
  SEND_CONFIRMATION: 'send-confirmation',
};

export const ADD_A_JOB_STEPS_DESKTOP = {
  FORM: 'details',
  SUMMARY: 'summary',
  SEND_CONFIRMATION: 'send-confirmation',
};

export const JOB_TYPE = {
  KANDA: 'kanda',
  CUSTOM: 'custom',
};

export interface Currency {
  amount: number | undefined;
  currency: string;
}

export const BASE_ITEM: JobItem = {
  title: '',
  description: undefined,
  quantity: 100,
  price: {
    amount: 0,
    currency: 'GBP' as JobItem['price']['currency'],
  },
  vat: {
    amount: 0,
    currency: 'GBP' as JobItem['price']['currency'],
  },
  vat_rate: 'exempted' as JobItem['vat_rate'],
};

export const ITEMS_FIELD_NAME = 'job.job_items';

export const CLOSE_MODAL_ID = 'close-modal';

export const BASE_FORM: CreateJobContextValue = {
  job: {
    title: '',
    job_items: [BASE_ITEM],
    deposit_type: 'no_deposit',
    deposit_value: {
      currency: 'GBP',
      amount: 0,
    },
    checkout_options: ['decline_job'],
    finance_options: undefined,
    customer: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: {
        postcode: '',
        line_1: '',
        line_2: '',
        city: '',
        country: '',
      },
    },
  },
  depositPercentage: 0,
};

export const BASE_DEPOSIT = {
  deposit_type: 'no_deposit',
  deposit_value: {
    currency: 'GBP',
    amount: 0,
  },
};

export const SOLAR_DEPOSIT = {
  deposit_type: 'partial_deposit',
  deposit_value: {
    currency: 'GBP',
    amount: 0,
  },
};
