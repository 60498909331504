import { BreakPoints } from '@kanda-libs/ks-design-library';

import Desktop from './Desktop';
import Container from './Item-container';
import Mobile from './Mobile';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
  refreshJobDetails?: () => void;
}

const ViewComponent = ({
  index,
  arrayName,
  refreshJobDetails,
}: ViewComponentProps) => (
  <Container index={index} arrayName={arrayName}>
    <BreakPoints
      mobile={<Mobile index={index} arrayName={arrayName} />}
      desktop={
        <Desktop
          index={index}
          arrayName={arrayName}
          refreshJobDetails={refreshJobDetails}
        />
      }
    />
  </Container>
);

ViewComponent.displayName = 'Createjob-Quote-Custom-Item-view';

export default ViewComponent;
