import { RowFields, Widget } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Button, Text } from '@kanda-libs/ks-design-library';

import { Role } from './components';
import { EMAIL_PROPS, MOBILE_PROPS, ROLES } from './New-constants';
import Container from './New-container';

export interface NewProps {
  role?: string;
  arrayName: string;
  index?: number;
  users: { index: number }[];
  remove?: (index: number) => void;
  prefillNewUser?: boolean;
}

const ViewComponent = ({ prefillNewUser, ...props }: NewProps) => (
  <Container {...props}>
    {({
      handleRemove,
      index,
      arrayName,
      validation,
      onNameBlur,
      onContactBlur,
      mobileValidation,
    }) => (
      <div className="flex flex-col mt-6 text-neutral-700">
        {!prefillNewUser && (
          <div className="flex flex-row justify-between mb-4">
            <Text text="New user" className="my-auto text-14-22-em " />
            <Button.Icon
              id="team-settings-new-user"
              icon="remove"
              onClick={handleRemove}
              size="32-20"
            />
          </div>
        )}
        <BreakPoints
          mobile={
            <>
              <Widget.CompanyUsersFirstNameArrayInput
                index={index}
                onBlur={() => onNameBlur('first_name')}
                placeholder="Their first name"
              />
              <Widget.CompanyUsersLastNameArrayInput
                index={index}
                validation={{
                  ...Widget.CompanyUsersLastNameArrayInputValidation,
                  required: { value: true, message: 'Last Name is required.' },
                }}
                onBlur={() => onNameBlur('last_name')}
                placeholder="Their last name"
              />
            </>
          }
          desktop={
            <RowFields>
              <Widget.CompanyUsersFirstNameArrayInput
                index={index}
                onBlur={() => onNameBlur('first_name')}
                placeholder="Their first name"
              />
              <Widget.CompanyUsersLastNameArrayInput
                index={index}
                validation={{
                  ...Widget.CompanyUsersLastNameArrayInputValidation,
                  required: { value: true, message: 'Last Name is required.' },
                }}
                onBlur={() => onNameBlur('last_name')}
                placeholder="Their last name"
              />
            </RowFields>
          }
        />
        <Widget.CompanyUsersEmailArrayInput
          {...EMAIL_PROPS}
          index={index}
          validation={validation}
          onBlur={() => onContactBlur('email')}
        />
        <Widget.CompanyUsersMobileArrayInput
          {...MOBILE_PROPS}
          validation={mobileValidation}
          index={index}
          onBlur={() => onContactBlur('mobile')}
        />
        <Text text="Account role" className="text-12-18-em text-neutral-600" />
        {ROLES.map((role) => (
          <Role role={role} arrayName={arrayName} index={index} key={role} />
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'New-view';

export default ViewComponent;
