import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useContext, useMemo } from 'react';
import { URLS } from '../../../../../../config';
import useMe from '../../../../../../hooks/useMe';
import {
  NAME_MODAL_ID,
  PHONE_MODAL_ID,
  VERIFY_EMAIL_MODAL_ID,
} from '../../Mobile-constants';

const ContainerComponent = ({ children }) => {
  const { user } = useCurrentUser();
  const { me, isValidating: isLoading } = useMe();

  const { showModal } = useContext(ModalsWrapperContext);

  const socialProvider =
    me?.provider === 'google.com' || me?.provider === 'facebook.com';

  const verified = user?.emailVerified;

  const items = useMemo(
    () => [
      {
        type: verified ? 'link' : 'button',
        title: 'Email',
        value: me?.email || 'Change your email',
        ...(verified && { to: URLS.changeEmail }),
        disabled: verified,
        onClick: (e) => {
          if (verified) {
            e.preventDefault();
            return;
          }
          showModal(VERIFY_EMAIL_MODAL_ID);
        },
        ...(verified && {
          style: {
            pointerEvents: 'none',
          },
        }),
        key: 'email',
      },
      {
        type: 'link',
        title: 'Password',
        value: socialProvider
          ? 'Your password is provided by your social provider'
          : 'Manage and update your password',
        to: URLS.changePassword,
        disabled: socialProvider,
        onClick: (e) => (socialProvider ? e.preventDefault() : {}),
        ...(socialProvider ? { style: { pointerEvents: 'none' } } : {}),
        key: 'password',
      },
      {
        type: 'button',
        title: 'Name',
        value: me?.name || 'Change your name',
        onClick: () => showModal(NAME_MODAL_ID),
        key: 'name',
      },
      {
        type: 'button',
        title: 'Phone number',
        value: me?.phone || 'Change your phone number',
        onClick: () => showModal(PHONE_MODAL_ID),
        key: 'phone',
      },
    ],
    [me, showModal, socialProvider, verified],
  );

  return children({
    items,
    isLoading,
  });
};

ContainerComponent.displayName =
  'MyAccount-AccountSettings-MobileMenu-container';

export default ContainerComponent;
