import { CLASS_NAMES } from './Desktop-constants';
import {
  Side,
  FinanceOptions,
  PaymentOptions,
  DepositOptions,
} from './components';

const ViewComponent = () => (
  <div className={CLASS_NAMES.container}>
    <Side />
    <div className={CLASS_NAMES.content}>
      <DepositOptions />
      <FinanceOptions />
      <PaymentOptions />
    </div>
  </div>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-view';

export default ViewComponent;
