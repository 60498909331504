import { abbreviateNumber } from '@kanda-utils/library';

export const formatAmount = (type, value, total) => {
  if (!type || !value) return '£0';
  const [pounds] = abbreviateNumber(Math.floor(value / 100));
  const cents = `${Math.round(value % 100)}`.padStart(2, '0');
  if (type === 'fixed_deposit') return `£${pounds}.${cents}`;
  const percentage = Math.round((value * 100) / total);
  return `${percentage}% / £${pounds}.${cents}`;
};
