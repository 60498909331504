import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import RatesTableHeader from './Header';
import RatesTableRow from './Row';
import useRatesTable from './useRatesTable';

interface EnterpriseRatesTableProps {
  rates: FinanceRate[];
  originalRates?: FinanceRate[];
}

const EnterpriseRatesTable: React.FunctionComponent<EnterpriseRatesTableProps> =
  function ({ rates, originalRates }) {
    const { formattedRates } = useRatesTable(rates, originalRates);
    if (!formattedRates) return null;
    return (
      <div className="grid grid-cols-[10fr_10fr_7fr]">
        <RatesTableHeader />
        {Object.keys(formattedRates).map((rate: string, index: number) => (
          <RatesTableRow
            key={rate}
            rate={rate}
            grouped={formattedRates[rate]}
            index={index}
          />
        ))}
        <div className="col-span-3 border-b md:border-0 border-neutral-300" />
      </div>
    );
  };

export default EnterpriseRatesTable;
