import { useContext } from 'react';
import { useFormContext } from '@kanda-libs/ks-component-ts';
import { useIsDesktop } from '@kanda-libs/ks-design-library';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import Context from '../../Context';
import { BASE_ITEM } from '../../../../../../CreateJob-constants';
import type { AddItemButtonProps } from './AddItemButton-view';

export interface ContainerComponentChildrenArgs {
  handleAddItem: () => void;
  isLoading: boolean;
}

export interface ContainerComponentProps extends AddItemButtonProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  arrayName,
  append,
}: ContainerComponentProps) => {
  const isDesktop = useIsDesktop();

  const {
    data: { isLoading = false },
  } = useCreateJobContextValue();

  const { setFocus } = useContext(Context);

  // Get `getter` functionf rom context
  const { getValues } = useFormContext();

  /**
   * Function to add another item
   */
  const handleAddItem = () => {
    // Add new item
    append(BASE_ITEM);
    // Return on desktop
    if (isDesktop) return;

    // Get form values
    const items = getValues(arrayName);
    // Determine number of items
    const numberOfItems = items?.length - 1;

    // Set focus to latest version if values have been correctly fetched
    if (numberOfItems) setFocus(numberOfItems);
  };

  return children({
    handleAddItem,
    isLoading,
  });
};

ContainerComponent.displayName = 'Items-AddItemButton-container';

export default ContainerComponent;
