import { useMe } from 'hooks';
import { PAGES } from '../constants';
import type { UpdateYourWebsitePage } from '../types';

export interface FooterHook {
  nextPage?: UpdateYourWebsitePage;
  isLastPage: boolean;
  showFinish: boolean;
}

export default function useFooter(page: UpdateYourWebsitePage): FooterHook {
  const { isAdmin } = useMe();

  const pageIndex = Object.keys(PAGES).indexOf(page);
  const nextPageIndex = pageIndex + 1;

  const nextPage = Object.keys(PAGES)[nextPageIndex] as
    | UpdateYourWebsitePage
    | undefined;

  const isLastPage = nextPageIndex === Object.keys(PAGES).length;

  const isCalculatorPage = page === 'calculator';

  const showFinish = isLastPage || (isCalculatorPage && !isAdmin);

  return {
    nextPage,
    isLastPage,
    showFinish,
  };
}
