import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatJobPayload } from './CreateJob-functions';
import useCreateJobContextValue from './CreateJob-useCreateJobContextValue';

export interface SubmitDraftHook {
  handleSubmit: (formData: StringIndexedObject) => void;
  isSubmitting: boolean;
  error?: string;
}

/**
 * Handles job submit
 * @returns {Array} handleSubmit, isSubmitting, error
 */
const useSubmitDraft = (): SubmitDraftHook => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const { setData, handleContinue } = useCreateJobContextValue();
  const { company } = useCurrentCompany();

  const [error, setError] = useState<string | undefined>();

  const onError = useApiError(
    'Error sending job at this time. This draft has been updated',
  );

  /**
   * Handles submit job
   */
  const handleSubmit = useCallback(
    (formData: StringIndexedObject) => {
      if (!company) return;
      const payload = formatJobPayload(formData, undefined, company);
      const id = formData?.job?.id;
      if (!id) return;

      dispatch(
        actions.putJob({
          body: payload,
          chainedRequest: true,
          params: { id },
          onError,
          onSuccess: (draftData) => {
            dispatch(
              actions.sendJob({
                params: { id },
                onError: (sentJobError) => {
                  onError(sentJobError);
                  setError(sentJobError.message);
                  setData({
                    ...formData,
                    job: {
                      ...draftData,
                    },
                  });
                },
                onSuccess: (sentJobData) => {
                  handleContinue({
                    ...formData,
                    job: {
                      ...sentJobData,
                    },
                  });
                },
              }),
            );
          },
        }),
      );
    },
    [onError, dispatch, setData, handleContinue, company, setError],
  );

  return {
    handleSubmit,
    isSubmitting,
    error,
  };
};

export default useSubmitDraft;
