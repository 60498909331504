import { isBlackFriday } from 'utils';
import type {
  SubscriptionCardClassNames,
  SubscriptionCardVariant,
} from './types';

export interface VariantProps {
  classNames: SubscriptionCardClassNames;
}

export const BASE_CARD_CLASSNAME =
  'relative overflow-hidden flex flex-col w-full border rounded-xl px-6 md:px-8 pt-8 md:pt-12 pb-5 md:pb-10 max-w-160 mx-auto';

export const BASE_TITLE_CLASSNAME = `text-14-22-em ${
  isBlackFriday() ? 'text-green-500' : 'text-neutral-700'
} mb-3 md:mb-4 z-40`;

export const BASE_LINES_CLASSNAME = 'absolute left-0 top-0';

export const BASE_SUBSCRIPTION_PRICE_CLASSNAME =
  'text-24-28-em md:text-32-40-em mr-1 md:mr-2';

export const BASE_SUBSCRIPTION_FREQUENCY_CLASSNAME =
  'text-14-22-em md:text-18-28-em md:mt-0.5';

export const CARD_VARIANT_PROPS: Record<SubscriptionCardVariant, VariantProps> =
  {
    standard: {
      classNames: {
        card: [
          BASE_CARD_CLASSNAME,
          isBlackFriday() ? 'border-neutral-300' : 'border-green-300',
        ].join(' '),
        title: BASE_TITLE_CLASSNAME,
        lines: BASE_LINES_CLASSNAME,
        subscriptionPrice: [
          BASE_SUBSCRIPTION_PRICE_CLASSNAME,
          'text-green-600',
        ].join(' '),
        subscriptionFrequency: [
          BASE_SUBSCRIPTION_FREQUENCY_CLASSNAME,
          'text-green-500',
        ].join(' '),
      },
    },
    highlight: {
      classNames: {
        card: [BASE_CARD_CLASSNAME, 'border-green-300'].join(' '),
        title: BASE_TITLE_CLASSNAME,
        lines: BASE_LINES_CLASSNAME,
        subscriptionPrice: [
          BASE_SUBSCRIPTION_PRICE_CLASSNAME,
          'text-green-600',
        ].join(' '),
        subscriptionFrequency: [
          BASE_SUBSCRIPTION_FREQUENCY_CLASSNAME,
          'text-green-500',
        ].join(' '),
      },
    },
  };
