import { MESSAGE } from './Modal-constants';

const ContainerComponent = ({ children, customer }) => {
  // Construct message for user
  const message = `${MESSAGE.intro}${customer?.first_name || ''}.\n${
    MESSAGE.middle
  }\n${MESSAGE.end}`;

  // Construct href for sending sms from anchor
  const href = customer?.phone
    ? `sms://${customer?.phone}?body=${encodeURI(message)}`
    : null;

  return children({
    message,
    href,
  });
};

ContainerComponent.displayName = 'CreateJob-SendConfirmation-Body-container';

export default ContainerComponent;
