import { useToast } from '@kanda-libs/ks-design-library';
import { Lead, actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface LeadAcceptHook {
  lead?: Lead;
  onApproveLead: () => void;
  onDenyLead: () => void;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useLeadAccept(): LeadAcceptHook {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const { showSuccess } = useToast();

  const lead = useSelector(selectors.lead.getEntity);
  const isLoading = useSelector(selectors.getIsLoading);
  const isSubmitting = useSelector(selectors.lead.getIsSubmitting);

  const onError = useApiError('Error accepting lead at this time');

  const onDenyLead = useCallback(() => {
    showSuccess('Lead denied successfully');
    push(URLS.home);
  }, [showSuccess, push]);

  const onApproveLead = useCallback(() => {
    if (!isLoading) {
      dispatch(
        actions.tradeQuoteApprovalLead({
          params: { id: lead?.id as string },
          onError,
          onSuccess: () => {
            showSuccess('Lead accepted successfully');
            push(URLS.home);
          },
        }),
      );
    }
  }, [isLoading, dispatch, push, showSuccess, onError, lead]);

  return {
    lead,
    onApproveLead,
    onDenyLead,
    isLoading,
    isSubmitting,
  };
}
