import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';

export interface FieldDisabledPropsHook {
  disabled: boolean;
  isExampleJob: boolean;
  isLeadJob: boolean;
  disabledProps:
    | object
    | {
        className: string;
      };
}

export default function useFieldDisabledProps(): FieldDisabledPropsHook {
  const isExampleJob = useIsExampleJob();
  const isLeadJob = useIsLeadJob();

  const disabled = isExampleJob || isLeadJob;

  const disabledProps = disabled
    ? {
        className: 'opacity-50 cursor-not-allowed pointer-events-none',
      }
    : {};

  return { disabled, isExampleJob, isLeadJob, disabledProps };
}
