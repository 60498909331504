import { Widget, Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import Container from './Address-container';
import { POSTCODE_NAME } from './Address-constants';

const ViewComponent = () => (
  <Container>
    {({
      addresses,
      postcodeCallback,
      manual,
      addManualInput,
      showSelect,
      selectProps,
    }) => (
      <div className="flex flex-col">
        <Widget.JobCustomerAddressPostcode
          callback={postcodeCallback}
          placeholder="Postcode"
          label="Postcode"
          autoComplete="none"
        />
        {showSelect && (
          <>
            <Field.Address.Select
              label="Select Address"
              postcodeName={POSTCODE_NAME}
              data={addresses}
              {...selectProps}
            />
            {!manual && (
              <Button.Link
                id="create-job-mobile-manually-enter-address"
                className="mr-auto mt-2"
                variant="light-grey"
                size={14}
                onClick={addManualInput}
                label="Manually enter address"
              />
            )}
            {manual && (
              <>
                <Widget.JobCustomerAddressLine1 placeholder="Address line 1" />
                <Widget.JobCustomerAddressLine2 placeholder="Address line 1" />
                <Widget.JobCustomerAddressCity placeholder="City" />
                <Widget.JobCustomerAddressCounty placeholder="County" />
                <Widget.JobCustomerAddressCountry placeholder="Country" />
              </>
            )}
          </>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Mobile-Customer-Field-Address-view';

export default ViewComponent;
