import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function useEnterpriseRatesSuccess() {
  const { company } = useCurrentCompany();
  const { push } = useHistory();

  useEffect(() => {
    if (!company) return;
    const enterprise = company?.tier_config?.current_tier === 'enterprise_rate';
    if (enterprise) return;
    push(URLS.home);
  }, [company, push]);
}
