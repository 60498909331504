import { Form } from '@kanda-libs/ks-component-ts';

import InformationRequiredBanner from 'pages/Onboarding/components/Pages/components/InformationRequiredBanner';
import BasePage from '../BasePage';
import { TITLE, BULLETS } from './InsuranceInfo-constants';
import useInsuranceInfo from './useInsuranceInfo';
import InsuranceInfoForm from './Form';

const ViewComponent = ({}) => {
  const { form, onSubmit, isLoading, isSubmitting } = useInsuranceInfo();

  return (
    <Form
      id="onboarding-company-info-insurance"
      form={form}
      onSubmit={onSubmit}
    >
      <BasePage sidebarBullets={BULLETS} sidebarTitle={TITLE}>
        <>
          <InformationRequiredBanner stage="insurance_reviewed" />
          <InsuranceInfoForm
            isLoading={isLoading}
            isSubmitting={isSubmitting}
          />
        </>
      </BasePage>
    </Form>
  );
};

ViewComponent.displayName = 'InsuranceInfo-view';

export default ViewComponent;
