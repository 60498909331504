import React from 'react';
import { Button } from '@kanda-libs/ks-design-library';

import { FILTER_TYPE, BUTTON_PROPS } from './ActiveFilters-constants';
import Container from './ActiveFilters-container';
import { StatusFilter, FinanceStatusFilter, PriceFilter } from './components';

const ViewComponent = () => (
  <Container>
    {({ activeFilters, onClearAll }) => (
      <div className="flex flex-row flex-wrap items-center pb-2 -mb-2">
        {activeFilters.map((type) => {
          switch (type) {
            case FILTER_TYPE.STATUS:
              return <StatusFilter key={type} />;
            case FILTER_TYPE.FINANCE_STATUS:
              return <FinanceStatusFilter key={type} />;
            case FILTER_TYPE.PRICE:
              return <PriceFilter key={type} />;
            default:
              return <React.Fragment key={type} />;
          }
        })}
        <Button.Text {...BUTTON_PROPS} onClick={onClearAll} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ActiveFilters-view';

export default ViewComponent;
