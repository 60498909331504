import { Header, Layout } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import type { EnterpriseRatesSuccessLayoutProps } from '.';

const EnterpriseRatesSuccessLayoutMobile: FunctionComponent<EnterpriseRatesSuccessLayoutProps> =
  function ({ children, footer }) {
    return (
      <Layout
        noBorder
        footer={<>{footer}</>}
        header={<Header.Main linkComponent={Link} />}
      >
        <>{children}</>
      </Layout>
    );
  };

export default EnterpriseRatesSuccessLayoutMobile;
