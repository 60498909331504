export const CLASS_NAMES = {
  container: 'flex flex-col',
  list: 'flex flex-col -mx-6',
  pagination: 'flex flex-row mb-4 justify-center',
  link: 'mb-3 border-b border-solid border-neutral-200 last:border-0',
  cardPreview: 'bg-violet-100',
};

const BASE_SKELETON_JOB = {
  link: '#',
  customerDetails: {
    firstName: '',
    lastName: '',
  },
  total: 0,
  status: 'draft',
  financeStatus: 'not_applied',
  updatedAt: '',
};

export const SKELETON_JOBS = [
  { key: 'temp-1', ...BASE_SKELETON_JOB },
  { key: 'temp-2', ...BASE_SKELETON_JOB },
  { key: 'temp-3', ...BASE_SKELETON_JOB },
];
