export const getQueryParams = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }

  return new URLSearchParams();
};

export const getQueryParamValue = (key) => {
  const query = getQueryParams();
  return decodeURIComponent(query.get(key) as string).replace(' ', '+');
};
