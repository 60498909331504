import Name from './Name';
import Contact from './Contact';
import Address from './Address';
import Wrapper from './Wrapper';

export default {
  Name,
  Contact,
  Address,
  Wrapper,
};
