import { Button } from '@kanda-libs/ks-design-library';

import Container from './MarkAsFinished-container';

const ViewComponent = ({}) => (
  <Container>
    {({ showButton, showOmniWarning, onClick, companyIsLoading }) => (
      <>
        {showButton && !showOmniWarning ? (
          <>
            <Button.Text
              id="job-details-mark-as-finished"
              onClick={onClick}
              label="Mark as finished"
              size={40}
              icon="send"
              left
              className="w-full md:mr-2"
              disabled={companyIsLoading}
            />
          </>
        ) : null}
        {showButton && showOmniWarning ? (
          <div className="w-full max-w-80 p-2 border border-red-200 rounded-lg bg-red-100 mb-2 md:mb-0 md:mr-2 text-14-22-em text-red-200">
            Due to a recent update by Omni Capital we&apos;re unable to fulfil
            this order. To see a full a update and to learn about what to do
            next and what Kanda is doing to help,{' '}
            <a
              href="https://clt1489884.bmeurl.co/108F1772"
              className="text-blue-200 underline"
            >
              click here
            </a>
          </div>
        ) : null}
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-MarkAsFinished-view';

export default ViewComponent;
