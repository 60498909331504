export const CLASS_NAMES = {
  outerWrapper: 'mb-10 w-full text-center flex flex-col items-center',
  card: 'max-w-100 shadow-card',
  wrapper: 'flex flex-col items-start',
  name: 'text-16-20-em text-green-500 mb-1',
  companyName: 'text-16-20-em text-neutral-500 text-sm',
  notYou: 'text-14-20-em text-neutral-500 text-sm mt-4',
  notYouLink: 'text-green-500 underline',
};

export const SKELETONS = {
  name: {
    height: 20,
    width: 160,
  },
  companyName: {
    height: 16,
    width: 200,
  },
};
