import { useWatch } from '@kanda-libs/ks-component-ts';
import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { BASE_USER } from '../AddUserButton/AddUserButton-constants';
import type { ContentUsersProps } from './Users-view';

const initialAndFieldsMatch = (initial, fields) => {
  if (!initial || !fields) return false;

  const formatted = fields.reduce((users, user) => {
    const { id, index, ...values } = user;
    users.push(values);
    return users;
  }, []);

  return isEqual(initial, formatted);
};

export interface ContainerComponentChildrenArgs {
  existingUsers: ContentUsersProps['fields'];
  newUsers: ContentUsersProps['fields'];
  arrayName: ContentUsersProps['arrayName'];
  defaultValues: ContentUsersProps['defaultValues'];
  remove: ContentUsersProps['remove'];
  showNewUsers: boolean;
  showRemove: ContentUsersProps['showRemove'];
  showUpdate: boolean;
  isSubmitting: ContentUsersProps['isSubmitting'];
  canUserEdit: ContentUsersProps['canUserEdit'];
  userToDelete: number | null;
  setUserToDelete: (index: number) => void;
}

export interface ContainerComponentProps extends ContentUsersProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  fields,
  arrayName,
  defaultValues,
  remove,
  showRemove,
  isSubmitting,
  canUserEdit,
  append,
  prefillNewUser,
}: ContainerComponentProps) => {
  const [userToDelete, setIndex] = useState(null);
  const setUserToDelete = (index) => setIndex(index);

  const [existingUsers, newUsers] = fields.reduce(
    (userArray, user, index) => {
      if (user?.state === 'existing') {
        userArray[0].push({
          ...user,
          index,
        });
        return userArray;
      }
      userArray[1].push({
        ...user,
        index,
      });
      return userArray;
    },
    [[], []] as unknown as ContentUsersProps['fields'][],
  );

  const users = useWatch({ name: 'company.users' });
  const showUpdate = !initialAndFieldsMatch(
    defaultValues?.company?.users,
    users,
  );

  const showNewUsers = newUsers?.length !== 0;

  useEffect(() => {
    if (prefillNewUser && append) {
      append(BASE_USER);
    }
  }, [prefillNewUser, append]);

  return children({
    existingUsers,
    newUsers,
    arrayName,
    defaultValues,
    remove,
    showNewUsers,
    showRemove,
    showUpdate,
    isSubmitting,
    canUserEdit,
    userToDelete,
    setUserToDelete,
  });
};

ContainerComponent.displayName = 'Users-container';

export default ContainerComponent;
