import { Layout } from '@kanda-libs/ks-design-library';

import { Form, Confirmation } from '../components';

import { SubPageHeader } from '../../components';
import type { FormProps } from '../components/Form';

interface ViewComponentProps extends Partial<FormProps> {
  /**
   * Show confirmation screen
   */
  showConfirmation?: boolean;
}

const ViewComponent = ({
  showConfirmation,
  ...formProps
}: ViewComponentProps) => (
  <Layout stickyFooter noBorder header={<SubPageHeader />}>
    {showConfirmation ? (
      <Confirmation />
    ) : (
      <Form {...(formProps as FormProps)} />
    )}
  </Layout>
);

ViewComponent.displayName = 'ChangePassword-Mobile-view';

ViewComponent.defaultProps = {
  showConfirmation: false,
};

export default ViewComponent;
