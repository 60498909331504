import Form from './Form';
import Summary from './Summary';
import SendConfirmation from './SendConfirmation';

import { ADD_A_JOB_STEPS_DESKTOP } from '../../../CreateJob-constants';

export default {
  [ADD_A_JOB_STEPS_DESKTOP.FORM]: Form,
  [ADD_A_JOB_STEPS_DESKTOP.SUMMARY]: Summary,
  [ADD_A_JOB_STEPS_DESKTOP.SEND_CONFIRMATION]: SendConfirmation,
};
