import { Widget } from '@kanda-libs/ks-component-ts';

import Row from './Row';
import Container from './Rows-container';

const ViewComponent = () => (
  <Container>
    {({ isLoading, hasCommsPrefs }) => (
      <div className="flex flex-col">
        <Widget.CompanyUsersArrayWrapper>
          {({ arrayName, fields }) =>
            isLoading ? (
              <>
                {['temp1', 'temp2', 'temp3'].map((id) => (
                  <Row key={id} id={id} isLoading={isLoading} />
                ))}
              </>
            ) : (
              <>
                {hasCommsPrefs ? (
                  fields.map(({ id, ...field }, index) => (
                    <Row
                      key={id}
                      id={id}
                      field={field}
                      isLoading={isLoading}
                      arrayName={arrayName}
                      index={index}
                    />
                  ))
                ) : (
                  <>
                    <p>test</p>
                  </>
                )}
              </>
            )
          }
        </Widget.CompanyUsersArrayWrapper>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'NotificationPreferences-Content-Desktop-Rows-view';

export default ViewComponent;
