import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions, useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { BASE_URL, URLS } from '../../config';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);

  const { isUserLoggedIn, isValidating } = useCurrentUser();

  const isSubmitting = useSelector(selectors.infoAuth.getIsSubmitting);

  const { push } = useHistory();
  const { showSuccess } = useToast();
  const onError = useApiError(
    'Cannot resend email at this time - please try again later',
  );

  const form = useForm();

  const disabled = isSubmitting || isValidating;

  const onSubmit = useCallback(
    (formValues) => {
      const email = formValues?.email;
      if (!email) return;
      const body = {
        email,
        continue_url: `${BASE_URL}claim-account?email=${email}`,
      };

      dispatch(
        actions.infoAuth({
          body,
          onError,
          onSuccess: () => {
            setPage(1);
            showSuccess('Email sent to your email address');
          },
        }),
      );
    },
    [dispatch, onError, showSuccess],
  );

  useEffect(() => {
    if (isValidating || !isUserLoggedIn) return;
    push(URLS.home);
  }, [isUserLoggedIn, isValidating, push]);

  return children({
    form,
    onSubmit,
    disabled,
    isSubmitting,
    page,
  });
};

ContainerComponent.displayName = 'SSO-container';

export default ContainerComponent;
