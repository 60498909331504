import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { UserType } from '@kanda-libs/ks-frontend-services';
import type { WelcomeSkipBanner } from '@kanda-libs/ks-frontend-services/src/generated/components/schemas/WelcomeSkipBanner';

import { EMPTY_WELCOME_PACK } from 'common/constants';
import { useCurrentCompany } from 'hooks';
import { selectors } from 'store';

export interface TrainingBannerHook {
  showBanner: boolean;
  welcomePack: WelcomeSkipBanner | object;
  someDirectorsNotVerified: boolean;
}

export default function useTrainingBanner(): TrainingBannerHook {
  const isLoading = useSelector(selectors.getIsLoading);
  const { company } = useCurrentCompany();

  const welcomePack = useMemo(
    () => ({ ...EMPTY_WELCOME_PACK, ...(company?.welcome_pack || {}) }),
    [company],
  );

  const someDirectorsNotVerified = useMemo(() => {
    if (isLoading || !company) return false;
    const directors = company.users
      ?.filter((user: UserType) => user?.director_info)
      .map((director: UserType) => director.director_info?.verification_status)
      ?.filter((status: string | undefined) => status && status !== 'verified');
    if (!directors) return false;
    return directors.length > 0;
  }, [isLoading, company]);

  const showBanner = useMemo(() => {
    if (isLoading) return false;
    if (!welcomePack) return true;
    if (someDirectorsNotVerified) return true;

    const keys = Object.keys(welcomePack);
    const completedKeys = keys.filter((key) => welcomePack[key] === 'yes');

    return keys.length !== completedKeys.length;
  }, [welcomePack, isLoading, someDirectorsNotVerified]);

  return {
    showBanner,
    welcomePack,
    someDirectorsNotVerified,
  };
}
