import useSaveDraft from '../../../../../../CreateJob-useSaveDraft';

export default function useSubscriptionModalSubmit() {
  const { handleSubmit: saveDraft } = useSaveDraft();

  function onSubmit() {
    saveDraft(null);
  }

  return onSubmit;
}
