import { Pagination } from '@kanda-libs/ks-design-library';

import Container from './Jobs-container';
import { CLASS_NAMES } from './Jobs-constants';
import { Card } from './components';

const ViewComponent = ({}) => (
  <Container>
    {({
      data,
      isLoading,
      showPagination,
      paginationProps,
      showingPlaceholderJobs,
    }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.list}>
          {data?.map(
            ({ id, customerDetails: { firstName, lastName }, ...rest }) => (
              <Card
                key={id}
                isLoading={isLoading}
                firstName={firstName}
                lastName={lastName}
                showingPlaceholderJobs={showingPlaceholderJobs}
                {...rest}
              />
            ),
          )}
        </div>
        {!isLoading && showPagination && (
          <div className={CLASS_NAMES.pagination}>
            <Pagination {...paginationProps} />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-view';

export default ViewComponent;
