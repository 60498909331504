import { Form } from '@kanda-libs/ks-component-ts';
import type { FunctionComponent } from 'react';
import * as JobExtraDetails from 'pages/CreateJob/Desktop/components/Pages/Form/components/JobExtraDetails';
import Page from '../../Page';
import Container from './ExtraDetails-container';

const ExtraDetails: FunctionComponent = function () {
  return (
    <Container>
      {({ form, onSubmit, nextButtonProps }) => (
        <Form id="create-job-customer-mobile" form={form} onSubmit={onSubmit}>
          <Page stickyHeader nextButtonProps={nextButtonProps}>
            <JobExtraDetails.Info />
            <JobExtraDetails.Fields />
          </Page>
        </Form>
      )}
    </Container>
  );
};

export default ExtraDetails;
