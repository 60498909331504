import useCompanyLifecycle from '../../../../../hooks/useCompanyLifecycle';

const ContainerComponent = ({ children }) => {
  const lifecycle = useCompanyLifecycle();

  const showBanner = lifecycle === 'registered' || lifecycle === 'onboarded';

  return children({ showBanner });
};

ContainerComponent.displayName = 'JobsPlaceholder-container';

export default ContainerComponent;
