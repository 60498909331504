import useStepIndicator from './useStepIndicator';

const SetupStepIndicator: React.FunctionComponent = function () {
  const { steps, indicatorClassNames } = useStepIndicator();
  return (
    <div className="flex flex-col w-full gap-2 mb-6 md:mb-16">
      <div className="flex flex-row w-full justify-between gap-2">
        {steps.map((step) => (
          <p className={step.className} key={step.label}>
            {step.label}
          </p>
        ))}
      </div>
      <div className={indicatorClassNames.wrapper}>
        <div className={indicatorClassNames.progress} />
      </div>
    </div>
  );
};

export default SetupStepIndicator;
