import { Widget } from '@kanda-libs/ks-component-ts';

import useSolarInfoForm from './useSolarInfoForm';

const ViewComponent = ({}) => {
  const { validation } = useSolarInfoForm();

  return (
    <>
      <Widget.CompanySolarCompanyInfoEpvsRegistrationNumber />
      <Widget.CompanySolarCompanyInfoSolarTradeAssociation />
      <Widget.CompanySolarCompanyInfoSolarTradeAssociationNumber />
      <Widget.CompanySolarCompanyInfoElectricalTradeAssociation />
      <Widget.CompanySolarCompanyInfoElectricalTradeAssociationNumber
        validation={validation}
      />
    </>
  );
};

ViewComponent.displayName = 'SolarTradeInfoForm-view';

export default ViewComponent;
