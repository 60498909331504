import { Text } from '@kanda-libs/ks-design-library';
import { Fragment, type FunctionComponent } from 'react';
import { isBlackFriday } from 'utils';
import type { SubscriptionCardVariant } from './types';

export interface BulletsProps {
  isLoading?: boolean;
  variant?: SubscriptionCardVariant;
  bullets: string[];
}

const Bullets: FunctionComponent<BulletsProps> = ({
  bullets,
  variant = 'standard',
  isLoading = false,
}) => {
  const bulletColor =
    isBlackFriday() && variant === 'highlight'
      ? 'bg-green-300'
      : 'bg-netural-400';
  const textColor =
    isBlackFriday() && variant === 'highlight'
      ? 'text-green-500'
      : 'text-neutral-700';

  return (
    <div className="flex flex-row flex-wrap mt-1 md:mt-3 mb-6 md:mb-8">
      {isLoading ? (
        <>
          <Text isLoading text="bullet" skeletonProps={{ width: 80 }} />
          <div
            className={`w-1 h-1 min-w-1 min-h-1 ${bulletColor} my-auto mx-3 rounded-full`}
          />
          <Text isLoading text="bullet" skeletonProps={{ width: 80 }} />
        </>
      ) : (
        bullets.map((bullet: string, index: number) => (
          <Fragment key={bullet}>
            {index !== 0 && (
              <div
                className={`w-1 h-1 min-w-1 min-h-1 ${bulletColor} my-auto mx-3 rounded-full`}
              />
            )}
            <p className={`text-14-22 ${textColor}`}>{bullet}</p>
          </Fragment>
        ))
      )}
    </div>
  );
};
export default Bullets;
