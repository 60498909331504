export const CLASS_NAMES = {
  container: 'flex flex-col mt-6 md:mt-10 mb-10 md:mb-0',
  title: 'text-18-22-em mb-4',
  subtitle: 'text-14-22 text-neutral-600 mb-6',
};

export const TEXT = {
  title: 'EPVS Calculations',
  subtitle:
    'You need to upload a copy of the calculations supplied to EPVS once they have confirmed your calculations provided to the customer have been checked.  ',
};

export const BASE_FILE = undefined;
