import { Icon, Description, Status, AddBankDetails } from './components';
import { CLASS_NAMES } from '../../FinanceApplicationProgress-constants';

const ViewComponent = () => (
  <div className={CLASS_NAMES.cardContainer}>
    <div className={CLASS_NAMES.card}>
      <Icon />
      <Description />
      <Status />
      <AddBankDetails />
    </div>
  </div>
);

ViewComponent.displayName = 'Job-Desktop-FinanceApplicationProgress-Card-view';

export default ViewComponent;
