import { Button } from '@kanda-libs/ks-design-library';
import useSwitchCompanyType from './useSwitchCompanyType';

const SwitchCompanyTypeButton: React.FunctionComponent = function () {
  const { onClick, label } = useSwitchCompanyType();
  return (
    <Button.Text
      id="switch-sole-trader"
      size={32}
      variant="outline"
      label={label}
      className="mt-0 mb-4 md:ml-3 md:mt-7 md:mb-0"
      onClick={onClick}
    />
  );
};

export default SwitchCompanyTypeButton;
