import { Referral } from '@kanda-libs/ks-frontend-services';
import get from 'lodash.get';
import { ORDER, SORTING, type Order } from './constants';

export type FormattedLifecyle = 'sent' | 'registered' | 'subscribed';

export interface EnhancedReferral extends Referral {
  formattedLifecyle: FormattedLifecyle;
}

export const formatLifecycle = (lifecycle?: string): FormattedLifecyle => {
  if (!lifecycle) return 'sent';
  if (lifecycle === 'registered') return 'registered';
  if (lifecycle === 'subscribed') return 'subscribed';
  return 'subscribed';
};

export const formatReferrals = (referrals: Referral[]) =>
  referrals.map((referral: Referral) => ({
    ...referral,
    formattedLifecyle: formatLifecycle(referral?.lifecycle),
  }));

export const sortAlphabetical = (
  referrals: EnhancedReferral[],
  accessor: string,
  order: Order,
): EnhancedReferral[] =>
  referrals.sort((r1: EnhancedReferral, r2: EnhancedReferral) => {
    const v1 = get(r1, accessor);
    const v2 = get(r2, accessor);
    if (order === ORDER.INVERSE) return v2.localeCompare(v1);
    return v1.localeCompare(v2);
  });

export const sortReferrals = (
  referrals: EnhancedReferral[],
  sorting: string,
  order: Order,
): EnhancedReferral[] => {
  if (referrals.length === 0) return [];
  if (sorting === SORTING.NAME)
    return sortAlphabetical(referrals, 'name', order);
  if (sorting === SORTING.EMAIL)
    return sortAlphabetical(referrals, 'email', order);
  if (sorting === SORTING.LIFECYCLE)
    return sortAlphabetical(referrals, 'formattedLifecyle', order);
  return referrals;
};
