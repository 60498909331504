import { useMemo } from 'react';

import { useCurrentCompany } from 'hooks';
import useCurrentJob from '../../../../../../JobDetails-useCurrentJob';

export interface ContainerComponentChildrenArgs {
  showButton: boolean;
  isLoading: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { company } = useCurrentCompany();
  const { isLoading, job } = useCurrentJob();

  const financeStatus = useMemo(
    () => job?.finance_status || 'not_applied',
    [job],
  );

  const showButton =
    ['payout_pending', 'financed'].includes(financeStatus) &&
    !company?.bank_account;

  return children({
    showButton,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-AddBankDetails-container';

export default ContainerComponent;
