import { useToast } from '@kanda-libs/ks-design-library';
import {
  actions,
  FinanceRate,
  RedirectURLs,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface EnterpriseRatesContentHook {
  onClick: () => void;
  isSubmitting: boolean;
  enterpriseRates?: FinanceRate[];
  userRates?: FinanceRate[];
}

export default function useEnterpriseRatesContent(): EnterpriseRatesContentHook {
  const dispatch = useAppDispatch();
  const handleApiError = useApiError('Unkonwn error');
  const { showError } = useToast();

  const { company } = useCurrentCompany();
  const enterprise = useSelector(selectors.rate.getEntities);
  const isSubmitting = useSelector(
    selectors.postCompanyBilling.getIsSubmitting,
  );

  const enterpriseRates = useMemo(
    () => enterprise?.all?.finance_rates,
    [enterprise],
  );
  const userRates = useMemo(() => company?.finance_rates, [company]);

  const onClick = useCallback(() => {
    const id = company?.id;
    if (!id || isSubmitting) return;
    dispatch(
      actions.postCompanyBilling({
        params: {
          id,
          tier: 'enterprise_rate',
        },
        onError: handleApiError,
        onSuccess: (data: RedirectURLs) => {
          const checkoutUrl = data?.checkout_url;
          if (!checkoutUrl) {
            showError(
              'Authorisation URL missing from response - please contact Kanda',
            );
            return;
          }
          window.location.href = checkoutUrl;
        },
      }),
    );
  }, [showError, dispatch, company, isSubmitting, handleApiError]);

  return {
    onClick,
    isSubmitting,
    enterpriseRates,
    userRates,
  };
}
