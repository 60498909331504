export const CLASS_NAMES = {
  base: {
    container:
      'flex-col w-full p-4 mb-1 rounded-md transition duration-150 ease-in-out',
    name: 'text-style-f-em mb-1 transition duration-150 ease-in-out',
    description: 'text-style-h transition duration-150 ease-in-out',
  },
  inactive: {
    container: 'group hover:bg-turquoise-100',
    name: 'text-neutral-900 group-hover:text-turquoise-500',
    description: 'text-neutral-600 group-hover:text-turquoise-500',
  },
  active: {
    container: `bg-turquoise-100`,
    name: 'text-turquoise-500',
    description: 'text-turquoise-500',
  },
};
