import { Loader } from '@kanda-libs/ks-design-library';
import SubscriptionCard, {
  type SubscriptionCardProps,
} from './SubscriptionCard';
import useSubscriptions from './useSubscriptions';

const Subsctiptions: React.FunctionComponent = function () {
  const { subscriptions, isSubmitting, isLoading } = useSubscriptions();
  return (
    <div className="flex flex-col md:flex-row gap-x-8 gap-y-4 mt-8 md:mt-16">
      {subscriptions.map((card: SubscriptionCardProps) => (
        <SubscriptionCard
          key={card.buttonProps.id}
          {...card}
          isLoading={isLoading}
        />
      ))}
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

export default Subsctiptions;
