import { getCompanyName, getUserName } from '../../MyAccount-functions';
import useIsSubscribed from '../../../../hooks/useIsSubscribed';
import useDocumentContent from '../../../../hooks/useDocumentContent';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import useMe from '../../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const { me } = useMe();

  const { company } = useCurrentCompany();

  const displayName =
    getCompanyName(company) || getUserName(me) || 'No company added';

  const isLoading = !company;

  const profilePicture = useDocumentContent(company?.company_logo?.id);

  const { isSubscribed } = useIsSubscribed();
  const subscriptionStatusLabel = isSubscribed ? 'Subscribed' : 'Trial';

  return children({
    profilePicture,
    isLoading,
    displayName,
    isSubscribed,
    subscriptionStatusLabel,
  });
};

ContainerComponent.displayName = 'CompanyHeader-container';

export default ContainerComponent;
