import { Button, Loader, Banner } from '@kanda-libs/ks-design-library';

import Container from './CompleteAccountBadge-container';
import {
  CLASS_NAMES,
  BUTTON_LARGE_PROPS,
  BUTTON_SMALL_PROPS,
} from './CompleteAccountBadge-constants';

const ViewComponent = () => (
  <Container>
    {({ showBanner, onClick, isSubmitting }) =>
      showBanner && (
        <div className="md:mt-10 border rounded-lg border-green-200">
          <Banner>
            <div className={CLASS_NAMES.container}>
              <div className={CLASS_NAMES.textWrapper}>
                <span className={CLASS_NAMES.title}>
                  Finish set up to send your first Job
                </span>
                <span className={CLASS_NAMES.subTitle}>
                  Complete Company Set Up
                </span>
              </div>
              <div className={CLASS_NAMES.link}>
                <Button.Text onClick={onClick} {...BUTTON_LARGE_PROPS} />
                <Button.Text onClick={onClick} {...BUTTON_SMALL_PROPS} />
              </div>
            </div>
          </Banner>
          <Loader isLoading={isSubmitting} />
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'CompleteAccountBadge-view';

export default ViewComponent;
