import { SkeletonLoader } from '@kanda-libs/ks-design-library';
import { ReactComponent as GoogleLogo } from 'assets/g-logo.svg';
import { useGtag } from 'hooks';

interface GoogleButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const GoogleButton: React.FunctionComponent<GoogleButtonProps> = function ({
  onClick,
  isLoading = false,
  disabled = false,
}) {
  const { buttonClick } = useGtag();

  const extendedOnClick = () => {
    buttonClick('google_sign_in');
    onClick();
  };

  return (
    <SkeletonLoader
      isLoading={isLoading}
      width="100%"
      height={44}
      wrapperClassName="w-full"
      afterLoading={
        <button
          className="relative flex flex-row justify-center w-full h-12 p-3 rounded rounded-3xl bg-neutral-900"
          type="button"
          id="google-sign-in"
          disabled={disabled}
          onClick={extendedOnClick}
        >
          <GoogleLogo className="my-auto mr-3" />
          <p className="my-auto text-center text-16-20-em whitespace-nowrap text-neutral-000">
            Sign up with Google
          </p>
        </button>
      }
    />
  );
};

export default GoogleButton;
