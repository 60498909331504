export const ONBOARDING_STEPS = {
  WELCOME: 'welcome',
  SETUP_YOUR_SUBSCRIPTION: 'setup-your-subscription',
  VERIFICATION: 'verification',
  IDENTIFICATION: 'identification',
  SETUP_COMPANY: 'setup-company',
};

export const STEPS = Object.values(ONBOARDING_STEPS);

export const INITIAL_DATA = {
  jobType: 'kanda',
};
