import { Icon, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import Layout from './Layout';

const NoCode: FunctionComponent = function () {
  return (
    <Layout>
      <div className="flex flex-col m-auto max-w-100">
        <Icon
          icon="error"
          stroke="red-200"
          size={64}
          className="mb-6 mx-auto"
        />
        <Text
          text="There appears to be an issue with the link you have followed - please try following the link from your email again"
          className="text-16-20-em md:text-20-24-em text-center pb-16 md:pb-0"
        />
      </div>
    </Layout>
  );
};

export default NoCode;
