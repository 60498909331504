export const FIELD_NAMES = {
  presence: 'company.company_info.online_presence.q_online',
  facebook: 'facebookPresence',
  google: 'googlePresence',
};

export const FIELD_PROPS = {
  facebook: {
    label: 'Do you have a Facebook business page?',
    name: FIELD_NAMES.facebook,
    placeholder: 'Select...',
    options: [
      {
        name: 'Yes',
        value: 'yes',
      },
      {
        name: 'No',
        value: 'no',
      },
    ],
  },
  google: {
    label: 'Do you have a Google business page?',
    name: FIELD_NAMES.google,
    placeholder: 'Select...',
    options: [
      {
        name: 'Yes',
        value: 'yes',
      },
      {
        name: 'No',
        value: 'no',
      },
    ],
  },
};

export const FIELD_VALIDATION = {
  google: {
    required: { value: true, message: 'Answer is required.' },
  },
  facebook: {
    required: { value: true, message: 'Answer is required.' },
  },
};

export const LINK_VALIDATION = {
  google: {
    required: { value: true, message: 'Link is required.' },
  },
  facebook: {
    required: { value: true, message: 'Link is required.' },
  },
};
