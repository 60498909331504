import { useForm } from '@kanda-libs/ks-component-ts';
import type { Job } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { CreateJobContextValue } from 'pages/CreateJob/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SEARCH_OPTIONS, SKELETON_DATA } from './Customer-constants';
import { formatCustomers } from './Customer-functions';

import { useFuse } from '../../../../../../hooks';

const ContainerComponent = ({ children }) => {
  // DEV_NOTE: add this back in to enable customer search
  const data = useCallback(() => [], []);

  const form = useForm({
    mode: 'onBlur',
  });

  const { setValue, reset } = form;

  // DEV_NOTE: add this back in to enable customer search
  // const showSearchBar = data && data?.length !== 0;

  const [showResults, setShowResults] = useState(false);

  const {
    data: formData,
    setData,
    currentStepIndex,
    setCurrentStepIndex,
    handleContinue,
  } = useCreateJobContextValue();

  const skipDeposit = formData?.company?.skip_deposit === 'Yes';

  const defaultValues = useMemo(() => {
    if (!formData?.job) return null;
    return {
      job: formData.job,
    };
  }, [formData]);

  const onSubmit = useCallback(
    (submittedData: CreateJobContextValue) => {
      if (!skipDeposit) {
        setData({
          ...formData,
          job: {
            ...(formData.job as Job),
            customer: submittedData.job?.customer,
          },
        });
        if (setCurrentStepIndex) {
          setCurrentStepIndex((currentStepIndex as number) + 2);
        }

        return;
      }
      handleContinue({
        ...formData,
        job: {
          ...(formData.job as Job),
          customer: submittedData.job?.customer,
        },
      });
    },
    [
      formData,
      handleContinue,
      skipDeposit,
      setData,
      currentStepIndex,
      setCurrentStepIndex,
    ],
  );

  const formattedData = useMemo(() => formatCustomers(data), [data]);

  const { hits, handleSearch, query, setQuery } = useFuse(
    formattedData,
    SEARCH_OPTIONS,
    {
      initialQuery: formData?.customerQuery,
    },
  );

  const isLoading = !data;

  const results = isLoading ? SKELETON_DATA : hits;

  const searchWords = useMemo(() => query.split(' '), [query]);

  const nextButtonProps = { submit: true };

  /**
   * Handles select customer
   * @param {Object} customer
   */
  const handleSelect = useCallback(
    (customer) => {
      setValue('job.customer', customer.raw);
      setShowResults(false);
    },
    [setValue],
  );

  const showSearch = useCallback(() => {
    setShowResults(true);
  }, []);

  const hideSearch = useCallback(() => {
    setShowResults(false);
    setQuery('');
  }, [setQuery]);

  const searchProps = {
    showSearch,
  };

  const headerProps = {
    handleSearch,
    hideSearch,
    defaultValue: query === formData?.customerQuery ? query : undefined,
  };

  const searchResultsProps = {
    results,
    searchWords,
    isLoading,
    handleSelect,
    query,
    addCustomer: hideSearch,
  };

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    form,
    isLoading,
    onSubmit,
    showSearchBar: false,
    nextButtonProps,
    searchProps,
    headerProps,
    showResults,
    searchResultsProps,
  });
};

ContainerComponent.displayName = 'CreateJob-Customer-Mobile-container';

export default ContainerComponent;
