import { useCallback } from 'react';
import { getFlagKey, getFlagValue } from './helpers';
import useCurrentCompany from '../useCurrentCompany';

/**
 * Provides a session storage flag and method for enabling the skipping of uploading
 * an insurance document. The user should be prompted to fill this out on every session
 * but have the option to skip, which is what this hook provides
 * @returns {{onSkipInsurance: onSkipInsurance, skipInsurance: boolean}}
 */
export default function useSkipInsuranceFlag() {
  const { company, isLoading } = useCurrentCompany();

  const onSkipInsurance = useCallback(() => {
    if (company?.id && !isLoading) {
      localStorage.setItem(getFlagKey(company.id), Date.now().toString());
    }
  }, [company?.id, isLoading]);

  return {
    skipInsurance: company && getFlagValue(company.id),
    onSkipInsurance,
  };
}
