import { Layout } from '@kanda-libs/ks-design-library';

import Header from './Header';

interface ReferralsMobileLayoutProps {
  children: React.ReactNode;
}

const ReferralsMobileLayout: React.FunctionComponent<ReferralsMobileLayoutProps> =
  function ({ children }) {
    return (
      <Layout stickyFooter noBorder stickyHeader scrollTop header={<Header />}>
        <div className="flex flex-col -mt-4">{children}</div>
      </Layout>
    );
  };

export default ReferralsMobileLayout;
