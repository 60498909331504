/* eslint import/no-cycle: 0 */

import Container from './Provider-container';

import Context from '../context';
import type { LineItemProps } from '../../LineItem-view';

export interface ViewComponentProps extends LineItemProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({ children, ...restProps }: ViewComponentProps) => (
  <Container {...restProps}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.displayName = 'LineItem-Context-Provider-view';

export default ViewComponent;
