export const DESKTOP_CLASS_NAMES = {
  wrapper:
    'flex flex-col items-center justify-center md:p-12 md:max-w-120 md:mx-auto',
  title: 'mb-2 text-28-32-em',
  subTitle:
    'mt-4 mb-10 text-16-24 text-neutral-600 text-lg max-w-lg text-center',
  continue: 'mb-10 text-style-f-em text-neutral-600 text-lg',
};

export const MOBILE_CLASS_NAMES = {
  wrapper: 'flex flex-col items-center justify-center',
  title: 'mb-10 text-28-32-em text-center',
  subTitle:
    'mb-10 text-16-24 text-neutral-600 text-lg max-w-lg text-center px-2',
  continue: 'mb-10 text-style-f-em text-neutral-600 text-lg',
};

export const SKELETONS = {
  title: {
    height: 32,
    width: 230,
  },
  secondTitle: {
    height: 32,
    width: 360,
  },
};
