import { Header, Layout, Logo } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import type { EnterpriseRatesLayoutProps } from '.';
import BookCallButton from './BookCallButton';

const EnterpriseRatesLayoutMobile: FunctionComponent<EnterpriseRatesLayoutProps> =
  function ({ children, footer }) {
    return (
      <Layout
        noBorder
        footer={<>{footer}</>}
        header={
          <Header.Base lines hideNumber>
            <div className="flex flex-row w-full justify-between">
              <Logo height={20} className="my-auto" />
              <BookCallButton />
            </div>
          </Header.Base>
        }
      >
        <>{children}</>
      </Layout>
    );
  };

export default EnterpriseRatesLayoutMobile;
