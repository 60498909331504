import {
  useWatch,
  type LabelProps,
  type SelectOption,
} from '@kanda-libs/ks-component-ts';
import { useCurrentCompany } from 'hooks';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useMemo } from 'react';
import { LABEL_PROPS, PROPS } from './constants';
import { checkWorkTypeNoRates } from './helpers';

interface WorkTypeFieldHook {
  props: {
    name: string;
    options: SelectOption[];
    disabled: boolean;
  };
  label: LabelProps;
  showNoRates: boolean;
}

export default function useWorkTypeField(): WorkTypeFieldHook {
  const { data } = useCreateJobContextValue();
  const { company, isLoading } = useCurrentCompany();
  const workType = useWatch({
    name: 'job.work_type',
  });

  const example = useMemo(() => data?.job?.flow_type === 'example', [data]);

  const showNoRates = useMemo(() => {
    if (isLoading || !company) return false;
    return checkWorkTypeNoRates(company, workType);
  }, [isLoading, company, workType]);

  return {
    props: {
      name: PROPS.name,
      options: PROPS.options,
      disabled: example,
    },
    label: {
      ...LABEL_PROPS,
      helperText: example
        ? '(can be changed when creating a live job)'
        : LABEL_PROPS.helperText,
    },
    showNoRates,
  };
}
