import { type FunctionComponent } from "react";
import { Text } from "@kanda-libs/ks-design-library";

import { CLASS_NAMES } from "./constants";

interface NumberCardProps {
  title: string;
  number: number;
  formatter?: (value: number) => string;
  isLoading?: boolean;
}

const NumberCard: FunctionComponent<NumberCardProps> = function ({
  title,
  number,
  formatter = (value: number) => String(value),
  isLoading = false,
}) {
  return (
    <div className={CLASS_NAMES.container}>
      <Text text={title} isLoading={isLoading} className={CLASS_NAMES.title} />
      <Text
        text={formatter(number)}
        isLoading={isLoading}
        className={CLASS_NAMES.count}
      />
    </div>
  );
};

export default NumberCard;
