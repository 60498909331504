import { PHONE_REGEX } from '../../../constants';

export const EMAIL_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'email',
  label: 'Email Address',
  placeholder: 'Their email address',
};

export const MOBILE_PROPS = {
  type: 'phone',
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Mobile Number',
  placeholder: 'Their mobile number',
  validation: {
    required: {
      value: true,
      message: 'Phone number is required',
    },
    pattern: {
      value: PHONE_REGEX,
      message: 'Phone number must be a valid UK phone number',
    },
  },
};

export const BASE_USER = {
  first_name: '',
  last_name: '',
  role: 'company-staff',
  email: '',
  state: 'new',
};
