export const getAllDirectorsAreNotMe = (directors, currentUserEmail) => {
  const filteredDirectors = directors.filter(
    (director) => director.email === currentUserEmail,
  );

  return filteredDirectors.length === 0;
};

export const currentUserDirectorIsVerified = (directors, currentUserEmail) => {
  const [currentUserDirector] = directors.filter(
    (director) => director.email === currentUserEmail,
  );

  if (!currentUserDirector) {
    return false;
  }

  return currentUserDirector.director_info.verification_status === 'verified';
};

export const getCompanyUserThatIsMe = (company, currentUserEmail) => {
  const [me] =
    company?.users?.filter((user) => user.email === currentUserEmail) || [];

  return me;
};
