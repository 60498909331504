import { type FunctionComponent } from 'react';

import { Tag, type TagColor } from '@kanda-libs/ks-design-library';
import type { FormattedLifecyle } from '../../helpers';

export interface ReferralLifecycleProps {
  lifecycle: FormattedLifecyle;
}

const getLifecycleColor = (lifecycle: FormattedLifecyle): TagColor => {
  if (lifecycle === 'sent') return 'violet';
  if (lifecycle === 'registered') return 'blue';
  if (lifecycle === 'subscribed') return 'green';
  return 'green';
};

const ReferralLifecycle: FunctionComponent<ReferralLifecycleProps> = function ({
  lifecycle,
}) {
  const color = getLifecycleColor(lifecycle);
  return <Tag color={color} label={lifecycle} variant="solid" />;
};

export default ReferralLifecycle;
