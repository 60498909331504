import { useState } from 'react';
import { getQueryParams, getQueryParamValue } from './helpers';

export type QueryParamHook = [string | null, (value: string) => void];

/**
 * Hook for reading from the query params
 */
export default function useQueryParam(
  key: string,
  defaultValue: string | null = null,
): QueryParamHook {
  const [query, setQuery] = useState(getQueryParamValue(key) || defaultValue);

  function setQueryParam(value) {
    setQuery(value);

    const queryParams = getQueryParams();

    if (value.trim() !== '') {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }

    if (typeof window !== 'undefined') {
      const { protocol, host, pathname } = window.location;
      const url = `${protocol}//${host}${pathname}?${query?.toString()}`;
      window.history.pushState({}, url);
    }
  }

  return [query, setQueryParam];
}
