import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';
import Container from './JobHeader-container';

const ViewComponent = ({}) => (
  <Container>
    {(props) => (
      <BreakPoints
        mobile={<Mobile {...props} />}
        desktop={<Desktop {...props} />}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Job-JobHeader-view';

export default ViewComponent;
