import type { Contract } from 'pages/Setup/Subscription/context';
import DocumentPages from './DocumentPages';

interface DocumentProps {
  contract: Contract;
}

const Document: React.FunctionComponent<DocumentProps> = function ({
  contract,
}) {
  return <DocumentPages contract={contract} />;
};

export default Document;
