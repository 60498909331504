import { useCallback, useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';

import {
  type StringIndexedObject,
  type ValidationItems,
  useWatch,
  useFormContext,
  AddressApiData,
  ValidationProps,
} from '@kanda-libs/ks-component-ts';
import type { Addresses } from 'types';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from '../../../../../../../../../utils';
import type { ContactDetailsProps } from './ContactDetails-view';

export interface ContainerComponentChildrenArgs {
  emailValidation: ValidationItems;
  isSubmitting: boolean;
  addresses: Addresses | null;
  postcodeName: string | null;
  setPostcodeName: (name: string) => void;
  postcodeCallback: (results: AddressApiData) => void;
  manual: boolean;
  setManual: (manual: boolean) => void;
  enterManually: () => void;
  selectProps: ValidationProps;
}

export interface ContainerComponentProps extends ContactDetailsProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  isSubmitting,
}: ContainerComponentProps) => {
  const [addresses, setAddresses] = useState<Addresses | null>(null);
  const [postcodeName, setPostcodeName] = useState<string | null>(null);
  const [manual, setManual] = useState(false);

  const { getValues, setValue } = useFormContext();

  const postcodeCallback = useCallback((results) => setAddresses(results), []);
  const enterManually = useCallback(() => setManual(true), []);

  const [address] = useWatch({
    name: ['company.contact_info.contact_address'],
  });

  const emailValidation = {
    validate: {
      value: {
        valid: (value) => EmailValidator.validate(value as unknown as string),
      },
      message: {
        valid: 'Email address is invalid',
      },
    },
  } as ValidationItems;

  const postcodeValidation = {
    validate: {
      value: () =>
        validateAddressSelect(
          getValues(),
          'company.contact_info.contact_address',
        ),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(postcodeValidation),
    validationErrors: getValidationErrors(postcodeValidation),
  } as unknown as ValidationProps;

  useEffect(() => {
    if (
      !address?.line_1 ||
      !addresses?.addresses ||
      addresses?.addresses?.length === 0
    )
      return;
    const match = addresses.addresses.findIndex(
      (addr) => (addr as StringIndexedObject).line_1 === address.line_1,
    );
    if (match === address?.selected) return;
    setValue('company.contact_info.contact_address.selected', match);
  }, [address, addresses, setValue]);

  return children({
    emailValidation,
    isSubmitting,
    addresses,
    postcodeName,
    setPostcodeName,
    postcodeCallback,
    manual,
    setManual,
    enterManually,
    selectProps,
  });
};

ContainerComponent.displayName =
  'CompanyInformation-Content-Form-ContactDetails-container';

export default ContainerComponent;
