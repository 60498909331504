import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import type {
  FinanceRate,
  Job,
  JobItem,
  WorkType,
} from '@kanda-libs/ks-frontend-services';
import { optionalToFixed } from '@kanda-utils/library';
import clsx from 'clsx';
import useCurrentCompany from 'hooks/useCurrentCompany';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useEffect, useMemo } from 'react';
import {
  calculateCost,
  calculateJobTotal,
  calculateLoanCosts,
  combinedFinanceOptionsAreSelected,
  financeOptionIsSelected,
  getDepositAmount,
  getDepositPercentage,
  getFinanceOptionDetails,
  getFinanceOptionName,
  removeCombinedOptionsFromSelectedOptions,
  removeOptionFromSelectedOptions,
  sortFinanceOptions,
} from 'utils';
import {
  getCombinedFinanceRatesDurationLabel,
  getFinanceRateLabel,
} from 'utils/FinanceRate-function';
import {
  depositAboveMinimum,
  financeRateIsSelectable,
  financeRateIsWithinBounds,
} from './helpers';

export interface OptionPropsHook {
  financeOption: FinanceRate;
  isLoading: boolean;
  onClick: (option: FinanceRate) => void;
  durationLabel: string;
  cost: number;
  monthly: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  interestLabel: string;
  name: string;
  comingSoon: boolean;
  disabled: boolean;
  selectable: boolean;
  rateWithinBounds: boolean;
  iconClasses: string;
  isSelected: boolean;
  icon: string;
  depositIsAboveMinimum: boolean;
}

export default function useOptionProps(
  financeOptions: FinanceRate[],
  label?: string,
  hideHandle = false,
  checkIfRateWithinBounds?: boolean,
): OptionPropsHook {
  const {
    data: { isLoading = false, job },
  } = useCreateJobContextValue();

  const { company } = useCurrentCompany();

  const { getValues, setValue } = useFormContext();

  const financeOption = financeOptions[0];

  const selectable = financeRateIsSelectable(financeOption);

  // DEV_NOTE: can be removed once etika live
  const comingSoon = financeOptions.some(
    (option: FinanceRate) => !option.enabled,
  );
  const { interest } = getFinanceOptionDetails(financeOption);

  const [
    formJob,
    formLineItems,
    formDeposit,
    selectedFinanceOptions,
    workType,
  ]: [Job, JobItem[], number, FinanceRate[], WorkType] = useWatch({
    name: [
      'job',
      'job.job_items',
      'job.deposit_value.amount',
      'job.finance_options',
      'job.work_type',
    ],
  });

  const [lineItems, deposit] = hideHandle
    ? [
        job?.job_items || formLineItems,
        job?.deposit_value?.amount || formDeposit,
      ]
    : [formLineItems, formDeposit];

  const skipDeposit = company?.skip_deposit === 'Yes';

  const currentDeposit = skipDeposit ? deposit || 0 : undefined;

  const depositPercentage = useMemo(
    () => getDepositPercentage(formJob),
    [formJob],
  );
  const depositAmount = useMemo(() => getDepositAmount(formJob), [formJob]);

  const { percentage, baseRate, baseFee } = calculateCost(
    lineItems,
    financeOption.fee,
    financeOption,
    currentDeposit,
  );

  const jobTotal = calculateJobTotal(lineItems);

  const financeOptionsCosts = financeOptions.map((option) => {
    const { total: optionCost } = calculateCost(
      lineItems,
      option.fee,
      option,
      currentDeposit,
    );
    return optionCost;
  });

  const financeOptionsMonthlys = financeOptions.map((option) => {
    const { monthly: optionMonthly } = calculateLoanCosts(
      jobTotal,
      option,
      currentDeposit,
    );

    return optionMonthly;
  });

  const rateWithinBounds = checkIfRateWithinBounds
    ? financeRateIsWithinBounds(
        financeOption,
        jobTotal.totalIncVat,
        depositPercentage * 100,
        depositAmount,
        workType,
      )
    : true;

  const depositIsAboveMinimum = checkIfRateWithinBounds
    ? depositAboveMinimum(financeOption, depositPercentage, depositAmount)
    : true;

  const combined = financeOptions.length > 1;

  const isSelected = combined
    ? combinedFinanceOptionsAreSelected(financeOptions, selectedFinanceOptions)
    : financeOptionIsSelected(financeOption, selectedFinanceOptions);

  const onClick = useCallback(
    (option: FinanceRate) => {
      if (!financeRateIsSelectable(option)) {
        return;
      }

      const currentFinanceOptions: FinanceRate[] = getValues(
        'job.finance_options',
      );

      if (!currentFinanceOptions) {
        const optionsToSubmit = combined ? financeOptions : [option];
        setValue('job.finance_options', optionsToSubmit);
        return;
      }

      /**
       * Logic to handle combined finance options and add / remove all combined
       * options from the selected options
       */
      if (combined) {
        if (
          combinedFinanceOptionsAreSelected(
            financeOptions,
            currentFinanceOptions,
          )
        ) {
          setValue(
            'job.finance_options',
            removeCombinedOptionsFromSelectedOptions(
              financeOptions,
              currentFinanceOptions,
            ),
          );
          return;
        }

        setValue('job.finance_options', [
          ...currentFinanceOptions,
          ...financeOptions,
        ]);
        return;
      }

      if (financeOptionIsSelected(option, currentFinanceOptions)) {
        const filtered = removeOptionFromSelectedOptions(
          option,
          currentFinanceOptions,
        );
        setValue('job.finance_options', sortFinanceOptions(filtered));
        return;
      }
      currentFinanceOptions.push(option);
      setValue(
        'job.finance_options',
        sortFinanceOptions(currentFinanceOptions),
      );
    },
    [getValues, setValue, combined, financeOptions],
  );

  const financeRateLabel = getFinanceRateLabel(financeOption);

  const defaultLabel = combined
    ? getCombinedFinanceRatesDurationLabel(financeOptions)
    : financeRateLabel;

  const durationLabel = label || defaultLabel;

  const disabled = comingSoon;

  const interestLabel = `${optionalToFixed(interest || 0)}%`;

  useEffect(() => {
    const options = job?.finance_options;
    const financeOptionElement = document.getElementById(
      getFinanceOptionName(financeOption),
    ) as HTMLInputElement;

    if (financeOptionElement) {
      financeOptionElement.checked = financeOptionIsSelected(
        financeOption,
        options,
      );
    }
  }, [job, financeOption]);

  const getIconColor = (withinBounds: boolean, aboveMinimum: boolean) => {
    if (!withinBounds) return 'text-neutral-600';
    if (!aboveMinimum) return 'text-orange-200';
    return 'text-green-600';
  };

  const iconClasses = clsx(
    'w-4 h-4 mr-2',
    getIconColor(rateWithinBounds, depositIsAboveMinimum),
  );

  const icon = useMemo(() => {
    if (!rateWithinBounds) return 'error';
    if (!depositIsAboveMinimum) return 'error';
    return 'check';
  }, [rateWithinBounds, depositIsAboveMinimum]);

  return {
    financeOption,
    isLoading,
    onClick,
    durationLabel,
    cost: Math.min(...financeOptionsCosts) || 0,
    monthly: Math.min(...financeOptionsMonthlys) || 0,
    percentage,
    baseRate,
    baseFee,
    interestLabel,
    name: getFinanceOptionName(financeOption),
    comingSoon,
    disabled,
    selectable,
    rateWithinBounds,
    depositIsAboveMinimum,
    isSelected,
    iconClasses,
    icon,
  };
}
