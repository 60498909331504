import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../../components';

const ViewComponent = ({}) => (
  <>
    <Helmet title="Account Settings" />
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </>
);

ViewComponent.displayName = 'AccountSettings-view';

export default ViewComponent;
