import { Button, Loader } from '@kanda-libs/ks-design-library';

import Container from './SendSSOEmailButton-container';

interface ViewComponentProps {
  /**
   * Email
   */
  email?: string;
}

const ViewComponent = ({ email }: ViewComponentProps) => (
  <Container email={email}>
    {({ onClick, isLoading, isSubmitting }) => (
      <>
        <Button.InlineLink
          id="click-to-resend-sso-email"
          variant="turquoise"
          disabled={isLoading || isSubmitting}
          onClick={onClick}
          label="No email? Click to resend"
          className="mx-auto"
        />
        <Loader isLoading={isSubmitting} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'SendSSOEmailButton-view';

ViewComponent.defaultProps = {
  email: undefined,
};

export default ViewComponent;
