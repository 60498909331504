import BoxedSidebarCard from '../../../../../components/BoxedSidebarCard';
import { SubscribeForm } from '../../../../MyAccount/components';

import SubscriptionSuccessSplashScreen from './SubscriptionSuccessSplashScreen';
import SubscriptionErrorSplashScreen from './SubscriptionErrorSplashScreen';
import SubscriptionPendingSplashScreen from './SubscriptionPendingSplashScreen';
import Sidebar from './Sidebar';

import useSubscriptionStatus from './useSubscriptionStatus';

const SetUpYourSubscription = ({}) => {
  const { showForm, success, error, pending, onRetryClick, onSuccessClick } =
    useSubscriptionStatus();

  return (
    <BoxedSidebarCard
      useLayout={false}
      sidebar={<Sidebar showForm={showForm} />}
    >
      <>
        {showForm && (
          <div className="flex flex-col -mt-4 md:mt-0 mb-6 md:mb-0">
            <div className="flex flex-col bg-blue-100 border border-neutral-300 px-4 py-5 rounded-xl mb-10 shadow-card">
              <span className="flex text-16-20-em md:text-20-24-em text-center text-blue-200 mx-auto">
                100% MONEY BACK GUARANTEE
              </span>
              <span className="flex text-16-20-em md:text-20-24-em text-center text-blue-200 mx-auto">
                if you aren’t approved to use Kanda
              </span>
            </div>
            <SubscribeForm showBulletPointList showReferralCode hideUSP />
          </div>
        )}
        {success && (
          <SubscriptionSuccessSplashScreen onSuccessClick={onSuccessClick} />
        )}
        {error && <SubscriptionErrorSplashScreen onRetryClick={onRetryClick} />}
        {pending && <SubscriptionPendingSplashScreen />}
      </>
    </BoxedSidebarCard>
  );
};

export default SetUpYourSubscription;
