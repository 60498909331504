import { cleanObject } from '../../../../../../../utils';

// Check if user has added an item title or price
const checkItem = (item) =>
  [!!item?.title, !!item?.price?.amount].filter(Boolean).length === 2;

// Check validity of all items
const checkItems = (items) => {
  const checked = items.map((item) => checkItem(item));
  return !!checked.filter((item) => item > 0).filter(Boolean).length;
};

export const shouldShowSave = (values) => {
  const job = values?.job;
  if (!job) return false;
  // Check at least title and one valid item
  const conditions = [!!job?.title, checkItems(job?.job_items)].filter(
    Boolean,
  ).length;
  if (conditions < 2) return false;
  return true;
};

export const formatPayload = (values) => {
  const items = values?.job?.job_items?.map((item) => ({
    ...item,
    ...(!item?.title && { title: 'No line item title added...' }),
    ...(!item?.price?.amount && {
      price: { amount: 0, currency: 'GBP' },
    }),
  }));
  const job = cleanObject({
    ...values?.job,
    job_items: items,
  });
  return job;
};
