export const ICON_PROPS = {
  mobile: {
    icon: 'job-color',
    size: 32,
    className: 'mb-4',
  },
  desktop: {
    icon: 'job-color',
    size: 75,
  },
};

export const HEADING = {
  text: 'Check your job',
  className: 'text-style-c text-neutral-900 mb-4 md:text-style-b ',
};

export const SUBHEADING = {
  text: "When you're happy, send it to your customer",
  className: 'text-style-g text-neutral-700 md:text-style-f',
};

export const CLASS_NAMES = {
  container: 'flex flex-col mt-4 mb-8 md:flex-row md:mt-0 md:mb-20',
  text: 'flex flex-col md:ml-6',
};
