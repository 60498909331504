import type { FunctionComponent } from 'react';
import clsx from 'clsx';
import { Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './constants';

export interface JobCreateInfoSidebarProps {
  title: string;
  description: string;
  isLoading?: boolean;
  hasBottomMargin?: boolean;
}

const JobCreateInfoSidebar: FunctionComponent<JobCreateInfoSidebarProps> =
  function ({
    title,
    description,
    isLoading = false,
    hasBottomMargin = false,
  }) {
    return (
      <div
        className={clsx(
          CLASS_NAMES.container,
          hasBottomMargin ? CLASS_NAMES.containerBottomMargin : '',
        )}
      >
        <Text
          className={CLASS_NAMES.title}
          isLoading={isLoading}
          text={title}
        />
        <Text
          className={CLASS_NAMES.description}
          isLoading={isLoading}
          text={description}
        />
      </div>
    );
  };

export default JobCreateInfoSidebar;
