import type {
  LabelProps,
  RichTextInputProps,
} from '@kanda-libs/ks-component-ts';

export const NOTE_FIELD_PROPS: Partial<RichTextInputProps> = {
  name: 'job.notes.0',
  placeholder: 'add a note...',
  inputHasFocusedBorder: false,
};

export const NOTE_LABEL_PROPS: LabelProps = {
  label: 'Customer note',
  helperText: '(Visible on quote to the customer)',
  inlineHelperText: true,
};
