import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface OnboardingHook {
  isLoading: boolean;
}

export default function useOnboarding(): OnboardingHook {
  const { company, isLoading } = useCurrentCompany();
  const { push } = useHistory();

  useEffect(() => {
    if (!company) return;
    if (['completed_setup', 'verified'].includes(company?.lifecycle || '')) {
      push(URLS.home);
    }
  }, [company, push]);

  return {
    isLoading,
  };
}
