import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Notes-container';
import { DESCRIPTION } from './Notes-constants';
import Wrapper from '../Wrapper';
import { Desktop } from './components';
import Note from './components/Note';

const ViewComponent = () => (
  <Container>
    {({ onClick, initialNotes }) => (
      <Wrapper description={DESCRIPTION} onEdit={onClick}>
        <BreakPoints
          desktop={<Desktop notes={initialNotes} />}
          mobile={<Note />}
        />
      </Wrapper>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Notes-view';

export default ViewComponent;
