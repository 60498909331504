import Content from '../../../../../../../Identify/Content';

const Identify = () => (
  <div>
    <Content />
  </div>
);

Identify.propTypes = {};

export default Identify;
