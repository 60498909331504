import { Content } from '../components';
import { DesktopLayout } from '../../components';

const ViewComponent = ({}) => (
  <DesktopLayout fullWidthContent>
    <Content />
  </DesktopLayout>
);

ViewComponent.displayName = 'NotificationPreferences-Content-view';

export default ViewComponent;
