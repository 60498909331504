import { useMemo } from 'react';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { getLabels, getButtonWidth } from './NextButton-functions';

const ContainerComponent = ({ children }) => {
  const { currentStepIndex, steps, handleSubmit, nextStep } =
    useOnboardingContextValue();

  const totalSteps = steps.length;

  const labels = getLabels(currentStepIndex, totalSteps);

  const width = useMemo(
    () => getButtonWidth(currentStepIndex, totalSteps),
    [currentStepIndex, totalSteps],
  );

  const onClick =
    currentStepIndex === steps.length - 1 ? handleSubmit : nextStep;

  return children({
    width,
    onClick,
    labels,
  });
};

ContainerComponent.displayName = 'NextButton-container';

export default ContainerComponent;
