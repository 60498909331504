import { Option } from './components';
import Container from './PaymentMethods-container';

import { PAYMENT_OPTIONS } from '../PaymentAndFinanceOptions-constants';

const ViewComponent = () => (
  <Container>
    {() => (
      <>
        {PAYMENT_OPTIONS.map((option) => (
          <Option key={option.title} {...option} />
        ))}
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentAndFinanceOptions-PaymentMethods-view';

export default ViewComponent;
