import type { FunctionComponent } from 'react';

import {
  Button,
  Card,
  Icon,
  Text,
  useIsDesktop,
} from '@kanda-libs/ks-design-library';
import type { WelcomeSkipBanner } from '@kanda-libs/ks-frontend-services/src/generated/components/schemas/WelcomeSkipBanner';

import { CLASS_NAMES, ITEMS } from './constants';
import TrainingItem from './TrainingItem';
import useReverify from './useReverify';
import useTrainingBanner from './useTrainingBanner';

export interface TrainingBannerItem {
  url?: string;
  title: string;
  description: string;
  welcomePackKey: keyof WelcomeSkipBanner;
  hideComplete?: boolean;
}

export interface TrainingBannerProps {
  icon?: string;
  className?: string;
}

const TrainingBanner: FunctionComponent<TrainingBannerProps> = function ({
  icon = 'rocket',
  className = '',
}) {
  const { showBanner, welcomePack, someDirectorsNotVerified } =
    useTrainingBanner();

  const { onClick, isSubmitting } = useReverify();

  const isDesktop = useIsDesktop();

  if (!showBanner) return <></>;

  return (
    <Card
      className={[CLASS_NAMES.wrapper, className].filter(Boolean).join(' ')}
      padding=" "
    >
      <div className={CLASS_NAMES.header}>
        <Icon icon={icon} className={CLASS_NAMES.icon} size={56} />
        <Text
          text="Welcome to Kanda! Get yourself set up to offer finance."
          className={CLASS_NAMES.title}
        />
      </div>
      <div className={CLASS_NAMES.items}>
        {someDirectorsNotVerified && (
          <TrainingItem
            key="director-verification"
            title="Director verification"
            description="Some directors haven't completed their verification steps"
            welcomePackKey="director_verification"
            hideComplete
            completed={
              (welcomePack as WelcomeSkipBanner).director_verification === 'yes'
            }
            cta={
              !isSubmitting ? (
                <Button.Text
                  id="training-banner-resend-email"
                  label={isDesktop ? 'Resend emails' : 'Resend'}
                  size={40}
                  onClick={onClick}
                  disabled={isSubmitting}
                />
              ) : (
                <></>
              )
            }
          />
        )}
        {ITEMS.map((item) => (
          <TrainingItem
            key={`item-${item.title}`}
            {...item}
            completed={welcomePack[item.welcomePackKey] === 'yes'}
          />
        ))}
      </div>
    </Card>
  );
};

export default TrainingBanner;
