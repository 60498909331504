import { Button, Card, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from 'config';
import { BUTTON_PROPS, CLASS_NAMES, ICON_PROPS, TITLE } from './constants';

const Placeholder: React.FunctionComponent = function () {
  return (
    <div className="flex my-auto mt-12">
      <Card>
        <div className={CLASS_NAMES.content}>
          <Icon {...ICON_PROPS} />
          <p className={CLASS_NAMES.title}>{TITLE}</p>
          <Link to={URLS.referralsForm}>
            <Button.Text {...BUTTON_PROPS} />
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default Placeholder;
