import { useFormContext } from '@kanda-libs/ks-component-ts';
import { removeTrailingWhitespace } from '../../../../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const onBlur = (name) =>
    setValue(name, removeTrailingWhitespace(getValues(name)));

  return children({
    onBlur,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Customer-Field-Name-container';

export default ContainerComponent;
