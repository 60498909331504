import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import { extractErrorMessage } from 'common/helpers';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany, useMe } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { isSessionError } from './helpers';
import type { FieldValues } from './types';

export interface ClaimAccountFormHook {
  form: UseFormReturn<FieldValues>;
  onSubmit: (formValues: StringIndexedObject<FieldValues>) => void;
  isSubmitting: boolean;
  isLoading: boolean;
  disabled: boolean;
}

export default function useClaimAccountForm(): ClaimAccountFormHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { company } = useCurrentCompany();
  const isLoading = useSelector(selectors.getIsLoading);

  const dispatch = useAppDispatch();
  const { me, revalidateMe } = useMe();
  const { mutate: updatePassword } = useMutate(
    FirebaseAuthService.updatePassword,
  );
  const { mutate: login } = useMutate(
    FirebaseAuthService.signInWithEmailAndPassword,
  );
  const { push } = useHistory();
  const { showError } = useToast();
  const onError = useApiError(
    'Error sending new login email - please contact Kanda',
  );

  const form = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: {
      password_strength: 0,
    },
  });

  const strength = form.watch('password_strength');

  const onSessionTimeout = useCallback(() => {
    const body = {
      email: me?.email || '',
      continue_url: window.location.href,
    };

    dispatch(
      actions.infoAuth({
        body,
        onError: (error) => {
          setIsSubmitting(false);
          onError(error);
        },
        onSuccess: () => {
          setIsSubmitting(false);
          push(URLS.claimAccountResent);
        },
      }),
    );
  }, [dispatch, me, push, onError]);

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<FieldValues>) => {
      const newPassword = formValues.password;
      if (!newPassword) return;
      setIsSubmitting(true);
      updatePassword(newPassword).then(({ error: signUpError }) => {
        if (signUpError) {
          if (isSessionError(signUpError)) {
            showError('Session expired - sending new login email');
            onSessionTimeout();
            return;
          }
          setIsSubmitting(false);
          const message = extractErrorMessage(signUpError as string);
          showError(message || 'Sign up error');
          return;
        }
        login(
          {
            email: me?.email,
            password: newPassword,
          },
          false,
        ).then(({ error: loginError }) => {
          if (loginError) {
            setIsSubmitting(false);
            showError('Error logging in');
            push(URLS.login);
            return;
          }
          revalidateMe().then(() => {
            setIsSubmitting(false);
            if (company) {
              push(URLS.home);
              return;
            }
            push(URLS.setupCompany);
          });
        });
      });
    },
    [
      updatePassword,
      showError,
      onSessionTimeout,
      revalidateMe,
      login,
      me,
      push,
      company,
    ],
  );

  const disabled = useMemo(() => {
    if (!strength) return true;
    return strength <= 2;
  }, [strength]);

  return {
    form,
    onSubmit,
    isSubmitting,
    isLoading: !me || isLoading,
    disabled,
  };
}
