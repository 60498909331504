import { Icon, Text } from '@kanda-libs/ks-design-library';

import Container from './PaymentOptions-container';

import { CLASS_NAMES } from '../../Desktop-constants';

const ViewComponent = () => (
  <Container>
    {({ options }) =>
      options.length > 0 ? (
        <div className={CLASS_NAMES.card}>
          {options.map(({ icon, title }) => (
            <div key={title} className={CLASS_NAMES.panel}>
              <Icon
                icon={icon}
                size={20}
                className="mr-3 my-auto"
                stroke="neutral-600"
              />
              <Text text={title} className="text-14-22-em text-neutral-600" />
            </div>
          ))}
        </div>
      ) : null
    }
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-PaymentOptions-view';

export default ViewComponent;
