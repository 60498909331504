import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  FirebaseAuthService,
  logout as reduxLogout,
  reset,
  useCurrentUser,
  useMutate,
  useSignIn,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useGtag } from 'hooks';
import useApiError from 'hooks/useApiError';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { formatSignUpPayload, getReferrer } from './helpers';
import type { FieldValues } from './types';

export interface SignUpProviderProps {
  children: ReactNode;
}

export type Display = 'buttons' | 'form' | 'success';

export interface SignUpContextType {
  display: Display;
  setFormDisplay: (newDisplay: Display) => void;
  isSubmitting: boolean;
  isLoading: boolean;
  disabled: boolean;
  referrerEmail?: string;
  setDisabled: (value: boolean) => void;
  signInWithGoogle: () => void;
  signInWithFacebook: () => void;
  signUpWithEmail: (values: FieldValues) => void;
}

export const SignUpContext = createContext<SignUpContextType>({
  display: 'buttons',
  setFormDisplay: () => {},
  isSubmitting: false,
  isLoading: false,
  disabled: false,
  referrerEmail: undefined,
  setDisabled: () => {},
  signInWithGoogle: () => {},
  signInWithFacebook: () => {},
  signUpWithEmail: () => {},
});

export const useSignUpContext = () => useContext(SignUpContext);

const SignUpProvider: FunctionComponent<SignUpProviderProps> = function ({
  children,
}) {
  const [display, setDisplay] = useState<Display>('buttons');
  const [disabled, _setDisabled] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { mutate: logout } = useMutate(FirebaseAuthService.logout);
  const { push, location } = useHistory();
  const { showError } = useToast();
  const { trackException } = useGtag();
  const dispatch = useAppDispatch();
  const { setUserId } = useAmplitude();
  const onError = useApiError('Error signing up');
  const { mutate: googleSignIn } = useSignIn('googlePopup');
  const { mutate: fbSignIn } = useSignIn('facebook');
  const { isUserLoggedIn } = useCurrentUser();
  const appIsLoading = useSelector(selectors.getIsLoading);

  const params = useMemo(() => {
    if (!location) return new URLSearchParams();
    if (!location?.search) return new URLSearchParams();
    return new URLSearchParams(location.search);
  }, [location]);

  const referrerEmail = useMemo((): string | undefined => {
    if (!location) return undefined;
    const urlParams = location.search
      .replace('?', '')
      .split('&')
      .reduce((parts: StringIndexedObject, part: string) => {
        const split = part.split('=');
        return {
          ...parts,
          [split[0]]: split[1],
        };
      }, {});
    return urlParams?.email || undefined;
  }, [location]);

  const setFormDisplay = useCallback(
    (newDisplay: Display) => setDisplay(newDisplay),
    [],
  );

  const handleLogout = useCallback(
    (redirect = true) =>
      new Promise<void>((resolve) => {
        logout(false).then(() => {
          dispatch(reduxLogout());
          dispatch(reset());
          if (redirect) push(URLS.login);
          resolve();
        });
      }),
    [logout, push, dispatch],
  );

  const signInWithGoogle = useCallback(() => {
    setIsSubmitting(true);
    handleLogout(false).then(() => {
      googleSignIn(false).then(({ error: googleSignInError }) => {
        setIsSubmitting(false);
        if (googleSignInError) {
          trackException(googleSignInError?.message || 'unknown error');
          showError('Google sign in error');
          return;
        }
        push(URLS.setupAccount);
      });
    });
  }, [handleLogout, googleSignIn, showError, push, trackException]);

  const signInWithFacebook = useCallback(async () => {
    setIsSubmitting(true);
    handleLogout(false).then(() => {
      fbSignIn(false).then(({ error: fbSignInError }) => {
        setIsSubmitting(false);
        if (fbSignInError) {
          trackException(fbSignInError?.message || 'unknown error');
          showError('Facebook sign in error');
          return;
        }
        push(URLS.setupAccount);
      });
    });
  }, [handleLogout, fbSignIn, showError, push, trackException]);

  const signUpWithEmail = useCallback(
    (formValues: FieldValues) => {
      const referrer = getReferrer(
        params.get('utm_source') || undefined,
        referrerEmail,
      );
      const body = formatSignUpPayload(formValues, referrer);
      setIsSubmitting(true);
      handleLogout(false).then(() => {
        dispatch(
          actions.postMe({
            body,
            protectedRequest: true,
            params: {
              x_kanda_protected: 'yes',
            },
            onError: (error) => {
              setIsSubmitting(false);
              onError(error);
            },
            onSuccess: (signUpBody) => {
              setIsSubmitting(false);
              if (signUpBody?.id) setUserId(signUpBody.id);
              setDisplay('success');
            },
          }),
        );
      });
    },
    [handleLogout, params, referrerEmail, dispatch, onError, setUserId],
  );

  const setDisabled = useCallback((val: boolean) => _setDisabled(val), []);

  const isLoading = useMemo(() => {
    if (isUserLoggedIn === null) return true;
    if (!isUserLoggedIn) return false;
    if (appIsLoading) return true;
    return false;
  }, [appIsLoading, isUserLoggedIn]);

  const values = useMemo(
    () => ({
      display,
      setFormDisplay,
      disabled,
      setDisabled,
      isSubmitting,
      isLoading,
      referrerEmail,
      signInWithGoogle,
      signInWithFacebook,
      signUpWithEmail,
    }),
    [
      display,
      setFormDisplay,
      disabled,
      setDisabled,
      isSubmitting,
      isLoading,
      referrerEmail,
      signInWithGoogle,
      signInWithFacebook,
      signUpWithEmail,
    ],
  );

  return (
    <SignUpContext.Provider value={values}>{children}</SignUpContext.Provider>
  );
};

export default SignUpProvider;
