import { Icon, Logo } from '@kanda-libs/ks-design-library';

type Widths = 80 | 96 | 120 | 160;

interface SplashScreenProps {
  logo?: boolean;
  icon: string;
  iconStroke?: string;
  iconClassName?: string;
  iconSize?: number;
  title: string;
  subText: string | JSX.Element;
  boldSubText?: string;
  cta?: JSX.Element;
  append?: JSX.Element;
  width?: Widths;
  contentWidth?: Widths;
  content?: JSX.Element;
}

const WIDTHS = {
  80: 'max-w-80',
  96: 'max-w-96',
  120: 'max-w-120',
  160: 'max-w160',
};

const SplashScreen = ({
  logo,
  icon,
  iconClassName,
  iconStroke,
  iconSize,
  title,
  subText,
  boldSubText,
  cta,
  append,
  width = 80,
  contentWidth = 80,
  content,
}: SplashScreenProps) => (
  <div
    className={`flex flex-col m-auto w-full ${WIDTHS[width]} text-center items-center justify-center`}
  >
    {logo && (
      <div className="mb-10">
        <Logo height={32} />
      </div>
    )}
    {!logo && (
      <Icon
        icon={icon}
        size={iconSize}
        stroke={iconStroke}
        className={`mx-auto mb-8 ${iconClassName}`}
      />
    )}
    <h1 className="text-24-28-em mb-4">{title}</h1>
    <div className={`flex flex-col w-full ${WIDTHS[contentWidth]} mx-auto`}>
      {content && <div className="mt-4 mb-8">{content}</div>}
      <span className="text-16-24 text-neutral-600">
        {subText}
        {boldSubText && <b> {boldSubText}</b>}
      </span>
      {cta && <div className="mt-10">{cta}</div>}
      {append}
    </div>
  </div>
);

SplashScreen.defaultProps = {
  logo: false,
  iconClassName: '',
  iconSize: 104,
  cta: null,
  append: null,
  boldSubText: null,
  content: null,
  width: 96,
  contentWidth: 96,
};

export default SplashScreen;
