import { Card, Icon } from '@kanda-libs/ks-design-library';

import {
  TITLE,
  DESCRIPTION,
  CLASS_NAMES,
  ICON_PROPS,
} from './NoResults-constants';

const ViewComponent = ({}) => (
  <Card>
    <div className={CLASS_NAMES.content}>
      <Icon {...ICON_PROPS} />
      <p className={CLASS_NAMES.title}>{TITLE}</p>
      <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
    </div>
  </Card>
);

ViewComponent.displayName = 'Jobs-Desktop-JobsPlaceholder-view';

export default ViewComponent;
