import { Button } from '@kanda-libs/ks-design-library';

import { IDS } from './Options-constants';
import Container from './Options-container';
import Modal from './Modal';

export interface ViewComponentProps {
  /**
   * Index of the item in the array
   */
  index: number;
  /**
   * Function to remove an item
   */
  removeItem: () => void;
  /**
   * State of whether to show remove button or not
   */
  showRemove: boolean;
}

const ViewComponent = ({
  showRemove,
  index,
  removeItem,
}: ViewComponentProps) => (
  <Container index={index}>
    {({ handleShowModal, modalId }) => (
      <>
        {showRemove && (
          <Button.Icon
            id={IDS.button}
            onClick={handleShowModal}
            variant="ghost-grey"
            size="32-20"
            icon="more"
          />
        )}
        <Modal modalId={modalId} removeItem={removeItem} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Options-view';

export default ViewComponent;
