import { useContext } from 'react';

import Context from '../../../../Context';

const ContainerComponent = ({ children }) => {
  const { filter, updateFilter } = useContext(Context);

  const activeFilters = [
    filter?.status ? 'status' : null,
    filter?.financeStatus ? 'financeStatus' : null,
    filter?.price ? 'price' : null,
  ].filter(Boolean);

  const onClearAll = () => {
    updateFilter('status', '');
    updateFilter('financeStatus', '');
    updateFilter('price', '');
  };

  return children({ activeFilters, onClearAll });
};

ContainerComponent.displayName = 'ActiveFilters-container';

export default ContainerComponent;
