import type { ButtonTextVariant } from '@kanda-libs/ks-design-library';

export const FILTER_TYPE = {
  STATUS: 'status',
  FINANCE_STATUS: 'financeStatus',
  PRICE: 'price',
};

export const BUTTON_PROPS = {
  id: 'home-filters-clear',
  label: 'Clear all',
  size: 32,
  className: 'mb-2',
  variant: 'ghost' as ButtonTextVariant,
};
