import type { FunctionComponent } from 'react';
import { Layout } from '@kanda-libs/ks-design-library';

import { MultiStepFormFooter } from '@kanda-libs/ks-component-ts';
import Container from './Page-container';
import { Header, CloseModal } from './components';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * Custom footer
   */
  footer?: React.ElementType;
  /**
   * Layout props
   */
  layoutProps?: object;
  /**
   * Simple header
   */
  simpleHeader?: boolean;
  /**
   * Show save button in header
   */
  saveButton?: boolean;
  /**
   * Next button
   */
  nextButton?: React.ReactElement;
  /**
   * Next button props
   */
  nextButtonProps?: {
    className?: string;
    icon?: string;
    iconProps?: {
      className?: string;
      stroke?: string;
      flip?: boolean;
      width?: number;
      height?: number;
      size?: number;
      fill?: string;
      isLoading?: boolean;
    };
    variant?: 'solid' | 'gradient' | 'ghost' | 'outline';
    disabled?: boolean;
    submit?: boolean;
    left?: boolean;
    size?: 'custom' | 48 | 40 | 32;
    label?: JSX.Element[] | JSX.Element;
    isLoading?: boolean;
    iconSpacing?: number;
  };
  /**
   * Prev button props
   */
  prevButtonProps?: {
    children?: JSX.Element[] | JSX.Element;
    id?: string;
    className?: string;
    icon?: string;
    variant?: 'turquoise' | 'light-turquoise' | 'solid-grey' | 'ghost-grey';
    size?: number | '48-20' | '40-20' | '32-20' | '32-16' | '28-16';
    submit?: boolean;
    isLoading?: boolean;
    iconProps?: {
      className?: string;
      stroke?: string;
      flip?: boolean;
      width?: number;
      height?: number;
      size?: number;
      fill?: string;
      isLoading?: boolean;
    };
    onClick?(...args: unknown[]): unknown;
  };
  stickyHeader?: boolean;
  nextSubmit?: boolean;
}

const ViewComponent = ({
  children,
  footer: Footer,
  simpleHeader,
  saveButton,
  layoutProps,
  ...footerProps
}: ViewComponentProps) => {
  const FooterTag = Footer as FunctionComponent<typeof footerProps>;

  return (
    <Layout
      stickyFooter
      footer={<FooterTag {...footerProps} />}
      header={<Header simple={simpleHeader} saveButton={saveButton} />}
      noBorder={simpleHeader}
      {...layoutProps}
    >
      <CloseModal />
      <Container>{children}</Container>
    </Layout>
  );
};

ViewComponent.displayName = 'CreateJob-Page-view';

ViewComponent.defaultProps = {
  children: null,
  footer: MultiStepFormFooter,
  layoutProps: undefined,
  nextButton: undefined,
  nextButtonProps: undefined,
  prevButtonProps: undefined,
  simpleHeader: undefined,
  saveButton: undefined,
};

export default ViewComponent;
