import {
  Button,
  Header,
  Icon,
  SkeletonLoader,
} from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import { CLASS_NAMES, DEFAULT_AVATAR_ICON_PROPS, SKELETONS } from './constants';
import useNavMenuHeader from './useNavMenuHeader';

export interface NavMenuHeaderProps {
  onClose: () => void;
}

const NavMenuHeader: FunctionComponent<NavMenuHeaderProps> = function ({
  onClose,
}) {
  const { isLoading } = useNavMenuHeader();
  return (
    <Header.Base
      help={false}
      hideNumber
      options={[
        <Button.Icon
          id="nav-menu-header-close"
          key="close"
          onClick={onClose}
          icon="close"
        />,
      ]}
    >
      <div className={CLASS_NAMES.header.container}>
        <div className={CLASS_NAMES.header.defaultAvatar}>
          <Icon {...DEFAULT_AVATAR_ICON_PROPS} />
        </div>
        <div className={CLASS_NAMES.header.infoContainer}>
          <p className={CLASS_NAMES.header.companyName}>
            <SkeletonLoader
              isLoading={isLoading}
              {...SKELETONS.companyName}
              afterLoading="My Kanda"
            />
          </p>
        </div>
      </div>
    </Header.Base>
  );
};

export default NavMenuHeader;
