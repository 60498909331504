import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { FIELD_NAMES } from './NameModal-constants';
import useMe from '../../../../../../hooks/useMe';

const ContainerComponent = ({ children, handleClose }) => {
  const dispatch = useAppDispatch();
  const { me, revalidateMe, isValidating: isLoading } = useMe();
  const isSubmitting = useSelector(selectors.authUser.getIsSubmitting);

  const { showSuccess } = useToast();
  const onError = useApiError('Error updating profile');

  const form = useForm({ mode: 'onBlur' });

  const { setValue } = form;

  const onSubmit = useCallback(
    (formValues) => {
      dispatch(
        actions.putMe({
          body: {
            name: `${formValues.firstName} ${formValues.lastName}`,
          },
          onError,
          onSuccess: () => {
            showSuccess('Profile updated');
            revalidateMe();
            handleClose();
          },
        }),
      );
    },
    [dispatch, onError, showSuccess, handleClose, revalidateMe],
  );

  const nameRef = useRef<string | null>(null);

  useEffect(() => {
    if (!me || !me?.name) return;
    if (me.name === nameRef.current) return;
    nameRef.current = me.name;
    const {
      0: firstName,
      length: n,
      [n - 1]: lastName,
    } = (me.name || '').split(' ');
    if (firstName) setValue(FIELD_NAMES.firstName, firstName);
    if (lastName) setValue(FIELD_NAMES.lastName, lastName);
  }, [me, setValue]);

  return children({
    form,
    onSubmit,
    isSubmitting,
    isLoading,
  });
};

ContainerComponent.displayName =
  'MyAccount-AccountSettings-NameModal-container';

export default ContainerComponent;
