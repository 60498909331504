import { SkeletonLoader } from '@kanda-libs/ks-design-library';
import { ReactComponent as FacebookLogo } from 'assets/f-logo.svg';

interface FacebookButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const FacebookButton: React.FunctionComponent<FacebookButtonProps> = function ({
  onClick,
  isLoading = false,
  disabled = false,
}) {
  return (
    <SkeletonLoader
      isLoading={isLoading}
      width="100%"
      height={44}
      wrapperClassName="w-full"
      afterLoading={
        <button
          className="relative flex flex-row justify-center w-full h-12 p-3 rounded rounded-3xl bg-fb-blue"
          type="button"
          id="facebook-sign-in"
          disabled={disabled}
          onClick={onClick}
        >
          <FacebookLogo className="my-auto mr-3" />
          <p className="my-auto text-center text-16-20-em whitespace-nowrap text-neutral-000">
            Sign up with Facebook
          </p>
        </button>
      }
    />
  );
};

export default FacebookButton;
