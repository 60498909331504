import { Text } from '@kanda-libs/ks-design-library';

import Container from './Card-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ price, legacy, openChat }) => (
      <div className="flex flex-col p-5 md:p-6 border border-neutral-300 shadow-card rounded-xl mb-10 md:mb-16">
        <Text
          text="Kanda Premium - Subscribed"
          className="text-14-22-em md:text-16-20-em text-neutral-600 mb-1"
        />
        {!legacy && (
          <Text
            text={price}
            className="text-14-22-em md:text-16-20-em text-green-400"
          />
        )}
        {legacy && (
          <div className="flex gap-x-1">
            <Text
              text="Contact us"
              className="text-14-22-em md:text-16-20-em text-green-400 underline cursor-pointer"
              onClick={() => {
                openChat();
              }}
            />
            <Text
              text=" to discuss your subscription"
              className="text-14-22-em md:text-16-20-em text-green-400"
            />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Subscription-Content-Card-view';

export default ViewComponent;
