import { ONBOARDING_STEPS } from '../../../Onboarding-constants';

export const TITLE = 'Apply to offer finance in 5 minutes';

export const CLASS_NAMES = {
  page: 'flex flex-1 flex-col justify-center items-center h-full',
  content: 'flex flex-col items-center md:pb-10 md:mb-24',
  title: 'text-24-28-em text-neutral-900 text-center mb-10 md:mb-6',
  nextStep:
    'text-16-24-em text-neutral-600 text-center mb-3 md:mb-6 font-semibold',
  description: 'text-16-24 text-neutral-600 text-center',
  icon: 'mx-auto mb-8 min-h-6',
};

export const SUBSCRIPTION_KEY = 'subscription';
export const ACCOUNT_VERIFICATION_KEY = 'account-verification';
export const BUSINESS_DETAILS_KEY = 'business-details';

export const ONBOARDING_STEP_ITEMS = [
  {
    key: SUBSCRIPTION_KEY,
    title: 'Subscription',
    subText: 'Set up a direct debit for your monthly subscription',
    required: 'You’ll need: bank details and direct debit authority',
    onboardingStep: ONBOARDING_STEPS.SETUP_YOUR_SUBSCRIPTION,
  },
  {
    key: ACCOUNT_VERIFICATION_KEY,
    title: 'Account verification',
    subText: 'Have each director verify their identity',
    required: 'You’ll need: passport or drivers licence',
    onboardingStep: ONBOARDING_STEPS.VERIFICATION,
  },
  {
    key: BUSINESS_DETAILS_KEY,
    title: 'Business details',
    subText: 'Enter a few details about your business and trade',
    required: 'You’ll need: liability insurance policy',
    onboardingStep: ONBOARDING_STEPS.SETUP_COMPANY,
  },
];

export const PAY_MONTHLY_CODES = ['KANDAPM1296V1'];

export const PC_TEXT =
  'Kanda has sent you a contract to sign to confirm your monthly subscription.';
