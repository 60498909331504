import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';

export const COPIED_BUTTON_PROPS = {
  id: 'update-your-website-calculator-copy-code',
  icon: 'check',
  variant: 'turquoise' as ButtonLinkVariant,
  size: 14,
  left: true,
  label: 'Link Copied',
};
