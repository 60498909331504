import useWrapper from './useWrapper';

interface WrapperProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }) => {
  const { WrapperTag, wrapperProps } = useWrapper();
  return <WrapperTag {...wrapperProps}>{children}</WrapperTag>;
};

export default Wrapper;
