import { Field, FormTheme } from '@kanda-libs/ks-component-ts';

import { CLASS_NAMES } from './Desktop-constants';

export interface DesktopProps {
  notes?: string;
}

const ViewComponent = ({ notes = '' }: DesktopProps) => (
  <div className={CLASS_NAMES.container}>
    <FormTheme variant="inline">
      <Field.RichTextInput
        readOnly
        name="read-only-notes"
        placeholder="add a note..."
        inputHasFocusedBorder={false}
        initialValue={notes}
      />
    </FormTheme>
  </div>
);

ViewComponent.displayName = 'JobDetails-Notes-Desktop-view';

export default ViewComponent;
