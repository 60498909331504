import { useHomeContext } from 'pages/Home/context';
import { useMemo } from 'react';
import { SEGMENTS } from './constants';

export interface PieChartSegment {
  name: string;
  color: string;
  value: number;
  opacity?: number;
}

interface BreakdownPieChartHook {
  isLoading: boolean;
  segments: PieChartSegment[];
}

export default function useBreakdownPieChart(): BreakdownPieChartHook {
  const { isLoading, jobsSent, jobsApplied, jobsApproved } = useHomeContext();

  const notApplied = useMemo(() => {
    if (isLoading) return 0;
    return jobsSent - jobsApplied;
  }, [isLoading, jobsSent, jobsApplied]);

  const declined = useMemo(() => {
    if (isLoading) return 0;
    return jobsApplied - jobsApproved;
  }, [isLoading, jobsApplied, jobsApproved]);

  const segments: PieChartSegment[] = useMemo(
    () => [
      {
        ...SEGMENTS.notApplied,
        value: notApplied,
      },
      {
        ...SEGMENTS.approved,
        value: jobsApproved,
      },
      {
        ...SEGMENTS.declined,
        value: declined,
      },
    ],
    [notApplied, jobsApproved, declined],
  );

  return {
    isLoading,
    segments,
  };
}
