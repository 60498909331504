import { Wrapper } from '../components';
import OnboardingSteps from '../OnboardingSteps';

const Welcome: React.FunctionComponent = () => (
  <Wrapper>
    <OnboardingSteps />
  </Wrapper>
);

export default Welcome;
