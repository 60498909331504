import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { AUTH_FIELD_FIELD_TEXT } from './constants';

export interface CheckBoxesHook {
  fieldText: string;
}

export default function useCheckBoxes(): CheckBoxesHook {
  const { company } = useCurrentCompany();

  const fieldText = useMemo(() => {
    const name =
      company?.company_type === 'limited_company'
        ? company?.limited_company_info?.company_name || ''
        : company?.sole_trader_info?.trading_name || '';
    return AUTH_FIELD_FIELD_TEXT.replace('$COMPANY', name);
  }, [company]);

  return {
    fieldText,
  };
}
