import { Loader, PopoverMenu } from '@kanda-libs/ks-design-library';

import Container from './JobMenu-container';

interface ViewComponentProps {
  /**
   * Job id
   */
  jobId?: string;
  /**
   * Job id
   */
  status?: string;
  /**
   * Disabled
   */
  handleClose?(...args: unknown[]): unknown;
  /**
   * PDF download handler
   */
  downloadPdf?: (id: string) => void;
}

const ViewComponent = ({
  jobId,
  status,
  handleClose,
  downloadPdf,
}: ViewComponentProps) => (
  <Container jobId={jobId} status={status} downloadPdf={downloadPdf}>
    {({ items, size, isLoading, isSubmitting }) => (
      <>
        <PopoverMenu
          className="-mx-6 md:m-0 md:w-64"
          items={items}
          onClose={handleClose}
          size={size}
        />
        <Loader isLoading={isLoading || isSubmitting} />
      </>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  handleClose: undefined,
};

ViewComponent.displayName = 'Job-Menu-view';

export default ViewComponent;
