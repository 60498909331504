import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

import { Content } from '../components';

const ViewComponent = ({}) => (
  <DesktopLayoutBoxed help>
    <DesktopLayoutBoxedContent>
      <Content />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'ClaimResent-Desktop-view';

export default ViewComponent;
