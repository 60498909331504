import { CARD_VARIANT_PROPS } from './constants';
import type {
  SubscriptionCardClassNames,
  SubscriptionCardVariant,
} from './types';

interface SubscriptionCardHook {
  classNames: SubscriptionCardClassNames;
  showLines: boolean;
}

export default function useSubscriptionCard(
  variant: SubscriptionCardVariant,
): SubscriptionCardHook {
  const variantProps = CARD_VARIANT_PROPS[variant];

  return {
    classNames: variantProps.classNames,
    showLines: variant === 'highlight',
  };
}
