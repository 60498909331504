import { Button } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';

const ReferralsInviteButton: React.FunctionComponent = function () {
  return (
    <div className="flex w-full justify-between items-center">
      <p className="text-28-32-em">Referrals</p>
      <Link to={URLS.referralsForm} className="ml-auto">
        <Button.Text
          id="dashboard-send-referral"
          label="Refer A User"
          icon="plus"
          size={40}
        />
      </Link>
    </div>
  );
};

export default ReferralsInviteButton;
