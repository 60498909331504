import { Table, type TableHeaderColumn } from '@kanda-libs/ks-component-ts';

import Container from './Table-container';
import { CLASS_NAMES, TABLE_COLUMNS, DEFAULT_COLUMN } from './Table-constants';
import HoverPopover from '../HoverPopover';

const ViewComponent = ({}) => (
  <Container>
    {({ isLoading, ...tableProps }) => (
      <div className={CLASS_NAMES.table}>
        <Table
          columns={TABLE_COLUMNS as TableHeaderColumn[]}
          defaultColumn={DEFAULT_COLUMN as TableHeaderColumn}
          hoverPopover={HoverPopover}
          isValidating={!isLoading}
          isLoading={isLoading}
          {...tableProps}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Home-Desktop-Table-view';

export default ViewComponent;
