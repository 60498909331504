import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

/**
 * Converts duration to proper label
 * @param {Number} duration
 *
 * @returns {String} duration string
 */
export const getCombinedDurationLabel = (options: FinanceRate[]): string => {
  const durations = [...(options || [])]
    .map((option) => {
      if (option?.name?.includes('free'))
        return option.name.split('_').slice(-1)[0];
      return option.name.split('_').slice(-2)[0];
    })
    .sort(
      (option1, option2) =>
        (option1 as unknown as number) - (option2 as unknown as number),
    );
  const extracted = [durations[0], durations.slice(-1)[0]]
    .map((duration) => (duration as unknown as number) / 12)
    // Remove all non-unique durations
    .filter((duration, index, array) => array.indexOf(duration) === index)
    .join('-');
  return `${extracted} years`;
};
