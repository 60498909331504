import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  type Service,
  actions,
  services,
  useSubmit,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { saveAs } from 'file-saver';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

const isJobCustom = (jobItems) => {
  if (jobItems.length > 1) return false;
  const { style } = jobItems[0];
  if (style === 'custom') return true;
  return false;
};

const base64toBlob = (
  base64Data: string,
  contentType = 'application/pdf',
  sliceSize = 512,
) => {
  const byteCharacters = atob(base64Data);
  const byteArrays: Uint8Array[] = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const downloadBase64 = (pdf) => {
  const name = pdf?.name || `quote-${pdf?.id || 'copy'}.pdf`;
  const blob = base64toBlob(pdf?.content);
  saveAs(blob, name);
};

const useDownloadPdf = () => {
  const dispatch = useAppDispatch();
  const jobIsSubmitting = useSelector(selectors.job.getIsLoading);
  const docIsSubmitting = useSelector(selectors.document.getIsLoading);

  const { submit: createPdf, isSubmitting: createIsSubmitting } = useSubmit(
    services.pdf.create as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const { showError } = useToast();

  const onDownloadJobError = useApiError('Error downloading job');

  const onFetchPdfError = useApiError(
    'Error generating PDF at this time - please try again later',
  );

  const onGeneratePdfError = useApiError(
    'Error generating PDF at this time - please try again later',
  );

  const customDownload = useCallback(
    (job) => {
      const id = job?.job_items[0]?.quote_document?.id;
      if (!id) {
        showError('No PDF attached to this job');
        return;
      }
      dispatch(
        actions.getDocument({
          params: { id },
          forceReload: true,
          onError: onFetchPdfError,
          onSuccess: (pdfDoc) => {
            if (!pdfDoc?.content) {
              showError('Error fetching PDF');
              return;
            }
            downloadBase64(pdfDoc);
          },
        }),
      );
    },
    [dispatch, onFetchPdfError, showError],
  );

  const kandaDownload = useCallback(
    (job) => {
      const { id } = job;

      dispatch(
        actions.jobCompanyInfo({
          params: { id },
          onError: onGeneratePdfError,
          onSuccess: (companyInfo) => {
            createPdf({ body: { job, company: companyInfo } }).then(
              ({ data: pdf, error: pdfError }) => {
                if (pdfError || !pdf) {
                  showError(
                    'Error generating PDF at this time - please try again later',
                  );
                  return;
                }
                downloadBase64({
                  content: pdf.base64,
                  id,
                });
              },
            );
          },
        }),
      );
    },
    [dispatch, createPdf, showError, onGeneratePdfError],
  );

  const downloadPdf = useCallback(
    (id) => {
      dispatch(
        actions.getJob({
          params: { id },
          forceReload: true,
          onError: onDownloadJobError,
          onSuccess: (job) => {
            const items = job?.job_items;
            if (!items || items?.length === 0) {
              showError('Error downloading job');
              return;
            }
            const custom = isJobCustom(items);
            if (custom) {
              customDownload(job);
              return;
            }
            kandaDownload(job);
          },
        }),
      );
    },
    [dispatch, showError, customDownload, kandaDownload, onDownloadJobError],
  );

  const isSubmitting = jobIsSubmitting || docIsSubmitting || createIsSubmitting;

  return {
    downloadPdf,
    isSubmitting,
  };
};

export default useDownloadPdf;
