import { Button } from '@kanda-libs/ks-design-library';

import SplashScreen from '../../../../../components/SplashScreen';

interface SubscriptionSuccessSplashScreenProps {
  onSuccessClick(...args: unknown[]): unknown;
}

const SubscriptionSuccessSplashScreen = ({
  onSuccessClick,
}: SubscriptionSuccessSplashScreenProps) => (
  <SplashScreen
    icon="check-circle-color"
    title="Subscription Set Up"
    subText="Your direct debit has been set up. Continue to add your business details."
    cta={
      <Button.Text
        id="subscription-success-continue"
        onClick={onSuccessClick}
        label="Next step"
        icon="arrow-right"
      />
    }
  />
);

export default SubscriptionSuccessSplashScreen;
