export const CLASS_NAMES = {
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
  text: 'mb-auto mx-auto text-14-22',
  linktext: 'text-14-22-em text-green-400 ml-1',
};

export const TITLE = 'Magic Link';

export const DESCRIPTION =
  'Enter your email address and we’ll\nsend an email with instructions to login';

export const SUCCESS = 'Check your mail';

export const SUCCESS_SUBTEXT =
  'Please click on the link in the email we sent you to login';

export const FIELDS = {
  email: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'username',
    name: 'email',
    label: 'Email address',
    placeholder: 'Your email address',
  },
};

export const EMAIL_VALIDATION = {
  required: {
    value: true,
    message: 'Email address is required',
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Email must be a valid email address',
  },
};
