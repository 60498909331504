import { SkeletonLoader, useIsDesktop } from '@kanda-libs/ks-design-library';
import type { Contract } from 'pages/Setup/Subscription/context';
import DocumentPages from './DocumentPages';

interface DocumentProps {
  contract: Contract | undefined;
}

const Document: React.FunctionComponent<DocumentProps> = function ({
  contract,
}) {
  const isDesktop = useIsDesktop();
  return (
    <SkeletonLoader
      isLoading={!contract}
      height={isDesktop ? 400 : 680}
      afterLoading={contract ? <DocumentPages contract={contract} /> : null}
    />
  );
};

export default Document;
