import type { FunctionComponent } from 'react';

import {
  BreakPoints,
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Header,
  Layout,
  Logo,
} from '@kanda-libs/ks-design-library';

import { Link } from 'react-router-dom';

import { URLS } from 'config';
import Footer from './Footer';
import useAdvertising from './useAdvertising';

const Advertising: FunctionComponent = function () {
  const { page, Content } = useAdvertising();

  const content = (
    <>
      {Content && <Content />}
      <Footer page={page} />
    </>
  );

  const desktop = (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent width="100">
        <>{content}</>
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );

  const mobile = (
    <Layout
      header={
        <Header.Base lines help>
          <Link to={URLS.home}>
            <Button.Icon
              id="home-mobile-back"
              icon="chevron-left"
              className="-ml-4"
            />
          </Link>
          <Logo />
        </Header.Base>
      }
    >
      <>{content}</>
    </Layout>
  );

  return <BreakPoints desktop={desktop} mobile={mobile} />;
};

export default Advertising;
