import { Field } from '@kanda-libs/ks-component-ts';
import { Button, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import {
  CLASS_NAMES,
  DESCRIPTION,
  EMAIL_VALIDATION,
  FIELDS,
  SUCCESS,
  SUCCESS_SUBTEXT,
  TITLE,
} from './Content-constants';
import Container from './Content-container';

import { URLS } from '../../../../config';

interface ViewComponentProps {
  /**
   * Page
   */
  page?: number;
}

const ViewComponent = ({ page }: ViewComponentProps) => (
  <Container>
    {({ onEmailBlur }) =>
      page === 0 ? (
        <>
          <Link to={URLS.login} className="absolute left-6 top-6">
            <Button.Icon id="sso-back" icon="arrow-left" />
          </Link>
          <div className={CLASS_NAMES.headerWrapper}>
            <p className={CLASS_NAMES.title}>{TITLE}</p>
            <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
          </div>
          <Field.Validator validation={EMAIL_VALIDATION}>
            <Field.Input
              {...FIELDS.email}
              onBlur={() => onEmailBlur(FIELDS.email.name)}
            />
          </Field.Validator>
        </>
      ) : (
        <>
          <Icon size={104} icon="send-quote-color" className="mb-8" />
          <p className={CLASS_NAMES.title}>{SUCCESS}</p>
          <p className={CLASS_NAMES.description}>{SUCCESS_SUBTEXT}</p>
        </>
      )
    }
  </Container>
);

ViewComponent.displayName = 'SSO-Content-view';

ViewComponent.defaultProps = {
  page: 0,
};

export default ViewComponent;
