import {
  useIsDesktop,
  DesktopLayoutBoxed,
  DesktopLayoutDefault,
  DesktopLayoutBoxedContent,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';
import Content from './Content';

const Identify = () => {
  const isDesktop = useIsDesktop();

  const Layout = isDesktop ? DesktopLayoutBoxed : DesktopLayoutDefault;

  const LayoutContent = isDesktop
    ? DesktopLayoutBoxedContent
    : DesktopLayoutDefaultContent;

  return (
    <Layout help>
      <LayoutContent>
        <div id="veriff" className="hidden" />
        <Content />
      </LayoutContent>
    </Layout>
  );
};

export default Identify;
