import { Button } from '@kanda-libs/ks-design-library';

import Container from './Mobile-container';
import { Modal } from '../components';

export interface PopoverMobileProps {
  permissions: boolean[];
  role: string;
}

const ViewComponent = (props: PopoverMobileProps) => (
  <Container {...props}>
    {({ onClick, ...rest }) => (
      <div className="flex absolute w-10 h-10">
        <Button.Icon
          id="team-settings-new-mobile-info"
          onClick={onClick}
          icon="info"
          className=" my-auto"
          size="32-16"
        />
        <Modal {...rest} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'New-Role-Popover-Mobile-view';

export default ViewComponent;
