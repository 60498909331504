import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useMemo } from 'react';
import { filterForValidRates } from 'utils/FinanceRate-function';

import { groupAllRatesByInterest } from './Mobile-functions';

export interface FinanceRatesHook {
  rates: StringIndexedObject;
}

export default function useMobileRates() {
  const {
    data: { company, job: storedJob },
  } = useCreateJobContextValue();

  const [job, workType] = [storedJob, storedJob?.work_type];

  const selectedRates: FinanceRate[] = job?.finance_options || [];

  const regulatedRates = useMemo(
    () =>
      filterForValidRates(
        company?.finance_rates || [],
        workType,
        false,
        true,
        true,
      ),
    [company, workType],
  );

  const rates = groupAllRatesByInterest([...selectedRates, ...regulatedRates]);

  return {
    rates,
  };
}
