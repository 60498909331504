import { Content } from '../components';
import { DesktopLayout } from '../../components';

const ViewComponent = () => (
  <DesktopLayout>
    <Content />
  </DesktopLayout>
);

ViewComponent.displayName = 'TeamSettings-Content-view';

export default ViewComponent;
