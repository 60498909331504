export const CLASS_NAMES = {
  wrapper:
    'flex flex-row justify-center items-center md:px-7 gap-x-4 md:gap-x-6 md:py-2',
  cross: 'w-5 h-5',
  content:
    'flex flex-row justify-between gap-x-2 md:gap-x-0 items-center flex-grow',
  textContent: 'flex flex-col',
  title: 'text-14-22-em md:text-16-24-em text-neutral-600',
  description: 'text-14-22 text-neutral-800',
  button: 'max-w-[30px] max-h-[30px]',
};
