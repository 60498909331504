import { useMemo } from 'react';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const custom = useMemo(() => {
    if (!data) return false;
    return data?.jobType === 'custom';
  }, [data]);

  const isSolar = useMemo(() => {
    if (!data) return false;
    return data?.job?.job_type === 'solar';
  }, [data]);

  const hasNotes = useMemo(() => {
    if (!data) return false;
    const notes = data?.job?.notes;
    return Boolean(notes && notes?.length > 0 && notes[0]);
  }, [data]);

  return children({
    custom,
    isSolar,
    hasNotes,
  });
};

ContainerComponent.displayName = 'JobDetails-container';

export default ContainerComponent;
