import { Button } from '@kanda-libs/ks-design-library';

import { SEND_BUTTON_PROPS } from './SendButton-constants';

interface ViewComponentProps {
  /**
   * ID for the selection modal
   */
  href: string;
}

const ViewComponent = ({ href }: ViewComponentProps) => (
  <a id="send-message" href={href}>
    <Button.Text {...SEND_BUTTON_PROPS} />
  </a>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-SendMessageButton-view';

export default ViewComponent;
