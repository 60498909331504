import type { MutableRefObject } from 'react';
import { ModalLayoutCenter, ModalContainer } from '@kanda-libs/ks-component-ts';
import { STAFF_IMITATION_MODAL_ID } from './constants';
import StaffImitationForm from '../StaffImitationForm';

export default function StaffImitationModal() {
  return (
    <ModalContainer id={STAFF_IMITATION_MODAL_ID} opacity={30}>
      {({ id, handleClose, ref }) => (
        <>
          <ModalLayoutCenter
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
            className="w-10/12 md:w-160"
          >
            <div className="flex flex-col md:px-28 md:py-10">
              <StaffImitationForm />
            </div>
          </ModalLayoutCenter>
        </>
      )}
    </ModalContainer>
  );
}
