import { Button, CopyContent } from '@kanda-libs/ks-design-library';

import { COPY_BUTTON_PROPS, COPIED_BUTTON_PROPS } from './CopyButton-constants';

interface ViewComponentProps {
  /**
   * ID for the selection modal
   */
  message: string;
}

const ViewComponent = ({ message }: ViewComponentProps) => (
  <CopyContent
    content={message}
    clickedView={<Button.Link {...COPIED_BUTTON_PROPS} size={14} />}
  >
    <Button.Link {...COPY_BUTTON_PROPS} size={14} />
  </CopyContent>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-CopyMessageButton-view';

export default ViewComponent;
