import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content, Footer } from '../components';

import { URLS } from '../../../../config';

interface ViewComponentProps {
  /**
   * Disabled
   */
  disabled?: boolean;
  /**
   * is loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({ disabled, isLoading }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <div className="absolute top-6 left-6">
        <Link to={URLS.login}>
          <Button.Icon id="reset-password-desktop-close" icon="arrow-left" />
        </Link>
      </div>
      <Content isLoading={isLoading} />
      <Footer disabled={disabled} />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.defaultProps = {
  disabled: undefined,
  isLoading: undefined,
};

ViewComponent.displayName = 'ResetPassword-Landing-Desktop-view';

export default ViewComponent;
