import AccountVerificationExplanation from './AccountVerificationExplanation';
import CompanyInfo from './CompanyInfo';
import SetUpYourSubscription from './SetUpYourSubscription';
import Welcome from './Welcome';

import { ONBOARDING_STEPS } from '../../constants';

export default {
  [ONBOARDING_STEPS.WELCOME]: Welcome,
  [ONBOARDING_STEPS.SETUP_YOUR_SUBSCRIPTION]: SetUpYourSubscription,
  [ONBOARDING_STEPS.VERIFICATION]: AccountVerificationExplanation,
  [ONBOARDING_STEPS.SETUP_COMPANY]: CompanyInfo,
};
