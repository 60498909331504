import {
  aiReducers,
  type AIExtraState,
} from '@kanda-libs/kai-frontend-services';
import { createSelectors, createStore } from '@kanda-libs/ks-frontend-services';
import type { pages } from 'components/App';
import { APP_ENV, type PageKeys } from 'config';

export const store = createStore<PageKeys, AIExtraState>(aiReducers);

store.subscribe(() => {
  if (APP_ENV === 'qa') {
    // eslint-disable-next-line no-console
    console.log('store changed', store.getState());
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const selectors = createSelectors<RootState, typeof pages>();
