export const getDefaultValues = (currentFilter) => {
  if (!currentFilter)
    return {
      'filter-type': 'from',
      from: null,
      to: null,
    };
  const filterType = currentFilter.includes('to') ? 'between' : 'from';
  const from = currentFilter.includes('from')
    ? parseInt(currentFilter.split(';')[0].split(':')[1], 10)
    : null;
  const to = currentFilter.includes('to')
    ? parseInt(currentFilter.split(';')[1].split(':')[1], 10)
    : null;
  return {
    'filter-type': filterType,
    from,
    to,
  };
};
