import { useContext, useMemo } from 'react';

import Context from '../Context';
import { Jobs, JobsPlaceholder } from './components';

const ContainerComponent = ({ children }) => {
  const { isLoading, jobs } = useContext(Context);

  const Content = useMemo(() => {
    if (isLoading) return Jobs;
    if (jobs?.length === 0) return JobsPlaceholder;
    return Jobs;
  }, [isLoading, jobs]);

  return children({
    Content,
  });
};

ContainerComponent.displayName = 'Jobs-Mobile-container';

export default ContainerComponent;
