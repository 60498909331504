import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { calculateJobTotal } from '../../../../../../utils';

export default function useDepositOptions() {
  const {
    data: { job, company },
  } = useCreateJobContextValue();

  const { totalIncVat: total } = calculateJobTotal(job?.job_items || []);

  const depositValue = job?.deposit_value?.amount || 0;

  return {
    skipDeposit: company?.skip_deposit === 'Yes',
    noDeposit: job?.deposit_type === 'no_deposit',
    fixedDeposit: job?.deposit_type === 'fixed_deposit',
    partialDeposit: job?.deposit_type === 'partial_deposit',
    depositValue,
    depositPercentage: Math.floor((depositValue / total) * 100),
  };
}
