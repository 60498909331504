import { Icon, Popover } from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { Permissions } from '../components';
import Container from './Desktop-container';

export interface PopoverDesktopProps {
  permissions: boolean[];
  id: string;
}

const ViewComponent = (props: PopoverDesktopProps) => (
  <Container {...props}>
    {({ visible, elementRef, permissions = [], id }) => (
      <div
        ref={elementRef as MutableRefObject<HTMLDivElement>}
        className="flex absolute w-10 h-10"
      >
        <div className="m-auto">
          <Popover.Standard
            id={`users-role-${id}`}
            button={
              <Icon
                icon="info"
                className="w-3 -mt-1 cursor-pointer min-w-3 text-neutral-500"
              />
            }
            above
            right
            visible={visible}
            xMargin="mr-6"
            yMargin="-mb-24"
          >
            {() => <Permissions permissions={permissions} />}
          </Popover.Standard>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'New-Role-Popover-Desktop-view';

export default ViewComponent;
