import { FinanceRate, FinanceType } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';

export interface EnterpriseFinanceRate extends FinanceRate {
  original_fee?: number;
}

export type GroupedByFeeRates = Record<string, EnterpriseFinanceRate[]>;

export type GroupedByRateRates = Record<string, GroupedByFeeRates>;

export interface RatesTableHook {
  formattedRates?: GroupedByFeeRates;
}

export const checkFinanceTypes = (
  rate: FinanceRate,
  rateToMatch: FinanceRate,
): boolean => {
  const rateTypes = rate?.finance_types;
  const matchTypes = rateToMatch?.finance_types;
  if (
    !rateTypes ||
    rateTypes.length === 0 ||
    !matchTypes ||
    matchTypes.length === 0
  )
    return false;
  const sorted1 = rateTypes.sort((t1: FinanceType, t2: FinanceType) =>
    t1.localeCompare(t2),
  );
  const sorted2 = matchTypes.sort((t1: FinanceType, t2: FinanceType) =>
    t1.localeCompare(t2),
  );
  return sorted1.every(
    (val: FinanceType, index: number) => sorted2[index] === val,
  );
};

export const matchRate = (
  rate: FinanceRate,
  matchRates: FinanceRate[],
): FinanceRate | undefined => {
  const match = matchRates.filter(
    (rateToMatch: FinanceRate) =>
      rateToMatch?.apr === rate?.apr &&
      rateToMatch?.apr_type === rate?.apr_type &&
      rateToMatch?.deferred_duration === rate?.deferred_duration &&
      rateToMatch?.duration === rate?.duration &&
      checkFinanceTypes(rate, rateToMatch),
  );
  return match?.[0];
};

export const appendOriginalFee = (
  rates: FinanceRate[],
  originalRates?: FinanceRate[],
): EnterpriseFinanceRate[] => {
  if (!originalRates) return rates;
  return rates.map((rate: FinanceRate) => {
    const match = matchRate(rate, originalRates);
    if (!match) return rate;
    return {
      ...rate,
      original_fee: match.fee,
    };
  });
};

const getOriginalFee = (
  rate: FinanceRate,
  originalRates?: FinanceRate[],
): string => {
  if (!originalRates) return 'none';
  const match = matchRate(rate, originalRates);
  if (!match) return 'none';
  return String(match.fee);
};

export const groupByAprAndFee = (
  rates: FinanceRate[],
  originalRates?: FinanceRate[],
): GroupedByFeeRates => {
  const keys = rates
    .map(
      (rate: FinanceRate) =>
        `${rate?.apr || 0}-${rate.fee}-${getOriginalFee(rate, originalRates)}${
          rate?.apr_type === 'BUYNOW_PAYLATER' ? '-bnpl' : ''
        }${
          rate.finance_types &&
          rate.finance_types.length === 1 &&
          rate.finance_types.includes('secondary')
            ? '-2nd'
            : ''
        }`,
    )
    .filter(
      (key: string, index: number, all: string[]) => all.indexOf(key) === index,
    );
  return keys.reduce((final: GroupedByFeeRates, key: string) => {
    const parts = key.split('-');
    const feeRates = rates.filter((rate: FinanceRate) => {
      if (parts.length === 4 && parts.includes('bnpl')) {
        if (rate?.apr_type !== 'BUYNOW_PAYLATER') return false;
        return (
          parseInt(parts[0], 10) === rate?.apr &&
          parseInt(parts[1], 10) === rate?.fee
        );
      }
      return (
        parseInt(parts[0], 10) === rate?.apr &&
        parseInt(parts[1], 10) === rate?.fee
      );
    });
    return {
      ...final,
      [key]: appendOriginalFee(feeRates, originalRates),
    };
  }, {});
};

export default function useRatesTable(
  rates: FinanceRate[],
  originalRates?: FinanceRate[],
): RatesTableHook {
  const formattedRates = useMemo(() => {
    if (!rates) return undefined;
    return groupByAprAndFee(rates, originalRates);
  }, [rates, originalRates]);
  return {
    formattedRates,
  };
}
