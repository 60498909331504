import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import type { HomeLayoutProps } from '.';

const HomeLayoutDesktop: FunctionComponent<HomeLayoutProps> = function ({
  children,
}) {
  return (
    <DesktopLayoutDefault>
      <DesktopLayoutDefaultContent>
        <div className="w-full max-w-screen-lg mx-auto">{children}</div>
      </DesktopLayoutDefaultContent>
    </DesktopLayoutDefault>
  );
};

export default HomeLayoutDesktop;
