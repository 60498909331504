import { Dropzone, Form, FormState } from '@kanda-libs/ks-component-ts';
import { DesktopLayoutBoxedContent } from '@kanda-libs/ks-design-library';

import { Customer } from './components';
import * as JobExtraDetails from './components/JobExtraDetails';
import { CLASS_NAMES } from './Form-constants';
import Container from './Form-container';

import {
  Deposit,
  Details,
  PaymentAndFinanceOptions,
} from '../../../../components';
import NextButton from '../../../../components/NextButton';
import Page from '../../Page';

const ViewComponent = () => (
  <Container>
    {({
      form,
      onSubmit,
      disabled,
      isLoading,
      dropZoneProps,
      showDepositFields,
      isSolar,
      refreshJobDetails,
      showCustomerDetails,
    }) => (
      <Dropzone {...dropZoneProps}>
        <Form
          id="create-job-desktop"
          form={form}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          <Page>
            <DesktopLayoutBoxedContent
              width="w-full lg:min-w-180"
              footer={
                <div className={CLASS_NAMES.footer}>
                  <FormState>
                    {() => <NextButton disabled={disabled} />}
                  </FormState>
                </div>
              }
            >
              <div className={CLASS_NAMES.container}>
                <Details.LeadBanner />
                <Details.ExampleJobAlertBanner />
                <Details.KandaQuoteBanner />
                <Details.Title />
                <Details.Quote refreshJobDetails={refreshJobDetails} />
                {isSolar && <Details.EPVSCalculations />}
                {showCustomerDetails && (
                  <div className={CLASS_NAMES.section}>
                    <div className={CLASS_NAMES.side}>
                      <JobExtraDetails.Info />
                    </div>
                    <div className={CLASS_NAMES.content}>
                      <JobExtraDetails.Fields />
                    </div>
                  </div>
                )}
                <div className={CLASS_NAMES.section}>
                  <div className={CLASS_NAMES.side}>
                    <Customer.Info />
                  </div>
                  <div className={CLASS_NAMES.content}>
                    <Customer.Field />
                  </div>
                </div>
                {showDepositFields && (
                  <div className={CLASS_NAMES.section}>
                    <div className={CLASS_NAMES.side}>
                      <Deposit.Info />
                    </div>
                    <div className={CLASS_NAMES.content}>
                      <Deposit.Fields />
                    </div>
                  </div>
                )}
                <div className={CLASS_NAMES.section}>
                  <div className={CLASS_NAMES.side}>
                    <PaymentAndFinanceOptions.Info />
                  </div>
                  <div className={CLASS_NAMES.content}>
                    <PaymentAndFinanceOptions.FinanceOptions unregulated />
                    <PaymentAndFinanceOptions.FinanceOptions />
                    <PaymentAndFinanceOptions.PaymentMethods />
                    <PaymentAndFinanceOptions.LeadFinanceOption />
                  </div>
                </div>
              </div>
            </DesktopLayoutBoxedContent>
          </Page>
        </Form>
      </Dropzone>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Details-view';

export default ViewComponent;
