export const LABEL = {
  mobile: 'Add items to the job',
  desktop: 'Add line items',
};

export const CLASS_NAME =
  'text-style-f-em text-neutral-900 my-6 md:text-style-e md:my-4';

export const SKELETONS = {
  title: {
    width: 100,
  },
};
