import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import useSaveDraft from '../../../CreateJob-useSaveDraft';

const ContainerComponent = ({ children }) => {
  const {
    data: { isLoading, company },
  } = useCreateJobContextValue();

  const showBanner =
    company?.lifecycle === 'registered' || company?.lifecycle === 'onboarded';

  const { handleSubmit, isSubmitting } = useSaveDraft();

  const onClick = () => handleSubmit();

  return children({
    isLoading: isLoading || !company,
    onClick,
    showBanner,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'CompleteAccountBadge-container';

export default ContainerComponent;
