export const CLASS_NAMES = {
  container: 'flex flex-col max-w-96 mx-auto',
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
};

export const TITLE = 'Change your password';

export const DESCRIPTION =
  'Your new password must be different from previously used passwords';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  password: {
    name: 'password',
    label: 'New password',
    placeholder: 'Your new password',
    autoCapitalize: 'off',
    autoCorrect: 'off',
  },
  confirmPassword: {
    name: 'confirmPassword',
    label: 'Confirm new password',
    placeholder: 'Confirm your new password',
    autoCapitalize: 'off',
    autoCorrect: 'off',
  },
};
