import { Button, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from '../../../../../config';

const ViewComponent = () => (
  <div className="flex flex-col w-full m-auto text-center max-w-96">
    <Icon icon="send-quote-color" size={104} className="mx-auto mb-8" />
    <h1 className="mb-4 text-24-28-em">Your email has been verified</h1>
    <h2 className="text-16-24 text-neutral-600">
      You can now continue with this account
    </h2>
    <Link to={URLS.home} className="mt-8">
      <Button.Text
        id="verify-email-confirmation-continue"
        label="Continue"
        icon="arrow-right"
      />
    </Link>
  </div>
);

ViewComponent.displayName = 'ResetPasswordConfirmation-Content-view';

export default ViewComponent;
