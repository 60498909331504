import { Icon } from '@kanda-libs/ks-design-library';

import Container from './Icon-container';
import { ICON_PROPS } from './Icon-constants';

const ViewComponent = () => (
  <Container>
    {({ icon, color }) => <Icon icon={icon} stroke={color} {...ICON_PROPS} />}
  </Container>
);

ViewComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Icon-view';

export default ViewComponent;
