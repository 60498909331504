import { Button, Text } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import { capitaliseWords } from '../../../utils';
import DirectorCard from '../DirectorCard';
import { DESKTOP_CLASS_NAMES, SKELETONS } from './constants';

interface DesktopProps {
  director: UserType | null;
  onClickButton: () => void;
  companyName?: string;
  isUserLoggedIn?: boolean;
  isLoading: boolean;
}

const Desktop = ({
  director,
  companyName,
  isLoading,
  isUserLoggedIn,
  onClickButton,
}: DesktopProps) => {
  const loadingProps = {
    isLoading,
  };

  const directorFullName =
    [director?.first_name, director?.last_name]
      ?.filter((name) => name)
      ?.join(' ') || '';

  return (
    <div className={DESKTOP_CLASS_NAMES.wrapper}>
      {!isUserLoggedIn && (
        <Text
          text={`Welcome ${director?.first_name}`}
          className={DESKTOP_CLASS_NAMES.title}
          skeletonProps={SKELETONS.title}
          {...loadingProps}
        />
      )}
      <Text
        text="Please verify your identity"
        className={DESKTOP_CLASS_NAMES.title}
        skeletonProps={SKELETONS.secondTitle}
        {...loadingProps}
      />
      <Text
        text="To offer customers payment methods and finance options, the identity of all company directors needs to be verified."
        className={DESKTOP_CLASS_NAMES.subTitle}
      />
      {!isUserLoggedIn && (
        <Text
          text="If this is you, continue to identity verification"
          className={DESKTOP_CLASS_NAMES.continue}
        />
      )}
      <DirectorCard
        name={directorFullName}
        companyName={
          companyName ? capitaliseWords(companyName.toLowerCase()) : ''
        }
        {...loadingProps}
      />
      <Button.Text
        id="identify-desktop-next"
        label="Continue"
        icon="arrow-right"
        isLoading={isLoading}
        onClick={() => {
          onClickButton();
        }}
      />
    </div>
  );
};

export default Desktop;
