import { Button, Text } from '@kanda-libs/ks-design-library';
import { Widget, Field, AddressApiData } from '@kanda-libs/ks-component-ts';

import Container from './ContactDetails-container';
import { PHONE_NUMBER } from './ContactDetails-constants';

import { GetName } from '../../../../../../../../../common';

export interface ContactDetailsProps {
  isSubmitting: boolean;
}

const ViewComponent = (props: ContactDetailsProps) => (
  <Container {...props}>
    {({
      emailValidation,
      isSubmitting,
      setPostcodeName,
      postcodeCallback,
      postcodeName,
      addresses,
      selectProps,
      manual,
      enterManually,
    }) => (
      <div className="flex flex-col">
        <Text
          text="Company Contact Details (shown to customer)"
          className="text-14-22-em text-neutral-700 mb-4 mt-4"
        />
        <Widget.CompanyContactInfoContactName />
        <Widget.CompanyContactInfoContactEmail validation={emailValidation} />
        <Widget.CompanyContactInfoContactPhone {...PHONE_NUMBER} />

        <GetName callback={setPostcodeName}>
          <Widget.CompanyContactInfoContactAddressPostcode
            callback={postcodeCallback}
            label="Company contact address postcode"
          />
        </GetName>

        {postcodeName && (
          <Field.Address.Select
            postcodeName={postcodeName}
            data={addresses as unknown as AddressApiData}
            {...selectProps}
          />
        )}

        {!manual ? (
          <Button.Link
            id="account-settings-company-contact-details-enter-manually"
            label="Enter address manually"
            size={14}
            variant="turquoise"
            className="mr-auto -mt-4 mb-6"
            onClick={enterManually}
          />
        ) : (
          <>
            <Widget.CompanyContactInfoContactAddressLine1 label="Company contact address line 1" />
            <Widget.CompanyContactInfoContactAddressLine2 label="Company contact address line 2" />
            <Widget.CompanyContactInfoContactAddressCity label="Company contact address city" />
            <Widget.CompanyContactInfoContactAddressCounty label="Company contact address county" />
            <Widget.CompanyContactInfoContactAddressCountry label="Company contact address country" />
          </>
        )}

        <Button.Text
          id="account-settings-company-contact-details-submit"
          submit
          label="Submit"
          className="mb-6"
          disabled={isSubmitting}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'CompanyInformation-Content-Form-ContactDetails-view';

export default ViewComponent;
