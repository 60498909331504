import AccountBadge from '../../../../../../components/AccountBadge';
import { URLS } from '../../../../../../config';

const ViewComponent = () => (
  <AccountBadge
    title="Complete your account to send jobs to customers"
    subTitle="You can only draft jobs until you finish set up"
    buttonLabel="Finish setup"
    link={URLS.onboarding}
  />
);

ViewComponent.displayName = 'CompleteAccountBadge-view';

export default ViewComponent;
