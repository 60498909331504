import { BreakPoints, Text } from '@kanda-libs/ks-design-library';

import Container from './FinanceOptions-container';
import {
  CLASS_NAMES,
  TITLE,
  SKELETONS,
} from '../../PaymentAndFinanceOptions-constants';

import FinanceOptionNarrow from '../../../../../../components/FinanceOptionNarrow';

const ViewComponent = () => (
  <Container>
    {({
      lineItems,
      items,
      bnpl,
      chosenRate,
      isLoading,
      jobType,
      deposit,
      declined,
      canApplySecond,
    }) => (
      <div className={CLASS_NAMES.cardContainer}>
        {chosenRate ? (
          <>
            <p className="text-style-h-em lg:text-style-f-em text-neutral-800 mb-4 lg:mb-6">
              Payment Option Chosen
            </p>
            <div className={CLASS_NAMES.card}>
              <FinanceOptionNarrow
                hideCost
                showPayout
                financeOption={chosenRate}
                lineItems={lineItems}
                isLoading={isLoading}
                jobType={jobType}
                deposit={deposit}
                declined={declined}
                isSecondLine={canApplySecond}
              />
            </div>
          </>
        ) : (
          <>
            <BreakPoints
              mobile={
                <Text
                  skeletonProps={SKELETONS.title}
                  isLoading={isLoading}
                  className={CLASS_NAMES.title}
                  text={TITLE.mobile.finance}
                />
              }
              desktop={
                <Text
                  skeletonProps={SKELETONS.title}
                  isLoading={isLoading}
                  className={CLASS_NAMES.title}
                  text={TITLE.desktop}
                />
              }
            />
            {bnpl && (
              <div className={CLASS_NAMES.card}>
                <p className="px-4 pt-5 md:pt-3 text-14-22-em text-neutral-700">
                  Buy now pay later
                </p>
                {bnpl.map((financeOption) => (
                  <FinanceOptionNarrow
                    hideCost
                    key={financeOption.name}
                    financeOption={financeOption}
                    lineItems={lineItems}
                    isLoading={isLoading}
                    jobType={jobType}
                    deposit={deposit}
                  />
                ))}
              </div>
            )}
            {items && (
              <div className={CLASS_NAMES.card}>
                {bnpl && (
                  <p className="px-4 pt-5 md:pt-3 text-14-22-em text-neutral-700">
                    Pay monthly
                  </p>
                )}
                {items.map((financeOption) => (
                  <FinanceOptionNarrow
                    hideCost
                    key={financeOption.name}
                    financeOption={financeOption}
                    lineItems={lineItems}
                    isLoading={isLoading}
                    jobType={jobType}
                    deposit={deposit}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-FinanceOptions-view';

export default ViewComponent;
