import {
  FormTheme,
  MultiStepForm,
  MultiStepFormRouter,
} from '@kanda-libs/ks-component-ts';

import CompanyInfoProvider from './context';
import { Pages, STEPS } from './Form-constants';
import useCompanyInfoForm from './useCompanyInfoForm';

const ViewComponent = ({}) => {
  const { initialStepIndex, initialData } = useCompanyInfoForm();

  if (initialStepIndex === null) return null;

  return (
    <CompanyInfoProvider>
      <FormTheme variant="default">
        <MultiStepForm
          steps={STEPS}
          addStepsToUrl={false}
          initialStepIndex={initialStepIndex}
          initialData={initialData}
        >
          <MultiStepFormRouter pages={Pages} />
        </MultiStepForm>
      </FormTheme>
    </CompanyInfoProvider>
  );
};

ViewComponent.displayName = 'Form-view';

export default ViewComponent;
