import { Table, type TableHeaderColumn } from '@kanda-libs/ks-component-ts';

import { DEFAULT_COLUMN, TABLE_COLUMNS } from './constants';
import Placeholder from './Placeholder';
import useReferralsTable from './useReferralsTable';

const ReferralsTable: React.FunctionComponent = function () {
  const { showTable, isLoading, isValidating, ...tableProps } =
    useReferralsTable();
  if (!showTable) return <Placeholder />;
  return (
    <div className="-mx-8 mt-8">
      <Table
        columns={TABLE_COLUMNS as TableHeaderColumn[]}
        defaultColumn={DEFAULT_COLUMN as TableHeaderColumn}
        isValidating={isValidating}
        isLoading={isLoading}
        {...tableProps}
      />
    </div>
  );
};

export default ReferralsTable;
