export const BUTTON_PROPS = {
  id: 'create-job-send-confirmation-mobile-finish',
  icon: 'check',
  left: true,
  label: 'Finish',
  className: 'w-full',
};

export const EXAMPLE_FINISH_BUTTON_PROPS = {
  id: 'create-job-send-confirmation-mobile-finish',
  icon: 'check',
  left: true,
  label: 'Finish',
  className: 'w-full',
  variant: 'ghost',
};
