import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { PAYMENT_METHODS } from '@kanda-utils/library';

export const BASE_SKELETON_RATE: Omit<FinanceRate, 'name'> = {
  fee: 0,
  charge: 0,
  enabled: true,
  finance_types: ['primary'],
  provider: 'omni',
};

export const SKELETON_ITEMS: FinanceRate[] = [
  { name: PAYMENT_METHODS.INTEREST_FREE_10, ...BASE_SKELETON_RATE },
  { name: PAYMENT_METHODS.INTEREST_FREE_12, ...BASE_SKELETON_RATE },
  { name: PAYMENT_METHODS.INTEREST_FREE_24, ...BASE_SKELETON_RATE },
];
