import Details from './Details';
import ExtraDetails from './ExtraDetails';
import Customer from './Customer';
import Deposit from './Deposit';
import PaymentAndFinanceOptions from './PaymentAndFinanceOptions';
import Summary from './Summary';
import SendConfirmation from './SendConfirmation';

import { ADD_A_JOB_STEPS } from '../../../CreateJob-constants';

export default {
  [ADD_A_JOB_STEPS.DETAILS]: Details,
  [ADD_A_JOB_STEPS.EXTRA_DETAILS]: ExtraDetails,
  [ADD_A_JOB_STEPS.CUSTOMER]: Customer,
  [ADD_A_JOB_STEPS.DEPOSIT]: Deposit,
  [ADD_A_JOB_STEPS.PAYMENT_AND_FINANCE_OPTIONS]: PaymentAndFinanceOptions,
  [ADD_A_JOB_STEPS.SUMMARY]: Summary,
  [ADD_A_JOB_STEPS.SEND_CONFIRMATION]: SendConfirmation,
};
