import { BASE_VALIDATION, PHONE_REGEX } from './constants';

export default function useReferralsInviteFormFieldPhone() {
  return {
    ...BASE_VALIDATION,
    validate: {
      value: (value?: string | number | boolean) => {
        if (typeof value !== 'string') return false;
        return PHONE_REGEX.test(value);
      },
      message: 'Please enter a valid UK mobile number, starting with 0 or +44',
    },
  };
}
