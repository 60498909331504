import { Form, Widget } from '@kanda-libs/ks-component-ts';
import { Text, Loader } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import Container from './Content-container';
import { AddUserButton, Users } from './components';
import { TITLE, CLASS_NAMES } from '../../TeamSettings-constants';

const ViewComponent = () => (
  <Container>
    {({
      isLoading,
      isSubmitting,
      form,
      onSubmit,
      defaultValues,
      canUserEdit,
    }) => (
      <Form form={form} onSubmit={onSubmit} id="account-settings-team-settings">
        <div className="flex flex-col">
          <Text
            isLoading={isLoading}
            text={TITLE}
            className={CLASS_NAMES.title}
            skeletonProps={{
              width: 200,
            }}
          />
          <Widget.CompanyUsersArrayWrapper>
            {({ arrayName, fields, arrayProps: { append, ...rest } }) => (
              <>
                <Users
                  fields={fields}
                  arrayName={arrayName}
                  defaultValues={defaultValues}
                  isSubmitting={isSubmitting}
                  canUserEdit={canUserEdit}
                  {...rest}
                />
                {canUserEdit && (
                  <AddUserButton
                    fields={fields as unknown as UserType[]}
                    append={append}
                  />
                )}
              </>
            )}
          </Widget.CompanyUsersArrayWrapper>
        </div>
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'TeamSettings-Content-view';

export default ViewComponent;
