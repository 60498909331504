import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

export const downloadBase64PDF = (base64, filename) => {
  if (!base64 || !filename) return;
  const element = document.createElement('a');
  element.setAttribute('href', `data:application/pdf;base64,${base64}`);
  element.setAttribute('download', filename || 'your-pdf.pdf');
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const printBase64PDF = (base64, filename) => {
  if (!base64 || !filename) return;
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const navigator = window.navigator as StringIndexedObject;

  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename || 'your-pdf.pdf');
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }
};

export const toBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);

    reader.readAsDataURL(blob);
  });
