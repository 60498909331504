import { MarkdownDisplay } from '@kanda-libs/ks-component-ts';
import Container from './Note-container';

const ViewComponent = () => (
  <Container>
    {({ note }) => (
      <div className="flex flex-col">
        <span className="text-12-18-em text-neutral-700 mb-0.5">Note</span>
        <span className="text-12-18 text-neutral-600">
          <MarkdownDisplay>{note}</MarkdownDisplay>
        </span>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Notes-Mobile-Note-view';

export default ViewComponent;
