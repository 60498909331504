import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

interface ViewComponentProps {
  /**
   * Title
   */
  title: string;
  /**
   * Customer
   */
  label: string;
  /**
   * On click function
   */
  onClick(...args: unknown[]): unknown;
  /**
   * icon
   */
  icon: string;
}

const ViewComponent = (props: ViewComponentProps) => (
  <BreakPoints
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

ViewComponent.displayName = 'Filter-view';

export default ViewComponent;
