import { BreakPoints } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Redirect } from 'react-router-dom';

import Desktop from './Desktop';

import { Helmet } from '../../../components';

const ViewComponent = () => (
  <>
    <Helmet title="Notification Preferences" />
    <BreakPoints
      mobile={<Redirect to={URLS.teamSettings} />}
      desktop={<Desktop />}
    />
  </>
);

ViewComponent.displayName = 'NotificationPreferences-view';

export default ViewComponent;
