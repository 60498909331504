import { Form, RowFields } from '@kanda-libs/ks-component-ts';
import { Title } from '@kanda-libs/ks-design-library-new';
import type { FunctionComponent } from 'react';
import InviteYourDeveloperForm from './InviteYourDeveloperForm';
import useInviteYourDeveloper from './useInviteYourDeveloper';

const InviteYourDeveloper: FunctionComponent = function () {
  const { form, onSubmit } = useInviteYourDeveloper();

  return (
    <div className="max-w-180">
      <div className="mb-16">
        <Title>Invite your developer</Title>
      </div>
      <RowFields>
        <Form
          id="welcome-invite-your-developer"
          form={form}
          onSubmit={onSubmit}
        >
          <InviteYourDeveloperForm />
        </Form>
      </RowFields>
    </div>
  );
};

export default InviteYourDeveloper;
