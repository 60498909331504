export const CLASS_NAMES = {
  container: 'flex flex-row py-1 items-center',
  title: 'text-style-h text-neutral-700',
  bullet: 'mx-1.5 w-0.75 h-0.75 bg-neutral-500 rounded-full',
};

export const ICON_PROPS = {
  size: 16,
  stroke: 'neutral-600',
};
