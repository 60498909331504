import { useState } from 'react';
import { useWatch } from 'react-hook-form';

interface LimitedCompanyFieldsHook {
  selected: boolean;
  showButton: boolean;
  noCompany: boolean;
  noCompanyCallback: () => void;
  searchCompany: () => void;
}

export default function useLimitedCompanyFields(): LimitedCompanyFieldsHook {
  const [noCompany, setNoCompany] = useState<boolean>(false);

  const [name, search, focus, number] = useWatch({
    name: [
      'company.limited_company_info.company_name',
      'companySearch',
      'companyFocus',
      'company.limited_company_info.company_number',
    ],
  });

  const selected = (search && focus && number) || noCompany;

  const showButton = selected || name;

  const noCompanyCallback = () => setNoCompany(true);
  const searchCompany = () => setNoCompany(false);

  return { selected, showButton, noCompany, noCompanyCallback, searchCompany };
}
