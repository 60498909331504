import type { AuthUser } from '@kanda-libs/ks-frontend-services';
import useMe from 'hooks/useMe';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { META_PIXEL_ID } from './constants';
import { addDefaultScript } from './helpers';
import type { InitData, MetaPixelInterface } from './types';

export interface FacebookPixelHook {
  init: (data: InitData | undefined) => void;
  fbq?: MetaPixelInterface;
}

function useFacebookPixel(): FacebookPixelHook {
  const authIsLoading = useSelector(selectors.getAuthIsLoading);

  const { me } = useMe();
  const [fbq, setFbq] = useState<MetaPixelInterface | undefined>(undefined);

  const getExternalInitData = useCallback(
    (user: AuthUser | undefined): InitData | undefined => {
      if (!user) return undefined;
      return {
        ...(user?.email ? { em: user.email } : {}),
        ...(user?.id ? { external_id: user.id } : {}),
      };
    },
    [],
  );

  const init = useCallback((data: InitData | undefined) => {
    const fbqScript = addDefaultScript();
    fbqScript('init', META_PIXEL_ID, data);
    fbqScript('track', 'PageView');
    setFbq(fbqScript);
  }, []);

  useEffect(() => {
    // Don't try to init until we know if logged in or out
    if (authIsLoading) return;
    // If window.fbq exists, simply return this
    if (window.fbq) {
      setFbq(window.fbq);
      return;
    }
    // Get the initial user data and init
    const data = getExternalInitData(me);
    init(data);
  }, [authIsLoading, me, getExternalInitData, init]);

  return {
    fbq,
    init,
  };
}

export default useFacebookPixel;
