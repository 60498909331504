import type { ButtonTextVariant } from '@kanda-libs/ks-design-library';

export const ICON_PROPS = {
  icon: 'user-square-color',
  size: 64,
  className: 'my-auto hidden md:block mr-6',
};

export const BUTTON_SMALL_PROPS = {
  id: 'create-job-summary-complete-account-small',
  variant: 'gradient' as ButtonTextVariant,
  className: 'block md:hidden',
  label: 'Save draft',
  icon: 'arrow-right',
  size: 32,
};

export const BUTTON_LARGE_PROPS = {
  id: 'create-job-summary-complete-account-large',
  variant: 'gradient' as ButtonTextVariant,
  className: 'hidden md:block',
  label: 'Finish setup',
  icon: 'arrow-right',
  size: 40,
};

export const CLASS_NAMES = {
  container: 'flex flex-col md:flex-row w-full md:p-4',
  textWrapper: 'flex flex-col my-auto mr-auto',
  title: 'text-14-22  mb-1 text-neutral-700',
  subTitle: 'text-18-22-em text-neutral-900',
  link: 'mt-4 md:my-auto',
};
