import { Widget } from '@kanda-libs/ks-component-ts';
import { Button, Card, Icon } from '@kanda-libs/ks-design-library';

import Container from './CompanyDetails-container';
import { COMPANY_INFO_FIELD_NAMES } from './CompanyDetails-constants';

const ViewComponent = () => (
  <Container>
    {({ showField, deselect, companyName, companyNumber, address }) =>
      showField ? (
        <Widget.CompanyLimitedCompanyInfoCompanyName
          label="Company Name (as found on Companies House)"
          {...COMPANY_INFO_FIELD_NAMES}
        />
      ) : (
        <div className="relative">
          <div className="flex absolute right-0 top-0">
            <Button.InlineLink
              id="company-info-change-company"
              label="Choose different company"
              variant="turquoise"
              onClick={deselect}
            />
          </div>
          <div className="flex">
            <Card
              className="mt-7 mb-6"
              title={
                <div className="flex">
                  <Icon
                    size={20}
                    stroke="neutral-700"
                    icon="office"
                    className="mr-3"
                  />
                  <span className="text-14-22-em text-neutral-900">
                    {companyName}
                  </span>
                </div>
              }
              footer={
                <span className="text-14-22 text-neutral-500">
                  {companyNumber}
                </span>
              }
            >
              <span className="text-14-22 text-neutral-600">{address}</span>
            </Card>
          </div>
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'CompanyDetails-view';

export default ViewComponent;
