import { useContext, useCallback } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import type { FinanceRate, Job } from '@kanda-libs/ks-frontend-services';

import { PAYMENT_OPTIONS_MODAL_ID } from './PaymentOptions-constants';

import useCurrentJob from '../../../JobDetails-useCurrentJob';

export interface ContainerComponentChildrenArgs {
  showPaymentOptionsModal: () => void;
  financeOptions: FinanceRate[];
  checkoutOptions: Job['checkout_options'];
  badge: number;
  isLoading: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { showModal } = useContext(ModalsWrapperContext);

  const { job, isLoading } = useCurrentJob();

  /**
   * Displays note modal
   */
  const showPaymentOptionsModal = useCallback(() => {
    if (isLoading) return;
    showModal(PAYMENT_OPTIONS_MODAL_ID);
  }, [isLoading, showModal]);

  const financeOptions = job?.finance_options || [];
  const checkoutOptions = job?.checkout_options || [];

  return children({
    showPaymentOptionsModal,
    financeOptions,
    checkoutOptions,
    badge: financeOptions?.length + checkoutOptions?.length,
    isLoading,
  });
};

ContainerComponent.displayName = 'PayemntOptions-Mobile-container';

export default ContainerComponent;
