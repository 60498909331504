import { Widget } from '@kanda-libs/ks-component-ts';

const NoCompanyFoundFields: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col">
      <Widget.CompanyLimitedCompanyInfoCompanyNumber />
    </div>
  );
};

export default NoCompanyFoundFields;
