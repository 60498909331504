import { useCallback } from 'react';

import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { ANALYTICS_KEY } from '../config';

export default function useGtag() {
  const { gtag } = window as StringIndexedObject;

  const buttonClick = useCallback(
    (eventName = 'button_click', id = null) => {
      if (!gtag) return;
      gtag('event', eventName, {
        ...(id ? { id } : {}),
      });
    },
    [gtag],
  );

  const trackEvent = useCallback(
    (eventName, params = {}) => {
      if (!gtag) return;
      gtag('event', eventName, params);
    },
    [gtag],
  );

  const trackSubmission = useCallback(
    (params = {}) => {
      if (!gtag) return;
      gtag('event', 'server_submission', params);
    },
    [gtag],
  );

  const trackResponse = useCallback(
    (params = {}) => {
      if (!gtag) return;
      gtag('event', 'server_response', params);
    },
    [gtag],
  );

  const trackException = useCallback(
    (description, fatal = false) => {
      if (!gtag) return;
      gtag('event', 'exception', {
        description,
        fatal,
      });
    },
    [gtag],
  );

  const updateStreamConfig = useCallback(
    (params) => {
      if (!gtag) return;
      gtag('config', ANALYTICS_KEY, params);
    },
    [gtag],
  );

  const updateConfig = useCallback(
    (fieldName, value) => {
      if (!gtag) return;
      gtag('set', fieldName, value);
    },
    [gtag],
  );

  const updateUserId = useCallback(
    (id, variant = 'global') => {
      if (!gtag) return;
      if (variant === 'global') {
        gtag('set', 'user_id', id);
        return;
      }
      gtag('config', ANALYTICS_KEY, { user_id: id });
    },
    [gtag],
  );

  return {
    gtag,
    trackSubmission,
    trackException,
    trackResponse,
    buttonClick,
    trackEvent,
    updateConfig,
    updateStreamConfig,
    updateUserId,
  };
}
