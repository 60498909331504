interface ErrorResponse {
  code?: string;
  message?: string;
}

type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
};

export const removeWhitespaceAndNewline = (value: string) => {
  if (!value) return value;
  return value?.replace(/(\r\n|\n|\r)/gm, '')?.replace(/\s/g, '');
};

export const removeTrailingWhitespace = (value: string) => value?.trim();

export const capitalise = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const capitaliseWords = (string: string) =>
  string
    .split(' ')
    .map((substring) => substring.charAt(0).toUpperCase() + substring.slice(1))
    ?.join(' ');

export const capitaliseInputOnChange = (
  e: HTMLElementEvent<HTMLTextAreaElement>,
) => {
  if (!e?.currentTarget) return;
  e.currentTarget.value = e.currentTarget?.value?.toUpperCase();
};

export const parsePhoneNumber = (number: string, code = '+44') => {
  if (!number) return '';
  if (number[0] === '0') return `${code}${number.slice(1)}`;
  if (number[0] === '+') return number;
  return `${code}${number}`;
};

export const parseMessage = (message: string): ErrorResponse => {
  const parts = message?.split(', ');
  const response = parts.reduce((errors, part) => {
    const split = part.split('=');
    if (split?.length !== 2) return errors;
    return {
      ...errors,
      [split[0]]: split[1],
    };
  }, {});
  return response;
};

export const pluralise = (
  count: number,
  singular: string,
  plural: string,
): string => (count === 1 ? singular : plural);
