import {
  useFormContext,
  useWatch,
  type StringIndexedObject,
  type ValidationItems,
  type ValidationProps,
} from '@kanda-libs/ks-component-ts';
import { useCallback, useEffect, useState } from 'react';

import type { Addresses } from 'types';

import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from '../../../../../../../../../utils';
import { ADDRESS_NAME, POSTCODE_NAME } from './Address-constants';

const ContainerComponent = ({ children }) => {
  const [addresses, setAddresses] = useState<Addresses | null>(null);
  const [manual, setManual] = useState(false);

  const postcodeCallback = useCallback((results) => setAddresses(results), []);

  const postcode = useWatch({
    name: POSTCODE_NAME,
  });

  const { setValue, getValues } = useFormContext();

  const address = useWatch({ name: ADDRESS_NAME });

  const showSelect = postcode && postcode !== '' && addresses;

  const addManualInput = () => setManual(!manual);

  useEffect(() => {
    if (
      !address?.line_1 ||
      !addresses?.addresses ||
      addresses?.addresses?.length === 0
    )
      return;
    const match = addresses.addresses.findIndex(
      (addr) => (addr as StringIndexedObject).line_1 === address.line_1,
    );
    if (match === -1 || match === address?.selected) return;
    const vals = addresses.addresses[match];
    setValue(`${ADDRESS_NAME}.selected`, match);
    setValue(`${ADDRESS_NAME}.line_1`, vals.line_1);
    if (vals?.line_2) setValue(`${ADDRESS_NAME}.line_2`, vals.line_2);
    if (vals?.building_name)
      setValue(`${ADDRESS_NAME}.building_name`, vals.building_name);
    if (vals?.building_number)
      setValue(`${ADDRESS_NAME}.building_number`, vals.building_number);
    setValue(`${ADDRESS_NAME}.city`, (vals as any).town_or_city);
    if (vals?.county) setValue(`${ADDRESS_NAME}.county`, vals.county);
    setValue(`${ADDRESS_NAME}.country`, vals.country);
  }, [address, addresses, setValue]);

  const validation = {
    validate: {
      value: () => validateAddressSelect(getValues(), 'job.customer.address'),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(validation),
    validationErrors: getValidationErrors(validation),
  } as unknown as ValidationProps;

  return children({
    addresses,
    postcodeCallback,
    manual,
    addManualInput,
    showSelect,
    selectProps,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Customer-Field-Address-container';

export default ContainerComponent;
