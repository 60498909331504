const NI_REGEX =
  /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]{0,1}|\s)$/i;

export const NATIONAL_INSURANCE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'National Insurance number is required',
  },
  minLength: {
    value: 1,
    message: 'National Insurance number be longer than 1 character',
  },
  pattern: {
    value: NI_REGEX,
    message: 'National Insurance number must be valid UK number',
  },
};
