import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

const useFuse = (list, options, { initialQuery = '', debounce = 100 } = {}) => {
  const isMounted = useRef(false);

  const [originalQuery, setQuery] = useState(initialQuery);
  const [query] = useDebounce(originalQuery, debounce);

  // let's memoize the fuse instance for performances
  const fuse = useMemo(() => {
    const { limit, matchAllOnEmptyQuery, ...fuseOptions } = options;

    return new Fuse(list, fuseOptions);
  }, [list, options]);

  // memoize results whenever the query or options change
  const hits = useMemo(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return all list
    // NOTE: we remap the results to match the return structure of `fuse.search()`
    () =>
      !query && options.matchAllOnEmptyQuery
        ? (fuse.getIndex() as StringIndexedObject).docs
            .slice(0, options.limit)
            .map((item, refIndex) => ({ item, refIndex }))
        : fuse.search(query, { limit: options.limit }),
    [fuse, options, query],
  );

  // pass a handling helper to speed up implementation
  const handleSearch = useCallback(
    (e) => setQuery(e.target.value.trim()),
    [setQuery],
  );

  /**
   * Handle mount
   * */
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  // still returning `setQuery` for custom handler implementations
  return {
    hits,
    handleSearch,
    setQuery,
    query,
  };
};

export default useFuse;
