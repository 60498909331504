export const BUTTON_CLASSNAME =
  'py-2 px-5 border border-neutral-200 rounded-xl';

export const ICON_PROPS = {
  icon: 'check-circle-color',
  size: 104,
  className: 'mx-auto mb-8',
};

export const CLASS_NAMES = {
  container: 'flex flex-1 flex-col m-auto',
  heading: 'text-style-c text-neutral-900 text-center mb-4',
  subHeading: 'text-style-f text-neutral-600 text-center',
  boldSubHeading: 'text-style-f-em',
  email: 'text-style-f text-neutral-500 text-center mb-8',
  buttonContainer: 'flex flex-row mx-auto md:mt-8',
};

export const TEXT = {
  heading: 'Job sent to customer',
  headingExample: 'Your example job has been created',
  customer: 'Your job has been sent to',
  customerExample:
    'Click the link in your email, or the "View checkout" button to see the customer checkout experience',
};
