import {
  StatusTag,
  SkeletonLoader,
  type StatusTagStatus,
} from '@kanda-libs/ks-design-library';

import { SKELETONS } from '../../JobHeader-constants';

interface ViewComponentProps {
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Customer
   */
  status?: StatusTagStatus;
  /**
   * Customer
   */
  archived?: boolean;
  /**
   * Customer
   */
  timestamp?: number | string | Date;
}

const ViewComponent = ({
  isLoading,
  status,
  archived,
  timestamp,
}: ViewComponentProps) => (
  <SkeletonLoader
    {...SKELETONS.status}
    isLoading={isLoading}
    afterLoading={
      <StatusTag
        status={(archived ? 'archived' : status) as StatusTagStatus}
        timestamp={timestamp as number}
        size={28}
        variant="solid"
        className="mb-2 ml-4 md:ml-0 md:mb-0 md:mr-6"
      />
    }
  />
);

ViewComponent.displayName = 'JobHeader-Status-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  status: undefined,
  archived: false,
  timestamp: undefined,
};

export default ViewComponent;
