import { Text } from '@kanda-libs/ks-design-library';
import { Widget } from '@kanda-libs/ks-component-ts';

import { TEXT, CLASS_NAMES, BASE_FILE } from './EPVSCalculations-constants';
import File from './File';

const ViewComponent = ({}) => (
  <div className={CLASS_NAMES.container}>
    <Text text={TEXT.title} className={CLASS_NAMES.title} />
    <Text text={TEXT.subtitle} className={CLASS_NAMES.subtitle} />
    <Widget.JobJobDocumentsArrayWrapper initialData={[BASE_FILE]}>
      {({ arrayName, fields }) =>
        fields.map((field, index) => (
          <File key={field.id} arrayName={arrayName} index={index} />
        ))
      }
    </Widget.JobJobDocumentsArrayWrapper>
  </div>
);

ViewComponent.displayName = 'EPVSCalculations-view';

export default ViewComponent;
