import Email from './Email';
import Name from './Name';
import Phone from './Phone';

const Fields = {
  Email,
  Name,
  Phone,
};

export default Fields;
