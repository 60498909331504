import { useWatch } from '@kanda-libs/ks-component-ts';
import type { Job } from '@kanda-libs/ks-frontend-services';
import { financeRateIsSelectable } from 'pages/CreateJob/components/PaymentAndFinanceOptions/FinanceOptions/components/SlimOption/helpers';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useMemo } from 'react';
import {
  calculateJobTotal,
  getDepositAmount,
  getDepositPercentage,
} from 'utils';
import {
  filterForValidRates,
  findCombinedRates,
  getSortedFinanceRateKeys,
  groupValidRatesByInterestRate,
  type CombinedRates,
} from 'utils/FinanceRate-function';

export interface FinanceRatesHook {
  isLoading: boolean;
  combinedRates: CombinedRates;
  hideHandle: boolean;
  keys: string[];
  unregulated: boolean;
}

export default function useFinanceRates(
  unregulated: boolean,
  useStoredJob = false,
  hideComingSoon = false,
  includeNonSelectableRates = false,
): FinanceRatesHook {
  const {
    data: { isLoading = false, company, job: storedJob },
  } = useCreateJobContextValue();

  const [formJob, formJobItems, formWorkType]: [
    Job,
    Job['job_items'],
    Job['work_type'],
  ] = useWatch({
    name: ['job', 'job.job_items', 'job.work_type'],
  });

  const [job, jobItems, workType] = useStoredJob
    ? [storedJob, storedJob?.job_items, storedJob?.work_type]
    : [formJob, formJobItems, formWorkType];

  const ratesToFormat = useMemo(() => {
    if (useStoredJob && unregulated) {
      const chosen = job?.finance_options;
      if (!chosen) return [];
      return chosen.filter((rate) => financeRateIsSelectable(rate));
    }
    return company?.finance_rates;
  }, [useStoredJob, job, company, unregulated]);

  const validNonSelectableRates = useMemo(
    () =>
      filterForValidRates(
        company?.finance_rates || [],
        workType,
        unregulated,
        hideComingSoon,
        true,
      ),
    [company, unregulated, workType, hideComingSoon],
  );

  const { totalIncVat: total } = calculateJobTotal(jobItems || []);

  const depositPercentage = useMemo(() => getDepositPercentage(job), [job]);
  const depositAmount = useMemo(() => getDepositAmount(job), [job]);

  const financeRates = useMemo(
    () => [
      ...(includeNonSelectableRates
        ? validNonSelectableRates || []
        : ratesToFormat || []),
    ],
    [ratesToFormat, includeNonSelectableRates, validNonSelectableRates],
  );

  const rates = useMemo(
    () =>
      groupValidRatesByInterestRate(
        financeRates,
        total - depositAmount,
        depositPercentage,
        depositAmount,
        workType,
        unregulated,
        hideComingSoon,
      ),
    [
      financeRates,
      total,
      depositAmount,
      depositPercentage,
      unregulated,
      workType,
      hideComingSoon,
    ],
  );

  const keys = getSortedFinanceRateKeys(rates);
  const combinedRates = findCombinedRates(rates);

  return {
    isLoading,
    combinedRates,
    keys,
    hideHandle: useStoredJob,
    unregulated,
  };
}
