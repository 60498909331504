import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const { prevStep } = useCreateJobContextValue();

  return children({
    prevStep,
  });
};

ContainerComponent.displayName = 'PaymentMethods-Desktop-Side-container';

export default ContainerComponent;
