import { StringIndexedObject, Text } from '@kanda-libs/ks-design-library';
import { FormTheme, FieldHandle } from '@kanda-libs/ks-component-ts';

import Container from './Bucket-container';
import Popover from './Popover';

export interface NotificationsModaBucketProps {
  bucket: string;
  onClick: (bucket: string) => void;
  index: number | null;
  commPreferences: StringIndexedObject;
}

const ViewComponent = (props: NotificationsModaBucketProps) => (
  <Container {...props}>
    {({ name, fieldProps, onClick }) => (
      <div className="flex flex-row justify-between py-2.5 border-b border-neutral-300 last:border-b-0">
        <div className="h-9 flex flex-row">
          <div className="my-auto px-4">
            <FormTheme variant="clean">
              <FieldHandle.Switch
                id={fieldProps?.id as string}
                name={fieldProps?.name as string}
                defaultChecked={fieldProps?.defaultChecked as boolean}
                onClick={onClick}
              />
            </FormTheme>
          </div>
          <Text
            className="my-auto text-14-22-em text-neutral-600"
            text={name}
          />
        </div>
        <Popover {...props} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'NotificationsModal-Bucket-view';

export default ViewComponent;
