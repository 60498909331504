import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { FunctionComponent, ReactNode } from 'react';
import EnterpriseRatesSuccessLayoutDesktop from './Desktop';
import EnterpriseRatesSuccessLayoutMobile from './Mobile';

export interface EnterpriseRatesSuccessLayoutProps {
  children: ReactNode;
  footer?: ReactNode;
}

const EnterpriseRatesSuccessLayout: FunctionComponent<EnterpriseRatesSuccessLayoutProps> =
  function ({ children, footer }) {
    return (
      <BreakPoints
        mobile={
          <EnterpriseRatesSuccessLayoutMobile footer={footer}>
            {children}
          </EnterpriseRatesSuccessLayoutMobile>
        }
        desktop={
          <EnterpriseRatesSuccessLayoutDesktop>
            {children}
          </EnterpriseRatesSuccessLayoutDesktop>
        }
      />
    );
  };

export default EnterpriseRatesSuccessLayout;
