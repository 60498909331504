import { capitaliseWords } from 'utils';
import Container from './StatusFilter-container';
import { FILTER_TAGS } from './StatusFilter-constants';

import Filter from '../Filter';

const ViewComponent = () => (
  <Container>
    {({ statuses, onClick }) =>
      statuses.map((status: string) => (
        <Filter
          {...FILTER_TAGS}
          label={capitaliseWords(status.replace(/_/g, ' '))}
          key={status}
          onClick={() => onClick(status)}
        />
      ))
    }
  </Container>
);

ViewComponent.displayName = 'StatusFilter-view';

export default ViewComponent;
