import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './File-container';
import { DOCUMENT_PROPS } from './File-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ index, arrayName }: ViewComponentProps) => (
  <Container index={index} arrayName={arrayName}>
    {({ error }) => (
      <Widget.JobJobDocumentsContentArrayInput
        key={index}
        index={index}
        error={error}
        {...DOCUMENT_PROPS}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'EPVSCalculations-File-view';

export default ViewComponent;
