import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './Wrapper-constants';

import { EDIT_BUTTON_PROPS } from '../../../Summary-constants';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * description
   */
  description?: string;
  /**
   * description
   */
  onEdit?(...args: unknown[]): unknown;
}

const ViewComponent = ({
  description,
  children,
  onEdit,
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <BreakPoints
      desktop={<p className={CLASS_NAMES.description}>{description}</p>}
    />
    <div className={CLASS_NAMES.content}>
      <div className={CLASS_NAMES.children}>{children}</div>
      {onEdit && (
        <BreakPoints
          mobile={<Button.Icon onClick={onEdit} {...EDIT_BUTTON_PROPS} />}
        />
      )}
    </div>
  </div>
);

ViewComponent.displayName = 'JobDetails-Wrapper-view';

ViewComponent.defaultProps = {
  description: null,
  children: null,
  onEdit: undefined,
};

export default ViewComponent;
