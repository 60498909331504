import { FormTheme } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import Fields from './Fields';

export interface PayMonthlyFormProps {
  disabled: boolean;
}

const PayMonthlyForm: React.FunctionComponent<PayMonthlyFormProps> = function ({
  disabled,
}) {
  return (
    <FormTheme variant="streamline">
      <p className="text-18-22-em mb-8">
        You&apos;re now setting up a 12-month contract with Kanda. This will be
        paid monthly and backed by a personal guarantee. Submit your details
        below to start offering finance.
      </p>
      <p className="text-16-24-em mb-4">Additional personal information</p>
      <Fields.Name />
      <Fields.DateOfBirth />
      <p className="text-16-24-em my-4">Your home address</p>
      <Fields.Address />
      <p className="text-16-24-em my-4">Company bank details</p>
      <Fields.BankDetails />
      <Fields.CheckBoxes />
      <BreakPoints
        desktop={
          <Button.Text
            id="pay-monthly-submit"
            submit
            className="w-full mt-8"
            label="Next Step"
            icon="arrow-right"
            variant="gradient"
            disabled={disabled}
          />
        }
      />
    </FormTheme>
  );
};

export default PayMonthlyForm;
