import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Logo, Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES, TITLE } from './constants';
import Desktop from './Desktop';
import Mobile from './Mobile';
import useOnboardingSteps from './useOnboardingSteps';

const OnboardingSteps = () => {
  const {
    steps,
    activeStep,
    setStep,
    isLoading,
    stepsCompleted,
    nextStepToComplete,
    completedSteps,
  } = useOnboardingSteps();

  return (
    <>
      <Logo width={108} className={CLASS_NAMES.icon} />
      <Text
        text={TITLE}
        className={CLASS_NAMES.title}
        isLoading={isLoading}
        skeletonProps={{ width: '400px' }}
      />
      <div className="flex flex-col md:flex-row items-center justify-center gap-x-6 my-auto">
        <BreakPoints
          mobile={
            <Mobile
              isLoading={isLoading}
              stepsCompleted={stepsCompleted}
              activeStep={activeStep}
              steps={steps}
              setStep={
                setStep as unknown as (step: StringIndexedObject) => void
              }
              nextStepToComplete={nextStepToComplete}
              completedSteps={completedSteps}
              isPremiumCredit={false}
            />
          }
          desktop={
            <Desktop
              isLoading={isLoading}
              stepsCompleted={stepsCompleted}
              activeStep={activeStep}
              steps={steps}
              setStep={
                setStep as unknown as (step: StringIndexedObject) => void
              }
              isPremiumCredit={false}
            />
          }
        />
      </div>
    </>
  );
};

export default OnboardingSteps;
