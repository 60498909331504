import type { Company } from '@kanda-libs/ks-frontend-services';

export const checkVerification = (company: Company): boolean => {
  if (!company) return false;
  const { users, lifecycle } = company;
  if (!['onboarded', 'completed_setup', 'verified'].includes(lifecycle || ''))
    return false;
  if (!users) return false;
  const directors = users.filter((user) => user?.director_info);
  if (directors?.length === 0) return false;
  return true;
};

export const allDirectorsVerified = (company: Company): boolean => {
  if (!company) return false;
  const users = company?.users;
  if (!users || users?.length === 0) return false;
  const directors = users.filter((user) => user?.director_info);
  if (directors?.length === 0) return false;
  const notVerified = directors.filter(
    (director) =>
      director?.director_info?.verification_status === 'not_verified',
  );
  return notVerified.length === 0;
};
