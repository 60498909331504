import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  cardBase: 'mb-4',
  cardNotLoading: 'cursor-pointer',
  cardSelected: 'border-turquoise-400',
  content: 'text-style-g text-neutral-600',
  footer: 'text-style-h text-neutral-600',
  footerOptions: 'text-style-h text-neutral-600',
};

export const SKELETONS = {
  title: true,
  option: {
    width: 28,
    height: 28,
    circle: true,
  },
  content: true,
  footer: true,
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: 'text-turquoise-300 bg-transparent',
  autoEscape: true,
};

export const BUTTON_PROPS = {
  variant: 'ghost-grey' as ButtonIconVariant,
  size: '28-16',
  icon: 'edit',
};
