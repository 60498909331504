import { Referral } from '@kanda-libs/ks-frontend-services';

export type FormattedLifecyle = 'sent' | 'registered' | 'subscribed';

export interface EnhancedReferral extends Referral {
  formattedLifecyle: FormattedLifecyle;
}

export const formatLifecycle = (lifecycle?: string): FormattedLifecyle => {
  if (!lifecycle) return 'sent';
  if (lifecycle === 'registered') return 'registered';
  if (lifecycle === 'subscribed') return 'subscribed';
  return 'subscribed';
};

export const formatReferrals = (referrals: Referral[]): EnhancedReferral[] =>
  referrals.map((referral: Referral) => ({
    ...referral,
    formattedLifecyle: formatLifecycle(referral?.lifecycle),
  }));
