import { Icon, Text } from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';

export interface BulletsProps {
  bullets: string[];
  isLoading?: boolean;
}

const Bullets: FunctionComponent<BulletsProps> = ({
  bullets,
  isLoading = false,
}) => (
  <div className="flex flex-col gap-y-3">
    {bullets.map((bullet: string) => (
      <div className="flex flex-row" key={bullet}>
        <div className="rounded-full bg-green-200 p-1.5 mb-auto mr-3">
          <Icon icon="check" stroke="green-600" size={12} className="min-w-3" />
        </div>
        <Text
          isLoading={isLoading}
          className="text-16-24 w-full"
          text={bullet}
          skeletonClassName="w-full"
        />
      </div>
    ))}
  </div>
);

export default Bullets;
