import {
  type AuthUser,
  revalidateUser,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface MeHook {
  me?: AuthUser;
  isValidating: boolean;
  revalidateMe: () => Promise<unknown>;
  isAdmin: boolean;
}

export default function useMe(): MeHook {
  const dispatch = useAppDispatch();
  const me = useSelector(selectors.getUser);

  const isAdmin = me?.role !== 'company-staff';

  const revalidateMe = useCallback(
    async () => dispatch(revalidateUser()),
    [dispatch],
  );

  return {
    me,
    isValidating: false,
    revalidateMe,
    isAdmin,
  };
}
