export const CLASS_NAMES = {
  title: 'text-neutral-700',
  baseContainer: 'my-4 overflow-hidden relative',
  collapsed: 'h-48',
  gradient:
    'h-8 absolute bottom-0 left-0 w-full bg-gradient-to-t from-neutral-000 to-transparent-white',
};

export const CARD_PROPS = {
  className: 'shadow-card cursor-pointer mb-12',
  showLoadingSkeleton: {
    title: true,
    option: true,
    footer: true,
  },
};

export const ICON_PROPS = {
  icon: 'chevron-right',
  size: 16,
  stroke: 'neutral-500',
};

export const LINE_ITEM_PROPS = {
  totalPriceProps: {
    poundsClassName: 'text-style-f-em my-auto',
    centsClassName: 'text-style-k-em',
    color: 'neutral-500',
  },
};
