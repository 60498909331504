export const TITLE = 'Change your email';

export const DESCRIPTION = "You'll have to confirm your password to do this";

export const FIELD_NAMES = {
  currentPassword: 'currentPassword',
  email: 'email',
};

const CURRENT_PASSWORD = {
  currentPassword: {
    name: FIELD_NAMES.currentPassword,
    label: 'Password',
    placeholder: 'Your password',
    autoComplete: 'password',
  },
};

const EMAIL = {
  email: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'email',
    label: 'New Email Address',
    name: FIELD_NAMES.email,
    placeholder: 'Your new email address',
  },
};

export const EMAIL_VALIDATION = {
  required: {
    value: true,
    message: 'Email address is required',
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Email must be a valid email address',
  },
};

export const WRAP_FIELDS = {
  ...CURRENT_PASSWORD,
  ...EMAIL,
};
