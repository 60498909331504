import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { type MouseEvent, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY,
  SEND_REMINDER_MODAL_ID,
} from './SendReminderModal/constants';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import useCurrentJob from '../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.job.getIsSubmitting);
  const onError = useApiError(
    'Error sending reminder - please contact Kanda for support',
  );

  const { showModal, hideModal } = useContext(ModalsWrapperContext);

  const { job, checkJob, isLoading } = useCurrentJob();
  const { company, isLoading: companyIsLoading } = useCurrentCompany();

  const showInfoModal =
    window.localStorage.getItem(DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY) !== 'true';

  const financeStatus = useMemo(() => {
    if (!job) return null;
    if (!checkJob?.has_finance_application) return null;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const showButton = useMemo(() => {
    if (!job || !company || isLoading || companyIsLoading) return false;

    if (['accepted'].includes(job?.status || '')) return false;

    if (
      ![
        'not_applied',
        'applied_for_finance',
        'financed',
        'sat_note_sent',
        'sat_note_viewed',
      ].includes(job?.finance_status || '')
    )
      return false;

    if (
      ['sat_note_sent', 'sat_note_viewed'].includes(job?.finance_status || '')
    )
      return true;

    if (['referred', 'accepted'].includes(financeStatus || '')) return false;

    return true;
  }, [job, isLoading, company, financeStatus, companyIsLoading]);

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!job?.id) return;

      dispatch(
        actions.resendJob({
          params: { id: job.id },
          onError,
          onSuccess: () => {
            if (showInfoModal) showModal(SEND_REMINDER_MODAL_ID);
          },
        }),
      );
    },
    [job, dispatch, showModal, showInfoModal, onError],
  );

  const onClickDontShowAgain = () => {
    window.localStorage.setItem(DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY, 'true');
    hideModal(SEND_REMINDER_MODAL_ID);
  };

  return children({
    showButton,
    isSubmitting,
    onClick,
    onClickDontShowAgain,
    companyIsLoading,
  });
};

ContainerComponent.displayName = 'Job-Title-container';

export default ContainerComponent;
