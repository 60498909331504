import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';

import Container from './Fixed-container';

const ViewComponent = () => (
  <Container>
    {({ showFields, validation }) =>
      showFields ? (
        <div className="flex flex-row w-full justify-between md:ml-4">
          <div className="mt-3 -mb-8 md:-mt-1.5 md:-mb-8 w-full">
            <FormTheme variant="default">
              <Widget.JobDepositValueAmount
                label=""
                validation={validation}
                placeholder="£0.00"
                type="price"
              />
            </FormTheme>
          </div>
        </div>
      ) : (
        <></>
      )
    }
  </Container>
);

ViewComponent.displayName = 'CreateJob-Desktop-Deposit-Fields-Fixed-view';

export default ViewComponent;
