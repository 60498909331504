import { Button } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import Modal from './Modal';
import useMobileModal from './useMobileModal';

interface MobileProps {
  financeOption: FinanceRate;
  totalCost: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  interestLabel: string;
  durationLabel: string;
  rateWithinBounds: boolean;
  depositIsAboveMinimum?: boolean;
}

const Mobile = ({ ...props }: MobileProps) => {
  const { financeOption } = props;
  const { onClick } = useMobileModal(financeOption);

  return (
    <>
      <div className="relative w-3 min-w-3">
        <Button.Icon
          id={`create-job-kanda-finance-options-mobile-info-${financeOption.name}`}
          icon="info"
          iconProps={{ className: 'w-3 -mt-1 text-neutral-500 cursor-pointer' }}
          onClick={onClick}
        />
      </div>
      <Modal {...props} />
    </>
  );
};

export default Mobile;
