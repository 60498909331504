import { Widget } from '@kanda-libs/ks-component-ts';

import { PRICE_COMPONENT_PROPS } from './Price-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Widget.JobJobItemsPriceAmountArrayInput
    {...PRICE_COMPONENT_PROPS}
    {...props}
    name="price.amount"
  />
);
// DEV_NOTE: remove `name` above when widget ready

ViewComponent.displayName = 'Item-Price-view';

export default ViewComponent;
