import { useCallback, useContext, useMemo } from 'react';
import {
  DropzoneContext,
  useFormContext,
  useWatch,
} from '@kanda-libs/ks-component-ts';
import type { Company } from '@kanda-libs/ks-frontend-services';

const ContainerComponent = ({ children }) => {
  const droppedContext = useContext(DropzoneContext);
  const droppedFiles = droppedContext?.droppedFiles || null;

  const fileContent = useWatch({
    name: 'company.company_info.insurance_document.content',
  });

  const {
    formState: { errors },
  } = useFormContext();

  const inputFile = useMemo(() => {
    if (!droppedFiles) return null;
    const file = droppedFiles[0];
    if (!file) return null;
    return file;
  }, [droppedFiles]);

  const fileError = (errors?.company as unknown as Company)?.company_info
    ?.insurance_document?.content
    ? 'Personal liability insurance policy is required'
    : null;

  const parseDate = useCallback((date: string) => {
    const formatted = date?.replace(/ /g, '')?.split('/');
    if (formatted?.length !== 3) return null;
    const reordered = [formatted[1], formatted[0], formatted[2]]?.join('/');
    const parsed = new Date(reordered);
    if (
      (!Object.prototype.toString.call(parsed) as unknown as string) ===
      '[object Date]'
    )
      return null;
    if (Number.isNaN(parsed.getTime())) return null;
    return parsed as Date;
  }, []);

  const validateDate = useCallback(
    (inputDate: string) => {
      const date = parseDate(inputDate);
      return !!date;
    },
    [parseDate],
  );

  const futureDate = useCallback(
    (inputDate: string) => {
      const date = parseDate(inputDate);
      const today = new Date();
      if (!date) {
        return false;
      }
      const difference = date.getTime() - today.getTime();
      return difference > 0;
    },
    [parseDate],
  );

  const validation = {
    validate: {
      value: {
        valid: (value: string) => validateDate(value),
        future: (value: string) => futureDate(value),
      },
      message: {
        valid: 'Date must be a valid date',
        future: 'Date must be a future date',
      },
    },
  };
  const showExpiry = useMemo(() => {
    if (!fileContent) return false;
    return true;
  }, [fileContent]);

  return children({
    inputFile,
    fileError,
    showExpiry,
    validation,
  });
};

ContainerComponent.displayName = 'InsuranceInfoForm-container';

export default ContainerComponent;
