import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const number = data?.job?.customer?.phone;

  const phoneNumber = `tel:${number}`;

  return children({
    number,
    phoneNumber,
  });
};

ContainerComponent.displayName =
  'CreateJob-SendConfirmation-CallButton-container';

export default ContainerComponent;
