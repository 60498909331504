import { useFormContext } from '@kanda-libs/ks-component-ts';
import * as EmailValidator from 'email-validator';

export default function useDirectorEmailValidation(index) {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: (value) => {
        const directorsExcludingCurrent = getValues().company.users.filter(
          (_, userIndex) => userIndex !== index,
        );
        const directorEmailsExcludingCurrent = directorsExcludingCurrent.map(
          ({ email }) => email,
        );
        const validEmail = EmailValidator.validate(value);
        return (
          value &&
          validEmail &&
          directorEmailsExcludingCurrent.indexOf(value) === -1
        );
      },
      message:
        "Please enter a valid email that hasn't been used for another director",
    },
  };
}
