import { FormTheme, FieldHandle } from '@kanda-libs/ks-component-ts';

import Container from './Wrapper-container';

export interface ViewComponentProps {
  /**
   * Payment method
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * Title
   */
  title?: string;
  /**
   * Deposit type
   */
  depositType?: string;
  /**
   * On click function
   */
  onClick: (name: string) => void;
}

const ViewComponent = ({
  children,
  title,
  depositType = '',
  onClick,
}: ViewComponentProps) => (
  <Container depositType={depositType}>
    {({ classNames }) => (
      <div className={classNames.container}>
        <label htmlFor={depositType} className="flex flex-row my-auto">
          <FormTheme variant="clean">
            <FieldHandle.RadioCheck
              id={depositType}
              name={depositType}
              onClick={() => onClick(depositType)}
            />
          </FormTheme>
          <span className={classNames.text}>{title}</span>
        </label>
        {children}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Desktop-Deposit-Fields-Wrapper-view';

ViewComponent.defaultProps = {
  children: null,
};

export default ViewComponent;
