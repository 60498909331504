interface LineItemSummaryProps {
  interestLabel: string;
  durationLabel: string;
}

const LineItemSummary = ({
  interestLabel,
  durationLabel,
}: LineItemSummaryProps) => (
  <div className="py-5 px-4 bg-neutral-100 text-16-20-em text-neutral-600 rounded-xl flex items-center">
    {interestLabel} interest
    <div className="w-1 h-1 bg-neutral-400 rounded-full mx-2" />
    {durationLabel}
  </div>
);

export default LineItemSummary;
