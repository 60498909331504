import { Form, useForm } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import { Debit, Referral, Terms, USP } from './components';
import BulletPointUSP from './components/BulletPointUSP';
import { YEARLY_PRICE } from './SubscribeForm-constants';
import useSubscribeForm from './useSubscribeForm';

interface ViewComponentProps {
  showBulletPointList?: boolean;
  showReferralCode?: boolean;
  hideUSP?: boolean;
}

const ViewComponent = ({
  showBulletPointList,
  showReferralCode,
  hideUSP = false,
}: ViewComponentProps) => {
  const form = useForm();
  const {
    loading,
    terms,
    debit,
    onClickTerms,
    onClickDebit,
    createSubscription,
    onClickHelp,
  } = useSubscribeForm();

  const canSubmitForm = terms && debit;

  return (
    <Form id="account-settings-subscribe" form={form} onSubmit={() => {}}>
      <div className="flex flex-col flex-1 -mb-6 md:mb-0">
        <div className="w-full mx-auto mb-auto max-w-96">
          {showBulletPointList && !hideUSP && <BulletPointUSP />}
          {!showBulletPointList && !hideUSP && <USP />}
          <Terms onClick={onClickTerms} />
          <Debit onClick={onClickDebit} />
        </div>
        <span className="p-4 mb-6 text-center rounded text-16-20-em text-neutral-000 bg-neutral-800">
          Price includes all FCA & compliance fees
        </span>
        <Button.Text
          id="subscribe-form-yearly"
          variant="gradient"
          className="mb-2"
          disabled={!canSubmitForm || loading}
          onClick={() => {
            createSubscription();
          }}
        >
          <span>Yearly subscription - £{YEARLY_PRICE / 100} + VAT</span>
        </Button.Text>

        <Button.InlineLink
          id="subscribe-form-request-a-demo"
          onClick={onClickHelp}
          variant="turquoise"
          className="mx-auto mt-4"
        >
          Not ready? View a demo here
        </Button.InlineLink>

        {showReferralCode && <Referral />}
      </div>
    </Form>
  );
};

ViewComponent.displayName = 'SubscribeForm-view';

ViewComponent.defaultProps = {
  showBulletPointList: false,
  showReferralCode: false,
};

export default ViewComponent;
