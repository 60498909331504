import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

interface ViewComponentProps {
  children?: JSX.Element | JSX.Element[];
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>{children}</DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.displayName = 'SubscriptionReturn-Desktop-view';

export default ViewComponent;
