import type { FunctionComponent, ReactNode } from 'react';

import { BreakPoints } from '@kanda-libs/ks-design-library';
import HomeLayoutDesktop from './Desktop';
import HomeLayoutMobile from './Mobile';

export interface HomeLayoutProps {
  children: ReactNode;
}

const HomeLayout: FunctionComponent<HomeLayoutProps> = function ({ children }) {
  return (
    <BreakPoints
      mobile={<HomeLayoutMobile>{children}</HomeLayoutMobile>}
      desktop={<HomeLayoutDesktop>{children}</HomeLayoutDesktop>}
    />
  );
};

export default HomeLayout;
