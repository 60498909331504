export const DEPOSIT_PERCENTAGE_NAME = 'depositPercentage';

export const DEPOSIT_PERCENTAGE_VALIDATION = {
  min: {
    value: 0,
    message: 'Deposit percentage must be greater than or equal to 0%',
  },
  max: {
    value: 75,
    message: 'Deposit percentage must be less than or equal to 75%',
  },
};
