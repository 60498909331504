import { SkeletonLoader } from '@kanda-libs/ks-design-library';

import Container from './Google-container';

import GoogleLogo from '../../assets/g-logo.png';

interface ViewComponentProps {
  /**
   * Disabled state for social buttons
   */
  socialDisabled?: boolean;
  /**
   * Function to sign in with Google
   */
  signInWithGoogle?(...args: unknown[]): unknown;
  /**
   * is loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({
  isLoading,
  socialDisabled,
  signInWithGoogle,
}: ViewComponentProps) => (
  <Container signInWithGoogle={signInWithGoogle}>
    {({ onClick }) => (
      <SkeletonLoader
        isLoading={isLoading}
        width="100%"
        height={44}
        wrapperClassName="w-full"
        afterLoading={
          <button
            className="relative flex flex-row w-full h-12 p-3 mb-4 rounded font-roboto social-shadow md:mb-0 md:mr-4 rounded-3xl bg-neutral-000"
            type="button"
            id="google_sign_in"
            disabled={socialDisabled}
            onClick={onClick}
          >
            <img
              src={GoogleLogo}
              alt="g-logo"
              className="absolute w-6 h-6 left-2 top-1/2 transform -translate-y-1/2"
            />
            <p className="w-full my-auto text-center text-14-22 whitespace-nowrap">
              Sign in with Google
            </p>
          </button>
        }
      />
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  socialDisabled: undefined,
  signInWithGoogle: undefined,
};

ViewComponent.displayName = 'Login-Google-view';

export default ViewComponent;
