import { Icon, LoadingSpinner } from '@kanda-libs/ks-design-library';

import Container from './ValidateEmail-container';

import GetName from '../../common/GetName';

interface ViewComponentProps {
  /**
   * children
   */
  children: (args: { suggestion: string }) => JSX.Element;
  /**
   * children
   */
  iconClassName?: string;
}

const ViewComponent = ({
  children,
  iconClassName = '',
}: ViewComponentProps) => (
  <Container>
    {({ setEmailName, isSubmitting, iconProps, suggestion, showIcon }) => (
      <div className="flex flex-row w-full">
        <div className="w-full">
          <GetName callback={setEmailName}>{children({ suggestion })}</GetName>
        </div>
        {isSubmitting ? (
          <LoadingSpinner
            size={16}
            className={iconClassName}
            stroke="green-500"
          />
        ) : (
          <>
            {showIcon && (
              <Icon size={16} {...iconProps} className={iconClassName} />
            )}
          </>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  iconClassName: 'mb-auto mt-11 ml-4',
};

ViewComponent.displayName = 'ValidateEmail-view';

export default ViewComponent;
