import type { Contract } from './context';

export const formatContractName = (contract: Contract) => {
  const parts = contract.name.split('.');
  const file = parts[1] || 'pdf';
  return [
    [
      parts[0],
      contract?.signee ? contract.signee.split(' ').join('-') : 'no-signee',
      contract?.timestamp || 'no-timestamp',
    ].join('_'),
    file,
  ].join('.');
};
