import { useForm } from '@kanda-libs/ks-component-ts';
import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { LEGAL_TEXT } from './constants';

export interface LegalTextHook {
  form: ReturnType<typeof useForm>;
  text: string;
}

export default function useLegalText(): LegalTextHook {
  const form = useForm();
  const { company } = useCurrentCompany();

  const text = useMemo(
    () =>
      LEGAL_TEXT.replace('$company', company?.contact_info?.trading_name || ''),
    [company],
  );

  return { form, text };
}
