import { Button } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import JobTableFilterModal from './JobTableFilterModal';
import useToggleJobTableFilterModal from './useToggleJobTableFilterModal';

const Mobile: FunctionComponent = function () {
  const { onShow } = useToggleJobTableFilterModal();
  return (
    <div>
      <Button.Icon
        id="home-job-table-filter-mobile"
        icon="filter"
        onClick={() => {
          onShow();
        }}
      />
      <JobTableFilterModal />
    </div>
  );
};

export default Mobile;
