import {
  useMultiStepFormContext,
  type MultiStepFormContextValue,
} from '@kanda-libs/ks-component-ts';
import type { CreateJobContextValue } from './types';

export type CreateJobContextValueHook =
  MultiStepFormContextValue<CreateJobContextValue>;

export default function useCreateJobContextValue(): CreateJobContextValueHook {
  return useMultiStepFormContext<CreateJobContextValue>();
}
