import { Layout, Header } from '@kanda-libs/ks-design-library';

import { Content } from '../components';

const ViewComponent = ({}) => (
  <Layout noBorder header={<Header.Base lines help />}>
    <Content />
  </Layout>
);

ViewComponent.displayName = 'ClaimResent-Mobile-view';

export default ViewComponent;
