import { Button } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';

const ReferralsBackButton: React.FunctionComponent = function () {
  return (
    <div className="flex flex-row">
      <Link to={URLS.referrals}>
        <Button.Icon id="referrals-invites-back-button" icon="arrow-left" />
      </Link>
      <p className="ml-3 text-24-28-em md:text-28-32-em my-auto">Refer users</p>
    </div>
  );
};

export default ReferralsBackButton;
