import {
  BreakPoints,
  Button,
  Loader,
  SkeletonLoader,
} from '@kanda-libs/ks-design-library';
import { TrustPilotEmbed } from 'components';
import type { FunctionComponent } from 'react';
import EnterpriseRatesLayout from '../Layout';
import SubHeaderPoints from './SubHeaderPoints';
import EnterpriseRatesTable from './Table';
import useEnterpriseRatesContent from './useEnterpriseRatesContent';

const EnterpriseRatesContent: FunctionComponent = function () {
  const { onClick, isSubmitting, enterpriseRates, userRates } =
    useEnterpriseRatesContent();
  return (
    <EnterpriseRatesLayout
      footer={
        <div className="w-full flex">
          <Button.Text
            id="purchase-enterprise-rates"
            label="Apply Now and Pay"
            icon="arrow-right"
            variant="gradient"
            onClick={onClick}
            disabled={isSubmitting}
            className="ml-auto"
          />
        </div>
      }
    >
      <div className="flex flex-col flex-1 w-full max-w-216 mx-auto">
        <div className="flex flex-col w-full max-w-120 md:max-w-full mx-auto">
          <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-10">
            Pay reduced fees on the following rates
          </p>
          <SubHeaderPoints />
          <div className="flex flex-col w-full md:px-10 md:py-8 md:border border-neutral-300 rounded-lg md:rounded-xl mt-8">
            <div className="flex flex-col w-full md:w-120 mx-auto">
              {enterpriseRates ? (
                <EnterpriseRatesTable
                  rates={enterpriseRates}
                  originalRates={userRates}
                />
              ) : (
                <SkeletonLoader width="100%" height={200} isLoading />
              )}
              <BreakPoints
                desktop={
                  <Button.Text
                    id="purchase-enterprise-rates"
                    label="Apply Now and Pay"
                    icon="arrow-right"
                    variant="gradient"
                    onClick={onClick}
                    disabled={isSubmitting}
                    className="mt-6"
                  />
                }
              />
              <TrustPilotEmbed.MicroCombo className="mt-6" />
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isSubmitting} />
    </EnterpriseRatesLayout>
  );
};

export default EnterpriseRatesContent;
