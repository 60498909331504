export const CLASSNAMES = {
  container: 'flex flex-col mt-3 px-2 md:mt-0 md:px-0',
  row: 'flex flex-row flex-wrap',
  text: 'text-style-i-em-up text-neutral-600 md:text-style-h-em',
  separator: 'border-b border-neutral-300 w-full mb-3 ',
};

export const SUBTOTAL_PROPS = {
  wrapperClassName: 'ml-auto mb-2',
  poundsClassName: 'text-style-f-em md:text-style-e',
  centsClassName: 'text-style-k-em mt-px',
  color: 'neutral-700 md:text-neutral-500',
};

export const VATTOTAL_PROPS = {
  wrapperClassName: 'ml-auto mb-2',
  poundsClassName: 'text-style-f-em md:text-style-e',
  centsClassName: 'text-style-k-em mt-px',
  color: 'neutral-700 md:text-neutral-500',
};

export const TOTAL_PROPS = {
  wrapperClassName: 'ml-auto mb-2',
  color: 'green-500 ',
};

export const DEPOSIT_PROPS = {
  wrapperClassName: 'ml-auto',
  color: 'green-500 ',
};
