import {
  MultiStepForm,
  MultiStepFormRouter,
  StringIndexedObject,
} from '@kanda-libs/ks-component-ts';

import Pages from './components/Pages';
import Container from './Mobile-container';

interface ViewComponentProps {
  /**
   * initialData
   */
  initialData?: StringIndexedObject;
}

const ViewComponent = ({ initialData = {} }: ViewComponentProps) => (
  <Container>
    {({ steps, onSubmit }) => (
      <MultiStepForm
        steps={steps}
        key={initialData.isLoading}
        initialData={initialData}
        initialStepIndex={0}
        onSubmit={onSubmit}
        addStepsToUrl={false}
      >
        <MultiStepFormRouter pages={Pages} />
      </MultiStepForm>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  initialData: {},
};

ViewComponent.displayName = 'CreateJob-Mobile-view';

export default ViewComponent;
