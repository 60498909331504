export const ONBOARDING_STEPS = {
  VERIFYING_YOUR_IDENTITY: 'verifying-your-identity',
  VALIDATE_DIRECTOR_INFO: 'validate-director-info',
  IDENTIFY: 'identify',
  VERIFICATION_STATUS: 'verification-status',
};

export const STEPS = Object.values(ONBOARDING_STEPS);

export const CLASS_NAMES = {
  page: 'flex flex-1 flex-col justify-center items-center h-full',
  content: 'flex flex-col items-center md:pb-10 md:mb-24 md:mt-24',
  title: 'text-24-28-em text-neutral-900 text-center mb-6 ',
  description: 'text-16-24 text-neutral-600 text-center',
  icon: 'mb-8',
};

export const ICON_PROPS = {
  size: 104,
  className: CLASS_NAMES.icon,
};

export const INITIAL_DATA = {
  jobType: 'kanda',
};
