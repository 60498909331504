import { Layout, Header } from '@kanda-libs/ks-design-library';

import { Content } from '../components';

interface ViewComponentProps {
  /**
   * is loading state
   */
  isLoading?: boolean;
  /**
   * is invalid state
   */
  isInvalid?: boolean;
}

const ViewComponent = ({ isLoading, isInvalid }: ViewComponentProps) => (
  <Layout noBorder header={<Header.Base lines />}>
    <Content isLoading={isLoading} isInvalid={isInvalid} />
  </Layout>
);

ViewComponent.displayName = 'VerifyEmail-Landing-Mobile-view';

ViewComponent.defaultProps = {
  isLoading: false,
  isInvalid: false,
};

export default ViewComponent;
