import { Field, Label } from '@kanda-libs/ks-component-ts';
import type { FunctionComponent } from 'react';
import { NO_RATES_LABEL } from './constants';
import useWorkTypeField from './useWorkTypeField';

const WorkTypeField: FunctionComponent = function () {
  const { props, label, showNoRates } = useWorkTypeField();
  return (
    <>
      <Label {...label} />
      <Field.Validator
        validation={{
          required: { value: true, message: 'Please enter a work type' },
        }}
      >
        <Field.FilterableSelect {...props} />
      </Field.Validator>
      {showNoRates && (
        <p className="p-4 mb-6 text-orange-200 border border-orange-200 border-solid rounded-xl text-neutral-600 amber-warning">
          {NO_RATES_LABEL}
        </p>
      )}
    </>
  );
};

export default WorkTypeField;
