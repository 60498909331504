export const ICON_PROPS = {
  size: 24,
  stroke: 'neutral-600',
};

export const CLASS_NAMES = {
  container: 'flex flex-row w-full items-center mb-6 lg:mb-0',
  title:
    'text-style-g-em text-neutral-700 ml-4 align-center block leading-none',
  badge:
    'ml-2 bg-turquoise-200 h-5 min-w-5 rounded-full text-turquoise-500 text-style-h-em leading-5 text-center',
};

export const SKELETONS = {
  icon: {
    width: 24,
    height: 24,
  },
  title: {
    width: 100,
  },
};
