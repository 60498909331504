import Placeholder from '../Placeholder';

export const DOCUMENT_PROPS = {
  label: '',
  centerPlaceholder: true,
  placeholder: <Placeholder />,
  maxFiles: 1,
  maxSize: 20 * 1024 * 1024,
  accept: ['application/pdf'],
  compressImages: true,
  validation: {
    required: { value: true, message: 'Please provide a valid file' },
  },
};
