import { Button } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import Container from './AddUserButton-container';
import { BUTTON_PROPS } from './AddUserButton-constants';

export interface ViewComponentProps {
  append: (value: UserType) => void;
  fields: UserType[];
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Container {...props}>
    {({ handleAddUser, showButton }) =>
      showButton ? (
        <Button.Text onClick={handleAddUser} {...BUTTON_PROPS} />
      ) : (
        <></>
      )
    }
  </Container>
);

ViewComponent.displayName = 'AddUserButton-view';

export default ViewComponent;
