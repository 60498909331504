import { useFormContext } from '@kanda-libs/ks-component-ts';
import * as EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { APP_ENV } from 'config';
import { OMNI_RESTRICTED_EMAIL_ADDRESSES } from '../../../../../../../../../../../constants';

export default function useContactValidation() {
  const { getValues } = useFormContext();

  const email = {
    validate: {
      value: {
        valid: () => EmailValidator.validate(getValues()?.job?.customer?.email),
        restricted: () => {
          if (APP_ENV === 'qa') return true;
          const prefix = getValues()?.job?.customer?.email?.split('@')[0];
          return !OMNI_RESTRICTED_EMAIL_ADDRESSES.includes(`${prefix}@`);
        },
      },
      message: {
        valid: 'Email address is invalid',
        restricted: `Email cannot start with "${
          getValues()?.job?.customer?.email?.split('@')[0]
        }"`,
      },
    },
  };

  const phone = {
    validate: {
      value: () => isValidPhoneNumber(getValues()?.job?.customer?.phone, 'GB'),
      message: 'Phone number is invalid',
    },
  };

  return {
    email,
    phone,
  };
}
