import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatDate } from './helpers';
import { cleanObject } from '../../../../utils';

/**
 * Hook for handling director identity form submission
 * @param {string} cid
 * @param {object} director
 * @param {function} onSuccessfulSubmit
 * @return {{onSubmit: onSubmit, isSubmitting: boolean}}
 */
export default function useDirectorForm(cid, director, onSuccessfulSubmit) {
  const dispatch = useAppDispatch();
  const onError = useApiError('There was an error updating your information');

  const isSubmitting = useSelector(
    selectors.postCompanyDirectorVerification.getIsSubmitting,
  );

  const onSubmit = (values) => {
    const body = cleanObject({
      ...values?.director_verification,
      first_name: director?.first_name,
      last_name: director?.last_name,
      date_of_birth: formatDate(values?.director_verification?.date_of_birth),
    });
    const params = cleanObject({
      id: cid,
      email: values?.director_verification?.email,
    });

    dispatch(
      actions.postCompanyDirectorVerification({
        body,
        params,
        onError,
        onSuccess: () => {
          onSuccessfulSubmit();
        },
      }),
    );
  };

  return {
    onSubmit,
    isSubmitting,
  };
}
