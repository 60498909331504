import { useSelector } from 'react-redux';
import { selectors } from 'store';
import type { InformationRequiredBannerProps } from '.';

export interface InformationRequiredHook {
  showBanner: boolean;
  reason?: string;
  note?: string;
}

export default function useInformationRequired(
  stage: InformationRequiredBannerProps['stage'],
): InformationRequiredHook {
  const isLoading = useSelector(selectors.getIsLoading);
  const [data] = useSelector(selectors.infoOnboarding.getEntitiesAsArray);

  const showBanner = !isLoading && data?.current_stage === stage;

  return {
    showBanner,
    reason: data?.current_approval?.reason,
    note: data?.current_approval?.reason_note,
  };
}
