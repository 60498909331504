import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { ADD_A_JOB_STEPS } from '../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { data, setStep } = useCreateJobContextValue();

  const lineItems = data?.job?.job_items;

  return children({
    lineItems,
    onClick: () => {
      if (setStep) setStep(ADD_A_JOB_STEPS.DETAILS);
    },
  });
};

ContainerComponent.displayName = 'JobDetails-LineItems-container';

export default ContainerComponent;
