import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './ClaimResent-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <Container>
    <Helmet title="Claim Resent" />
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </Container>
);

ViewComponent.displayName = 'ClaimResent-view';

export default ViewComponent;
