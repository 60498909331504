import { Button } from '@kanda-libs/ks-design-library';

import { BASE_PROPS } from '../SaveAsDraftButton-constants';

const ViewComponent = ({ ...props }) => (
  <Button.Text {...BASE_PROPS} size={48} className="mr-4" {...props} />
);

ViewComponent.displayName = 'CreateJob-SaveDraftButton-Desktop-view';

export default ViewComponent;
