import { useRef, useEffect, MutableRefObject } from 'react';

interface ContainerComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * callback
   */
  callback?: (name: string) => void;
}

const ContainerComponent = ({
  children,
  callback,
}: ContainerComponentProps) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current || !callback) return;
    const id = (ref?.current?.firstChild as HTMLDivElement)?.id?.replace(
      '-wrapper',
      '',
    );
    callback(id);
  }, [callback]);

  if (!children) return null;

  return <div ref={ref as MutableRefObject<HTMLDivElement>}>{children}</div>;
};

ContainerComponent.displayName = 'GetName-container';

ContainerComponent.defaultProps = {
  children: undefined,
  callback: undefined,
};

export default ContainerComponent;
