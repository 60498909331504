import { Button } from '@kanda-libs/ks-design-library';
import SignUpFormCard from '../Card';
import FacebookButton from '../FacebookButton';
import GoogleButton from '../GoogleButton';
import useFormButtons from './useFormButtons';

const SignUpFormButtons: React.FunctionComponent = function () {
  const {
    signUpWithGoogle,
    signUpWithFacebook,
    signUpWithEmail,
    isSubmitting,
    isLoading,
  } = useFormButtons();
  return (
    <SignUpFormCard>
      <div className="grid grid-cols-1 gap-4 w-full max-w-80 mx-auto">
        <GoogleButton
          onClick={signUpWithGoogle}
          disabled={isSubmitting}
          isLoading={isLoading}
        />
        <FacebookButton
          onClick={signUpWithFacebook}
          disabled={isSubmitting}
          isLoading={isLoading}
        />
        <Button.Text
          id="email-sign-up"
          variant="outline"
          onClick={signUpWithEmail}
          label="Sign up with email"
          disabled={isSubmitting || isLoading}
        />
      </div>
    </SignUpFormCard>
  );
};

export default SignUpFormButtons;
