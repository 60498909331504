import { Form, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Popover, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';

import { JOB_SETTINGS_OPTIONS } from '../JobSettings-constants';
import InvoiceSettingsPopover from './InvoiceSettingsPopover';
import useJobSettingsForm from './useJobSettingsForm';

import RequestNewRates from '../../RequestNewRates';
import RequestOwnDeposit from '../../RequestOwnDeposit';

const Content: FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useJobSettingsForm();
  return (
    <div className="flex flex-col">
      <Form id="account-settings-job-settings" form={form} onSubmit={onSubmit}>
        <label
          className="flex w-full mb-6 text-style-h-em text-neutral-600 gap-x-1"
          htmlFor="customer_details.email"
        >
          <Text
            text="Invoice settings"
            className="text-16-20-em text-neutral-900"
          />
          <Popover.Standard
            id="invoice-settings-popover"
            above
            button={
              <div className="w-4 h-4">
                <Button.Icon
                  id="invoice-settings-popover"
                  icon="info"
                  size="28-16"
                  className="-mt-1.5 -ml-1"
                />
              </div>
            }
          >
            {({ handleClose }) => (
              <InvoiceSettingsPopover onClose={handleClose} />
            )}
          </Popover.Standard>
        </label>
        <Widget.CompanyQuotePreference
          options={JOB_SETTINGS_OPTIONS}
          label=""
        />
        <Button.Text
          submit
          id="account-settings-job-settings-submit"
          className="mb-4 -mt-2"
          disabled={isSubmitting}
          label="Submit"
        />
      </Form>
      <RequestNewRates />
      <RequestOwnDeposit />
    </div>
  );
};

export default Content;
