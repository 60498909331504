import { Text } from '@kanda-libs/ks-design-library';

import Container from './Description-container';

const ViewComponent = () => (
  <Container>
    {({ heading, subHeading }) => (
      <div className="flex flex-col">
        <Text text={heading} className="text-14-22-em text-neutral-700 mb-2" />
        <Text text={subHeading} className="text-12-18 text-neutral-700" />
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Description-view';

export default ViewComponent;
