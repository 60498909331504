import { Layout, Header } from '@kanda-libs/ks-design-library';

interface ViewComponentProps {
  children?: JSX.Element | JSX.Element[];
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <Layout noBorder header={<Header.Base lines />}>
    {children}
  </Layout>
);

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.displayName = 'SubscriptionReturn-Mobile-view';

export default ViewComponent;
