import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';

import {
  EXAMPLE_JOB_EMAIL_TEXT,
  EXAMPLE_JOB_TITLE,
  NO_CUSTOMER_TEXT,
  NO_EMAIL_TEXT,
  TITLE,
} from './Body-constants';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const customer = data?.job?.customer;

  const isExampleJob = useIsExampleJob();

  const name =
    `${customer?.first_name} ${customer?.last_name}` || NO_CUSTOMER_TEXT;
  const email = isExampleJob
    ? EXAMPLE_JOB_EMAIL_TEXT
    : customer?.email || NO_EMAIL_TEXT;
  const title = isExampleJob ? EXAMPLE_JOB_TITLE : `${TITLE} ${name}`;

  return children({
    name,
    email,
    title,
  });
};

ContainerComponent.displayName = 'CreateJob-SendConfirmation-Body-container';

export default ContainerComponent;
