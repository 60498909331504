export const BASE_BUTTON_PROPS = {
  icon: 'send',
  label: 'Email job to customer',
  left: true,
  submit: true,
};

export const CLASS_NAMES = {
  container: 'flex flex-col w-full max-w-180 text-left',
  footer: 'flex flex-end w-full max-w-256 justify-end p-10 mx-auto',
};
