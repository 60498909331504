export const TITLE = 'Choose payment & finance options';

export const TITLE_DESKTOP = 'Checkout options';

export const MORE_RATES_LINK = 'Need more rates? See more options here.';

export const CLASS_NAMES = {
  wrapper: 'flex flex-col mb-8',
  title: 'text-style-e text-neutral-900 mb-6',
};

export const BULLET_POINTS = [
  'Your customer decides with which option they checkout',
  'The minimum loan is £1,000, and the maximum is £25,000',
  'Fees are charged automatically',
];
