import { Field } from '@kanda-libs/ks-component-ts';

import Container from './Content-container';
import {
  CLASS_NAMES,
  TITLE,
  DESCRIPTION,
  FIELDS,
  EMAIL_VALIDATION,
} from './Content-constants';

const ViewComponent = () => (
  <Container>
    {({ onBlur }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.headerWrapper}>
          <p className={CLASS_NAMES.title}>{TITLE}</p>
          <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
        </div>
        <Field.Validator validation={EMAIL_VALIDATION}>
          <Field.Input
            {...FIELDS.email}
            onBlur={() => onBlur(FIELDS.email.name)}
          />
        </Field.Validator>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ForgotPassword-Request-Content-view';

export default ViewComponent;
