import { SkeletonLoader, Price, Icon } from '@kanda-libs/ks-design-library';

import Container from './PriceStatus-container';

export interface ViewComponentProps {
  /**
   * Price
   */
  total: number;
  /**
   * Job status
   */
  status?: string;
  /**
   * Finance status
   */
  financeStatus?: string;
  /**
   * ClassName for wrapper
   */
  skeletonClassName?: string;
  /**
   * Display Loading state
   */
  isLoading?: boolean;
  /**
   * Skeleton props
   */
  skeletonProps?: object;
}

const ViewComponent = ({
  total,
  status,
  financeStatus,
  isLoading,
  skeletonClassName,
  skeletonProps,
}: ViewComponentProps) => (
  <Container status={status} financeStatus={financeStatus}>
    {({ color, classNames, financed, paid }) => (
      <SkeletonLoader
        isLoading={isLoading}
        className={skeletonClassName}
        {...skeletonProps}
        afterLoading={
          <div className="flex flex-row max-w-full">
            <Price amount={total} color={color} {...classNames} />
            {financed && (
              <Icon
                icon="financed"
                stroke={color}
                size={16}
                className="my-auto ml-2"
              />
            )}
            {paid && (
              <Icon
                icon="check"
                stroke="green-500"
                size={16}
                className="my-auto ml-2"
              />
            )}
          </div>
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'PriceStatus-view';

ViewComponent.defaultProps = {
  status: 'draft',
  financeStatus: 'not_applied',
  skeletonClassName: '',
  skeletonProps: {},
  isLoading: false,
};

export default ViewComponent;
