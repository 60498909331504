import { Button, Icon, Text } from '@kanda-libs/ks-design-library';
import { AlternateColourText } from 'components';
import { URLS } from 'config';
import { useReferralSignUpContext } from 'pages/ReferralSignUp/context';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from '../../components';

const ReferralSignUpKanda: React.FunctionComponent = function () {
  const { from, setPage } = useReferralSignUpContext();

  // const title = useMemo(() => {
  //   if (!from) return 'You have been sent £50!';
  //   return `${from} has sent you £50!`;
  // }, [from]);

  const title = useMemo(() => {
    if (!from) return ['You have been sent ', '£50', ' off!'];
    return ['', from, ' has sent you £50 off!'];
  }, [from]);

  const subTitle = useMemo(() => {
    if (!from) return ['Join over 1000 businesses growing today with Kanda'];
    return [
      'Join ',
      from,
      ' and over 1000 businesses growing today with Kanda',
    ];
  }, [from]);

  const onClick = useCallback(() => setPage('signup'), [setPage]);

  return (
    <Layout>
      <div className="flex flex-col">
        <AlternateColourText
          text={title}
          className="text-24-28-em md:text-40-48-em mb-8"
        />
        <div className="flex flex-col md:flex-row gap-3 md:justify-between w-full mb-12">
          <div className="flex flex-row">
            <Icon icon="shield-alt" stroke="lavender-200" />
            <Text
              text="£50 off your first year"
              className="text-16-24-em text-lavender-200 my-auto ml-3"
              skeletonProps={{
                width: 100,
              }}
            />
          </div>
          <div className="flex flex-row">
            <Icon icon="coin-pound" stroke="lavender-200" />
            <Text
              text="100% refund if not approved"
              className="text-16-24-em text-lavender-200 my-auto ml-3"
              skeletonProps={{
                width: 100,
              }}
            />
          </div>
          <div className="flex flex-row">
            <Icon icon="coin-pound" stroke="lavender-200" />
            <Text
              text="Prices include all FCA & compliance fees"
              className="text-16-24-em text-lavender-200 my-auto ml-3"
              skeletonProps={{
                width: 100,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 border border-neutral-300 rounded-lg px-5 py-6 md:p-16 shadow-card">
          <div className="flex flex-col flex-1 w-full max-w-160 mx-auto my-auto md:my-0">
            <AlternateColourText
              text={subTitle}
              className="text-center text-20-24-em md:text-24-28-em mb-6"
            />
            <p className="text-16-24 md:text-20-24-em md:font-normal mb-10 w-full max-w-120 mx-auto">
              Tradespeople win more work by offering finance to clients. If you
              win 5 or more jobs a year on finance, your subscription is already
              paid for.
            </p>
            <Button.Text
              id="referral-kanda-next"
              onClick={onClick}
              label="Claim £50 & Sign Up Now!"
              icon="arrow-right"
              className="w-full max-w-120 mx-auto"
              variant="gradient"
            />
            <span className="mt-6 mx-auto text-14-22">
              Already have an account?
              <Link
                to={URLS.login}
                className="text-14-22-em text-green-400 ml-1 underline"
              >
                Login
              </Link>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReferralSignUpKanda;
