import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { InfoMe } from '@kanda-libs/ks-frontend-services';
import { parsePhoneNumber } from 'utils';
import { AMPERSAND_REGEX } from './constants';
import type { ReferralSignUpFieldValues } from './types';

export const formatSignUpPayload = (
  payload: ReferralSignUpFieldValues,
): InfoMe => ({
  name: `${payload.first_name} ${payload.last_name}`,
  email: payload.email,
  phone: parsePhoneNumber(payload.phone),
  referrer: window.location.href,
});

export const formatParams = (search: string): StringIndexedObject => {
  if (search[0] !== '?') return {};
  const parts = search.replace('?', '').split(AMPERSAND_REGEX);
  if (parts.length === 0) return {};
  return parts.reduce((params: StringIndexedObject, part: string) => {
    const [key, value] = part.split('=');
    if (key === 'email')
      return {
        ...params,
        [key]: value,
      };
    return {
      ...params,
      [key]: value.replace(/\+/g, ' '),
    };
  }, {});
};
