import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Container from './SSO-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../components';

const ViewComponent = () => (
  <Container>
    {({ onSubmit, form, isSubmitting, ...restProps }) => (
      <>
        <Helmet title="Email Sign In" />
        <Form id="sso-email" form={form} onSubmit={onSubmit}>
          <BreakPoints
            mobile={<Mobile {...restProps} />}
            desktop={<Desktop {...restProps} />}
          />
          <Loader isLoading={isSubmitting} />
        </Form>
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'SSO-view';

export default ViewComponent;
