import { useState, useRef, useEffect } from 'react';

export default function useOptionHover() {
  const [visible, setVisible] = useState(false);
  const elementRef = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    if (!elementRef.current) return () => {};
    const el = elementRef.current;
    const onMouseEnter = () => setVisible(true);
    const onMouseLeave = () => setVisible(false);
    el.addEventListener('mouseenter', onMouseEnter);
    el.addEventListener('mouseleave', onMouseLeave);
    el.addEventListener('touchstart', onMouseEnter);
    el.addEventListener('touchend', onMouseLeave);
    return () => {
      el.removeEventListener('mouseenter', onMouseEnter);
      el.removeEventListener('mouseleave', onMouseLeave);
      el.removeEventListener('touchstart', onMouseEnter);
      el.removeEventListener('touchend', onMouseLeave);
    };
  }, []);

  return {
    elementRef,
    visible,
  };
}
