import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = () => (
  <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
);

ViewComponent.displayName = 'PaymentMethods-view';

export default ViewComponent;
