import { Icon } from '@kanda-libs/ks-design-library';
import { themeConfig } from '@kanda-libs/ks-tailwind-config';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CLASS_NAMES } from './CompanyLogo-constants';
import useCompanyAccountProgress from './useCompanyAccountProgress';

interface CompanyLogoProps {
  src?: string;
  showProgressIndicator?: boolean;
}

const CompanyLogo = ({ src, showProgressIndicator }: CompanyLogoProps) => {
  const progress = useCompanyAccountProgress();

  return (
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.logo}>
        {!src && <Icon icon="toolbox" className="w-7 h-7" />}
        {src && (
          <img
            className={CLASS_NAMES.img}
            src={`data:image/png;base64,${src}`}
            alt="logo"
          />
        )}
      </div>
      {showProgressIndicator && progress !== 0 && progress !== 100 && (
        <CircularProgressbar
          value={progress}
          maxValue={100}
          className={CLASS_NAMES.progressIndicator}
          strokeWidth={4}
          styles={buildStyles({
            pathColor: themeConfig.theme.colors['turquoise-300'],
            trailColor: themeConfig.theme.colors['neutral-200'],
          })}
        />
      )}
    </div>
  );
};

CompanyLogo.defaultProps = {
  src: null,
  showProgressIndicator: true,
};

export default CompanyLogo;
