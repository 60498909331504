import { useMemo } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { formatToCurrency } from 'utils';

interface FieldValues {
  margin: number;
  jobsWon: number;
}

export interface CalculatorFormook {
  form: UseFormReturn<FieldValues>;
  numJobsForSub: string;
  extraRevenue: string;
}

export default function useCalculatorForm(): CalculatorFormook {
  const defaultValues: FieldValues = useMemo(
    () => ({
      margin: 30000,
      jobsWon: 30,
    }),
    [],
  );

  const form = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const [margin, jobsWon] = form.watch(['margin', 'jobsWon']);

  const numJobsForSub = useMemo(() => {
    if (!margin) return '--';
    return String(Math.ceil(90000 / margin));
  }, [margin]);

  const extraRevenue = useMemo(() => {
    if (!margin || !jobsWon) return '--';
    const extra = Math.max(0, margin * jobsWon - 90000);
    return formatToCurrency(extra);
  }, [margin, jobsWon]);

  return {
    form,
    numJobsForSub,
    extraRevenue,
  };
}
