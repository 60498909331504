import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './Description-container';
import { DESCRIPTION_COMPONENT_PROPS } from './Description-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Container {...props}>
    {({ onBlur, value }) => (
      <Widget.JobJobItemsDescriptionArrayInput
        {...DESCRIPTION_COMPONENT_PROPS}
        {...props}
        onBlur={onBlur}
        initialValue={value}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Description-view';

export default ViewComponent;
