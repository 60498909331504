import { Price } from '@kanda-libs/ks-design-library';

import Container from './Total-container';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
  /**
   * State of whether item is in focus or not
   */
  inFocus: boolean;
}

const ViewComponent = ({ arrayName, index, inFocus }: ViewComponentProps) => (
  <Container arrayName={arrayName} index={index} inFocus={inFocus}>
    {({ amount, className, isLoading }) => (
      <div className={className}>
        <Price amount={amount} isLoading={isLoading} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Total-view';

export default ViewComponent;
