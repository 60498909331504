import { MultiStepForm } from '@kanda-libs/ks-component-ts';

import Layout from './components/Layout';
import { INITIAL_DATA, STEPS } from './constants';

import useOnboarding from './useOnboarding';

import { Helmet } from '../../components';

const Onboarding: React.FunctionComponent = () => {
  const { isLoading } = useOnboarding();

  return (
    <>
      <Helmet title="Onboarding" />
      <MultiStepForm
        steps={STEPS}
        addStepsToUrl={false}
        initialStepIndex={0}
        onSubmit={() => {}}
        initialData={INITIAL_DATA}
      >
        <Layout isLoading={isLoading} />
      </MultiStepForm>
    </>
  );
};

export default Onboarding;
