import { useSubscriptionContext } from './context';
import PayMonthlyForm from './PayMonthlyForm';
import SignContractForm from './SignContractForm';

const SetupSubscriptionContent: React.FunctionComponent = function () {
  const { display } = useSubscriptionContext();
  if (display === 'contract') return <SignContractForm />;
  return <PayMonthlyForm />;
};

export default SetupSubscriptionContent;
