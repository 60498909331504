import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import type { EnterpriseRatesSuccessLayoutProps } from '.';

const EnterpriseRatesLayoutDesktop: FunctionComponent<EnterpriseRatesSuccessLayoutProps> =
  function ({ children }) {
    return (
      <DesktopLayoutBoxed showNavigation>
        <DesktopLayoutBoxedContent>
          <>{children}</>
        </DesktopLayoutBoxedContent>
      </DesktopLayoutBoxed>
    );
  };

export default EnterpriseRatesLayoutDesktop;
