import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import RatesTableHeader from './Header';
import RatesTableRow from './Row';
import useRatesTable from './useRatesTable';

interface RatesTableProps {
  rates: FinanceRate[] | undefined;
}

const RatesTable: React.FunctionComponent<RatesTableProps> = function ({
  rates,
}) {
  const { formattedRates } = useRatesTable(rates);
  if (!formattedRates) return null;
  return (
    <div className="grid grid-cols-[10fr_10fr_7fr]">
      <RatesTableHeader />
      {Object.keys(formattedRates).map((rate: string, index: number) => (
        <RatesTableRow
          key={rate}
          rate={rate}
          grouped={formattedRates[rate]}
          index={index}
        />
      ))}
    </div>
  );
};

export default RatesTable;
