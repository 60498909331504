import {
  ModalContainer,
  ModalLayoutCenter,
} from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { AI_DOC_MODAL_ID } from '../Document-constants';
import AIDocModalContent from './Content';

interface AIDocModalProps {
  content?: string;
  refreshJobDetails?: () => void;
}

const AIDocModal: React.FunctionComponent<AIDocModalProps> = function ({
  content,
  refreshJobDetails,
}) {
  return (
    <ModalContainer id={AI_DOC_MODAL_ID} opacity={50}>
      {({ id, handleClose, ref }) => (
        <ModalLayoutCenter
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          className="w-full max-w-[90%] md:max-w-2xl break-words"
        >
          <AIDocModalContent
            content={content}
            handleClose={handleClose}
            refreshJobDetails={refreshJobDetails}
          />
        </ModalLayoutCenter>
      )}
    </ModalContainer>
  );
};

export default AIDocModal;
