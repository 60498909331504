export const TITLE = 'Email changed successfully';

export const DESCRIPTION =
  'You should also receive an email to confirm the change of email.';

export const ICON_PROPS = {
  icon: 'send-quote-color',
  size: 104,
  className: 'mb-8',
};
