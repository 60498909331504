import { BreakPoints } from '@kanda-libs/ks-design-library';

import Desktop from './Desktop';
import Mobile from './Mobile';
import useLayout from './useLayout';

interface LayoutProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ isLoading }) => {
  const props = useLayout();
  return (
    <BreakPoints
      mobile={<Mobile {...props} isLoading={isLoading} />}
      desktop={<Desktop {...props} isLoading={isLoading} />}
    />
  );
};

export default Layout;
