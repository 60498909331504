import { FormTheme } from '@kanda-libs/ks-component-ts';
import { BreakPoints } from '@kanda-libs/ks-design-library';

import { Price, VAT, Total } from './components';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <BreakPoints
    desktop={
      <div className="flex flex-col flex-1 md:min-w-75 md:mr-8">
        <div className="flex flex-row">
          <FormTheme variant="narrow">
            <Price {...props} />
            <VAT {...props} />
          </FormTheme>
        </div>
        <Total {...props} />
      </div>
    }
    mobile={
      <div className="flex flex-col flex-1">
        <Price {...props} />
        <VAT {...props} />
        <Total {...props} />
      </div>
    }
  />
);

ViewComponent.displayName = 'Createjob-Quote-Custom-Item-Details-view';

export default ViewComponent;
