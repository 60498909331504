import { FormTheme } from '@kanda-libs/ks-component-ts';
import { Separator } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import JobNotesField from '../../JobNotesField';
import WorkTypeField from '../WorkTypeField';

const Fields: FunctionComponent = function () {
  return (
    <FormTheme variant="emphasized">
      <WorkTypeField />
      <Separator />
      <JobNotesField />
    </FormTheme>
  );
};

export default Fields;
