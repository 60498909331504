import Container from './PaymentOptions-container';
import {
  PAYMENT_OPTIONS_MODAL_PROPS,
  PAYMENT_OPTIONS_ACTION_PROPS,
} from './PaymentOptions-constants';

import { Action } from '../../../components';
import { PaymentOptionsModal } from './components';

const ViewComponent = () => (
  <Container>
    {({ showPaymentOptionsModal, badge, isLoading }) => (
      <>
        <Action
          {...PAYMENT_OPTIONS_ACTION_PROPS}
          onClick={showPaymentOptionsModal}
          badge={badge}
          isLoading={isLoading}
        />
        <PaymentOptionsModal {...PAYMENT_OPTIONS_MODAL_PROPS} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Note-Mobile-view';

export default ViewComponent;
