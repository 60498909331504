import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsSubscribed from '../../../../../hooks/useIsSubscribed';

export default function useUpgradeAccountBadgeIsVisible() {
  const {
    data: { isLoading, company },
  } = useCreateJobContextValue();

  const needsToCompleteOnboarding =
    company?.lifecycle === 'registered' ||
    company?.lifecycle === 'onboarded' ||
    company?.lifecycle === 'completed_setup';

  const { isSubscribed } = useIsSubscribed();

  return !isLoading && !needsToCompleteOnboarding && !isSubscribed;
}
