import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useIsDesktop, useToast } from '@kanda-libs/ks-design-library';
import {
  useCurrentUser,
  type LimitedCompanyInfo,
  type SoleTraderInfo,
  type UserType,
} from '@kanda-libs/ks-frontend-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { URLS } from '../../config';
import { getCompanyUserThatIsMe } from '../../pages/Onboarding/Onboarding-helpers';
import useCurrentCompany from '../useCurrentCompany';
import useQueryParam from '../useQueryParam';

import {
  VERIFICATION_STATUS_DECLINED,
  VERIFICATION_STATUS_SUBMITTED,
  VERIFICATION_STATUS_VERIFIED,
} from './constants';
import { createVeriffButton, showVeriffModal } from './helpers';

export interface VeriffIdentifyHook {
  director: UserType | null;
  cid: string;
  companyName?: string;
  isLoading: boolean;
  onClickButton: () => void;
  verified: boolean;
  submitted: boolean;
  showForm: boolean;
  onShowForm: () => void;
  notPresentError: string | undefined;
}

/**
 * Hook to handle Veriff within platform
 */
export default function useVeriffIdentify(): VeriffIdentifyHook {
  const isLoading = useSelector(selectors.getIsLoading);
  const error = useSelector(selectors.company.getError);
  const [data] = useSelector(
    selectors.getCompanyDirectorVerification.getEntitiesAsArray,
  );
  const [notPresentError, setNotPresentError] = useState<string | undefined>(
    undefined,
  );
  const [director, setDirector] = useState<UserType | null>(null);
  const [company, setCompany] = useState<
    LimitedCompanyInfo | SoleTraderInfo | null
  >(null);
  const [declinedToastShown, setDeclinedToastShown] = useState(false);
  const [verified, setVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { id: initialCid } = useSelector(selectors.getPathKey);
  const { push } = useHistory();
  const [email] = useQueryParam('email');
  const isDesktop = useIsDesktop();

  const { showError } = useToast();

  const {
    user,
    isUserLoggedIn,
    isValidating: userIsLoading,
  } = useCurrentUser();

  const { company: currentCompany, isLoading: companyIsLoading } =
    useCurrentCompany();

  const cid = useMemo(() => {
    if (initialCid) return initialCid;
    if (companyIsLoading) return null;
    return currentCompany?.cid || null;
  }, [initialCid, companyIsLoading, currentCompany]);

  const onSession = useCallback(
    (err, response) => {
      if (err) {
        showError('There was an error contacting Veriff');
        return;
      }

      if (isDesktop) {
        showVeriffModal(response.verification.url, () => {
          setSubmitted(true);
        });
      } else {
        window.location.href = response.verification.url;
      }
    },
    [showError, isDesktop],
  );

  /**
   * A useEffect hook that handles when the company identify endpoint throws an error.
   * This could be because the company is already verified or not yet in the right state
   * for the directors to be verified.
   */
  useEffect(() => {
    if (isLoading) return;
    if (error) {
      /**
       * The director and/or company is already verified
       */
      if (error.code === 423) {
        setVerified(true);
        return;
      }

      /**
       * This state should never be reached as this is only possible when manually accessing the link before
       * being invited to identify. So we just want to show a generic error and push to the login screen
       */
      if (!data && error) {
        showError('There was an error verifying your identity. Please login');
        push(URLS.login);
      }
    }
  }, [error, showError, push, isLoading, data]);

  /**
   * The Veriff JS API is limited in that it only allows you to change the button
   * text rather than the styling. So this function will just click the verify
   * submit button that's hidden on the page.
   */
  const onClickButton = useCallback(() => {
    (
      document.querySelector('#veriff-submit-btn') as StringIndexedObject
    )?.click();
  }, []);

  /**
   * useEffect to show a toast when the verification status is declined
   */
  useEffect(() => {
    if (
      !declinedToastShown &&
      director?.director_info?.verification_status ===
        VERIFICATION_STATUS_DECLINED
    ) {
      showError(
        'Your previous verification was declined. Please try again or contact support if the problem persists.',
      );
      setDeclinedToastShown(true);
    }
  }, [declinedToastShown, director, showError, setDeclinedToastShown]);

  const setDirectorFromData = useCallback(() => {
    if (!email) {
      setNotPresentError(
        'Cannot find user - please follow link in email or contact Kanda at help@kanda.co.uk',
      );
      return undefined;
    }
    const directors = data?.directors || [];
    if (directors.length === 0) {
      setNotPresentError(
        'Cannot find director data in company - please follow link in email or contact Kanda at help@kanda.co.uk',
      );
      return undefined;
    }
    const foundDirector = directors.filter((d) => d.email === email);
    if (foundDirector.length !== 1) {
      setNotPresentError(
        'Cannot find director email in company data - please follow link in email or contact Kanda at help@kanda.co.uk',
      );
      return undefined;
    }
    setDirector(foundDirector[0]);
    return foundDirector[0];
  }, [data, email]);

  /**
   * useEffect that runs veriff when a director has been found
   */
  useEffect(() => {
    // Return if loading or error
    if (isLoading) return;
    // Check if user is logged in
    if (isUserLoggedIn && error && error.code !== 423) return;
    if (isUserLoggedIn) {
      // Check user and company have loaded
      if (!user || companyIsLoading || !currentCompany) return;
      // Set company
      setCompany(
        currentCompany as unknown as LimitedCompanyInfo | SoleTraderInfo,
      );
      // Find user
      const me = getCompanyUserThatIsMe(currentCompany, user?.email);
      // If user found, create veriff button
      if (me) {
        setDirector(me);
        createVeriffButton(me, cid, onSession, isUserLoggedIn);
        return;
      }
      // if user not found, try to get user from data
      const foundDirector = setDirectorFromData();
      // if no director found, return
      if (!foundDirector) return;
      // Create veriff button
      createVeriffButton(foundDirector, cid, onSession, isUserLoggedIn);
    }
    // If not logged in, check if data is loaded
    if (!data) return;
    // Set company from data
    setCompany(
      (data?.limited_company || data?.sole_trader) as
        | LimitedCompanyInfo
        | SoleTraderInfo,
    );
    // Find director from email
    const foundDirector = setDirectorFromData();
    // If no director, return
    if (!foundDirector) return;
    // Create veriff button
    createVeriffButton(foundDirector, cid, onSession, isUserLoggedIn);
  }, [
    error,
    data,
    isLoading,
    email,
    setDirector,
    cid,
    onSession,
    currentCompany,
    userIsLoading,
    companyIsLoading,
    user?.email,
    isUserLoggedIn,
    setDirectorFromData,
    user,
  ]);

  return {
    director,
    cid: data?.id || cid || '',
    companyName:
      (!email && currentCompany?.contact_info?.trading_name) ||
      (company as LimitedCompanyInfo)?.company_name ||
      (company as SoleTraderInfo)?.trading_name,
    isLoading,
    onClickButton,
    verified:
      verified ||
      director?.director_info?.verification_status ===
        VERIFICATION_STATUS_VERIFIED,
    submitted:
      submitted ||
      director?.director_info?.verification_status ===
        VERIFICATION_STATUS_SUBMITTED,
    showForm,
    onShowForm: () => {
      setShowForm(true);
    },
    notPresentError,
  };
}
