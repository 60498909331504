import { Layout } from '@kanda-libs/ks-design-library';

import { Form, Confirmation } from '../components';

import { SubPageHeader } from '../../components';
import type { FormProps } from '../components/Form';

interface ViewComponentProps extends FormProps {
  /**
   * Show confirmation screen
   */
  showConfirmation?: boolean;
}

const ViewComponent = ({
  showConfirmation,
  ...formProps
}: ViewComponentProps) => (
  <Layout stickyFooter noBorder header={<SubPageHeader />}>
    {showConfirmation ? <Confirmation /> : <Form {...formProps} />}
  </Layout>
);

ViewComponent.displayName = 'ChangePassword-Mobile-view';

ViewComponent.defaultProps = {
  showConfirmation: false,
};

export default ViewComponent;
