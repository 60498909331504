import { capitalize } from '@kanda-utils/library';
import { useCallback, useContext } from 'react';

import Context from '../../../../../../../Context';

const ContainerComponent = ({ children, status }) => {
  const { filter, updateFilter } = useContext(Context);

  const filterValue = filter?.status || '';

  const active = filterValue.includes(status);

  const id = `status-filter-${status}`;

  const label = capitalize(status);

  /**
   * Handles status click
   * Adds a new status as filters or removes if it exists
   */
  const onClick = useCallback(() => {
    if (active) {
      const update = filterValue.replace(`${status};`, '');
      updateFilter('status', update);
      return;
    }
    const update = `${filterValue}${status};`;
    updateFilter('status', update);
  }, [updateFilter, status, active, filterValue]);

  return children({
    id,
    onClick,
    active,
    label,
  });
};

ContainerComponent.displayName = 'StatusFilters-container';

export default ContainerComponent;
