import { MultiStepFormRouter } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback } from 'react';

import { CLASS_NAMES } from './Content-constants';

import { ONBOARDING_STEPS } from '../../../../../AccountVerificationExplanation-constants';
import Pages from '../../../../Pages';

const ViewComponent = ({}) => {
  const { step, handleContinue = () => {} } = useOnboardingContextValue();

  const onClick = useCallback(() => {
    handleContinue({});
  }, [handleContinue]);

  return (
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.content}>
        <MultiStepFormRouter pages={Pages} />
        {[
          ONBOARDING_STEPS.VALIDATE_DIRECTOR_INFO,
          ONBOARDING_STEPS.IDENTIFY,
          ONBOARDING_STEPS.VERIFICATION_STATUS,
        ].indexOf(step as string) === -1 && (
          <div className="absolute w-full text-center bottom-16">
            <Button.Text
              id="account-verification-desktop-next"
              label="Next"
              icon="arrow-right"
              onClick={onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ViewComponent.displayName =
  'AccountVerificationExplanation-Desktop-Content-view';

export default ViewComponent;
