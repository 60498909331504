export const formatCompany = (company) => ({
  company: {
    ...company,
    contact_info: {
      ...company?.contact_info,
      contact_phone: company?.contact_info?.contact_phone
        ? company.contact_info.contact_phone.replace('+44', 0)
        : null,
    },
  },
});
