import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatImage } from './CompanyLogo-functions';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();

  const id = useWatch({ name: 'company.company_logo.id' });

  const isLoading = useSelector(selectors.document.getIsLoading);
  const documents = useSelector(selectors.document.getEntities);
  const logo = documents[id];

  useEffect(() => {
    if (id) {
      dispatch(actions.getDocument({ params: { id }, forceReload: true }));
    }
  }, [id, dispatch]);

  const { unregister, setValue } = useFormContext();

  const dataUrl = formatImage(logo);

  const onClick = useCallback(() => {
    setValue('company.company_logo', null);
    unregister('company.company_logo');
  }, [setValue, unregister]);

  return children({
    isLoading,
    dataUrl,
    alt: logo?.name || 'logo',
    onClick,
  });
};

ContainerComponent.displayName = 'CompanyLogo-container';

export default ContainerComponent;
