export const CLASS_NAMES = {
  description: 'text-neutral-700',
  subTitle: 'text-neutral-900',
  card: {
    wrapper: 'mt-4',
    row: 'flex flex-row justify-between',
    title: 'flex flex-col text-left',
    actions: 'flex flex-row items-center',
    details: 'flex flex-col mt-4 text-left',
    detailsRow: 'flex flex-row gap-x-2 text-left',
  },
};
