import { RowFields, Widget } from '@kanda-libs/ks-component-ts';
import { Button, BreakPoints, Text } from '@kanda-libs/ks-design-library';

import Container from './CompanyForm-container';
import {
  LOGO_DOCUMENT_PROPS,
  AVERGAE_JOB_VALUE_PROPS,
  ACCOUNT_NAME_VALIDATION,
  ACCOUNT_NUMBER_VALIDATION,
  SORT_CODE_VALIDATION,
  INSURANCE_DOCUMENT_PROPS,
} from './CompanyForm-constants';
import {
  CompanyLogo,
  Standard,
  Solar,
  ContactDetails,
  CompanyDetails,
} from './components';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({
      hasLogo,
      inputFile,
      fileError,
      personalLiabilityError,
      limited,
      vatNumberValidation,
      isSoleTrader,
      isSolar,
      isSubmitting,
      insuranceValidation,
    }) => (
      <div className="flex flex-col">
        <Text
          text="Company Logo"
          className="mt-4 mb-4 text-14-22-em text-neutral-700"
        />
        {hasLogo ? (
          <CompanyLogo />
        ) : (
          <Widget.CompanyCompanyLogoContent
            {...LOGO_DOCUMENT_PROPS}
            error={fileError}
            inputFile={inputFile as unknown as File}
          />
        )}
        <Button.Text
          id="company-info-upload-logo"
          submit
          label="Upload"
          className="mb-6"
          disabled={isSubmitting}
        />

        <Text
          text="Company Details"
          className="mt-4 mb-4 text-14-22-em text-neutral-700"
        />
        {limited ? (
          <>
            <CompanyDetails />
            <Widget.CompanyContactInfoTradingName />
          </>
        ) : (
          <Widget.CompanySoleTraderInfoTradingName />
        )}
        {limited && (
          <Widget.CompanyLimitedCompanyInfoVatNumber
            validation={vatNumberValidation}
          />
        )}
        <Button.Text
          id="company-info-submit"
          submit
          label="Submit"
          className="mb-6"
          disabled={isSubmitting}
        />

        <ContactDetails isSubmitting={isSubmitting} />

        <Text
          text="Company Bank Details"
          className="mt-4 mb-4 text-14-22-em text-neutral-700"
        />
        <Widget.CompanyBankAccountAccountName
          validation={ACCOUNT_NAME_VALIDATION}
        />
        <BreakPoints
          mobile={
            <>
              <Widget.CompanyBankAccountAccountNumber
                validation={ACCOUNT_NUMBER_VALIDATION}
              />
              <Widget.CompanyBankAccountSortCode
                type="sortCode"
                validation={SORT_CODE_VALIDATION}
              />
            </>
          }
          desktop={
            <RowFields>
              <Widget.CompanyBankAccountAccountNumber
                validation={ACCOUNT_NUMBER_VALIDATION}
              />
              <Widget.CompanyBankAccountSortCode
                type="sortCode"
                validation={SORT_CODE_VALIDATION}
              />
            </RowFields>
          }
        />
        <Button.Text
          id="company-info-submit-disabled"
          submit
          label="Submit"
          disabled={isSubmitting}
        />

        <Text
          text="Trade info"
          className="mt-10 mb-6 text-16-20-em text-neutral-900"
        />
        {isSolar ? <Solar /> : <Standard />}

        {isSoleTrader && (
          <Widget.CompanySoleTraderInfoNationalInsuranceNumber />
        )}
        <BreakPoints
          mobile={
            <>
              <Widget.CompanyCompanyInfoAverageJobValue
                {...AVERGAE_JOB_VALUE_PROPS}
              />
              <Widget.CompanyCompanyInfoAverageMonthlyJobs />
            </>
          }
          desktop={
            <RowFields>
              <Widget.CompanyCompanyInfoAverageJobValue
                {...AVERGAE_JOB_VALUE_PROPS}
              />
              <Widget.CompanyCompanyInfoAverageMonthlyJobs />
            </RowFields>
          }
        />
        <BreakPoints
          mobile={
            <>
              <Widget.CompanyCompanyInfoWarrantyLength />
              <Widget.CompanyCompanyInfoUseSubcontractor
                label="Do you use subcontractors?"
                placeholder="Select..."
              />
            </>
          }
          desktop={
            <RowFields>
              <Widget.CompanyCompanyInfoWarrantyLength />
              <Widget.CompanyCompanyInfoUseSubcontractor
                label="Do you use subcontractors?"
                placeholder="Select..."
              />
            </RowFields>
          }
        />
        <Button.Text
          id="company-info-submit"
          submit
          className="mb-4"
          label="Submit"
          disabled={isSubmitting}
        />
        <Widget.CompanyCompanyInfoInsuranceDocumentContent
          {...INSURANCE_DOCUMENT_PROPS}
          error={personalLiabilityError}
          inputFile={inputFile as unknown as File}
          validation={insuranceValidation}
        />
        <Button.Text
          id="company-info-submit-disabled"
          submit
          label="Submit"
          disabled={isSubmitting}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyInformation-Content-Form-view';

export default ViewComponent;
