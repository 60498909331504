import { useForm } from '@kanda-libs/ks-component-ts';
import { StringIndexedObject, useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import useUserDefaultValues from 'hooks/useUserDefaultValues';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import useMe from '../../../../../hooks/useMe';
import { cleanObject } from '../../../../../utils';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { me, revalidateMe } = useMe();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const canUserEdit = me?.role === 'company-admin';

  const { showSuccess } = useToast();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const defaultValues = useUserDefaultValues();

  const form = useForm({ mode: `onBlur`, defaultValues });

  const { reset } = form;

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      const { id } = company || {};
      const body = cleanObject({
        ...company,
        ...formValues.company,
      });

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            showSuccess('Company Updated!');
            revalidateMe();
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess, revalidateMe],
  );

  const isLoading = !company;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return children({
    isLoading,
    isSubmitting,
    form,
    onSubmit,
    defaultValues,
    canUserEdit,
  });
};

ContainerComponent.displayName = 'TeamSettings-Content-container';

export default ContainerComponent;
