import { Price } from '@kanda-libs/ks-design-library';

import Container from './Total-container';

const ViewComponent = ({}) => (
  <Container>
    {({ total, isLoading, classNames }) => (
      <Price isLoading={isLoading} {...classNames.total} amount={total} />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Total-view';

export default ViewComponent;
