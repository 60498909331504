import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import {
  Cards,
  Desktop,
  InviteBanner,
  InviteButton,
  Mobile,
  Table,
} from '../components';

const Referrals: React.FunctionComponent = function () {
  return (
    <>
      <Helmet title="Referrals" />
      <BreakPoints
        mobile={
          <Mobile>
            <InviteButton />
            <InviteBanner />
            <Cards />
          </Mobile>
        }
        desktop={
          <Desktop>
            <InviteButton />
            <InviteBanner />
            <Table />
          </Desktop>
        }
      />
    </>
  );
};

export default Referrals;
