import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export interface SwitchCompanyTypeHook {
  onClick: () => void;
  label: string;
}

export default function useSwitchCompanyType(): SwitchCompanyTypeHook {
  const { setValue, unregister } = useFormContext();
  const companyType = useWatch({ name: 'company.company_type' });

  const onClick = useCallback(() => {
    if (companyType === 'limited_company') {
      setValue('company.company_type', 'sole_trader');
      unregister('company.limited_company_info');
      return;
    }
    setValue('company.company_type', 'limited_company');
    unregister('company.sole_trader_info');
  }, [companyType, setValue, unregister]);

  const label = useMemo(() => {
    if (companyType === 'limited_company') return "I'm a sole trader";
    return 'I own a limited company';
  }, [companyType]);

  return {
    onClick,
    label,
  };
}
