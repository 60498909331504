export const CLASSNAMES = {
  container: 'flex flex-row text-left',
  text: 'ml-3 my-auto text-14-22 text-neutral-700',
};

export const TERMS_TEXT = "I have read and agree to Kanda's ";

export const LINK_TEXT = 'Terms & Conditions of Service';

export const TERMS_BUTTON = {
  label: 'Terms & Conditions of Service',
  size: 14,
};
