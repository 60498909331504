import Container from './Logout-container';

import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <>
    <Helmet title="Logout" />
    <Container />
  </>
);

ViewComponent.displayName = 'Logout-view';

export default ViewComponent;
