import { useMemo } from 'react';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { ADD_A_JOB_STEPS } from '../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { data, setStep } = useCreateJobContextValue();

  const fileName = useMemo(() => {
    if (!data) return null;
    return data?.job?.job_documents?.[0]?.name;
  }, [data]);

  return children({
    onClick: () => {
      if (setStep) setStep(ADD_A_JOB_STEPS.DETAILS);
    },
    fileName,
  });
};

ContainerComponent.displayName = 'JobDetails-EPVSFile-container';

export default ContainerComponent;
