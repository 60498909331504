import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';
import { Provider } from './Context';

export interface LineItemProps {
  /**
   * Name of field
   */
  item?: {
    title?: string;
    description?: string;
    quantity?: number;
    price?: {
      amount?: number;
    };
    vat_rate?: string;
    vat?: string;
  };
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Variant
   */
  variant?: 'job-details' | 'customer-job-view' | 'job-summary';
}

const ViewComponent = (props: LineItemProps) => (
  <Provider {...props}>
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </Provider>
);

ViewComponent.displayName = 'LineItem-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  item: undefined,
  variant: 'job-details',
};

export default ViewComponent;
