import type { ValidationItems } from '@kanda-libs/ks-component-ts';
import { useReferralSignUpContext } from 'pages/ReferralSignUp/context';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { removeTrailingWhitespace } from 'utils';
import { EMAIL_PROPS } from './constants';

export interface SignUpFormFieldEmailHook {
  onBlur: () => void;
  validation: ValidationItems;
  disabled: boolean;
}

export default function useSignUpFormFieldEmail(): SignUpFormFieldEmailHook {
  const { email } = useReferralSignUpContext();
  const { getValues, setValue } = useFormContext();

  const onBlur = useCallback(
    () =>
      setValue(
        EMAIL_PROPS.name,
        removeTrailingWhitespace(getValues(EMAIL_PROPS.name)),
      ),
    [getValues, setValue],
  );

  const validation: ValidationItems = {
    required: {
      value: true,
      message: 'Email address is required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Email must be a valid email address',
    },
    validate: {
      value: () => {
        const values = getValues();
        return values?.email_validation !== 'invalid';
      },
      message: 'Email address entered is invalid',
    },
  };

  const disabled = useMemo(() => Boolean(email), [email]);

  return {
    onBlur,
    validation,
    disabled,
  };
}
