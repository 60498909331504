import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';

import { removeWhitespaceAndNewline } from '../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const [email] = useWatch({ name: ['email'] });

  const onEmailBlur = () =>
    setValue('email', removeWhitespaceAndNewline(getValues('email')));

  return children({
    onEmailBlur,
    email,
  });
};

ContainerComponent.displayName = 'Login-Content-container';

export default ContainerComponent;
