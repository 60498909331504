import { makeIsAllowed } from '@kanda-utils/library';

const Append = () => (
  <span className="hidden field-focus:inline-block field-error:inline-block opacity-0">
    £
  </span>
);

export const VAT_COMPONENT_PROPS = {
  placeholder: 'Select VAT',
  className: '',
  label: 'VAT',
  modal: true,
};

export const OPTIONS = [
  { name: 'No VAT', value: 'exempted' },
  { name: '0% VAT', value: '0%' },
  { name: '+5% VAT', value: '5%' },
  { name: '+20% VAT', value: '20%' },
  { name: 'Custom', value: 'custom' },
];

export const VAT_AMOUNT_COMPONENT_PROPS = {
  autoWidth: true,
  label: '',
  placeholder: '£0.00',
  prefix: '',
  wrapperProps: {
    className: 'justify-center',
  },
  append: <Append />,
  isAllowed: makeIsAllowed(0, 100000),
  autosize: true,
  type: 'price',
};

export const VAT_COMPONENT_MOBILE_PROPS = {
  placeholder: 'Select VAT',
  label: 'Tax',
};

export const VAT_AMOUNT_COMPONENT_MOBILE_PROPS = {
  label: '',
  placeholder: '£0.00',
  className: 'w-full',
  wrapperProps: {
    className: 'w-full',
  },
  isAllowed: makeIsAllowed(0, 100000),
  type: 'price',
};
