import { BULLETS } from './Info-constants';
import BulletListSidebar from '../../../../../../../components/BulletListSidebar';

const ViewComponent = () => (
  <BulletListSidebar title="Verify who owns the business" bullets={BULLETS} />
);

ViewComponent.displayName = 'Info-view';

export default ViewComponent;
