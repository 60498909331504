export const LAST_NAME_PROPS = {
  name: 'last_name',
  autoCapitalize: 'on',
  autoCorrect: 'off',
  autoComplete: 'family-name',
  label: 'Last Name',
  placeholder: 'Last name',
};

export const LAST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'Last name is required',
  },
  minLength: {
    value: 1,
    message: 'Last name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'Last name cannot be blank',
  },
};
