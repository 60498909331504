import { Button, Loader } from '@kanda-libs/ks-design-library';
import useAnalysePDF from './useAnalysePDF';

interface AIDocModalContentProps {
  handleClose: () => void;
  content?: string;
  refreshJobDetails?: () => void;
}

const AIDocModalContent: React.FunctionComponent<AIDocModalContentProps> =
  function ({ content, handleClose, refreshJobDetails }) {
    const { isSubmitting, onClick } = useAnalysePDF(
      handleClose,
      content,
      refreshJobDetails,
    );
    return (
      <>
        <p className="text-16-24-em mb-3">Prefill with AI?</p>
        <p>
          Would you like us to analyse this PDF with our AI agent to prefill the
          information for this quote?
        </p>
        <Button.Text
          id="analyse-pdf"
          onClick={onClick}
          disabled={isSubmitting}
          label="Yes"
          variant="gradient"
          className="mt-6"
        />
        <Loader isLoading={isSubmitting} />
      </>
    );
  };

export default AIDocModalContent;
