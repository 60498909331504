import {
  BreakPoints,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Header,
  Layout as MobileLayout,
  Logo,
} from '@kanda-libs/ks-design-library';
import type { FunctionComponent, ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = function ({ children }) {
  return (
    <BreakPoints
      mobile={
        <MobileLayout
          noBorder
          header={
            <Header.Base hideNumber lines help>
              <Logo />
            </Header.Base>
          }
          footer={undefined}
        >
          <>{children}</>
        </MobileLayout>
      }
      desktop={
        <DesktopLayoutBoxed help>
          <DesktopLayoutBoxedContent>
            <>{children}</>
          </DesktopLayoutBoxedContent>
        </DesktopLayoutBoxed>
      }
    />
  );
};

export default Layout;
