import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { URLS } from '../../../../config';

const ContainerComponent = ({ children }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const form = useForm({ mode: 'onBlur' });

  const { mutate: reauthenticate, isSubmitting: isReauthenticating } =
    useMutate(FirebaseAuthService.reauthenticate);

  const { mutate: updateEmail, isSubmitting: isUpdating } = useMutate(
    FirebaseAuthService.updateEmail,
  );

  const { showError } = useToast();
  const { push } = useHistory();

  const onSubmit = useCallback(
    (formValues) => {
      reauthenticate(formValues.currentPassword).then(
        ({ error: reauthError }) => {
          if (reauthError) {
            showError('Invalid credentials');
            return;
          }
          updateEmail(formValues.email).then(({ error }) => {
            if (error) {
              showError('Error updating email');
              return;
            }
            setShowConfirmation(true);
          });
        },
      );
    },
    [showError, updateEmail, reauthenticate],
  );

  const isSubmitting = isReauthenticating || isUpdating;

  const disabled = isSubmitting;

  useEffect(() => {
    push(URLS.accountSettings);
  }, [push]);

  return children({
    form,
    onSubmit,
    isSubmitting,
    showConfirmation,
    disabled,
  });
};

ContainerComponent.displayName = 'ChangeEmail-container';

export default ContainerComponent;
