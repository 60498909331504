export const CLASS_NAMES = {
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
  text: 'my-auto text-14-22',
  linktext: 'text-14-22-em text-green-400 ml-1',
};

export const TITLE = 'Confirm email';

export const EXPIRED_TITLE = 'Link expired';

export const DESCRIPTION = 'Please confirm your email address';

export const EXPIRED_DESCRIPTION =
  'This link has expired. A new link has been sent to your email address';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  email: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'username',
    name: 'email',
    label: 'Email',
    placeholder: 'Your email',
  },
};

export const EMAIL_VALIDATION = {
  required: {
    value: true,
    message: 'Email address is required',
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Email must be a valid email address',
  },
};

export const FORGOT_PASSWORD_BUTTON = {
  label: 'Forgot password',
  className: 'whitespace-nowrap',
  variant: 'turquoise',
};
