import { Text } from '@kanda-libs/ks-design-library';
import { formatToCurrency, Payout } from 'utils';

interface PayoutProps extends Payout {
  /**
   * Loading state
   */
  isLoading?: boolean;
}

const formatPercentage = (num: number, precision = 2) =>
  num.toFixed(precision).replace(/\.0+$/, '');

const PayoutComponent = ({
  isLoading,
  payout,
  subsidyTotal,
  subsidyPercentage,
  subsidyBase,
}: PayoutProps) => {
  const payoutText = formatToCurrency(payout);
  const subsidyTotalText = formatToCurrency(subsidyTotal);
  const subsidyBaseText = formatToCurrency(subsidyBase);
  const subsidyPercentageText = formatPercentage(subsidyPercentage / 100);

  return (
    <div className="flex flex-col text-12-18-em mt-1">
      <div className="flex flex-row">
        <Text
          text="Payout"
          isLoading={isLoading}
          className="text-neutral-500"
        />
        <Text
          text={payoutText}
          isLoading={isLoading}
          className="ml-1 text-lavender-200"
        />
      </div>
      <div className="flex flex-row mt-1">
        <Text
          text="Subsidy"
          isLoading={isLoading}
          className="text-neutral-500"
        />

        <Text
          text={subsidyTotalText}
          isLoading={isLoading}
          className="ml-1 text-lavender-200"
        />
        <Text
          text="/"
          isLoading={isLoading}
          className="ml-0.5 text-lavender-200"
        />
        <Text
          text={`${subsidyPercentageText}%`}
          isLoading={isLoading}
          className="ml-0.5 text-lavender-200"
        />
        <Text
          text="+"
          isLoading={isLoading}
          className="ml-0.5 text-lavender-200"
        />
        <Text
          text={subsidyBaseText}
          isLoading={isLoading}
          className="ml-0.5 text-lavender-200"
        />
      </div>
    </div>
  );
};

export default PayoutComponent;
