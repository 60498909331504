import { Form } from '@kanda-libs/ks-component-ts';

import {
  SelectCustomer,
  SearchHeader,
  SearchResults,
  Field,
} from './components';

import Container from './Customer-container';
import { SEARCH_HEADER_PROPS } from './Customer-constants';

import { Page } from '../..';

const ViewComponent = () => (
  <Container>
    {({
      form,
      onSubmit,
      isLoading,
      showSearchBar,
      showResults,
      nextButtonProps,
      searchProps,
      headerProps,
      searchResultsProps,
    }) => (
      <Form
        id="create-job-customer-mobile"
        form={form}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        <Page
          stickyHeader
          nextButtonProps={nextButtonProps}
          layoutProps={
            showResults
              ? {
                  ...SEARCH_HEADER_PROPS,
                  header: <SearchHeader {...headerProps} />,
                }
              : {}
          }
        >
          {showResults ? (
            <SearchResults {...searchResultsProps} />
          ) : (
            <>
              <Field.Wrapper>
                {showSearchBar && <SelectCustomer {...searchProps} />}
                <Field.Name />
                <Field.Contact />
                <Field.Address />
              </Field.Wrapper>
            </>
          )}
        </Page>
      </Form>
    )}
  </Container>
);
ViewComponent.displayName = 'CreateJob-Customer-Mobile-view';

export default ViewComponent;
