import type { HandleType } from '@kanda-libs/ks-component-ts';

export const AUTH_FIELD = {
  name: 'checks.authorised.value',
  handle: 'checkbox' as HandleType,
};

export const AUTH_FIELD_FIELD_TEXT =
  'I confirm I am duly authorised to sign a contract on behalf of $COMPANY';

export const AUTH_FIELD_VALIDATION = {
  required: {
    value: true,
    message:
      'You must be an authorised member of your company to sign this contract',
  },
};
