import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Button, Text } from '@kanda-libs/ks-design-library';

import useTrainingItem from './useTrainingItem';

import type { TrainingBannerItem } from '..';

export interface TrainingItemProps extends TrainingBannerItem {
  completed?: boolean;
  cta?: JSX.Element;
}

const TrainingItem: FunctionComponent<TrainingItemProps> = function ({
  url,
  title,
  description,
  completed = false,
  welcomePackKey,
  hideComplete,
  cta,
}) {
  const { classNames, markAsCompleted, isSubmitting } =
    useTrainingItem(completed);

  if (completed) return <></>;

  return (
    <>
      <div className={classNames.wrapper}>
        {!hideComplete ? (
          <Button.Icon
            id="training-item-close"
            iconProps={{ className: classNames.cross }}
            icon="close"
            onClick={() => {
              markAsCompleted(welcomePackKey);
            }}
          />
        ) : (
          <div className="min-w-10" />
        )}
        <div className={classNames.content}>
          <Link to={url} className={classNames.textContent}>
            <Text
              text={title}
              className={classNames.title}
              isLoading={isSubmitting}
              skeletonProps={{ width: 300 }}
            />
            <Text
              text={description}
              className={classNames.description}
              isLoading={isSubmitting}
              skeletonProps={{ width: 150 }}
            />
          </Link>
          {cta}
          {!cta && (
            <Link to={url}>
              <Button.Text
                id="training-item-next"
                label=""
                iconSpace={false}
                icon="arrow-right"
                size={40}
                className={classNames.button}
                disabled={completed}
              />
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainingItem;
