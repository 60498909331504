import type { Job } from '@kanda-libs/ks-frontend-services';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export type MostRecentJobHook = Job | undefined;

export default function useMostRecentJob(): MostRecentJobHook {
  const [job] = useSelector(selectors.job.getEntitiesAsArray);

  return job;
}
