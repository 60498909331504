// Finance statuses:
// - not_applied
// - under_review
// - applied_for_finance
// - finance_deposit_paid
// - finacne_not_approved
// - financed
// - sat_note_sent
// - sat_note_viewed
// - sat_note_signed
// - paid

// Statuses:
// - draft
// - sent
// - accepted
// - declined
// - archived

const getFinanceLabel = (financeStatus) => {
  if (!financeStatus) return 'Updated';
  if (financeStatus === 'not_applied') return 'Sent';
  if (financeStatus === 'finance_not_approved') return 'Declined';
  if (
    financeStatus === 'under_review' ||
    financeStatus === 'applied_for_finance'
  )
    return 'Applied';
  if (financeStatus === 'finance_deposit_paid' || financeStatus === 'financed')
    return 'Approved';
  if (financeStatus === 'paid') return 'Paid';
  return 'Updated';
};

export const getLabel = (status, financeStatus, viewedFinance) => {
  if (!status) return 'Updated';
  if (status === 'draft') return 'Created';
  if (status === 'sent') {
    if (viewedFinance) return 'Viewed';
    return 'Sent';
  }
  if (status === 'accepted') return getFinanceLabel(financeStatus);
  if (status === 'declined') return 'Declined';
  if (status === 'archived') return 'Archived';
  return 'Updated';
};
