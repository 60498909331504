import { useContext } from 'react';
import type { PaginationProps } from '@kanda-libs/ks-design-library';

import { SKELETON_JOBS } from './Jobs-constants';

import Context from '../../../Context';

export interface ContainerComponentChildrenArgs {
  data?: any[];
  isLoading: boolean;
  showPagination: boolean;
  paginationProps: PaginationProps;
  showingPlaceholderJobs: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    totalPages,
    pageIndex,
    setPage,
    isLoading = false,
    data: jobs,
    showingPlaceholderJobs = false,
  } = useContext(Context);

  const data = isLoading ? SKELETON_JOBS : jobs;

  const showPagination = !!(totalPages && totalPages > 1);

  const paginationProps = {
    pageCount: totalPages,
    pageIndex,
    setPage,
  } as PaginationProps;

  return children({
    data,
    isLoading,
    showPagination,
    paginationProps,
    showingPlaceholderJobs,
  });
};

ContainerComponent.displayName = 'Jobs-container';

export default ContainerComponent;
