import { Pagination } from '@kanda-libs/ks-design-library';
import Card from './Card';
import type { EnhancedReferral } from './helpers';
import Placeholder from './Placeholder';
import useReferralsCards from './useReferralsCards';

const ReferralsCards: React.FunctionComponent = function () {
  const { data, showCards, paginationProps } = useReferralsCards();
  if (!showCards) return <Placeholder />;
  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col -mx-6">
        {data.map((datum: EnhancedReferral) => (
          <Card {...datum} key={datum.email} />
        ))}
      </div>
      <div className="flex flex-row mt-4 justify-center">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default ReferralsCards;
