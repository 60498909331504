import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import {
  BackButton,
  Desktop,
  InviteBanner,
  InviteForm,
  Mobile,
} from '../components';

const ReferralsForm: React.FunctionComponent = function () {
  return (
    <>
      <Helmet title="Referrals" />
      <BreakPoints
        mobile={
          <Mobile>
            <BackButton />
            <InviteBanner />
            <InviteForm />
          </Mobile>
        }
        desktop={
          <Desktop>
            <BackButton />
            <InviteBanner />
            <InviteForm />
          </Desktop>
        }
      />
    </>
  );
};

export default ReferralsForm;
