import {
  Form,
  Field,
  ModalContainer,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';
import { Header, Button, Loader } from '@kanda-libs/ks-design-library';

import Container from './PhoneModal-container';
import { PHONE_NUMBER_VALIDATION, WRAP_FIELDS } from './PhoneModal-constants';

import { PHONE_MODAL_ID } from '../../Mobile-constants';

const ViewComponent = () => (
  <ModalContainer id={PHONE_MODAL_ID}>
    {({ handleClose }) => (
      <Container handleClose={handleClose}>
        {({ form, onSubmit, isSubmitting, isLoading }) => (
          <Form
            id="account-settings-change-phone"
            form={form}
            onSubmit={onSubmit}
          >
            <ModalLayoutFullScreen
              noBorder
              footer={
                <Button.Text
                  id="account-settings-update-profile"
                  label="Update profile"
                  submit
                  variant="solid"
                  disabled={isSubmitting}
                  className="ml-auto"
                />
              }
              header={
                <Header.Base
                  className="text-style-f-em text-neutral-700"
                  options={[
                    <Button.Icon
                      id="account-settings-update-profile-close"
                      key="close"
                      onClick={handleClose}
                      icon="close"
                    />,
                  ]}
                />
              }
            >
              <div className="flex flex-col">
                <p className="mb-4 text-24-28-em">Change your phone number</p>
                <p className="mb-8 text-14-22">
                  Update the phone number assigned to your profile
                </p>
                <Field.Validator validation={PHONE_NUMBER_VALIDATION}>
                  <Field.PhoneNumberInput
                    {...WRAP_FIELDS.phoneNumber}
                    isLoading={isLoading}
                  />
                </Field.Validator>
              </div>
              <Loader isLoading={isSubmitting} />
            </ModalLayoutFullScreen>
          </Form>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'AccountSettings-Mobile-PhoneModal-view';

export default ViewComponent;
