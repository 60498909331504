import type { MutableRefObject } from 'react';
import { Icon } from '@kanda-libs/ks-design-library';
import { ModalContainer, ModalLayoutCenter } from '@kanda-libs/ks-component-ts';
import Container from './Modal-container';
import { CopyButton, SendButton, Message } from './components';
import { CLASSNAMES, TEXT, WARNING_ICON_PROPS } from './Modal-constants';

interface ViewComponentProps {
  /**
   * ID for the selection modal
   */
  modalId: string;
  /**
   * ID for the selection modal
   */
  customer?: object;
}

const ViewComponent = ({ modalId, customer }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutCenter
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
        className={CLASSNAMES.modal}
      >
        <Container customer={customer}>
          {({ message, href }) => (
            <>
              <p className={CLASSNAMES.heading}>{TEXT.heading}</p>
              <p className={CLASSNAMES.subHeading}>{TEXT.subHeading}</p>
              <Message message={message} />
              <div className={CLASSNAMES.warningContainer}>
                <Icon {...WARNING_ICON_PROPS} />
                <p className={CLASSNAMES.warningText}>{TEXT.warning}</p>
              </div>
              <div className={CLASSNAMES.buttonContainer}>
                <CopyButton message={message} />
                {href && <SendButton href={href} />}
              </div>
            </>
          )}
        </Container>
      </ModalLayoutCenter>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-SMSModal-view';

ViewComponent.defaultProps = {
  customer: null,
};

export default ViewComponent;
