import type { FunctionComponent } from 'react';
import { PopoverMenuItem } from '@kanda-libs/ks-design-library';
import type { JobTableFilterPopoverItemType } from './types';
import useJobTableFilterPopoverItemProps from './useJobTableFilterPopoverItemProps';

export interface JobTableFilterPopoverItemProps {
  status: string;
  type: JobTableFilterPopoverItemType;
  className: string;
}

const JobTableFilterPopoverItem: FunctionComponent<JobTableFilterPopoverItemProps> =
  function ({ status, type, className }) {
    const { active, onClick, label } = useJobTableFilterPopoverItemProps(
      type,
      status,
    );

    return (
      <PopoverMenuItem
        name={label}
        icon={active ? 'check' : ''}
        onClick={onClick}
        className={className}
        selectedColorVariant="green"
        selected={active}
      />
    );
  };

export default JobTableFilterPopoverItem;
