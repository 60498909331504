import { useCallback, useMemo } from 'react';
import { useSignUpContext } from '../../../context';

export interface EditSignUpMethodHook {
  onClick: () => void;
  disabled: boolean;
  showEdit: boolean;
}

export default function useEditSignUpMethod(): EditSignUpMethodHook {
  const { display, setFormDisplay, isSubmitting } = useSignUpContext();

  const onClick = useCallback(
    () => setFormDisplay('buttons'),
    [setFormDisplay],
  );

  const disabled = useMemo(() => isSubmitting, [isSubmitting]);

  const showEdit = useMemo(() => display !== 'success', [display]);

  return {
    onClick,
    disabled,
    showEdit,
  };
}
