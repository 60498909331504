import {
  Field,
  PasswordStrengthIndicator,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';

import { TITLE, DESCRIPTION, WRAP_FIELDS } from './Form-constants';

import { FORM_CLASS_NAMES } from '../../../AccountSettings-constants';
import { SubPageFooter } from '../../../components';

export interface ViewComponentProps {
  /**
   * Is loading flag
   */
  isLoading?: boolean;
  /**
   * Disabled
   */
  passwordValidation: ValidationItems;
  /**
   * On submit function
   */
  confirmPasswordValidation: ValidationItems;
  /**
   * Is loading flag
   */
  disabled?: boolean;
}

const ViewComponent = ({
  isLoading,
  disabled,
  passwordValidation,
  confirmPasswordValidation,
}: ViewComponentProps) => (
  <div className="flex flex-col md:w-96">
    <p className={FORM_CLASS_NAMES.title}>{TITLE}</p>
    <p className={FORM_CLASS_NAMES.description}>{DESCRIPTION}</p>
    <div className={FORM_CLASS_NAMES.fields}>
      <Field.PasswordInput
        {...WRAP_FIELDS.currentPassword}
        isLoading={isLoading}
      />
      <Field.Validator validation={passwordValidation}>
        <Field.PasswordInput {...WRAP_FIELDS.password} isLoading={isLoading} />
      </Field.Validator>
      <PasswordStrengthIndicator
        passwordFieldName="password"
        passwordScoreFieldName="password_strength"
        description="Your password must have a strength of at least 'Good'"
      />
      <Field.Validator validation={confirmPasswordValidation}>
        <Field.PasswordInput
          {...WRAP_FIELDS.confirmPassword}
          isLoading={isLoading}
        />
      </Field.Validator>
    </div>
    <SubPageFooter disabled={disabled} />
  </div>
);

ViewComponent.defaultProps = {
  disabled: false,
};

ViewComponent.displayName = 'ChangePasswordForm-view';

export default ViewComponent;
