import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';

export type GroupedByFeeRates = Record<string, FinanceRate[]>;

export type GroupedByRateRates = Record<string, GroupedByFeeRates>;

export interface RatesTableHook {
  formattedRates?: GroupedByFeeRates;
}

export const groupByAprAndFee = (rates: FinanceRate[]): GroupedByFeeRates => {
  const keys = rates
    .map(
      (rate: FinanceRate) =>
        `${rate?.apr || 0}-${rate.fee}${
          rate?.apr_type === 'BUYNOW_PAYLATER' ? '-bnpl' : ''
        }${
          rate.finance_types &&
          rate.finance_types.length === 1 &&
          rate.finance_types.includes('secondary')
            ? '-2nd'
            : ''
        }`,
    )
    .filter(
      (key: string, index: number, all: string[]) => all.indexOf(key) === index,
    );
  return keys.reduce((final: GroupedByFeeRates, key: string) => {
    const parts = key.split('-');
    const feeRates = rates.filter((rate: FinanceRate) => {
      if (parts.length === 3 && parts.includes('bnpl')) {
        if (rate?.apr_type !== 'BUYNOW_PAYLATER') return false;
        return (
          parseInt(parts[0], 10) === rate?.apr &&
          parseInt(parts[1], 10) === rate?.fee
        );
      }
      return (
        parseInt(parts[0], 10) === rate?.apr &&
        parseInt(parts[1], 10) === rate?.fee
      );
    });
    return {
      ...final,
      [key]: feeRates,
    };
  }, {});
};

export default function useRatesTable(
  rates: FinanceRate[] | undefined,
): RatesTableHook {
  const formattedRates = useMemo(() => {
    if (!rates) return undefined;
    return groupByAprAndFee(rates);
  }, [rates]);

  return {
    formattedRates,
  };
}
