import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { MODAL_ID } from './JobTableFilterModal/constants';

export interface ToggleJobTableFilterModalHook {
  onShow: () => void;
}

export default function useToggleJobTableFilterModal(): ToggleJobTableFilterModalHook {
  const { showModal } = useContext(ModalsWrapperContext);

  const onShow = useCallback(() => {
    showModal(MODAL_ID);
  }, [showModal]);

  return {
    onShow,
  };
}
