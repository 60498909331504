import { actions, useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import useMe from '../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { company, getCompanies } = useCurrentCompany();
  const companiesIsLoading = useSelector(selectors.company.getIsLoading);

  const params = new URLSearchParams(window?.location?.search);
  const sid = params?.get('sid');

  const { push } = useHistory();

  const onError = useApiError(
    'Error marking subscription as pending at this time - please contact Kanda',
  );

  const { isUserLoggedIn, isValidating: loggedInIsValidating } =
    useCurrentUser();

  const { me } = useMe();

  const isLoading = !isUserLoggedIn || !me || !companiesIsLoading;

  useEffect(() => {
    if (loggedInIsValidating) return;
    if (isUserLoggedIn) return;
    push(URLS.login);
  }, [loggedInIsValidating, isUserLoggedIn, push]);

  const submittedRef = useRef(false);
  useEffect(() => {
    if (isLoading || !sid || submittedRef.current) return;
    submittedRef.current = true;

    dispatch(
      actions.pendingSubscription({
        params: { id: sid },
        onError,
        onSuccess: () => {
          getCompanies().then((companiesData = []) => {
            const [companyData] = companiesData;

            if (
              ['completed_setup', 'verified'].includes(
                companyData?.lifecycle || '',
              )
            ) {
              push(URLS.subscription);
              return;
            }
            push(URLS.onboarding);
          });
        },
      }),
    );
  }, [isLoading, sid, onError, push, company, dispatch, getCompanies]);

  return children;
};

ContainerComponent.displayName = 'SubscriptionReturn-Success-container';

export default ContainerComponent;
