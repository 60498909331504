import { Button } from '@kanda-libs/ks-design-library';

import Container from './CallButton-container';
import { CALL_BUTTON_PROPS } from './CallButton-constants';

const ViewComponent = () => (
  <Container>
    {({ number, phoneNumber }) =>
      number ? (
        <a href={phoneNumber}>
          <Button.Link {...CALL_BUTTON_PROPS} size={14} />
        </a>
      ) : null
    }
  </Container>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-CallButton-view';

export default ViewComponent;
