import type { Company } from '@kanda-libs/ks-frontend-services';
import { APP_ENV } from 'config';

export const getIFrameUrl = (company: Company | null): string => {
  const name = company?.contact_info?.trading_name?.replace(/ /g, '+');

  const suffix = APP_ENV === 'qa' ? '-qa' : '';

  if (!company)
    return `https://finance-calculator${suffix}.kanda.co.uk/?noBackground`;

  const url = `https://finance-calculator${suffix}.kanda.co.uk/?cid=${company.id}&noBackground`;

  return [url, `iar=${name}`].join('&');
};
