import { useFormContext } from '@kanda-libs/ks-component-ts';
import { VAT_NUMBER_REGEX } from './constants';

export default function useVatNumberValidation() {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: () => {
        const values = getValues();
        const vatNumber = values?.company?.limited_company_info?.vat_number;

        return !vatNumber || !!vatNumber.match(VAT_NUMBER_REGEX);
      },
      message: 'Please enter a valid VAT number',
    },
  };
}
