import { PlaceHolder } from '@kanda-libs/ks-design-library';

import { Title, Button } from './components';

const ViewComponent = ({}) => (
  <PlaceHolder
    backgroundColour="neutral-50"
    title={(<Title />) as JSX.Element}
    cta={<Button />}
  />
);

ViewComponent.displayName = 'JobsPlaceholder-view';

export default ViewComponent;
