import { Field } from '@kanda-libs/ks-component-ts';
import {
  ACCOUNT_NAME_PROPS,
  ACCOUNT_NUMBER_PROPS,
  SORT_CODE_PROPS,
} from './constants';
import useBankDetailsFields from './useBankDetailsFields';

const Name: React.FunctionComponent = function () {
  const { sortCodeValidation } = useBankDetailsFields();
  return (
    <>
      <Field.Validator validation={ACCOUNT_NAME_PROPS.validation}>
        <Field.Input {...ACCOUNT_NAME_PROPS.field} />
      </Field.Validator>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
        <Field.Validator validation={ACCOUNT_NUMBER_PROPS.validation}>
          <Field.Input {...ACCOUNT_NUMBER_PROPS.field} />
        </Field.Validator>
        <Field.Validator validation={sortCodeValidation}>
          <Field.NumberInput {...SORT_CODE_PROPS.field} />
        </Field.Validator>
      </div>
    </>
  );
};

export default Name;
