export const FIRST_STEP_LABEL = 'How it works';

export const DEFAULT_STEP_LABEL = 'Next';

export const LAST_STEP_LABEL = 'Get started';

export const LABEL_TEXT_STYLE = 'text-style-f-em';

export const LABEL_CLASS_NAMES = {
  base: `transition-all duration-300 ease-out absolute ${LABEL_TEXT_STYLE}`,
  active: 'opacity-100',
  inactive: 'opacity-0',
};

export const LABEL_WRAPPER_CLASS_NAME =
  'flex items-center justify-items-center justify-center flex-row overflow-hidden transition-all duration-300 ease-out';
