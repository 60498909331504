import { useMemo } from 'react';

import { getDescription } from './Description-functions';

import useCurrentJob from '../../../../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { isLoading, job, checkJob } = useCurrentJob();

  const creditStatus = useMemo(() => {
    if (!job || !checkJob) return null;
    if (!checkJob?.has_finance_application) return null;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const xstatus = useMemo(() => checkJob?.xstatus, [checkJob]);

  const financeStatus = useMemo(() => {
    if (xstatus?.includes('AG_LIVE')) return 'ag_live';
    return job?.finance_status || 'not_applied';
  }, [job, xstatus]);

  const { heading, subHeading } = useMemo(
    () => getDescription(job, financeStatus, creditStatus),
    [job, financeStatus, creditStatus],
  );

  return children({
    heading,
    subHeading,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Description-container';

export default ContainerComponent;
