import type { InfoStats } from '@kanda-libs/ks-frontend-services';

export const PLACEHOLDER_STATS: InfoStats = {
  jobs_sent: 0,
  jobs_applied_for_finance: 0,
  jobs_approved_for_finance: 0,
  jobs_total_revenue_of_approved: 0,
  jobs_approval_rate: 0,
  jobs_average_value_of_approved: 0,
};
