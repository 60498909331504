import { useFormContext } from '@kanda-libs/ks-component-ts';

import { removeWhitespaceAndNewline } from '../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const onBlur = () =>
    setValue('email', removeWhitespaceAndNewline(getValues('email')));

  return children({
    onBlur,
  });
};

ContainerComponent.displayName = 'ForgotPassword-Request-Content-container';

export default ContainerComponent;
