import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import useCreateJobContextValue from './CreateJob-useCreateJobContextValue';

export type LeadJobHook = boolean;

export default function useIsLeadJob(): LeadJobHook {
  const { data } = useCreateJobContextValue();

  // TODO: Don't cast
  const isLeadJob = !!(data?.job as unknown as StringIndexedObject<string>)
    ?.quoted_to;

  return isLeadJob;
}
