import type { FinanceRate, WorkType } from '@kanda-libs/ks-frontend-services';

export const financeRateIsSelectable = (financeRate: FinanceRate): boolean =>
  financeRate.apr_type === 'INTEREST_FREE' &&
  (financeRate?.duration || 12) <= 10;

export const financeRateIsWithinBounds = (
  financeRate: FinanceRate,
  jobTotal: number,
  depositPercentage: number,
  depositAmount: number,
  workType?: WorkType,
): boolean => {
  const {
    work_types: workTypes,
    min_job_value: minJobValue,
    max_job_value: maxJobValue,
    min_deposit_pct: minDepPct,
    max_deposit_pct: maxDepPct,
    min_deposit_value: minDepValue,
    max_deposit_value: maxDepValue,
  } = financeRate;

  if (
    workType &&
    workTypes &&
    workTypes.length > 0 &&
    !workTypes.includes(workType)
  )
    return false;

  // First boundary check - deposit percentage greater than max percentage
  if (maxDepPct && depositPercentage > maxDepPct) return false;
  // Second boundary check - deposit amount greater than max amount
  if (maxDepValue && depositAmount > maxDepValue) return false;
  // NOTE: only checking max as even if user sets a minimum (the depositAmount
  // and the depositPercentage) the user can increase it

  // Minimum deposit allowed will be the maximum of the user supplied amount,
  // the minimum depost percentage times the total, or the minimum allowed
  // deposit value of the rate
  const minimumDeposit = Math.max(
    depositAmount,
    jobTotal * ((minDepPct || 0) / 10000),
    minDepValue || 0,
  );

  // Maximum deposit allowed will be the minimum of the maximum percentage
  // times the total, or the maximum deposit value of the rate
  const maximumDeposit = Math.min(
    jobTotal * ((maxDepPct || 100000) / 10000),
    maxDepValue || Infinity,
  );

  // The minimum allowable job value will be the rates minimum job value
  // plus the minimum allowable deposit. The maximum allowable job value
  // will be the rates maximum job value plus the maximum allowable
  // deposit
  const minimumJobValue = (minJobValue || 0) + minimumDeposit;
  const maximumJobValue = (maxJobValue || 0) + maximumDeposit;

  // Check total greater or equal to minimum allowable job value, and less
  // or equal to the maximum allowable job value
  return jobTotal >= minimumJobValue && jobTotal <= maximumJobValue;
};

const rateIsAboveDepositPercentageMinimum = (
  financeRate: FinanceRate,
  depositPercentage: number,
): boolean => {
  if (!financeRate.min_deposit_pct) return true;
  const formattedPercentage = depositPercentage * 100;
  return formattedPercentage >= (financeRate.min_deposit_pct || 0);
};

const rateIsAboveDepositAmountMinimum = (
  financeRate: FinanceRate,
  depositAmount: number,
): boolean => {
  if (!financeRate.min_deposit_value) return true;
  return depositAmount >= ((financeRate.min_deposit_value as number) || 0);
};

export const depositAboveMinimum = (
  financeRate: FinanceRate,
  depositPercentage: number,
  depositAmount: number,
): boolean => {
  const aboveDepositPercentageMinimum = rateIsAboveDepositPercentageMinimum(
    financeRate,
    depositPercentage,
  );
  const aboveDepositAmountMinimum = rateIsAboveDepositAmountMinimum(
    financeRate,
    depositAmount,
  );
  return aboveDepositPercentageMinimum && aboveDepositAmountMinimum;
};
