import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const jobType = data?.jobType;

  return children({ jobType });
};

ContainerComponent.displayName = 'CreateJob-Quote-container';

export default ContainerComponent;
