export const CLASS_NAMES = {
  container: 'flex flex-col max-w-96 mx-auto',
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
};

export const TITLE = 'Reset your password';

export const DESCRIPTION =
  'Enter your email address and we’ll send an email with instructions to reset your password';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  email: {
    name: 'email',
    label: 'Email address',
    placeholder: 'Your email address',
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'email',
  },
};

export const EMAIL_VALIDATION = {
  required: {
    value: true,
    message: 'Email address is required',
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Email must be a valid email address',
  },
};
