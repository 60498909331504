import { HeaderSearch } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import { SEARCH_PLACEHOLDER } from './SearchHeader-constants';

interface ViewComponentProps {
  /**
   * Close modal callback
   */
  hideSearch?: () => void;
  /**
   * On search callback
   */
  handleSearch: () => void;
  /**
   * defaultValue
   */
  defaultValue?: string;
}

const ViewComponent = ({
  hideSearch,
  handleSearch,
  defaultValue,
}: ViewComponentProps) => (
  <HeaderSearch
    autoFocus
    onChange={handleSearch}
    defaultValue={defaultValue}
    placeholder={SEARCH_PLACEHOLDER}
    options={[
      <Button.Icon
        id="create-job-modal-search-header-close"
        key="close"
        icon="close"
        onClick={hideSearch}
      />,
    ]}
  />
);

ViewComponent.defaultProps = {
  defaultValue: undefined,
};

ViewComponent.displayName = 'SearchHeader-view';

export default ViewComponent;
