import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import AddressFields from '../AddressFields';
import CompanyName from '../CompanyName';
import SwitchCompanyTypeButton from '../SwitchCompanyTypeButton';
import { NATIONAL_INSURANCE_NUMBER_VALIDATION } from './constants';

const SoleTraderFields: React.FunctionComponent = function () {
  return (
    <FormTheme variant="streamline">
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex-1">
            <CompanyName />
          </div>
          <SwitchCompanyTypeButton />
        </div>
        <Widget.CompanySoleTraderInfoNationalInsuranceNumber
          validation={NATIONAL_INSURANCE_NUMBER_VALIDATION}
        />
        <AddressFields companyType="sole_trader" />
      </div>
    </FormTheme>
  );
};

export default SoleTraderFields;
