import { useFormContext } from '@kanda-libs/ks-component-ts';

import useContactValidation from './useContactValidation';

import { removeWhitespaceAndNewline } from '../../../../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const { email, phone } = useContactValidation();

  const onBlur = (name) =>
    setValue(name, removeWhitespaceAndNewline(getValues(name)));

  return children({
    email,
    phone,
    onBlur,
  });
};

ContainerComponent.displayName =
  'CreateJob-Mobile-Customer-Field-Contact-container';

export default ContainerComponent;
