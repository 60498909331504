import { Layout, Header, Logo } from '@kanda-libs/ks-design-library';

import { Content, Footer } from '../components';

interface ViewComponentProps {
  /**
   * Disabled state for social buttons
   */
  disabled?: boolean;
  /**
   * Function to sign in with Google
   */
  display?: string;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Layout
    noBorder
    header={
      <Header.Base lines>
        <Logo height={20} />
      </Header.Base>
    }
    footer={<Footer {...props} />}
  >
    <Content {...props} />
  </Layout>
);

ViewComponent.displayName = 'AuthLink-Mobile-view';

ViewComponent.defaultProps = {
  disabled: undefined,
  display: undefined,
};

export default ViewComponent;
