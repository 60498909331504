import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import type { MutableRefObject } from 'react';
import Content from '../Content';
import { MODAL_ID } from './constants';
import LineItemSummary from './LineItemSummary';

interface ModalProps {
  financeOption: FinanceRate;
  totalCost: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  interestLabel: string;
  durationLabel: string;
  rateWithinBounds: boolean;
  depositIsAboveMinimum?: boolean;
}

const Modal = ({
  financeOption,
  interestLabel,
  durationLabel,
  depositIsAboveMinimum = true,
  ...props
}: ModalProps) => (
  <ModalContainer id={`${MODAL_ID}-${financeOption.name}`}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <div className="flex flex-col">
          <h1 className="mb-6 text-18-22-em text-neutral-900">
            Cost breakdown
          </h1>
          <LineItemSummary
            interestLabel={interestLabel}
            durationLabel={durationLabel}
          />
          <Content
            emphasised
            financeOption={financeOption}
            depositIsAboveMinimum={depositIsAboveMinimum}
            {...props}
            title="Your cost"
            totalLabel="Total cost"
          />
        </div>
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

Modal.displayName = 'OptionPopover-view';

export default Modal;
