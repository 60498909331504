import { AlertBanner } from '@kanda-libs/ks-design-library-new';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';
import type { FunctionComponent } from 'react';

const ExampleJobAlertBanner: FunctionComponent = function () {
  const showBanner = useIsExampleJob();

  if (!showBanner) return <></>;

  return (
    <div className="mb-6">
      <AlertBanner>
        This is an example job which will be sent to your own email address, so
        you can get an idea of how the checkout process works for your customers
      </AlertBanner>
    </div>
  );
};

export default ExampleJobAlertBanner;
