import { Button, useIsDesktop } from '@kanda-libs/ks-design-library';

const BookCallButton: React.FunctionComponent = function () {
  const isDesktop = useIsDesktop();
  return (
    <a
      href="https://calendly.com/kanda-sales/intro-call"
      target="_blank"
      rel="noreferrer"
    >
      <Button.Text
        id="setup-book-callback"
        variant="solid"
        size={isDesktop ? 48 : 40}
        label="Book a call back"
        icon="phone"
        left
      />
    </a>
  );
};

export default BookCallButton;
