export const CLASS_NAMES = {
  content: 'flex flex-col items-center p-10 text-center',
  title: 'text-28-32-em text-neutral-700 mb-6',
  description: 'text-16-24 text-neutral-600 mb-8 w-80',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'job',
  size: 64,
};

export const TITLE = 'Get started by creating your first job';

export const DESCRIPTION =
  'To send jobs to your customers, please finish setting up your company first.';

export const BUTTON_PROPS = {
  id: 'home-empty-desktop-create-job',
  label: 'Create a new Job',
  left: true,
  icon: 'plus',
  size: 40,
};
