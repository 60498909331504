import { Icon, Text } from '@kanda-libs/ks-design-library';
import { SendSSOEmailButton } from 'components';
import { useReferralSignUpContext } from 'pages/ReferralSignUp/context';
import { Card, Layout } from '../../components';

const ReferralSignUpSuccess: React.FunctionComponent = function () {
  const { email } = useReferralSignUpContext();
  return (
    <Layout>
      <div className="flex flex-col">
        <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
          Create your Kanda account
        </p>
        <Card>
          <Icon icon="send-quote-color" size={48} className="mb-6 mx-auto" />
          <Text
            text="Confirm your email"
            className="text-16-24-em mb-2 text-center"
          />
          <span className="text-14-22 text-neutral-600 text-center mb-6">
            Follow the link we emailed to{' '}
            <span className="text-14-22-em">{email}</span> to finish setting up
            your account.
          </span>
          <SendSSOEmailButton email={email} />
        </Card>
      </div>
    </Layout>
  );
};

export default ReferralSignUpSuccess;
