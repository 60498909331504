import { MultiStepFormRouter } from '@kanda-libs/ks-component-ts';

import { CLASS_NAMES } from './constants';

import Pages from '../../../../Pages';

const Content: React.FunctionComponent = () => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.content}>
      <MultiStepFormRouter pages={Pages} />
    </div>
  </div>
);

export default Content;
