import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { useCurrentUser, useSignIn } from '@kanda-libs/ks-frontend-services';
import { URLS } from 'config';
import { getError } from 'pages/Login/helpers';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface StaffLoginHook {
  form: ReturnType<typeof useForm>;
  onSubmit: () => void;
  isSubmitting: boolean;
  signInWithGoogle: () => void;
}

export default function useStaffLogin(): StaffLoginHook {
  const user = useSelector(selectors.getUser);
  const form = useForm();

  const onSubmit = useCallback(() => {}, []);

  const { isUserLoggedIn, isValidating } = useCurrentUser();
  const { mutate: googleSignIn, isLoading: signInIsSubmitting } =
    useSignIn('googlePopup');

  const { push } = useHistory();

  const { showError } = useToast();

  const signInWithGoogle = useCallback(() => {
    googleSignIn(false).then(({ error: signInError }) => {
      if (signInError) {
        showError(getError(signInError));
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }, [googleSignIn, showError]);

  /**
   * This useEffect call will listen for the current user state. If the user is logged in
   * it will redirect them either to / or /staff depending on their role.
   */
  useEffect(() => {
    if (isValidating || !isUserLoggedIn || !user) return;

    if (user.role === 'staff') {
      push(URLS.staff);
      return;
    }

    push(URLS.home);
  }, [isUserLoggedIn, isValidating, push, user]);

  const isSubmitting = signInIsSubmitting;

  return {
    form,
    onSubmit,
    isSubmitting,
    signInWithGoogle,
  };
}
