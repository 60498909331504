import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect } from 'react';

import { URLS } from '../../../../../../../../../config';

const ContainerComponent = ({ children }) => {
  const { currentStepIndex } = useOnboardingContextValue();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'verification');
    if (currentStepIndex === 0) {
      window.history.pushState({}, '', `${url}?step=provide-identity`);
      logEvent('page-view');
      return;
    }
    if (currentStepIndex === 1) {
      window.history.pushState({}, '', `${url}?step=photo-id`);
      logEvent('page-view');
      return;
    }
    if (currentStepIndex === 2) {
      window.history.pushState({}, '', `${url}?step=hi-on-camera`);
      logEvent('page-view');
    }
  }, [currentStepIndex, logEvent]);

  return children;
};

ContainerComponent.displayName = 'Wrapper-container';

export default ContainerComponent;
