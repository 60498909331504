import { makeIsAllowed } from '@kanda-utils/library';

export const AVERGAE_JOB_VALUE_PROPS = {
  label: 'Average Job Value',
  placeholder: 'average job value',
  wrapperProps: {
    className: 'mr-2',
  },
  isAllowed: makeIsAllowed(0, 100000),
  type: 'price',
};
