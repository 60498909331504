import { STATUSES, CLASS_NAMES } from './StatusFilters-constants';
import { Item } from './components';

export interface ViewComponentProps {
  /**
   * HandleClose callback
   */
  handleClose: () => void;
}

const ViewComponent = ({ handleClose }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    {STATUSES.map((status) => (
      <Item key={status} status={status} handleClose={handleClose} />
    ))}
  </div>
);

ViewComponent.displayName = 'StatusFilters-view';

export default ViewComponent;
