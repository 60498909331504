import { Button, Text } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { WARNING } from './AddBankDetails-constants';
import Container from './AddBankDetails-container';

import { URLS } from '../../../../../../../../config';

const ViewComponent = () => (
  <Container>
    {({ showButton }) =>
      showButton ? (
        <div className="flex flex-col mt-2">
          <Text text={WARNING} className="text-12-18 text-neutral-700" />
          <Link to={URLS.companyInformation}>
            <Button.Text
              id="job-details-finance-progress-add-bank-details"
              label="Add bank details"
              size={40}
              className="w-full mt-2"
            />
          </Link>
        </div>
      ) : (
        <></>
      )
    }
  </Container>
);

ViewComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Description-view';

export default ViewComponent;
