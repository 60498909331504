import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useMemo } from 'react';
import useCurrentCompany from './useCurrentCompany';

export type UserDefaultValuesHook = StringIndexedObject;

export default function useUserDefaultValues(): UserDefaultValuesHook {
  const { company } = useCurrentCompany();

  const defaultValues = useMemo(() => {
    if (!company || !company?.users || company?.users?.length === 0) return {};
    return {
      company: {
        users: company.users.map((user) => ({
          ...user,
          state: 'existing',
        })),
      },
    } as StringIndexedObject;
  }, [company]);

  return defaultValues;
}
