import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  container: '-mt-6 mb-10 md:mt-0',
  content: 'flex w-full flex-col',
  header: 'flex w-full flex-row justify-between',
  title: 'text-16-20-em md:text-14-22–em text-neutral-900 mb-3 w-100',
  closeButton: '-m-3 icon-stroke-md',
};

export const PROPS = {
  title: {
    className: CLASS_NAMES.title,
    text: 'Did you know using a Kanda quote can boost conversion up to 25%?',
  },
  closeButton: {
    className: CLASS_NAMES.closeButton,
    icon: 'close',
    variant: 'light-turquoise' as ButtonIconVariant,
  },
  changeQuoteButton: {
    id: 'create-custom-job-build-kanda-quote',
    icon: 'arrow-right',
    label: 'Build a Kanda quote',
    size: 24,
  },
};

export const TOAST_MESSAGES = {
  admin:
    "You're now building a quote with Kanda's quote builder - if you would like this to be your default choice, you can change this in your job settings",
  other:
    "You're now building a quote with Kanda's quote builder - if you would like this to be your default choice, as a company admin to change this in job settings",
};
