import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

export interface ContainerComponentChildrenArgs {
  note: string;
}
export interface ContainerComponentHook {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  return children({
    note: data?.job?.notes?.[0] || 'No note added...',
  });
};

ContainerComponent.displayName = 'JobDetails-Notes-Mobile-Note-container';

export default ContainerComponent;
