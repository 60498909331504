import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

import { Content, Footer } from '../components';

interface ViewComponentProps {
  /**
   * Disabled state
   */
  disabled?: boolean;
  /**
   * Page
   */
  page?: number;
}

const ViewComponent = ({ disabled, page }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <Content page={page} />
      <>{page === 0 && <Footer disabled={disabled} />}</>
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'Login-Desktop-view';

ViewComponent.defaultProps = {
  disabled: false,
  page: 0,
};

export default ViewComponent;
