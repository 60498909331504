import { Field, Form, FormTheme } from '@kanda-libs/ks-component-ts';
import useCalculatorForm from './useCalculatorForm';

const SubscriptionCalculatorForm: React.FunctionComponent = function () {
  const { form, numJobsForSub, extraRevenue } = useCalculatorForm();
  return (
    <Form
      id="subscription-calculator-form"
      form={form}
      onSubmit={() => {}}
      className="flex flex-col w-full"
    >
      <FormTheme variant="streamline">
        <div className="flex flex-col md:flex-row gap-0 md:gap-8">
          <div className="flex flex-row">
            <div className="flex mb-6">
              <p className="text-14-22-em text-neutral-700 mr-4 whitespace-nowrap my-auto">
                Margin per job
              </p>
            </div>
            <div className="w-36">
              <Field.NumberInput
                name="margin"
                type="price"
                icon="pound"
                symbol=""
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex mb-6">
              <p className="text-14-22-em text-neutral-700 mr-4 whitespace-nowrap my-auto">
                Jobs won
              </p>
            </div>
            <div className="w-36">
              <Field.NumberInput
                name="jobsWon"
                decimalScale={0}
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <div className="border-b border-neutral-300 mb-8 mt-3" />
        <div className="flex flex-row gap-9 md:gap-12">
          <div className="flex flex-col">
            <p className="text-14-22-em md:text-18-28-em text-neutral-500 mb-2">
              Subscription paid for
            </p>
            <p className="text-24-28-em md:text-32-40-em text-neutral-600">
              {numJobsForSub} jobs
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-14-22-em md:text-18-28-em text-green-500 mb-2">
              Extra revenue
            </p>
            <p className="text-24-28-em md:text-32-40-em text-green-600">
              {extraRevenue}
            </p>
          </div>
        </div>
        <p className="text-14-22 text-neutral-600 mt-4">
          Calculated using annual plan
        </p>
      </FormTheme>
    </Form>
  );
};

export default SubscriptionCalculatorForm;
