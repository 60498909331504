import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type {
  Company,
  FinanceRate,
  Job,
} from '@kanda-libs/ks-frontend-services';
import { cleanObject, getCompanyInitialRates } from '../../utils';
import { financeRateIsSelectable } from './components/PaymentAndFinanceOptions/FinanceOptions/components/SlimOption/helpers';
import type { CreateJobContextValue } from './types';

const extractMessage = (error: StringIndexedObject) => {
  const parts = error?.split(', ');
  const object = parts?.reduce((obj, part) => {
    const split = part?.split('=');
    return {
      ...obj,
      [split[0]]: split[1],
    };
  }, {});
  return (
    object?.message ||
    'There has been an error posting this job at this time - please contact Kanda'
  );
};

export const formatError = (error: StringIndexedObject | string): string => {
  if (typeof error === 'string') return error;
  if (error?.message) return extractMessage(error.message);
  return 'Error posting draft';
};

const formatJob = (job: CreateJobContextValue['job'], company: Company) => {
  if (company.skip_deposit === 'No')
    return {
      ...job,
      deposit_type: 'no_deposit',
      deposit_value: {
        amount: 0,
        currency: 'GBP',
      },
    };
  if (job?.deposit_type === 'no_deposit') {
    return {
      ...job,
      deposit_type: 'fixed_deposit',
      deposit_value: {
        amount: 0,
        currency: 'GBP',
      },
    };
  }
  return job;
};

export const formatJobPayload = (
  formData: CreateJobContextValue,
  notes: string | null = null,
  company: Company,
): Job => {
  const { job } = formData;

  const formattedJob = formatJob(job, company);

  return {
    ...cleanObject(
      {
        ...formattedJob,
        notes: [notes || ''],
      },
      true,
    ),
    finance_options: (job?.finance_options || []).filter((rate) =>
      financeRateIsSelectable(rate),
    ),
  };
};

const formatFinanceOptions = (
  jobType: Job['job_type'],
  financeOptions: FinanceRate[],
  company: Company,
): FinanceRate[] => {
  if (!financeOptions || financeOptions?.length === 0) return [];
  if (jobType === 'solar') return financeOptions;
  const companyOptions = getCompanyInitialRates(
    company.finance_rates as FinanceRate[],
  );
  return companyOptions.reduce(
    (rates: FinanceRate[], option: FinanceRate): FinanceRate[] => {
      if (
        !financeOptions?.some(
          (rate: FinanceRate) =>
            rate.apr === option.apr &&
            rate.apr_type === option.apr_type &&
            rate.duration === option.duration &&
            rate.provider === option.provider,
        )
      ) {
        return rates;
      }
      rates.push(option);
      return rates;
    },
    [],
  );
};

export const formatDuplicate = (duplicate: Job, company: Company): Job => {
  // Extract relevant info
  const {
    customer,
    deposit_type: depositType,
    deposit_value: depositValue,
    finance_options: financeOptions,
    checkout_options: checkoutOptions,
    job_items: jobItems,
    notes: jobNotes = [],
    title,
    work_type: workType,
  } = duplicate;

  // const reapplied = inputFinanceOptions?.some(
  //   (rate: FinanceRate) => !rate.enabled,
  // );

  // const financeOptions = inputFinanceOptions?.reduce(
  //   (all: FinanceRate[], rate: FinanceRate) => {
  //     const types = rate.finance_types;
  //     if (!types.includes('primary')) return all;
  //     if (reapplied && rate.enabled) return all;
  //     return [
  //       ...all,
  //       {
  //         ...rate,
  //         enabled: true,
  //       },
  //     ];
  //   },
  //   [],
  // );

  const jobType = duplicate?.job_type || 'standard';

  // Format items to remove any quote documents - they'll need to be uploaded again
  const formattedItems = jobItems.map((item) => {
    const newItem = { ...item };
    if (newItem?.quote_document) delete newItem?.quote_document;
    return newItem;
  });

  // Format rates to remove old rates
  const formattedFinance = formatFinanceOptions(
    jobType,
    financeOptions || [],
    company,
  );

  // Pass back extracted data
  return {
    customer,
    deposit_type: depositType,
    deposit_value: depositValue,
    finance_options: formattedFinance,
    checkout_options: checkoutOptions,
    job_items: formattedItems,
    title,
    job_type: jobType,
    notes: jobNotes,
    work_type: workType,
  };
};

export const getJobType = (
  company: Company | null,
  job: Job | null,
): Company['quote_preference'] => {
  if (!job) return company?.quote_preference || 'kanda';
  const style = job?.job_items?.[0]?.style;
  if (!style) {
    if (job?.quoted_to) return company?.quote_preference || 'kanda';
    return 'kanda';
  }
  return style;
};

export const formatExampleDraft = (draft: Job): Job => ({
  ...draft,
  work_type: 'ev_charger',
  ...(draft?.customer
    ? {
        customer: {
          ...draft.customer,
          phone: draft?.customer?.phone || '+447123456789',
        },
      }
    : {}),
});
