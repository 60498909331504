import Container from './Fields-container';
import { Wrapper } from './components';
import { WARNING } from './Fields-constants';

const ViewComponent = ({}) => (
  <Container>
    {({ onClick, financeOptionsError, options }) => (
      <>
        <div className="flex flex-col">
          {(options || []).map(({ children, ...rest }) => (
            <Wrapper key={rest.depositType} onClick={onClick} {...rest}>
              {children}
            </Wrapper>
          ))}
        </div>
        {financeOptionsError && (
          <div className="mt-4 p-3 border border-orange-200 bg-orange-100 text-orange-200 text-style-h rounded-lg">
            {WARNING}
          </div>
        )}
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Desktop-Deposit-Fields-view';

export default ViewComponent;
