export const IN_REVIEW_TITLE = "We're reviewing your account";

export const IN_REVIEW_DESCRIPTION =
  "It takes Kanda 2-4 working days to review your account. We'll notify you if we need more information. ";

export const IN_REVIEW_FCA_DESCRIPTION =
  "It can take the FCA and our Lender 2-4 weeks to review your account. We'll notify you if they need more information. ";

export const IN_REVIEW_LENDER_DESCRIPTION =
  "It can take the Lender 2-4 weeks to review your account. We'll notify you if they need more information. ";
