import type {
  ButtonIconVariant,
  ButtonTextVariant,
} from '@kanda-libs/ks-design-library';

export const BUTTON_PROPS = {
  mobile: {
    variant: 'solid-grey' as ButtonIconVariant,
    icon: 'archive',
    size: '40-20' as unknown as number,
    id: 'jobs-banner-archive',
  },
  desktop: {
    variant: 'outline' as ButtonTextVariant,
    size: 40,
    id: 'jobs-banner-archive',
    text: 'Archive',
    label: 'View archive',
    className: 'whitespace-nowrap',
  },
};
