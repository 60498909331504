import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from '../../../components';
import Desktop from './Desktop';
import Mobile from './Mobile';

const ViewComponent = () => (
  <>
    <Helmet title="Job Settings" />
    <BreakPoints desktop={<Desktop />} mobile={<Mobile />} />
  </>
);

ViewComponent.displayName = 'TeamSettings-view';

export default ViewComponent;
