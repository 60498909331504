import { useForm, type StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { URLS } from '../../../config';

const ContainerComponent = ({ children }) => {
  const { state } = useLocation();
  const { code, mode } = state;

  const { mutate: resetPassword, isLoading: setNewPasswordIsSubmitting } =
    useMutate(FirebaseAuthService.resetPassword);

  const { mutate: validateCode, isLoading: codeIsValidating } = useMutate(
    FirebaseAuthService.verifyResetCode,
  );

  const { showError } = useToast();

  const { push } = useHistory();

  const form = useForm();

  const disabled = setNewPasswordIsSubmitting || !form.formState.isDirty;

  const onSubmit = (formValues) => {
    const { password: newPassword } = formValues;
    resetPassword(code, newPassword).then(({ error: newPasswordError }) => {
      if (newPasswordError) {
        showError('Error setting new password');
        return;
      }
      push(URLS.resetPasswordConfirmation);
    });
  };

  const emailRef = useRef<StringIndexedObject | null>(null);
  // Effect verifies code is valid
  useEffect(() => {
    if (!code || codeIsValidating) return;
    if (emailRef.current) return;
    validateCode(code).then(({ data, error }) => {
      if (error || !data) {
        showError('Code is invalid - please request a new code');
        push(URLS.forgotPassword);
      }
      emailRef.current = data as StringIndexedObject;
    });
  }, [code, codeIsValidating, validateCode, push, showError]);

  useEffect(() => {
    if (mode && mode === 'PASSWORD_RESET') return;
    showError('Please request a password reset from this page');
    push(URLS.forgotPassword);
  }, [mode, push, showError]);

  return children({
    onSubmit,
    form,
    disabled,
    isSubmitting: setNewPasswordIsSubmitting,
    isLoading: codeIsValidating,
  });
};

ContainerComponent.displayName = 'ResetPassword-Landing-container';

export default ContainerComponent;
