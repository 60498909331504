import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Success-container';
import Mobile from '../Mobile';
import Desktop from '../Desktop';
import SplashScreen from './SplashScreen';

const ViewComponent = () => (
  <Container>
    <BreakPoints
      mobile={
        <Mobile>
          <SplashScreen />
        </Mobile>
      }
      desktop={
        <Desktop>
          <SplashScreen />
        </Desktop>
      }
    />
  </Container>
);

ViewComponent.displayName = 'SubscriptionReturn-Success-view';

export default ViewComponent;
