const ContainerComponent = ({ children }) => {
  // DEV_NOTE: add this back in to enable customer search
  const customers = [];
  const isLoading = false;

  const noCustomers = !isLoading && !customers;

  return children({
    isLoading,
    noCustomers,
  });
};

ContainerComponent.displayName = 'CreateJob-Desktop-Customer-Field-container';

export default ContainerComponent;
