import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Container from './ChangePassword-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = ({}) => (
  <Container>
    {({ form, onSubmit, email, isSubmitting, ...rest }) => (
      <Form
        id="account-settings-change-password"
        form={form}
        onSubmit={onSubmit}
      >
        <input
          type="text"
          name="email"
          value={email}
          readOnly
          className="hidden"
          autoComplete="email username"
        />
        <BreakPoints
          mobile={<Mobile {...rest} />}
          desktop={<Desktop {...rest} />}
        />
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'ChangePassword-view';

export default ViewComponent;
