import {
  MultiStepFormContextValue,
  useMultiStepFormContext,
} from '@kanda-libs/ks-component-ts';
import type { OnboardingContextValue } from './types';

export type OnboardingContextValueHook =
  MultiStepFormContextValue<OnboardingContextValue>;

export default function useOnboardingContextValue(): OnboardingContextValueHook {
  return useMultiStepFormContext<OnboardingContextValue>();
}
