import { Layout } from '@kanda-libs/ks-design-library';

import { TITLE, CLASS_NAMES } from '../AccountSettings-constants';
import Header from '../../components/Header';
import { Menu, NameModal, PhoneModal, VerifyEmailModal } from './components';

const ViewComponent = ({}) => (
  <Layout noBorder header={<Header />}>
    <p className={CLASS_NAMES.title}>{TITLE}</p>
    <Menu />
    <NameModal />
    <PhoneModal />
    <VerifyEmailModal />
  </Layout>
);

ViewComponent.displayName = 'AccountSettings-Mobile-view';

export default ViewComponent;
