import { Footer } from './components';

import { Page } from '../..';
import { SendConfirmation } from '../../../../components';

const ViewComponent = () => (
  <Page footer={Footer} simpleHeader>
    <SendConfirmation.Body />
  </Page>
);

ViewComponent.displayName = 'CreateJob-Mobile-SendConfirmation-view';

export default ViewComponent;
