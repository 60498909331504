import type { TableProps } from '@kanda-libs/ks-component-ts';
import type { Company, Job } from '@kanda-libs/ks-frontend-services';
import { createContext } from 'react';
import type { HomeFilter } from './Provider/types';

export interface HomeContextValue extends Partial<TableProps> {
  filter?: {
    price?: string;
    status?: string;
    financeStatus?: string;
  };
  updateFilter: (filter: keyof HomeFilter, value: string) => void;
  setFilter: (value: HomeFilter) => void;
  sorting?: string;
  order?: string;
  setSorting: (value: string) => void;
  setOrder: (value: string) => void;
  jobs?: Job[];
  company: Company | null;
  hasActiveFilters?: boolean;
  archive?: boolean;
  showOptions: boolean;
  query?: string;
  setQuery?: (value: string) => void;
  onAddFilter: (type: keyof HomeFilter, status: string) => void;
  onRemoveFilter: (type: keyof HomeFilter, status: string) => void;
  downloadPdf?: (id: string) => void;
  pdfIsSubmitting?: boolean;
  companyIsValidating: boolean;
  setPageIndex: (value: number) => void;
  perPage?: number;
  setPerPage: (value: number) => void;
  showingPlaceholderJobs?: boolean;
}

const Context = createContext<
  Partial<HomeContextValue> & {
    updateFilter: HomeContextValue['updateFilter'];
    onRemoveFilter: HomeContextValue['onRemoveFilter'];
    onAddFilter: HomeContextValue['onAddFilter'];
  }
>({
  updateFilter: () => {
    // Empty
  },
  onAddFilter: () => {},
  onRemoveFilter: () => {},
});

Context.displayName = 'Jobs-Context';

export default Context;
