import type { StatusTagStatus } from '@kanda-libs/ks-design-library';

export const STATUS_TAG_PROPS = {
  className: 'mr-3',
  size: 21,
  variant: 'solid',
  status: 'draft' as StatusTagStatus,
};

export const SKELETONS = {
  content: true,
  option: true,
  title: true,
};

export const CARD_PROPS = {
  padding: 'py-5 px-6',
  variant: 'simple',
};
