import { calculateJobTotal } from '../utils';
import { WARNING_MAXIMUM_PRICE } from '../pages/CreateJob/components/PaymentAndFinanceOptions/FinanceOptions/FinanceOptions-constants';

export default function useLowTotalWarning(lineItems) {
  if (!lineItems || lineItems?.length < 1) return true;

  const { totalIncVat: total } = calculateJobTotal(lineItems);

  const showWarning = total < WARNING_MAXIMUM_PRICE;

  return showWarning;
}
