import { MultiStepFormIndicator } from '@kanda-libs/ks-component-ts';
import {
  Button,
  DesktopLayoutBoxedContentHeader,
} from '@kanda-libs/ks-design-library';

interface ViewComponentProps {
  /**
   * prev step function
   */
  prevStep?(...args: unknown[]): unknown;
  /**
   * prev step function
   */
  step?: number;
}

const ViewComponent = ({ prevStep, step }: ViewComponentProps) => (
  <DesktopLayoutBoxedContentHeader>
    <>
      {step !== 0 && (
        <Button.Icon
          id="onboarding-account-verification-header-back"
          icon="arrow-left"
          className="absolute"
          onClick={prevStep}
        />
      )}
      <MultiStepFormIndicator />
    </>
  </DesktopLayoutBoxedContentHeader>
);

ViewComponent.displayName =
  'AccountVerificationExplanation-Desktop-ContentHeader-view';

ViewComponent.defaultProps = {
  prevStep: undefined,
  step: 0,
};

export default ViewComponent;
