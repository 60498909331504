export const CLASS_NAMES = {
  container: 'flex flex-col w-full -mt-7 mb-10 bg-neutral-000',
};

export const CARD_CLASS_NAMES = {
  wrapper:
    'bg-neutral-50 border border-neutral-300 rounded-xl p-6 flex flex-col text-left',
  card: 'flex items-center',
  icon: 'text-green-600 mr-6',
  cardInnerWrapper: 'w-auto flex flex-col justify-center',
  title: 'text-16-20-em text-neutral-900 mb-2',
  description: 'text-style-g text-neutral-600',
  mobileIconWrapper: 'md:hidden mb-2',
  mobileIcon: 'mr-4 text-neutral-600',
};
