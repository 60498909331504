import { SideBarItem } from './components';
import Container from './SideBar-container';

const ViewComponent = () => (
  <Container>
    {({ items }) => (
      <div className="flex flex-col w-full">
        {items.map((item) => (
          <SideBarItem key={item.name} {...item} />
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopLayout-SideBar-view';

export default ViewComponent;
