import { Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Container from './Summary-container';

import { Page } from '../..';
import { Summary } from '../../../../components';
import { SubscriptionModal } from './components';

const ViewComponent = () => (
  <Container>
    {({ nextButtonProps, isSubmitting, form, onSubmit }) => (
      <Form id="create-job-summary-mobile" form={form} onSubmit={onSubmit}>
        <Page nextButtonProps={nextButtonProps} simpleHeader saveButton>
          <Summary.Header />
          <Summary.CompanyDetails />
          <Summary.JobDetails />
          <Summary.PaymentMethods />
          <SubscriptionModal />
          <Summary.CompleteAccountBadge />
          <Summary.AccountCompletedBadge />
          <Loader isLoading={isSubmitting} />
        </Page>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Summary-Mobile-view';

export default ViewComponent;
