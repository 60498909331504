import { Button, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { DESCRIPTION, TITLE } from './Content-constants';

import { URLS } from '../../../../config';

const ViewComponent = ({}) => (
  <div className="flex flex-col flex-1 w-full -mt-16 max-w-80 md:mt-0">
    <div className="flex flex-col my-auto text-center">
      <Icon icon="user-square-color" size={104} className="mx-auto mb-8" />
      <h1 className="mb-4 text-24-28-em text-neutral-900">{TITLE}</h1>
      <h2 className="mb-8 text-16-24-em text-neutral-600">{DESCRIPTION}</h2>
      <Link to={URLS.login}>
        <Button.Text
          id="claim-resent-sign-in"
          label="Sign in"
          icon="arrow-right"
          variant="solid"
        />
      </Link>
    </div>
  </div>
);

ViewComponent.displayName = 'Veriff-Content-view';

export default ViewComponent;
