import { Card, Icon } from '@kanda-libs/ks-design-library';

import Container from './Mobile-container';
import { CARD_PROPS, ICON_PROPS } from './Mobile-constants';

import { Footer, List } from '../components';
import { TITLE } from '../Items-constants';

const ViewComponent = () => (
  <Container>
    {({ classNames, toggleCollapsed, isLoading, kanda }) => (
      <Card
        onClick={kanda ? toggleCollapsed : undefined}
        isLoading={isLoading}
        {...CARD_PROPS}
        title={
          <p className={classNames.title}>{kanda ? TITLE : 'Job details'}</p>
        }
        option={kanda && <Icon {...ICON_PROPS} />}
        footer={<Footer />}
      >
        <div className={classNames.container}>
          <List />
          <div className={classNames.gradient} />
        </div>
      </Card>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-Items-Mobile-view';

export default ViewComponent;
