import { useContext } from 'react';
import { useFormContext } from '@kanda-libs/ks-component-ts';
import { useIsDesktop } from '@kanda-libs/ks-design-library';
import type { Job } from '@kanda-libs/ks-frontend-services';

import Context from '../../../../../Context';

const ContainerComponent = ({
  children,
  handleClose,
  removeItem = () => {},
}) => {
  const { focus, setFocus } = useContext(Context);
  const { getValues } = useFormContext();
  const isDesktop = useIsDesktop();

  /**
   * Function to delete an item
   */
  const deleteItem = () => {
    // Remove the item
    removeItem();
    // Close the modal
    handleClose();

    if (!isDesktop) {
      return;
    }

    // Gte the form values
    const items: Job['job_items'] = getValues('job.job_items');
    // If the item is the last item, set the focus to one before
    if (items.length === (focus || 0) + 1) setFocus((focus || 0) - 1);
  };

  return children({
    deleteItem,
  });
};

ContainerComponent.displayName = 'Item-Options-Modal-Container';

export default ContainerComponent;
