import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Mobile from './Mobile';
import Desktop from './Desktop';
import Container from './AuthLink-container';

import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <Container>
    {({ display, form, onSubmit, isSubmitting, ...restProps }) =>
      display && (
        <>
          <Helmet title="Authentication" />
          <Form id="authentication-link" form={form} onSubmit={onSubmit}>
            <BreakPoints
              mobile={<Mobile display={display} {...restProps} />}
              desktop={<Desktop display={display} {...restProps} />}
            />
            <Loader isLoading={isSubmitting} />
          </Form>
        </>
      )
    }
  </Container>
);

ViewComponent.displayName = 'AuthLink-view';

export default ViewComponent;
