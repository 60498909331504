import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Widget } from '@kanda-libs/ks-component-ts';

import { capitaliseInputOnChange } from 'utils';

import useReferralCode from './useReferralCode';

const ViewComponent = ({}) => {
  const { onApplyClick, onBlur, isSubmitting } = useReferralCode();

  return (
    <div className="mt-8">
      <Widget.CompanyReferralCode
        label="Got a referral code?"
        placeholder="e.g KANDAROCKS"
        onChange={capitaliseInputOnChange as () => void}
        onBlur={onBlur}
      />
      <Button.Text
        id="referral-form-apply-referral-code"
        label="Apply referral code"
        className="w-full"
        submit={false}
        disabled={isSubmitting}
        onClick={onApplyClick}
      />
      <Loader isLoading={isSubmitting} />
    </div>
  );
};

ViewComponent.displayName = 'SubscribeForm-Referral-view';

export default ViewComponent;
