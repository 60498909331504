import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface CreateExampleJobHook {
  onCreateExampleJob: () => void;
  isLoading: boolean;
}

export default function useCreateExampleJob(): CreateExampleJobHook {
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const { showError } = useToast();
  const onError = useApiError('Error creating example job');

  const onCreateExampleJob = useCallback(() => {
    dispatch(
      actions.infoExampleJob({
        onError,
        onSuccess: (data) => {
          if (!data?.id) {
            showError('Error creating example job');
            return;
          }
          push(URLS.createJobFromDraft.replace(':id', data?.id));
        },
      }),
    );
  }, [dispatch, onError, showError, push]);

  return {
    onCreateExampleJob,
    isLoading: isSubmitting,
  };
}
