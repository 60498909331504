import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';

export interface LeadFinanceOptionHook {
  isLeadJob: boolean;
  financeOptions: FinanceRate[];
  isLoading: boolean;
}

export default function useLeadFinanceOption(): LeadFinanceOptionHook {
  const { data } = useCreateJobContextValue();
  const isLeadJob = useIsLeadJob();

  const financeOptions = data.job?.finance_options || [];

  return { isLeadJob, financeOptions, isLoading: data.isLoading || false };
}
