import { Text } from '@kanda-libs/ks-design-library';
import { AlertBanner } from '@kanda-libs/ks-design-library-new';
import type { FunctionComponent } from 'react';
import { CLASS_NAMES } from './constants';
import useLeadBanner from './useLeadBanner';

const LeadBanner: FunctionComponent = function () {
  const { showLeadBanner, customer, address } = useLeadBanner();

  if (!showLeadBanner) return <></>;

  return (
    <div className="mb-4">
      <AlertBanner>
        <p>Customer details</p>
        <div className={CLASS_NAMES.row}>
          <Text text="Name:" className={CLASS_NAMES.label} />
          <Text
            text={[customer?.first_name, customer?.last_name].join(' ')}
            className={CLASS_NAMES.value}
          />
        </div>
        <div className={CLASS_NAMES.row}>
          <Text text="Email:" className={CLASS_NAMES.label} />
          <Text text={customer?.email} className={CLASS_NAMES.value} />
        </div>
        <div className={CLASS_NAMES.row}>
          <Text text="Phone:" className={CLASS_NAMES.label} />
          <Text text={customer?.phone} className={CLASS_NAMES.value} />
        </div>
        <div className={CLASS_NAMES.row}>
          <Text text="Address:" className={CLASS_NAMES.label} />
          <Text text={address} className={CLASS_NAMES.value} />
        </div>
      </AlertBanner>
    </div>
  );
};

export default LeadBanner;
