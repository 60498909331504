import { useForm } from '@kanda-libs/ks-component-ts';
import { actions, Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useCurrentCompany from '../../../../../../../../../hooks/useCurrentCompany';
import { cleanObject } from '../../../../../../../../../utils';

export default function useInsuranceInfo() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const form = useForm({
    mode: 'onBlur',
  });

  const { data, nextStep } = useOnboardingContextValue();
  const { push } = useHistory();
  const { company } = useCurrentCompany();

  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const hasSignedContract = useMemo(() => {
    if (!data) return false;
    return Boolean(data?.contract?.filename);
  }, [data]);

  const submitContract = useCallback(
    (companyData: Company) => {
      if (!companyData) return;
      const id = companyData?.id;
      if (!id) return;
      const companyInfo = {
        ...companyData?.company_info,
        contract_agreement: {
          contract: {
            name: data?.contract?.document.name,
            content: data?.contract?.document.content,
          },
          agreed_terms_and_conditions: 'yes',
          agreed_to_become_iar_of_kanda: 'yes',
          print_name: data?.contract?.signee || 'no signee name',
        },
      };
      const body = cleanObject({
        ...companyData,
        company_info: companyInfo,
      });
      dispatch(
        actions.putCompany({
          body,
          params: { id },
          onError: (error) => {
            setIsSubmitting(false);
            onError(error);
          },
          onSuccess: () => {
            setIsSubmitting(false);
            push(URLS.exampleJob);
          },
        }),
      );
    },
    [data, dispatch, onError, push],
  );

  const onSubmit = useCallback(
    (formData) => {
      if (!company) return;
      const id = company?.id;
      if (!id) return;
      const companyInfo = {
        ...company?.company_info,
        ...formData.company.company_info,
        insurance_document: {
          ...formData.company.company_info.insurance_document,
          expiry: formData.company.company_info.insurance_document.expiry
            .split('/')
            .reverse()
            .join('-'),
        },
      };
      const body = cleanObject({
        ...company,
        company_info: companyInfo,
      });
      setIsSubmitting(true);
      dispatch(
        actions.putCompany({
          body,
          params: { id },
          onError: (error) => {
            setIsSubmitting(false);
            onError(error);
          },
          onSuccess: (successData: Company) => {
            if (hasSignedContract) {
              submitContract(successData);
              return;
            }
            setIsSubmitting(false);
            nextStep();
          },
        }),
      );
    },
    [nextStep, company, dispatch, onError, hasSignedContract, submitContract],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
    isLoading: !company,
  };
}
