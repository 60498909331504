import clsx from 'clsx';

import { getColor, getFinanceStatus } from './PriceStatus-functions';

const ContainerComponent = ({ children, status, financeStatus }) => {
  const color = getColor(status, financeStatus);

  const { financed, paid } = getFinanceStatus(financeStatus);

  const strikeThrough = status === 'declined';

  const classNames = {
    wrapperClassName: 'my-auto',
    poundsClassName: clsx('text-14-22-em', strikeThrough && 'line-through'),
    centsClassName: clsx('text-14-22-em', strikeThrough && 'line-through'),
  };

  return children({
    color,
    financed,
    paid,
    classNames,
  });
};

ContainerComponent.displayName = 'PriceStatus-container';

export default ContainerComponent;
