import { NavigationLink } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from './Menu-constants';
import Container from './Menu-container';

const ViewComponent = () => (
  <Container>
    {({ items, isLoading, Tag }) => (
      <div className={CLASS_NAMES.container}>
        {items.map((item) => (
          <div key={item.name} className={CLASS_NAMES.link}>
            <Tag to={isLoading ? '' : item.to}>
              <NavigationLink
                isLoading={isLoading}
                title={item.name}
                subtitle={item.description}
                badgeColor={item.badgeColor}
              />
            </Tag>
          </div>
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Menu-view';

export default ViewComponent;
