import { AlternateColourText } from 'components';
import { MAIN_COPY } from './constants';
import SubscriptionCalculatorForm from './Form';

const Calculator: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col mt-16">
      <p className="text-18-28-em text-green-400 mb-4">It pays for itself</p>
      <AlternateColourText
        text={[
          'Most tradespeople have their subscriptions paid for in ',
          '3 jobs',
          ' on finance',
        ]}
        className="mb-6"
      />
      <p className="w-full max-w-100 text-14-22 md:text-18-28 text-neutral-900 mr-auto">
        {MAIN_COPY}
      </p>
      <div className="md:px-6 md:py-8 md:border border-neutral-300 rounded-lg mt-6 md:mt-12">
        <p className="text-16-24-em md:text-18-28-em mb-4">
          Calculate your profit
        </p>
        <SubscriptionCalculatorForm />
      </div>
    </div>
  );
};

export default Calculator;
