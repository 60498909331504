import type { FunctionComponent } from 'react';
import { Layout } from '@kanda-libs/ks-design-library';
import Header from '../../components/Header';
import Content from '../Content';

const Mobile: FunctionComponent = function () {
  return (
    <Layout noBorder header={<Header />}>
      <Content />
    </Layout>
  );
};

export default Mobile;
