export const CLASS_NAMES = {
  container: 'flex flex-col px-3 py-4 rounded-xl bg-neutral-100 mb-8',
};

export const PAYMENT_OPTION = {
  icon: 'currency',
  title: 'Finance',
};

export const MANUAL_PAYMENT_OPTION = {
  icon: 'deposit',
  title: 'Payment on completion',
};

export const DECLINE_OPTION = {
  icon: 'delete-file',
  title: 'Decline job',
};
