/**
 * @typedef {Object} LineItem
 * @property {String} title - The line item title
 * @property {String} description - The line item description
 * @property {Number} quantity - The line item quantity (default in base 100, e.g. 100 - 1.00)
 * @property {Object} price - The line item price object
 * @property {Number} price.amount - The amount of the line item
 * @property {String} price.currency - The currency of the amount
 * @property {String} vat_rate - The string of the vat rate
 * @property {Object} vat - The line item vat object
 * @property {Number} vat.amount - The vat amount of the line item
 * @property {String} vat.currency - The currency of the vat amount
 */

import type { JobItem } from '@kanda-libs/ks-frontend-services';
import type { Totals } from 'types';

/**
 * @typedef {Object} Totals
 * @property {Number} totalExcVat - The total excluding VAT
 * @property {Number} totalVat - The total VAT
 * @property {Number} totalIncVat - The total including VAT
 */

const calculateVatPercentage = (vat: string) => {
  // if (vat === 'exempted') return 0;
  if (!vat.includes('%')) return 0;
  try {
    const vatPercentage = parseInt(vat, 10);
    return vatPercentage;
  } catch (e) {
    return 0;
  }
};

/**
 * Calculates item total from parts
 *
 * @param {Int} quantity Quantity for item, in units e.g. 100 = 1.00 (default)
 * @param {Int} price Price for item, in pence
 * @param {String} vat Vat for item
 */
export const calculateItemTotal = (
  quantity = 0,
  price = 0,
  vat = '',
  vatAmount = 0,
  quantityDecimal = 100,
): Totals => {
  // Get VAT percentage
  const vatPercentage = calculateVatPercentage(vat);
  // Calculate exc-VAT total
  const totalExcVat =
    // Quantity times price, over quantityDecimal
    Math.round((quantity * price) / quantityDecimal);

  if (vat === 'custom') {
    const roundedVat = Math.round(vatAmount);
    return {
      totalExcVat,
      totalVat: roundedVat,
      totalIncVat: totalExcVat + roundedVat,
    };
  }
  // returns if no VAT to add
  if (vatPercentage === null) {
    return {
      totalExcVat,
      totalVat: 0,
      totalIncVat: totalExcVat,
    };
  }

  // Calculate VAT total
  const totalVat = Math.round((totalExcVat * vatPercentage) / 100);
  // Calculate total (incl. VAT)
  const totalIncVat = Math.round(totalExcVat + totalVat);
  return {
    totalExcVat,
    totalVat,
    totalIncVat,
  };
};

/**
 * Calculates item total from parts
 *
 * @param {[LineItem]} lineItems Array of line items
 * @returns {Totals} totals object
 */
export const calculateJobTotal = (lineItems: JobItem[]): Totals => {
  if (!lineItems || lineItems?.length === 0)
    return {
      totalExcVat: 0,
      totalVat: 0,
      totalIncVat: 0,
    };
  return lineItems.reduce(
    (totals, item) => {
      const itemTotal = calculateItemTotal(
        item.quantity,
        item.price.amount,
        item.vat_rate,
        item.vat.amount,
      );
      return {
        totalExcVat: totals.totalExcVat + itemTotal.totalExcVat,
        totalVat: itemTotal.totalVat
          ? totals.totalVat + itemTotal.totalVat
          : totals.totalVat,
        totalIncVat: totals.totalIncVat + itemTotal.totalIncVat,
      };
    },
    {
      totalExcVat: 0,
      totalVat: 0,
      totalIncVat: 0,
    },
  );
};
