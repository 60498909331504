import { Price, Text } from '@kanda-libs/ks-design-library';

import Container from './JobTotal-container';
import { CLASS_NAMES, SKELETONS, LABELS } from './JobTotal-constants';

const ViewComponent = () => (
  <Container>
    {({ amount, vat, isLoading }) => (
      <div className={CLASS_NAMES.wrapper}>
        <div className={CLASS_NAMES.container}>
          <Text
            isLoading={isLoading}
            className={CLASS_NAMES.label}
            skeletonProps={SKELETONS.label}
            text={LABELS.vat}
          />
          <div className={CLASS_NAMES.priceWrapper}>
            <Price amount={vat} isLoading={isLoading} color="neutral-500" />
          </div>
        </div>
        <div className={CLASS_NAMES.container}>
          <Text
            isLoading={isLoading}
            className={CLASS_NAMES.label}
            skeletonProps={SKELETONS.label}
            text={LABELS.total}
          />
          <div className={CLASS_NAMES.priceWrapper}>
            <Price amount={amount} isLoading={isLoading} />
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobTotal-view';

export default ViewComponent;
