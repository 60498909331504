import { BreakPoints } from '@kanda-libs/ks-design-library';
import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import useVeriffIdentify from '../../../hooks/useVeriffIdentify';
import IdentifyForm, { type IdentityFormProps } from '../IdentifyForm';
import NotPresent from '../NotPresent';
import Success from '../Success';
import Verified from '../Verified';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Content = () => {
  const { isUserLoggedIn } = useCurrentUser();

  const identityProps = useVeriffIdentify();

  const {
    director,
    companyName,
    isLoading,
    submitted,
    verified,
    showForm,
    onShowForm,
    notPresentError,
  } = identityProps;

  if (notPresentError) {
    return <NotPresent error={notPresentError} />;
  }

  if (verified) {
    return <Verified />;
  }

  if (submitted) {
    return <Success />;
  }

  if (showForm) {
    return (
      <IdentifyForm {...(identityProps as unknown as IdentityFormProps)} />
    );
  }

  const childProps = {
    director,
    companyName,
    isLoading,
    isUserLoggedIn,
    onClickButton: onShowForm,
  };

  return (
    <>
      <BreakPoints
        mobile={<Mobile {...childProps} />}
        desktop={<Desktop {...childProps} />}
      />
      <div id="veriff" className="hidden" />
    </>
  );
};

export default Content;
