import { useCallback } from 'react';
import { useClasses } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from './Item-constants';

const ContainerComponent = ({
  children,
  handleSelect,
  customer,
  isLoading,
}) => {
  /**
    Handles select customer
  */
  const onSelect = useCallback(() => {
    handleSelect(customer);
  }, [handleSelect, customer]);

  const loadingClassName = !isLoading && '.cardNotLoading';

  const classNames = useClasses(CLASS_NAMES, {
    card: ['.cardBase', loadingClassName],
  });

  return children({
    classNames,
    onSelect,
  });
};

ContainerComponent.displayName = 'SearchCustomerModal-item-container';

export default ContainerComponent;
