import { useCallback, useMemo } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';

import { formatAddress } from './helpers';

const ContainerComponent = ({ children }) => {
  const { setValue } = useFormContext();

  const limitedCompanyInfo = useWatch({
    name: 'company.limited_company_info',
  });

  const [companyName, companyNumber, companyAddress] = useMemo(() => {
    if (!limitedCompanyInfo) return [null, null, null];
    return [
      limitedCompanyInfo?.company_name,
      limitedCompanyInfo?.company_number,
      limitedCompanyInfo?.company_address,
    ];
  }, [limitedCompanyInfo]);

  const showField = useMemo(() => {
    if (!limitedCompanyInfo) return true;
    return !(
      limitedCompanyInfo?.company_name && limitedCompanyInfo?.company_number
    );
  }, [limitedCompanyInfo]);

  const deselect = useCallback(() => {
    setValue('company.limited_company_info.company_number', '');
    setValue('company.limited_company_info.company_name', '');
    setValue('company.limited_company_info.company_address', {});
  }, [setValue]);

  const address = useMemo(() => {
    if (!companyAddress) return '';
    return formatAddress(
      companyAddress?.line_1,
      companyAddress?.line_2,
      companyAddress?.postcode,
      companyAddress?.city,
    );
  }, [companyAddress]);

  return children({
    showField,
    deselect,
    companyName,
    companyNumber,
    address,
  });
};

ContainerComponent.displayName = 'CompanyLogo-container';

export default ContainerComponent;
