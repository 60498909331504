import {
  actions,
  JobCreditState,
  type Job,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface CurrentJobHook {
  job?: Job;
  isLoading: boolean;
  checkJob?: JobCreditState;
  markDepositAsPaid: boolean;
  uploadQuotePdf: boolean;
  markAsFinished: boolean;
  markJobAsPaid: boolean;
  refetchJobFromId: () => void;
}

/**
 * Returns current job and loading state
 */
const useCurrentJob = (): CurrentJobHook => {
  const dispatch = useAppDispatch();

  const { id } = useSelector(selectors.getPathKey);
  const isLoading = useSelector(selectors.getIsLoading);

  const checkJob = useSelector(selectors.checkJob.getEntity);
  const jobs = useSelector(selectors.job.getEntitiesAsArray);
  const jobFromId = useSelector(selectors.job.getEntity);

  const refetchJobFromId = useCallback(() => {
    if (id) {
      dispatch(
        actions.getJob({
          params: { id },
          forceReload: true,
        }),
      );
    }
  }, [dispatch, id]);

  // Fetch stored job from cache
  const storedJob = useMemo(() => {
    if (!id || !jobs) return null;
    const filtered = jobs.filter((current) => current?.id === id);
    if (filtered.length !== 1) return null;
    return filtered[0];
  }, [id, jobs]);

  // Ensure fetched job is null until revalidated
  const fetchedJob = useMemo(() => {
    if (!id || !jobFromId || isLoading) return null;
    if (jobFromId?.id !== id) {
      refetchJobFromId();
      return null;
    }
    return jobFromId;
  }, [id, jobFromId, isLoading, refetchJobFromId]);

  const job = useMemo(() => {
    if (!storedJob && !fetchedJob) return null;
    if (!fetchedJob) return storedJob;
    return fetchedJob;
  }, [fetchedJob, storedJob]);

  // TODO: add in logic here
  const markDepositAsPaid = false;
  // TODO: add in logic here
  const uploadQuotePdf = false;
  // TODO: add in logic here
  const markAsFinished = false;
  // TODO: add in logic here
  const markJobAsPaid = false;

  return {
    job: job || undefined,
    isLoading,
    checkJob,
    markDepositAsPaid,
    uploadQuotePdf,
    markAsFinished,
    markJobAsPaid,
    refetchJobFromId,
  };
};

export default useCurrentJob;
