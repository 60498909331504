import { BUTTON_PROPS } from './AddCustomer-constants';

const ContainerComponent = ({ children, addCustomer }) => {
  const handleClick = () => {
    addCustomer();
  };

  const buttonProps = {
    ...BUTTON_PROPS,
    label: `Add new customer`,
    onClick: handleClick,
  };

  return children({
    buttonProps,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Customer-SearchResults-container';

export default ContainerComponent;
