export const ICON_PROPS = {
  stroke: 'neutral-600',
  icon: 'toolbox',
  size: 24,
  className: 'mr-4 my-auto',
};

export const HEADING = {
  text: 'Complete your information',
  className: 'text-left text-style-h-em text-neutral-700 md:text-style-g-em',
};

export const SUBHEADING = {
  text: 'Add your company details and logo',
  className: 'text-style-h text-neutral-600 md:text-style-g',
};

export const ARROW_PROPS = {
  mobile: {
    stroke: 'neutral-500',
    icon: 'chevron-right',
    size: 16,
    className: 'my-auto',
  },
  desktop: {
    stroke: 'neutral-500',
    icon: 'arrow-right',
    size: 20,
    className: 'my-auto',
  },
};

export const CLASS_NAMES = {
  skeletonWrapper: 'mt-4 mb-8 md:mt-0 md:mb-12',
  link: 'flex flex-row justify-between mt-4 mb-8 md:bg-neutral-50 md:p-6 md:mt-0 md:mb-12 md:border md:border-neutral-200 md:rounded-xl',
  container: 'flex flex-row',
  headings: 'flex flex-col',
};
