import type { FunctionComponent } from 'react';
import { Field, Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import useStaffImitationForm from './useStaffImitationForm';
import { INPUT_PROPS } from './constants';

const StaffImitationForm: FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useStaffImitationForm();
  return (
    <Form id="staff-imitattion" form={form} onSubmit={onSubmit}>
      To imitate a user, please enter their bearer token
      <Field.Input {...INPUT_PROPS} />
      <Button.Text id="staff-login" submit label="Login" />
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default StaffImitationForm;
