import { PAYMENT_METHODS } from '@kanda-utils/library';

export const FINANCE_OPTION_TITLE = 'Required Rates';

export const FINANCE_OPTION_UNREGULATED_TITLE = 'Optional Rates';

export const FINANCE_OPTION_DISCLAIMER =
  'Prices are indicative and are subject to change';

export const UNREGULATED_DESCRIPTION = `You can choose to offer these options to your customers on a job by job basis. ${FINANCE_OPTION_DISCLAIMER}`;

export const REGULATED_DESCRIPTION = `These options will be offered to every customer who is sent a job from your account. ${FINANCE_OPTION_DISCLAIMER}`;

export const FINANCE_OPTION_SUGGESTED = PAYMENT_METHODS.INTEREST_FREE_12;

export const SKELETONS = {
  title: {
    width: 100,
  },
  description: {
    width: 90,
  },
};

export const WARNING_MAXIMUM_PRICE = 50000;

export const PRICE_LIMITED = {
  INTEREST_BEARING_84_1190: 225000,
  INTEREST_BEARING_120_1190: 225000,
  INTEREST_BEARING_84_1390: 225000,
  INTEREST_BEARING_120_1390: 225000,
  INTEREST_BEARING_84_1590: 225000,
  INTEREST_BEARING_120_1590: 225000,
};
