import { Header, Button, Text } from '@kanda-libs/ks-design-library';
import { ModalLayoutFullScreen } from '@kanda-libs/ks-component-ts';

import SubscribeForm from '../../SubscribeForm';

interface ViewComponentProps {
  /**
   * handle close function
   */
  handleClose(...args: unknown[]): unknown;
}

const ViewComponent = ({ handleClose }: ViewComponentProps) => (
  <ModalLayoutFullScreen
    noBorder
    header={
      <Header.Base
        className="text-style-f-em text-neutral-700"
        options={[
          <Button.Icon
            id="subscribe-modal-close"
            key="close"
            onClick={handleClose}
            icon="close"
          />,
        ]}
      />
    }
  >
    <Text text="Upgrade your Kanda" className="mb-3 text-left text-24-28-em" />
    <Text
      text="Send unlimited jobs and win more bids"
      className="mb-10 text-left text-16-24 text-neutral-600"
    />
    <SubscribeForm />
  </ModalLayoutFullScreen>
);

ViewComponent.displayName = 'SubscribeModal-Desktop-view';

export default ViewComponent;
