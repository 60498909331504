import { Text } from '@kanda-libs/ks-design-library';
import { GoogleSignupButton } from 'components';
import type { FunctionComponent } from 'react';

export interface ContentProps {
  signInWithGoogle: () => void;
}

const Content: FunctionComponent<ContentProps> = function ({
  signInWithGoogle,
}) {
  return (
    <>
      <Text text="Staff Login" className="mb-4 text-style-b text-neutral-900" />
      <GoogleSignupButton signInWithGoogle={signInWithGoogle} />
    </>
  );
};

export default Content;
