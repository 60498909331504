import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany, useMe } from 'hooks';
import { useMemo } from 'react';

export interface NavMenuHeaderHook {
  isLoading: boolean;
}

export default function useNavMenuHeader(): NavMenuHeaderHook {
  const { isValidating: isUserLoggedInIsValidating } = useCurrentUser();
  const { isValidating: meIsValidating } = useMe();
  const { isLoading: companyIsLoading } = useCurrentCompany();

  const isLoading = useMemo(
    () => isUserLoggedInIsValidating || meIsValidating || companyIsLoading,
    [isUserLoggedInIsValidating, meIsValidating, companyIsLoading],
  );

  return { isLoading };
}
