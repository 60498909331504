import type { PaginationProps } from '@kanda-libs/ks-design-library';
import type { Referral } from '@kanda-libs/ks-frontend-services';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatReferrals, type EnhancedReferral } from './helpers';

interface ReferralsCardsHook {
  showCards: boolean;
  data: EnhancedReferral[];
  isLoading: boolean;
  isValidating: boolean;
  pageIndex: number;
  totalPages: number;
  setPage: (index: number) => void;
  paginationProps: PaginationProps;
}

export default function useReferralsCards(): ReferralsCardsHook {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [perPage] = useState<number>(10);

  const referrals = useSelector(selectors.getCompanyReferrals.getRawResponse);
  const hasFetched = useSelector(selectors.getCompanyReferrals.getHasFetched);
  const isSubmitting = useSelector(
    selectors.getCompanyReferrals.getIsSubmitting,
  );

  const data = useMemo(() => {
    if (!hasFetched) return [];
    const refs = referrals as Referral[];
    if (refs.length === 0) return [];
    return formatReferrals(refs).slice(
      pageIndex * perPage,
      pageIndex * perPage + perPage,
    );
  }, [referrals, hasFetched, pageIndex, perPage]);

  // Determine number of pages
  const totalPages = useMemo(() => {
    if (!hasFetched) return 1;
    return Math.ceil(data?.length / perPage);
  }, [data, perPage, hasFetched]);

  const setPage = useCallback((index: number) => setPageIndex(index), []);

  const showCards = useMemo(() => {
    if (!hasFetched) return true;
    if (hasFetched && data?.length === 0) return false;
    return true;
  }, [hasFetched, data]);

  const paginationProps = {
    pageCount: totalPages,
    pageIndex,
    setPage,
  } as PaginationProps;

  return {
    showCards,
    data,
    isLoading: !hasFetched,
    isValidating: isSubmitting,
    pageIndex,
    totalPages,
    setPage,
    paginationProps,
  };
}
