import { Button, Text } from '@kanda-libs/ks-design-library';

interface InvoiceSettingsPopoverProps {
  onClose: () => unknown;
}

const InvoiceSettingsPopover = ({ onClose }: InvoiceSettingsPopoverProps) => (
  <div className="relative mx-4 my-5 min-w-60">
    <div className="mb-2">
      <Text
        text="Job Builder Settings"
        className="text-12-18-em text-neutral-900"
      />
    </div>
    <Text
      text={`Use Kanda's quote builder or upload your own quotes (PDF, PNG, etc) to Kanda in order to attach finance options.`}
      className="text-12-18 text-neutral-600"
    />
    <Button.Icon
      id="invoice-settings-popover-close"
      icon="close"
      className="absolute cursor-pointer -top-4 -right-3 text-neutral-600"
      iconProps={{
        className: 'w-4',
      }}
      onClick={() => {
        onClose();
      }}
    />
  </div>
);

export default InvoiceSettingsPopover;
