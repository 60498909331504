import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useEffect, useState } from 'react';

export default function useDocumentContent(id) {
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(
        actions.getDocument({
          params: { id },
          forceReload: true,
          onSuccess: (data) => {
            setContent(data?.content as string);
          },
        }),
      );
    }
  }, [id, dispatch]);

  return content;
}
