import { Item, AddCustomer } from './components';
import { CLASS_NAMES, TITLE } from './SearchResults-constants';

interface ViewComponentProps {
  /**
   * Add edit customer
   */
  addCustomer(...args: unknown[]): unknown;
  /**
   * Select customer
   */
  handleSelect(...args: unknown[]): unknown;
  /**
   * Loading state
   */
  isLoading?: boolean;
  /**
   * Results array
   */
  results?: {
    refIndex?: number;
    item?: {
      id?: string;
      name?: string;
      address?: string;
      email?: string;
      phoneNumber?: string;
    };
  }[];
  /**
   * Search words array
   */
  searchWords?: string[];
}

const ViewComponent = ({
  results,
  addCustomer,
  ...itemProps
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <p className={CLASS_NAMES.title}>{TITLE}</p>
    {results?.length === 0 ? (
      <p className="text-16-20-em text-neutral-600 mb-6">No results!</p>
    ) : (
      (results || []).map((result) => (
        <Item
          key={String(result.refIndex)}
          customer={result.item}
          {...itemProps}
        />
      ))
    )}
    <AddCustomer addCustomer={addCustomer} />
  </div>
);

ViewComponent.displayName = 'SearchCustomerModal-view';

ViewComponent.defaultProps = {
  results: [],
  searchWords: [],
  isLoading: undefined,
};

export default ViewComponent;
