import Kanda from './Kanda';
import Partner from './Partner';
import SignUp from './SignUp';
import Success from './Success';

const Pages = {
  Kanda,
  Partner,
  SignUp,
  Success,
};

export default Pages;
