export const LOGIN = 'Already have an account?';

export const LOGIN_BUTTON = {
  id: 'sign-up-footer-login',
  label: 'Login',
};

export const CLASS_NAMES = {
  footer: 'flex flex-row justify-center mt-6 mb-4 md:mb-0',
  login: 'text-style-g text-neutral-700 mr-2 my-auto',
};
