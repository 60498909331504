const ViewComponent = () => (
  <div className="py-6 flex flex-row">
    <span className="text-14-22 text-neutral-600">
      <span className="mr-1 text-14-22-em text-green-400">
        Click to upload file
      </span>
    </span>
  </div>
);

ViewComponent.displayName = 'EPVSCalculations-Placeholder-view';

export default ViewComponent;
