import {
  Button,
  Icon,
  SkeletonLoader,
  Text,
} from '@kanda-libs/ks-design-library';
import type { Contract } from 'pages/Setup/Subscription/context';
import useActionBar from './useActionBar';

interface ActionBarProps {
  contract: Contract | undefined;
}

const ActionBar: React.FunctionComponent<ActionBarProps> = function ({
  contract,
}) {
  const { onDownloadClick } = useActionBar(contract);
  return (
    <SkeletonLoader
      height={100}
      isLoading={!contract}
      afterLoading={
        <div className="flex flex-col justify-between px-3 py-2 md:px-4 md:py-5 border border-neutral-300 rounded bg-neutral-100">
          <div className="flex flex-row mr-4">
            <div className="flex my-auto w-6 h-6 min-w-6 min-h-6 bg-lavender-100 rounded-full mr-3">
              <Icon
                icon="check"
                size={16}
                className="m-auto text-lavender-300"
                stroke="lavender-300"
              />
            </div>
            <Text
              text="We strongly suggest that you save the contract for your own records"
              className="text-12-18-em text-lavender-200 w-full my-auto"
              skeletonProps={{
                width: '100%',
                count: 2,
              }}
            />
          </div>
          <div className="flex flex-row">
            <Button.Link
              id="sign-subscription-contract-download"
              label="Download"
              icon="download"
              left
              variant="grey"
              className="mx-auto mt-3 md:mt-0"
              size={14}
              onClick={onDownloadClick}
            />
          </div>
        </div>
      }
    />
  );
};

export default ActionBar;
