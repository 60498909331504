import { CLASS_NAMES } from './PaymentAndFinanceOptions-constants';
import { FinanceOptions, Deposit } from './components';

const ViewComponent = ({}) => (
  <div className={CLASS_NAMES.container}>
    <FinanceOptions />
    <Deposit />
  </div>
);

ViewComponent.displayName = 'Job-Desktop-PaymentAndFinanceOptions-view';

export default ViewComponent;
