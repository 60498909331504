import type { Job } from '@kanda-libs/ks-frontend-services';

export const SORTING = {
  UPDATED_AT: 'UPDATED_AT',
  CUSTOMER: 'CUSTOMER',
  PRICE: 'PRICE',
};

export const ORDER = {
  NORMAL: 'NORMAL',
  INVERSE: 'INVERSE',
};

export const FILTER_TYPES = {
  NONE: 'NONE',
  STATUS: 'STATUS',
};

export const SEARCH_OPTIONS = {
  keys: [
    { name: 'customerDetails.firstName', weight: 1 },
    { name: 'customerDetails.lastName', weight: 1 },
    { name: 'customerDetails.email', weight: 1 },
    { name: 'customerDetails.phone', weight: 1 },
    { name: 'customerDetails.postCode', weight: 1 },
    { name: 'customerDetails.addressLine1', weight: 1 },
    { name: 'customerDetails.addressLine2', weight: 1 },
    { name: 'customerDetails.city', weight: 1 },
    { name: 'financeStatus', weight: 1 },
    { name: 'price', weight: 1 },
    { name: 'title', weight: 1 },
    { name: 'description', weight: 1 },
    { name: 'status', weight: 1 },
    { name: 'id', weight: 1 },
  ],
  includeMatches: true,
  matchAllOnEmptyQuery: true,
  minMatchCharLength: 3,
  threshold: 0.2,
};

export const PLACEHOLDER_JOBS: Job[] = [
  {
    id: '1',
    title: 'Example Job: Bathroom and living room electrics',
    deposit_type: 'no_deposit',
    deposit_value: { amount: 0, currency: 'GBP' },
    metadata: {
      created_at: new Date(),
      updated_at: new Date(),
      liveness: true,
    },
    job_items: [],
    archived: 'no',
    total: {
      amount_vat: {
        amount: 100000,
      },
      amount_vat_inclusive: {
        amount: 100000,
      },
      amount_vat_exclusive: {
        amount: 100000,
      },
    },
    customer: {
      first_name: 'John',
      last_name: 'Doe',
      email: 'andy@kanda.co.uk',
      phone: '0123456789',
    },
  },
];
