import type { MutableRefObject } from 'react';
import useOptionHover from '../useOptionHover';
import type { PopoverDesktopProps } from './Desktop-view';

export interface ContainerComponentChildrenArgs {
  visible: boolean;
  elementRef: MutableRefObject<HTMLDivElement | undefined>;
  Content: JSX.Element;
  id: string;
}

export interface ContainerComponentProps extends PopoverDesktopProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  content,
  id,
}: ContainerComponentProps) => {
  const { visible, elementRef } = useOptionHover();

  return children({
    visible,
    elementRef,
    Content: content,
    id,
  });
};

export default ContainerComponent;
