import { formatToCurrency } from '@kanda-utils/library';
import { useContext, useCallback } from 'react';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children }) => {
  const { filter, updateFilter } = useContext(Context);

  const [from, to] = filter?.price?.split(';') || [];

  const onClick = useCallback(() => updateFilter('price', ''), [updateFilter]);

  const label =
    from && to
      ? `${formatToCurrency(from.split(':')[1])} ... ${formatToCurrency(
          to.split(':')[1],
        )}`
      : `> ${formatToCurrency(from.split(':')[1])}`;

  return children({ label, onClick });
};

ContainerComponent.displayName = 'PriceFilter-container';

export default ContainerComponent;
