import type { FunctionComponent } from 'react';

import Calculator from './Calculator';
import MarketingMaterials from './MarketingMaterials';
import SocialMedia from './SocialMedia';
import type { AdvertisingPage } from './types';

export const PAGES: Record<AdvertisingPage, FunctionComponent> = {
  'social-media': SocialMedia,
  calculator: Calculator,
  'marketing-materials': MarketingMaterials,
};

export const PAGE_KEYS = Object.keys(PAGES) as string[];
