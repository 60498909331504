import { SkeletonLoader } from '@kanda-libs/ks-design-library';

import FacebookLogo from '../../assets/f-logo.png';

interface ViewComponentProps {
  /**
   * Disabled state for social buttons
   */
  socialDisabled?: boolean;
  /**
   * Function to sign in with Facebook
   */
  signInWithFb?(...args: unknown[]): unknown;
  /**
   * is loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({
  isLoading,
  socialDisabled,
  signInWithFb,
}: ViewComponentProps) => (
  <SkeletonLoader
    isLoading={isLoading}
    width="100%"
    height={44}
    wrapperClassName="w-full md:ml-4"
    afterLoading={
      <button
        className="relative flex flex-row w-full h-12 p-3 rounded social-shadow bg-fb-blue rounded-3xl"
        type="button"
        id="facebook_sign_in"
        disabled={socialDisabled}
        onClick={signInWithFb}
      >
        <img
          src={FacebookLogo}
          alt="f-logo"
          className="absolute w-6 h-6 left-2 top-1/2 transform -translate-y-1/2"
        />
        <p className="w-full my-auto text-center text-14-22-em text-neutral-000 whitespace-nowrap">
          Sign in with Facebook
        </p>
      </button>
    }
  />
);

ViewComponent.defaultProps = {
  isLoading: false,
  socialDisabled: undefined,
  signInWithFb: undefined,
};

ViewComponent.displayName = 'Login-Facebook-view';

export default ViewComponent;
