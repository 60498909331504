import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import Desktop from './Desktop';
import Mobile from './Mobile';

interface OptionPopoverProps {
  financeOption: FinanceRate;
  totalCost: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  interestLabel: string;
  durationLabel: string;
  rateWithinBounds: boolean;
  depositIsAboveMinimum?: boolean;
}

const OptionPopover = ({ ...props }: OptionPopoverProps) => (
  <BreakPoints
    desktop={<Desktop {...props} />}
    mobile={<Mobile {...props} />}
  />
);

export default OptionPopover;
