import { Button } from '@kanda-libs/ks-design-library';

import { LOGIN_BUTTON, CLASS_NAMES } from './Footer-constants';

interface ViewComponentProps {
  /**
   * Disabled state for social buttons
   */
  disabled?: boolean;
  /**
   * Function to sign in with Google
   */
  display?: string;
}

const ViewComponent = ({ display, disabled }: ViewComponentProps) =>
  display !== 'expired' ? (
    <div className={CLASS_NAMES.container}>
      <Button.Text {...LOGIN_BUTTON} disabled={disabled} />
    </div>
  ) : (
    <></>
  );

ViewComponent.displayName = 'Login-Footer-view';

export default ViewComponent;
