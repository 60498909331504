import { Form } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Layout } from '../components';
import SetupAccountCompanyForm from './CompanyForm';
import useSetupAccountForm from './useSetupAccountForm';

const SetupAccount: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting, ...rest } = useSetupAccountForm();
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      id="setup-account"
      className="flex flex-col"
    >
      <Layout
        mobileFooter={
          <Button.Text
            id="setup-account-submit"
            submit
            className="w-full"
            label="Next"
            icon="arrow-right"
            variant="gradient"
            disabled={isSubmitting}
          />
        }
      >
        <Helmet title="Setup Account" />
        <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
          Create your Kanda account
        </p>
        <SetupAccountCompanyForm isSubmitting={isSubmitting} {...rest} />
      </Layout>
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default SetupAccount;
