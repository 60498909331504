import { Banner, Button, Text } from '@kanda-libs/ks-design-library';

import Container from './KandaQuoteBanner-container';
import { PROPS, CLASS_NAMES } from './KandaQuoteBanner-constants';

const ViewComponent = ({}) => (
  <Container>
    {({ showBanner, handleChange }) =>
      showBanner && (
        <div className={CLASS_NAMES.container}>
          <Banner>
            <div className={CLASS_NAMES.content}>
              <div className={CLASS_NAMES.header}>
                <Text {...PROPS.title} />
              </div>
              <div>
                <Button.Text
                  {...PROPS.changeQuoteButton}
                  onClick={handleChange}
                />
              </div>
            </div>
          </Banner>
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'CreateJob-KandaQuoteBanner-view';

export default ViewComponent;
