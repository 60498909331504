import { Field } from '@kanda-libs/ks-component-ts';
import { NAME_VALDIATION } from './constants';

export interface ReferralsInviteFormFieldNameProps {
  index: number;
}

const ReferralsInviteFormFieldName: React.FunctionComponent<ReferralsInviteFormFieldNameProps> =
  function ({ index }) {
    return (
      <Field.Array.Input name="name" index={index}>
        <Field.Validator validation={NAME_VALDIATION}>
          <Field.Input label="Name" placeholder="Full name" />
        </Field.Validator>
      </Field.Array.Input>
    );
  };

export default ReferralsInviteFormFieldName;
