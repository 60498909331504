import { BreakPoints, Text } from '@kanda-libs/ks-design-library';
import { Widget, FormTheme } from '@kanda-libs/ks-component-ts';

import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import Container from './Title-container';
import { TITLE_FIELD_PROPS, FORM_THEME } from './Title-constants';

const ViewComponent = ({}) => {
  const isLeadJob = useIsLeadJob();
  const {
    data: { job },
  } = useCreateJobContextValue();

  const description = isLeadJob ? (
    <Text text={`Customer job description: ${job?.description}`} />
  ) : (
    <></>
  );

  return (
    <Container>
      {({ onBlur }) => (
        <BreakPoints
          mobile={
            <FormTheme variant={FORM_THEME.mobile}>
              <>
                <Widget.JobTitle
                  {...TITLE_FIELD_PROPS.mobile}
                  onBlur={onBlur}
                />
                {description}
              </>
            </FormTheme>
          }
          desktop={
            <FormTheme variant={FORM_THEME.desktop}>
              <>
                <Widget.JobTitle
                  {...TITLE_FIELD_PROPS.desktop}
                  onBlur={onBlur}
                />
                {description}
              </>
            </FormTheme>
          }
        />
      )}
    </Container>
  );
};

ViewComponent.displayName = 'Title-view';

export default ViewComponent;
