import KandaQuoteBanner from './KandaQuoteBanner';
import Title from './Title';
import Quote from './Quote';
import EPVSCalculations from './EPVSCalculations';
import ExampleJobAlertBanner from './ExampleJobAlertBanner';
import LeadBanner from './LeadBanner';

export default {
  KandaQuoteBanner,
  Title,
  Quote,
  EPVSCalculations,
  ExampleJobAlertBanner,
  LeadBanner,
};
