import {
  Button,
  BreakPoints,
  Text,
  Loader,
} from '@kanda-libs/ks-design-library';
import { RowFields, Widget } from '@kanda-libs/ks-component-ts';

import { AVERGAE_JOB_VALUE_PROPS } from './Form-constants';

interface ViewComponentProps {
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const ViewComponent = ({ isLoading, isSubmitting }: ViewComponentProps) => (
  <>
    <Text
      text="Business & Insurance"
      className="text-14-22–em text-neutral-800 font-bold mb-2"
    />
    <Widget.CompanyCompanyInfoWarrantyLength
      label="Warranty length"
      placeholder="Warranty length (months)"
    />
    <BreakPoints
      mobile={
        <>
          <Widget.CompanyCompanyInfoAverageJobValue
            {...AVERGAE_JOB_VALUE_PROPS}
          />
          <Widget.CompanyCompanyInfoAverageMonthlyJobs />
        </>
      }
      desktop={
        <RowFields>
          <Widget.CompanyCompanyInfoAverageJobValue
            {...AVERGAE_JOB_VALUE_PROPS}
          />
          <Widget.CompanyCompanyInfoAverageMonthlyJobs />
        </RowFields>
      }
    />
    <Widget.CompanyCompanyInfoUseSubcontractor
      label="Do you use subcontractors?"
      placeholder="Select..."
    />
    <div className="mt-auto md:mt-0">
      <Button.Text
        id="onboarding-job-info-submit"
        label="Next"
        submit
        isLoading={isLoading}
        disabled={isSubmitting}
        className="w-full mt-6"
      />
    </div>
    <Loader isLoading={isSubmitting} />
  </>
);

ViewComponent.defaultProps = {
  isLoading: false,
};

ViewComponent.displayName = 'JobInfoForm-view';

export default ViewComponent;
