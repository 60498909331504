import { Button } from '@kanda-libs/ks-design-library';
import { INITIAL_DATA } from './constants';

interface ReferralsInviteFormButtonsProps {
  plural: boolean;
  append: (value?: any) => void;
  isSubmitting: boolean;
}

const ReferralsInviteFormButtons: React.FunctionComponent<ReferralsInviteFormButtonsProps> =
  function ({ plural, append, isSubmitting }) {
    return (
      <div className="flex flex-row justify-between mt-5">
        <Button.Link
          id="referrals-add-member"
          label="Add invite"
          icon="plus"
          variant="light-grey"
          onClick={() => append(INITIAL_DATA)}
          disabled={isSubmitting}
        />
        <Button.Text
          submit
          left
          size={40}
          id="referrals-send-invite-button"
          icon="send"
          label={plural ? 'Send invites' : 'Send invite'}
          disabled={isSubmitting}
        />
      </div>
    );
  };

export default ReferralsInviteFormButtons;
