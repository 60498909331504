import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export type JobNotesHook = string;

export default function useJobNotes(): JobNotesHook {
  const {
    data: { job },
  } = useCreateJobContextValue();
  const formNotes = useWatch({ name: 'job.notes' });
  const jobNotes = job?.notes || undefined;

  const notes = useMemo(() => {
    if (!formNotes && !jobNotes) return '';
    if (!formNotes || !formNotes?.length) {
      if (!jobNotes || !jobNotes?.length) return '';
      return jobNotes[0];
    }
    return formNotes[0];
  }, [formNotes, jobNotes]);

  return notes;
}
