import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from '../../../../../config';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <DesktopLayoutBoxed
    options={[
      <Link key="close" to={URLS.accountSettings}>
        <Button.Icon id="account-settings-close" icon="close" />
      </Link>,
    ]}
  >
    <DesktopLayoutBoxedContent>{children}</DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'SubPageDesktopLayout-view';

ViewComponent.defaultProps = {
  children: null,
};

export default ViewComponent;
