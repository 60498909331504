import type { ButtonInlineLinkVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  container: 'flex flex-row flex-1 mt-20',
  side: 'flex flex-col w-64 mr-20',
  title: 'text-16-20-em text-neutral-900 mb-6',
  content: 'flex flex-col flex-1 -mb-4',
  card: 'radius-xl border border-neutral-300 flex flex-col rounded-xl shadow-card mb-4',
  panel: 'flex flex-row p-4 border-b border-neutral-300 last:border-0',
};

export const TITLE = 'Finance Options Chosen';

export const EDIT_BUTTON_PROPS = {
  id: 'create-job-edit-payment',
  label: 'Edit',
  className: 'ml-4',
  variant: 'turquoise' as ButtonInlineLinkVariant,
};
