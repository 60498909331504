import { CLASSNAME, ARCHIVE, TITLE } from './Title-constants';

interface ViewComponentProps {
  /**
   * Title
   */
  archive?: boolean;
}

const ViewComponent = ({ archive }: ViewComponentProps) => (
  <p className={CLASSNAME}>{archive ? ARCHIVE : TITLE}</p>
);

ViewComponent.displayName = 'Banner-Title-view';

ViewComponent.defaultProps = {
  archive: false,
};

export default ViewComponent;
