import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

import { VARIANTS, VAT_OPTIONS } from '../../LineItem-constants';
import { calculateItemTotal } from '../../../../utils';
import type { ViewComponentProps } from './Provider-view';

export interface ContainerComponentChildrenArgs {
  isLoading?: boolean;
  classNames?: StringIndexedObject;
  title?: string;
  description?: string;
  quantity?: string;
  price?: number;
  vat?: string;
  total?: number;
  style?: string;
  quoteDocument?: string;
  variant?: string;
}

export interface ContainerComponentProps
  extends Omit<ViewComponentProps, 'children'> {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  variant?: string;
}

const ContainerComponent = ({
  children,
  item,
  isLoading,
  variant,
}: ContainerComponentProps) => {
  // Get line item totals
  const { totalExcVat: subTotal, totalIncVat: total } = (item &&
    item?.quantity &&
    calculateItemTotal(
      item?.quantity,
      item?.price?.amount,
      item?.vat_rate,
    )) || {
    subTotal: 0,
    total: 0,
  };

  // Extract the correct VAT label from the value
  const vat =
    item &&
    VAT_OPTIONS.reduce(
      (labels, option) => ({ ...labels, [option.value]: option.name }),
      {},
    )[item?.vat_rate as string];

  // Structure all values into an object
  const values = {
    title: item?.title || 'No line item title given...',
    description: item?.description,
    quantity: `${(item?.quantity as number) / 100} x`,
    price: subTotal,
    vat,
    total,
    style: item?.style || 'kanda',
    ...(item?.quote_document ? { quoteDocument: item.quote_document } : {}),
  };

  const classNames = VARIANTS[variant as string];

  return children({
    isLoading,
    classNames,
    ...values,
    variant: variant as string,
  });
};

ContainerComponent.displayName = 'LineItem-Context-Provider-container';

export default ContainerComponent;
