import { Icon } from '@kanda-libs/ks-design-library';
import { Widget, RowFields } from '@kanda-libs/ks-component-ts';

import Container from './Name-container';

const ViewComponent = () => (
  <Container>
    {({ onBlur }) => (
      <div className="flex flex-row mb-5">
        <Icon
          icon="user"
          size={16}
          stroke="neutral-600"
          className="my-auto mr-3"
        />
        <RowFields>
          <Widget.JobCustomerFirstName
            placeholder="add first name"
            onBlur={() => onBlur('job.customer.first_name')}
          />
          <Widget.JobCustomerLastName
            placeholder="add last name"
            onBlur={() => onBlur('job.customer.last_name')}
          />
        </RowFields>
      </div>
    )}
  </Container>
);
ViewComponent.displayName = 'CreateJob-Desktop-Customer-Field-Name-view';

export default ViewComponent;
