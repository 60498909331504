import { Link } from 'react-router-dom';
import { Banner, Icon, Button } from '@kanda-libs/ks-design-library';
import {
  CLASS_NAMES,
  ICON_PROPS,
  BUTTON_LARGE_PROPS,
  BUTTON_SMALL_PROPS,
} from './AccountBadge-constants';

interface ViewComponentProps {
  title: string;
  subTitle: string;
  link?: string;
  buttonLabel: string;
  buttonPrepend?: JSX.Element;
  buttonAppend?: JSX.Element;
  buttonIcon?: string;
  showIcon?: boolean;
  showTitleAboveSubtitle?: boolean;
  onClickButton?(...args: unknown[]): unknown;
  disabled?: boolean;
}

const ViewComponent = ({
  title,
  subTitle,
  link,
  buttonLabel,
  buttonPrepend,
  buttonAppend,
  buttonIcon,
  showIcon,
  showTitleAboveSubtitle,
  onClickButton,
  disabled = false,
}: ViewComponentProps) => {
  const buttons = (
    <>
      <Button.Text
        id="account-badge-button-large"
        {...BUTTON_LARGE_PROPS}
        label={buttonLabel}
        prepend={buttonPrepend}
        append={buttonAppend}
        icon={buttonIcon}
        onClick={onClickButton}
        disabled={disabled}
      />
      <Button.Text
        id="account-badge-button-small"
        {...BUTTON_SMALL_PROPS}
        label={buttonLabel}
        prepend={buttonPrepend}
        append={buttonAppend}
        icon={buttonIcon}
        onClick={onClickButton}
        disabled={disabled}
      />
    </>
  );

  return (
    <Banner>
      <div className={CLASS_NAMES.container}>
        {showIcon && <Icon {...ICON_PROPS} />}
        <div
          className={
            showTitleAboveSubtitle
              ? CLASS_NAMES.textWrapper
              : CLASS_NAMES.textWrapperReversed
          }
        >
          <span className={CLASS_NAMES.title}>{title}</span>
          <span className={CLASS_NAMES.subTitle}>{subTitle}</span>
        </div>
        {!link && <div className={CLASS_NAMES.link}>{buttons}</div>}
        {link && (
          <Link to={link} className={CLASS_NAMES.link}>
            {buttons}
          </Link>
        )}
      </div>
    </Banner>
  );
};

ViewComponent.displayName = 'CompleteAccountBadge-view';

ViewComponent.defaultProps = {
  buttonPrepend: null,
  buttonAppend: null,
  buttonIcon: 'arrow-right',
  showIcon: true,
  showTitleAboveSubtitle: true,
  onClickButton: () => {},
};

export default ViewComponent;
