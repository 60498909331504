import { Text, Icon } from '@kanda-libs/ks-design-library';

import { PERMISSIONS } from './Permissions-constants';

interface ViewComponentProps {
  /**
   *
   */
  permissions?: boolean[];
}

const ViewComponent = ({ permissions = [] }: ViewComponentProps) => (
  <div className="flex flex-col md:px-4 md:py-5 w-full md:w-64 min-w-64">
    <Text text="Permissions" className="hidden md:block text-12-18-em mb-3" />
    {PERMISSIONS.map((permission, index) => (
      <div key={permission} className="flex flex-row justify-between">
        <Text text={permission} className="text-12-18 text-neutral-600" />
        <Icon
          icon={permissions[index] ? 'check' : 'close'}
          stroke={permissions[index] ? 'green-500' : 'red-200'}
        />
      </div>
    ))}
  </div>
);

ViewComponent.displayName = 'New-Role-Popover-Permissions-view';

ViewComponent.defaultProps = {
  permissions: [false, false, false, false, false],
};

export default ViewComponent;
