import { Icon } from '@kanda-libs/ks-design-library';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';

import { CallButton, SMSButton } from '../components';
import { ICON_PROPS, CLASS_NAMES, TEXT } from '../Confirmation-constants';

interface ViewComponentProps {
  /**
   * Customer's name
   */
  name?: string;
  /**
   * Customer's email
   */
  email?: string;
}

const ViewComponent = ({ name, email }: ViewComponentProps) => {
  const isExampleJob = useIsExampleJob();

  return (
    <div className={CLASS_NAMES.container}>
      <Icon {...ICON_PROPS} />
      <p className={CLASS_NAMES.heading}>
        {isExampleJob ? TEXT.headingExample : TEXT.heading}
      </p>
      <div className={CLASS_NAMES.subHeading}>
        <span>{isExampleJob ? TEXT.customerExample : TEXT.customer}</span>
        {!isExampleJob && (
          <span className={CLASS_NAMES.boldSubHeading}>&nbsp;{name}</span>
        )}
      </div>
      {!isExampleJob && <p className={CLASS_NAMES.email}>({email})</p>}
      {!isExampleJob && (
        <div className={CLASS_NAMES.buttonContainer}>
          <CallButton />
          <SMSButton />
        </div>
      )}
    </div>
  );
};

ViewComponent.defaultProps = {
  name: undefined,
  email: undefined,
};

ViewComponent.displayName =
  'CreateJob-SendConfirmation-Confirmation-Mobile-view';

export default ViewComponent;
