import type { FunctionComponent } from 'react';
import SplashScreen from '../../../components/SplashScreen';

interface NotPresentProps {
  error?: string;
}

const NotPresent: FunctionComponent<NotPresentProps> = function ({ error }) {
  return (
    <div className="md:px-16">
      <SplashScreen
        icon="error"
        iconStroke="orange-200"
        title="There is an error with getting you verified"
        subText="We've hit the following error with the link you followed:"
      />
      {error && (
        <p className="p-2 mt-4 text-red-200 bg-red-100 border border-red-200 rounded-lg">
          {error}
        </p>
      )}
    </div>
  );
};

export default NotPresent;
