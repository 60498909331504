import { CLASS_NAMES } from './FormattedCustomer-constants';

interface ViewComponentProps {
  /**
   * Children
   */
  customer: {
    name: string;
    address: string;
    email: string;
    phoneNumber: string;
  };
}

const ViewComponent = ({ customer }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <p className={CLASS_NAMES.name}>{customer.name}</p>
    <p className={CLASS_NAMES.address}>{customer.address}</p>
    <div className={CLASS_NAMES.contactWrapper}>
      <p className={CLASS_NAMES.email}>{customer.email}</p>
      <p className={CLASS_NAMES.spacer}>•</p>
      <p className={CLASS_NAMES.phoneNumber}>{customer.phoneNumber}</p>
    </div>
  </div>
);

ViewComponent.displayName = 'JobDetails-FormattedCustomer-view';

export default ViewComponent;
