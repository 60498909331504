import { useWatch } from '@kanda-libs/ks-component-ts';
import type { JobItem, WorkType } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import { calculateJobTotal } from 'utils';

export interface NoFinanceOptionsWarningHook {
  label: string;
}

export default function useNoFinanceOptionsWarning(): NoFinanceOptionsWarningHook {
  const [jobItems, workType]: [JobItem[], WorkType] = useWatch({
    name: ['job.job_items', 'job.work_type'],
  });

  const { totalIncVat: total } = calculateJobTotal(jobItems || []);

  const label = useMemo(() => {
    if (workType === 'other')
      return "There are no finance options available for your job based on the selected work type of 'other'.";
    const tooLow = total < 25000;
    return `There are no finance options available for your job based on the job total and deposit provided.${
      tooLow ? ' Please enter a job total of at least £250' : ''
    }`;
  }, [total, workType]);

  return {
    label,
  };
}
