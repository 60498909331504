import { Button, Loader, Text } from '@kanda-libs/ks-design-library';
import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './Form-container';
import { INSURANCE_DOCUMENT_PROPS } from './Form-constants';

interface ViewComponentProps {
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const ViewComponent = ({ isLoading, isSubmitting }: ViewComponentProps) => (
  <Container>
    {({ inputFile, fileError, showExpiry, validation }) => (
      <>
        <Text
          text="Business & Insurance"
          className="text-14-22–em text-neutral-800 font-bold mb-2"
        />
        <Widget.CompanyCompanyInfoInsuranceDocumentContent
          {...INSURANCE_DOCUMENT_PROPS}
          error={fileError}
          inputFile={inputFile}
        />
        {showExpiry && (
          <Widget.CompanyCompanyInfoInsuranceDocumentExpiry
            validation={validation}
          />
        )}
        <Button.Text
          submit
          id="onboarding-insurance-doc-submit"
          label="Submit document"
          icon="arrow-right"
          className="w-full"
          isLoading={isLoading}
          disabled={isSubmitting}
        />
        <Loader isLoading={isSubmitting} />
      </>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  isSubmitting: false,
};

ViewComponent.displayName = 'InsuranceInfoForm-view';

export default ViewComponent;
