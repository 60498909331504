import { createContext } from 'react';

export interface QuoteContextValue {
  focus?: number;
  setFocus: (numberOfItems: number) => void;
}

const Context = createContext<QuoteContextValue>({
  setFocus: () => {
    //  Empty
  },
});

Context.displayName = 'Createjob-Quote-Kanda-Context';

export default Context;
