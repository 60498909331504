import { useAtlasChatWidget } from '@kanda-libs/ks-design-library';
import useActiveSubscription from '../../../../../../../hooks/useActiveSubscription';
import { getPriceWithoutVat } from './helpers';

const ContainerComponent = ({ children }) => {
  const { activeSubscription, billing } = useActiveSubscription();
  const { openChat } = useAtlasChatWidget();

  const priceWithoutVat = getPriceWithoutVat(
    activeSubscription?.amount?.amount || 0,
  );

  const labelSuffix =
    activeSubscription?.interval === 'yearly'
      ? '+ VAT / year'
      : '+ VAT / month';

  const label = [`£${priceWithoutVat / 100}`, labelSuffix].join(' ');

  return children({
    openChat,
    legacy: billing === 'legacy',
    price: label,
  });
};

ContainerComponent.displayName = 'Subscription-Content-Card-container';

export default ContainerComponent;
