import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

export const COMING_SOON_1190_RATES: FinanceRate[] = [
  {
    apr: 1190,
    apr_type: 'INTEREST_BEARING',
    charge: 0,
    duration: 12,
    enabled: false,
    fee: 0,
    min_deposit_pct: 0,
    max_deposit_pct: 5000,
    min_job_value: 25000,
    max_job_value: 2500000,
    finance_types: ['primary'],
    name: 'INTEREST_BEARING_12_1190',
    provider: 'etika',
    work_types: [],
  },
  {
    apr: 1190,
    apr_type: 'INTEREST_BEARING',
    charge: 0,
    duration: 60,
    enabled: false,
    fee: 0,
    min_deposit_pct: 0,
    max_deposit_pct: 5000,
    min_job_value: 25000,
    max_job_value: 2500000,
    finance_types: ['primary'],
    name: 'INTEREST_BEARING_60_1190',
    provider: 'etika',
    work_types: [],
  },
];

export const COMING_SOON_1390_RATES: FinanceRate[] = [
  {
    apr: 1390,
    apr_type: 'INTEREST_BEARING',
    charge: 0,
    duration: 60,
    enabled: false,
    fee: 0,
    min_deposit_pct: 0,
    max_deposit_pct: 5000,
    min_job_value: 25000,
    max_job_value: 2500000,
    finance_types: ['primary'],
    name: 'INTEREST_BEARING_60_1390',
    provider: 'allium',
    work_types: [],
  },
  {
    apr: 1390,
    apr_type: 'INTEREST_BEARING',
    charge: 0,
    duration: 120,
    enabled: false,
    fee: 0,
    min_deposit_pct: 0,
    max_deposit_pct: 5000,
    min_job_value: 25000,
    max_job_value: 2500000,
    finance_types: ['primary'],
    name: 'INTEREST_BEARING_120_1390',
    provider: 'allium',
    work_types: [],
  },
];
