import useCurrentJob from '../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { job } = useCurrentJob();

  const note = job?.notes?.[0] || null;

  return children({
    showNotes: !!note,
    note,
  });
};

ContainerComponent.displayName = 'Job-Notes-container';

export default ContainerComponent;
