import { PopoverMenu } from '@kanda-libs/ks-design-library';

import Container from './UserMenu-container';

interface ViewComponentProps {
  /**
   * Job id
   */
  index: number;
  /**
   * Disabled
   */
  handleClose?(...args: unknown[]): unknown;
  /**
   * Disabled
   */
  setUserToDelete(...args: unknown[]): unknown;
  /**
   * director
   */
  director?: boolean;
}

const ViewComponent = ({
  index,
  handleClose,
  setUserToDelete,
  director,
}: ViewComponentProps) => (
  <Container
    index={index}
    setUserToDelete={setUserToDelete}
    director={director}
  >
    {({ show, items }) =>
      show && (
        <PopoverMenu
          className="-mx-6 md:m-0 md:w-40"
          items={items}
          onClose={handleClose}
        />
      )
    }
  </Container>
);

ViewComponent.defaultProps = {
  handleClose: undefined,
};

ViewComponent.displayName = 'UserMenu-view';

export default ViewComponent;
