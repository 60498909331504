import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Button, Text } from '@kanda-libs/ks-design-library';

import { URLS } from 'config';
import socialMediaAdvertsImage from '../../../assets/social-media-adverts.png';

const Start: FunctionComponent = function () {
  return (
    <div className="w-full grid md:grid-cols-2 gap-x-8">
      <div className="flex flex-row items-center justify-center ">
        <img
          src={socialMediaAdvertsImage}
          alt="Update your website"
          className="md:max-w-[400px] w-auto"
        />
      </div>
      <div className="flex flex-col justify-center text-left md:w-72">
        <Text
          text="Update your website"
          className="mt-4 mb-6 text-24-28-em md:mt-0"
        />
        <Text
          text="Invite your website developer to your team and we’ll tell them how to update your website so it’s compliant with FCA regulations."
          className="mb-6 text-neutral-600 text-16-24"
        />
        <div>
          <Link to={URLS.updateYourWebsite.replace(':id', 'legal')}>
            <Button.Text
              id="dashboard-example-job"
              label="See more"
              icon="arrow-right"
              size={40}
              className="mb-2"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Start;
