import type { Company, InfoMe } from '@kanda-libs/ks-frontend-services';
import { cleanObject, parsePhoneNumber } from 'utils';
import type { MeFieldValues } from './useSetupAccountForm';

export const formatMeBody = (fieldValues: MeFieldValues): InfoMe => ({
  name: `${fieldValues.first_name} ${fieldValues.last_name}`,
  email: fieldValues.email,
  phone: parsePhoneNumber(fieldValues?.phone || ''),
});

export const formatPid = (pid: string | null): string | undefined => {
  if (!pid) return undefined;
  if (pid === 'null') return undefined;
  return pid;
};

export const formatSoleTrader = (fieldValues: Company, pid?: string): Company =>
  cleanObject({
    ...fieldValues,
    ...(pid && { referred_by: pid }),
  });

// Companies house can be inconsistent in the data it returns for certain
// companies. So we need to default the country if it's missing
export const formatLimitedCompany = (
  fieldValues: Company,
  pid?: string,
): Company =>
  cleanObject({
    ...fieldValues,
    limited_company_info: {
      ...fieldValues.limited_company_info,
      company_address: {
        ...fieldValues.limited_company_info?.company_address,
        country:
          fieldValues.limited_company_info?.company_address?.country ||
          'United Kingdom',
      },
    },
    ...(pid && { referred_by: pid }),
  });

export const formatCompanyBody = (
  fieldValues: Company,
  pid?: string,
): Company => {
  const type = fieldValues.company_type;
  if (type === 'sole_trader') return formatSoleTrader(fieldValues, pid);
  return formatLimitedCompany(fieldValues, pid);
};
