import useCreateJobContextValue from './CreateJob-useCreateJobContextValue';

export type IsExampleJobHook = boolean;

export default function useIsExampleJob(): IsExampleJobHook {
  const { data } = useCreateJobContextValue();

  const isExampleJob = data?.job?.flow_type === 'example';

  return isExampleJob;
}
