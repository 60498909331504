import { NotesDisplay } from '@kanda-libs/ks-component-ts';

interface NotesDesktopProps {
  note: string;
}

const ViewComponent = ({ note }: NotesDesktopProps) => (
  <NotesDisplay note={note} />
);

ViewComponent.displayName = 'Job-Notes-Deskto-view';

export default ViewComponent;
