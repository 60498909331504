import { useWatch } from '@kanda-libs/ks-component-ts';
import { OPTIONS } from './VAT-constants';

const ContainerComponent = ({ children, index, arrayName }) => {
  const vatRateName = `${arrayName}.${index}.vat_rate`;

  const rate = useWatch({ name: vatRateName });

  const showAmountField = rate === 'custom';

  const vatSelectSharedProps = {
    options: OPTIONS,
  };

  return children({
    showAmountField,
    vatSelectSharedProps,
  });
};

ContainerComponent.displayName = 'Items-VAT-container';

export default ContainerComponent;
