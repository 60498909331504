import useCurrentJobCredit from 'hooks/useCurrentJobCredit';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatAmount } from './Deposit-functions';
import { getCustomerDeposit } from './helpers';
import useCurrentCompany from '../../../../../../hooks/useCurrentCompany';
import useCurrentJob from '../../../../JobDetails-useCurrentJob';

export interface ContainerComponentChildrenArgs {
  amount: string;
  customerAmount: string;
  showCustomerDeposit: boolean;
  depositIsDifferent: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { job } = useCurrentJob();
  const { credit } = useCurrentJobCredit();
  const { company } = useCurrentCompany();

  const isLoading = useSelector(selectors.getIsLoading);

  const showDeposit = !isLoading && company?.skip_deposit === 'Yes';

  const customerDeposit = getCustomerDeposit(job);

  const customerDepositIsDifferent =
    job?.deposit_value?.amount !== customerDeposit && customerDeposit !== 0;

  const showCustomerDeposit = !isLoading && customerDepositIsDifferent;

  const amount = formatAmount(
    job?.deposit_type,
    job?.deposit_value?.amount,
    job?.total?.amount_vat_inclusive?.amount,
  );

  const customerAmount = formatAmount(
    job?.deposit_type,
    customerDeposit,
    job?.total?.amount_vat_inclusive?.amount,
  );

  const depositIsDifferent =
    job?.deposit_value?.amount !== credit?.deposit_value?.amount;

  if (!showDeposit) return <></>;

  return children({
    amount,
    customerAmount,
    showCustomerDeposit,
    depositIsDifferent,
  });
};

ContainerComponent.displayName = 'Job-Desktop-Deposit-container';

export default ContainerComponent;
