import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import {
  Title,
  Description,
  Quantity,
  Price,
  Vat,
  Total,
} from '../../../components';

interface ViewComponentProps {
  /**
   * Is loading
   */
  smaller?: boolean;
  /**
   * Variant
   */
  classNames: StringIndexedObject<StringIndexedObject<string>>;
}

const ViewComponent = ({ classNames, smaller }: ViewComponentProps) => (
  <div className={classNames.desktop.container}>
    {!smaller && <Quantity />}
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full">
        <div className={classNames.desktop.titleWrapper}>
          <div className="flex flex-row flex-1">
            {smaller && <Quantity />}
            <Title />
            {smaller && <Total />}
          </div>
          {smaller && <Description />}
        </div>
        <div>
          <div className={classNames.desktop.values}>
            {!smaller && (
              <>
                <Price />
                <Vat />
              </>
            )}
            {!smaller && <Total />}
          </div>
        </div>
      </div>
      {!smaller && <Description />}
    </div>
  </div>
);

ViewComponent.displayName = 'LineItem-Desktop-Kanda-view';

export default ViewComponent;
