import Container from './List-container';

import { LineItem } from '../../../../../../components';

const ViewComponent = () => (
  <Container>
    {({ lineItems, isLoading }) =>
      lineItems.map((item) => (
        <div className="mb-2" key={item.key}>
          <LineItem isLoading={isLoading} item={item} variant="job-summary" />
        </div>
      ))
    }
  </Container>
);

ViewComponent.displayName = 'Job-Items-List-view';

export default ViewComponent;
