import { Price } from '@kanda-libs/ks-design-library';
import useDepositOptions from '../useDepositOptions';
import { CLASS_NAMES } from './constants';
import Header from './Header';

const Mobile = ({}) => {
  const {
    skipDeposit,
    noDeposit,
    fixedDeposit,
    partialDeposit,
    depositValue,
    depositPercentage,
  } = useDepositOptions();

  if (!skipDeposit) {
    return <div className={CLASS_NAMES.emptyContainer} />;
  }

  return (
    <div className={CLASS_NAMES.container}>
      <Header />
      <div className={CLASS_NAMES.title}>
        {noDeposit && 'No deposit set'}
        {(fixedDeposit || partialDeposit) && (
          <div className="flex items-center gap-x-1">
            Customer deposit at least{' '}
            <Price
              amount={depositValue}
              poundsClassName={CLASS_NAMES.title}
              centsClassName={CLASS_NAMES.title}
            />{' '}
            / {depositPercentage}%
          </div>
        )}
      </div>
    </div>
  );
};

export default Mobile;
