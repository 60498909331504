import {
  Button,
  Card,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Text,
} from '@kanda-libs/ks-design-library';
import { Title } from '@kanda-libs/ks-design-library-new';
import type { FunctionComponent } from 'react';
import { capitaliseWords } from 'utils';
import { CLASS_NAMES } from './constants';
import { getLeadCustomerName, getLeadTitle } from './helpers';
import useLeadAccept from './useLeadAccept';

const LeadAccept: FunctionComponent = function () {
  const { lead, isLoading, isSubmitting, onApproveLead, onDenyLead } =
    useLeadAccept();

  const loadingProps = { isLoading, skeletonProps: { width: 100 } };

  return (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent>
        <Title>Here are the customers details</Title>
        <Text
          text="If you want to quote this lead click accept and it will appear in your dashboard. You can then view the customers contact details and quote them like normal. You don't have to quote the customer if you don't want to. Be fast! You're not the only trade this lead went to."
          className={CLASS_NAMES.description}
          {...loadingProps}
        />
        <Card className={CLASS_NAMES.card.wrapper}>
          <div className={CLASS_NAMES.card.row}>
            <div className={CLASS_NAMES.card.title}>
              <Title className={CLASS_NAMES.subTitle} size={2}>
                {getLeadTitle(lead)}
              </Title>
              <Text text={getLeadCustomerName(lead)} {...loadingProps} />
            </div>
            <div className={CLASS_NAMES.card.actions}>
              <Button.Text
                left
                size={40}
                id="dashboard-accept-lead"
                label="Not interested"
                variant="ghost"
                disabled={isSubmitting}
                onClick={onDenyLead}
                {...loadingProps}
              />
              <Button.Text
                left
                size={40}
                id="dashboard-deny-lead"
                label="Accept"
                variant="gradient"
                icon="check"
                disabled={isSubmitting}
                onClick={onApproveLead}
                {...loadingProps}
              />
            </div>
          </div>
          <div className={CLASS_NAMES.card.details}>
            <Title className={CLASS_NAMES.subTitle} size={2}>
              Quote details
            </Title>
            <div className={CLASS_NAMES.card.detailsRow}>
              <Text text="Description:" {...loadingProps} />
              <Text
                text={capitaliseWords(lead?.lead_quote?.description || '')}
                {...loadingProps}
              />
            </div>
            <div className={CLASS_NAMES.card.detailsRow}>
              <Text text="Needs work finished by:" {...loadingProps} />
              <Text
                text={capitaliseWords(lead?.lead_quote?.work_finished_by || '')}
                {...loadingProps}
              />
            </div>
            <div className={CLASS_NAMES.card.detailsRow}>
              <Text text="County:" {...loadingProps} />
              <Text
                text={capitaliseWords(
                  lead?.lead_applicant?.customer_details?.current_address
                    ?.county || '',
                )}
                {...loadingProps}
              />
            </div>
          </div>
        </Card>
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );
};

export default LeadAccept;
