import { useForm } from '@kanda-libs/ks-component-ts';
import { FirebaseAuthService, actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export default function useStaffImitationForm() {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.infoGhost.getIsSubmitting);

  const form = useForm({ mode: 'onBlur' });

  const onError = useApiError('There was an error logging in as this user');

  const onSubmit = useCallback(
    (formData) => {
      const { email } = formData;
      if (!email) return;

      dispatch(
        actions.infoGhost({
          body: {
            email,
          },
          onError,
          onSuccess: (data) => {
            FirebaseAuthService.signInWithCustomToken(
              data?.custom_token as string,
            );
          },
        }),
      );
    },
    [onError, dispatch],
  );

  return { form, onSubmit, isSubmitting };
}
