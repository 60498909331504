export const PHONE_REGEX = /^(0|\+44)?[1,2,3,7,8][0-9]{9}$/;

export const FIELD_NAMES = {
  firstName: 'me.first_name',
  lastName: 'me.last_name',
  email: 'me.email',
  phoneNumber: 'me.phone',
} as const;

export const FIRST_NAME_PROPS = {
  name: FIELD_NAMES.firstName,
  label: 'First Name',
  placeholder: 'First name',
  autoCapitalize: 'on',
  autoCorrect: 'off',
  autoComplete: 'given-name',
};

export const LAST_NAME_PROPS = {
  name: FIELD_NAMES.lastName,
  autoCapitalize: 'on',
  autoCorrect: 'off',
  autoComplete: 'family-name',
  label: 'Last Name',
  placeholder: 'Last name',
};

export const PHONE_NUMBER_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Phone',
  name: FIELD_NAMES.phoneNumber,
  placeholder: 'Phone number',
};

export const EMAIL_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'email',
  label: 'Email',
  name: FIELD_NAMES.email,
  placeholder: 'Email address',
  disabled: true,
};

export const FIRST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'First name is required',
  },
  minLength: {
    value: 1,
    message: 'First name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'First name cannot be blank',
  },
};

export const LAST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'Last name is required',
  },
  minLength: {
    value: 1,
    message: 'Last name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'Last name cannot be blank',
  },
};

export const PHONE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};
