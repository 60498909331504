import { Field } from '@kanda-libs/ks-component-ts';
import { LAST_NAME_PROPS, LAST_NAME_VALIDATION } from './constants';

const SignUpFormFieldLastName: React.FunctionComponent = function () {
  return (
    <Field.Validator validation={LAST_NAME_VALIDATION}>
      <Field.Input {...LAST_NAME_PROPS} />
    </Field.Validator>
  );
};

export default SignUpFormFieldLastName;
