import { useFormContext } from '@kanda-libs/ks-component-ts';
import { APP_ENV } from 'config';

const PHONE_REGEX = /^(0)?[1,2,3,7,8][0-9]{9}$/;

export default function useDirectorEmailValidation(index) {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: (value) => {
        const directorsExcludingCurrent = getValues().company.users.filter(
          (_, userIndex) => userIndex !== index,
        );
        const directorMobilesExcludingCurrent = directorsExcludingCurrent.map(
          ({ mobile }) => mobile,
        );
        const validMobile = PHONE_REGEX.test(value);
        if (APP_ENV === 'qa')
          return value && validMobile && value === '07123456789';
        return (
          value &&
          validMobile &&
          directorMobilesExcludingCurrent.indexOf(value) === -1
        );
      },
      message:
        "Please enter a valid mobile number that hasn't been used for another director",
    },
  };
}
