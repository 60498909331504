import { useIsDesktop } from '@kanda-libs/ks-design-library';
import useMe from 'hooks/useMe';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { TITLE, TITLE_DESKTOP } from './Info-constants';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  label: string;
  isAdmin: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    data: { isLoading = false },
  } = useCreateJobContextValue();

  const { isAdmin } = useMe();

  const isDesktop = useIsDesktop();

  const label = isDesktop ? TITLE_DESKTOP : TITLE;

  return children({ isLoading, label, isAdmin });
};

ContainerComponent.displayName = 'PaymentAndFinanceOptions-Info-container';

export default ContainerComponent;
