import { Button, Tag } from '@kanda-libs/ks-design-library';

import { TAG, BUTTON } from './Mobile-constants';

interface ViewComponentProps {
  /**
   * Label
   */
  label: string;
  /**
   * On click function
   */
  onClick(...args: unknown[]): unknown;
}

const ViewComponent = ({ label, onClick }: ViewComponentProps) => (
  <Tag {...TAG} label={label} size={31} variant="solid">
    <Button.Icon {...BUTTON} onClick={onClick} />
  </Tag>
);

ViewComponent.displayName = 'Filter-Mobile-view';

export default ViewComponent;
