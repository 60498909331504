import { CommonWrapper } from '@kanda-libs/ks-component-ts';
import { ConfigWrapper, DesktopFooter } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';
import { DesktopHeader } from '..';

import Container from './AppWrapper-container';

import { URLS } from '../../config';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <ConfigWrapper
    linkComponent={Link}
    homeLinkProps={{ to: URLS.home }}
    desktopHeader={DesktopHeader}
    desktopFooter={DesktopFooter}
  >
    <CommonWrapper recaptcha>
      <Container>{children}</Container>
    </CommonWrapper>
  </ConfigWrapper>
);

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.displayName = 'AppWrapper-view';

export default ViewComponent;
