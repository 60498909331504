import { Header, Layout, Logo } from '@kanda-libs/ks-design-library';
import { BookCallButton } from '../components';

interface SetupLayoutMobileProps {
  children: React.ReactNode;
  footer: React.ReactNode;
}

const SetupLayoutMobile: React.FunctionComponent<SetupLayoutMobileProps> =
  function ({ children, footer }) {
    return (
      <Layout
        header={
          <Header.Base lines hideNumber>
            <div className="flex flex-row w-full justify-between">
              <Logo height={20} className="my-auto" />
              <BookCallButton />
            </div>
          </Header.Base>
        }
        footer={footer ? <>{footer}</> : undefined}
        stickyFooter
        stickyHeader
      >
        <>{children}</>
      </Layout>
    );
  };

export default SetupLayoutMobile;
