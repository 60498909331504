interface SignUpFormCardProps {
  children: React.ReactNode;
}

const SignUpFormCard: React.FunctionComponent<SignUpFormCardProps> = function ({
  children,
}) {
  return (
    <div className="flex flex-col flex-1 md:border border-neutral-300 rounded-lg md:p-16 md:shadow-card">
      <div className="flex flex-col flex-1 w-full max-w-120 mx-auto my-auto md:my-0">
        {children}
      </div>
    </div>
  );
};

export default SignUpFormCard;
