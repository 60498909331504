import { Text } from '@kanda-libs/ks-design-library';

const ViewComponent = () => (
  <div className="flex flex-col">
    <Text text="Payment info" className="text-16-20-em mb-6 text-neutral-900" />
  </div>
);

ViewComponent.displayName = 'Subscription-Content-PaymentInfo-Desktop-view';

export default ViewComponent;
