import { Field, FormTheme, Label } from '@kanda-libs/ks-component-ts';
import type { FunctionComponent } from 'react';
import { NOTE_FIELD_PROPS, NOTE_LABEL_PROPS } from './constants';
import useJobNotes from './useJobNotes';

const JobNotesField: FunctionComponent = function () {
  const notes = useJobNotes();
  return (
    <>
      <Label {...NOTE_LABEL_PROPS} />
      <FormTheme variant="emphasized">
        <Field.RichTextInput {...NOTE_FIELD_PROPS} initialValue={notes} />
      </FormTheme>
    </>
  );
};

export default JobNotesField;
