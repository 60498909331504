import { Field, FormTheme } from '@kanda-libs/ks-component-ts';
import { FILTERABLE_SELECT_FIELD_PROPS } from '../constants';
import useFilterableSelect from './useFilterableSelect';

const BusinessConfigFilterableSelect: React.FunctionComponent = function () {
  useFilterableSelect();
  return (
    <FormTheme variant="streamline">
      <Field.FilterableSelect {...FILTERABLE_SELECT_FIELD_PROPS} />
    </FormTheme>
  );
};

export default BusinessConfigFilterableSelect;
