import Container from './Deposit-container';
import { CLASS_NAMES } from '../../PaymentAndFinanceOptions-constants';

const ViewComponent = () => (
  <Container>
    {({ amount, customerAmount, showCustomerDeposit, depositIsDifferent }) => (
      <div className={CLASS_NAMES.cardContainer}>
        <div className={CLASS_NAMES.card}>
          <div className="flex flex-col p-4">
            <p className="text-14-22-em text-neutral-600">Deposit</p>
            {!showCustomerDeposit && (
              <div className="flex flex-row">
                <p className="mr-1 text-12-18-em text-neutral-500">
                  You selected:
                </p>
                <p className="text-12-18-em text-lavender-200">{amount}</p>
              </div>
            )}
            {showCustomerDeposit && (
              <div className="flex flex-row">
                <p className="mr-1 text-12-18-em text-neutral-500">
                  Customer opted to pay:
                </p>
                <p className="text-12-18-em text-lavender-200">
                  {customerAmount}
                </p>
              </div>
            )}
            {showCustomerDeposit && depositIsDifferent && (
              <div>
                <p className="mt-2 mr-1 text-12-18-em text-neutral-900">
                  Please note, the customer opted to pay a higher deposit for
                  this job so you will need to collect the difference.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-Deposit-view';

export default ViewComponent;
