import InformationRequiredBanner from 'pages/Onboarding/components/Pages/components/InformationRequiredBanner';
import BasePage from '../BasePage';
import { TITLE, BULLETS, TYPE } from './TradeInfo-constants';
import useCompanyIsSolar from './useCompanyIsSolar';
import Solar from './Solar';
import Standard from './Standard';
import useOnlinePresenceRedirect from './useOnlinePresenceRedirect';

const ViewComponent = ({}) => {
  const { companyType } = useCompanyIsSolar();

  useOnlinePresenceRedirect();

  if (!companyType) return null;

  return (
    <BasePage sidebarBullets={BULLETS} sidebarTitle={TITLE}>
      <InformationRequiredBanner stage="trade_reviewed" />
      {companyType === TYPE.SOLAR ? <Solar /> : <Standard />}
    </BasePage>
  );
};

ViewComponent.displayName = 'TradeInfo-view';

export default ViewComponent;
