import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

import { Content, Footer } from '../components';

interface ViewComponentProps {
  /**
   * Disabled state for social buttons
   */
  disabled?: boolean;
  /**
   * Function to sign in with Google
   */
  display?: string;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <Content {...props} />
      <Footer {...props} />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'AuthLink-Desktop-view';

ViewComponent.defaultProps = {
  disabled: undefined,
  display: undefined,
};

export default ViewComponent;
