import { useFormContext, ValidationItems } from '@kanda-libs/ks-component-ts';
import { useCallback } from 'react';

import useUserEmailValidation from 'hooks/useUserEmailValidation';
import {
  removeTrailingWhitespace,
  removeWhitespaceAndNewline,
} from '../../../../../../../../../utils';
import type { NewProps } from './New-view';
import { useMobileValidation } from './useMobileValidation';

export interface ContainerComponentChildrenArgs {
  index: number;
  arrayName: string;
  handleRemove: () => void;
  validation: ValidationItems;
  onNameBlur: (name: string) => void;
  onContactBlur: (name: string) => void;
  mobileValidation: ValidationItems;
}

export interface ContainerComponentProps extends NewProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  users,
  remove = () => {},
  arrayName,
}: ContainerComponentProps) => {
  const { getValues, setValue } = useFormContext();

  const index = users?.[0]?.index;
  const handleRemove = useCallback(() => remove(index), [remove, index]);

  const validation = useUserEmailValidation(index);

  const { validation: mobileValidation } = useMobileValidation();

  const onNameBlur = useCallback(
    (name) => {
      const fieldName = `${arrayName}.${index}.${name}`;
      setValue(fieldName, removeTrailingWhitespace(getValues(fieldName)));
    },
    [arrayName, index, setValue, getValues],
  );

  const onContactBlur = useCallback(
    (name) => {
      const fieldName = `${arrayName}.${index}.${name}`;
      setValue(fieldName, removeWhitespaceAndNewline(getValues(fieldName)));
    },
    [arrayName, index, setValue, getValues],
  );

  return children({
    index,
    arrayName,
    handleRemove,
    validation,
    onNameBlur,
    onContactBlur,
    mobileValidation,
  });
};

ContainerComponent.displayName = 'Users-New-container';

export default ContainerComponent;
