import { useGtag } from '../../hooks';

const ContainerComponent = ({ children, signInWithGoogle }) => {
  const { buttonClick } = useGtag();

  const onClick = () => {
    buttonClick('google_sign_in');
    signInWithGoogle();
  };

  return children({
    onClick,
  });
};

ContainerComponent.displayName = 'Login-Google-container';

export default ContainerComponent;
