import { Form } from '@kanda-libs/ks-component-ts';
import { Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { Layout } from '../components';
import Pages from './Pages';
import useSignContractForm from './useSignContractForm';

const SignContractForm: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting } = useSignContractForm();

  return (
    <Form id="sign-subscription-contract" form={form} onSubmit={onSubmit}>
      <Layout>
        <Helmet title="Sign Subscription Contract" />
        <div className="flex flex-col w-full max-w-120 md:max-w-full mx-auto">
          <Pages.Contract isSubmitting={isSubmitting} />
        </div>
        <Loader isLoading={isSubmitting} />
      </Layout>
    </Form>
  );
};

export default SignContractForm;
