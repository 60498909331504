import { Button, Icon } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import EnterpriseRatesSuccessLayout from './Layout';
import useEnterpriseRatesSuccess from './useEnterpriseRatesSuccess';

const EnterpriseRatesSuccess: FunctionComponent = function () {
  useEnterpriseRatesSuccess();
  return (
    <EnterpriseRatesSuccessLayout>
      <div className="flex flex-col m-auto">
        <Icon icon="check-circle-color" size={104} className="mx-auto mb-8" />
        <p className="text-style-c text-neutral-900 text-center mb-4">
          Success! Your rates have been updated for the next 12 months
        </p>
        <p className="text-style-f text-neutral-600 text-center">
          The new rate card will apply to all jobs sent from now on
        </p>
        <Link to={URLS.home} className="mt-6 mx-auto">
          <Button.Text
            id="enterprise-success-go-home"
            label="Return to Dashboard"
            icon="arrow-right"
            className=""
          />
        </Link>
      </div>
    </EnterpriseRatesSuccessLayout>
  );
};

export default EnterpriseRatesSuccess;
