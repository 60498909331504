import { getTextWidth } from '@kanda-utils/library';
import clsx from 'clsx';

import {
  FIRST_STEP_LABEL,
  DEFAULT_STEP_LABEL,
  LAST_STEP_LABEL,
  LABEL_CLASS_NAMES,
  LABEL_TEXT_STYLE,
} from './NextButton-constants';

/**
 * Function to return current label for next button
 * @param {number} current current step
 * @param {number} totalSteps number of total steps
 * @returns {String} label
 */
export const getCurrentLabel = (current, totalSteps) => {
  if (current === 0) return FIRST_STEP_LABEL;

  if (current === totalSteps - 1) return LAST_STEP_LABEL;

  return DEFAULT_STEP_LABEL;
};

/**
 * Function to return label class name
 * @param {Boolean} isActive
 * @returns {String} className
 */
const getLabelClassName = (isActive) => {
  const { base, active, inactive } = LABEL_CLASS_NAMES;

  return clsx(base, isActive ? active : inactive);
};

/**
 * Function to return all labels for next button
 * @param {number} current current step
 * @param {number} totalSteps number of total steps
 * @returns {Array} labels
 */
export const getLabels = (current, totalSteps) => {
  const currentLabel = getCurrentLabel(current, totalSteps);

  return [FIRST_STEP_LABEL, DEFAULT_STEP_LABEL, LAST_STEP_LABEL].map(
    (label) => ({
      label,
      className: getLabelClassName(label === currentLabel),
    }),
  );
};

/**
 * Get Button Width
 * @param {number} current current step
 * @param {number} totalSteps number of total steps
 * @returns {Number} width
 */
export const getButtonWidth = (current, totalSteps) => {
  const currentLabel = getCurrentLabel(current, totalSteps);

  return getTextWidth(currentLabel, LABEL_TEXT_STYLE);
};
