import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';

import Container from './SaveAsDraftButton-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = ({}) => (
  <Container>
    {({ isSubmitting, ...rest }) => (
      <>
        <BreakPoints
          mobile={<Mobile disabled={isSubmitting} {...rest} />}
          desktop={<Desktop disabled={isSubmitting} {...rest} />}
        />
        <Loader isLoading={isSubmitting} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-SaveDraftButton-view';

export default ViewComponent;
