import { useMemo } from 'react';

import useMe from '../../../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const { me } = useMe();

  const isLoading = !me;

  const isVerified = useMemo(() => {
    if (isLoading) return true;
    return me?.verified;
  }, [isLoading, me]);

  return children({
    isVerified,
  });
};

ContainerComponent.displayName = 'SetupCompany-CompanyInfo-container';

export default ContainerComponent;
