import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';

interface ViewComponentProps {
  /**
   * onClick function
   */
  onClick(...args: unknown[]): unknown;
}

const ViewComponent = ({ onClick }: ViewComponentProps) => (
  <div className="flex flex-row mb-10">
    <FormTheme variant="clean">
      <FieldHandle.Checkbox id="sub-debit" name="sub-debit" onClick={onClick} />
    </FormTheme>
    <Text
      text="Set up direct debit"
      className="ml-3 my-auto text-14-22 text-neutral-700"
    />
  </div>
);

ViewComponent.displayName = 'SubscribeForm-Debit-view';

export default ViewComponent;
