import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './Contact-container';

const ViewComponent = () => (
  <Container>
    {({ email, phone, onBlur }) => (
      <>
        <Widget.JobCustomerEmail
          placeholder="Email address"
          validation={email}
          onBlur={() => onBlur('job.customer.email')}
        />
        <Widget.JobCustomerPhone
          placeholder="Phone number"
          validation={phone}
          onBlur={() => onBlur('job.customer.phone')}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Mobile-Customer-Field-Contact-view';

export default ViewComponent;
