import { useCallback, useMemo, useEffect } from 'react';
import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';

import { getRole } from '../../../../Users-functions';
import { getRoleSubtext, getRolePermissions } from './Role-functions';
import type { NewRoleProps } from './Role-view';

export interface ContainerComponentChildrenArgs {
  role: string;
  roleName: string;
  subtext: string;
  permissions: boolean[];
  selected: boolean;
  onClick: () => void;
}

export interface ContainerComponentProps extends NewRoleProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  role,
  arrayName,
  index,
}: ContainerComponentProps) => {
  const fieldName = useMemo(
    () => `${arrayName}.${index}.role`,
    [arrayName, index],
  );
  const selectedRole = useWatch({ name: fieldName });

  const { setValue } = useFormContext();

  const selected = role === selectedRole;

  const roleName = getRole(role);

  const onClick = useCallback(
    () => setValue(fieldName, role),
    [setValue, fieldName, role],
  );

  const subtext = useMemo(() => getRoleSubtext(role), [role]);
  const permissions = useMemo(() => getRolePermissions(role), [role]);

  useEffect(() => {
    (document.getElementById(role) as HTMLInputElement).checked = selected;
  }, [selected, role]);

  return children({
    role,
    roleName,
    subtext,
    permissions,
    selected,
    onClick,
  });
};

ContainerComponent.displayName = 'New-Role-container';

export default ContainerComponent;
