import { useForm } from '@kanda-libs/ks-component-ts';
import { StringIndexedObject, useToast } from '@kanda-libs/ks-design-library';
import { actions, Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useCurrentCompany from 'hooks/useCurrentCompany';
import useMe from 'hooks/useMe';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { cleanObject } from 'utils';
import { formatCompany } from './helpers';

export interface JobSettingsFormData {
  quote_preference: Company['quote_preference'];
}

export interface JobSettingsFormHook {
  form: UseFormReturn<JobSettingsFormData>;
  onSubmit: (formValues: StringIndexedObject) => void;
  isSubmitting: boolean;
}

export default function useJobSettingsForm(): JobSettingsFormHook {
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { revalidateMe } = useMe();
  const { company, getCompanies } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const onError = useApiError(
    'Error updating job settings at this time - please try again later',
  );

  const defaultValues = useMemo(() => {
    if (!company) return null;
    const formattedCompany = cleanObject(formatCompany(company));
    return { ...formattedCompany };
  }, [company]);

  const form = useForm({ mode: 'onBlur', defaultValues });

  const { reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      const { id } = company || {};
      if (!id) return;
      const body = cleanObject({
        ...company,
        ...formValues.company,
      });

      dispatch(
        actions.putCompany({
          body,
          params: { id },
          onError,
          onSuccess: () => {
            showSuccess('Job settings updated');
            getCompanies();
            revalidateMe();
          },
        }),
      );
    },
    [company, getCompanies, dispatch, revalidateMe, onError, showSuccess],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
  };
}
