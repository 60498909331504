import { Price, SkeletonLoader } from '@kanda-libs/ks-design-library';

import Container from './Footer-container';
import { TOTAL_LABEL, CLASS_NAMES, PRICE_PROPS } from './Footer-constants';

const ViewComponent = () => (
  <Container>
    {({ total, isLoading }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.label}>
          <SkeletonLoader isLoading={isLoading} afterLoading={TOTAL_LABEL} />
        </div>
        <Price isLoading={isLoading} amount={total} {...PRICE_PROPS} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-Items-Footer-view';

export default ViewComponent;
