import { Icon } from '@kanda-libs/ks-design-library';

const EmailConfirmed: React.FunctionComponent = function () {
  return (
    <div className="flex flex-row justify-between p-4 md:p-6 rounded-lg md:rounded-xl border border-neutral-300 mb-6 shadow-card">
      <div className="flex flex-row">
        <div className="w-5 h-5 min-w-5 min-h-5 flex bg-neutral-500 rounded-full mr-4">
          <Icon
            icon="check"
            size={12}
            className="m-auto"
            stroke="neutral-000"
          />
        </div>
        <p className="text-16-20-em text-neutral-600">Email Confirmed</p>
      </div>
    </div>
  );
};

export default EmailConfirmed;
