import { Icon, Text } from '@kanda-libs/ks-design-library';
import AccountBadge from '../../../../../components/AccountBadge';
import { URLS } from '../../../../../config';
import useUpgradeAccountBadgeIsVisible from './useUpgradeAccountBadgeIsVisible';

const UpgradeAccountBadge = ({}) => {
  const showBanner = useUpgradeAccountBadgeIsVisible();

  if (!showBanner) {
    return <></>;
  }

  return (
    <div className="md:mt-10">
      <AccountBadge
        title="Subscribe to send your job"
        subTitle="Finish set up to send your first job"
        buttonLabel="Upgrade now"
        buttonPrepend={<Icon icon="star" className="w-4 mr-2" />}
        buttonAppend={
          <Text text="£30/mo" className="ml-1 font-medium text-light" />
        }
        buttonIcon={undefined}
        showIcon={false}
        showTitleAboveSubtitle={false}
        link={URLS.subscription}
      />
    </div>
  );
};

export default UpgradeAccountBadge;
