/**
 * Ensure any numeric value is an integer
 *
 * @param {Any} value
 */
export const ensureValueIsInt = (value) => {
  if (typeof value !== 'number') return value;
  return Math.round(value);
};

export const formatToPercentage = (value: number): string => `${value / 100}%`;
