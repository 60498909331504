import {
  BreakPoints,
  Icon,
  SkeletonLoader,
} from '@kanda-libs/ks-design-library';

import {
  SKELETONS,
  CLASS_NAMES,
  CUSTOMER_ICON_PROPS,
} from '../../JobHeader-constants';

interface ViewComponentProps {
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Customer
   */
  name?: string;
}

const ViewComponent = ({ isLoading, name }: ViewComponentProps) => (
  <p className={CLASS_NAMES.customer}>
    <BreakPoints
      mobile={<Icon isLoading={isLoading} {...CUSTOMER_ICON_PROPS.mobile} />}
      desktop={<Icon isLoading={isLoading} {...CUSTOMER_ICON_PROPS.desktop} />}
    />
    <span className={CLASS_NAMES.customerLabel}>
      <SkeletonLoader
        {...SKELETONS.customer}
        isLoading={isLoading}
        afterLoading={name}
      />
    </span>
  </p>
);

ViewComponent.displayName = 'JobHeader-Customer-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  name: undefined,
};

export default ViewComponent;
