import type { Company, Document } from '@kanda-libs/ks-frontend-services';
import type { Contract } from 'pages/Onboarding/types';

export const getContractFile = (
  documents: Document[],
  company: Company,
): Contract | undefined => {
  const cid = company?.cid;
  if (!cid) return undefined;
  const document = documents.filter((doc: Document) =>
    doc.name.includes(`Kanda-T&C-${cid}_`),
  )?.[0];
  if (!document) return undefined;
  const parts = document.name.split('.')[0].split('_');
  if (parts.length !== 3) return undefined;
  const filename = parts[0];
  const signee = parts[1].split('-').join(' ');
  const timestamp = parseInt(parts[2], 10);
  return {
    document: {
      ...document,
      name: [filename, document.name.split('.')[1]].join('.'),
    },
    filename,
    signee,
    timestamp,
  };
};
