import type { ButtonTextVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  container: 'flex flex-col w-full mt-6',
  footer: 'flex flex-row justify-center mt-6',
  signup: 'text-style-g text-neutral-700 mr-2 my-auto',
  socialContainer: 'flex flex-col md:flex-row mt-4',
};

export const SUBMIT_BUTTON = {
  submit: true,
  id: 'sso-submit',
  variant: 'solid' as ButtonTextVariant,
  label: 'Send instructions',
  icon: 'check',
  left: true,
};
