import { useFormContext } from '@kanda-libs/ks-component-ts';

export default function useTradeBodyNumberValidation() {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: () => {
        const values = getValues();
        const tradeBody = values?.company?.company_info?.trade_body;

        return (
          !tradeBody ||
          tradeBody === 'none' ||
          !!values?.company?.company_info?.trade_body_number
        );
      },
      message: 'Please enter a trade body number',
    },
  };
}
