import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { DesktopLayoutBoxed, Loader } from '@kanda-libs/ks-design-library';

import { Content, ContentHeader, Wrapper } from './components';

interface DesktopProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
  /**
   * LogoProps
   */
  logoProps?: StringIndexedObject<string>;
  /**
   * prev step function
   */
  setStep?: (step: string) => void;
  /**
   * Step index
   */
  step?: number;
}

const Desktop: React.FunctionComponent<DesktopProps> = ({
  isLoading,
  logoProps,
  setStep = () => {},
  step,
}) => (
  <Wrapper>
    <DesktopLayoutBoxed logoProps={logoProps}>
      <>
        <ContentHeader setStep={setStep} step={step} />
        <Content />
        {isLoading && <Loader isLoading={isLoading} />}
      </>
    </DesktopLayoutBoxed>
  </Wrapper>
);

export default Desktop;
