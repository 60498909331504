import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { renderStep } from './helpers';

interface DesktopProps {
  steps: StringIndexedObject[];
  isLoading: boolean;
  activeStep: number;
  stepsCompleted: StringIndexedObject;
  setStep?: (step: StringIndexedObject) => void;
  isPremiumCredit?: boolean;
}

const Desktop = ({
  steps,
  isLoading,
  activeStep,
  stepsCompleted,
  setStep,
  isPremiumCredit,
}: DesktopProps) => (
  <>
    {steps.map((step, index) =>
      renderStep(
        step,
        isLoading,
        !isLoading && activeStep === index,
        stepsCompleted,
        index + 1,
        setStep,
        isPremiumCredit,
      ),
    )}
  </>
);

export default Desktop;
