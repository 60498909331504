import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';

import TermsText from './TermsAndConditionsText';

interface ViewComponentProps {
  /**
   * onClick function
   */
  onClick(...args: unknown[]): unknown;
}

const ViewComponent = ({ onClick }: ViewComponentProps) => (
  <div className="flex flex-row mb-3">
    <FormTheme variant="clean">
      <FieldHandle.Checkbox id="sub-terms" name="sub-terms" onClick={onClick} />
    </FormTheme>
    <TermsText />
  </div>
);

ViewComponent.displayName = 'SubscribeForm-Terms-view';

export default ViewComponent;
