import { Button, Icon, Text } from '@kanda-libs/ks-design-library';

interface DirectorNameCardProps {
  name: string;
  number?: string;
  isLoading: boolean;
  buttonProps?: object;
}

const DirectorNameCard = ({
  name,
  number,
  isLoading,
  buttonProps,
}: DirectorNameCardProps) => (
  <>
    {number && (
      <div className="flex flex-row justify-between mb-2">
        <Text
          text={`Director ${number}`}
          className="text-12-18-em text-neutral-600 mb-px"
          isLoading={isLoading}
          skeletonProps={{
            width: '80px',
          }}
        />
        <Button.InlineLink
          id="director-name-card-this-is-me"
          variant="turquoise"
          isLoading={isLoading}
          {...buttonProps}
        />
      </div>
    )}
    <div className="flex flex-row px-4 py-5 border border-neutral-300 rounded-xl mb-6">
      <Icon
        icon="account"
        size={20}
        stroke="neutral-700"
        className="my-auto mr-3"
      />
      <Text
        text={name}
        className="text-14-22-em text-neutral-800"
        isLoading={isLoading}
        skeletonProps={{
          width: '150px',
        }}
      />
    </div>
  </>
);

DirectorNameCard.defaultProps = {
  number: null,
  buttonProps: {},
};

export default DirectorNameCard;
