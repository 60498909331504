import { useFormContext } from '@kanda-libs/ks-component-ts';

import { removeTrailingWhitespace } from '../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { getValues, setValue } = useFormContext();

  const onBlur = () =>
    setValue('job.title', removeTrailingWhitespace(getValues('job.title')));

  return children({
    onBlur,
  });
};

ContainerComponent.displayName = 'Title-container';

export default ContainerComponent;
