import type { InfoMe } from '@kanda-libs/ks-frontend-services';
import { parsePhoneNumber } from 'utils';
import type { FieldValues } from './types';

export const formatSignUpPayload = (
  payload: FieldValues,
  referrer: string | undefined,
): InfoMe => ({
  name: `${payload.first_name} ${payload.last_name}`,
  email: payload.email,
  phone: parsePhoneNumber(payload.phone),
  ...(referrer && { referrer }),
});

export const getReferrer = (
  referrer: string | undefined,
  email: string | undefined,
): string | undefined => {
  if (!referrer) return undefined;
  const url = window.location.href;
  if (!email) return url;
  return url.replace(`email=${email}`, '');
};
