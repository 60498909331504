import { Other, Popover, PostJob, PreJob } from './components';

const ViewComponent = () => (
  <div className="flex flex-row text-14-22-em text-neutral-500 h-16 border-b border-neutral-300">
    <span className="w-3/12 pr-2 my-auto">User Name</span>
    <span className="w-3/12 pr-2 my-auto">User Permissions</span>
    <div className="flex flex-row justify-center w-2/12">
      <span className="my-auto">Pre Job</span>
      <Popover content={<PreJob />} id="pre-job" />
    </div>
    <div className="flex flex-row justify-center w-2/12">
      <span className="my-auto">Post Job</span>
      <Popover content={<PostJob />} id="post-job" />
    </div>
    <div className="flex flex-row justify-center w-2/12">
      <span className="my-auto">Other</span>
      <Popover content={<Other />} id="other" />
    </div>
  </div>
);

ViewComponent.displayName =
  'NotificationPreferences-Content-Desktop-Header-view';

export default ViewComponent;
