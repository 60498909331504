import {
  ModalsWrapperContext,
  useFormContext,
} from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { URLS } from '../../../../../../../config';
import { MODAL_ID } from '../../Page-constants';

const ContainerComponent = ({ children }) => {
  const { push } = useHistory();
  const { showModal } = useContext(ModalsWrapperContext);
  const { currentStepIndex, step, steps } = useCreateJobContextValue();
  const formContext = useFormContext();

  const isDirty = useMemo(() => {
    if (formContext == null) return true;
    const dirty = formContext?.formState?.isDirty;
    if (dirty == null) return true;
    return dirty;
  }, [formContext]);

  const currentStep = (currentStepIndex as number) + 1;
  const totalSteps = steps.length - 2;

  const showSteps = (currentStepIndex as number) < totalSteps;

  const currentStepLabel = `${currentStep}/${totalSteps}`;

  const onClick = useCallback(() => {
    // If form hasn't been changed, or user is on success step, return home
    if (!isDirty || step === steps.slice(-1)[0]) {
      push(URLS.home);
      return;
    }
    showModal(MODAL_ID);
  }, [push, showModal, step, steps, isDirty]);

  return children({
    showSteps,
    currentStepLabel,
    onClick,
  });
};

ContainerComponent.displayName = 'CreateJob-Header-container';

export default ContainerComponent;
