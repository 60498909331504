import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { PLACEHOLDER_UPSELL, RATES_UPSELL, type UpSell } from './constants';

export interface UpSellsHook {
  upsells?: UpSell[];
  isLoading: boolean;
}

export default function useUpSells(): UpSellsHook {
  const { company } = useCurrentCompany();

  const isLoading = useMemo(() => !company, [company]);

  const upsells = useMemo(() => {
    if (!company) return [PLACEHOLDER_UPSELL];
    const hasEnterpriseRates =
      company?.tier_config?.current_tier === 'enterprise_rate';
    if (hasEnterpriseRates) return undefined;
    return [RATES_UPSELL];
  }, [company]);

  return {
    upsells,
    isLoading,
  };
}
