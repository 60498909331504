import { useFormContext } from '@kanda-libs/ks-component-ts';
import { removeTrailingWhitespace } from '../../../../../../../../../../utils';

const ContainerComponent = ({ children, arrayName, index }) => {
  const { getValues, setValue } = useFormContext();

  const name = `${arrayName}.${index}.title`;

  const onBlur = () =>
    setValue(name, removeTrailingWhitespace(getValues(name)));

  return children({
    onBlur,
  });
};

ContainerComponent.displayName = 'Title-container';

export default ContainerComponent;
