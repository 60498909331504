import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import useFinanceRates, { type FinanceRatesHook } from 'hooks/useFinanceRates';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';
import { useEffect } from 'react';
import NoFinanceOptionsWarning from './NoFinanceOptionsWarning';

export type ContainerComponentChildrenArgs = FinanceRatesHook;

export interface ContainerComponentProps {
  unregulated: boolean;
  useStoredJob: boolean;
  hideComingSoon: boolean;
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  unregulated,
  useStoredJob,
  hideComingSoon,
  children,
}: ContainerComponentProps) => {
  const { setValue } = useFormContext();
  const [options = [], checkoutOptions = []] = useWatch({
    name: ['job.finance_options', 'job.checkout_options', 'job.job_items'],
  });

  const isLeadJob = useIsLeadJob();

  const formattedRates = useFinanceRates(
    unregulated,
    useStoredJob,
    hideComingSoon,
    false,
  );

  useEffect(() => {
    // If no finance options field return
    if (!options) return;
    // If finance options length is not 0 and the checkout options includes
    // apply_for_finance, return
    if (options?.length !== 0 && checkoutOptions?.includes('apply_for_finance'))
      return;
    if (
      options?.length === 0 &&
      !checkoutOptions?.includes('apply_for_finance')
    )
      return;
    if (
      options?.length === 0 &&
      checkoutOptions?.includes('apply_for_finance')
    ) {
      const newOptions = checkoutOptions.filter(
        (value) => value !== 'apply_for_finance',
      );
      setValue('job.checkout_options', newOptions);
      return;
    }
    const newOptions = [...checkoutOptions, 'apply_for_finance'];
    setValue('job.checkout_options', newOptions);
  }, [checkoutOptions, options, setValue]);

  if (isLeadJob) return <></>;

  if (formattedRates.keys.length === 0) {
    if (unregulated) return <></>;
    if (useStoredJob) return <></>;
    return <NoFinanceOptionsWarning />;
  }

  return children(formattedRates);
};

ContainerComponent.displayName =
  'PaymentAndFinanceOptions-FinanceOptions-container';

export default ContainerComponent;
