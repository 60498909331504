import { Text } from '@kanda-libs/ks-design-library';
import { ModalLayoutFullScreen } from '@kanda-libs/ks-component-ts';

import SubscribeForm from '../../SubscribeForm';

const ViewComponent = () => (
  <ModalLayoutFullScreen>
    <div className="flex flex-col mx-auto w-96 max-w-96">
      <Text
        text="Upgrade your Kanda"
        className="mb-3 text-center text-24-28-em"
      />
      <Text
        text="Send unlimited jobs and win more bids"
        className="mb-10 text-center text-16-24 text-neutral-600"
      />
    </div>
    <SubscribeForm />
  </ModalLayoutFullScreen>
);

ViewComponent.displayName = 'SubscribeModal-Desktop-view';

export default ViewComponent;
