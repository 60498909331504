import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

interface CompanyFormHook {
  companyType: string;
  showMeForm: boolean;
  showCompanyForm: boolean;
}

export default function useCompanyForm(): CompanyFormHook {
  const [companyType, tradeType, tradeTypeText, extra] = useWatch({
    name: [
      'company.company_type',
      'company.business_config.trade_type',
      'company.business_config.trade_text',
      'company.business_config.extra',
    ],
  });
  const me = useSelector(selectors.getUser);

  const showMeForm = useMemo(() => {
    if (!me) return false;
    return !me?.phone;
  }, [me]);

  const showCompanyForm = useMemo(() => {
    if (!tradeType || !tradeTypeText) return false;
    if (tradeType !== 'other_trade') return true;
    if (!tradeTypeText.toLowerCase().includes('not listed')) return true;
    return Boolean(extra);
  }, [tradeType, tradeTypeText, extra]);

  return { companyType, showMeForm, showCompanyForm };
}
