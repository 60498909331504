import { DesktopLayout } from '../../components';
import { UserForm, LoginDetails } from './components';

const ViewComponent = ({}) => (
  <DesktopLayout>
    <UserForm />
    <LoginDetails />
  </DesktopLayout>
);

ViewComponent.displayName = 'AccountSettings-Desktop-view';

export default ViewComponent;
