const numberClasses =
  'w-6 h-6 border-2 flex items-center justify-center text-16-20-em rounded-full mb-3';

export const CLASS_NAMES = {
  wrapper:
    'flex flex-col w-full max-w-60 text-center items-center justify-center mb-6 md:mb-0 min-w-60',
  wrapperCompleted:
    'flex flex-1 items-center md:flex-col w-full h-full max-w-60 text-center items-center justify-center mb-6 md:mb-0 min-w-60',
  icon: 'mb-3',
  iconCompleted: 'mr-2 md:mb-3 md:mr-0',
  title: 'text-16-20-em text-neutral-700 mb-3',
  titleCompleted: 'text-16-20-em text-green-500 md:mb-3',
  subText: 'text-16-24 text-neutral-700 mb-1',
  subTextCompleted: 'text-16-24 text-neutral-700 md:mb-auto hidden md:block',
  required: 'text-16-24 text-green-500 mb-4 italic',
  numberActive: `${numberClasses} border-green-300 text-green-300`,
  numberInactive: `${numberClasses} border-neutral-500 text-neutral-500 bg-neutral-200`,
};
