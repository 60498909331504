import type {
  SelectOption,
  ValidationItems,
} from '@kanda-libs/ks-component-ts';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BASE_CONFIGS } from './constants';
import {
  getBusinessSectorOptions,
  getGroupedByBusinessSectorOptions,
  getTradeTypeFromTradeText,
} from './helpers';

interface BusinessConfigHook {
  showTradeType: boolean;
  showExtraField: boolean;
  sectorOptions: SelectOption[];
  tradeTypeOptions?: SelectOption[];
  extraFieldValidation: ValidationItems;
}

export default function useBusinessConfig(): BusinessConfigHook {
  const { setValue, getValues } = useFormContext();
  const [businessSector, tradeType, tradeText] = useWatch({
    name: [
      'company.business_config.business_sector',
      'company.business_config.trade_type',
      'company.business_config.trade_text',
    ],
  });

  const sectorOptions = getBusinessSectorOptions(BASE_CONFIGS);
  const groupedOptions = getGroupedByBusinessSectorOptions(BASE_CONFIGS);

  const showTradeType = useMemo(
    () => Boolean(businessSector),
    [businessSector],
  );

  const tradeTypeOptions = useMemo(() => {
    if (!businessSector) return undefined;
    return groupedOptions[businessSector];
  }, [businessSector, groupedOptions]);

  const showExtraField = useMemo(() => {
    if (!tradeText || tradeType !== 'other_trade') return false;
    return tradeText.toLowerCase().includes('not listed');
  }, [tradeType, tradeText]);

  const extraFieldValidation = useMemo(
    () => ({
      validate: {
        value: () => {
          const [tradeTypeField, tradeTypeTextField, extra] = getValues([
            'company.business_config.trade_type',
            'company.business_config.trade_text',
            'company.business_config.extra',
          ]);
          if (tradeTypeField !== 'other_trade' || !tradeTypeTextField)
            return true;
          if (!tradeTypeTextField.toLowerCase().includes('not listed'))
            return true;
          return Boolean(extra);
        },
        message: 'You need to tell us your trade type',
      },
    }),
    [getValues],
  );

  useEffect(() => {
    if (!businessSector) return;
    setValue('company.business_config.show', 'yes');
    setValue('company.business_config.trade_text', undefined);
    setValue('company.business_config.extra', undefined);
  }, [setValue, businessSector]);

  useEffect(() => {
    if (!tradeText) return;
    const matchedTradeType = getTradeTypeFromTradeText(BASE_CONFIGS, tradeText);
    if (!matchedTradeType) return;
    setValue('company.business_config.trade_type', matchedTradeType.trade_type);
    setValue('company.business_config.lender', matchedTradeType.lender);
    setValue(
      'company.business_config.lender_rate_type',
      matchedTradeType.lender_rate_type,
    );
    setValue('company.business_config.extra', undefined);
  }, [tradeText, setValue]);

  return {
    showTradeType,
    showExtraField,
    sectorOptions,
    tradeTypeOptions,
    extraFieldValidation,
  };
}
