import { useMemo } from 'react';

import {
  AG_LIVE,
  NO_ACTION,
  PROMPT_DEPOSIT,
  PROMPT_SIGN,
  SAT_NOTE,
} from './Status-constants';
import { getStatus } from './Status-functions';

import useCurrentJob from '../../../../../../JobDetails-useCurrentJob';

const getPayoutDate = (date?: Date) => {
  if (!date) return '';
  const now = new Date(date);
  const day = now.getDay();
  if (day <= 2) {
    const payout = new Date(now.setDate(now.getDate() + 3));
    return payout.toDateString();
  }
  const payout = new Date(now.setDate(now.getDate() + 5));
  return payout.toDateString();
};

const ContainerComponent = ({ children }) => {
  const { isLoading, job, checkJob } = useCurrentJob();

  const financeStatus = useMemo(() => {
    if (!job || !checkJob) return null;
    if (!checkJob?.has_finance_application) return null;
    return checkJob?.current_status;
  }, [job, checkJob]);

  const xstatus = useMemo(() => checkJob?.xstatus, [checkJob]);

  const status = useMemo(
    () => getStatus(job, financeStatus, xstatus),
    [job, financeStatus, xstatus],
  );

  const payoutDate = useMemo(() => {
    if (!xstatus || !xstatus.includes('AG_LIVE')) return '';
    return getPayoutDate(job?.metadata?.updated_at);
  }, [job, xstatus]);

  const subStatus = useMemo(() => {
    if (financeStatus === 'accepted_deposit_required') return PROMPT_DEPOSIT;
    if (financeStatus === 'accepted_sign_document') return PROMPT_SIGN;
    if (
      job?.finance_status === 'sat_note_signed' ||
      job?.finance_status === 'sat_note_lender_review'
    ) {
      if (!xstatus || !xstatus.includes('AG_LIVE')) return SAT_NOTE;
      return AG_LIVE.replace('$DATE', payoutDate);
    }
    return NO_ACTION;
  }, [financeStatus, job, xstatus, payoutDate]);

  return children({
    status,
    subStatus,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Status-container';

export default ContainerComponent;
