import { Card, EditPaymentMethod } from '../../components';
import PayMonthlyForm from './Form';

export interface PayMonthlyPageProps {
  disabled: boolean;
}

const PayMonthlyPage: React.FunctionComponent<PayMonthlyPageProps> = function ({
  disabled,
}) {
  return (
    <>
      <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
        Set up your monthly subscription via Premium Credit
      </p>
      <EditPaymentMethod />
      <Card>
        <PayMonthlyForm disabled={disabled} />
      </Card>
    </>
  );
};

export default PayMonthlyPage;
