import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { Referral } from '@kanda-libs/ks-frontend-services';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { ORDER, SORTING, type Order, type Sorting } from './constants';
import {
  formatReferrals,
  sortReferrals,
  type EnhancedReferral,
} from './helpers';

interface ReferralsTableHook {
  showTable: boolean;
  data: EnhancedReferral[];
  isLoading: boolean;
  isValidating: boolean;
  pageIndex: number;
  totalPages: number;
  setPage: (index: number) => void;
  onAction: (action?: StringIndexedObject) => void;
  onRowClicked: () => void;
}

export default function useReferralsTable(): ReferralsTableHook {
  const [sorting, setSorting] = useState<Sorting>(SORTING.NAME);
  const [order, setOrder] = useState<Order>(ORDER.NORMAL);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [perPage] = useState<number>(10);

  const referrals = useSelector(selectors.getCompanyReferrals.getRawResponse);
  const hasFetched = useSelector(selectors.getCompanyReferrals.getHasFetched);
  const isSubmitting = useSelector(
    selectors.getCompanyReferrals.getIsSubmitting,
  );

  const data = useMemo(() => {
    if (!hasFetched || !referrals) return [];
    const refs = referrals as Referral[];
    if (refs.length === 0) return [];
    const sorted = sortReferrals(formatReferrals(refs), sorting, order);
    return sorted.slice(pageIndex * perPage, pageIndex * perPage + perPage);
  }, [referrals, hasFetched, order, sorting, pageIndex, perPage]);

  // Determine number of pages
  const totalPages = useMemo(() => {
    if (!hasFetched) return 1;
    return Math.ceil(data?.length / perPage);
  }, [data, perPage, hasFetched]);

  const setPage = useCallback((index: number) => setPageIndex(index), []);

  const showTable = useMemo(() => {
    if (!hasFetched) return true;
    if (hasFetched && data?.length === 0) return false;
    return true;
  }, [hasFetched, data]);

  /**
   * Handles table actions
   * @param {Object} action
   * @param {String} action.type
   * @param {*} action.payload
   */
  const onAction = useCallback(
    (action: StringIndexedObject = {}) => {
      const { type } = action;
      if (type === 'name-alphabetical') {
        if (sorting === SORTING.NAME) {
          if (order === ORDER.NORMAL) {
            setOrder(ORDER.INVERSE);
            return;
          }
          setOrder(ORDER.NORMAL);
          return;
        }
        setSorting(SORTING.NAME);
        setOrder(ORDER.NORMAL);
        return;
      }
      if (type === 'email-alphabetical') {
        if (sorting === SORTING.EMAIL) {
          if (order === ORDER.NORMAL) {
            setOrder(ORDER.INVERSE);
            return;
          }
          setOrder(ORDER.NORMAL);
          return;
        }
        setSorting(SORTING.EMAIL);
        setOrder(ORDER.NORMAL);
        return;
      }
      if (type === 'lifecycle-alphabetical') {
        if (sorting === SORTING.LIFECYCLE) {
          if (order === ORDER.NORMAL) {
            setOrder(ORDER.INVERSE);
            return;
          }
          setOrder(ORDER.NORMAL);
          return;
        }
        setSorting(SORTING.LIFECYCLE);
        setOrder(ORDER.NORMAL);
        return;
      }
      setSorting(SORTING.NAME);
      setOrder(ORDER.NORMAL);
    },
    [order, setOrder, sorting, setSorting],
  );

  return {
    showTable,
    data,
    isLoading: !hasFetched,
    isValidating: isSubmitting,
    pageIndex,
    totalPages,
    setPage,
    onAction,
    onRowClicked: () => {},
  };
}
