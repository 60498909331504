import { Button } from '@kanda-libs/ks-design-library';

import Container from './NextButton-container';

import { LABEL_WRAPPER_CLASS_NAME } from './NextButton-constants';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * disabled
   */
  disabled?: boolean;
}

const ViewComponent = ({
  children,
  disabled,
  ...restProps
}: ViewComponentProps) => (
  <Container {...restProps}>
    {({ labels, onClick, width }) => (
      <Button.Text
        id="onboarding-account-verification-next"
        onClick={onClick}
        disabled={disabled}
        icon="arrow-right"
        data-cy="nextButton"
      >
        <span style={{ width }} className={LABEL_WRAPPER_CLASS_NAME}>
          {labels.map(({ label, className }) => (
            <span key={label} className={className}>
              {label}
            </span>
          ))}
        </span>
      </Button.Text>
    )}
  </Container>
);

ViewComponent.displayName = 'Footer-view';

ViewComponent.defaultProps = { children: null, disabled: undefined };

export default ViewComponent;
