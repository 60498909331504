import { Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useAddressFields from './useAddressFields';

interface AddressFieldProps {
  companyType: Company['company_type'];
}

const AddressFields: React.FunctionComponent<AddressFieldProps> = function ({
  companyType,
}) {
  const {
    Fields,
    postcodeCallback,
    postcodeName,
    showSelect,
    addresses,
    selectProps,
    manual,
    addManualInput,
    labelPrefix,
  } = useAddressFields(companyType);
  return (
    <div className="flex flex-col">
      <Fields.Postcode
        callback={postcodeCallback}
        placeholder="Postcode"
        label={`${labelPrefix} Address Postcode`}
        autoComplete="none"
      />
      {showSelect && addresses && (
        <>
          <Field.Address.Select
            label="Select Address"
            postcodeName={postcodeName}
            data={addresses}
            {...selectProps}
          />
          {!manual && (
            <Button.Link
              id="setup-account-manually-enter-address"
              className="mr-auto mt-2"
              variant="light-grey"
              size={14}
              onClick={addManualInput}
              label="Manually enter address"
            />
          )}
          {manual && (
            <>
              <Fields.Line1
                placeholder="Company address line 1"
                label={`${labelPrefix} Address Line 1`}
              />
              <Fields.Line2
                placeholder="Company address line 2"
                label={`${labelPrefix} Address Line 2`}
              />
              <Fields.City
                placeholder="Company address city"
                label={`${labelPrefix} Address City`}
              />
              <Fields.County
                placeholder="Company address county"
                label={`${labelPrefix} Address County`}
              />
              <Fields.Country
                placeholder="Company address country"
                label={`${labelPrefix} Address Country`}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AddressFields;
