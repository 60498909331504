import type { ValidationItems } from '@kanda-libs/ks-component-ts';
import { useCallback } from 'react';

export default function useDateValidation(): ValidationItems {
  const parseDate = useCallback((date: string): Date | undefined => {
    const formatted = date?.replace(/ /g, '')?.split('/');
    if (formatted?.length !== 3) return undefined;
    const reordered = formatted.reverse().join('-');
    const parsed = new Date(reordered);
    if (
      (!Object.prototype.toString.call(parsed) as unknown as string) ===
      '[object Date]'
    )
      return undefined;
    if (Number.isNaN(parsed.getTime())) return undefined;
    return parsed;
  }, []);

  const validateDate = useCallback(
    (inputDate: string): boolean => {
      const date = parseDate(inputDate);
      return Boolean(date);
    },
    [parseDate],
  );

  const testOver18 = useCallback(
    (inputDate: string) => {
      const date = parseDate(inputDate);
      if (!date) return false;
      const min18 = new Date(
        new Date().setFullYear(new Date().getFullYear() - 18),
      );
      const age = min18.getTime() - date.getTime();
      return age >= 0;
    },
    [parseDate],
  );

  return {
    validate: {
      value: {
        valid: (value: any) => validateDate(value),
        moreThan18: (value: any) => testOver18(value),
      },
      message: {
        valid: 'You must enter a valid date of birth',
        moreThan18: 'You must be over 18 years of age',
      },
    },
  };
}
