import { Form as KandaForm, Widget } from '@kanda-libs/ks-component-ts';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import {
  AddUserButton,
  Users,
} from 'pages/MyAccount/TeamSettings/components/Content/components';
import type { FunctionComponent } from 'react';
import Layout from '../Layout';
import useInviteForm from './useInviteForm';

const Form: FunctionComponent = function () {
  const { form, onSubmit, isSubmitting, defaultValues } = useInviteForm();
  // TODO
  const canUserEdit = true;

  return (
    <Layout>
      <div className="w-full">
        <KandaForm form={form} onSubmit={onSubmit} id="welcome-invite-team">
          <Widget.CompanyUsersArrayWrapper>
            {({ arrayName, fields, arrayProps: { append, ...rest } }) => (
              <>
                <Users
                  prefillNewUser
                  fields={fields}
                  arrayName={arrayName}
                  defaultValues={defaultValues}
                  canUserEdit={canUserEdit}
                  isSubmitting={isSubmitting}
                  append={append}
                  {...rest}
                />
                {canUserEdit && (
                  <AddUserButton
                    fields={fields as unknown as UserType[]}
                    append={append}
                  />
                )}
              </>
            )}
          </Widget.CompanyUsersArrayWrapper>
        </KandaForm>
      </div>
    </Layout>
  );
};

export default Form;
