import type { FunctionComponent } from 'react';

import { Button, Text, useIsDesktop } from '@kanda-libs/ks-design-library';

import { MARKETING_RESOURCES_LINK } from 'components/DesktopHeader/DesktopHeader-constants';
import useCompleteWelcomePackSection from 'hooks/useCompleteWelcomePackSection';
import socialMediaAdvertsImage from '../../../assets/social-media-adverts.png';

const MarketingMaterials: FunctionComponent = function () {
  const { isSubmitting, onFinish } = useCompleteWelcomePackSection(
    'advert_finance_calculator',
  );

  const isDesktop = useIsDesktop();

  return (
    <>
      <div className="w-full grid md:grid-cols-2 gap-x-8">
        <div className="flex flex-row items-center justify-center ">
          <img
            src={socialMediaAdvertsImage}
            alt="Get more marketing materials"
            className="md:max-w-[400px] w-auto"
          />
        </div>
        <div className="flex flex-col justify-center text-left md:w-72">
          <Text
            text="Get more marketing materials"
            className="mt-4 mb-6 text-24-28-em md:mt-0"
          />
          <Text
            text="Click below to see a range of marketing materials to help you advertise finance"
            className="mb-6 text-neutral-600 text-16-24"
          />
          <div>
            <a
              href={MARKETING_RESOURCES_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button.Text
                id="dashboard-example-job"
                label="Marketing materials"
                icon="arrow-right"
                className="w-full mb-2 md:w-auto"
                size={isDesktop ? 40 : undefined}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 md:w-auto md:mt-8">
        <Button.Text
          left
          id="update-your-website-finish"
          label="Finish"
          icon="check"
          variant="outline"
          className="w-full md:w-auto"
          disabled={isSubmitting}
          onClick={onFinish}
        />
      </div>
    </>
  );
};

export default MarketingMaterials;
