import { Button } from '@kanda-libs/ks-design-library';

import { RESET_PASSWORD_BUTTON, CLASS_NAMES } from './Footer-constants';

interface ViewComponentProps {
  /**
   * Disabled
   */
  disabled?: boolean;
}

const ViewComponent = ({ disabled }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <Button.Text {...RESET_PASSWORD_BUTTON} disabled={disabled} />
  </div>
);

ViewComponent.defaultProps = {
  disabled: undefined,
};

ViewComponent.displayName = 'ResetPassword-Landing-Footer-view';

export default ViewComponent;
