import { Widget } from '@kanda-libs/ks-component-ts';
import { VAT_COMPONENT_PROPS, OPTIONS } from './VAT-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = (props: ViewComponentProps) => (
  <div className="flex mt-auto mb-0.5 mr-2">
    <Widget.JobJobItemsVatRateArraySelect
      {...VAT_COMPONENT_PROPS}
      {...props}
      options={OPTIONS}
    />
  </div>
);

ViewComponent.displayName = 'Item-VAT-view';

export default ViewComponent;
