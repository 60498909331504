import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../components';

const ViewComponent = () => (
  <>
    <Helmet title="Veriff" />
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </>
);

ViewComponent.displayName = 'Veriff-view';

export default ViewComponent;
