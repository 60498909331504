import { Icon } from '@kanda-libs/ks-design-library';

import { ICON_PROPS, CLASS_NAMES } from '../Confirmation-constants';

interface ViewComponentProps {
  /**
   * Title
   */
  title?: string;
  /**
   * Customer's email
   */
  email?: string;
}

// import CopyLinkButton from '../../CopyLinkButton';

const ViewComponent = ({ title, email }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <Icon {...ICON_PROPS} />
    <p className={CLASS_NAMES.heading}>{title}</p>
    <p className={CLASS_NAMES.subHeading}>{email}</p>
  </div>
);

// <div className={CLASS_NAMES.buttonContainer}>
//   <CopyLinkButton />
// </div>

ViewComponent.defaultProps = {
  title: undefined,
  email: undefined,
};

ViewComponent.displayName =
  'CreateJob-SendConfirmation-Confirmation-Desktop-view';

export default ViewComponent;
