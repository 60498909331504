import { Icon, Text } from '@kanda-libs/ks-design-library';

const BulletPointUSP = () => (
  <div className="flex flex-row px-5 py-4 border border-neutral-300 rounded-xl shadow-card mb-10">
    <Icon
      icon="favourite"
      size={24}
      stroke="lavender-200"
      className="hidden md:block mt-3 mr-5"
    />
    <div className="flex flex-col text-left ">
      <Text
        text="Kanda Premium"
        className="text-14-22-em text-neutral-600 mb-1"
      />
      <ul className="text-14-22 list-inside list-disc ml-2 ">
        <li>Send unlimited Jobs</li>
        <li>Offer 0% finance</li>
        <li>Offer 15.9% APR</li>
      </ul>
    </div>
  </div>
);

BulletPointUSP.propTypes = {};

export default BulletPointUSP;
