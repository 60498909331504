import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useContext } from 'react';

import Context from '../../Context';

export interface ContainerChildrenArgs {
  description: string;
  isLoading: boolean;
  classNames: StringIndexedObject;
}

export interface ContainerProps {
  children: (args: ContainerChildrenArgs) => JSX.Element;
}

// eslint-disable-next-line
const ContainerComponent = ({ children }: ContainerProps) => {
  const {
    isLoading = false,
    description,
    classNames = {},
  } = useContext(Context);

  if (!description && !isLoading) return null;

  return children({ description: description || '', isLoading, classNames });
};

ContainerComponent.displayName = 'LineItem-Description-container';

export default ContainerComponent;
