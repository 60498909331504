import Container from './Customer-container';
import FormattedCustomer from './FormattedCustomer';
import {
  CLASS_NAMES,
  NO_CUSTOMER_TEXT,
  DESCRIPTION,
} from './Customer-constants';

import Wrapper from '../Wrapper';

const ViewComponent = () => (
  <Container>
    {({ customer, onClick }) => (
      <Wrapper description={DESCRIPTION} onEdit={onClick}>
        {customer ? (
          <FormattedCustomer customer={customer} />
        ) : (
          <p className={CLASS_NAMES.heading}>{NO_CUSTOMER_TEXT}</p>
        )}
      </Wrapper>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Customer-view';

export default ViewComponent;
