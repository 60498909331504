import { useFormContext } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import useCurrentCompany from '../../../../../../hooks/useCurrentCompany';

export default function useReferralCode() {
  const dispatch = useAppDispatch();
  const { getValues, setError, clearErrors } = useFormContext();

  const { company, isValidating } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess, showError } = useToast();
  const onError = useApiError(
    'Error adding referral code at this time - please try again later',
  );

  const onApplyClick = useCallback(() => {
    const code = getValues('company.referral_code');
    if (!code)
      setError('company.referral_code', {
        type: 'custom',
        message: 'No referral code has been entered',
      });
    const { id } = company || {};
    const body = {
      ...company,
      referral_code: code,
    };

    dispatch(
      actions.putCompany({
        body: body as unknown as {
          company_id: string;
          company_type: 'limited_company' | 'sole_trader';
        },
        params: { id: id as string },
        onError,
        onSuccess: (newCompany) => {
          const invalidCode = !newCompany?.referral_code;
          if (invalidCode) {
            showError(
              'Invalid referral code provided - please check your code is correct or try again later',
            );
            return;
          }
          showSuccess('Referral code applied!');
        },
      }),
    );
  }, [getValues, setError, company, dispatch, showError, onError, showSuccess]);

  const onBlur = useCallback(
    () => clearErrors('company.referral_code'),
    [clearErrors],
  );

  return {
    onApplyClick,
    onBlur,
    isSubmitting: isSubmitting || isValidating,
  };
}
