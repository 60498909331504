import { Details, Document } from '../components';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
  refreshJobDetails?: () => void;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <div className="flex flex-row mt-6">
    <Details {...props} />
    <Document {...props} />
  </div>
);

ViewComponent.displayName = 'Createjob-Quote-Custom-Item-Desktop-view';

export default ViewComponent;
