import { Link } from 'react-router-dom';

const ContainerComponent = ({ children, type }) => {
  const Tag = type === 'link' ? Link : 'button';

  return children({
    Tag,
  });
};

ContainerComponent.displayName =
  'MyAccount-AccountSettings-MobileMenu-Card-container';

export default ContainerComponent;
