import { Icon, Text, TextSlides } from '@kanda-libs/ks-design-library';
import {
  CLASS_NAMES,
  TEXT_SLIDES,
  TEXT_SLIDES_AUTO_ROTATING_TIME,
} from './constants';

const ViewComponent = () => (
  <div className={CLASS_NAMES.container}>
    <Icon icon="alarm-color" size={64} className={CLASS_NAMES.icon} />
    <Text
      text="We're just confirming your direct debit mandate with your bank"
      className={CLASS_NAMES.heading}
    />
    <Text
      text="Once confirmed, this page will automatically redirect"
      className={CLASS_NAMES.subHeading}
    />
    <div className={CLASS_NAMES.textSlideContainer}>
      <TextSlides autoRotateTime={TEXT_SLIDES_AUTO_ROTATING_TIME}>
        {TEXT_SLIDES.map((text) => (
          <Text key={text} text={text} className={CLASS_NAMES.textSlide} />
        ))}
      </TextSlides>
    </div>
  </div>
);

ViewComponent.displayName = 'SubscriptionReturn-Success-SplashScreen-view';

export default ViewComponent;
