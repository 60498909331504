import { formatDate, checkAndFormatTimestamp } from '@kanda-utils/library';
import type { Payment } from '@kanda-libs/ks-frontend-services';
import type { PaymentProps } from './Payment-view';

const daysFromMilliseconds = (milliseconds) =>
  milliseconds / (1000 * 60 * 60 * 24);

const getDueText = (status, date) => {
  if (status === 'paid')
    return `Paid on ${formatDate(checkAndFormatTimestamp(date))}`;
  const difference =
    (new Date() as unknown as number) - (new Date(date) as unknown as number);
  const days = daysFromMilliseconds(difference);
  if (days <= -1) return `Due in ${Math.ceil(-1 * days)} days`;
  if (days > -1 && days <= 0) return 'Due tomorrow';
  if (days > 0 && days <= 1) return 'Due today';
  if (days > 1 && days <= 2) return 'Overdue yesterday';
  return `Overdue by ${Math.floor(days)} days`;
};

export interface ContainerComponentChildrenArgs extends Payment {
  color: string;
  dueText: string;
}

export interface ContainerComponentProps extends PaymentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children, payment }) => {
  const color = payment?.status === 'unpaid' ? 'violet' : 'green';

  const dueText = getDueText(payment?.status, payment?.due);

  return children({
    ...payment,
    color,
    dueText,
  });
};

ContainerComponent.displayName =
  'Subscription-Content-History-Payments-Payment-container';

export default ContainerComponent;
