export const PASSWORD_FIELD_PROPS = {
  name: 'password',
  label: 'Password',
  placeholder: 'Your password',
  autoComplete: 'new-password',
};

export const CONFIRM_PASSWORD_FIELD_PROPS = {
  name: 'confirm_password',
  label: 'Confirm Password',
  placeholder: 'Confirm your password',
  autoComplete: 'new-password',
};
