import { Menu, SkeletonLoader } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import UpgradeBanner from './components/UpgradeBanner';
import { SKELETONS } from './Menu-constants';
import Container from './Menu-container';

const ViewComponent = ({}) => (
  <Container>
    {({ isLoading, menuLinks }) => (
      <>
        <Menu
          linkComponent={Link}
          items={menuLinks}
          hideHelp
          companyName={
            <SkeletonLoader
              isLoading={isLoading}
              {...SKELETONS.companyName}
              afterLoading="My Kanda"
            />
          }
          footer={<UpgradeBanner />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Menu-view';

export default ViewComponent;
