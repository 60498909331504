import { useState, useCallback, useRef } from 'react';

export interface CollapsedHook {
  collapsed: boolean;
  toggleCollapsed: () => void;
  removeCollapse: () => void;
  setInitialCollapse: () => void;
}

/**
 * Keeps collapsed logic
 */
const useCollapsed = (): CollapsedHook => {
  const [collapsed, setCollapsed] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const toggleCollapsed = useCallback(() => {
    if (disabled) return;
    setCollapsed(!collapsed);
  }, [collapsed, disabled]);

  const removeCollapse = useRef(() => {
    setDisabled(true);
    setCollapsed(false);
  });

  const setInitialCollapse = useRef(() => {
    setCollapsed(false);
  });

  return {
    collapsed,
    toggleCollapsed,
    removeCollapse: removeCollapse.current,
    setInitialCollapse: setInitialCollapse.current,
  };
};

export default useCollapsed;
