import { useMemo } from 'react';
import type { DirectorCardProps } from './DirectorCard-view';

export interface ContainerComponentChildrenArgs {
  name: string | null;
  verified: boolean;
  email: string | null;
}

export interface ContainerComponentProps extends DirectorCardProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children, user }: ContainerComponentProps) => {
  const { name, verified, email } = useMemo(() => {
    if (!user)
      return {
        name: null,
        verified: false,
        email: null,
      };
    return {
      name: [user?.first_name, user?.last_name]
        .filter((namePart) => namePart)
        .join(' '),
      verified: user?.director_info?.verification_status === 'verified',
      email: user?.email,
    };
  }, [user]);

  return children({
    name,
    verified,
    email,
  });
};

ContainerComponent.displayName =
  'DirectorVerification-Content-DirectorCard-container';

export default ContainerComponent;
