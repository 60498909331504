import { Widget } from '@kanda-libs/ks-component-ts';

import SoleTraderForm from './SoleTraderForm';

const ViewComponent = ({ ...props }) => (
  <Widget.CompanyUsersArrayWrapper>
    {({ arrayName, fields }) =>
      fields.map((field, index) => (
        <SoleTraderForm
          key={field.id}
          arrayName={arrayName}
          {...field}
          index={index >= 0 ? index : field.index}
          {...props}
        />
      ))
    }
  </Widget.CompanyUsersArrayWrapper>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-Form-Sole-view';

export default ViewComponent;
