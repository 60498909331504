import {
  MultiStepForm,
  MultiStepFormRouter,
} from '@kanda-libs/ks-component-ts';

import { Pages } from './components';
import Container from './Desktop-container';

interface ViewComponentProps {
  /**
   * initialData
   */
  initialData?: object;
}

const ViewComponent = ({ initialData }: ViewComponentProps) => (
  <Container>
    {({ steps, onSubmit }) => (
      <MultiStepForm
        steps={steps}
        initialData={initialData}
        initialStepIndex={0}
        onSubmit={onSubmit}
        addStepsToUrl={false}
      >
        <MultiStepFormRouter pages={Pages} />
      </MultiStepForm>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  initialData: {},
};

ViewComponent.displayName = 'CreateJob-Desktop-view';

export default ViewComponent;
