import type { FunctionComponent } from 'react';

export interface BannerProps {
  children: string | JSX.Element | JSX.Element[];
}

const Banner: FunctionComponent<BannerProps> = function ({ children }) {
  return (
    <div className="p-4 mb-4 border bg-lavender-100 border-lavender-200 rounded-xl text-lavender-200">
      {children}
    </div>
  );
};

export default Banner;
