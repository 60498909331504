import { type FunctionComponent } from 'react';
import { formatToCurrency, formatToPercentage } from 'utils';
import { BreakdownPieChart, NumberCard } from '..';
import { useHomeContext } from '../../context';

const DashboardContent: FunctionComponent = function () {
  const {
    jobsSent,
    jobsApplied,
    jobsApproved,
    revenue,
    aov,
    approvalRate,
    isLoading,
  } = useHomeContext();

  return (
    <div className="flex flex-col w-full max-w-120 md:max-w-none mx-auto">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-3 gap-y-4 md:gap-x-6 md:gap-y-8 mb-4 md:mb-8">
        <NumberCard title="Jobs Sent" number={jobsSent} isLoading={isLoading} />
        <NumberCard
          title="Finance Apps"
          number={jobsApplied}
          isLoading={isLoading}
        />
        <NumberCard
          title="Approved Apps"
          number={jobsApproved}
          isLoading={isLoading}
        />
        <NumberCard
          title="Finance Revenue"
          formatter={formatToCurrency}
          number={revenue}
          isLoading={isLoading}
        />
        <NumberCard
          title="Avg. Order Value"
          formatter={formatToCurrency}
          number={aov}
          isLoading={isLoading}
        />
        <NumberCard
          title="Approval Rate"
          formatter={formatToPercentage}
          number={approvalRate}
          isLoading={isLoading}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8 ">
        <BreakdownPieChart title="Finance Applications Breakdown" />
      </div>
    </div>
  );
};

export default DashboardContent;
