import { Button } from '@kanda-libs/ks-design-library';

import type { BASE_ITEM } from 'pages/CreateJob/CreateJob-constants';
import Container from './AddItemButton-container';
import { BUTTON_PROPS } from './AddItemButton-constants';

export interface AddItemButtonProps {
  arrayName: string;
  append: (value: typeof BASE_ITEM) => void;
}

const ViewComponent = (props: AddItemButtonProps) => (
  <Container {...props}>
    {({ handleAddItem, isLoading }) => (
      <Button.Link
        isLoading={isLoading}
        onClick={handleAddItem}
        {...BUTTON_PROPS}
        size={BUTTON_PROPS.size as 14 | 16}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Items-view';

export default ViewComponent;
