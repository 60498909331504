import type { Company } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import useIsSubscribed from '../../../../../hooks/useIsSubscribed';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  showForm: boolean;
  company: Company | null;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { company, isLoading } = useCurrentCompany();

  const { isSubscribed } = useIsSubscribed();

  return children({
    isLoading,
    showForm: !isSubscribed,
    company,
  });
};

ContainerComponent.displayName = 'Subscription-Content-container';

export default ContainerComponent;
