import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';

export const COPY_BUTTON_PROPS = {
  id: 'send-confirmation-copy-button',
  icon: 'copy',
  variant: 'grey' as ButtonLinkVariant,
  size: 14,
  left: true,
  label: 'Copy Message',
};

export const COPIED_BUTTON_PROPS = {
  id: 'send-confirmation-copied-button',
  icon: 'check',
  variant: 'turquoise' as ButtonLinkVariant,
  size: 14,
  left: true,
  label: 'Message Copied',
};
