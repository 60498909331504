import { BreakPoints } from '@kanda-libs/ks-design-library';
import Desktop from './Desktop';
import Mobile from './Mobile';

const DepositOptions = ({ ...props }) => (
  <BreakPoints
    desktop={<Desktop {...props} />}
    mobile={<Mobile {...props} />}
  />
);

export default DepositOptions;
