import { useMemo } from 'react';

interface CompanyFormHeaderProps {
  companyType: string;
}

const CompanyFormHeader: React.FunctionComponent<CompanyFormHeaderProps> =
  function ({ companyType }) {
    const heading = useMemo(() => {
      if (companyType === 'limited_company') return 'Your company details';
      return 'Your company details';
    }, [companyType]);
    return <p className="text-18-22-em mb-4">{heading}</p>;
  };

export default CompanyFormHeader;
