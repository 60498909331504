import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import SignUpFormCard from '../Card';
import EditSignUpMethod from '../EditSignUpMethod';
import Fields from './Fields';
import useSignUpFormFields from './useSignUpFormFields';

const SignUpFormFields: React.FunctionComponent = function () {
  const { isSubmitting, disabled } = useSignUpFormFields();
  return (
    <>
      <EditSignUpMethod />
      <SignUpFormCard>
        <p className="text-18-22-em mb-4">Your details</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
          <Fields.FirstName />
          <Fields.LastName />
          <Fields.Phone />
          <Fields.Email />
        </div>
        <div className="flex flex-col mt-auto pt-3">
          <div className="border-b border-neutral-300 mb-8" />
          <Fields.TermsAndConditions />
          <BreakPoints
            desktop={
              <Button.Text
                id="sign-up-submit"
                submit
                className="w-full mt-1"
                label="Sign Up"
                icon="arrow-right"
                variant="gradient"
                disabled={disabled || isSubmitting}
              />
            }
          />
        </div>
      </SignUpFormCard>
    </>
  );
};

export default SignUpFormFields;
