import { Icon, Button } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES, BUTTON, ICON_PROPS } from './Desktop-constants';

interface ViewComponentProps {
  /**
   * Title
   */
  title: string;
  /**
   * Label
   */
  label: string;
  /**
   * On click function
   */
  onClick(...args: unknown[]): unknown;
  /**
   * icon
   */
  icon: string;
}

const ViewComponent = ({ title, label, onClick, icon }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <Icon {...ICON_PROPS} icon={icon} />
    <div className={CLASS_NAMES.info}>
      <p className={CLASS_NAMES.title}>{title}</p>
      <p className={CLASS_NAMES.label}>{label}</p>
    </div>
    <Button.Icon {...BUTTON} onClick={onClick} />
  </div>
);

ViewComponent.displayName = 'Filter-Mobile-view';

export default ViewComponent;
