import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './Contact-container';

const ViewComponent = () => (
  <Container>
    {({ email, phone, onBlur }) => (
      <div className="flex flex-col">
        <Widget.JobCustomerEmail
          placeholder="add an email"
          validation={email}
          onBlur={() => onBlur('job.customer.email')}
        />
        <div className="mb-3" />
        <Widget.JobCustomerPhone
          placeholder="add a phone number"
          validation={phone}
          onBlur={() => onBlur('job.customer.phone')}
        />
        <div className="mb-3" />
      </div>
    )}
  </Container>
);
ViewComponent.displayName = 'CreateJob-Desktop-Customer-Field-Contact-view';

export default ViewComponent;
