import { Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import { EmailConfirmed, FormFields, Layout } from './components';
import useClaimAccountForm from './useClaimAccountForm';

const ClaimAccount: React.FunctionComponent = function () {
  const { form, onSubmit, isSubmitting, isLoading, disabled } =
    useClaimAccountForm();

  return (
    <Form id="sign-up" form={form} onSubmit={onSubmit}>
      <FormTheme variant="streamline">
        <Layout
          mobileFooter={
            <Button.Text
              id="sign-up-submit"
              submit
              className="w-full"
              label="Save Password"
              icon="arrow-right"
              variant="gradient"
              disabled={isSubmitting || disabled}
            />
          }
        >
          <Helmet title="Claim Account" />
          <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
            Claim your Kanda account
          </p>
          <EmailConfirmed />
          <FormFields
            isSubmitting={isSubmitting}
            isLoading={isLoading}
            disabled={disabled}
          />
          <Loader isLoading={isSubmitting} />
        </Layout>
      </FormTheme>
    </Form>
  );
};

export default ClaimAccount;
