import { Field } from '@kanda-libs/ks-component-ts';
import { ValidateEmail } from 'components';
import { EMAIL_PROPS } from './constants';
import useSignUpFormFieldEmail from './useSignUpFormFieldEmail';

const SignUpFormFieldEmail: React.FunctionComponent = function () {
  const { onBlur, validation, disabled } = useSignUpFormFieldEmail();
  return (
    <ValidateEmail iconClassName="mb-auto mt-9 ml-4">
      {({ suggestion }) => (
        <Field.Validator validation={validation}>
          <Field.Input
            {...EMAIL_PROPS}
            onBlur={onBlur}
            warning={suggestion}
            disabled={disabled}
          />
        </Field.Validator>
      )}
    </ValidateEmail>
  );
};

export default SignUpFormFieldEmail;
