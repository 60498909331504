export const CLASS_NAMES = {
  wrapper: 'flex flex-row items-center',
  firstItem: 'flex-grow',
  otherItems: 'min-w-24',
  label: 'text-11-20-em-up text-neutral-600',
};

export const FROM_LABEL = 'From';

export const COST_LABEL = 'Cost';
