export const CLASS_NAMES = {
  content: 'flex flex-col items-center p-10 text-center',
  title: 'text-28-32-em text-neutral-700 mb-6',
  description: 'text-16-24 text-neutral-600 mb-8 w-80',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'user',
  size: 64,
};

export const TITLE = 'Any users you invite will be shown here';

export const BUTTON_PROPS = {
  id: 'home-empty-desktop-create-job',
  label: 'Refer A User',
  icon: 'plus',
  size: 40,
  className: 'mt-4',
};
