import type { SelectOption } from '@kanda-libs/ks-component-ts';
import type { Company, WorkType } from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import { capitalise, filterForRatesByWorkType } from 'utils';
import type { WorkTypeOption } from './constants';

const mapValuesAndNamesToSelectOptions = (
  options: WorkTypeOption[],
): SelectOption[] =>
  options.map((option: WorkTypeOption) => ({
    value: option.value,
    name: option?.name || option.value.split('_').map(capitalise).join(' '),
  }));

export const formatSelectOptions = (
  options: WorkTypeOption[],
): SelectOption[] => pipe(options, mapValuesAndNamesToSelectOptions);

export const checkWorkTypeNoRates = (
  company: Company,
  workType: WorkType,
): boolean => {
  const rates = company?.finance_rates;
  if (!rates) return false;
  const valid = filterForRatesByWorkType(rates, workType);
  return valid.length === 0;
};
