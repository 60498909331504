// TODO: Refactor so this maps to actual statuses instead
export const STATUSES = ['draft', 'accepted', 'declined', 'financed', 'paid'];

export const FINANCE_STATUSES = [
  'not_applied',
  'under_review',
  'applied_for_finance',
  'finance_not_approved',
  'financed',
  'sat_note_sent',
  'sat_note_viewed',
  'sat_note_signed',
  'payout_pending',
  'paid',
  'finance_cancelled',
];

export const CLASS_NAMES = {
  wrapper: 'p-2 min-w-60',
  item: 'w-full',
};
