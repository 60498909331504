export const formatCustomers = (customers) => {
  if (!customers || customers?.length === 0) return [];
  return customers.map((customer) => ({
    name: `${customer?.first_name} ${customer?.last_name}`,
    address: `${customer?.address?.line_1}, ${customer?.address?.city}, ${customer?.address?.postcode}`,
    email: customer?.email,
    phoneNumber: customer?.phone,
    raw: customer,
  }));
};
