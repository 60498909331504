import { useForm } from '@kanda-libs/ks-component-ts';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany, useMe } from 'hooks';
import useApiError from 'hooks/useApiError';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { cleanObject } from 'utils';

export default function useOnlinePresence() {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);
  const form = useForm({
    mode: 'onBlur',
  });

  const { nextStep } = useOnboardingContextValue();
  const { revalidateMe } = useMe();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const { company } = useCurrentCompany();

  const isLoading = !company;

  const onSubmit = useCallback(
    (submittedData) => {
      if (!company) return;
      const { id } = company;
      if (!id) return;

      const body = cleanObject({
        ...company,
        company_info: {
          ...company?.company_info,
          ...submittedData.company.company_info,
        },
      });

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            revalidateMe();
            nextStep();
          },
        }),
      );
    },
    [company, nextStep, dispatch, revalidateMe, onError],
  );

  return {
    form,
    onSubmit,
    isLoading,
    isSubmitting,
  };
}
