import { Icon, Text, BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './EPVSFile-container';
import { DESCRIPTION } from './EPVSFile-constants';
import Wrapper from '../Wrapper';

import { ReactComponent as JobPDF } from '../../../../../../../assets/job-pdf.svg';

const ViewComponent = () => (
  <Container>
    {({ onClick, fileName }) => (
      <Wrapper description={DESCRIPTION} onEdit={onClick}>
        <div className="flex flex-1 flex-col">
          <BreakPoints
            mobile={
              <Text
                text="EPVS Calculations"
                className="text-style-g-em text-neutral-800 mb-3"
              />
            }
          />
          {fileName ? (
            <div className="flex-1 flex flex-col bg-neutral-100 border border-neutral-200 rounded-xl overflow-hidden -mr-8">
              <div className="p-3 h-38">
                <JobPDF className="mx-auto" />
              </div>
              <div className="flex flex-row bg-neutral-000 border-t border-neutral-200 px-3 py-3.5">
                <Icon
                  icon="file"
                  stroke="green-300"
                  className="mr-3 my-auto"
                  size={20}
                />
                <Text
                  text={fileName}
                  className="text-14-22 text-neutral-700 my-auto"
                />
              </div>
            </div>
          ) : (
            <Text
              text="No EPVS Calculations file added..."
              className="text-style-g text-neutral-600 mb-3"
            />
          )}
        </div>
      </Wrapper>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-EPVSFile-view';

export default ViewComponent;
