import { Button } from '@kanda-libs/ks-design-library';
import SplashScreen from '../../../../../components/SplashScreen';

interface SubscriptionErrorSplashScreenProps {
  onClickMessageUs?: () => void;
  onRetryClick: () => void;
}

const SubscriptionErrorSplashScreen = ({
  onClickMessageUs = () => {},
  onRetryClick,
}: SubscriptionErrorSplashScreenProps) => (
  <SplashScreen
    icon="error"
    iconClassName="text-red-200"
    title="Something went wrong"
    subText={
      <>
        There was an issue setting up your direct debit.{' '}
        <button
          type="button"
          className="font-bold underline cursor-pointer"
          onClick={() => {
            onClickMessageUs();
          }}
        >
          Click here to message us.
        </button>
      </>
    }
    cta={
      <Button.Text
        id="subscription-error-retry"
        onClick={onRetryClick}
        label="Try again"
        icon="arrow-right"
      />
    }
  />
);

export default SubscriptionErrorSplashScreen;
