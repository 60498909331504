import Container from './Body-container';
import { CLASS_NAMES } from './Body-constants';
import { Confirmation } from './components';

const ViewComponent = () => (
  <Container>
    {({ name, email, title }) => (
      <div className={CLASS_NAMES.body}>
        <Confirmation name={name} email={email} title={title} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-Body-view';

export default ViewComponent;
