import {
  useFormContext,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';
import type { UserType } from '@kanda-libs/ks-frontend-services';

import { useCurrentCompany } from 'hooks';
import { useCallback } from 'react';
import { BASE_PHONE_VALIDATION } from './New-constants';

interface MobileValidationHook {
  validation: ValidationItems;
}

export function useMobileValidation(): MobileValidationHook {
  const { getValues } = useFormContext();
  const { company } = useCurrentCompany();

  const getErrorMessage = useCallback(() => {
    const users = getValues('company.users');
    if (!users || users?.length === 0) return '';
    const index = users.length - 1;
    const number = users[index].mobile;
    const userInCompany = company?.users?.filter(
      (user: UserType) => user?.mobile === number,
    )?.[0];
    if (!useCurrentCompany)
      return 'This number is currently in use by another team member - please enter a different number';
    const isDirector = Boolean(userInCompany?.director_info);
    const name = [userInCompany?.first_name, userInCompany?.last_name]
      .filter(Boolean)
      .join(' ');
    const email = userInCompany?.email;
    return `This number is currently in use by another team member - ${`${
      isDirector ? 'Director: ' : ''
    } ${name ? `${name}, ` : ''}${email}`} - please enter a different number`;
  }, [company, getValues]);

  return {
    validation: {
      ...BASE_PHONE_VALIDATION,
      validate: {
        value: (value?: string | number | boolean): boolean => {
          if (typeof value !== 'string' || !company) return false;
          const users = company?.users;
          if (!users) return true;
          const numbers = users
            .map((user: UserType) => user?.mobile)
            .filter(Boolean) as string[];
          return !numbers.includes(value);
        },
        message: getErrorMessage(),
      },
    },
  };
}
