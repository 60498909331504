import useCurrentJob from '../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { isLoading } = useCurrentJob();

  return children({
    isLoading,
  });
};

ContainerComponent.displayName = 'Job-Items-Desktop-container';

export default ContainerComponent;
