export const FIELD = {
  name: 'desktop-search',
  icon: 'search',
  iconVariant: 'search',
  iconColor: 'text-turquoise-500',
  placeholder: 'Search for customer, price, description...',
};

export const BUTTON = {
  id: 'home-banner-desktop-search',
  icon: 'search',
  className: 'flex-none ml-1.5',
};

export const DEBOUNCE = 500;
