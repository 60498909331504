import JobCreateInfoSidebar from 'components/JobCreateInfoSidebar';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { TITLE, DESCRIPTION } from './Info-constants';

const ViewComponent = ({}) => {
  const {
    data: { isLoading },
  } = useCreateJobContextValue();

  return (
    <JobCreateInfoSidebar
      hasBottomMargin
      title={TITLE}
      description={DESCRIPTION}
      isLoading={isLoading}
    />
  );
};

ViewComponent.displayName = 'CreateJob-Desktop-Deposit-Info-view';

export default ViewComponent;
