import { useCallback, useContext } from 'react';
import { useForm } from '@kanda-libs/ks-component-ts';
import {
  ModalsWrapperContext,
  StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import type { Job } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';

import { BASE_BUTTON_PROPS, MODAL_ID } from './Summary-constants';

import useSubmitJob from '../../../../CreateJob-useSubmitJob';
import useSubmitDraft from '../../../../CreateJob-useSubmitDraft';
import useSaveDraft from '../../../../CreateJob-useSaveDraft';
import useIsSubscribed from '../../../../../../hooks/useIsSubscribed';

const ContainerComponent = ({ children }) => {
  const { data, setData } = useCreateJobContextValue();
  const { showModal } = useContext(ModalsWrapperContext);
  const isExampleJob = useIsExampleJob();

  const company = data?.company;
  const showSubmit = isExampleJob || company?.lifecycle === 'verified';

  const { handleSubmit: submitJob, isSubmitting: jobIsSubmitting } =
    useSubmitJob();
  const { handleSubmit: submitDraft, isSubmitting: draftIsSubmitting } =
    useSubmitDraft();
  const { isSubmitting: draftIsSaving } = useSaveDraft();

  const isSubmitting = jobIsSubmitting || draftIsSubmitting || draftIsSaving;

  const { isSubscribed } = useIsSubscribed();

  const showFinishSetUpModal = useCallback(() => {
    showModal(MODAL_ID);
  }, [showModal]);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      job: {
        notes: [''],
      },
    },
  });

  const handleSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      if (!showSubmit) return;
      // DEV_NOTE: When using dot notation, TS throws an error of `job` not
      // existing on type `never` - I can't figure for the life of me why
      // it's throwing this error, but this works - Rob
      // eslint-disable-next-line
      const notes = formValues?.['job']?.['notes'] || null;
      const formData = {
        ...data,
        job: {
          ...(data.job as Job),
          ...(notes ? { notes } : {}),
        },
      };
      setData(formData);
      const handler = data?.job?.id ? submitDraft : submitJob;
      handler(formData);
    },
    [setData, data, submitDraft, submitJob, showSubmit],
  );

  return children({
    isSubmitting,
    nextButtonProps: {
      ...BASE_BUTTON_PROPS,
      disabled: isSubmitting,
      ...(!isSubscribed ? { onClick: showFinishSetUpModal } : { submit: true }),
      className: showSubmit ? '' : 'hidden',
    },
    form,
    onSubmit: handleSubmit,
  });
};

ContainerComponent.displayName = 'CreateJob-Summary-Mobile-container';

export default ContainerComponent;
