import { MultiStepFormFooter } from '@kanda-libs/ks-component-ts';

import { NextButton } from '../../../components';

interface ViewComponentProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
}

const ViewComponent = ({ isLoading }: ViewComponentProps) => (
  <div className="w-full">
    <MultiStepFormFooter nextButton={<NextButton disabled={isLoading} />} />
  </div>
);

ViewComponent.displayName = 'Footer-view';

ViewComponent.defaultProps = { isLoading: undefined };

export default ViewComponent;
