import { Text } from '@kanda-libs/ks-design-library';

import Container from './Title-container';
import { CLASS_NAME, SKELETONS } from './Title-constants';

const ViewComponent = () => (
  <Container>
    {({ isLoading, label }) => (
      <Text
        className={CLASS_NAME}
        isLoading={isLoading}
        skeletonProps={SKELETONS.title}
        text={label}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Title-view';

export default ViewComponent;
