import Desktop from './Desktop';

export interface RolePopoverProps {
  id: string;
  permissions: boolean[];
  role: string;
}

const ViewComponent = (props: RolePopoverProps) => (
  <div className="relative h-10 w-10 my-auto mr-12">
    <Desktop {...props} />
  </div>
);

ViewComponent.displayName = 'New-Role-Popover-view';

export default ViewComponent;
