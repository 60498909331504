import { Form, Confirmation } from '../components';

import { SubPageDesktopLayout } from '../../components';
import type { FormProps } from '../components/Form';

interface ViewComponentProps extends FormProps {
  /**
   * Show confirmation screen
   */
  showConfirmation?: boolean;
}

const ViewComponent = ({
  showConfirmation,
  ...formProps
}: ViewComponentProps) => (
  <SubPageDesktopLayout>
    {showConfirmation ? <Confirmation /> : <Form {...formProps} />}
  </SubPageDesktopLayout>
);

ViewComponent.displayName = 'ChangePassword-Desktop-view';

ViewComponent.defaultProps = {
  showConfirmation: false,
};

export default ViewComponent;
