import type { MutableRefObject } from 'react';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';

import { JOB_MENU_MODAL_ID } from '../../Header-constants';
import { JobMenu } from '../../../../../../../components';

interface ViewComponentProps {
  /**
   * Job id
   */
  jobId?: string;
  /**
   * Job status
   */
  status?: string;
  /**
   * Job status
   */
  downloadPdf?: (id: string) => void;
}

const ViewComponent = ({ jobId, status, downloadPdf }: ViewComponentProps) => (
  <ModalContainer id={JOB_MENU_MODAL_ID}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <JobMenu
          jobId={jobId}
          status={status}
          handleClose={handleClose}
          downloadPdf={downloadPdf}
        />
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.defaultProps = {
  jobId: undefined,
  status: undefined,
};

ViewComponent.displayName = 'SlideUp-view';

export default ViewComponent;
