import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import type { DeleteModalProps } from './DeleteModal-view';
import { capitaliseWords } from '../../../../../../../../../utils';

const formatUser = (user) => ({
  name: [user?.first_name, user?.last_name]?.filter(Boolean)?.join(' '),
  email: user?.email,
  mobile: user?.mobile,
  role: capitaliseWords(user?.role?.replace(/-/g, ' '))?.replace(
    'Company ',
    '',
  ),
});

export interface ContainerComponentChildrenArgs {
  onClick: () => void;
  isSubmitting?: boolean;
  name: string;
  email: string;
  mobile: string;
  role: string;
}

export interface ContainerComponentProps extends DeleteModalProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  handleClose: () => void;
}

const ContainerComponent = ({
  children,
  handleClose,
  userToDelete,
}: ContainerComponentProps) => {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess } = useToast();
  const onError = useApiError(
    'Error deleting user at this time - please try again later',
  );

  const user = company?.users?.[userToDelete as number];
  const details = formatUser(user);

  const onClick = useCallback(() => {
    const users = [...(company?.users || [])];
    users?.splice(userToDelete as number, 1);
    const body = {
      ...company,
      users,
    };
    const { id } = company || {};

    dispatch(
      actions.putCompany({
        body: body as unknown as {
          company_id: string;
          company_type: 'limited_company' | 'sole_trader';
        },
        params: { id: id as string },
        onError,
        onSuccess: () => {
          showSuccess('User deleted!');
          handleClose();
        },
      }),
    );
  }, [company, dispatch, handleClose, onError, showSuccess, userToDelete]);

  return children({
    onClick,
    isSubmitting,
    ...details,
  });
};

ContainerComponent.displayName = 'CreateJob-CloseModal-Container';

export default ContainerComponent;
