import { Text } from '@kanda-libs/ks-design-library';

import { Payments } from '../components';

const ViewComponent = ({ ...props }) => (
  <div className="flex flex-col">
    <Text
      text="Billing history"
      className="text-16-20-em mb-6 text-neutral-900"
    />
    <Payments {...props} />
  </div>
);

ViewComponent.displayName = 'Subscription-Content-History-Desktop-view';

export default ViewComponent;
