import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  option: 'absolute right-0 -top-1',
  container: 'border-neutral-300 border px-8 pt-6 rounded-xl mb-4',
};

export const REMOVE_BUTTON_PROPS = {
  id: 'create-job-kanda-remove-line-item',
  variant: 'ghost-grey' as ButtonIconVariant,
  icon: 'remove',
  size: '28-16' as unknown as number,
  iconProps: { stroke: 'neutral-500' },
};
