import type { TableHeaderColumn } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';
import { Lifecycle } from './components';

export type Sorting = 'NAME' | 'EMAIL' | 'LIFECYCLE';
export type Order = 'NORMAL' | 'INVERSE';

export const SORTING: Record<Sorting, Sorting> = {
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  LIFECYCLE: 'LIFECYCLE',
};

export const ORDER: Record<Order, Order> = {
  NORMAL: 'NORMAL',
  INVERSE: 'INVERSE',
};

export const TABLE_COLUMNS: Partial<TableHeaderColumn>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    items: [
      {
        action: {
          type: 'name-alphabetical',
        },
        icon: 'alphabet',
        name: 'Sort alphabetically',
      },
    ],
    renderComponent: {
      component: Text,
      value: 'text',
      props: {
        className: 'text-14-22 text-neutral-700',
      },
    },
    width: 320,
    minWidth: 200,
    maxWidth: 2000,
  },
  {
    Header: 'Email',
    accessor: 'email',
    items: [
      {
        action: {
          type: 'email-alphabetical',
        },
        icon: 'alphabet',
        name: 'Sort alphabetically',
      },
    ],
    renderComponent: {
      component: Text,
      value: 'text',
      props: {
        className: 'text-14-22 text-neutral-700',
      },
    },
    width: 320,
    minWidth: 200,
    maxWidth: 2000,
  },
  {
    Header: 'Status',
    accessor: 'formattedLifecyle',
    items: [
      {
        action: {
          type: 'lifecycle-alphabetical',
        },
        icon: 'alphabet',
        name: 'Sort alphabetically',
      },
    ],
    renderComponent: {
      component: Lifecycle,
      value: 'lifecycle',
    },
    width: 320,
    minWidth: 200,
    maxWidth: 2000,
  },
];

export const DEFAULT_COLUMN: Partial<TableHeaderColumn> = {
  width: 200,
  minWidth: 80,
  maxWidth: 400,
};

export const CLASS_NAMES = {
  content: 'flex flex-col items-center p-10 text-center',
  title: 'text-28-32-em text-neutral-700 mb-6',
  description: 'text-16-24 text-neutral-600 mb-8 w-80',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'user',
  size: 64,
};

export const TITLE = 'Any users you invite will be shown here';

export const BUTTON_PROPS = {
  id: 'home-empty-desktop-create-job',
  label: 'Refer A User',
  icon: 'plus',
  size: 40,
  className: 'mt-4',
};
