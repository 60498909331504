import { Lifecycle } from '../Table/components';
import type { EnhancedReferral } from './helpers';

const Card: React.FunctionComponent<EnhancedReferral> = function ({
  name,
  email,
  mobile,
  formattedLifecyle,
}) {
  return (
    <div className="flex flex-col border-t border-neutral-300 first:border-t-0 py-4 px-6">
      <div className="flex flex-col w-full max-w-100 mx-auto">
        <div className="flex flex-row justify-between mb-2">
          <p className="text-14-22-em my-auto">{name}</p>
          <Lifecycle lifecycle={formattedLifecyle} />
        </div>
        <p className="text-14-22 text-neutral-700">{email}</p>
        <p className="text-14-22 text-neutral-700">{mobile}</p>
      </div>
    </div>
  );
};

export default Card;
