import { Form } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Button, Loader } from '@kanda-libs/ks-design-library';
import { Card, Layout } from '../../components';
import ReferralSignUpFormFields from './FormFields';
import useReferralSignUpForm from './useReferralSignUpForm';

const ReferralSignUpSignUp: React.FunctionComponent = function () {
  const { form, onSubmit, disabled, isSubmitting } = useReferralSignUpForm();

  return (
    <Form id="referral-sign-up" form={form} onSubmit={onSubmit}>
      <Layout
        footer={
          <Button.Text
            id="referral-kanda-signup"
            label="Sign Up"
            icon="arrow-right"
            className="w-full"
            submit
            disabled={disabled || isSubmitting}
          />
        }
      >
        <div className="flex flex-col">
          <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
            Create your Kanda account
          </p>
          <Card>
            <ReferralSignUpFormFields />
            <BreakPoints
              desktop={
                <Button.Text
                  id="referral-kanda-signup"
                  label="Sign Up"
                  icon="arrow-right"
                  submit
                  disabled={disabled || isSubmitting}
                />
              }
            />
          </Card>
        </div>
      </Layout>
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

export default ReferralSignUpSignUp;
