import Container from './Title-container';
import { CLASS_NAMES, DESCRIPTION } from './Title-constants';

import Wrapper from '../Wrapper';

const ViewComponent = () => (
  <Container>
    {({ title, onClick }) => (
      <Wrapper description={DESCRIPTION} onEdit={onClick}>
        <p className={CLASS_NAMES.title}>{title}</p>
      </Wrapper>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Title-view';

export default ViewComponent;
