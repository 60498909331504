import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import { urlWithFrom } from '@kanda-utils/library';
import { Link } from 'react-router-dom';

import { MODAL_ID } from '../../Summary-constants';
import {
  BUTTON,
  CLASS_NAMES,
  SUB_TITLE_TEXT,
  UPGRADE_TEXT,
} from './SubscriptionModal-constants';

import { URLS } from '../../../../../../../../config';
import useSubscriptionModalSubmit from './useSubscriptionModalSubmit';

const ViewComponent = ({}) => {
  const onSubmit = useSubscriptionModalSubmit();

  return (
    <ModalContainer id={MODAL_ID}>
      {({ id, handleClose }) => (
        <ModalLayoutSlideUp id={id} onClose={handleClose} variant="MARKETING">
          <div className="flex flex-col">
            <p className={CLASS_NAMES.subTitle}>{SUB_TITLE_TEXT}</p>
            <h1 className={CLASS_NAMES.title}>{UPGRADE_TEXT}</h1>
            <Link
              className="flex w-full max-w-md mx-auto"
              to={urlWithFrom(URLS.subscription)}
              onClick={() => {
                onSubmit();
              }}
            >
              <Button.Text {...BUTTON} />
            </Link>
          </div>
        </ModalLayoutSlideUp>
      )}
    </ModalContainer>
  );
};

ViewComponent.displayName = 'SubscriptionModal-view';

export default ViewComponent;
