import type { Lead } from '@kanda-libs/ks-frontend-services';
import { capitaliseWords } from 'utils';

export const getLeadTitle = (lead?: Lead): string =>
  capitaliseWords(lead?.lead_quote?.work_type || '').replace(/_/g, ' ');

export const getLeadCustomerName = (lead?: Lead): string =>
  [
    lead?.lead_applicant?.customer_details?.first_name,
    lead?.lead_applicant?.customer_details?.last_name,
  ]
    .filter(Boolean)
    .join(' ');
