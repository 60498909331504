import type { ButtonTextVariant } from '@kanda-libs/ks-design-library';

export const BUTTON_PROPS = {
  id: 'home-empty-mobile-create-job',
  label: 'New',
  variant: 'solid' as ButtonTextVariant,
  left: true,
  icon: 'plus',
  size: 40,
};
