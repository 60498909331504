const formatAddress = (address) =>
  `${address?.line_1}${address?.postcode ? `, ${address.postcode}` : ''}`;

/**
 * Format to check customer and pass out relevant info
 *
 * @param {Object} customer Selected customer object
 */
export const formatCustomer = (customer) => {
  // If customer is undefined or null, return null
  if (!customer) return null;
  // Return parsed info
  return {
    name: `${customer?.first_name} ${customer?.last_name}`,
    address: formatAddress(customer?.address),
    email: customer?.email,
    phoneNumber: customer?.phone,
  };
};
