import HummWrapper from 'components/HummWrapper';
import UserHasCompletedOnboardingWrapper from 'components/UserHasCompletedOnboardingWrapper';
import type { FunctionComponent } from 'react';

export interface CombinedStateWrapperProps {
  children: JSX.Element;
}

const CombinedStateWrapper: FunctionComponent<CombinedStateWrapperProps> =
  function ({ children }) {
    return (
      <HummWrapper>
        <UserHasCompletedOnboardingWrapper>
          {children}
        </UserHasCompletedOnboardingWrapper>
      </HummWrapper>
    );
  };

export default CombinedStateWrapper;
