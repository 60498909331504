import { useCallback } from 'react';
import { useSignUpContext } from '../../../context';

export interface FormButtonsHook {
  signUpWithGoogle: () => void;
  signUpWithFacebook: () => void;
  signUpWithEmail: () => void;
  isSubmitting: boolean;
  isLoading: boolean;
}

export default function useFormButtons(): FormButtonsHook {
  const {
    signInWithGoogle,
    signInWithFacebook,
    setFormDisplay,
    isSubmitting,
    isLoading,
  } = useSignUpContext();

  const signUpWithEmail = useCallback(
    () => setFormDisplay('form'),
    [setFormDisplay],
  );
  return {
    signUpWithGoogle: signInWithGoogle,
    signUpWithFacebook: signInWithFacebook,
    signUpWithEmail,
    isSubmitting,
    isLoading,
  };
}
