import {
  ActionBar,
  Document,
  SignDocument,
  ViewOnlyNotice,
} from './components';
import ScrollBottomButton from './components/ScrollBottomButton';
import useSubscriptionContract from './useSubscriptionContract';

export interface ContractPageProps {
  isSubmitting: boolean;
}

const ContractPage: React.FunctionComponent<ContractPageProps> = function ({
  isSubmitting,
}) {
  const { contract, disabled } = useSubscriptionContract();
  return (
    <div className="flex flex-col w-full relative">
      <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
        Sign your 12-month contract with a personal guarantee
      </p>
      {contract ? (
        <>
          <ViewOnlyNotice />
          <ActionBar contract={contract} />
          <Document contract={contract} />
          <ScrollBottomButton />
          <SignDocument disabled={disabled} isSubmitting={isSubmitting} />
        </>
      ) : null}
    </div>
  );
};

export default ContractPage;
