import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { useReferralSignUpContext } from 'pages/ReferralSignUp/context';
import { useCallback } from 'react';
import { Card, Layout } from '../../components';

const ReferralSignUpPartner: React.FunctionComponent = function () {
  const { setPage } = useReferralSignUpContext();
  const onClick = useCallback(() => setPage('signup'), [setPage]);

  return (
    <Layout
      footer={
        <Button.Text
          id="referral-kanda-next"
          onClick={onClick}
          label="Sign Up"
          icon="arrow-right"
          className="w-full"
        />
      }
    >
      <div className="flex flex-col">
        <p className="text-24-28-em md:text-40-48-em mb-6 md:mb-16">
          Partner rates
        </p>
        <Card>
          <BreakPoints
            desktop={
              <Button.Text
                id="referral-partner-next"
                onClick={onClick}
                label="Sign Up"
                icon="arrow-right"
              />
            }
          />
        </Card>
      </div>
    </Layout>
  );
};

export default ReferralSignUpPartner;
