import { RowFields, Widget } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import useUserEmailValidation from 'hooks/useUserEmailValidation';
import { BASE_USER } from 'pages/MyAccount/TeamSettings/components/Content/components/AddUserButton/AddUserButton-constants';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { EMAIL_PROPS, MOBILE_PROPS } from './constants';

const InviteYourDeveloperForm: FunctionComponent = function () {
  const validation = useUserEmailValidation(0);
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  return (
    <Widget.CompanyUsersArrayWrapper initialData={[BASE_USER]}>
      {() => (
        <>
          <BreakPoints
            mobile={
              <>
                <Widget.CompanyUsersFirstNameArrayInput
                  index={0}
                  placeholder="Their first name"
                />
                <Widget.CompanyUsersLastNameArrayInput
                  index={0}
                  validation={{
                    ...Widget.CompanyUsersLastNameArrayInputValidation,
                    required: {
                      value: true,
                      message: 'Last Name is required.',
                    },
                  }}
                  placeholder="Their last name"
                />
              </>
            }
            desktop={
              <RowFields>
                <Widget.CompanyUsersFirstNameArrayInput
                  index={0}
                  placeholder="Their first name"
                />
                <Widget.CompanyUsersLastNameArrayInput
                  index={0}
                  validation={{
                    ...Widget.CompanyUsersLastNameArrayInputValidation,
                    required: {
                      value: true,
                      message: 'Last Name is required.',
                    },
                  }}
                  placeholder="Their last name"
                />
              </RowFields>
            }
          />
          <Widget.CompanyUsersEmailArrayInput
            {...EMAIL_PROPS}
            index={0}
            validation={validation}
          />
          <Widget.CompanyUsersMobileArrayInput {...MOBILE_PROPS} index={0} />
          <Button.Text
            submit
            left
            id="invite-your-developer-send"
            label="Send Invite"
            icon="send"
            className="mt-2"
            disabled={isSubmitting}
          />
        </>
      )}
    </Widget.CompanyUsersArrayWrapper>
  );
};

export default InviteYourDeveloperForm;
