import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';

import SetupStepIndicator from '../StepIndicator';
import Desktop from './Desktop';
import Mobile from './Mobile';

interface ReferralSignUpLayoutProps {
  children: ReactNode;
  footer?: ReactNode;
}

interface WrapperProps {
  children: ReactNode;
}

const Wrapper: React.FunctionComponent<WrapperProps> = function ({ children }) {
  return (
    <div className="flex flex-col flex-1 w-full max-w-216 mx-auto">
      <SetupStepIndicator />
      {children}
    </div>
  );
};

const ReferralSignUpLayout: React.FunctionComponent<ReferralSignUpLayoutProps> =
  function ({ children, footer }) {
    return (
      <BreakPoints
        mobile={
          <Mobile footer={footer}>
            <Wrapper>{children}</Wrapper>
          </Mobile>
        }
        desktop={
          <Desktop>
            <Wrapper>{children}</Wrapper>
          </Desktop>
        }
      />
    );
  };

export default ReferralSignUpLayout;
