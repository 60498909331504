import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { useForm, type StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  actions,
  useCurrentUser,
  useSignIn,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { getError } from './helpers';
import type { LoginFormProps } from './types';

export default function useLogin() {
  const dispatch = useAppDispatch();
  const meIsSubmitting = useSelector(selectors.authUser.getIsSubmitting);
  const { isUserLoggedIn, revalidate, isValidating } = useCurrentUser();

  const { mutate: login, isLoading: loginIsSubmitting } = useSignIn('email');
  const { mutate: googleSignIn, isLoading: googleIsSubmitting } =
    useSignIn('googlePopup');
  const { mutate: fbSignIn, isLoading: fbIsSubmitting } = useSignIn('facebook');
  const { setUserId } = useAmplitude();

  const isSubmitting =
    loginIsSubmitting || googleIsSubmitting || fbIsSubmitting || meIsSubmitting;

  const { push } = useHistory();

  const form = useForm<LoginFormProps>({ mode: 'onBlur' });

  const disabled =
    loginIsSubmitting ||
    fbIsSubmitting ||
    googleIsSubmitting ||
    !form.formState.isDirty ||
    meIsSubmitting ||
    isValidating;

  const socialDisabled =
    loginIsSubmitting ||
    fbIsSubmitting ||
    googleIsSubmitting ||
    meIsSubmitting ||
    isValidating;

  const { showError } = useToast();
  const onError = useApiError('Error accessing user data');

  const afterSignIn = useCallback(() => {
    revalidate().then(() => {
      dispatch(
        actions.me({
          onError,
          onSuccess: (data) => {
            const user = data as StringIndexedObject;
            if (user?.id) setUserId(user.id);
            if (user?.role === 'user') {
              push(URLS.setupAccount);
              return;
            }
            push(URLS.home);
          },
        }),
      );
    });
  }, [dispatch, revalidate, push, onError, setUserId]);

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      login(formValues, false).then(({ error: loginError }) => {
        if (loginError) {
          showError(getError(loginError));
          return;
        }
        afterSignIn();
      });
    },
    [login, showError, afterSignIn],
  );

  const signInWithGoogle = useCallback(() => {
    googleSignIn(false).then(({ error: signInGoogleError }) => {
      if (signInGoogleError) {
        showError(getError(signInGoogleError));
        return;
      }
      afterSignIn();
    });
  }, [googleSignIn, afterSignIn, showError]);

  const signInWithFb = useCallback(() => {
    fbSignIn(false).then(({ error: signInFbError }) => {
      if (signInFbError) {
        showError('FB error');
        return;
      }
      afterSignIn();
    });
  }, [fbSignIn, afterSignIn, showError]);

  useEffect(() => {
    if (isValidating || !isUserLoggedIn) return;
    push(URLS.home);
  }, [isUserLoggedIn, isValidating, push]);

  return {
    form,
    onSubmit,
    signInWithGoogle,
    signInWithFb,
    disabled,
    socialDisabled,
    isSubmitting,
  };
}
