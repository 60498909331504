import { Button, Icon } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';
import { BUTTON_PROPS, CLASS_NAMES, ICON_PROPS, TITLE } from './constants';

const Placeholder: React.FunctionComponent = function () {
  return (
    <div className={CLASS_NAMES.content}>
      <Icon {...ICON_PROPS} />
      <p className={CLASS_NAMES.title}>{TITLE}</p>
      <Link to={URLS.referralsForm}>
        <Button.Text {...BUTTON_PROPS} />
      </Link>
    </div>
  );
};

export default Placeholder;
