import { Layout, Header } from '@kanda-libs/ks-design-library';

import { Content, Footer } from '../components';

interface ViewComponentProps {
  /**
   * Disabled state
   */
  disabled?: boolean;
  /**
   * Page
   */
  page?: number;
}

const ViewComponent = ({ disabled, page }: ViewComponentProps) => (
  <Layout
    noBorder
    header={<Header.Base lines />}
    footer={page === 0 ? <Footer disabled={disabled} /> : <></>}
  >
    <Content page={page} />
  </Layout>
);

ViewComponent.displayName = 'SSO-Mobile-view';

ViewComponent.defaultProps = {
  disabled: false,
  page: 0,
};

export default ViewComponent;
