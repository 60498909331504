import {
  AnimatedModalContainer,
  Animations,
  Icon,
  ModalLayoutSlideLeft,
} from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { NAV_MENU_ID } from './constants';
import NavMenuHeader from './NavMenuHeader';
import useNavMenu from './useNavMenu';

const NavMenu: FunctionComponent = function () {
  const { items } = useNavMenu();
  return (
    <AnimatedModalContainer id={NAV_MENU_ID} opacity={50}>
      {({ isVisible, hideModal, handleClose }) => (
        <Animations.SlideLeft show={isVisible} onEnd={hideModal}>
          <ModalLayoutSlideLeft
            header={<NavMenuHeader onClose={handleClose} />}
          >
            <div className="flex flex-col -mt-4">
              {items &&
                items.map(
                  ({ icon, name, stroke, size, to, onClick }, index) => {
                    const Tag = to ? Link : 'button';
                    const tagProps = {
                      ...(to ? { to } : {}),
                      ...(onClick
                        ? {
                            onClick: () => {
                              onClick();
                              handleClose();
                            },
                          }
                        : { onClick: handleClose }),
                    };
                    return (
                      <Tag
                        {...tagProps}
                        className="flex flex-col cursor-pointer"
                        key={name}
                      >
                        {index > 0 && (
                          <div className="w-4/5 border-b border-neutral-200 mx-auto" />
                        )}
                        <div className="flex flex-1 w-full h-8 items-center py-2.5">
                          <Icon icon={icon} stroke={stroke} size={size || 28} />
                          <p className="ml-4 style-d-em">{name}</p>
                        </div>
                      </Tag>
                    );
                  },
                )}
            </div>
          </ModalLayoutSlideLeft>
        </Animations.SlideLeft>
      )}
    </AnimatedModalContainer>
  );
};

export default NavMenu;

// {/* <div className="-mt-8">
//                 {items.map(({ icon, name, stroke, size, ...itemProps }, i) => (
//                   <div
//                     onClick={handleClose}
//                     className="cursor-pointer mt-8"
//                     key={String(i)}
//                   >
//                     <LinkComponent {...itemProps}>
//                       <div className="flex flex-1 w-full h-8 items-center ">
//                         <Icon icon={icon} stroke={stroke} size={size || 32} />
//                         <p className="ml-4 style-d-em">{name}</p>
//                       </div>
//                     </LinkComponent>
//                   </div>
//                 ))}
//               </div> */}
