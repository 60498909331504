import { useForm, type StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  actions,
  Service,
  services,
  useSubmit,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { cleanObject } from 'utils';
import { Contract, useCompanyInfoContext } from '../../context';

export interface JobInfoHook {
  form: UseFormReturn<StringIndexedObject>;
  onSubmit: (values: StringIndexedObject) => void;
  contract: Contract | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
  disabled: boolean;
}

export default function useJobInfo(): JobInfoHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const { contract } = useCompanyInfoContext();
  const { showError, showSuccess } = useToast();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );
  const { push } = useHistory();

  const { submit: generateContract } = useSubmit(
    services.contract.generate as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const form = useForm({
    mode: 'onBlur',
  });

  const [auth, iar, tac] = form.watch(['auth', 'iar', 'tac.value']);

  const disabled = useMemo(() => !auth || !iar || !tac, [auth, iar, tac]);

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      setIsSubmitting(true);
      const contractBody = cleanObject({
        company,
        signee:
          formValues?.company?.company_info?.contract_agreement?.print_name ||
          '',
        fingerprint: formValues?.tac,
      });
      generateContract({
        body: contractBody,
      }).then(({ data: signedContract, error: contractError }) => {
        if (contractError || !signedContract) {
          setIsSubmitting(false);
          showError(
            'Error electronically signing your contract at this time - please refresh and try again. If this issue persists, please contact Kanda',
          );
          return;
        }
        const body = cleanObject({
          ...company,
          company_info: {
            ...company?.company_info,
            contract_agreement: {
              ...formValues?.company?.company_info?.contract_agreement,
              contract: {
                name: signedContract?.data?.name,
                content: signedContract?.data?.content,
              },
              agreed_terms_and_conditions: 'yes',
              agreed_to_become_iar_of_kanda: 'yes',
            },
          },
        });
        const id = company?.id;
        if (!body || !id) {
          setIsSubmitting(false);
          showError('Error with data in company - please contact Kanda');
          return;
        }
        dispatch(
          actions.putCompany({
            body,
            params: { id },
            onError: (error) => {
              setIsSubmitting(false);
              onError(error);
            },
            onSuccess: () => {
              setIsSubmitting(false);
              showSuccess(
                'Details submitted successfully, your account is now in review',
              );
              push(URLS.exampleJob);
            },
          }),
        );
      });
    },
    [
      company,
      dispatch,
      onError,
      push,
      showSuccess,
      generateContract,
      showError,
    ],
  );

  return {
    form,
    onSubmit,
    contract,
    isSubmitting: isSubmitting || false,
    isLoading: !contract,
    disabled,
  };
}
