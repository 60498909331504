import { Button, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import useRequestNewRates from './useRequestNewRates';

const RequestNewRates: FunctionComponent = function () {
  const { link, isLoading } = useRequestNewRates();
  if (!link) return null;
  return (
    <div className="flex flex-col mt-6 mb-4">
      <Text
        text="Available Finance Options"
        className="mb-1 text-16-20-em text-neutral-900"
      />
      <div className="relative pb-2/3 h-0 my-4">
        <iframe
          src="https://www.loom.com/embed/950a793f331d4b6999bd0d44069d899d"
          title="request new rates"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full border-0"
        />
      </div>
      <Text
        isLoading={isLoading}
        text={
          <>
            Request new <strong>required</strong> credit options to be added to
            your account. Once added they can’t be removed for 6 months and will
            be available to all of your customers (loan restrictions dependent).
          </>
        }
        className="text-16-20 text-neutral-600"
      />
      <div className="mt-4">
        <a href={link} target="_blank" rel="noreferrer noopener">
          <Button.Text
            id="request-new-rates"
            label="Request new rates"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </a>
      </div>
    </div>
  );
};

export default RequestNewRates;
