import { useCallback, useMemo } from 'react';
import { scrollToTop } from 'utils';
import { useSubscriptionContext } from '../../context';

export interface EditSignUpMethodHook {
  onClick: () => void;
  showEdit: boolean;
}

export default function useEditPaymentMethod(): EditSignUpMethodHook {
  const { display, setFormDisplay } = useSubscriptionContext();

  const onClick = useCallback(() => {
    scrollToTop();
    setFormDisplay('subscriptions');
  }, [setFormDisplay]);

  const showEdit = useMemo(() => display !== 'subscriptions', [display]);

  return {
    onClick,
    showEdit,
  };
}
