import { useContext, useMemo } from 'react';
import {
  DropzoneContext,
  useFormContext,
  useWatch,
  type ValidationItems,
  type ValidError,
} from '@kanda-libs/ks-component-ts';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useTradeBodyNumberValidation from '../../../../../../../hooks/useTradeBodyNumberValidation';
import useVatNumberValidation from '../../../../../../../hooks/useVatNumberValidation';
import useSolarElectricalTradeBodyNumberValidation from '../../../../../../../hooks/useSolarElectricalTradeBodyNumberValidation';

export interface ContainerComponentChildrenProps {
  inputFile: File[] | null;
  fileError: ValidError;
  limited: boolean;
  hasLogo: boolean;
  tradeNumberValidation: ValidationItems;
  solarElectricalValidation: ValidationItems;
  vatNumberValidation: ValidationItems;
  personalLiabilityError: ValidError;
  isSoleTrader: boolean;
  isSolar: boolean | null;
  isSubmitting: boolean;
  insuranceValidation: ValidationItems;
}

export interface ContainerComponentProps {
  company?: Company;
  isSubmitting?: boolean;
  children: (props: ContainerComponentChildrenProps) => JSX.Element;
}

const ContainerComponent = ({
  children,
  company,
  isSubmitting = false,
}: ContainerComponentProps) => {
  const dropzoneContext = useContext(DropzoneContext);
  const droppedFiles = dropzoneContext?.droppedFiles;

  const {
    formState: { errors },
    getValues,
  } = useFormContext();

  const isSolar = useMemo(() => {
    if (!company) return null;
    return !!company?.solar_company_info;
  }, [company]);

  const inputFile = useMemo(() => {
    if (!droppedFiles) return null;
    const file = droppedFiles[0];
    if (!file) return null;
    return file;
  }, [droppedFiles]);

  const fileError = (errors?.company as unknown as Company)?.company_logo
    ? 'Error uploading company logo - please try again later'
    : null;

  const personalLiabilityError = (errors?.company as unknown as Company)
    ?.company_info?.insurance_document
    ? 'Personal liability insurance policy is required'
    : null;

  const limited = company?.company_type === 'limited_company';

  const logoId = useWatch({ name: 'company.company_logo.id' });

  const hasLogo = !!logoId;

  const tradeNumberValidation = useTradeBodyNumberValidation();
  const vatNumberValidation = useVatNumberValidation();
  const solarElectricalValidation =
    useSolarElectricalTradeBodyNumberValidation();
  const insuranceValidation = {
    validate: {
      value: () => {
        const doc = getValues('company.company_info.insurance_document');
        if (doc?.id) return true;
        if (doc?.content) return true;
        return false;
      },
      message: 'An insurance document is required',
    },
  };

  return children({
    inputFile,
    fileError: fileError as ValidError,
    limited,
    hasLogo,
    tradeNumberValidation,
    solarElectricalValidation,
    vatNumberValidation,
    personalLiabilityError: personalLiabilityError as ValidError,
    isSoleTrader: company?.company_type === 'sole_trader',
    isSolar,
    isSubmitting,
    insuranceValidation,
  });
};

ContainerComponent.displayName = 'Menu-container';

export default ContainerComponent;
