import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    data: { isLoading = false },
  } = useCreateJobContextValue();

  const isLeadJob = useIsLeadJob();

  if (isLeadJob) {
    return <></>;
  }

  return children({ isLoading });
};

ContainerComponent.displayName = 'PaymentMethods-Header-container';

export default ContainerComponent;
