import { BreakPoints } from '@kanda-libs/ks-design-library';
import { ModalContainer } from '@kanda-libs/ks-component-ts';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { SUBSCRIBE_MODAL_ID } from '../../../../hooks/useSubscribeModal';

const ViewComponent = () => (
  <ModalContainer id={SUBSCRIBE_MODAL_ID} opacity={30}>
    {({ ...modalProps }) => (
      <BreakPoints mobile={<Mobile {...modalProps} />} desktop={<Desktop />} />
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'SubscribeModal-view';

export default ViewComponent;
