import {
  useFormContext,
  type ValidationItems,
} from '@kanda-libs/ks-component-ts';

export default function useSolarElectricalTradeBodyNumberValidation(): ValidationItems {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: () => {
        // Get values
        const values = getValues();
        const tradeBody =
          values?.company?.solar_company_info?.electrical_trade_association;
        return (
          !tradeBody ||
          tradeBody === 'none' ||
          !!values?.company?.solar_company_info
            ?.electrical_trade_association_number
        );
      },
      message: 'Please enter your electrical trade association number',
    },
  };
}
