import {
  FIXED_DEPOSIT_VALIDATION_MESSAGES,
  QUOTE_FIXED_DEPOSIT_VALIDATION_MESSAGES,
} from './Fixed-constants';

export const getFixedDepositValidation = (
  jobTotal: number,
  isQuote: boolean,
) => {
  if (!isQuote)
    return {
      min: {
        value: 0,
        message: FIXED_DEPOSIT_VALIDATION_MESSAGES.min,
      },
      max: {
        value: Math.floor((jobTotal * 3) / 4),
        message: FIXED_DEPOSIT_VALIDATION_MESSAGES.max,
      },
    };

  return {
    min: {
      value: 0,
      message: QUOTE_FIXED_DEPOSIT_VALIDATION_MESSAGES.min,
    },

    max: {
      value: Math.floor((jobTotal * 3) / 4),
      message: QUOTE_FIXED_DEPOSIT_VALIDATION_MESSAGES.max,
    },
  };
};
