import { Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import {
  CITY_PROPS,
  COUNTRY_PROPS,
  COUNTY_PROPS,
  LINE_1_PROPS,
  LINE_2_PROPS,
  MANUAL_BUTTON_PROPS,
  POSTCODE_NAME,
  POSTCODE_PROPS,
} from './constants';
import useAddressFields from './useAddressFields';

const Address: React.FunctionComponent = function () {
  const {
    postcodeCallback,
    showSelect,
    addresses,
    selectProps,
    manual,
    addManualInput,
  } = useAddressFields();

  return (
    <div className="flex flex-col">
      <Field.Validator validation={POSTCODE_PROPS.validation}>
        <Field.Postcode callback={postcodeCallback} {...POSTCODE_PROPS.field} />
      </Field.Validator>
      {showSelect && addresses && (
        <>
          <Field.Address.Select
            label="Select Address"
            postcodeName={POSTCODE_NAME}
            data={addresses}
            {...selectProps}
          />
          {!manual && (
            <Button.Link onClick={addManualInput} {...MANUAL_BUTTON_PROPS} />
          )}
          {manual && (
            <>
              <Field.Validator validation={LINE_1_PROPS.validation}>
                <Field.Input {...LINE_1_PROPS.field} />
              </Field.Validator>
              <Field.Input {...LINE_2_PROPS.field} />
              <Field.Validator validation={CITY_PROPS.validation}>
                <Field.Input {...CITY_PROPS.field} />
              </Field.Validator>
              <Field.Input {...COUNTY_PROPS.field} />
              <Field.Validator validation={COUNTRY_PROPS.validation}>
                <Field.Input {...COUNTRY_PROPS.field} />
              </Field.Validator>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Address;
