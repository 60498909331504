import type { TrustPilotEmbedProps } from '../types';
import { useTrustPilotEmbed } from '../useTrustPilotEmbed';

const Micro: React.FunctionComponent<TrustPilotEmbedProps> = function ({
  className,
}) {
  const { elementRef } = useTrustPilotEmbed();

  return (
    <div
      id="trustbox"
      ref={elementRef}
      className={`trustpilot-widget ${className}`}
      data-locale="en-GB"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="6230d3f88d7b1f06076ebc8c"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://uk.trustpilot.com/review/getkanda.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default Micro;
