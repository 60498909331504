import { useIsDesktop } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

interface RatesTableRowProps {
  rate: string;
  grouped: FinanceRate[];
  index: number;
}

interface RateFeeTerm {
  apr: string;
  term: string;
  fee: string;
  originalFee?: string;
}

const getFee = (feeLender: string): string => {
  if (feeLender === 'none') return '';
  const baseFee = feeLender.split('-')[0];
  if (baseFee === '0') return 'Free!';
  return `${Number(baseFee) / 100}%`;
};

const getRate = (
  apr: string,
  bnpl: boolean,
  secondLine: boolean,
  desktop: boolean,
): string => {
  const parsedApr = `${String(parseInt(apr, 10) / 100)}%`;
  const secondLineLabel = desktop ? '(2nd line)' : '2nd line';
  return [
    bnpl && !desktop ? 'BNPL' : undefined,
    secondLine && !desktop ? secondLineLabel : undefined,
    parsedApr,
    bnpl && desktop ? 'BNPL' : undefined,
    !secondLine && !bnpl ? 'APR' : undefined,
    desktop && !secondLine && !bnpl ? 'Interest' : undefined,
    desktop && secondLine ? secondLineLabel : undefined,
  ]
    .filter(Boolean)
    .join(' ');
};

const getTerms = (rates: FinanceRate[]): string => {
  if (!rates) return '--';
  if (rates.length === 1) return `${rates[0]?.duration} months`;
  const bnpl = rates.some(
    (rate: FinanceRate) => rate?.apr_type === 'BUYNOW_PAYLATER',
  );
  if (bnpl) {
    const deferredDuration = rates[0]?.deferred_duration || '6';
    const durations = rates
      .map((rate: FinanceRate) => rate?.duration || undefined)
      .filter(Boolean) as number[];
    const [min, max] = [Math.min(...durations), Math.max(...durations)];
    return `${deferredDuration}+${min}-${max} months`;
  }
  const durations = rates
    .map((rate: FinanceRate) => rate?.duration || undefined)
    .filter(Boolean) as number[];
  const [min, max] = [Math.min(...durations), Math.max(...durations)];
  return `${min}-${max} months`;
};

const getRateFeeTerms = (
  rate: string,
  rates: FinanceRate[],
  desktop: boolean,
): RateFeeTerm => {
  const [apr, fee, originalFee, ...parts] = rate.split('-');
  const secondLine = parts?.includes('2nd');
  const bnpl = parts?.includes('bnpl');
  return {
    apr: getRate(apr, bnpl, secondLine, desktop),
    term: getTerms(rates),
    fee: getFee(fee),
    originalFee: getFee(originalFee),
  };
};

const RatesTableRow: React.FunctionComponent<RatesTableRowProps> = function ({
  rate,
  grouped,
  index,
}) {
  const isDesktop = useIsDesktop();

  const { apr, term, fee, originalFee } = getRateFeeTerms(
    rate,
    grouped,
    isDesktop,
  );

  const rowClassName = [
    'flex flex-row py-2.5',
    index % 2 === 0 ? 'bg-neutral-000' : 'bg-neutral-200 md:bg-neutral-000',
  ].join(' ');

  return (
    <>
      <div
        className={`col-span-3 border-b ${
          index === 0 && 'md:border-0'
        } border-neutral-300`}
      />
      <p
        className={`${rowClassName} pl-1 pr-2 text-14-22-em md:text-16-24-em text-neutral-700`}
      >
        {apr}
      </p>
      <p
        className={`${rowClassName} pr-2 text-14-22 md:text-16-24 text-neutral-900`}
      >
        {term}
      </p>
      <p
        className={`${rowClassName} pr-2 text-14-22-em md:text-16-24-em text-lavender-200`}
      >
        {originalFee && (
          <span className="line-through text-neutral-500 mr-2">
            {originalFee}
          </span>
        )}
        {fee}
      </p>
    </>
  );
};

export default RatesTableRow;
