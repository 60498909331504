import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

import { Content } from '../components';

interface ViewComponentProps {
  /**
   * is loading state
   */
  isLoading?: boolean;
  /**
   * is invalid state
   */
  isInvalid?: boolean;
}

const ViewComponent = ({ isLoading, isInvalid }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <Content isLoading={isLoading} isInvalid={isInvalid} />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.defaultProps = {
  isLoading: false,
  isInvalid: false,
};

ViewComponent.displayName = 'VerifyEmail-Landing-Desktop-view';

export default ViewComponent;
