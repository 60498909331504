import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { useFrontChatWidget } from '@kanda-libs/ks-design-library';
import {
  revalidateUser,
  useAutomaticTokenRefresh,
  useCurrentUser,
} from '@kanda-libs/ks-frontend-services';
import { H } from 'highlight.run';
import { useEffect, useRef } from 'react';

import { useAppDispatch } from 'components/App';
import { APP_ENV, HIGHLIGHT_KEY } from '../../config';
import { useGtag } from '../../hooks';
import useCurrentCompany from '../../hooks/useCurrentCompany';
import useMe from '../../hooks/useMe';
import { cleanObject } from '../../utils';

const ContainerComponent = ({ children }) => {
  const { update } = useFrontChatWidget();
  const { isUserLoggedIn } = useCurrentUser();
  const dispatch = useAppDispatch();

  useAutomaticTokenRefresh();

  const { updateConfig } = useGtag();

  const { me } = useMe();

  const { company } = useCurrentCompany();

  const { setUserId } = useAmplitude();

  // Effect initialised Highlight
  useEffect(() => {
    if (APP_ENV === 'qa') return;
    H.init(HIGHLIGHT_KEY, {
      environment: APP_ENV,
      networkRecording: {
        recordHeadersAndBody: true,
      },
    });
  }, []);

  // Effect sends user info to Highlight
  useEffect(() => {
    if (APP_ENV === 'qa' || !me || !company) return;
    const email = me?.email;
    const name = me?.name;
    const verified = me?.verified;
    const companyName =
      company?.limited_company_info?.company_name ||
      company?.sole_trader_info?.trading_name;
    const metadata = cleanObject({
      name,
      company: companyName,
      verified,
    });
    H.identify(email, metadata);
  }, [me, company]);

  useEffect(() => {
    if (!me || !company) return;
    const { email, name, id: userId, phone: phoneNumber } = me;
    const settings = cleanObject({
      email,
      name,
      userId,
      phoneNumber,
    });
    update(settings);
  }, [me, company, update]);

  useEffect(() => {
    if (!company || !company?.cid) return;
    updateConfig('user_id', company?.cid);
  }, [company, updateConfig]);

  useEffect(() => {
    if (!me) return;
    setUserId(me.id);
  }, [me, setUserId]);

  const timerRef = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    if (!isUserLoggedIn) return () => {};
    timerRef.current = setInterval(() => dispatch(revalidateUser()), 60000);
    return () => {
      clearTimeout(timerRef.current as unknown as number);
    };
  }, [isUserLoggedIn, dispatch]);

  return children;
};

ContainerComponent.displayName = 'AppWrapper-container';

export default ContainerComponent;
