import { BreakPoints } from '@kanda-libs/ks-design-library';
import { makeIsAllowed } from '@kanda-utils/library';
import InsuranceInfo from './Pages/InsuranceInfo';
import JobInfo from './Pages/JobInfo';
import OnlinePresence from './Pages/OnlinePresence';
import TermsAndConditions from './Pages/TermsAndConditions';
import TradeInfo from './Pages/TradeInfo';

export const COMPANY_INFO_STEPS = {
  TRADE_INFO: 'trade-info',
  JOB_INFO: 'job-info',
  ONLINE_PRESENCE: 'online-presence',
  INSURANCE_INFO: 'insurance-info',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
};

export const STEPS = Object.values(COMPANY_INFO_STEPS);

export const Pages = {
  [COMPANY_INFO_STEPS.TRADE_INFO]: TradeInfo,
  [COMPANY_INFO_STEPS.JOB_INFO]: JobInfo,
  [COMPANY_INFO_STEPS.ONLINE_PRESENCE]: OnlinePresence,
  [COMPANY_INFO_STEPS.INSURANCE_INFO]: InsuranceInfo,
  [COMPANY_INFO_STEPS.TERMS_AND_CONDITIONS]: TermsAndConditions,
};

const Placeholder = () => (
  <div className="flex flex-row my-auto">
    <span className="mr-1 text-14-22-em text-green-400">
      Click to upload file
    </span>
    <BreakPoints
      desktop={
        <span className="text-14-22 text-neutral-600">or drag & drop</span>
      }
    />
  </div>
);

const VALIDATION = {
  required: { value: true, message: 'A quote PDF is required.' },
};

export const INSURANCE_DOCUMENT_PROPS = {
  label: 'Personal liability insurance policy',
  placeholder: <Placeholder />,
  maxFiles: 1,
  maxSize: 20 * 1024 * 1024,
  accept: ['image/*', 'application/pdf'],
  compressImages: true,
  jobPdfInput: true,
  validation: VALIDATION,
};

export const AVERGAE_JOB_VALUE_PROPS = {
  label: 'Average Job Value',
  placeholder: 'average job value',
  wrapperProps: {
    className: 'mr-2',
  },
  isAllowed: makeIsAllowed(0, 100000),
  type: 'price',
};

const NI_REGEX =
  /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]{0,1}|\s)$/i;

export const NATIONAL_INSURANCE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'National Insurance number is required',
  },
  minLength: {
    value: 1,
    message: 'National Insurance number be longer than 1 character',
  },
  pattern: {
    value: NI_REGEX,
    message: 'National Insurance number must be valid UK number',
  },
};
