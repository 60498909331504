import { useMemo } from 'react';
import { useWatch } from '@kanda-libs/ks-component-ts';
import { useIsDesktop } from '@kanda-libs/ks-design-library';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { TOTAL_LABEL } from './JobTotal-constants';

import { ITEMS_FIELD_NAME } from '../../../../../../CreateJob-constants';
import { calculateJobTotal } from '../../../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const isDesktop = useIsDesktop();

  const {
    data: { isLoading },
  } = useCreateJobContextValue();

  const items = useWatch({
    name: ITEMS_FIELD_NAME,
  });

  const { totalVat: vat, totalIncVat: amount } = useMemo(
    () => calculateJobTotal(items),
    [items],
  );

  const label = isDesktop ? TOTAL_LABEL.desktop : TOTAL_LABEL.mobile;

  return children({
    amount,
    vat,
    isLoading,
    label,
    isDesktop,
  });
};

ContainerComponent.displayName = 'JobTotal-container';

export default ContainerComponent;
