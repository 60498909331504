import { VARIANTS, VAT_OPTIONS } from '../../LineItem-constants';

import { calculateItemTotal } from '../../../../../../../../../../../utils';
import type { ViewComponentProps } from './Provider-view';
import type { LineItemContextValue } from '../context';

export type ContainerChildrenArgs = LineItemContextValue;

export interface ContainerProps
  extends Omit<ViewComponentProps, 'children' | 'totalPriceProps'> {
  children: (args: ContainerChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  item,
  isLoading,
  variant,
}: ContainerProps) => {
  // Get line item totals
  const { totalExcVat: subTotal, totalIncVat: total } = (item &&
    calculateItemTotal(
      item?.quantity as unknown as number,
      item?.price?.amount,
      item?.vat_rate,
    )) || {
    subTotal: 0,
    total: 0,
  };

  // Extract the correct VAT label from the value
  const vat =
    item &&
    VAT_OPTIONS.reduce(
      (labels, option) => ({ ...labels, [option.value]: option.name }),
      {},
    )[item?.vat_rate as string];

  // Structure all values into an object
  const values = {
    title: item?.title || 'No line item title given...',
    description: item?.description,
    quantity: `${(item?.quantity as unknown as number) / 100} x`,
    price: subTotal,
    vat,
    total,
  };

  const classNames = VARIANTS[variant as string];

  return children({
    isLoading,
    classNames,
    ...values,
    variant,
  });
};

ContainerComponent.displayName = 'LineItem-Context-Provider-container';

export default ContainerComponent;
