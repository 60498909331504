import { Button, Text } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import { MOBILE_CLASS_NAMES, SKELETONS } from './constants';
import DirectorCard from '../DirectorCard';
import { capitaliseWords } from '../../../utils';

interface MobileProps {
  director: UserType | null;
  companyName?: string;
  isUserLoggedIn?: boolean;
  isLoading: boolean;
  onClickButton: () => void;
}

const Mobile = ({
  director,
  companyName,
  isLoading,
  isUserLoggedIn,
  onClickButton,
}: MobileProps) => {
  const loadingProps = {
    isLoading,
  };

  return (
    <div className={MOBILE_CLASS_NAMES.wrapper}>
      <Text
        text="Please verify your identity"
        className={MOBILE_CLASS_NAMES.title}
        skeletonProps={SKELETONS.secondTitle}
        {...loadingProps}
      />
      {!isUserLoggedIn && (
        <Text
          text="If this is you, continue to identity verification"
          className={MOBILE_CLASS_NAMES.continue}
        />
      )}
      <DirectorCard
        name={[director?.first_name, director?.last_name].join(' ')}
        companyName={
          companyName ? capitaliseWords(companyName.toLowerCase()) : ''
        }
        {...loadingProps}
      />
      <Text
        text="Your identity will be verified by our 3rd party partner called Veriff."
        className={MOBILE_CLASS_NAMES.subTitle}
      />
      <Button.Text
        id="identify-mobile-next"
        label="Continue"
        icon="arrow-right"
        isLoading={isLoading}
        onClick={() => {
          onClickButton();
        }}
      />
    </div>
  );
};

export default Mobile;
