import SplashScreen from '../../../components/SplashScreen';

const Success = () => (
  <div>
    <SplashScreen
      icon="user-square-color"
      title="Verification is in review"
      subText="You'll be notified when your identity is verified by Veriff."
    />
    <div id="veriff" className="hidden" />
  </div>
);

export default Success;
