import { SkeletonLoader } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from './OnboardingStep-constants';

interface StepNumberProps {
  active: boolean;
  number: number;
  isLoading?: boolean;
}

const StepNumber = ({ active, number, isLoading }: StepNumberProps) => (
  <SkeletonLoader
    isLoading={isLoading}
    circle
    width={24}
    height={24}
    wrapperClassName="mb-3"
    afterLoading={
      <div
        className={
          active ? CLASS_NAMES.numberActive : CLASS_NAMES.numberInactive
        }
      >
        {number}
      </div>
    }
  />
);

StepNumber.defaultProps = {
  isLoading: false,
};

export default StepNumber;
