import { makeIsAllowed } from '@kanda-libs/ks-component-ts';

export const PRICE_COMPONENT_PROPS = {
  autoWidth: true,
  label: 'Price',
  placeholder: '£0.00',
  wrapperProps: {
    className: 'mr-2',
  },
  isAllowed: makeIsAllowed(0, 100000),
  autosize: true,
  type: 'price',
};

export const PRICE_COMPONENT_MOBILE_PROPS = {
  autoWidth: true,
  label: 'Price',
  placeholder: '£0.00',
  wrapperProps: {
    className: 'mr-2',
  },
  isAllowed: makeIsAllowed(0, 100000),
  type: 'price',
};
