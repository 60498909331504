import { Text } from '@kanda-libs/ks-design-library';

import {
  FINANCE_OPTION_TITLE,
  FINANCE_OPTION_UNREGULATED_TITLE,
  REGULATED_DESCRIPTION,
  UNREGULATED_DESCRIPTION,
  SKELETONS,
} from './FinanceOptions-constants';
import Container from './FinanceOptions-container';

import { CLASS_NAMES } from '../PaymentAndFinanceOptions-constants';
import { Options } from './components';

export interface FinanceOptionsProps {
  unregulated?: boolean;
  useStoredJob?: boolean;
  hideComingSoon?: boolean;
}

const FinanceOptions = ({
  unregulated = false,
  useStoredJob = false,
  hideComingSoon = true,
}: FinanceOptionsProps) => (
  <Container
    unregulated={unregulated}
    useStoredJob={useStoredJob}
    hideComingSoon={hideComingSoon}
  >
    {({ isLoading, ...props }) => (
      <div className={CLASS_NAMES.optionWrapper}>
        <Text
          className={CLASS_NAMES.optionTitle}
          text={
            unregulated
              ? FINANCE_OPTION_UNREGULATED_TITLE
              : FINANCE_OPTION_TITLE
          }
          skeletonProps={SKELETONS.title}
          isLoading={isLoading}
        />
        {!unregulated && (
          <Text
            className={CLASS_NAMES.optionDescription}
            text={REGULATED_DESCRIPTION}
            skeletonProps={SKELETONS.title}
            isLoading={isLoading}
          />
        )}
        {unregulated && (
          <Text
            className={CLASS_NAMES.optionDescription}
            text={UNREGULATED_DESCRIPTION}
            skeletonProps={SKELETONS.description}
            isLoading={isLoading}
          />
        )}
        <Options isLoading={isLoading} {...props} />
      </div>
    )}
  </Container>
);

FinanceOptions.displayName = 'PaymentAndFinanceOptions-FinanceOptions-view';

export default FinanceOptions;
