import { BreakPoints } from '@kanda-libs/ks-design-library';

const Placeholder = () => (
  <div className="flex flex-row my-auto py-4">
    <span className="text-14-22-em text-green-400">Click to upload file</span>
    <BreakPoints
      desktop={
        <span className="text-14-22 text-neutral-600 ml-1">or drag & drop</span>
      }
    />
  </div>
);

export const INSURANCE_DOCUMENT_PROPS = {
  label: 'Upload your public liability insurance',
  placeholder: <Placeholder />,
  centerPlaceholder: true,
  jobPdfInput: true,
  maxFiles: 1,
  maxSize: 20 * 1024 * 1024,
  accept: ['image/*', 'application/pdf'],
  compressImages: true,
  validation: {
    required: { value: true, message: 'Please provide a valid file' },
  },
};
