import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@kanda-libs/ks-design-library';

import { URLS } from 'config';

import useFooter from './useFooter';

import type { AdvertisingPage } from '../types';

export interface FooterProps {
  page: AdvertisingPage;
}

const Footer: FunctionComponent<FooterProps> = function ({ page }) {
  const { nextPage, isLastPage } = useFooter(page);

  if (isLastPage) {
    return <></>;
  }

  return (
    <div className="w-full mt-4 md:w-auto md:mt-8">
      <Link to={URLS.advertising.replace(':id', nextPage as string)}>
        <Button.Text
          id="update-your-website-next"
          label="Next"
          icon="arrow-right"
          variant="outline"
          className="w-full"
        />
      </Link>
    </div>
  );
};

export default Footer;
