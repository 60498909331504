import useCompanyLifecycle from '../../../../hooks/useCompanyLifecycle';
import useIsSubscribed from '../../../../hooks/useIsSubscribed';

export default function useCompanyAccountProgress() {
  const lifecycle = useCompanyLifecycle();
  const { isSubscribed } = useIsSubscribed();

  if (!isSubscribed || !lifecycle || lifecycle === 'registered') {
    return 0;
  }

  if (lifecycle === 'onboarded') {
    return 33;
  }

  if (lifecycle === 'completed_setup') {
    return 66;
  }

  return 100;
}
