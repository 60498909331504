import { useEffect } from 'react';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const { step } = useCreateJobContextValue();

  useEffect(() => {
    const location = window?.location;
    if (!location) return;
    const url = new URL(location as unknown as string);
    url.searchParams.set('step', step as string);
    window.history.pushState({}, '', url);
  }, [step]);

  return children;
};

ContainerComponent.displayName = 'Page-container';

export default ContainerComponent;
