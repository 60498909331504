import { RowFields, Widget } from '@kanda-libs/ks-component-ts';

import Container from './Name-container';

const ViewComponent = () => (
  <Container>
    {({ onBlur }) => (
      <RowFields>
        <Widget.JobCustomerFirstName
          placeholder="First name"
          onBlur={() => onBlur('job.customer.first_name')}
        />
        <Widget.JobCustomerLastName
          placeholder="Last name"
          onBlur={() => onBlur('job.customer.last_name')}
        />
      </RowFields>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Mobile-Customer-Field-Name-view';

export default ViewComponent;
