import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';

interface ReferralsDesktopLayoutProps {
  children: React.ReactNode;
}

const ReferralsDesktopLayout: React.FunctionComponent<ReferralsDesktopLayoutProps> =
  function ({ children }) {
    return (
      <DesktopLayoutDefault>
        <DesktopLayoutDefaultContent>
          <div className="flex flex-col flex-1">{children}</div>
        </DesktopLayoutDefaultContent>
      </DesktopLayoutDefault>
    );
  };

export default ReferralsDesktopLayout;
