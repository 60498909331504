import { useForm } from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { URLS } from '../../../config';

export interface ContainerComponentChildrenArgs {
  onSubmit: (formValues: StringIndexedObject<string>) => void;
  form: ReturnType<typeof useForm>;
  disabled: boolean;
  isSubmitting: boolean;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.infoAuth.getIsSubmitting);

  const onError = useApiError('Error requesting password reset');

  const { push } = useHistory();

  const form = useForm();

  const disabled = isSubmitting || !form.formState.isDirty;

  const onSubmit = (formValues: StringIndexedObject<string>) => {
    const { email } = formValues;

    dispatch(
      actions.infoPassword({
        body: { email },
        onError,
        onSuccess: () => {
          push(URLS.forgotPasswordConfirmation);
        },
      }),
    );
  };

  return children({
    onSubmit,
    form,
    disabled,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'ForgotPassword-Request-container';

export default ContainerComponent;
