import { FormTheme } from '@kanda-libs/ks-component-ts';
import Fields from './Fields';

const ReferralSignUpFormFields: React.FunctionComponent = function () {
  return (
    <FormTheme variant="streamline">
      <p className="text-18-22-em mb-4">Your details</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
        <Fields.FirstName />
        <Fields.LastName />
        <Fields.Phone />
        <Fields.Email />
      </div>
      <div className="flex flex-col mt-auto pt-3">
        <div className="border-b border-neutral-300 mb-8" />
        <Fields.TermsAndConditions />
      </div>
    </FormTheme>
  );
};

export default ReferralSignUpFormFields;
