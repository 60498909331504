import { useMemo } from 'react';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';

// eslint-disable-next-line
const ContainerComponent = ({ children }) => {
  const { company, isLoading } = useCurrentCompany();

  const setupInProgress = company?.lifecycle === 'onboarded';
  const companyInReview = company?.lifecycle === 'completed_setup';

  const showBanner = setupInProgress || companyInReview;
  const showFca = useMemo(() => {
    if (!company) return true;
    return company?.business_config?.lender_rate_type === 'regulated';
  }, [company]);

  return children({
    isLoading,
    showBanner: showBanner || true,
    showFca,
    setupInProgress,
    companyInReview,
  });
};

ContainerComponent.displayName = 'CompanyBanner-container';

export default ContainerComponent;
