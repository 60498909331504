import { Icon, Text } from '@kanda-libs/ks-design-library';
import { IN_REVIEW_TITLE } from './CompanyInReview-constants';

import { CARD_CLASS_NAMES } from '../../CompanyBanner-constants';
import ReviewTimeline from './ReviewTimeline';
import useInReviewStatus from './useInReviewStatus';

interface CompanyInReviewProps {
  showIcon?: boolean;
  showFca?: boolean;
  className?: string;
}

const CompanyInReview = ({
  showIcon,
  showFca = true,
  className = '',
}: CompanyInReviewProps) => {
  const { activeKey, heading } = useInReviewStatus();

  return (
    <div
      className={[CARD_CLASS_NAMES.wrapper, className]
        .filter(Boolean)
        .join(' ')}
    >
      <div className={CARD_CLASS_NAMES.mobileIconWrapper}>
        <Icon icon="lock" className={CARD_CLASS_NAMES.mobileIcon} />
      </div>
      <div>
        <div className={CARD_CLASS_NAMES.card}>
          {showIcon && (
            <Icon icon="clock" size={60} className="mr-4 text-green-600" />
          )}
          <div className={CARD_CLASS_NAMES.cardInnerWrapper}>
            <Text text={IN_REVIEW_TITLE} className={CARD_CLASS_NAMES.title} />
            <p className={CARD_CLASS_NAMES.description}>{heading}</p>
          </div>
        </div>
        {showIcon && (
          <div>
            <ReviewTimeline activeKey={activeKey} showFca={showFca} />
          </div>
        )}
      </div>
    </div>
  );
};

CompanyInReview.defaultProps = {
  showIcon: false,
};

export default CompanyInReview;
