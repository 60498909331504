import { BreakPoints, Button, Text } from '@kanda-libs/ks-design-library';

import { Card } from './components';
import {
  CLASS_NAMES,
  SKELETONS,
  TITLE,
} from './FinanceApplicationProgress-constants';
import Container from './FinanceApplicationProgress-container';

const ViewComponent = ({}) => (
  <Container>
    {({ isLoading, showStatus }) =>
      showStatus && (
        <div className={CLASS_NAMES.container}>
          <div className="flex flex-row w-full justify-between gap-4">
            <BreakPoints
              desktop={
                <Text
                  skeletonProps={SKELETONS.title}
                  isLoading={isLoading}
                  className={CLASS_NAMES.title}
                  text={TITLE}
                />
              }
            />
            <a
              href="https://clt1489884.bmeurl.co/10B8FC47"
              target="_blank"
              rel="noreferrer"
              className="mb-3 md:mb-auto ml-auto"
            >
              <Button.InlineLink
                variant="turquoise"
                id="payouts-explainer"
                label="Payouts Explained"
              />
            </a>
          </div>
          <Card />
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'Job-Desktop-FinanceApplicationProgress-view';

export default ViewComponent;
