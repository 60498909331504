import { Field, FormTheme } from '@kanda-libs/ks-component-ts';
import { Price } from '@kanda-libs/ks-design-library';

import Container from './Percentage-container';
import { DEPOSIT_PERCENTAGE_VALIDATION } from './Percentage-constants';

const ViewComponent = () => (
  <Container>
    {({ showFields, total }) =>
      showFields ? (
        <div className="flex flex-row w-full justify-between md:ml-4">
          <div className="mt-3 -mb-8 md:-mt-1.5 md:-mb-8 w-16">
            <FormTheme variant="default">
              <Field.Validator validation={DEPOSIT_PERCENTAGE_VALIDATION}>
                <Field.NumberInput
                  name="depositPercentage"
                  decimalScale={0}
                  placeholder="10%"
                  suffix="%"
                />
              </Field.Validator>
            </FormTheme>
          </div>
          <div className="flex flex-row mt-auto mb-1 md:my-auto">
            <Price
              amount={total}
              color="neutral-500"
              poundsClassName="text-16-20-em text-green-500 md:text-neutral-500"
              centsClassName="text-10-14-em mt-px text-green-500 md:text-neutral-500"
            />
            <span className="text-16-20-em md:text-14-22 text-neutral-500 my-auto md:mt-auto ml-2">
              incl. VAT
            </span>
          </div>
        </div>
      ) : (
        <></>
      )
    }
  </Container>
);

ViewComponent.displayName = 'CreateJob-Desktop-Deposit-Fields-Percentage-view';

export default ViewComponent;
