import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useReferralSignUpContext } from '../../../../../context';

export default function useSignUpFormFieldsTerms() {
  const terms = useWatch({ name: 'terms_and_conditions' });

  const { setDisabled } = useReferralSignUpContext();

  useEffect(() => {
    if (!terms) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [setDisabled, terms]);
}
