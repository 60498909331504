import { Card, StatusTag } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Link } from 'react-router-dom';

import { CLASS_NAMES } from '../../Jobs-constants';
import Title from '../Title';
import { CARD_PROPS, SKELETONS, STATUS_TAG_PROPS } from './Card-constants';

import { Activity } from '../../../../../components';

interface ViewComponentProps {
  /**
   * First name of customer
   */
  firstName?: string;
  /**
   * Last name of customer
   */
  lastName?: string;
  /**
   * Quoted to
   */
  quotedTo?: string;
  /**
   * Price
   */
  price?: number;
  /**
   * Job status
   */
  status?: string;
  /**
   * Finance status
   */
  financeStatus?: string;
  /**
   * Date updated
   */
  updatedAt?: string | Date;
  /**
   * Title of job
   */
  title?: string;
  /**
   * Link for job
   */
  link?: string;
  /**
   * Display Loading state
   */
  isLoading?: boolean;
  /**
   * Display placeholder jobs
   */
  showingPlaceholderJobs?: boolean;
}

const ViewComponent = ({
  title,
  link,
  updatedAt,
  status,
  financeStatus,
  price,
  firstName,
  lastName,
  quotedTo,
  isLoading,
  showingPlaceholderJobs,
}: ViewComponentProps) => (
  <Link className={CLASS_NAMES.link} to={link || URLS.exampleJob}>
    <Card
      {...CARD_PROPS}
      variant="simple"
      isLoading={isLoading}
      showLoadingSkeleton={SKELETONS}
      className={showingPlaceholderJobs ? CLASS_NAMES.cardPreview : undefined}
      title={
        <Title
          firstName={firstName}
          lastName={lastName}
          status={status}
          financeStatus={financeStatus}
          total={price as number}
          showingPlaceholderJobs={showingPlaceholderJobs}
        />
      }
      option={
        <Activity
          date={updatedAt as string}
          status={status as string}
          financeStatus={financeStatus as string}
        />
      }
    >
      <>
        {status === 'draft' && (
          <StatusTag
            {...STATUS_TAG_PROPS}
            status="draft"
            size={21}
            variant="solid"
          />
        )}
        {quotedTo && (
          <StatusTag
            {...STATUS_TAG_PROPS}
            status="lead"
            size={21}
            variant="solid"
          />
        )}
        {title}
      </>
    </Card>
  </Link>
);

ViewComponent.defaultProps = {
  firstName: '',
  lastName: '',
  price: 0,
  status: 'draft',
  financeStatus: 'not_applied',
  updatedAt: '',
  title: '',
  link: '',
  isLoading: false,
};

ViewComponent.displayName = 'MobileJobCard-view';

export default ViewComponent;
