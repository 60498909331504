import Container from './CompanyInfo-container';
import { Form, Verify } from './components';

const ViewComponent = () => (
  <Container>
    {({ isVerified }) => (isVerified ? <Form /> : <Verify />)}
  </Container>
);

ViewComponent.displayName = 'SetupCompany-CompanyInfo-view';

export default ViewComponent;
