export const CLASS_NAMES = {
  optionWrapper:
    'flex flex-col mb-4 md:px-4 md:pt-5 pb-0 md:pb-3 border-0 md:border border-neutral-300 rounded-lg md:shadow-none',
  lastOptionWrapper: 'mb-8 md:-mb-6',
  optionCard: 'flex flex-1 flex-col mb-5 mt-0 md:mt-0 -mx-4 last:mb-0',
  optionTitle:
    'text-14-22-em text-neutral-900 md:text-style-g-em md:text-neutral-700 mb-0 mb-1',
  optionDescription: 'text-12-18 text-neutral-600 mb-4',
  optionWarningWrapper: 'flex items-center gap-x-1 md:mb-4',
  optionWarning: 'text-12-18 text-neutral-600',
  optionIcon: 'text-neutral-600 w-3 h-3 mr-1',
  paymentMethodsCard:
    'md:shadow-card md:rounded-lg md:border md:border-solid md:border-neutral-200 md:overflow-hidden mb-6',
};

export const PAYMENT_OPTIONS_TITLE = 'Payment Methods';

export const PAYMENT_OPTIONS = [
  {
    title: 'Decline job',
    name: 'decline_job',
  },
];
