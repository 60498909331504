import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { FIELD_NAMES } from './UserForm-constants';
import useMe from '../../../../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { me, isValidating: isLoading } = useMe();
  const isSubmitting = useSelector(selectors.authUser.getIsSubmitting);

  const { showSuccess } = useToast();
  const onError = useApiError('Error updating profile');

  const form = useForm({ mode: 'onBlur' });

  const { setValue } = form;

  const onSubmit = useCallback(
    (formValues) => {
      dispatch(
        actions.putMe({
          body: {
            name: `${formValues.firstName} ${formValues.lastName}`,
            phone: `+44${
              formValues.phoneNumber[0] === '0'
                ? formValues.phoneNumber.slice(1)
                : formValues.phoneNumber
            }`,
          },
          onError,
          onSuccess: () => {
            showSuccess('Profile updated');
          },
        }),
      );
    },
    [dispatch, onError, showSuccess],
  );

  const nameRef = useRef<string | null>(null);

  useEffect(() => {
    if (!me || !me?.name) return;
    if (me.name === nameRef.current) return;
    nameRef.current = me.name;
    const {
      0: firstName,
      length: n,
      [n - 1]: lastName,
    } = (me.name || '').split(' ');
    if (firstName) setValue(FIELD_NAMES.firstName, firstName);
    if (lastName) setValue(FIELD_NAMES.lastName, lastName);
  }, [me, setValue]);

  const phoneRef = useRef<string | null>(null);
  useEffect(() => {
    if (!me || !me?.phone) return () => {};
    if (me.phone === phoneRef.current) return () => {};

    phoneRef.current = me.phone;
    const phoneNumber = me.phone.replace('+44', '0');
    if (phoneNumber) setValue(FIELD_NAMES.phoneNumber, phoneNumber);

    return () => {};
  }, [me, setValue]);

  return children({
    form,
    onSubmit,
    isSubmitting,
    isLoading,
  });
};

ContainerComponent.displayName = 'MyAccount-AccountSettings-UserForm-container';

export default ContainerComponent;
