import { DesktopLayoutBoxed } from '@kanda-libs/ks-design-library';
import clsx from 'clsx';

interface BoxedSidebarCardProps {
  sidebar: JSX.Element;
  children: JSX.Element | JSX.Element[];
  footer?: JSX.Element;
  useLayout?: boolean;
}

const BoxedSidebarCard = ({
  sidebar,
  children,
  footer,
  useLayout,
}: BoxedSidebarCardProps) => {
  const content = (
    <div
      className={clsx(
        'flex flex-col flex-1 w-full px-6 mx-auto -mt-6 md:mt-0 md:flex-row md:pt-16 md:px-10 lg:px-20 lg:pt-20 max-w-256',
        !footer && 'md:pb-16 lg:pb-20',
      )}
    >
      {sidebar}
      <div className="flex flex-col w-full h-full mx-auto md:w-7/12 md:min-w-108 max-w-120">
        {children}
      </div>
    </div>
  );

  if (!useLayout) {
    return content;
  }

  return (
    <DesktopLayoutBoxed help>
      <>
        {content}
        {footer}
      </>
    </DesktopLayoutBoxed>
  );
};

BoxedSidebarCard.defaultProps = {
  useLayout: true,
};

export default BoxedSidebarCard;
