export const ACCOUNT_NAME_PROPS = {
  field: {
    name: 'guarantor.bank_account.account_name',
    label: 'Name on Company Bank Account',
    placeholder: 'Name on account',
  },
  validation: {
    required: { value: true, message: 'Account name is required.' },
  },
};

export const ACCOUNT_NUMBER_PROPS = {
  field: {
    name: 'guarantor.bank_account.account_number',
    label: 'Company Bank Account Number',
    placeholder: 'Account number',
  },
  validation: {
    minLength: {
      value: 1,
      message: 'Account Number requires minimum length of 1',
    },
    pattern: {
      value: /^[0-9]{8}$/,
      message:
        'Account Number must be 8 digits. If your account number is less than 8 digits long please add a 0 at the start.',
    },
    required: { value: true, message: 'Account Number is required.' },
  },
};

export const SORT_CODE_PROPS = {
  field: {
    name: 'guarantor.bank_account.sort_code',
    label: 'Company Bank Account Sort Code',
    placeholder: 'Sort code',
    type: 'sortCode',
  },
  validation: {
    required: { value: true, message: 'Sort code is required.' },
  },
};
