import type { FunctionComponent } from 'react';

import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Text,
} from '@kanda-libs/ks-design-library';

import { CompanyBanner } from 'pages/MyAccount/components';

import useCreateExampleJob from './useCreateExampleJob';

import exampleJobImage from '../../assets/example-job.png';

const ExampleJob: FunctionComponent = function () {
  const { onCreateExampleJob, isLoading } = useCreateExampleJob();

  return (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent width="100" noPadding>
        <div className="p-5 md:px-18 lg:px-38 md:pt-18 md:pb-18">
          <span className="-mx-4 -mb-4">
            <CompanyBanner homeBanner />
          </span>
          <div className="w-full grid md:grid-cols-2 gap-x-8">
            <div className="flex flex-row items-center justify-center ">
              <img
                src={exampleJobImage}
                alt="Send your self an example job"
                className="md:max-w-[400px] w-auto"
              />
            </div>
            <div className="flex flex-col justify-center text-left md:w-72">
              <Text
                text="Send yourself an example job to preview what your customers will see."
                className="mb-6 text-24-28-em"
              />
              <Text
                text="Send yourself an example Job by email to see what your customers will see when they receive a Job on Kanda."
                className="mb-6 text-neutral-600 text-16-24"
              />
              <Button.Text
                id="dashboard-example-job"
                label="Create example job"
                icon="send"
                className="mb-6"
                onClick={onCreateExampleJob}
                isLoading={isLoading}
              />
              <Text
                text="You can safely apply for finance on the example"
                className="text-center text-neutral-600"
              />
            </div>
          </div>
        </div>
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );
};

export default ExampleJob;
