import { Button, Header, Layout } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content } from '../components';

import { URLS } from '../../../../config';

const ViewComponent = () => (
  <Layout
    noBorder
    header={
      <Header.Base
        lines
        options={[
          <Link to={URLS.login} key="back-button">
            <Button.Icon
              id="reset-password-confirmation-mobile-close"
              icon="close"
            />
          </Link>,
        ]}
      />
    }
  >
    <Content />
  </Layout>
);

ViewComponent.displayName = 'ResetPassword-Confirmation-Mobile-view';

export default ViewComponent;
