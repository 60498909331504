import { Button, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { TYPE_FORM_LINK } from './constants';

const RequestOwnDeposit: FunctionComponent = function () {
  return (
    <div className="flex flex-col mt-6 mb-4">
      <Text
        text="Take Your Own Deposit"
        className="mb-1 text-16-20-em text-neutral-900"
      />
      <div className="relative pb-2/3 h-0 my-4">
        <iframe
          src="https://www.loom.com/embed/9ee1d6b706c64222a2e21500b8027193"
          title="request new rates"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full border-0"
        />
      </div>
      <Text
        text={
          <>
            Request the ability to take your own deposit for jobs finance
            through Kanda. <strong>Note:</strong> If you opt to take your own
            deposit, you are responsible for taking this from your customer and
            they are responsible for making this payment to you.
          </>
        }
        className="text-16-20 text-neutral-600"
      />
      <div className="mt-4">
        <a href={TYPE_FORM_LINK} target="_blank" rel="noreferrer noopener">
          <Button.Text id="request-new-rates" label="Request own deposit" />
        </a>
      </div>
    </div>
  );
};

export default RequestOwnDeposit;
