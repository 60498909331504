import { useMemo } from 'react';

import { getIcon } from './Icon-functions';

import useCurrentJob from '../../../../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { isLoading, job, checkJob } = useCurrentJob();

  const financeStatus = useMemo(() => {
    if (!job || !checkJob) return 'not_applied';
    if (job?.finance_status === 'financed') {
      const payment = [...(job?.payments || [])]
        ?.sort(
          (p1, p2) =>
            (new Date(
              p2?.metadata?.created_at as unknown as number,
            ) as unknown as number) -
            (new Date(
              p1?.metadata?.created_at as unknown as number,
            ) as unknown as number),
        )
        ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
      if (!payment) return job?.finance_status;
      if (
        ['accepted_sign_document', 'accepted_deposit_required']?.includes(
          checkJob?.current_status || '',
        )
      )
        return 'applied_for_finance';
      return job?.finance_status;
    }
    return job?.finance_status;
  }, [job, checkJob]);

  const { icon, color } = useMemo(
    () => getIcon(financeStatus),
    [financeStatus],
  );

  return children({
    icon,
    color,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Icon-container';

export default ContainerComponent;
