import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';

export const BUTTON_PROPS = {
  id: 'create-job-kanda-add-line-item',
  variant: 'grey' as ButtonLinkVariant,
  size: 14,
  left: true,
  icon: 'plus',
  className: 'py-4 border border-neutral-300 rounded-xl w-full',
  label: 'Add Line Item',
};
