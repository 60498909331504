import { PHONE_REGEX } from '../../../../../../../../../constants';

export const PHONE_NUMBER = {
  validation: {
    required: {
      value: true,
      message: 'Phone number is required',
    },
    pattern: {
      value: PHONE_REGEX,
      message: 'Phone number must be a valid UK phone number',
    },
  },
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Contact Phone Number',
  placeholder: 'Contact phone number',
};
