import { useContext, useEffect, useState, useRef } from 'react';
import { useForm, useWatch } from '@kanda-libs/ks-component-ts';
import { useClasses } from '@kanda-libs/ks-design-library';
import { useDebounce } from 'use-debounce';

import {
  CLASS_NAMES,
  PRICE_FILTER_TYPE,
  DEBOUNCE_INTERVAL,
} from './PriceFilter-constants';
import { getDefaultValues } from './PriceFilter-functions';

import Context from '../../../../../Context';

interface ContainerValue {
  gt?: number;
  lt?: number;
}

const ContainerComponent = ({ children, handleClose }) => {
  const { filter, updateFilter } = useContext(Context);
  const filterRef = useRef(filter);

  const [value, setValue] = useState<ContainerValue>();
  const [error, setError] = useState<string | undefined>();
  const [debounceValue] = useDebounce(value, DEBOUNCE_INTERVAL);

  const form = useForm({
    defaultValues: getDefaultValues(filterRef.current?.price),
  });

  const [type, from, to] = useWatch({
    control: form.control,
    name: ['filter-type', 'from', 'to'],
  });

  useEffect(() => {
    if (from && type === PRICE_FILTER_TYPE.FROM) {
      setValue({ gt: from });
    }

    if (from && to && type === PRICE_FILTER_TYPE.BETWEEN) {
      setValue({ gt: from, lt: to });
    }
  }, [from, to, type]);

  useEffect(() => {
    setError(undefined);

    if (debounceValue) {
      if (
        debounceValue.gt &&
        debounceValue.lt &&
        debounceValue.lt <= debounceValue.gt
      ) {
        setError('Incorrect values');
        return;
      }
      const update = `${debounceValue.gt ? `from:${debounceValue.gt};` : ''}${
        debounceValue.lt ? `to:${debounceValue.lt};` : ''
      }`;
      updateFilter('price', update);
    }
  }, [debounceValue, updateFilter, handleClose]);

  const classNames = useClasses(CLASS_NAMES, {
    variants: {
      type,
    },
  });

  const onSubmit = () => {};

  return children({
    form,
    onSubmit,
    type,
    classNames,
    error,
  });
};

ContainerComponent.displayName = 'StatusFilters-container';

export default ContainerComponent;
