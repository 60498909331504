import type { FunctionComponent } from 'react';
import useNoFinanceOptionsWarning from './useNoFinanceOptionsWarning';

const NoFinanceOptionsWarning: FunctionComponent = function () {
  const { label } = useNoFinanceOptionsWarning();

  return (
    <div className="p-4 mb-4 text-orange-200 border border-orange-200 border-solid rounded-xl text-neutral-600 amber-warning">
      {label}
    </div>
  );
};

export default NoFinanceOptionsWarning;
