import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { URLS } from '../../config';

const ContainerComponent = ({}) => {
  const { push } = useHistory();

  useEffect(() => {
    push(URLS.subscription);
  }, [push]);

  return null;
};

ContainerComponent.displayName = 'SubscriptionReturn-container';

export default ContainerComponent;
