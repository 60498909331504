import { Button, Text } from '@kanda-libs/ks-design-library';
import SplashScreen from '../../../../../../../../components/SplashScreen';
import { Content as DirectorVerification } from '../../../../../../../MyAccount/DirectorVerification';
import useVerificationStatus from './useVerificationStatus';

const VerificationStatus = () => {
  const { showStatus, setShowStatus, email } = useVerificationStatus();

  if (showStatus) {
    return (
      <div className="flex flex-col -mt-6 md:mt-0 md:flex-row flex-1 md:p-16 lg:p-20 w-full max-w-256 mx-auto max-w-3xl">
        <DirectorVerification centered />
      </div>
    );
  }

  return (
    <SplashScreen
      icon="send-quote-color"
      title="Check your email"
      subText={`Follow the link we emailed to ${email} to verifying your identity.`}
      cta={
        <Button.Text
          id="onboarding-verification-status-continue"
          label="Continue"
          icon="arrow-right"
          onClick={() => {
            setShowStatus(true);
          }}
        />
      }
      append={
        <Text
          text="No email? Click to resend"
          className="mt-8 text-green-300 text-style-h-em underline"
        />
      }
    />
  );
};

export default VerificationStatus;
