import type { TrustPilotEmbedProps } from '../types';
import { useTrustPilotEmbed } from '../useTrustPilotEmbed';

const Mini: React.FunctionComponent<TrustPilotEmbedProps> = function ({
  className,
  theme = 'light',
}) {
  const { elementRef } = useTrustPilotEmbed();

  return (
    <div
      id="trustbox"
      ref={elementRef}
      className={`trustpilot-widget ${className}`}
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="6230d3f88d7b1f06076ebc8c"
      data-style-height="120px"
      data-style-width="100%"
      data-theme={theme}
    >
      <a
        href="https://uk.trustpilot.com/review/getkanda.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default Mini;
