import { useState, useCallback } from 'react';
import { useToast } from '@kanda-libs/ks-design-library';
import { useForm } from '@kanda-libs/ks-component-ts';
import {
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import useMe from '../../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const form = useForm({ mode: 'onBlur' });

  const { getValues } = form;

  const { me, isValidating: isLoading } = useMe();

  const { mutate: reauthenticate, isSubmitting: isReauthenticating } =
    useMutate(FirebaseAuthService.reauthenticate);

  const { mutate: updatePassword, isSubmitting: isUpdating } = useMutate(
    FirebaseAuthService.updatePassword,
  );

  const { showError } = useToast();

  const passwordValidation = {
    required: {
      value: true,
      message: 'Password is required',
    },
    validate: {
      value: () => getValues()?.password_strength > 2,
      message: 'Password is not strong enough',
    },
  };

  const confirmPasswordValidation = {
    required: {
      value: true,
      message: 'Password confirmation is required',
    },
    validate: {
      value: (confirmation) => getValues()?.password === confirmation,
      message: "Passwords don't match",
    },
  };

  const onSubmit = useCallback(
    (formValues) => {
      reauthenticate(formValues.currentPassword).then(
        ({ error: reauthError }) => {
          if (reauthError) {
            showError('Invalid credentials');
            return;
          }
          updatePassword(formValues.password).then(({ error }) => {
            if (error) {
              showError('Error updating password');
              return;
            }
            setShowConfirmation(true);
          });
        },
      );
    },
    [showError, updatePassword, reauthenticate],
  );

  const email = me?.email || '';

  const isSubmitting = isReauthenticating || isUpdating;

  const disabled = isLoading || isSubmitting;

  return children({
    form,
    onSubmit,
    isSubmitting,
    showConfirmation,
    isLoading,
    disabled,
    passwordValidation,
    confirmPasswordValidation,
    email,
  });
};

ContainerComponent.displayName = 'ChangePassword-container';

export default ContainerComponent;
