import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';

import Container from './Desktop-container';

import { CLASS_NAMES } from '../Option-constants';

interface ViewComponentProps {
  /**
   * Payment method title
   */
  title: string;
  /**
   * Payment method field name
   */
  name: string;
  /**
   * Payment method description
   */
  subtitle?: string;
}

const ViewComponent = ({ title, subtitle, name }: ViewComponentProps) => (
  <Container name={name}>
    {({ onClick, checked, paymentMethod, disabled }) => (
      <div className={CLASS_NAMES.container}>
        <div className="my-auto mr-4">
          <FormTheme variant="clean">
            <FieldHandle.Checkbox
              id={paymentMethod}
              name={paymentMethod}
              disabled={disabled}
              checked={checked}
              onClick={() => onClick(paymentMethod)}
            />
          </FormTheme>
        </div>
        <div className={CLASS_NAMES.text}>
          <Text text={title} className={CLASS_NAMES.title} />
          {subtitle && (
            <Text text={subtitle} className={CLASS_NAMES.subtitle} />
          )}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Option-Desktop-view';

ViewComponent.defaultProps = {
  subtitle: null,
};

export default ViewComponent;
