import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

import { IDS } from './Options-constants';

const ContainerComponent = ({ children, index }) => {
  const { showModal } = useContext(ModalsWrapperContext);

  const modalId = `${IDS.modal}.${index}`;

  /**
   * Function to show delete item modal
   */
  const handleShowModal = useCallback(
    () => showModal(modalId),
    [showModal, modalId],
  );

  return children({
    modalId,
    handleShowModal,
  });
};

ContainerComponent.displayName = 'Item-Options-Container';

export default ContainerComponent;
