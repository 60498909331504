import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import {
  MANUAL_PAYMENT_OPTION,
  DECLINE_OPTION,
} from './PaymentOptions-constants';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const checkoutOptions = data?.job?.checkout_options;

  const showManualPaymentOption =
    checkoutOptions && checkoutOptions?.includes('pay_on_completion');
  const showDeclineJobOption =
    checkoutOptions && checkoutOptions?.includes('decline_job');

  const options = [
    showManualPaymentOption && MANUAL_PAYMENT_OPTION,
    showDeclineJobOption && DECLINE_OPTION,
  ].filter(Boolean);

  return children({
    options,
  });
};

ContainerComponent.displayName =
  'PaymentMethods-Desktop-PaymentOptions-container';

export default ContainerComponent;
