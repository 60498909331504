import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content } from '../components';

import { URLS } from '../../../config';

const ViewComponent = () => (
  <DesktopLayoutBoxed
    help
    options={[
      <Link key="close" to={URLS.home}>
        <Button.Icon id="veriff-close-desktop" icon="close" />
      </Link>,
    ]}
  >
    <DesktopLayoutBoxedContent>
      <Content />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'Veriff-Desktop-view';

export default ViewComponent;
