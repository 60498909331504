export const SKELETON_DATA = [
  { refIndex: 'loading-0' },
  { refIndex: 'loading-1' },
  { refIndex: 'loading-2' },
];

export const SEARCH_HEADER_PROPS = {
  stickyHeader: true,
  noBorder: true,
};

export const SEARCH_OPTIONS = {
  keys: [
    { name: 'name', weight: 3 },
    { name: 'address', weight: 1 },
    { name: 'email', weight: 2 },
    { name: 'phoneNumber', weight: 2 },
  ],
  includeMatches: true,
  matchAllOnEmptyQuery: true,
};
