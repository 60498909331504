import { Button, Popover } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { FILTERS } from '../constants';
import JobTableFilterPopover from '../JobTableFilterPopover';
import type { JobTableFilterPopoverItemType } from '../JobTableFilterPopover/JobTableFilterPopoverItem/types';

const Desktop: FunctionComponent = function () {
  return (
    <Popover.Advanced
      size="medium"
      variant="green"
      selectedColorVariant="green"
      items={Object.keys(FILTERS).map((filterKey) => ({
        name: FILTERS[filterKey],
        action: {
          type: 'open_panel',
          panel: () => (
            <JobTableFilterPopover
              type={filterKey as JobTableFilterPopoverItemType}
            />
          ),
        },
      }))}
      button={
        <Button.Icon id="job-table-filter" icon="filter" variant="ghost-grey" />
      }
    />
  );
};

export default Desktop;
