import { API_DOCS_LINK } from 'components/DesktopHeader/DesktopHeader-constants';
import { URLS } from '../../config/application';

export const MENU_LINKS = [
  { icon: 'home', stroke: 'neutral-600', name: 'Home', to: URLS.home },
  { icon: 'job', stroke: 'neutral-600', name: 'Jobs', to: URLS.jobs },
  {
    icon: 'currency',
    stroke: 'neutral-600',
    name: 'Refer another company',
    to: URLS.referrals,
  },
  {
    icon: 'settings',
    name: 'Account settings',
    to: URLS.myAccount,
    stroke: 'neutral-600',
  },
];

export const MENU_ADMIN_LINKS = [
  {
    icon: 'note',
    name: 'API Docs',
    to: window.location.pathname,
    onClick: () => window.open(API_DOCS_LINK, '_blank')?.focus(),
    stroke: 'neutral-600',
  },
];

export const LOGOUT_LINK = {
  icon: 'logout',
  name: 'Log Out',
  to: URLS.logout,
  stroke: 'neutral-600',
};

export const MENU_VERIFICATION_LINKS = {
  icon: 'verification',
  name: 'Director verification',
  to: URLS.directorVerification,
  stroke: 'neutral-600',
};

export const STAFF_IMITATE_USER_MENU_LINK = {
  name: 'Imitate user',
  icon: 'user',
  to: URLS.staffLogin,
};

export const SKELETONS = {
  banner: {
    height: 60,
  },
  companyName: {
    width: 160,
  },
  plan: {
    width: 120,
  },
};
