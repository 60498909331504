import Container from './Desktop-container';

import { Kanda, Custom } from './components';

const ViewComponent = ({}) => (
  <Container>
    {({ classNames, smaller, kanda }) =>
      kanda ? <Kanda classNames={classNames} smaller={smaller} /> : <Custom />
    }
  </Container>
);

ViewComponent.displayName = 'LineItem-Desktop-view';

export default ViewComponent;
