import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Custom-container';
import { CLASS_NAMES, DESCRIPTION } from './Custom-constants';
import { Totals } from '../LineItems/components';

import Wrapper from '../Wrapper';

const ViewComponent = () => (
  <Container>
    {({ onClick }) => (
      <div className={CLASS_NAMES.container}>
        <Wrapper onEdit={onClick} description={DESCRIPTION}>
          <div className={CLASS_NAMES.content}>
            <BreakPoints
              mobile={
                <div className={CLASS_NAMES.titleWrapper}>
                  <p className={CLASS_NAMES.heading}>{DESCRIPTION}</p>
                </div>
              }
            />
            <Totals />
          </div>
        </Wrapper>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Custom-view';

export default ViewComponent;
