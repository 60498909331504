import { PHONE_REGEX } from '../../../../../../../constants';

export const PHONE_NUMBER_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Mobile Number',
  name: 'phone',
  placeholder: 'Mobile number',
};

export const PHONE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};
