import { useToast } from '@kanda-libs/ks-design-library';
import { actions, Company } from '@kanda-libs/ks-frontend-services';
import type { WelcomeSkipBanner } from '@kanda-libs/ks-frontend-services/src/generated/components/schemas/WelcomeSkipBanner';
import { EMPTY_WELCOME_PACK } from 'common/constants';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useCurrentCompany from 'hooks/useCurrentCompany';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';

export interface CompleteWelcomePackSectionHook {
  isSubmitting: boolean;
  onFinish: () => void;
}

export default function useCompleteWelcomePackSection(
  key: keyof WelcomeSkipBanner,
): CompleteWelcomePackSectionHook {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);
  const { company } = useCurrentCompany();
  const { showError, showSuccess } = useToast();

  const { push } = useHistory();

  const onFinish = useCallback(() => {
    if (!company?.id) {
      showError('There was an error saving your company. Please try again.');
      return;
    }
    dispatch(
      actions.putCompany({
        params: {
          id: company.id,
        },
        onSuccess: () => {
          showSuccess('Your company has been saved.');
          push(URLS.home);
        },
        body: {
          ...company,
          welcome_pack: {
            ...EMPTY_WELCOME_PACK,
            ...company.welcome_pack,
            [key]: 'yes',
          },
        } as Company,
      }),
    );
  }, [company, key, dispatch, push, showSuccess, showError]);

  return {
    isSubmitting,
    onFinish,
  };
}
