import {
  useFormContext,
  type PostcodeCallback,
  type ValidationItems,
  type ValidationProps,
} from '@kanda-libs/ks-component-ts';
import type { Address } from '@kanda-libs/ks-frontend-services';
import { useCallback, useState } from 'react';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from 'utils';

export interface DirectorAddressHook {
  setPostcodeName: (name: string) => void;
  postcodeCallback: PostcodeCallback;
  postcodeName?: string;
  addresses: Address[] | null;
  manual: boolean;
  enterManually: () => void;
  selectProps: ValidationProps;
}

export default function useDirectorAddress(
  name: string,
  isArray = false,
  index: number | undefined = undefined,
): DirectorAddressHook {
  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [postcodeName, setPostcodeName] = useState<string | undefined>();

  const [manual, setManual] = useState(false);

  const postcodeCallback = useCallback((results) => setAddresses(results), []);
  const enterManually = useCallback(() => setManual(true), []);

  const { getValues } = useFormContext();

  const validation = {
    validate: {
      value: () => validateAddressSelect(getValues(), name, isArray, index),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(validation),
    validationErrors: getValidationErrors(validation),
  } as unknown as ValidationProps;

  return {
    setPostcodeName,
    postcodeCallback,
    postcodeName,
    addresses,
    manual,
    enterManually,
    selectProps,
  };
}
