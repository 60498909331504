import { Price } from '@kanda-libs/ks-design-library';

import Container from './Price-container';

const ViewComponent = () => (
  <Container>
    {({ price, isLoading, classNames }) => (
      <Price isLoading={isLoading} {...classNames.price} amount={price} />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Price-view';

export default ViewComponent;
