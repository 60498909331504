import { Icon, Text } from '@kanda-libs/ks-design-library';

const SubHeaderPoints: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-8 w-full">
      <div className="flex flex-row">
        <div className="flex w-5 h-5 min-w-5 min-h-5 bg-lavender-200 rounded-full my-auto">
          <Icon
            icon="check"
            className="m-auto"
            stroke="neutral-000"
            size={12}
          />
        </div>
        <Text
          text="Apply for enterprise level rates"
          className="text-16-24-em text-lavender-200 my-auto ml-3"
        />
      </div>
      <div className="flex flex-row">
        <div className="flex w-5 h-5 min-w-5 min-h-5 bg-lavender-200 rounded-full my-auto">
          <Icon
            icon="check"
            className="m-auto"
            stroke="neutral-000"
            size={12}
          />
        </div>
        <Text
          text="Save money based on finance volume"
          className="text-16-24-em text-lavender-200 my-auto ml-3"
        />
      </div>
    </div>
  );
};

export default SubHeaderPoints;
