import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const TAG = {
  className: 'inline-flex mr-2 mb-2',
};

export const BUTTON = {
  id: 'home-banner-mobile-filters-close',
  variant: 'custom' as ButtonIconVariant,
  className: 'ml-2',
  size: 12,
  iconProps: {
    size: 12,
  },
  icon: 'close',
};
