import {
  SkeletonLoader,
  Icon,
  BreakPoints,
} from '@kanda-libs/ks-design-library';

import Container from './CompanyDetails-container';
import {
  CLASS_NAMES,
  ICON_PROPS,
  HEADING,
  SUBHEADING,
  ARROW_PROPS,
} from './CompanyDetails-constants';

const ViewComponent = () => (
  <Container>
    {({ showBanner, saveDraft, ...rest }) =>
      showBanner && (
        <SkeletonLoader
          wrapperClassName={CLASS_NAMES.skeletonWrapper}
          afterLoading={
            <button
              type="button"
              onClick={saveDraft}
              className={CLASS_NAMES.link}
            >
              <div className={CLASS_NAMES.container}>
                <Icon {...ICON_PROPS} />
                <div className={CLASS_NAMES.headings}>
                  <p className={HEADING.className}>{HEADING.text}</p>
                  <p className={SUBHEADING.className}>{SUBHEADING.text}</p>
                </div>
              </div>
              <BreakPoints
                mobile={<Icon {...ARROW_PROPS.mobile} />}
                desktop={<Icon {...ARROW_PROPS.desktop} />}
              />
            </button>
          }
          {...rest}
        />
      )
    }
  </Container>
);

ViewComponent.displayName = 'CompanyDetails-view';

export default ViewComponent;
