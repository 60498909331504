import type { FunctionComponent } from 'react';

import Calculator from './Calculator';
import InviteYourDeveloper from './InviteYourDeveloper';
import InviteYourDeveloperSuccess from './InviteYourDeveloperSuccess';
import Legal from './Legal';
import Start from './Start';
import type { UpdateYourWebsitePage } from './types';

export const PAGES: Record<UpdateYourWebsitePage, FunctionComponent> = {
  start: Start,
  legal: Legal,
  calculator: Calculator,
  'invite-your-developer': InviteYourDeveloper,
  'invite-your-developer-success': InviteYourDeveloperSuccess,
};

export const PAGE_KEYS = Object.keys(PAGES) as string[];
