import { AlertBanner } from '@kanda-libs/ks-design-library-new';
import { FormTheme } from '@kanda-libs/ks-component-ts';

import Container from './Field-container';
import { CLASS_NAMES } from './Field-constants';
import { Name, Contact, Address } from './components';
import useFieldDisabledProps from './useFieldDisabledProps';

const ViewComponent = () => {
  const { isExampleJob, isLeadJob, disabledProps } = useFieldDisabledProps();

  return (
    <Container>
      {() => (
        <>
          {isExampleJob && (
            <div className="mb-6">
              <AlertBanner>
                You cannot edit customer details on an example job
              </AlertBanner>
            </div>
          )}
          {isLeadJob && (
            <div className="mb-6">
              <AlertBanner>
                You cannot edit customer details on a job that has been sent to
                you by Kanda
              </AlertBanner>
            </div>
          )}
          <div className={CLASS_NAMES.container} {...disabledProps}>
            <FormTheme variant="inline">
              <div className="px-6 py-8 border rounded-lg border-neutral-300">
                <Name />
                <Contact />
                <Address />
              </div>
            </FormTheme>
          </div>
        </>
      )}
    </Container>
  );
};

ViewComponent.displayName = 'CreateJob-Desktop-Customer-Field-view';

export default ViewComponent;
