import { Header, Rows } from './components';

const ViewComponent = () => (
  <div className="w-full pt-4 pb-16 overflow-scroll">
    <div className="min-w-166">
      <Header />
      <Rows />
    </div>
  </div>
);

ViewComponent.displayName = 'NotificationPreferences-Content-Desktop-view';

export default ViewComponent;
