import { Button } from '@kanda-libs/ks-design-library';

import {
  DEFAULT_NEXT_ICON,
  DEFAULT_STEP_LABEL,
} from '../../../../Page/Page-constants';

const ViewComponent = () => (
  <Button.Text
    submit
    id="create-job-mobile-deposit-next"
    icon={DEFAULT_NEXT_ICON}
  >
    <>{DEFAULT_STEP_LABEL}</>
  </Button.Text>
);

ViewComponent.displayName = 'PaymentAndFinanceOptions-NextButton-view';

export default ViewComponent;
