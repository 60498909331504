import { Field, FormTheme } from '@kanda-libs/ks-component-ts';
import {
  EXTRA_FIELD_PROPS,
  SECTOR_FIELD_PROPS,
  SECTOR_FIELD_VALIDATION,
  TYPE_FIELD_PROPS,
  TYPE_FIELD_VALIDATION,
} from './constants';
import BusinessConfigFilterableSelect from './FilterableSelect';
import useBusinessConfig from './useBusinessConfig';

const BusinessConfig: React.FunctionComponent = function () {
  const {
    sectorOptions,
    showTradeType,
    tradeTypeOptions,
    showExtraField,
    extraFieldValidation,
  } = useBusinessConfig();
  return (
    <FormTheme variant="streamline">
      <div className="flex flex-col">
        <BusinessConfigFilterableSelect />
        <div className="flex flex-row w-full mb-6">
          <div className="w-full border-b border-neutral-300 my-auto" />
          <p className="text-14-22-em px-4 text-neutral-700">OR</p>
          <div className="w-full border-b border-neutral-300 my-auto" />
        </div>
        <Field.Validator validation={SECTOR_FIELD_VALIDATION}>
          <Field.Select {...SECTOR_FIELD_PROPS} options={sectorOptions} />
        </Field.Validator>
        {showTradeType && tradeTypeOptions && (
          <Field.Validator validation={TYPE_FIELD_VALIDATION}>
            <Field.Select {...TYPE_FIELD_PROPS} options={tradeTypeOptions} />
          </Field.Validator>
        )}
        {showExtraField && (
          <Field.Validator validation={extraFieldValidation}>
            <Field.Input {...EXTRA_FIELD_PROPS} />
          </Field.Validator>
        )}
      </div>
    </FormTheme>
  );
};

export default BusinessConfig;
