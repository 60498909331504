import { Button } from '@kanda-libs/ks-design-library';

import Container from './Payments-container';
import Payment from './Payment';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ payments, showMore, onClick }) => (
      <div className="flex flex-col mb-10">
        {payments.map((payment) => (
          <Payment key={payment.reference} payment={payment} />
        ))}
        {!showMore && (
          <Button.Link
            id="account-settings-subscription-view-more"
            variant="light-grey"
            label="View more"
            size={14}
            onClick={onClick}
            className="mr-auto mt-6"
          />
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Subscription-Content-History-Payments-view';

export default ViewComponent;
