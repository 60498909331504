import { Logo } from '@kanda-libs/ks-design-library';
import BookCallButton from '../BookCallButton';

const DesktopHeader: React.FunctionComponent = function () {
  return (
    <div className="flex w-full bg-neutral-000 mb-8 sticky top-0 py-6 z-50">
      <div className="flex flex-row justify-between w-full max-w-216 mx-auto">
        <Logo width={95} className="my-auto" />
        <BookCallButton />
      </div>
    </div>
  );
};

export default DesktopHeader;
