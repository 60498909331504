import { Price } from '@kanda-libs/ks-design-library';

import Container from './Total-container';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Container {...props}>
    {({ total }) => (
      <div className="flex flex-col">
        <span className="text-12-18-em md:text-14-22-em text-neutral-600 md:text-neutral-900 mb-2">
          Total price incl. VAT
        </span>
        <Price
          amount={total}
          color="neutral-900"
          poundsClassName="text-14-22-em md:text-28-32-em md:text-neutral-600"
          centsClassName="text-14-22-em md:text-28-32-em md:text-neutral-600"
          wrapperClassName="px-4 py-3.25 bg-neutral-100 rounded-lg border border-solid border-transparent md:p-0 md:bg-neutral-000 md:border-0"
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Total-view';

export default ViewComponent;
