import type { Subscription } from '@kanda-libs/ks-frontend-services';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import useCurrentCompany from './useCurrentCompany';

export interface ActiveSubscriptionHook {
  activeSubscription: Subscription | null;
  billing?: string;
}

const BILLING_STATUS_SUBSCRIBED = 'subscribed';

export default function useActiveSubscription(): ActiveSubscriptionHook {
  const { company } = useCurrentCompany();
  const [activeSubscription, setActiveSubscription] =
    useState<Subscription | null>(null);

  const isLoading = useSelector(selectors.getIsLoading);
  const subscriptions = useSelector(selectors.subscription.getEntitiesAsArray);

  useEffect(() => {
    if (!isLoading && subscriptions?.length) {
      const [subscription] = subscriptions.filter(
        (currentSubscription) =>
          currentSubscription.billing_status === BILLING_STATUS_SUBSCRIBED,
      );

      if (subscription) {
        setActiveSubscription(subscription);
      }
    }
  }, [isLoading, subscriptions]);

  return { activeSubscription, billing: company?.billing };
}
