import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { isActive } from './activeLinks-functions';

export interface Link {
  to?: string;
  active?: boolean;
}

const useActiveLinks = (links: Link[], options = {}): Link[] => {
  const location = useLocation();

  return useMemo(
    () =>
      links.map((link) => ({
        ...link,
        active: Boolean(isActive(location.pathname, link.to, options)),
      })),
    [links, location, options],
  );
};

export default useActiveLinks;
