import type { FunctionComponent } from 'react';
import { CLASS_NAMES, FINANCE_STATUSES, STATUSES } from './constants';
import JobTableFilterPopoverItem from './JobTableFilterPopoverItem';
import type { JobTableFilterPopoverItemType } from './JobTableFilterPopoverItem/types';

export interface JobTableFilterPopoverProps {
  type: JobTableFilterPopoverItemType;
}

const JobTableFilterPopover: FunctionComponent<JobTableFilterPopoverProps> =
  function ({ type }) {
    const items = type === 'status' ? STATUSES : FINANCE_STATUSES;

    return (
      <div className={CLASS_NAMES.wrapper}>
        {items.map((status) => (
          <JobTableFilterPopoverItem
            className={CLASS_NAMES.item}
            key={status}
            type={type}
            status={status}
          />
        ))}
      </div>
    );
  };

export default JobTableFilterPopover;
