import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import Success from '../Identify/Success';

const IdentifySuccess = () => (
  <DesktopLayoutBoxed help>
    <DesktopLayoutBoxedContent>
      <Success />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

export default IdentifySuccess;
