import {
  CLASSNAMES,
  TERMS_TEXT,
  LINK_TEXT,
} from './TermsAndConditionsText-constants';

import { TERMS_URL } from '../../../../../../../config';

const ViewComponent = () => (
  <div className={CLASSNAMES.container}>
    <p className={CLASSNAMES.text}>
      {TERMS_TEXT}
      <a
        href={TERMS_URL}
        className="text-green-500"
        target="_blank"
        rel="noreferrer"
      >
        {LINK_TEXT}
      </a>
    </p>
  </div>
);

ViewComponent.displayName = 'TermsAndConditionsText-view';

export default ViewComponent;
