import { Button, Header, Layout } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content } from '../components';

import { URLS } from '../../../config';

const ViewComponent = () => (
  <Layout
    bg="neutral-000"
    stickyFooter
    stickyHeader
    noBorder
    header={
      <Header.Base
        className="text-style-f-em text-neutral-700"
        help
        options={[
          <Link key="close" to={URLS.home}>
            <Button.Icon id="veriff-close-mobile" icon="close" />
          </Link>,
        ]}
      />
    }
    headerBg="neutral-50"
  >
    <Content />
  </Layout>
);
ViewComponent.displayName = 'Veriff-Mobile-view';

export default ViewComponent;
