import { ORDER, SORTING } from './Provider-constants';

import { URLS } from '../../../../config';
import { urlWithParams } from '../../../../utils';

/**
 * Sorts jobs by price
 * @param {Object} jobs jobs array
 * @param {String} order sorting order
 */
const sortByCustomer = (jobs, order) =>
  [...(jobs || [])].sort((job1, job2) => {
    const name1 = `${job1?.customerDetails?.firstName} ${job1?.customerDetails?.lastName}`;
    const name2 = `${job2?.customerDetails?.firstName} ${job2?.customerDetails?.lastName}`;
    if (order === ORDER.INVERSE) return name2.localeCompare(name1);
    return name1.localeCompare(name2);
  });

/**
 * Sorts jobs by price
 * @param {Object} jobs jobs array
 * @param {String} order sorting order
 */
const sortByPrice = (jobs, order) =>
  [...(jobs || [])].sort((job1, job2) => {
    if (order === ORDER.INVERSE)
      return (
        (new Date(job2.price) as unknown as number) -
        (new Date(job1.price) as unknown as number)
      );
    return (
      (new Date(job1.price) as unknown as number) -
      (new Date(job2.price) as unknown as number)
    );
  });

/**
 * Sorts jobs by updated at date
 * @param {Object} jobs jobs array
 * @param {String} order sorting order
 */
const sortByUpdatedAt = (jobs, order) =>
  [...(jobs || [])].sort((job1, job2) => {
    if (order === ORDER.INVERSE)
      return (
        (new Date(job1.updatedAt) as unknown as number) -
        (new Date(job2.updatedAt) as unknown as number)
      );
    return (
      (new Date(job2.updatedAt) as unknown as number) -
      (new Date(job1.updatedAt) as unknown as number)
    );
  });

/**
 * Sorts jobs according to sorting and order
 * @param {Object} jobs jobs array
 * @param {String} sorting sorting type
 * @param {String} order sorting order
 */
export const sortJobData = (jobs, sorting, order) => {
  if (!jobs) return null;
  // If no jobs, return null to show placeholder
  if (jobs.length === 0) return [];
  // If sorting is updated_at, return data sorted by updated at date
  if (sorting === SORTING.UPDATED_AT) return sortByUpdatedAt(jobs, order);
  // If sorting is price, return data sorted by price
  if (sorting === SORTING.PRICE) return sortByPrice(jobs, order);
  // If sorting is customer, return data sorted by customer name
  if (sorting === SORTING.CUSTOMER) return sortByCustomer(jobs, order);
  return jobs;
};

/**
 * Filters out jobs by price
 * @param {Object} job job object
 * @param {String} filter price filter
 */
const priceFilter = (job, filter) => {
  // If filter not set, return job
  if (!filter) return job;
  // Extract components of filter
  const [from, to] = filter.split(';');
  // If `from` is not defined, return the job
  if (!from) return job;
  // Extract minimum job value
  const min = parseInt(from.split(':')[1], 10);
  // If no to value, simply filter by minimum value
  if (!to) return job?.price >= min;
  // Extract maximum job value
  const max = parseInt(to.split(':')[1], 10);
  // Filter jobs between min and max price
  return job?.price >= min && job?.price <= max;
};

/**
 * Filters out jobs by status filter
 */
const statusFilter = (job, filter) => {
  // If filter not set, return job
  if (!filter) return job;
  // Extract job status and finance status
  const status = `${job?.status}.${job?.financeStatus}`;
  // Split out the filters
  const filters = filter.split(';');
  const foundStatuses = filters.filter((f) => f && status.includes(f));

  return foundStatuses.length > 0;
};

const financeStatusFilter = (job, filter) => {
  // If filter not set, return job
  if (!filter) return job;
  // Extract job status and finance status
  const status = `${job?.status}.${job?.financeStatus}`;
  // Split out the filters
  const filters = filter.split(';');
  const foundStatuses = filters.filter((f) => f && status.includes(f));

  return foundStatuses.length > 0;
};

/**
 * Filters jobs to include those with specified filter
 * @param {Object} jobs jobs array
 * @param {Object} filter filter object
 * @param {Object} filter.type filter type
 * @param {Object} filter.term filter term
 */
export const filterJobData = (jobs, filter) => {
  const { status, financeStatus, price } = filter;
  // const { type, term } = filter;
  // if (type === FILTER_TYPES.NONE) return jobs;
  // if (type === FILTER_TYPES.STATUS)
  //   return jobs.filter((job) => job?.status === term);
  return jobs
    .filter((job) => statusFilter(job, status))
    .filter((job) => financeStatusFilter(job, financeStatus))
    .filter((job) => priceFilter(job, price));
};

/**
 * Filters jobs to only find open jobs
 * @param {Object} jobs
 */
export const filterOpenJobs = (jobs, archive) => {
  if (!jobs) return null;
  // Filter out archived jobs
  const open = jobs.filter((job) =>
    archive ? job.archived === 'yes' : job.archived === 'no',
  );
  // Return open jobs
  return open;
};

/**
 * Create URL link for job - if status, go to create, else go to details
 * @param {String} id job id
 * @param {String} status job status
 */
const urlLink = (id, status) => {
  if (!status || !id) return null;
  if (status === 'draft') return urlWithParams(URLS.createJobFromDraft, { id });
  return urlWithParams(URLS.job, { id });
};

const formatFinanceStatus = (job) => {
  if (!job?.payments) return job?.finance_status || '';
  const payments = [...(job?.payments || [])]
    ?.sort(
      (p1, p2) =>
        (new Date(p2?.metadata?.created_at) as unknown as number) -
        (new Date(p1?.metadata?.created_at) as unknown as number),
    )
    ?.filter((p) => p?.payment_option?.payment_method === 'card');

  const payment = (payments || [])[0];
  if (!payment) return job?.finance_status || '';
  if (job?.finance_status === 'financed' && payment?.status === 'unpaid')
    return 'applied_for_finance';
  return job?.finance_status || '';
};

/**
 * Format jobs
 * @param {Object} jobs
 */
export const formatJobs = (jobs) => {
  if (!jobs) return [];

  return jobs.map((job) => ({
    title: job?.title || '',
    description: job?.description || '',
    financeStatus: formatFinanceStatus(job),
    flowType: job?.flow_type,
    quotedTo: job?.quoted_to || '',
    status: job?.status || '',
    price: job?.total?.amount_vat_inclusive?.amount || 0,
    id: job?.id || '',
    link: urlLink(job?.id, job?.status),
    archived: job?.archived || 'no',
    viewedFinance:
      job?.customer_options?.checkout_option === 'apply_for_finance',
    createdAt: job?.metadata?.created_at || '',
    updatedAt: job?.metadata?.updated_at || '',
    customerDetails: {
      firstName: job?.customer?.first_name || '',
      lastName: job?.customer?.last_name || '',
      email: job?.customer?.email || '',
      phone: job?.customer?.phone || '',
      postCode: job?.customer?.address?.postcode || '',
      addressLine1: job?.customer?.address?.line_1 || '',
      addressLine2: job?.customer?.address?.line_2 || '',
      city: job?.customer?.address?.city || '',
    },
  }));
};
