import { CLASS_NAMES } from './Option-constants';

interface ViewComponentProps {
  /**
   * Title
   */
  apr: string;
  /**
   * Icon
   */
  durations: string[];
}

const ViewComponent = ({ apr, durations }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <p className={CLASS_NAMES.title}>{`${apr}%`}</p>
    <div className={CLASS_NAMES.bullet} />
    <p className={CLASS_NAMES.title}>{`${durations.join(', ')} months`}</p>
  </div>
);

ViewComponent.displayName = 'PaymentMethods-Option-view';

export default ViewComponent;
