import { Price } from '@kanda-libs/ks-design-library';

import {
  CLASSNAMES,
  SUBTOTAL_PROPS,
  VATTOTAL_PROPS,
  TOTAL_PROPS,
  DEPOSIT_PROPS,
} from './Totals-constants';
import Container from './Totals-container';

const ViewComponent = () => (
  <Container>
    {({ totalExcVat, totalVat, totalIncVat, deposit }) => (
      <div className={CLASSNAMES.container}>
        <div className={CLASSNAMES.row}>
          <p className={CLASSNAMES.text}>subtotal</p>
          <Price amount={totalExcVat} {...SUBTOTAL_PROPS} />
        </div>
        <div className={CLASSNAMES.row}>
          <p className={CLASSNAMES.text}>vat</p>
          <Price amount={totalVat} {...VATTOTAL_PROPS} />
        </div>
        <div className={CLASSNAMES.separator} />
        <div className={CLASSNAMES.row}>
          <p className={CLASSNAMES.text}>total</p>
          <Price amount={totalIncVat} {...TOTAL_PROPS} />
        </div>
        {deposit ? (
          <div className={CLASSNAMES.row}>
            <p className={CLASSNAMES.text}>deposit</p>
            <Price amount={deposit} {...DEPOSIT_PROPS} />
          </div>
        ) : null}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-Totals-view';

export default ViewComponent;
