import { useWatch } from 'react-hook-form';

interface CompanyNameHook {
  companyType: string;
}

export default function useCompanyName(): CompanyNameHook {
  const companyType = useWatch({ name: 'company.company_type' });
  return { companyType };
}
