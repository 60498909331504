import { BreakPoints } from '@kanda-libs/ks-design-library';

import Desktop from './Desktop';
import Mobile from './Mobile';

export interface RolePopoverProps {
  permissions: boolean[];
  role: string;
  id: string;
}

const ViewComponent = (props: RolePopoverProps) => (
  <div className="relative h-10 ml-3 mr-1 my-auto">
    <BreakPoints
      desktop={<Desktop {...props} />}
      mobile={<Mobile {...props} />}
    />
  </div>
);

ViewComponent.displayName = 'New-Role-Popover-view';

export default ViewComponent;
