import { BreakPoints } from '@kanda-libs/ks-design-library';

const Placeholder = () => (
  <div className="flex flex-row my-auto">
    <span className="mr-1 text-green-400 text-14-22-em">
      Click to upload file
    </span>
    <BreakPoints
      desktop={
        <span className="text-14-22 text-neutral-600">or drag & drop</span>
      }
    />
  </div>
);

export const LOGO_DOCUMENT_PROPS = {
  label: 'Company Logo',
  placeholder: <Placeholder />,
  maxFiles: 1,
  maxSize: 20 * 1024 * 1024,
  accept: ['image/*'],
  compressImages: true,
};

export const AVERGAE_JOB_VALUE_PROPS = {
  label: 'Average Job Value',
  placeholder: 'average job value',
  wrapperProps: {
    className: 'mr-2',
  },
  type: 'price',
};

export const ACCOUNT_NAME_VALIDATION = {
  minLength: { value: 1, message: 'Account Name requires minimum length of 1' },
};

export const ACCOUNT_NUMBER_VALIDATION = {
  minLength: {
    value: 1,
    message: 'Account Number requires minimum length of 1',
  },
  pattern: {
    value: /^[0-9]{8}$/,
    message:
      'Account Number must be 8 digits. If your account number is less than 8 digits long please add a 0 at the start.',
  },
};

export const SORT_CODE_VALIDATION = {
  pattern: {
    value: /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/,
    message: 'Sort Code input is invalid',
  },
};

export const INSURANCE_DOCUMENT_PROPS = {
  label: 'Personal liability insurance policy',
  placeholder: <Placeholder />,
  maxFiles: 1,
  maxSize: 20 * 1024 * 1024,
  jobPdfInput: true,
  accept: ['image/*', 'application/pdf'],
  compressImages: true,
};
