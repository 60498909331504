import { SkeletonLoader } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './Desktop-constants';
import Container from './Desktop-container';

import { Footer, List } from '../components';
import { TITLE } from '../Items-constants';

const ViewComponent = () => (
  <Container>
    {({ isLoading }) => (
      <div className={CLASS_NAMES.container}>
        <p className={CLASS_NAMES.title}>
          <SkeletonLoader
            isLoading={isLoading}
            width={100}
            afterLoading={TITLE}
          />
        </p>

        <div className={CLASS_NAMES.content}>
          <List />
        </div>
        <div className={CLASS_NAMES.footer}>
          <Footer />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-Items-Desktop-view';

export default ViewComponent;
// <Footer />
