import { useFormContext } from '@kanda-libs/ks-component-ts';
import { removeTrailingWhitespace } from '../../../../../../../../../../../utils';

export interface ContainerComponentChildrenArgs {
  onBlur: (name: string) => void;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { getValues, setValue } = useFormContext();

  const onBlur = (name: string) =>
    setValue(name, removeTrailingWhitespace(getValues(name)));

  return children({
    onBlur,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Customer-Field-Name-container';

export default ContainerComponent;
