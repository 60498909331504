import { Form } from '@kanda-libs/ks-component-ts';

import useStandardTradeInfo from './useStandardTradeInfo';
import TradeInfoForm from './Form';

const ViewComponent = ({}) => {
  const { form, onSubmit } = useStandardTradeInfo();

  return (
    <Form
      id="onboarding-company-info-standard-company"
      form={form}
      onSubmit={onSubmit}
      className="w-full flex flex-col"
    >
      <TradeInfoForm />
    </Form>
  );
};

ViewComponent.displayName = 'StandardTradeInfo-view';

export default ViewComponent;
