import { useSignUpContext } from '../../../context';

export interface SignUpFormFieldsHook {
  disabled: boolean;
  isSubmitting: boolean;
}

export default function useSignUpFormFields(): SignUpFormFieldsHook {
  const { disabled, isSubmitting } = useSignUpContext();

  return {
    disabled,
    isSubmitting,
  };
}
