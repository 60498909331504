import type { Company, InfoStats } from '@kanda-libs/ks-frontend-services';
import { URLS } from 'config';
import { useCurrentCompany, useMe } from 'hooks';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { PLACEHOLDER_STATS } from './constants';

export interface HomeProviderProps {
  children: ReactNode;
}

export interface HomeContextType {
  company: Company | undefined;
  jobsSent: number;
  jobsApplied: number;
  jobsApproved: number;
  revenue: number;
  aov: number;
  approvalRate: number;
  isLoading: boolean;
}

export const HomeContext = createContext<HomeContextType>({
  company: undefined,
  jobsSent: 0,
  jobsApplied: 0,
  jobsApproved: 0,
  revenue: 0,
  aov: 0,
  approvalRate: 0,
  isLoading: true,
});

export const useHomeContext = () => useContext(HomeContext);

const HomeProvider: FunctionComponent<HomeProviderProps> = function ({
  children,
}) {
  const { company } = useCurrentCompany();
  const { me, isValidating: meIsValidating } = useMe();
  const { push } = useHistory();
  const apiStats = useSelector(selectors.infoStats.getEntities);
  const hasFetched = useSelector(selectors.infoStats.getHasFetched);

  const stats = useMemo(() => {
    if (!apiStats || !company) return PLACEHOLDER_STATS;
    const cid = company?.id;
    if (!cid) return PLACEHOLDER_STATS;
    return apiStats[cid] as InfoStats;
  }, [apiStats, company]);

  const approvalRate = useMemo(() => {
    if (!stats) return 0;
    if (
      !stats?.jobs_applied_for_finance ||
      stats.jobs_applied_for_finance === 0
    )
      return 0;
    if (
      !stats?.jobs_approved_for_finance ||
      stats.jobs_approved_for_finance === 0
    )
      return 0;
    return Math.round(
      (stats.jobs_approved_for_finance * 10000) /
        stats.jobs_applied_for_finance,
    );
  }, [stats]);

  const isLoading = useMemo(() => {
    if (!company || !hasFetched) return true;
    return false;
  }, [company, hasFetched]);

  const values = useMemo(
    () => ({
      company: company || undefined,
      jobsSent: stats?.jobs_sent || 0,
      jobsApplied: stats?.jobs_applied_for_finance || 0,
      jobsApproved: stats?.jobs_approved_for_finance || 0,
      revenue: stats?.jobs_total_revenue_of_approved || 0,
      aov: stats?.jobs_average_value_of_approved || 0,
      approvalRate,
      isLoading,
    }),
    [company, isLoading, stats, approvalRate],
  );

  useEffect(() => {
    if (meIsValidating) return;
    const isAdmin = me?.role !== 'company-staff';
    if (isAdmin) return;
    push(URLS.jobs);
  }, [me, meIsValidating, push]);

  return <HomeContext.Provider value={values}>{children}</HomeContext.Provider>;
};

export default HomeProvider;
