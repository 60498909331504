import type { MutableRefObject } from 'react';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';

import { PaymentAndFinanceOptions } from '../../../../../components';

interface ViewComponentProps {
  /**
   * Modal id
   */
  modalId: string;
  /**
   * Title
   */
  title?: string;
}

const ViewComponent = ({ modalId, title }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
        variant="MARKETING"
      >
        <div className="overflow-scroll max-h-60vh">
          <h1 className="mb-8 text-style-f-em text-neutral-900">{title}</h1>
          <PaymentAndFinanceOptions />
        </div>
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'PaymentOptionsModal-view';

ViewComponent.defaultProps = {
  title: 'Payment & Finance Options',
};

export default ViewComponent;
