import { Form, Field } from '@kanda-libs/ks-component-ts';
import { Loader, Button } from '@kanda-libs/ks-design-library';

import Container from './UserForm-container';
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  PHONE_NUMBER_VALIDATION,
  WRAP_FIELDS,
} from './UserForm-constants';

const ViewComponent = () => (
  <Container>
    {({ onSubmit, form, isLoading, isSubmitting }) => (
      <Form
        id="account-settings-update-profile"
        form={form}
        onSubmit={onSubmit}
      >
        <div className="flex flex-col mb-12">
          <p className="hidden md:block text-16-20-em mb-6">Personal Details</p>
          <div className="flex w-full flex-col md:flex-row">
            <Field.Validator validation={FIRST_NAME_VALIDATION}>
              <Field.Input {...WRAP_FIELDS.firstName} isLoading={isLoading} />
            </Field.Validator>
            <Field.Validator validation={LAST_NAME_VALIDATION}>
              <Field.Input {...WRAP_FIELDS.lastName} isLoading={isLoading} />
            </Field.Validator>
          </div>
          <Field.Validator validation={PHONE_NUMBER_VALIDATION}>
            <Field.PhoneNumberInput
              {...WRAP_FIELDS.phoneNumber}
              isLoading={isLoading}
            />
          </Field.Validator>
          <Button.Text
            id="account-settings-update-profile"
            variant="solid"
            label="Update profile"
            className="ml-auto"
            submit
            disabled={isSubmitting}
          />
        </div>
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'MyAccount-AccountSettings-UserForm-view';

export default ViewComponent;
