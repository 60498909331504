import { Text, Loader } from '@kanda-libs/ks-design-library';
import Container from './Content-container';
import {
  TITLE,
  DESRCIPTION,
  CLASS_NAMES,
} from '../../DirectorVerification-constants';
import { DirectorCard } from './components';

interface ViewComponentProps {
  centered?: boolean;
}

const ViewComponent = ({ centered }: ViewComponentProps) => (
  <Container>
    {({ isLoading, directors, onClick, isSubmitting }) => (
      <div className="flex flex-col w-full">
        <div
          className={centered ? 'text-center flex flex-col' : 'flex flex-col'}
        >
          <Text
            isLoading={isLoading}
            text={TITLE}
            className={CLASS_NAMES.title}
            skeletonProps={{
              width: 200,
            }}
          />
          <Text
            isLoading={isLoading}
            text={DESRCIPTION}
            className={CLASS_NAMES.description}
            skeletonProps={{
              count: 2,
            }}
          />
        </div>
        {directors.map((director) => (
          <DirectorCard
            key={director?.email}
            user={director}
            resend={onClick}
            isSubmitting={isSubmitting}
          />
        ))}
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DirectorVerification-Desktop-view';

ViewComponent.defaultProps = {
  centered: false,
};

export default ViewComponent;
