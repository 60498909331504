import { useForm } from '@kanda-libs/ks-component-ts';
import { StringIndexedObject, useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany, useMe } from 'hooks';
import useApiError from 'hooks/useApiError';
import useCompleteWelcomePackSection from 'hooks/useCompleteWelcomePackSection';
import useUserDefaultValues from 'hooks/useUserDefaultValues';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { cleanObject } from 'utils';

export interface InviteFormHook {
  form: ReturnType<typeof useForm>;
  onSubmit: (values: StringIndexedObject) => void;
  isSubmitting: boolean;
  defaultValues: StringIndexedObject;
  onFinish: () => void;
}

export default function useInviteForm(): InviteFormHook {
  const dispatch = useAppDispatch();

  const { push } = useHistory();
  const { company } = useCurrentCompany();
  const { revalidateMe } = useMe();

  const defaultValues = useUserDefaultValues();

  const form = useForm({ mode: `onBlur`, defaultValues });

  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess } = useToast();

  const onError = useApiError('Error inviting users - please try again later');

  const { onFinish } = useCompleteWelcomePackSection('invite_team_member');

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      const { id } = company || {};
      const body = cleanObject({
        ...company,
        ...formValues.company,
      });

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            showSuccess('Company Updated!');
            revalidateMe();
            form.reset({ mode: `onBlur`, defaultValues });
            push(URLS.inviteSummary);
          },
        }),
      );
    },
    [
      company,
      dispatch,
      onError,
      showSuccess,
      revalidateMe,
      push,
      form,
      defaultValues,
    ],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
    defaultValues,
    onFinish,
  };
}
