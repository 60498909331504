import { Icon, Text } from '@kanda-libs/ks-design-library';
import { MONTHLY_PRICE } from '../../SubscribeForm-constants';

const ViewComponent = () => (
  <div className="flex flex-row px-5 py-4 border border-neutral-300 rounded-xl shadow-card mb-10">
    <Icon
      icon="favourite"
      size={24}
      stroke="lavender-200"
      className="hidden md:block my-auto mr-5"
    />
    <div className="flex flex-col text-left ">
      <Text
        text="Kanda Premium"
        className="text-14-22-em text-neutral-600 mb-1"
      />
      <Text
        text={`£${MONTHLY_PRICE / 100} + VAT / month`}
        className="text-20-24-em"
      />
    </div>
  </div>
);

ViewComponent.displayName = 'SetupCompany-Subscription-view';

export default ViewComponent;
