import { CLASS_NAMES } from '../../../../Onboarding-constants';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element | JSX.Element[];
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <div className={CLASS_NAMES.page}>{children}</div>
);

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.displayName = 'Wrapper-view';

export default ViewComponent;
