import { useForm } from '@kanda-libs/ks-component-ts';
import {
  type StringIndexedObject,
  useToast,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useCurrentCompany from 'hooks/useCurrentCompany';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import type { NotificationsModalProps } from './NotificationsModal-view';
import { capitaliseWords } from '../../../../../../../../../utils';

const getCommsPrefs = (user, formUser) => {
  if (!formUser && !user) return {};
  if (!formUser) return user?.comm_preferences || {};
  return formUser?.comm_preferences || user?.comm_preferences || {};
};

const formatUser = (user, formUser) => {
  const commPrefs = getCommsPrefs(user, formUser);
  if (!user)
    return {
      name: '--',
      email: '--',
      mobile: '--',
      role: '--',
      director: false,
      verified: false,
      commPreferences: {},
    };
  return {
    name: [user?.first_name, user?.last_name]?.filter(Boolean)?.join(' '),
    email: user?.email,
    mobile: user?.mobile,
    role: capitaliseWords(user?.role?.replace(/-/g, ' '))?.replace(
      'Company ',
      '',
    ),
    director: Boolean(user?.director_info),
    verified: user?.director_info?.verification_status,
    commPreferences: commPrefs,
  };
};

export interface ContainerComponentChildrenArgs {
  onClick: (bucket: string) => void;
  onSubmit: () => void;
  form: UseFormReturn<StringIndexedObject>;
  isSubmitting?: boolean;
  name: string;
  email: string;
  mobile: string;
  role: string;
  director: boolean;
  verified: boolean;
  index: number | null;
  commPreferences: StringIndexedObject;
  hasCommsPrefs: boolean;
  addPreferences: () => void;
}

export interface ContainerComponentProps extends NotificationsModalProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  handleClose: () => void;
}

const ContainerComponent = ({
  children,
  handleClose,
  userToManage,
}: ContainerComponentProps) => {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess } = useToast();
  const onError = useApiError('Error updating user notification preferences');

  const user = company?.users?.[userToManage as number];

  const defaultValues = useMemo(() => {
    if (!company)
      return {
        company: {
          users: [],
        },
      };
    return {
      company: {
        users: company?.users,
      },
    };
  }, [company]);

  const form = useForm({ mode: 'onBlur' });

  const { reset, getValues, setValue, watch } = form;

  const onClick = useCallback(
    (bucket: string): void => {
      if (!bucket) return;
      const fieldName = `company.users.${userToManage}.comm_preferences.${bucket?.toLowerCase()}`;
      const state = getValues(fieldName);
      if (state === 'enabled') {
        setValue(fieldName, 'disabled');
        return;
      }
      setValue(fieldName, 'enabled');
    },
    [getValues, setValue, userToManage],
  );

  const formUsers = watch('company.users');

  const formUser = useMemo(() => {
    if (!formUsers || formUsers?.length === 0) return {};
    return formUsers?.[userToManage as number];
  }, [formUsers, userToManage]);

  const details = formatUser(user, formUser);

  const hasCommsPrefs = useMemo(() => {
    if (!formUsers) return false;
    return formUsers?.some((u) => u?.comm_preferences);
  }, [formUsers]);

  const addPreferences = useCallback(() => {
    const users = company?.users?.map((companyUser) => ({
      ...companyUser,
      comm_preferences: {
        pre_job: 'enabled',
        post_job: ['company-admin', 'company-manager']?.includes(
          companyUser?.role,
        )
          ? 'enabled'
          : 'disabled',
        other: ['company-admin']?.includes(companyUser?.role)
          ? 'enabled'
          : 'disabled',
      },
    }));
    setValue('company.users', users);
  }, [company, setValue]);

  const onSubmit = useCallback(() => {
    const formValues = getValues();
    const body = {
      ...company,
      ...(formValues?.company || {}),
    };
    const { id } = company || {};

    dispatch(
      actions.putCompany({
        body,
        params: { id: id as string },
        onError,
        onSuccess: () => {
          showSuccess('User notification preferences updated');
          handleClose();
        },
      }),
    );
  }, [handleClose, getValues, company, dispatch, onError, showSuccess]);

  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    onClick,
    onSubmit,
    form,
    index: userToManage,
    isSubmitting,
    hasCommsPrefs,
    addPreferences,
    ...details,
  });
};

ContainerComponent.displayName = 'NotificationsModal-Container';

export default ContainerComponent;
