import { type ButtonTextVariant, Text } from '@kanda-libs/ks-design-library';

export const SUB_TITLE_TEXT = 'Finish Set Up';

export const UPGRADE_TEXT = 'Set up your profile to send';

export const CLASS_NAMES = {
  subTitle: 'text-14-22 text-neutral-700 mb-2 text-center',
  title: 'text-style-e text-center whitespace-pre-line mb-6',
  price: 'ml-2 mr-2 text-light font-medium',
};

export const BUTTON = {
  id: 'create-job-mobile-subscription-complete',
  icon: 'star',
  left: true,
  label: 'Complete set up',
  variant: 'gradient' as ButtonTextVariant,
  className: 'mx-auto',
  append: (
    <>
      <Text text="£30/mo" className={CLASS_NAMES.price} />
    </>
  ),
};
