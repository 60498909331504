export const CLASS_NAMES = {
  container: 'flex flex-col m-auto text-center',
  icon: 'mb-4 mx-auto',
  heading: 'mb-4 text-24-28-em',
  subHeading: 'mb-4 text-lg text-style-h text-neutral-700 md:text-style-f',
  textSlideContainer: 'min-h-16',
  textSlide: 'text-16-24 text-neutral-600',
};

export const TEXT_SLIDES_AUTO_ROTATING_TIME = 10000;

export const TEXT_SLIDES = [
  'Some banks are quite slow, so sit tight or grab a cuppa.',
  'This is taking longer than usual, please be patient with us',
  "We've not received any errors, no need to refresh your page, your bank has just not confirmed your mandate yet",
];
