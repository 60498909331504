import type {
  ButtonIconVariant,
  ButtonTextVariant,
} from '@kanda-libs/ks-design-library';

export const BUTTON_PROPS = {
  mobile: {
    variant: 'turquoise' as ButtonIconVariant,
    icon: 'plus',
    size: '40-20' as unknown as number,
    id: 'jobs-banner-new-quote',
    className: 'whitespace-nowrap',
    text: 'New',
  },
  desktop: {
    variant: 'solid' as ButtonTextVariant,
    icon: 'plus',
    size: 40,
    left: true,
    id: 'jobs-banner-new-quote',
    text: 'New',
    label: 'New Job',
    className: 'whitespace-nowrap',
  },
};
