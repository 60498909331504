import { useToast } from '@kanda-libs/ks-design-library';
import type { Contract } from 'pages/Setup/Subscription/context';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useCallback, useMemo, useState } from 'react';

import type { DocumentPageProps, DocumentPdfProps } from './types';

interface DocumentPagesHook {
  showContent: boolean;
  pages: DocumentPageProps[] | undefined;
  pdfProps: DocumentPdfProps | undefined;
}

export default function useDocumentPages(
  contract: Contract,
): DocumentPagesHook {
  const [pages, setPages] = useState<DocumentPageProps[] | undefined>();
  const { showError } = useToast();

  const content = useMemo(() => contract.content, [contract]);
  const prefix = useMemo(() => contract.name.toLowerCase(), [contract]);

  const onLoadSuccess = useCallback(
    ({ numPages }: PDFDocumentProxy) =>
      setPages(
        [...Array(numPages).keys()].map((index) => ({
          key: `page_${prefix}_${index + 1}`,
          pageNumber: index + 1,
          className: index !== 0 ? 'mt-4' : '',
        })),
      ),
    [prefix],
  );

  const onLoadError = useCallback(
    ({ message }) =>
      showError(
        `Error loading document. Please contact Kanda. Error message: ${message}`,
      ),
    [showError],
  );

  const onSourceError = useCallback(
    ({ message }) =>
      showError(
        `Error retrieving document source. Please contact Kanda. Error message: ${message}`,
      ),
    [showError],
  );

  const pdfProps = useMemo(() => {
    if (!content) return undefined;
    return {
      file: `data:application/pdf;base64,${content}`,
      error:
        'Failed to load the PDF file. The file can still be downloaded by clicking the button below',
      onLoadSuccess,
      onLoadError,
      onSourceError,
    };
  }, [content, onLoadSuccess, onLoadError, onSourceError]);

  const showContent = Boolean(pdfProps && !!content);

  return {
    showContent,
    pages,
    pdfProps,
  };
}
