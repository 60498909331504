import { Helmet } from 'react-helmet';

interface ViewComponentProps {
  title?: string;
}

const ViewComponent = ({ title }: ViewComponentProps) => (
  <Helmet>
    <title>{`Kanda | Dashboard${title ? ` | ${title}` : ''}`}</title>
  </Helmet>
);

ViewComponent.displayName = 'Helmet-view';

ViewComponent.defaultProps = {
  title: undefined,
};

export default ViewComponent;
