import type { MouseEvent } from 'react';
import { Field, FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import type {
  FinanceRate,
  Job,
  JobItem,
} from '@kanda-libs/ks-frontend-services';
import { Tag } from '@kanda-libs/ks-design-library';
import { getFinanceOptionName } from 'utils';

import Container from './FinanceOptionNarrow-container';
import { CLASS_NAMES } from './FinanceOptionNarrow-constants';
import { Header, Description, Payout } from './components';

interface ViewComponentProps {
  /**
   * Payment method
   */
  financeOption: FinanceRate;
  financeOptions?: FinanceRate[];
  /**
   * Line items
   */
  lineItems: JobItem[];
  /**
   * TP fee
   */
  fee?: number;
  /**
   * Field name
   */
  name?: string;
  /**
   * Hide cost
   */
  hideCost?: boolean;
  /**
   * Hide cost
   */
  showPayout?: boolean;
  /**
   * Loading state
   */
  isLoading?: boolean;
  /**
   * Field ID
   */
  id?: string;
  /**
   * onClick
   */
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  /**
   * The total value of the job is lower than the threshold for finance
   */
  lowTotal?: boolean;
  /**
   * The total value of the job is lower than the threshold for finance
   */
  deposit?: number;
  /**
   * The type of job (standard or solar)
   */
  jobType?: Job['job_type'];
  /**
   * Combine multiple finance options into one
   */
  combined?: boolean;
  /**
   * Combine multiple finance options into one
   */
  declined?: boolean;
  /**
   * Is a second line rate
   */
  isSecondLine?: boolean;
}

const ViewComponent = ({
  name,
  id,
  hideCost,
  showPayout = false,
  financeOption,
  financeOptions,
  fee,
  lineItems,
  isLoading,
  onClick,
  deposit,
  combined,
  declined = false,
  isSecondLine = false,
}: ViewComponentProps) => (
  <Container
    financeOption={financeOption}
    financeOptions={financeOptions}
    fee={fee as number}
    lineItems={lineItems}
    deposit={deposit as number}
    combined={combined}
  >
    {({ monthlyLoan, lowTotal, payoutProps, ...headerProps }) => (
      <div className={CLASS_NAMES.container}>
        {isSecondLine && (
          <div className={CLASS_NAMES.secondLine.container}>
            <Tag
              label="first application declined"
              variant="solid"
              color="violet"
            />
          </div>
        )}
        <div className={CLASS_NAMES.wrapper}>
          <label htmlFor={name} className={CLASS_NAMES.info}>
            <Header
              {...headerProps}
              hideCost={hideCost}
              isLoading={isLoading}
              financeOption={financeOption}
              declined={declined}
              isSecondLine={isSecondLine}
            />
            {showPayout ? (
              <Payout isLoading={isLoading} {...payoutProps} />
            ) : (
              <Description monthlyLoan={monthlyLoan} isLoading={isLoading} />
            )}
          </label>
          {name && (
            <div>
              <FormTheme variant="clean">
                <Field.BooleanInput name={name} />
              </FormTheme>
            </div>
          )}
          {id && !name && (
            <div>
              <FormTheme variant="clean">
                <FieldHandle.Switch
                  id={id as string}
                  disabled={lowTotal}
                  name={getFinanceOptionName(financeOption)}
                  onClick={(e) => !lowTotal && onClick && onClick(e)}
                />
              </FormTheme>
            </div>
          )}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  name: undefined,
  id: undefined,
  hideCost: false,
  fee: 0,
  isLoading: false,
  onClick: () => {},
  lowTotal: false,
  deposit: 0,
  jobType: 'standard',
};

ViewComponent.displayName = 'FinanceOptions-Option-view';

export default ViewComponent;
