import { Text } from '@kanda-libs/ks-design-library';

import Container from './Quantity-container';

import { SKELETONS } from '../../LineItem-constants';

const ViewComponent = () => (
  <Container>
    {({ quantity, isLoading, classNames }) => (
      <Text
        className={classNames.quantity}
        isLoading={isLoading}
        text={quantity}
        skeletonProps={SKELETONS.quantity}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Quantity-view';

export default ViewComponent;
