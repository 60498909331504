import { MESSAGE_PROPS } from './Message-constants';

interface ViewComponentProps {
  /**
   * ID for the selection modal
   */
  message: string;
}

const ViewComponent = ({ message }: ViewComponentProps) => (
  <div {...MESSAGE_PROPS}>{message}</div>
);

ViewComponent.displayName = 'CreateJob-SendConfirmation-Message-view';

export default ViewComponent;
