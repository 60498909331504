import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useMe from 'hooks/useMe';
import useCurrentCompany from 'hooks/useCurrentCompany';

import {
  MENU_ITEMS,
  MOBILE_ADMIN_ITEMS,
  VERIFICATION_ITEM,
} from '../../MyAccount-constants';

import { checkVerification } from '../../../../utils';

const ContainerComponent = ({ children }) => {
  const { me } = useMe();
  const { company } = useCurrentCompany();

  // TODO: change this up to actual verification enabler when ready
  const showVerification = checkVerification(company as Company);

  const isLoading = !company;

  const isAdmin = me?.role !== 'company-staff';

  // TODO: Put this back in once other settings pages are ready
  const items = useMemo(
    () => [
      ...MENU_ITEMS,
      ...(isAdmin ? MOBILE_ADMIN_ITEMS : []),
      ...(isAdmin && showVerification ? [VERIFICATION_ITEM] : []),
    ],
    [showVerification, isAdmin],
  );

  const Tag = isLoading ? 'div' : Link;

  return children({
    isLoading,
    items,
    Tag,
  });
};

ContainerComponent.displayName = 'Menu-container';

export default ContainerComponent;
