import { Form } from '@kanda-libs/ks-component-ts';
import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Loader,
} from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import type { FunctionComponent } from 'react';
import Content from './Content';
import useStaffLogin from './useStaffLogin';

const StaffLogin: FunctionComponent = function () {
  const { form, onSubmit, isSubmitting, signInWithGoogle } = useStaffLogin();

  return (
    <>
      <Helmet title="Login" />
      <Form id="login" form={form} onSubmit={onSubmit}>
        <DesktopLayoutBoxed>
          <DesktopLayoutBoxedContent>
            <Content signInWithGoogle={signInWithGoogle} />
          </DesktopLayoutBoxedContent>
        </DesktopLayoutBoxed>
        <Loader isLoading={isSubmitting} />
      </Form>
    </>
  );
};

export default StaffLogin;
