import type { MutableRefObject } from 'react';
import {
  MarkdownDisplay,
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';

interface ViewComponentProps {
  /**
   * Modal id
   */
  modalId: string;
  /**
   * Title
   */
  note: string;
}

const ViewComponent = ({ modalId, note }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
        variant="MARKETING"
      >
        <div className="flex flex-col w-full pb-10">
          <span className="mb-8 text-16-20-em">Note</span>
          <span className="text-16-24">
            <MarkdownDisplay>{note}</MarkdownDisplay>
          </span>
        </div>
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'NotesModal-view';

export default ViewComponent;
