import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const BUTTON_PROPS = {
  icon: 'plus',
  left: true,
  size: 14,
  variant: 'light-grey' as ButtonIconVariant,
  className:
    'mt-2 w-full border border-neutral-300 hover:border-neutral-400 rounded-xl py-4.5',
};
