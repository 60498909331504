export const PAYMENT_OPTIONS_MODAL_ID = 'job-view-payment-options-modal';

export const PAYMENT_OPTIONS_MODAL_PROPS = {
  modalId: PAYMENT_OPTIONS_MODAL_ID,
  title: 'Payment & Finance Options',
};

export const PAYMENT_OPTIONS_ACTION_PROPS = {
  icon: 'wallet',
  title: 'Payment & finance options',
};
