import { BreakPoints } from '@kanda-libs/ks-design-library';

import Desktop from './Desktop';

export interface BucketPopoverProps {
  content: JSX.Element;
  id: string;
}

const ViewComponent = (props: BucketPopoverProps) => (
  <div className="relative h-10 w-10 my-auto">
    <BreakPoints desktop={<Desktop {...props} />} />
  </div>
);

ViewComponent.displayName =
  'NotificationPreferences-Content-Desktop-Header-Popover-view';

export default ViewComponent;
