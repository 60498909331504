import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Text, type StringIndexedObject } from '@kanda-libs/ks-design-library';

import { Popover } from './components';

import Container from './Row-container';

export interface RowProps {
  id: string;
  field?: StringIndexedObject;
  isLoading?: boolean;
  arrayName?: string;
  index?: number;
}

const ViewComponent = (props: RowProps) => (
  <Container {...props}>
    {({ name, role, permissions, isLoading, fieldProps, id }) => (
      <div className="flex flex-row h-16 border-b border-neutral-300">
        <Text
          className="w-3/12 pr-2 my-auto text-14-22-em text-neutral-900"
          text={name}
          isLoading={isLoading}
        />
        <div className="flex flex-row justify-between w-3/12 pr-2">
          <Text
            className="min-w-10 my-auto text-14-22 text-neutral-600"
            text={role}
            isLoading={isLoading}
          />
          <Popover id={id} role={role} permissions={permissions} />
        </div>

        <span className="flex w-2/12 pr-2 my-auto">
          <div className="mx-auto">
            <FormTheme variant="clean">
              <FieldHandle.Switch
                id={fieldProps?.preJob?.id as string}
                name={fieldProps?.preJob?.name as string}
                defaultChecked={fieldProps?.preJob?.defaultChecked as boolean}
                onClick={fieldProps?.preJob?.onClick}
                isLoading={isLoading}
              />
            </FormTheme>
          </div>
        </span>

        <span className="flex w-2/12 pr-2 my-auto">
          <div className="mx-auto">
            <FormTheme variant="clean">
              <FieldHandle.Switch
                id={fieldProps?.postJob?.id as string}
                name={fieldProps?.postJob?.name as string}
                defaultChecked={fieldProps?.postJob?.defaultChecked as boolean}
                onClick={fieldProps?.postJob?.onClick}
                isLoading={isLoading}
              />
            </FormTheme>
          </div>
        </span>

        <span className="flex w-2/12 pr-2 my-auto">
          <div className="mx-auto">
            <FormTheme variant="clean">
              <FieldHandle.Switch
                id={fieldProps?.other?.id as string}
                name={fieldProps?.other?.name as string}
                defaultChecked={fieldProps?.other?.defaultChecked as boolean}
                onClick={fieldProps?.other?.onClick}
                isLoading={isLoading}
              />
            </FormTheme>
          </div>
        </span>
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'NotificationPreferences-Content-Desktop-Rows-Row-view';

export default ViewComponent;
