import { useFormContext } from '@kanda-libs/ks-component-ts';
import type { Job } from '@kanda-libs/ks-frontend-services';

function scrollToError(offset = 600) {
  const error = document.querySelector(
    '.mt-2.text-style-h.text-red-200',
  ) as HTMLDivElement;

  if (error) {
    window.scrollTo({
      top: error.offsetTop - offset,
    });
  } else {
    // Set timeout to account for the component rerendering as `errors` can be set
    // without the error being visible
    setTimeout(() => {
      scrollToError(offset);
    }, 100);
  }
}

// This is not an ideal solution, but the quote field doesn't handle scrolling
// to the error when it's the only one present on the page. This should be revisited
// once we have a better way of managing form state for these custom component fields
export default function useQuoteErrorScroll() {
  const context = useFormContext();

  if (!context) {
    return;
  }

  const {
    formState: { errors },
  } = context;

  if (Object.keys(errors)?.length === 1) {
    const jobItems = (errors?.job as unknown as Job)?.job_items;

    if (jobItems?.length === 1 && jobItems?.[0]?.quote_document) {
      scrollToError();
    }
  }
}
