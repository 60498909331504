import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from '@kanda-libs/ks-component-ts';
import { StringIndexedObject, useToast } from '@kanda-libs/ks-design-library';
import { Company, UserType, actions } from '@kanda-libs/ks-frontend-services';

import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany, useMe } from 'hooks';
import useApiError from 'hooks/useApiError';
import useUserDefaultValues from 'hooks/useUserDefaultValues';
import { cleanObject } from 'utils';

export interface InviteYourDeveloperHook {
  form: ReturnType<typeof useForm>;
  onSubmit: (values: StringIndexedObject) => void;
}

export default function useInviteYourDeveloper(): InviteYourDeveloperHook {
  const dispatch = useAppDispatch();
  const form = useForm({ mode: 'onBlur' });

  const { company } = useCurrentCompany();
  const { revalidateMe } = useMe();
  const { push } = useHistory();

  const defaultValues = useUserDefaultValues();

  const { showSuccess, showError } = useToast();
  const onError = useApiError(
    'Error inviting your developer - please try again later',
  );

  const onSubmit = useCallback(
    (formValues: StringIndexedObject) => {
      const { id } = company || {};
      const body = cleanObject({
        ...company,
        users: [...(company?.users || []), ...(formValues.company.users || [])],
      } as Company);

      const [developer]: [UserType] = formValues.company.users || [];

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            showSuccess('Your developer has been invited');
            revalidateMe();
            form.reset({ mode: `onBlur`, defaultValues });

            if (!developer) {
              showError(
                'Error inviting your developer - please try again later',
              );
              return;
            }

            const dashboardUrl =
              process.env.REACT_APP_HOME_URL?.slice(0, -1) || '';

            dispatch(
              actions.infoAuth({
                body: {
                  email: developer.email,
                  continue_url: [dashboardUrl, URLS.developerRedirect].join(''),
                },
              }),
            );

            push(
              URLS.updateYourWebsite.replace(
                ':id',
                'invite-your-developer-success',
              ),
            );
          },
        }),
      );
    },
    [
      company,
      dispatch,
      form,
      onError,
      push,
      revalidateMe,
      showSuccess,
      defaultValues,
      showError,
    ],
  );

  return { form, onSubmit };
}
