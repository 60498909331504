import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

import { NOTES_MODAL } from './Mobile-constants';

const ContainerComponent = ({ children }) => {
  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => showModal(NOTES_MODAL), [showModal]);

  return children({
    onClick,
  });
};

ContainerComponent.displayName = 'Job-Notes-Mobile-container';

export default ContainerComponent;
