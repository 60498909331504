import type { HandleType } from '@kanda-libs/ks-component-ts';

export const TITLE = 'Agree to our terms & conditions';

export const BULLETS = [
  {
    title: 'Platform Rules',
    subText: 'You agree to abide by our platform rules',
  },
  {
    title: 'Introducer of Kanda',
    subText: "You'll become an IAR of Kanda",
  },
];

export const FONTS = {
  Galano:
    'https://storage.googleapis.com/kanda-fonts/GalanoGrotesqueRegular.ttf',
  GalanoBold:
    'https://storage.googleapis.com/kanda-fonts/GalanoGrotesqueBold.ttf',
  Scriptina: 'https://storage.googleapis.com/kanda-fonts/Scriptina.ttf',
};

export const SIGNEE_FIELD = {
  label: 'Signee Name',
  name: 'company.company_info.contract_agreement.print_name',
  placeholder: "The signee's full name",
  autoCapitalize: 'on',
  autoCorrect: 'off',
  autoComplete: 'name',
  wrapperProps: {
    className: 'mt-6',
  },
  validation: {
    required: {
      value: true,
      message: 'Signee name is required',
    },
    minLength: {
      value: 1,
      message: 'Signee name must be longer than 1 character',
    },
  },
};

export const AUTH_FIELD = {
  name: 'auth',
  handle: 'checkbox',
  fieldText: 'I am duly authorised to sign this contract',
};

export const AUTH_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'You must be authorised to sign this contract',
  },
};

export const TAC_FIELD = {
  name: 'tac',
  handle: 'checkbox' as HandleType,
  fieldText: "I have read and agree to Kanda's terms & conditions",
};

export const TAC_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'You must agree to terms and conditions',
  },
};

export const IAR_FIELD = {
  name: 'iar',
  handle: 'checkbox',
  fieldText: 'I agree to become an IAR of Kanda ',
};

export const IAR_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'You must agree to become and IAR of Kanda',
  },
};
