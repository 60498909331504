import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';

const JobTableFilterButton: FunctionComponent = function () {
  return <BreakPoints desktop={<Desktop />} mobile={<Mobile />} />;
};

export default JobTableFilterButton;
