import { TITLE } from './Title-constants';

const ViewComponent = () => (
  <div className="flex flex-col mx-auto text-16-20-em text-neutral-500">
    <span>{TITLE.line1}</span>
    <span>{TITLE.line2}</span>
  </div>
);

ViewComponent.displayName = 'JobsPlaceholder-Title-view';

export default ViewComponent;
