import useSaveDraft from '../../CreateJob-useSaveDraft';

const ContainerComponent = ({ children }) => {
  const { handleSubmit, isSubmitting } = useSaveDraft();

  function onClick() {
    handleSubmit();
  }

  return children({
    onClick,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'CreateJob-SaveDraftButton-container';

export default ContainerComponent;
