import { Form } from '@kanda-libs/ks-component-ts';

import { NextButton } from './components';
import Container from './Deposit-container';

import { Page } from '../..';
import { Deposit } from '../../../../components';

const ViewComponent = () => (
  <Container>
    {({ form, onSubmit }) => (
      <Form id="create-job-deposit-mobile" form={form} onSubmit={onSubmit}>
        <Page nextButton={<NextButton />} nextSubmit>
          <Deposit.Info />
          <Deposit.Fields />
        </Page>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Deposit-view';

export default ViewComponent;
