export const CLASS_NAMES = {
  container:
    'py-4 px-5 md:py-6 lg:px-14 border border-neutral-300 shadow-card flex flex-col rounded-lg text-center',
  title: 'text-14-22 text-neutral-600 mb-4',
};

export const SEGMENTS = {
  notApplied: { color: '#E0E7F0', name: 'Not Applied' },
  approved: { color: '#4D52FF', name: 'Approved' },
  declined: { color: '#4D52FF', name: 'Declined', opacity: 0.35 },
};
