import { Form } from '@kanda-libs/ks-component-ts';
import { Loader } from '@kanda-libs/ks-design-library';
import InformationRequiredBanner from 'pages/Onboarding/components/Pages/components/InformationRequiredBanner';

import BasePage from '../BasePage';
import { TITLE, BULLETS } from './OnlinePresence-constants';
import useOnlinePresence from './useOnlinePresence';
import OnlinePresenceForm from './Form';

const ViewComponent = () => {
  const { form, onSubmit, isLoading, isSubmitting } = useOnlinePresence();

  return (
    <Form id="onboarding-company-info-job-info" form={form} onSubmit={onSubmit}>
      <BasePage sidebarBullets={BULLETS} sidebarTitle={TITLE}>
        <InformationRequiredBanner stage="online_reviewed" />
        <OnlinePresenceForm isLoading={isLoading} isSubmitting={isSubmitting} />
        <Loader isLoading={isSubmitting} />
      </BasePage>
    </Form>
  );
};

ViewComponent.displayName = 'OnlinePresence-view';

export default ViewComponent;
