import { useState, useCallback, useEffect } from 'react';
import {
  useWatch,
  useFormContext,
  type StringIndexedObject,
  type ValidationProps,
  type PostcodeCallback,
  ValidationItems,
} from '@kanda-libs/ks-component-ts';
import type { Addresses } from 'types';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from '../../../../../../../../../../utils';
import type { SoleTraderFormProps } from './SoleTraderForm-view';

export interface ContainerComponentChildrenArgs {
  arrayName: string;
  index: number;
  setPostcodeName: (name: string) => void;
  postcodeCallback: PostcodeCallback;
  postcodeName: string | undefined;
  addresses: Addresses | null;
  manual: boolean;
  enterManually: () => void;
  onSameClick: () => void;
  selectProps: ValidationProps;
}

export interface ContainerComponentProps extends SoleTraderFormProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  arrayName,
  index,
  company,
}: ContainerComponentProps) => {
  const { setValue, getValues } = useFormContext();

  const [addresses, setAddresses] = useState<Addresses | null>(null);
  const [postcodeName, setPostcodeName] = useState<string | undefined>();
  const [manual, setManual] = useState(false);

  const baseName = `${arrayName}.${index}.director_info.home_address`;

  const postcodeCallback = useCallback((results) => setAddresses(results), []);
  const enterManually = useCallback(() => setManual(true), []);
  const onSameClick = useCallback(() => {
    const address = company?.sole_trader_info?.trading_address;
    setValue(baseName, address);
  }, [setValue, company, baseName]);

  const address = useWatch({ name: baseName });

  const validation = {
    validate: {
      value: () => validateAddressSelect(getValues(), baseName),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(validation),
    validationErrors: getValidationErrors(validation),
  } as unknown as ValidationProps;

  useEffect(() => {
    if (
      !address?.line_1 ||
      !addresses?.addresses ||
      addresses?.addresses?.length === 0
    )
      return;
    const match = addresses.addresses.findIndex(
      (addr) => (addr as StringIndexedObject).line_1 === address.line_1,
    );
    if (match === address?.selected) return;
    setValue(`${baseName}.selected`, match);
  }, [address, addresses, baseName, setValue]);

  return children({
    arrayName,
    index,
    setPostcodeName,
    postcodeCallback,
    postcodeName,
    addresses,
    manual,
    enterManually,
    onSameClick,
    selectProps,
  });
};

ContainerComponent.displayName =
  'SetupCompany-DirectorInfo-Form-Limited-Card-container';

export default ContainerComponent;
