import { Card, Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES, SKELETONS } from './constants';

interface DirectorCardProps {
  name?: string;
  companyName?: string;
  isLoading?: boolean;
}

const DirectorCard = ({ name, companyName, isLoading }: DirectorCardProps) => (
  <div className={CLASS_NAMES.outerWrapper}>
    <Card className={CLASS_NAMES.card}>
      <div className={CLASS_NAMES.wrapper}>
        <Text
          text={name}
          isLoading={isLoading}
          className={CLASS_NAMES.name}
          skeletonProps={SKELETONS.name}
        />
        <Text
          text={companyName}
          isLoading={isLoading}
          className={CLASS_NAMES.companyName}
          skeletonProps={SKELETONS.companyName}
        />
      </div>
    </Card>
    {isLoading ? (
      <Text
        isLoading={isLoading}
        text="Not you? Please contact help@kanda.co.uk"
      />
    ) : (
      <span className="text-style-g mt-4">
        Not you? Please contact{' '}
        <a href="mailto:help@kanda.co.uk" className={CLASS_NAMES.notYouLink}>
          help@kanda.co.uk
        </a>
      </span>
    )}
  </div>
);

DirectorCard.defaultProps = {
  name: null,
  companyName: null,
  isLoading: false,
};

export default DirectorCard;
