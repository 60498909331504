import { useCallback, useContext, useMemo } from 'react';
import { useWatch, ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { NOTES_MODAL_ID } from './Notes-constants';

export interface ContainerChildrenArgs {
  onClick: (() => void) | undefined;
  initialNotes: string;
}

export interface ContainerProps {
  children: (args: ContainerChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerProps) => {
  const {
    data: { job },
  } = useCreateJobContextValue();
  const { showModal } = useContext(ModalsWrapperContext);

  const notes = useWatch({ name: 'job.notes' });

  const clickHandler = useCallback(
    () => showModal(NOTES_MODAL_ID),
    [showModal],
  );

  const initialNotes = job?.notes?.[0] || '';

  const onClick = useMemo(() => {
    if (!notes) return undefined;
    return clickHandler;
  }, [notes, clickHandler]);

  return children({
    onClick,
    initialNotes,
  });
};

ContainerComponent.displayName = 'JobDetails-Title-container';

export default ContainerComponent;
