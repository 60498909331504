import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { MODAL_ID } from './constants';
import useCurrentJob from '../../JobDetails-useCurrentJob';

export default function useMarkAsFinishedModal() {
  const dispatch = useAppDispatch();

  const { job } = useCurrentJob();
  const { showSuccess } = useToast();
  const { hideModal } = useContext(ModalsWrapperContext);

  const isExampleJob = job?.flow_type === 'example';

  const isSubmitting = useSelector(selectors.job.getIsSubmitting);

  const onError = useApiError('Error completing job at this time');

  const onFinish = useCallback(() => {
    if (!job) return;

    dispatch(
      actions.completeJob({
        params: { id: job.id || '' },
        onError,
        onSuccess: () => {
          hideModal(MODAL_ID);

          if (isExampleJob) {
            showSuccess(
              'Your example job has been marked as finished. Check your email to see the invitation to sign the sat note',
            );
          }
        },
      }),
    );
  }, [job, onError, hideModal, isExampleJob, showSuccess, dispatch]);

  return {
    onFinish,
    isSubmitting,
  };
}
