import { Button, LoadingSpinner } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import {
  CLASS_NAMES,
  DESCRIPTION,
  INVALID_DESCRIPTION,
  INVALID_TITLE,
  TITLE,
} from './Content-constants';

import { URLS } from '../../../../../config';

interface ViewComponentProps {
  /**
   * is loading state
   */
  isLoading?: boolean;
  /**
   * is invalid state
   */
  isInvalid?: boolean;
}

const ViewComponent = ({ isLoading, isInvalid }: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.headerWrapper}>
      <p className={CLASS_NAMES.title}>
        {isLoading && TITLE}
        {isInvalid && INVALID_TITLE}
      </p>
      <p className={CLASS_NAMES.description}>
        {isLoading && DESCRIPTION}
        {isInvalid && INVALID_DESCRIPTION}
      </p>
    </div>
    {isLoading && (
      <LoadingSpinner
        stroke="green-400"
        className="m-auto animate-spin"
        duration={0}
      />
    )}
    {isInvalid && (
      <Link to={URLS.accountSettings}>
        <Button.Text
          id="verify-email-account-settings"
          label="Account Settings"
          icon="arrow-right"
          variant="solid"
        />
      </Link>
    )}
  </div>
);

ViewComponent.displayName = 'ResetPassword-Landing-Content-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  isInvalid: undefined,
};

export default ViewComponent;
