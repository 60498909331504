import { DesktopHeader, Icon, Text } from '@kanda-libs/ks-design-library';

import { SubscriptionNotice } from './components';
import Container from './DesktopHeader-container';

const ViewComponent = (props) => (
  <Container>
    {({ isLoading, logout, showNotice, companyItems, items }) => (
      <div className="z-50 flex flex-1 w-full">
        <DesktopHeader
          isLoading={isLoading}
          companyName="My Kanda"
          companyItems={companyItems}
          logout={logout}
          items={items}
          notice={showNotice ? SubscriptionNotice : undefined}
          extraOptions={
            <div className="flex flex-row items-center justify-center mr-4 text-neutral-700 gap-x-2">
              <Icon icon="phone" size={18} /> <Text text="03308187500" />
            </div>
          }
          {...props}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopHeader-view';

export default ViewComponent;
