import { Icon } from '@kanda-libs/ks-design-library';

import { TITLE, DESCRIPTION, ICON_PROPS } from './Confirmation-constants';

import { CONFIRMATION_CLASS_NAMES } from '../../../AccountSettings-constants';

const ViewComponent = () => (
  <div className={CONFIRMATION_CLASS_NAMES.container}>
    <Icon {...ICON_PROPS} />
    <p className={CONFIRMATION_CLASS_NAMES.title}>{TITLE}</p>
    <p className={CONFIRMATION_CLASS_NAMES.description}>{DESCRIPTION}</p>
  </div>
);

ViewComponent.displayName = 'ChangePasswordConfirmation-view';

export default ViewComponent;
