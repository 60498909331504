import { FormTheme } from '@kanda-libs/ks-component-ts';

import Container from './Mobile-container';
import {
  Title,
  Description,
  VAT,
  Quantity,
  Price,
  Total,
  Options,
} from '../components';

interface ViewComponentProps {
  /**
   * Index of the item in the array
   */
  arrayName: string;
  /**
   * Index of the item in the array
   */
  index: number;
  /**
   * Function to remove an item
   */
  remove(...args: unknown[]): unknown;
  /**
   * State of whether to show remove button or not
   */
  showRemove: boolean;
}

const ViewComponent = ({
  index,
  arrayName,
  remove,
  showRemove,
}: ViewComponentProps) => (
  <Container index={index} showRemove={showRemove} remove={remove}>
    {({
      itemRef,
      theme,
      inFocus,
      className,
      removeButtonClassName,
      handleRemove,
    }) => (
      <FormTheme variant={theme}>
        <div ref={itemRef} className={className}>
          <div className="flex flex-row">
            <div className="w-full">
              <Title arrayName={arrayName} index={index} />
            </div>
            <div className={removeButtonClassName}>
              <Options
                index={index}
                removeItem={handleRemove}
                showRemove={showRemove}
              />
            </div>
          </div>
          <Description arrayName={arrayName} index={index} />
          <div className="flex flex-row flex-wrap -mb-2">
            <Quantity arrayName={arrayName} index={index} />
            <Price arrayName={arrayName} index={index} />
            <VAT arrayName={arrayName} index={index} />
            <div className="flex flex-1 flex-row items-end justify-end">
              <Total arrayName={arrayName} index={index} inFocus={inFocus} />
            </div>
          </div>
        </div>
      </FormTheme>
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Mobile-view';

export default ViewComponent;
