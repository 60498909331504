export const CLASS_NAMES = {
  footer: 'absolute bottom-0 right-0 m-10',
};

export const BUTTON_PROPS = {
  id: 'create-job-send-confirmation-footer-finish',
  icon: 'check',
  left: true,
  label: 'Finish',
};
