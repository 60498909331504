import { BreakPoints } from '@kanda-libs/ks-design-library';
import SetupStepIndicator from '../StepIndicator';
import SetupLayoutDesktop from './Desktop';
import SetupLayoutMobile from './Mobile';

interface SetupLayoutProps {
  children: React.ReactNode;
  mobileFooter?: React.ReactNode;
}

const Wrapper: React.FunctionComponent<SetupLayoutProps> = function ({
  children,
}) {
  return (
    <div className="flex flex-col flex-1 w-full max-w-216 mx-auto">
      <SetupStepIndicator />
      {children}
    </div>
  );
};

const SetupLayout: React.FunctionComponent<SetupLayoutProps> = function ({
  children,
  mobileFooter,
}) {
  return (
    <BreakPoints
      mobile={
        <SetupLayoutMobile footer={mobileFooter}>
          <Wrapper>{children}</Wrapper>
        </SetupLayoutMobile>
      }
      desktop={
        <SetupLayoutDesktop>
          <Wrapper>{children}</Wrapper>
        </SetupLayoutDesktop>
      }
    />
  );
};

export default SetupLayout;
