import type { Job } from '@kanda-libs/ks-frontend-services';
import useQueryParam from 'hooks/useQueryParam';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectors } from 'store';
import { URLS } from '../../../../config';
import { useDownloadPdf, useFuse } from '../../../../hooks';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import type { HomeContextValue } from '../context';
import {
  ORDER,
  PLACEHOLDER_JOBS,
  SEARCH_OPTIONS,
  SORTING,
} from './Provider-constants';
import {
  filterJobData,
  filterOpenJobs,
  formatJobs,
  sortJobData,
} from './Provider-functions';
import type { HomeFilter } from './types';

export type ContainerComponentChildrenArgs = HomeContextValue;

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const getQueryPage = (queryPage: string | null) => {
  if (!queryPage) return 0;
  if (queryPage === 'null') return 0;
  const int = parseInt(queryPage, 10);
  if (Number.isNaN(int) || int <= 0) return 0;
  return int - 1;
};

const correctPageIndex = (
  pageIndex: number,
  perPage: number,
  jobData?: Job[],
  setPageIndex?: Dispatch<SetStateAction<number>>,
): number => {
  if (!jobData || jobData.length === 0) return 1;
  const maxPages = Math.ceil(jobData.length / perPage);
  if (pageIndex >= maxPages) {
    if (setPageIndex) setPageIndex(maxPages - 1);
    return maxPages;
  }
  return pageIndex + 1;
};

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { downloadPdf, isSubmitting: pdfIsSubmitting } = useDownloadPdf();

  const [page] = useQueryParam('page');

  const [sorting, setSorting] = useState(SORTING.UPDATED_AT);
  const [order, setOrder] = useState(ORDER.NORMAL);
  const [filter, setFilter] = useState<HomeFilter>({
    status: '',
    financeStatus: '',
    price: '',
  });
  const [pageIndex, setPageIndex] = useState(getQueryPage(page));
  const [perPage, setPerPage] = useState(10);

  const { pathname } = useLocation();
  const archive = pathname === URLS.archive;

  const jobData = useSelector(selectors.job.getEntitiesAsArray);
  const jobHasFetched = useSelector(selectors.job.getHasFetched);
  const searchIsSubmitting = useSelector(selectors.infoSearch.getIsSubmitting);

  const { company, isLoading: companyIsValidating } = useCurrentCompany();

  // const isLoading =
  //   !jobHasFetched || !companyIsValidating || !searchIsSubmitting;
  const isLoading = !jobHasFetched && searchIsSubmitting;

  // Jobs, only open jobs filtered to show
  const jobs = useMemo(
    () =>
      formatJobs(
        filterOpenJobs(
          jobData?.length > 0 ? jobData : PLACEHOLDER_JOBS,
          archive,
        ),
      ),
    [jobData, archive],
  );

  // Handles searching
  const { hits, query, setQuery } = useFuse(jobs || [], SEARCH_OPTIONS, {
    initialQuery: '',
  });

  const filteredData = useMemo(() => {
    if (isLoading) return [];
    const rawData = hits.map((hit) => hit.item);
    return sortJobData(filterJobData(rawData, filter), sorting, order);
  }, [filter, sorting, order, isLoading, hits]);

  // Determine number of pages
  const totalPages = useMemo(
    () => (isLoading ? 1 : Math.ceil(filteredData.length / perPage)),
    [isLoading, perPage, filteredData],
  );

  // Data slicing for pagination
  const data = useMemo(
    () =>
      filteredData.slice(pageIndex * perPage, pageIndex * perPage + perPage),
    [pageIndex, perPage, filteredData],
  );

  const setPage = useCallback((index) => setPageIndex(index), []);

  const filterRef = useRef(filter);
  /**
   * Handles filter click
   * @param {String} filterType ex: status
   * @param {Array} filterValue
   * @param {Boolean} merge
   */
  const updateFilter = useCallback(
    (filterType: keyof HomeFilter, filterValue) => {
      const newFilter = {
        ...filterRef.current,
        [filterType]: filterValue,
      };
      setFilter(newFilter);
      filterRef.current = newFilter;
    },
    [],
  );

  const getCurrentFilters = useCallback(
    (type: keyof HomeFilter) => filter?.[type]?.split(';') || [],
    [filter],
  );

  const onAddFilter = useCallback(
    (type: keyof HomeFilter, status: string): void => {
      const currentFilters = getCurrentFilters(type);
      updateFilter(type, [...currentFilters, status].join(';'));
    },
    [updateFilter, getCurrentFilters],
  );

  const onRemoveFilter = useCallback(
    (type: keyof HomeFilter, status: string): void => {
      const currentFilters = getCurrentFilters(type);
      updateFilter(type, currentFilters.filter((s) => s !== status).join(';'));
    },
    [updateFilter, getCurrentFilters],
  );

  const hasActiveFilters = Boolean(
    filter?.status || filter?.financeStatus || filter?.price,
  );

  const showOptions = !isLoading && jobData?.length > 0;

  const url = useMemo(() => {
    const windowUrl = new URL(window.location.href);
    if (!jobHasFetched) {
      if (page) return windowUrl;
      windowUrl.searchParams.set('page', '1');
      setPageIndex(0);
      return windowUrl;
    }
    const correctedPageIndex = correctPageIndex(
      pageIndex,
      perPage,
      jobData,
      setPageIndex,
    );
    windowUrl.searchParams.set('page', String(correctedPageIndex));
    return windowUrl;
  }, [page, pageIndex, perPage, jobData, jobHasFetched]);

  useEffect(() => {
    window.history.pushState(null, '', url.toString());
  }, [url]);

  return children({
    data,
    jobs,
    company,
    isLoading,
    companyIsValidating,
    isValidating: isLoading,
    sorting,
    setSorting,
    order,
    setOrder,
    filter,
    setFilter,
    updateFilter,
    hasActiveFilters,
    pageIndex,
    setPageIndex,
    perPage,
    setPerPage,
    setPage,
    query,
    setQuery,
    totalPages,
    archive,
    showOptions,
    onAddFilter,
    onRemoveFilter,
    downloadPdf,
    pdfIsSubmitting,
    showingPlaceholderJobs: jobData?.length === 0,
  });
};
ContainerComponent.displayName = 'Home-Provider-Container';

export default ContainerComponent;
