import { Button, Text } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout';

const Welcome: FunctionComponent = function () {
  return (
    <Layout>
      <div className="flex flex-col gap-y-8">
        <Text text="Get your team on Kanda and get more done in the process." />
        <div className="mt-4">
          <Link to={URLS.inviteForm}>
            <Button.Text
              left
              id="welcome-invite-next"
              label="Invite team member"
              icon="plus"
              variant="gradient"
            />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Welcome;
