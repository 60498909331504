import type { SelectOption } from '@kanda-libs/ks-component-ts';
import { formatSelectOptions } from './helpers';

export interface WorkTypeOption extends Omit<SelectOption, 'name'> {
  name?: string;
}

export const WORK_TYPES: WorkTypeOption[] = [
  {
    value: 'air_conditioning',
  },
  {
    value: 'alarm_systems',
  },
  {
    value: 'artificial_grass',
  },
  {
    value: 'bathrooms_supply_and_fitted',
  },
  {
    value: 'bathrooms_supply_only',
  },
  {
    value: 'bedrooms_furnitue_custom',
    name: 'Bedrooms Furniture (Custom)',
  },
  {
    value: 'blinds_awnings_shutters',
    name: 'Blinds, Awnings and/or Shutters',
  },
  {
    value: 'boiler_swap',
  },
  {
    value: 'cctv',
    name: 'CCTV',
  },
  {
    value: 'central_heating_system',
  },
  {
    value: 'conservatory',
  },
  {
    value: 'conservatory_roof',
  },
  {
    value: 'decking',
  },
  {
    value: 'door_canopy',
  },
  {
    value: 'doors',
  },
  {
    value: 'driveways',
  },
  {
    value: 'electrical_installation',
  },
  {
    value: 'ev_charger',
    name: 'EV Charger',
  },
  {
    value: 'fencing',
  },
  {
    value: 'fireplaces',
  },
  {
    value: 'fitted_furniture',
  },
  {
    value: 'flooring_excluding_carpets',
    name: 'Flooring (Exlcuding Carpets)',
  },
  {
    value: 'garage_doors',
  },
  {
    value: 'garden_buildings',
  },
  {
    value: 'gates',
  },
  {
    value: 'glass_splash_backs',
  },
  {
    value: 'granite_worktops',
  },
  {
    value: 'home_lifts',
  },
  {
    value: 'home_study',
  },
  {
    value: 'hot_tubs_and_spas',
  },
  {
    value: 'insulation_roof_foil_wrap_only',
    name: 'Insulation (Roof Foil Wrap Only)',
  },
  {
    value: 'kitchens_fitted',
    name: 'Kitchens (Fitted)',
  },
  {
    value: 'kitchens_supply_only',
    name: 'Kitchens (Supply Only)',
  },
  {
    value: 'landscaping',
  },
  {
    value: 'loft_boarding',
  },
  {
    value: 'loft_conversion',
  },
  {
    value: 'media_walls',
  },
  {
    value: 'painting_and_decorating',
  },
  {
    value: 'patios',
  },
  {
    value: 'plastering',
  },
  {
    value: 'rendering',
  },
  {
    value: 'resin_driveways',
  },
  {
    value: 'roofing',
  },
  {
    value: 'roofing_excl_flat_roofs',
    name: 'Roofing (Exclusing Flat Roofs)',
  },
  {
    value: 'roofline',
  },
  {
    value: 'security_lighting',
  },
  {
    value: 'stair_lifts',
  },
  {
    value: 'staircases',
  },
  {
    value: 'tiling',
  },
  {
    value: 'under_stair_storage',
  },
  {
    value: 'underfloor_heating',
  },
  {
    value: 'upvc_doors',
  },
  {
    value: 'verandas',
  },
  {
    value: 'wall_insulation_and_cladding',
  },
  {
    value: 'water_softeners',
  },
  {
    value: 'windows',
  },
  {
    value: 'windows_and_doors',
  },
  {
    value: 'wardrobes',
  },
  {
    value: 'other',
  },
];

interface WorkTypeSelectProps {
  name: string;
  options: SelectOption[];
}

export const PROPS: WorkTypeSelectProps = {
  name: 'job.work_type',
  options: formatSelectOptions(WORK_TYPES),
};

interface WorkTypeLabelProps {
  label: string;
  helperText: string;
  inlineHelperText: boolean;
}

export const LABEL_PROPS: WorkTypeLabelProps = {
  label: 'Job type',
  helperText: '(visible only to the lender)',
  inlineHelperText: true,
};

export const NO_RATES_LABEL =
  "You won't be able to attach any finance options to this job due to the selected work type.";
