import { URLS } from 'config';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StepBasis, STEPS, STEP_CLASSNAMES } from './constants';

interface IndicatorStep {
  label: string;
  className: string;
}

interface IndicatorClassNames {
  wrapper: string;
  progress: string;
}

export interface StepIndicatorHook {
  steps: IndicatorStep[];
  indicatorClassNames: IndicatorClassNames;
}

const getLocationWidth = (pathname: string) => {
  if (pathname === URLS.setupAccount) return 'w-4';
  if (pathname === URLS.setupRates) return 'w-1/2';
  return 'w-full';
};

export default function useStepIndicator(): StepIndicatorHook {
  const { pathname } = useLocation();

  const steps: IndicatorStep[] = useMemo(
    () =>
      STEPS.map((step: StepBasis) => ({
        label: step.label,
        className:
          step.url === pathname
            ? STEP_CLASSNAMES.active
            : STEP_CLASSNAMES.default,
      })),
    [pathname],
  );

  const indicatorClassNames = useMemo(
    () => ({
      wrapper: 'w-full rounded-full h-2 bg-neutral-200',
      progress: [
        'rounded-full h-2 bg-green-500',
        getLocationWidth(pathname),
      ].join(' '),
    }),
    [pathname],
  );

  return {
    steps,
    indicatorClassNames,
  };
}
