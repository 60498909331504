import { Button, Header, Layout } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content, Footer } from '../components';

import { URLS } from '../../../../config';

interface ViewComponentProps {
  /**
   * Disabled
   */
  disabled?: boolean;
  /**
   * is loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({ disabled, isLoading }: ViewComponentProps) => (
  <Layout
    noBorder
    header={
      <Header.Base
        lines
        options={[
          <Link to={URLS.login} key="back-button">
            <Button.Icon id="reset-password-mobile-close" icon="close" />
          </Link>,
        ]}
      />
    }
    footer={<Footer disabled={disabled} />}
  >
    <Content isLoading={isLoading} />
  </Layout>
);

ViewComponent.displayName = 'ResetPassword-Landing-Mobile-view';

ViewComponent.defaultProps = {
  disabled: undefined,
  isLoading: undefined,
};

export default ViewComponent;
