import { useContext, useCallback } from 'react';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children }) => {
  const { filter, onRemoveFilter } = useContext(Context);

  const statuses = filter?.status?.split(';').filter((status) => status);

  const onClick = useCallback(
    (status: string) => {
      onRemoveFilter('status', status);
    },
    [onRemoveFilter],
  );

  return children({ statuses, onClick });
};

ContainerComponent.displayName = 'StatusFilter-container';

export default ContainerComponent;
