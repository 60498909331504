import type {
  FinanceRate,
  Job,
  JobItem,
} from '@kanda-libs/ks-frontend-services';
import type { Totals } from 'types';
import { calculateJobTotal } from './Jobs-functions';

/**
 * @typedef {Object} FinanceOptions
 * @property {Number} interest - The interest on the finance option
 * @property {Number} duration - The duration of the finance option in months
 */

export type FinanceOptionDetails = {
  interest: number;
  duration: number;
};

/**
 * Converts finance option name into interest and duration
 * @param {String} financeOption finance option name, e.g. INTEREST_BEARING_60_1390
 *
 * @returns {FinanceOptions} Finance options object
 */
export const getFinanceOptionDetails = (
  financeOption: FinanceRate,
): FinanceOptionDetails => {
  const { name } = financeOption;
  const splitName = name?.split('_');
  if (name.toLowerCase().includes('free'))
    return {
      interest: 0,
      duration: parseInt(splitName.slice(-1)[0], 10),
    };
  const [durationString, interestString] = splitName.slice(-2);
  return {
    interest: parseInt(interestString, 10) / 100,
    duration: parseInt(durationString, 10),
  };
};

/**
 * Calculate the 11.9% interest base fee
 * @param duration
 * @returns {number}
 */
export const calculate119BaseFee = (duration: number): number => {
  switch (duration) {
    case 12:
    case 24:
    case 60:
    case 84:
      return 0;
    default:
      return 4900;
  }
};

export interface JobCosts {
  percentage: number;
  baseRate: number;
  baseFee: number;
  total: number;
}

/**
 * Calculates cost to tradesperson from finance breokerage fee
 */
export const calculateCost = (
  lineItems: JobItem[],
  fee: number,
  financeOption: FinanceRate,
  deposit: number | null = null,
): JobCosts => {
  if (!lineItems || !financeOption || lineItems.length === 0)
    return {
      percentage: 0,
      baseRate: 0,
      baseFee: 0,
      total: 0,
    };

  const { totalIncVat: totalNotIncludingDeposit } =
    calculateJobTotal(lineItems);

  let total = totalNotIncludingDeposit;

  /**
   * skipDeposit is set to true, so calculate cost based
   * on the total - deposit value
   */
  if (deposit != null) {
    total = totalNotIncludingDeposit - (deposit as number);
  }

  const percentage = fee / 100;

  const baseRate = (total * percentage) / 100;

  const baseFee = financeOption.charge;

  const financeTotal = Math.max(
    financeOption?.min_charge || 0,
    baseRate + baseFee,
  );

  return {
    percentage: percentage || 0,
    baseRate: baseRate || 0,
    baseFee: baseFee || 0,
    total: financeTotal || 0,
  };
};

export interface LoanCosts {
  monthly: number;
  financeCost: number;
  total: number;
}

/**
 * Calculates Loan from total and payment method
 */
export const calculateLoanCosts = (
  totals: Totals,
  paymentMethod: FinanceRate,
  deposit = 0,
): LoanCosts => {
  if (!paymentMethod || !totals)
    return {
      monthly: 0,
      financeCost: 0,
      total: 0,
    };

  const total = totals.totalIncVat - deposit;

  const { interest, duration } = getFinanceOptionDetails(paymentMethod);

  const rate = interest / 12 / 100;

  if (rate === 0)
    return {
      monthly: Math.ceil(total / duration),
      financeCost: 0,
      total: totals.totalIncVat,
    };

  const compoundInterest = (1 + rate) ** duration;

  const monthlyLoan =
    (total * (rate * compoundInterest)) / (compoundInterest - 1);

  const cost = Math.ceil(monthlyLoan * duration);

  return {
    monthly: Math.ceil(monthlyLoan),
    financeCost: cost - total,
    total: cost,
  };
};

export const sortFinanceOptions = (options: FinanceRate[]): FinanceRate[] => {
  const order = [...(options || [])]
    .map((option) => {
      if (option.name.toLowerCase().includes('free')) return 0;
      return parseInt(option.name.split('_').slice(-1)[0], 10);
    })
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort((v1, v2) => v1 - v2)
    .map((apr) => (apr === 0 ? 'free' : String(apr)));

  return order.reduce((sorted, key) => {
    const rates = options.filter((option) =>
      option.name.toLowerCase().includes(key),
    );
    if (rates.length === 0) return sorted;
    if (rates.length === 1) {
      sorted.push(...rates);
      return sorted;
    }
    const sortedRates = [...(rates || [])].sort((rate1, rate2) => {
      const index = key === 'free' ? -1 : -2;
      return (
        (rate1.name.split('_').slice(index)[0] as unknown as number) -
        (rate2.name.split('_').slice(index)[0] as unknown as number)
      );
    });
    sorted.push(...sortedRates);
    return sorted;
  }, [] as FinanceRate[]);
};

export const getFinanceOptionName = (option: FinanceRate): string =>
  `${option.name}-${option.provider}`;

export const financeOptionIsSelected = (
  option: FinanceRate,
  selectedOptions: FinanceRate[] = [],
): boolean => {
  const selectedOptionNames = selectedOptions.map(getFinanceOptionName);
  return selectedOptionNames.includes(getFinanceOptionName(option));
};

export const removeOptionFromSelectedOptions = (
  option: FinanceRate,
  selectedOptions: FinanceRate[],
): FinanceRate[] =>
  selectedOptions.filter(
    (selectedOption) =>
      getFinanceOptionName(selectedOption) !== getFinanceOptionName(option),
  );

export const removeCombinedOptionsFromSelectedOptions = (
  options: FinanceRate[],
  selectedOptions: FinanceRate[],
): FinanceRate[] =>
  selectedOptions.filter((selectedOption) => {
    const selectedOptionName = getFinanceOptionName(selectedOption);
    const foundOption = options.find(
      (option) => getFinanceOptionName(option) === selectedOptionName,
    );
    return !foundOption;
  });

export const combinedFinanceOptionsAreSelected = (
  options: FinanceRate[],
  selectedOptions: FinanceRate[] = [],
): boolean => {
  const optionNames = options.map((option) =>
    getFinanceOptionName((option as FinanceRate) || option),
  );
  const selectedOptionNames = selectedOptions.map(getFinanceOptionName);
  const foundOptionNames = optionNames.filter((option) =>
    selectedOptionNames.includes(option),
  );

  return foundOptionNames.length === optionNames.length;
};

export const getDepositPercentage = (job?: Job): number => {
  if (!job?.job_items) return 0;
  if (job?.deposit_type === 'no_deposit') return 0;
  const deposit = job?.deposit_value?.amount;
  const { totalIncVat: total } = calculateJobTotal(job?.job_items);
  return Math.ceil((deposit / total) * 100);
};

export const getDepositAmount = (job?: Job): number => {
  if (job?.deposit_type === 'fixed_deposit')
    return job?.deposit_value?.amount || 0;
  const depositPercentage = getDepositPercentage(job);
  const { totalIncVat: total } = calculateJobTotal(job?.job_items || []);
  return Math.round((total / 100) * depositPercentage);
};

export interface Payout {
  payout: number;
  subsidyTotal: number;
  subsidyPercentage: number;
  subsidyBase: number;
}

export const calculatePayout = (
  lineItems: JobItem[],
  fee: number,
  financeOption: FinanceRate,
  deposit: number | null = null,
  skipDeposit: boolean,
): Payout => {
  const { min_charge: minCharge, charge } = financeOption;

  const { totalIncVat: jobTotal } = calculateJobTotal(lineItems);

  const total = skipDeposit ? jobTotal - (deposit || 0) : jobTotal;

  const subsidyFee = (fee * total) / 10000;
  const subsidyTotal = Math.max(minCharge || 0, subsidyFee + charge);

  const payout = total - subsidyTotal;

  return {
    payout,
    subsidyTotal,
    subsidyPercentage: fee,
    subsidyBase: charge,
  };
};

export const canApplySecondLine = (job?: Job) => {
  const financeOptions = job?.finance_options;
  if (!financeOptions) return false;
  return financeOptions.some((rate: FinanceRate) => rate.enabled === false);
};

export const hasAppliedSecondLine = (job: Job) => {
  const financeOptions = job?.finance_options;
  const financeStatus = job?.finance_status;
  const canApply = canApplySecondLine(job);
  if (!financeOptions || !canApply) return false;
  const latestLine = job?.customer_options?.finance_option?.finance_types[0];
  if (latestLine !== 'secondary') return false;
  if (financeStatus === 'not_applied') return false;
  return true;
};

export const formatToCurrency = (
  value: number,
  currency = 'GBP',
  locale = 'en-US',
  minorUnit = 2,
): string => {
  const majorUnitValue = value / 10 ** minorUnit;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: minorUnit,
  });
  return formatter.format(majorUnitValue);
};
