import {
  StringIndexedObject,
  useAtlasChatWidget,
  useToast,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export const convertErrorToObject = (
  error: StringIndexedObject | string | undefined,
): StringIndexedObject => {
  if (!error || typeof error === 'string')
    return {
      code: 400,
      message: error,
    };
  if (!error.message)
    return {
      code: 400,
      message: 'Unknown error',
    };
  const mapping = error.message
    .split(', ')
    .reduce((errorObj: StringIndexedObject, part: string) => {
      const parts = part.split('=');
      if (parts.length !== 2) return errorObj;
      return {
        ...errorObj,
        [parts[0]]: parts[1],
      };
    }, {});
  if (Object.keys(mapping).length === 0)
    return {
      code: 400,
      message: 'Unknown error',
    };
  return mapping;
};

export interface SubscribeFormHook {
  terms: boolean;
  debit: boolean;
  loading: boolean;
  onClickTerms: () => void;
  onClickDebit: () => void;
  createSubscription: () => void;
  onClickHelp: () => void;
}

export default function useSubscribeForm(): SubscribeFormHook {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const [terms, setTerms] = useState(false);
  const [debit, setDebit] = useState(false);

  const { showError } = useToast();
  const onError = useApiError('There was an error creating the subscription');

  const { openChat } = useAtlasChatWidget();
  const isSubmitting = useSelector(
    selectors.postCompanyBilling.getIsSubmitting,
  );

  const onClickTerms = useCallback(() => {
    setTerms(!terms);
  }, [terms]);

  const onClickDebit = useCallback(() => {
    setDebit(!debit);
  }, [debit]);

  const onClickHelp = useCallback(() => openChat(), [openChat]);

  const createSubscription = useCallback(() => {
    if (!company?.id) {
      showError('Error creating subscription - company id missing');
      return;
    }
    dispatch(
      actions.postCompanyBilling({
        params: {
          id: company?.id,
        },
        onError,
        onSuccess: (data) => {
          if (!data?.checkout_url) {
            showError(
              'Authorisation URL missing from response - please contact Kanda',
            );
            return;
          }
          window.location.href = data.checkout_url;
        },
      }),
    );
  }, [company, dispatch, onError, showError]);

  return {
    terms,
    debit,
    loading: isSubmitting,
    onClickTerms,
    onClickDebit,
    createSubscription,
    onClickHelp,
  };
}
