import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';

export const LEGAL_TEXT = `$company is an Introducer Appointed Representative of Kanda. Kanda is a trading style of Kanda Products & Services Ltd, Forward House, 17 High Street, Henley-in-Arden, B95 5AA, registered in England (11330964), authorised and regulated by the Financial Conduct Authority (firm reference number 920795). Kanda is acting as a credit broker offering finance products from a panel of lenders Credit is subject to status. Representative example: 11.9% APR Representative based on a loan of £5000 repayable over 60 months at an interest rate of 11.9% pa (fixed). Monthly repayment of £109.45. Total amount payable £6567.16.`;

export const COPY_BUTTON_PROPS = {
  id: 'create-job-send-confirmation-copy-link-button',
  icon: 'copy',
  size: 14,
  left: true,
  label: 'Copy Text',
};

export const COPIED_BUTTON_PROPS = {
  id: 'create-job-send-confirmation-copied-link-button',
  icon: 'check',
  variant: 'turquoise' as ButtonLinkVariant,
  size: 14,
  left: true,
  label: 'Text Copied',
};
