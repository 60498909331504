import { Button, Text } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';
import { URLS } from '../../../../config';
import CompanyLogo from '../CompanyLogo';
import { CompanyBanner } from '../index';
import { CLASS_NAMES } from './CompanyHeader-constants';
import Container from './CompanyHeader-container';

const ViewComponent = () => (
  <Container>
    {({
      profilePicture,
      displayName,
      isLoading,
      isSubscribed,
      subscriptionStatusLabel,
    }) => (
      <>
        <div className={CLASS_NAMES.container}>
          <CompanyLogo src={profilePicture} />
          <div className="flex flex-col">
            <Text
              text={displayName}
              className={CLASS_NAMES.title}
              isLoading={isLoading}
            />
            <Text
              text="Your company"
              className={CLASS_NAMES.companyName}
              isLoading={isLoading}
            />
            <div className="hidden md:block">
              <Text
                text={subscriptionStatusLabel}
                className={CLASS_NAMES.subscriptionStatus}
                isLoading={isLoading}
              />
              {!isLoading && !isSubscribed && (
                <Link to={URLS.subscription}>
                  <Button.Text
                    id="account-settings-company-header-upgrade"
                    label="Upgrade Now"
                    icon="arrow-right"
                    variant="gradient"
                    iconProps={{
                      className: 'h-3',
                    }}
                    size={40}
                    className={CLASS_NAMES.upgradeButton}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={CLASS_NAMES.banner}>
          <CompanyBanner />
        </div>
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyHeader-view';

export default ViewComponent;
