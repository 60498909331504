export const TITLE = 'Line items';

export const SKELETON_ITEMS = [
  {
    id: 'temp-1',
  },
  {
    id: 'temp-2',
  },
];
