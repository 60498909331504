import { Footer } from './components';

import Page from '../../Page';
import { SendConfirmation } from '../../../../components';

const ViewComponent = () => (
  <Page>
    <div className="w-full h-full max-h-180 max-w-256 m-auto relative">
      <SendConfirmation.Body />
      <Footer />
    </div>
  </Page>
);

ViewComponent.displayName = 'CreateJob-Desktop-SendConfirmation-view';

export default ViewComponent;
