import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

export const SUBSCRIBE_MODAL_ID = 'subscribe-modal';

export default function useSubscribeModal() {
  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => {
    showModal(SUBSCRIBE_MODAL_ID);
  }, [showModal]);

  return onClick;
}
