import { VIDEO_PROPS } from './constants';

const VideoEmbed: React.FunctionComponent = function () {
  return (
    <>
      <p className="text-24-28-em md:text-40-48-em my-6 md:my-16">
        Join 1000+ tradespeople on Kanda
      </p>
      <div className="flex w-full md:px-12 mb-8">
        <iframe title="Kanda Onboarding Demo Video" {...VIDEO_PROPS} />
      </div>
    </>
  );
};

export default VideoEmbed;
