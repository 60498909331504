import Button from './Button';

export interface BucketPopoverProps {
  bucket: string;
}

const ViewComponent = (props: BucketPopoverProps) => (
  <div className="relative h-10 w-6 my-auto">
    <Button {...props} />
  </div>
);

ViewComponent.displayName = 'NotificationsModal-Bucket-Popover-view';

export default ViewComponent;
