import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect, useState } from 'react';

import usePollBilling from '../../../../../hooks/usePollBilling';
import { ONBOARDING_STEPS } from '../../../Onboarding-constants';

import { URLS } from '../../../../../config';

export default function useSubscriptionStatus() {
  const { setStep } = useOnboardingContextValue();

  const { logEvent } = useAmplitude();

  const [showForm, setShowForm] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [pending, setPending] = useState(false);

  const { billing, isLoading } = usePollBilling();

  const onRetryClick = () => {
    setShowForm(true);
    setSuccess(false);
    setError(false);
    setPending(false);
  };

  const onSuccessClick = () => {
    if (setStep) setStep(ONBOARDING_STEPS.WELCOME);
  };

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'subscription');
    if (isLoading) {
      window.history.pushState({}, '', url);
      return;
    }

    if (billing === 'subscribed') {
      setSuccess(true);
      setShowForm(false);
      setError(false);
      setPending(false);
      window.history.pushState({}, '', `${url}?step=subscribe&status=success`);
      logEvent('page-view');
      return;
    }
    if (billing === 'pending') {
      setPending(true);
      setShowForm(false);
      setSuccess(false);
      setError(false);
      window.history.pushState({}, '', `${url}?step=subscribe&status=pending`);
      logEvent('page-view');
      return;
    }
    if (['suspended', 'cancelled'].includes(billing as string)) {
      setError(true);
      setShowForm(false);
      setSuccess(false);
      setPending(false);
      window.history.pushState({}, '', `${url}?step=subscribe&status=failure`);
      logEvent('page-view');
      return;
    }
    setShowForm(true);
    setSuccess(false);
    setError(false);
    setPending(false);
    window.history.pushState({}, '', `${url}?step=subscribe`);
    logEvent('page-view');
  }, [isLoading, billing, logEvent]);

  return {
    showForm,
    success,
    error,
    pending,
    onRetryClick,
    onSuccessClick,
  };
}
