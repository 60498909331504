import { useState } from 'react';

const ContainerComponent = ({ children }) => {
  const [focus, setFocus] = useState(0);

  return children({
    focus,
    setFocus,
  });
};
ContainerComponent.displayName = 'Createjob-Quote-Kanda-Provider-container';

export default ContainerComponent;
