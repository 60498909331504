import { useCallback } from 'react';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';

const ContainerComponent = ({ children, handleClose }) => {
  const { mutate: sendEmailVerification, isSubmitting } = useMutate(
    FirebaseAuthService.sendEmailVerification,
  );

  const { showError, showSuccess } = useToast();

  const emailClick = useCallback(
    () =>
      sendEmailVerification().then(({ error }) => {
        if (error) {
          showError(
            'Cannot resend verification email at this time - please try again later',
          );
          handleClose();
          return;
        }
        showSuccess('Verificaiton email sent to your email address');
        handleClose();
      }),
    [showSuccess, showError, sendEmailVerification, handleClose],
  );

  return children({
    isSubmitting,
    emailClick,
  });
};

ContainerComponent.displayName =
  'MyAccount-AccountSettings-VerifyEmailModal-container';

export default ContainerComponent;
