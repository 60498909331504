import { URLS } from 'config';
import EnterpriseRatesImage from './assets/enterprise-rates.png';
import MarketingPackageImage from './assets/marketing-package.png';

export interface UpSell {
  id: string;
  pos: string;
  title: string;
  subtitle: string;
  points: string[];
  button: {
    link: string;
    label: string;
    comment: string;
  };
  imageSrc: string;
}

export const PLACEHOLDER_UPSELL: UpSell = {
  id: 'placeholder-upsell',
  pos: 'Placeholder',
  title: 'Placeholder',
  subtitle: 'Placeholder',
  points: ['Placeholder', 'Placeholder'],
  button: {
    link: URLS.home,
    label: 'Placeholder',
    comment: 'Placeholder',
  },
  imageSrc: EnterpriseRatesImage,
};

export const RATES_UPSELL: UpSell = {
  id: 'enterprise-rates',
  pos: 'Discounted Kanda Rates',
  title: 'Save money by upgrading to enterprise',
  subtitle:
    'Doing over £100,000 a year in finance? Upgrade to our enterprise package and save £1000s',
  points: [
    'Up to 3% off core subsidy fees',
    'One upfront fee to earn discounted rates for 12 months',
  ],
  button: {
    link: URLS.enterpriseRates,
    label: 'Buy Now',
    comment: 'For ambitious businesses',
  },
  imageSrc: EnterpriseRatesImage,
};

export const MARKETING_UPSELL: UpSell = {
  id: 'marketing-package',
  pos: 'Kanda Marketing Package',
  title: 'Purchase a set of custom adverts for £250',
  subtitle:
    'Start your financing journey off right by purchasing a set of 10 custom adverts announcing that you now offer finance',
  points: [
    'Just £25/advert made by our in house pros',
    'Perfectly designed for social media and websites',
  ],
  button: {
    link: URLS.marketingPackage,
    label: 'Buy Now',
    comment: 'Our highest converting marketing yet',
  },
  imageSrc: MarketingPackageImage,
};
