import { Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { BUTTON_PROPS } from './Button-constants';

import { URLS } from '../../../../../../../config';

const ViewComponent = () => (
  <Link to={URLS.createJob}>
    <Button.Text {...BUTTON_PROPS} />
  </Link>
);

ViewComponent.displayName = 'JobsPlaceholder-Button-view';

export default ViewComponent;
