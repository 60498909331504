import { useMemo, useEffect } from 'react';
import { useForm } from '@kanda-libs/ks-component-ts';
import type { Job } from '@kanda-libs/ks-frontend-services';
import type { UseFormReturn } from 'react-hook-form';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { CreateJobContextValue } from 'pages/CreateJob/types';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';

export interface ContainerComponentChildrenArgs {
  form: UseFormReturn;
  onSubmit: (data: CreateJobContextValue) => void;
  prevButtonProps: StringIndexedObject;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    data,
    handleContinue,
    prevStep,
    currentStepIndex,
    setCurrentStepIndex,
  } = useCreateJobContextValue();

  const skipDeposit = data?.company?.skip_deposit === 'Yes';

  const defaultValues = useMemo(() => {
    if (!data?.job) return null;
    return {
      job: data.job,
    };
  }, [data]);

  const form = useForm({
    mode: 'onBlur',
  });

  const { reset } = form;

  const onSubmit = (submittedData: CreateJobContextValue) => {
    handleContinue({
      ...data,
      job: {
        ...(data.job as Job),
        finance_options: submittedData.job?.finance_options || [],
        checkout_options: submittedData.job?.checkout_options || [],
      },
    });
  };

  const handleBack = () => {
    if (skipDeposit) {
      prevStep();
      return;
    }
    if (setCurrentStepIndex) {
      setCurrentStepIndex((currentStepIndex as number) - 2);
    }
  };

  const prevButtonProps = {
    onClick: handleBack,
  };

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    form,
    onSubmit,
    prevButtonProps,
  });
};

ContainerComponent.displayName = 'CreateJob-PaymentAndFinanceOptions-container';

export default ContainerComponent;
