import { useReferralSignUpContext } from './context';
import Pages from './pages';

const ReferralSignUpContent: React.FunctionComponent = function () {
  const { page } = useReferralSignUpContext();
  return (
    <>
      {page === 'holding' && null}
      {page === 'kanda' && <Pages.Kanda />}
      {page === 'partner' && <Pages.Partner />}
      {page === 'signup' && <Pages.SignUp />}
      {page === 'success' && <Pages.Success />}
    </>
  );
};

export default ReferralSignUpContent;
