import { useMemo } from 'react';

import { useCurrentCompany } from 'hooks';

import { getIFrameUrl } from './helpers';

export interface IFrameHook {
  url: string;
}

export default function useIFrame(): IFrameHook {
  const { company } = useCurrentCompany();

  const url = useMemo(() => getIFrameUrl(company), [company]);

  return {
    url,
  };
}
