import { Price, Text } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from '../../Desktop/Desktop-constants';
import useDepositOptions from '../useDepositOptions';

const Desktop = ({}) => {
  const {
    skipDeposit,
    noDeposit,
    fixedDeposit,
    partialDeposit,
    depositValue,
    depositPercentage,
  } = useDepositOptions();

  if (!skipDeposit) {
    return <></>;
  }

  return (
    <div className={CLASS_NAMES.card}>
      <div className={CLASS_NAMES.panel}>
        <div className="flex flex-col gap-y-1">
          <Text text="Deposit" className="text-14-22-em text-neutral-600" />
          {noDeposit && (
            <Text
              text="No deposit set"
              className="text-12-18-em text-neutral-500"
            />
          )}
          {(fixedDeposit || partialDeposit) && (
            <div className="flex items-center gap-x-1">
              <Text
                text="Customer deposit"
                className="text-12-18-em text-neutral-500"
              />
              <Price
                amount={depositValue}
                poundsClassName="text-12-18-em text-lavender-200"
                centsClassName="text-12-18-em text-lavender-200"
              />
              {partialDeposit && (
                <Text
                  text={` / ${depositPercentage}%`}
                  className="text-12-18-em text-lavender-200"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Desktop.displayName = 'PaymentMethods-Desktop-DepositOptions-view';

export default Desktop;
