import { Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';
import useFooterProps from './useFooterProps';

import { BUTTON_PROPS, CLASS_NAMES } from './Footer-constants';

import { URLS } from '../../../../../../../../config';

const ViewComponent = () => {
  const { isExampleJob } = useFooterProps();

  return (
    <div className={CLASS_NAMES.footer}>
      {!isExampleJob && (
        <Link to={URLS.home}>
          <Button.Text
            {...BUTTON_PROPS}
            variant="solid"
            label={BUTTON_PROPS.label}
          />
        </Link>
      )}
    </div>
  );
};

ViewComponent.displayName = 'CreateJob-Desktop-SendConfirmation-Footer-view';

export default ViewComponent;
