import Container from './Menu-container';
import { Card } from './components';

const ViewComponent = () => (
  <Container>
    {({ items }) =>
      items.map(({ key, ...rest }) => <Card key={key} {...rest} />)
    }
  </Container>
);

ViewComponent.displayName = 'MyAccount-AccountSettings-MobileMenu-view';

export default ViewComponent;
