import { Price, Text } from '@kanda-libs/ks-design-library';

import {
  CLASS_NAMES,
  SKELETONS,
  CUSTOMER_SEES_LABEL,
  FROM_LABEL,
  MONTHS_LABEL,
} from '../../FinanceOptionNarrow-constants';

interface ViewComponentProps {
  /**
   * Monthly loan label
   */
  monthlyLoan?: number;
  /**
   * Loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({ isLoading, monthlyLoan }: ViewComponentProps) => (
  <div className={CLASS_NAMES.description}>
    <Text
      skeletonProps={SKELETONS.customerSees}
      text={CUSTOMER_SEES_LABEL}
      className={CLASS_NAMES.customerSees}
      isLoading={isLoading}
    />
    <Text
      skeletonProps={SKELETONS.from}
      className={CLASS_NAMES.from}
      text={FROM_LABEL}
      isLoading={isLoading}
    />
    <Price
      amount={monthlyLoan}
      {...CLASS_NAMES.fromPrice}
      isLoading={isLoading}
    />
    <Text
      className={CLASS_NAMES.from}
      text={MONTHS_LABEL}
      isLoading={isLoading}
    />
  </div>
);

ViewComponent.defaultProps = {
  monthlyLoan: undefined,
  isLoading: false,
};

ViewComponent.displayName = 'FinanceOptions-Option-Description-view';

export default ViewComponent;
