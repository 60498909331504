import { Button } from '@kanda-libs/ks-design-library';
import useScrollBottomButton from './useScrollBottom';

const ScrollBottomButton: React.FunctionComponent = function () {
  const { visible, onClick } = useScrollBottomButton();
  return visible ? (
    <div className="flex md:hidden sticky bottom-4 right-1 w-min ml-auto h-0 overflow-visible">
      <Button.Text
        id="scroll-bottom-contract"
        icon="arrow-down"
        variant="solid"
        label="Scroll bottom"
        onClick={onClick}
        className="-mt-16"
      />
    </div>
  ) : null;
};

export default ScrollBottomButton;
