import { Field } from '@kanda-libs/ks-component-ts';
import useReferralsInviteFormFieldPhone from './useReferralsInviteFormFieldPhone';

export interface ReferralsInviteFormFieldPhoneProps {
  index: number;
}

const ReferralsInviteFormFieldPhone: React.FunctionComponent<ReferralsInviteFormFieldPhoneProps> =
  function ({ index }) {
    const validation = useReferralsInviteFormFieldPhone();
    return (
      <Field.Array.Input name="mobile" index={index}>
        <Field.Validator validation={validation}>
          <Field.PhoneNumberInput
            label="Phone Number"
            placeholder="Phone number"
          />
        </Field.Validator>
      </Field.Array.Input>
    );
  };

export default ReferralsInviteFormFieldPhone;
