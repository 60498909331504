import { MultiStepForm } from '@kanda-libs/ks-component-ts';
import { LoadingSpinner } from '@kanda-libs/ks-design-library';
import {
  INITIAL_DATA,
  STEPS,
} from './AccountVerificationExplanation-constants';
import Layout from './components/Layout';
import useAccountVerificationExplanation from './useAccountVerificationExplanation';

const AccountVerificationExplanation = ({}) => {
  const { isLoading, initialStepIndex } = useAccountVerificationExplanation();

  if (isLoading) {
    return (
      <LoadingSpinner
        stroke="green-400"
        className="m-auto animate-spin"
        duration={0}
      />
    );
  }

  return (
    <MultiStepForm
      steps={STEPS}
      addStepsToUrl={false}
      initialStepIndex={initialStepIndex}
      onSubmit={() => {}}
      initialData={INITIAL_DATA}
    >
      <Layout />
    </MultiStepForm>
  );
};

export default AccountVerificationExplanation;
