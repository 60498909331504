import { ADD_A_JOB_STEPS } from '../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const steps = Object.values(ADD_A_JOB_STEPS);

  return children({ steps });
};

ContainerComponent.displayName = 'CreateJob-Mobile-container';

export default ContainerComponent;
