import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Landing-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../../components';

const ViewComponent = () => (
  <Container>
    {({ ...restProps }) => (
      <>
        <Helmet title="Verify Email" />
        <BreakPoints
          mobile={<Mobile {...restProps} />}
          desktop={<Desktop {...restProps} />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'VerifyEmail-Landing-view';

export default ViewComponent;
