import type { MutableRefObject } from 'react';
import { Text } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';
import Permissions from '../Permissions';

interface ViewComponentProps {
  /**
   *
   */
  permissions?: boolean[];
  /**
   *
   */
  role?: string;
  /**
   *
   */
  modalId: string;
}

const ViewComponent = ({ permissions, role, modalId }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <Text text="Permissions" className="mb-8 text-18-22-em" />
        <div className="px-4 py-5 mb-6 rounded-lg bg-neutral-100">
          <span className="text-16-20-em text-neutral-600">{role}</span>
        </div>
        <Permissions permissions={permissions} />
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'New-Role-Popover-Modal-view';

ViewComponent.defaultProps = {
  permissions: [false, false, false, false, false],
  role: 'Member',
};

export default ViewComponent;
