import clsx from 'clsx';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { LOGO_CLASS_NAME } from './Layout-constants';

const ContainerComponent = ({ children }) => {
  const { currentStepIndex, prevStep } = useOnboardingContextValue();

  const logoProps = {
    className: clsx(
      LOGO_CLASS_NAME,
      currentStepIndex === 0 ? 'opacity-0' : 'opacity-100',
    ),
  };

  return children({
    prevStep,
    logoProps,
    step: currentStepIndex,
  });
};

ContainerComponent.displayName = 'Layout-container';

export default ContainerComponent;
