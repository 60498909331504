import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <BreakPoints
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

ViewComponent.displayName = 'CreateJob-Item-view';

export default ViewComponent;

// }
