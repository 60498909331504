import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import { useEffect, type FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

export interface HummWrapperProps {
  children: JSX.Element;
}

const HummWrapper: FunctionComponent<HummWrapperProps> = function ({
  children,
}) {
  const { company } = useCurrentCompany();
  const { push } = useHistory();

  useEffect(() => {
    if (!company) return;
    if (!['completed_setup', 'verified'].includes(company?.lifecycle || ''))
      return;
    if (company?.lifecycle === 'completed_setup') {
      const lender = company?.business_config?.lender;
      if (!lender || lender === 'propensio') return;
      push(URLS.accountInReview);
    }
  }, [company, push]);

  return children;
};

export default HummWrapper;
