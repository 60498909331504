import { useEffect } from 'react';
import clsx from 'clsx';
import { useWatch } from '@kanda-libs/ks-component-ts';

const ContainerComponent = ({ children, depositType }) => {
  const selectedType = useWatch({ name: 'job.deposit_type' });

  const selected = selectedType && selectedType === depositType;

  const classNames = {
    container: clsx(
      'flex flex-col md:flex-row p-4 border mb-4 last:mb-0 rounded-lg',
      selected ? 'border-green-300' : 'border-neutral-300',
    ),
    text: clsx(
      'text-14-22 ml-4 whitespace-nowrap',
      selected ? 'text-neutral-800' : 'text-neutral-600',
    ),
  };

  useEffect(() => {
    const element = document.getElementById(depositType) as HTMLInputElement;
    if (element) {
      if (!selected) {
        element.checked = false;
        return;
      }
      element.checked = true;
    }
  }, [depositType, selected]);

  return children({
    classNames,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Deposit-Fields-Wrapper-container';

export default ContainerComponent;
