export const TITLE = 'Change your password';

export const DESCRIPTION =
  "You'll need to confirm your current password to set a new password";

export const FIELD_NAMES = {
  currentPassword: 'currentPassword',
  password: 'password',
  confirmPassword: 'confirmPassword',
};

const CURRENT_PASSWORD = {
  currentPassword: {
    name: FIELD_NAMES.currentPassword,
    label: 'Current Password',
    placeholder: 'Your current password',
    autoComplete: 'current-password',
  },
};

const PASSWORD = {
  password: {
    name: FIELD_NAMES.password,
    label: 'New Password',
    placeholder: 'Your new password',
    autoComplete: 'new-password',
  },
};

const CONFIRM_PASSWORD = {
  confirmPassword: {
    name: FIELD_NAMES.confirmPassword,
    label: 'Confirm New Password',
    placeholder: 'Confirm your new password',
    autoComplete: 'new-password',
  },
};

export const WRAP_FIELDS = {
  ...CURRENT_PASSWORD,
  ...PASSWORD,
  ...CONFIRM_PASSWORD,
};
