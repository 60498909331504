import {
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { Content, Footer } from '../components';

import { URLS } from '../../../../config';

interface ViewComponentProps {
  /**
   * Disabled
   */
  disabled?: boolean;
}

const ViewComponent = ({ disabled }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <div className="absolute top-6 left-6">
        <Link to={URLS.login}>
          <Button.Icon id="forgot-password-desktop-back" icon="arrow-left" />
        </Link>
      </div>
      <Content />
      <Footer disabled={disabled} />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.defaultProps = {
  disabled: undefined,
};

ViewComponent.displayName = 'ForgotPassword-Request-Desktop-view';

export default ViewComponent;
