import { useCallback, useContext, useEffect, useState } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import type { JobTableFilterPopoverItemType } from '../../JobTableFilterPopover/JobTableFilterPopoverItem/types';

export interface JobTableFilterModalHook {
  filter?: JobTableFilterPopoverItemType;
  onSelectFilter: (type?: JobTableFilterPopoverItemType) => void;
}

export default function useJobTableFilterModal(): JobTableFilterModalHook {
  const { visibleModals } = useContext(ModalsWrapperContext);
  const [filter, setFilter] = useState<
    JobTableFilterPopoverItemType | undefined
  >();

  useEffect(() => {
    setFilter(undefined);
  }, [visibleModals]);

  const onSelectFilter = useCallback(
    (type?: JobTableFilterPopoverItemType) => {
      setFilter(type);
    },
    [setFilter],
  );

  return {
    filter,
    onSelectFilter,
  };
}
