import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import {
  Title,
  Description,
  Quantity,
  Price,
  Vat,
  Total,
} from '../../../components';

interface ViewComponentProps {
  /**
   * Is loading
   */
  smaller?: boolean;
  /**
   * Variant
   */
  classNames?: StringIndexedObject;
}

const ViewComponent = ({ classNames = {}, smaller }: ViewComponentProps) => (
  <div className={classNames.mobile.container}>
    <div className="flex flex-row w-full item-center justify-between">
      {smaller && <Quantity />}
      <Title />
      {smaller && <Total />}
    </div>
    <Description />
    {!smaller && (
      <div className={classNames.mobile.values}>
        <Quantity />
        <Price />
        <Vat />
        <Total />
      </div>
    )}
  </div>
);

ViewComponent.displayName = 'LineItem-Mobile-Kanda-view';

ViewComponent.defaultProps = {
  smaller: false,
  classNames: {},
};

export default ViewComponent;
