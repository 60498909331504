import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { URLS } from 'config';
import { useDownloadPdf } from 'hooks';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { JOB_MENU_MODAL_ID } from './Header-constants';

import useCurrentJob from '../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { showModal } = useContext(ModalsWrapperContext);
  const { downloadPdf, isSubmitting } = useDownloadPdf();

  const { job } = useCurrentJob();

  const jobId = job?.id;
  const status = job?.status;

  /**
   * Opens slide up
   * @param {Event} event
   */
  const openSlideUp = (event) => {
    event.stopPropagation();
    showModal(JOB_MENU_MODAL_ID);
  };

  const { length, goBack, push } = useHistory();

  const handleClick = useCallback(() => {
    if (length === 0) {
      push(URLS.home);
      return;
    }
    const previousURL = document.referrer;
    if (previousURL.includes('dashboard')) {
      goBack();
      return;
    }
    push(URLS.home);
  }, [length, goBack, push]);

  return children({
    openSlideUp,
    jobId,
    status,
    handleClick,
    downloadPdf,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'Header-container';

export default ContainerComponent;
