import { useMemo } from 'react';
import useCurrentCompany from './useCurrentCompany';
import {
  BILLING_CANCELLED,
  BILLING_SUSPENDED,
  BILLING_TRIAL,
} from '../constants/subscription';

/**
 * Returns a boolean as to whether the current user has a Kanda subscription
 * @returns { isSubscribed: boolean, isLoading: boolean }
 */
export default function useIsSubscribed() {
  const { company, isLoading: companyIsLoading } = useCurrentCompany();
  const billing = company?.billing;

  /**
   * @type {boolean}
   */
  const isSubscribed = useMemo(() => {
    if (!billing) return false;

    return (
      billing &&
      [BILLING_TRIAL, BILLING_SUSPENDED, BILLING_CANCELLED].indexOf(billing) ===
        -1
    );
  }, [billing]);

  /**
   * @type {boolean}
   */
  const isLoading = companyIsLoading || !company;

  return { isSubscribed, isLoading };
}
