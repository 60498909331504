import { Text, Icon } from '@kanda-libs/ks-design-library';

import Container from './Card-container';

interface ViewComponentProps {
  /**
   * Type of component to render
   */
  type?: 'button' | 'link';
  /**
   * Type of component to render
   */
  title?: string;
  /**
   * Type of component to render
   */
  value?: string;
  /**
   * Type of component to render
   */
  hidden?: boolean;
  /**
   * Type of component to render
   */
  disabled?: boolean;
}

const ViewComponent = ({
  type,
  title,
  value,
  hidden,
  disabled,
  ...rest
}: ViewComponentProps) => (
  <Container type={type}>
    {({ Tag }) =>
      !hidden && (
        <Tag
          {...rest}
          disabled={disabled}
          className="flex flex-row justify-between mb-6"
        >
          <div className="flex flex-col">
            <Text
              text={title}
              className="text-16-20-em text-neutral-700 mb-0.5 text-left"
            />
            <Text text={value} className="text-14-22 text-neutral-600" />
          </div>
          {!disabled && (
            <Icon icon="chevron-right" size={20} className="ml-3 my-auto" />
          )}
        </Tag>
      )
    }
  </Container>
);

ViewComponent.displayName = 'MyAccount-AccountSettings-MobileMenu-Card-view';

ViewComponent.defaultProps = {
  type: 'link',
  title: '',
  value: '',
  hidden: false,
  disabled: false,
};

export default ViewComponent;
