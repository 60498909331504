import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BASE_CONFIGS } from '../constants';
import { getTradeTypeFromTradeText } from '../helpers';

export default function useFilterableSelect() {
  const { setValue } = useFormContext();

  const [tradeText] = useWatch({
    name: ['company.business_config.trade_text'],
  });

  useEffect(() => {
    if (!tradeText) return;
    const matchedTradeType = getTradeTypeFromTradeText(BASE_CONFIGS, tradeText);
    if (!matchedTradeType) return;
    setValue(
      'company.business_config.business_sector',
      matchedTradeType.business_sector,
    );
    setTimeout(() => {
      setValue(
        'company.business_config.trade_text',
        matchedTradeType.trade_text,
      );
    }, 10);
  }, [tradeText, setValue]);
}
