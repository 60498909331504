import { Widget } from '@kanda-libs/ks-component-ts';

import DirectorCard from './DirectorCard';

const ViewComponent = ({ ...props }) => (
  <Widget.CompanyUsersArrayWrapper>
    {({ arrayName, fields }) =>
      fields.map((field, index) => (
        <DirectorCard
          key={field.id}
          arrayName={arrayName}
          {...field}
          index={typeof index !== 'undefined' ? index : field.index}
          {...props}
        />
      ))
    }
  </Widget.CompanyUsersArrayWrapper>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-Form-Limited-view';

export default ViewComponent;
