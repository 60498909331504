import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { scrollToTop } from 'utils';
import { REFERRAL_DISCOUNT } from '../../constants';
import { useSubscriptionContext } from '../../context';
import {
  HUMM_MONTHLY_CARD,
  HUMM_YEARLY_CARD,
  STANDARD_MONTHLY_CARD,
  STANDARD_YEARLY_CARD,
} from './constants';
import type { SubscriptionCardProps } from './SubscriptionCard';

export interface SubscriptionsHook {
  subscriptions: SubscriptionCardProps[];
  isSubmitting: boolean;
  isLoading: boolean;
}

export default function useSubscriptions(): SubscriptionsHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { showError } = useToast();
  const onError = useApiError('There was an error creating the subscription');
  const companies = useSelector(selectors.company.getEntitiesAsArray);
  const { setFormDisplay, isLoading, referredByUser } =
    useSubscriptionContext();

  const company = useMemo(() => {
    if (!companies || companies?.length === 0) return null;
    return companies[0];
  }, [companies]);

  const onMonthlyClick = useCallback(() => {
    const id = company?.id;
    if (!id) {
      showError('Error creating subscription - company id missing');
      return;
    }
    scrollToTop();
    setFormDisplay('form');
  }, [company, showError, setFormDisplay]);

  const onYearlyClick = useCallback(() => {
    const id = company?.id;
    if (!id) {
      showError('Error creating subscription - company id missing');
      return;
    }
    setIsSubmitting(true);
    dispatch(
      actions.postCompanyBilling({
        params: {
          id,
        },
        onError: (error) => {
          setIsSubmitting(false);
          onError(error);
        },
        onSuccess: (data) => {
          setIsSubmitting(false);
          const url = data?.checkout_url;
          if (!url) {
            showError(
              'Authorisation URL missing from response - please contact Kanda',
            );
            return;
          }
          window.location.href = url;
        },
      }),
    );
  }, [company, dispatch, onError, showError]);

  const subscriptions = useMemo(() => {
    if (!company)
      return [
        {
          ...STANDARD_YEARLY_CARD,
          subscription: {
            ...STANDARD_YEARLY_CARD.subscription,
            ...(referredByUser
              ? {
                  amount:
                    STANDARD_YEARLY_CARD.subscription.amount -
                    REFERRAL_DISCOUNT,
                }
              : {}),
          },
          buttonProps: {
            ...STANDARD_YEARLY_CARD.buttonProps,
            onClick: onYearlyClick,
            disabled: isSubmitting,
          },
        },
        {
          ...STANDARD_MONTHLY_CARD,
          buttonProps: {
            ...STANDARD_MONTHLY_CARD.buttonProps,
            onClick: onMonthlyClick,
            disabled: isSubmitting,
          },
        },
      ];
    const hummUnreg =
      company?.business_config?.lender === 'humm' &&
      company?.business_config?.lender_rate_type === 'unregulated';
    if (hummUnreg)
      return [
        {
          ...HUMM_YEARLY_CARD,
          subscription: {
            ...HUMM_YEARLY_CARD.subscription,
            ...(referredByUser
              ? {
                  amount:
                    HUMM_YEARLY_CARD.subscription.amount - REFERRAL_DISCOUNT,
                }
              : {}),
          },
          buttonProps: {
            ...HUMM_YEARLY_CARD.buttonProps,
            onClick: onYearlyClick,
            disabled: isSubmitting,
          },
        },
        {
          ...HUMM_MONTHLY_CARD,
          buttonProps: {
            ...HUMM_MONTHLY_CARD.buttonProps,
            onClick: onMonthlyClick,
            disabled: isSubmitting,
          },
        },
      ];
    return [
      {
        ...STANDARD_YEARLY_CARD,
        subscription: {
          ...STANDARD_YEARLY_CARD.subscription,
          ...(referredByUser
            ? {
                amount:
                  STANDARD_YEARLY_CARD.subscription.amount - REFERRAL_DISCOUNT,
              }
            : {}),
        },
        buttonProps: {
          ...STANDARD_YEARLY_CARD.buttonProps,
          onClick: onYearlyClick,
          disabled: isSubmitting,
        },
      },
      {
        ...STANDARD_MONTHLY_CARD,
        buttonProps: {
          ...STANDARD_MONTHLY_CARD.buttonProps,
          onClick: onMonthlyClick,
          disabled: isSubmitting,
        },
      },
    ];
  }, [company, isSubmitting, onMonthlyClick, onYearlyClick, referredByUser]);

  return {
    subscriptions,
    isSubmitting,
    isLoading,
  };
}
