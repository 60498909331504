import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './LineItems-container';
import { CLASS_NAMES, DESCRIPTION } from './LineItems-constants';
import { Totals } from './components';
import Wrapper from '../Wrapper';

import { LineItem } from '../../../../../../../components';

const ViewComponent = () => (
  <Container>
    {({ lineItems, onClick }) => (
      <div className={CLASS_NAMES.container}>
        <Wrapper onEdit={onClick} description={DESCRIPTION}>
          <div className={CLASS_NAMES.content}>
            <BreakPoints
              mobile={
                <div className={CLASS_NAMES.titleWrapper}>
                  <p className={CLASS_NAMES.heading}>{DESCRIPTION}</p>
                </div>
              }
            />
            {lineItems
              ? lineItems.map((item) => (
                  <LineItem
                    key={item.title}
                    item={item}
                    variant="job-details"
                  />
                ))
              : null}
            <Totals />
          </div>
        </Wrapper>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-LineItems-view';

export default ViewComponent;
