import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './constants';
import { renderStep } from './helpers';

interface MobileProps {
  steps: StringIndexedObject[];
  completedSteps: StringIndexedObject[];
  isLoading: boolean;
  activeStep: number;
  nextStepToComplete: StringIndexedObject;
  stepsCompleted: StringIndexedObject;
  setStep?: (step: StringIndexedObject) => void;
  isPremiumCredit?: boolean;
}

const Mobile = ({
  steps,
  isLoading,
  activeStep,
  stepsCompleted,
  setStep,
  nextStepToComplete,
  completedSteps,
  isPremiumCredit,
}: MobileProps) => (
  <>
    {/* Show the next step to complete at the top without a number */}
    {nextStepToComplete && (
      <>
        <Text
          text="Next steps:"
          className={CLASS_NAMES.nextStep}
          isLoading={isLoading}
          skeletonProps={{ width: '400px' }}
        />
        {renderStep(
          nextStepToComplete,
          isLoading,
          true,
          stepsCompleted,
          null,
          setStep,
        )}
        <hr />
      </>
    )}
    {/* Show all steps that are not completed or up next */}
    {steps.map((step, index) => {
      const stepIsNextStepToComplete = step.key === nextStepToComplete?.key;
      const stepIsComplete = stepsCompleted[step.key];

      return !stepIsNextStepToComplete && !stepIsComplete
        ? renderStep(
            step,
            isLoading,
            !isLoading && activeStep === index,
            stepsCompleted,
            index + 1,
            setStep,
          )
        : null;
    })}
    <Text
      text="Completed steps:"
      className={CLASS_NAMES.nextStep}
      isLoading={isLoading}
      skeletonProps={{ width: '400px' }}
    />
    {/* Show the completed steps at the bottom */}
    {completedSteps.map((step, index) => {
      const stepIsComplete = stepsCompleted[step.key];

      return stepIsComplete
        ? renderStep(
            step,
            isLoading,
            !isLoading && activeStep === index,
            stepsCompleted,
            index + 1,
            setStep,
            isPremiumCredit,
          )
        : null;
    })}
  </>
);

export default Mobile;
