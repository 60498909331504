import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { TrustPilotEmbed } from 'components';
import RatesTable from './Table';
import useRates from './useRates';

const Rates: React.FunctionComponent = function () {
  const { rates, onClick } = useRates();
  return (
    <div className="flex flex-col w-full p-3 md:px-10 md:py-8 border border-neutral-300 rounded-lg md:rounded-xl mt-8 md:mt-16">
      <div className="flex flex-col w-full md:w-120 mx-auto">
        <RatesTable rates={rates} />
        <BreakPoints
          desktop={
            <Button.Text
              id="setup-rates-next"
              className="w-full mt-6"
              label="Apply Now and Pay"
              icon="arrow-right"
              variant="gradient"
              onClick={onClick}
            />
          }
        />
        <TrustPilotEmbed.MicroCombo className="mt-6" />
      </div>
    </div>
  );
};

export default Rates;
