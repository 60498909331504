import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { removeTrailingWhitespace } from 'utils';
import { PHONE_NUMBER_PROPS } from './constants';

export interface SignUpFormFieldPhoneHook {
  onBlur: () => void;
}

export default function useSignUpFormFieldPhone(): SignUpFormFieldPhoneHook {
  const { getValues, setValue } = useFormContext();

  const onBlur = useCallback(
    () =>
      setValue(
        PHONE_NUMBER_PROPS.name,
        removeTrailingWhitespace(getValues(PHONE_NUMBER_PROPS.name)),
      ),
    [getValues, setValue],
  );

  return {
    onBlur,
  };
}
