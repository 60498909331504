import { useMemo, useEffect, useRef } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';

import useTradeBodyNumberValidation from '../../../../../../../../../hooks/useTradeBodyNumberValidation';
import useCurrentCompany from '../../../../../../../../../hooks/useCurrentCompany';

export default function useTradeInfo() {
  const { company } = useCurrentCompany();

  const { setValue } = useFormContext();

  const [tradeType, tradeBody] = useWatch({
    name: [
      'company.company_info.trade_type',
      'company.company_info.trade_body',
    ],
  });

  const tradeNumberValidation = useTradeBodyNumberValidation();

  const isSoleTrader = useMemo(() => {
    if (!company) return false;
    return company?.company_type === 'sole_trader';
  }, [company]);

  const otherTradeType = useMemo(() => {
    if (!tradeType) return false;
    if (tradeType !== 'other_trade') return false;
    return true;
  }, [tradeType]);

  const otherTradeBody = useMemo(() => {
    if (!tradeBody) return false;
    if (tradeBody !== 'other') return false;
    return true;
  }, [tradeBody]);

  const noneTradeBody = useMemo(() => {
    if (!tradeBody) return false;
    if (tradeBody !== 'none') return false;
    return true;
  }, [tradeBody]);

  // Ref holds initial trade type from form
  const typeRef = useRef(tradeType);
  // Effect updates fields as needed when trade body changes
  useEffect(() => {
    if (!tradeType) return;
    if (tradeType && !typeRef.current) {
      typeRef.current = tradeType;
      return;
    }
    if (tradeType === typeRef.current) return;
    typeRef.current = tradeType;
    if (tradeType === 'other_trade') {
      setValue('company.company_info.trade_type_name', '');
      return;
    }
    setValue('company.company_info.trade_type_name', tradeType);
  }, [tradeType, setValue]);

  // Ref holds initial trade body from form
  const bodyRef = useRef(tradeBody);
  // Effect updates fields as needed when trade body changes
  useEffect(() => {
    if (!tradeBody) return;
    if (tradeBody && !bodyRef.current) {
      bodyRef.current = tradeBody;
      return;
    }
    if (tradeBody === bodyRef.current) return;
    bodyRef.current = tradeBody;
    if (tradeBody === 'other') {
      setValue('company.company_info.trade_body_name', '');
      return;
    }
    setValue('company.company_info.trade_body_name', tradeBody);
  }, [tradeBody, setValue]);

  useEffect(() => {
    if (!tradeBody) return;
    if (tradeBody !== 'none') return;
    setValue('company.company_info.trade_body_number', '');
  }, [tradeBody, setValue]);

  return {
    isLoading: !company,
    isSoleTrader,
    tradeNumberValidation,
    otherTradeType,
    otherTradeBody,
    noneTradeBody,
  };
}
