import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useSignUpContext, type Display } from '../context';
import type { FieldValues } from '../types';

export interface SignUpFormHook {
  form: UseFormReturn<FieldValues>;
  onSubmit: (formValues: StringIndexedObject<FieldValues>) => void;
  display: Display;
  showFooter: boolean;
  disabled: boolean;
  isSubmitting: boolean;
}

export default function useSignUpForm(): SignUpFormHook {
  const { signUpWithEmail, display, disabled, isSubmitting, referrerEmail } =
    useSignUpContext();

  const form = useForm<FieldValues>({
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<FieldValues>) =>
      signUpWithEmail(formValues as unknown as FieldValues),
    [signUpWithEmail],
  );

  const showFooter = useMemo(() => display === 'form', [display]);

  useEffect(() => {
    if (!referrerEmail) return;
    form.setValue('email', referrerEmail);
  }, [referrerEmail, form]);

  return {
    form,
    onSubmit,
    display,
    showFooter,
    disabled,
    isSubmitting,
  };
}
