export const TITLE_COMPONENT_PROPS = {
  mobile: {
    label: 'Title',
    disableNewLine: true,
    collapsible: true,
    placeholder: "Add a title, i.e. 'new fuse box'",
    autoSize: true,
    textarea: true,
  },
  desktop: {
    label: 'Title',
    placeholder: "Add a title, i.e. 'new fuse box'",
    autoSize: true,
    textarea: true,
  },
};
