import { useToast } from '@kanda-libs/ks-design-library';
import { actions, useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import useMe from '../../../../../../hooks/useMe';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { user } = useCurrentUser();
  const isSubmitting = useSelector(selectors.infoAuth.getIsSubmitting);

  const { me, isValidating: isLoading } = useMe();

  const { showSuccess } = useToast();
  const onError = useApiError(
    'Cannot resend verification email at this time - please try again later',
  );

  const socialProvider =
    me?.provider === 'google.com' || me?.provider === 'facebook.com';

  const email = me?.email || 'Change your email';

  const password = socialProvider
    ? 'Your account is linked to a social provider'
    : 'Manage and update your password';

  const emailClick = useCallback(
    () =>
      dispatch(
        actions.infoVerify({
          body: {
            email: me?.email || '',
          },
          onError,
          onSuccess: () => {
            showSuccess('Verificaiton email sent to your email address');
          },
        }),
      ),
    [showSuccess, onError, dispatch, me],
  );

  const disableEmail = socialProvider || user?.emailVerified;

  return children({
    isLoading,
    email,
    password,
    socialProvider,
    emailClick,
    isSubmitting,
    disableEmail,
  });
};

ContainerComponent.displayName =
  'MyAccount-AccountSettings-LoginDetails-container';

export default ContainerComponent;
