import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions, type Referral } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parsePhoneNumber } from 'utils';

export interface ReferralFormReferral extends Omit<Referral, 'mobile'> {
  mobile: string;
}

export interface ReferralForm {
  referrals: ReferralFormReferral[];
}

export interface ReferralInviteFormHook {
  form: UseFormReturn<ReferralForm>;
  onSubmit: (values: StringIndexedObject<ReferralForm>) => void;
  isSubmitting: boolean;
}

const formatReferralsBody = (values: ReferralForm): Referral[] =>
  values.referrals.map((referral: Referral) => ({
    ...referral,
    phone: parsePhoneNumber(referral.mobile || ''),
  }));

export default function useReferralInviteForm(): ReferralInviteFormHook {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<ReferralForm>({
    reValidateMode: 'onBlur',
  });
  const { company, isLoading } = useCurrentCompany();
  const dispatch = useAppDispatch();
  const onApiError = useApiError(
    'Unknown error occurred - please contact Kanda',
  );
  const { showSuccess } = useToast();
  const { push } = useHistory();

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<ReferralForm>) => {
      if (isLoading) return;
      const id = company?.id;
      if (!id) return;
      const body = formatReferralsBody(formValues as unknown as ReferralForm);
      setIsSubmitting(true);
      dispatch(
        actions.postCompanyReferrals({
          body,
          params: {
            id,
          },
          onError: (error) => {
            onApiError(error);
            setIsSubmitting(false);
          },
          onSuccess: () => {
            dispatch(
              actions.getCompanyReferrals({
                params: {
                  id,
                },
                forceReload: true,
                onError: (error) => {
                  onApiError(error);
                  setIsSubmitting(false);
                },
                onSuccess: () => {
                  setIsSubmitting(false);
                  showSuccess('Invites sent out');
                  push(URLS.referrals);
                },
              }),
            );
            dispatch(
              actions.getCompanies({
                params: {},
                forceReload: true,
              }),
            );
          },
        }),
      );
    },
    [company, dispatch, isLoading, onApiError, push, showSuccess],
  );

  return { form, onSubmit, isSubmitting };
}
