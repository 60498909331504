import { Header, Button, Loader } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';

import Container from './VerifyEmailModal-container';

import { VERIFY_EMAIL_MODAL_ID } from '../../Mobile-constants';

const ViewComponent = () => (
  <ModalContainer id={VERIFY_EMAIL_MODAL_ID}>
    {({ handleClose }) => (
      <Container handleClose={handleClose}>
        {({ isSubmitting, emailClick }) => (
          <ModalLayoutFullScreen
            noBorder
            footer={
              <Button.Text
                id="account-settings-mobile-send-verification"
                label="Send Verification"
                onClick={emailClick}
                variant="solid"
                disabled={isSubmitting}
                className="ml-auto"
              />
            }
            header={
              <Header.Base
                className="text-style-f-em text-neutral-700"
                options={[
                  <Button.Icon
                    id="account-settings-mobile-verify-email-close"
                    key="close"
                    onClick={handleClose}
                    icon="close"
                  />,
                ]}
              />
            }
          >
            <div className="flex flex-col">
              <p className="mb-4 text-24-28-em">Verify your email</p>
              <p className="mb-8 text-14-22">
                Your email is not yet verified - follow the link sent in the
                email or click the button below to send a new verification email
              </p>
            </div>
            <Loader isLoading={isSubmitting} />
          </ModalLayoutFullScreen>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'AccountSettings-Mobile-VerifyEmailModal-view';

export default ViewComponent;
