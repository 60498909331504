export const MESSAGE = {
  intro: 'Hi ',
  middle:
    "I have sent you a copy of the job on Kanda. You'll receive an email with a link to follow to view the job.",
  end: "If you have any questions, please don't hesitate to get in touch.",
};

export const CLASSNAMES = {
  modal: 'w-11/12 lg:w-1/2',
  heading: 'text-style-e text-neutral-900 mb-6',
  subHeading: 'text-style-h-em text-neutral-600 mb-2',
  warningContainer: 'flex flex-row mb-8',
  warningText: 'text-style-h text-neutral-600 ml-2',
  buttonContainer: 'flex flex-row justify-between',
};

export const TEXT = {
  heading: 'Send SMS',
  subHeading: 'Your message',
  warning: 'Your carrier may charge for SMS messages',
};

export const WARNING_ICON_PROPS = {
  size: 16,
  icon: 'info',
  stroke: 'neutral-500',
  className: 'mt-px',
};
