import { Text } from '@kanda-libs/ks-design-library';
import { type FunctionComponent } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { CLASS_NAMES } from './constants';
import useBreakdownPieChart, {
  type PieChartSegment,
} from './useBreakdownPieChart';

interface NumberCardProps {
  title?: string;
}

const NumberCard: FunctionComponent<NumberCardProps> = function ({ title }) {
  const { segments, isLoading } = useBreakdownPieChart();
  return (
    <div className={CLASS_NAMES.container}>
      {title && (
        <p className="text-14-22 text-neutral-600 mb-3 md:mb-6">{title}</p>
      )}
      <div className="w-full flex flex-row justify-between h-44">
        <div className="w-44">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={172} height={172}>
              <Pie
                dataKey="value"
                data={segments}
                innerRadius={80}
                outerRadius={86}
                endAngle={-360}
              >
                {segments.map((entry: PieChartSegment) => (
                  <Cell
                    key={entry.name}
                    fill={entry.color}
                    opacity={entry?.opacity || 1}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex flex-col h-full justify-between">
          {segments.map((segment: PieChartSegment) => (
            <div key={segment.name} className="flex flex-col min-w-20">
              <Text
                text={segment.name}
                className="text-14-22 text-neutral-600 mb-1 text-left"
                isLoading={isLoading}
              />
              <div className="flex flex-row">
                <div
                  className="w-3 h-3 rounded my-auto mr-2"
                  style={{
                    backgroundColor: segment.color,
                    opacity: segment?.opacity || 1,
                  }}
                />
                <Text
                  text={String(segment.value)}
                  className="text-24-28-em text-neutral-900"
                  isLoading={isLoading}
                  skeletonProps={{
                    width: 40,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NumberCard;
