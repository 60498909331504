import { useContext, useCallback, useMemo } from 'react';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children }) => {
  const { filter, onRemoveFilter } = useContext(Context);

  const statuses = useMemo(
    () => filter?.financeStatus?.split(';').filter((status) => status) || [],
    [filter],
  );

  const onClick = useCallback(
    (status: string) => {
      onRemoveFilter('financeStatus', status);
    },
    [onRemoveFilter],
  );

  return children({ statuses, onClick });
};

ContainerComponent.displayName = 'FinanceStatusFilter-container';

export default ContainerComponent;
