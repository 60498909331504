import { Wrapper } from '../components';
import SplashScreen from '../../../../../../../../components/SplashScreen';

const VerifyingYourIdentity = () => (
  <Wrapper>
    <SplashScreen
      icon="account-color"
      iconSize={52}
      title="Verifying your identity"
      subText="To protect against financial crime we need to know a few details about the owners of your company. Have everyone's DOB & addresses handy."
    />
  </Wrapper>
);

VerifyingYourIdentity.propTypes = {};

export default VerifyingYourIdentity;
