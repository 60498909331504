import { BreakPoints } from '@kanda-libs/ks-design-library';
import Container from './CreateJob-container';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <Container>
    {(props) => (
      <>
        <Helmet title="Create Job" />
        <BreakPoints
          mobile={<Mobile {...props} />}
          desktop={<Desktop {...props} />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-view';

export default ViewComponent;
