import { useMemo } from 'react';
import { capitaliseWords } from 'utils';

export interface ContainerComponentChildrenArgs {
  heading?: string;
  body?: string;
}

export interface ContainerComponentProps {
  bucket?: string;
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children, bucket }: ContainerComponentProps) => {
  const heading = useMemo(() => {
    const pretext = bucket
      ?.split('_')
      ?.map((string) => capitaliseWords(string.toLowerCase()))
      ?.join(' ');
    return `${pretext} Notifications`;
  }, [bucket]);

  // DEV_NOTE: get final copy for this dependent on bucket
  const body =
    'Notifications related to jobs after the finance agreement is in place';

  return children({
    heading,
    body,
  });
};

ContainerComponent.displayName =
  'NotificationsModal-Bucket-Popover-Mobile-Container';

export default ContainerComponent;
