import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useSubscriptionContext, type Contract } from '../../context';
import type { ContractFieldValues } from '../../types';

export interface SubscriptionContractHook {
  contract: Contract | undefined;
  disabled: boolean;
  objectProps?: StringIndexedObject;
}

export default function useSubscriptionContract(): SubscriptionContractHook {
  const { contract } = useSubscriptionContext();
  const [iar, terms] = useWatch<ContractFieldValues>({
    name: ['iar', 'terms_and_conditions'],
  });

  const objectProps = useMemo(() => {
    if (!contract?.content) return undefined;
    return {
      type: 'application/pdf',
      data: `data:application/pdf;base64,${contract.content}`,
      className: 'w-full h-full min-h-180 md:min-h-240 max-h-240',
    };
  }, [contract]);

  const disabled = useMemo(() => !iar || !terms, [iar, terms]);

  return {
    contract,
    disabled,
    objectProps,
  };
}
