import useMobileRates from './useMobileRates';

const ContainerComponent = ({ children }) => {
  const { rates } = useMobileRates();

  return children({
    options: rates?.length === 0 ? null : rates,
  });
};

ContainerComponent.displayName = 'PaymentMethods-Mobile-container';

export default ContainerComponent;
