import Container from './PriceFilter-container';
import { FILTER_TAGS } from './PriceFilter-constants';

import Filter from '../Filter';

const ViewComponent = () => (
  <Container>
    {({ label, onClick }) => (
      <Filter {...FILTER_TAGS} label={label} onClick={onClick} />
    )}
  </Container>
);

ViewComponent.displayName = 'StatusFilter-view';

export default ViewComponent;
