import { useEffect, useRef, useMemo } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { DEPOSIT_PERCENTAGE_NAME } from './Percentage-constants';
import { TYPE_NAME, DEPOSIT_TYPES, VALUE_NAME } from '../../Fields-constants';

import { calculateJobTotal } from '../../../../../../../utils';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  showFields: boolean;
  total: number;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    data: { isLoading = false },
  } = useCreateJobContextValue();

  const { setValue } = useFormContext();

  const [lineItems, depositType, percentage] = useWatch({
    name: ['job.job_items', TYPE_NAME, DEPOSIT_PERCENTAGE_NAME],
  });

  const totals = calculateJobTotal(lineItems);

  const showFields = depositType === DEPOSIT_TYPES.partialDeposit;

  const total = useMemo(() => {
    const deposit = Math.ceil((totals.totalIncVat * percentage || 0) / 100);
    if (deposit <= 0.5 * totals.totalIncVat) return deposit;
    return deposit - 1;
  }, [percentage, totals]);

  useEffect(() => {
    if (depositType !== DEPOSIT_TYPES.partialDeposit) return;
    setValue(VALUE_NAME, total);
  }, [total, depositType, setValue]);

  const initialSet = useRef(false);
  useEffect(() => {
    if (initialSet.current) return;
    initialSet.current = true;
    if (percentage) return;
    setValue(DEPOSIT_PERCENTAGE_NAME, 10);
  }, [depositType, percentage, setValue]);

  useEffect(() => {
    if (depositType === DEPOSIT_TYPES.partialDeposit) return;
    initialSet.current = false;
  }, [depositType]);

  return children({
    isLoading,
    showFields,
    total,
  });
};

ContainerComponent.displayName = 'CreateJob-Desktop-Deposit-Info-container';

export default ContainerComponent;
