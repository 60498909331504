import useCurrentJob from '../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { isLoading, job } = useCurrentJob();

  const financeStatus = job?.finance_status || 'not_applied';

  const showStatus = financeStatus !== 'not_applied';

  return children({
    showStatus,
    isLoading,
  });
};

ContainerComponent.displayName =
  'Job-Desktop-PaymentAndFinanceOptions-container';

export default ContainerComponent;
