import type { PriceProps } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  wrapper:
    'flex flex-row items-center px-4 py-3 -mx-4 border-0 sibling:border-t sibling:border-solid sibling:border-t-1 sibling:border-neutral-200',
  firstItem: 'flex flex-row items-center flex-grow mr-2 cursor-pointer',
  otherItems: 'flex flex-row items-center min-w-24',
  comingSoon: 'flex flex-row flex-1 min-w-48',
  popover: '-ml-2 -mt-1 md:ml-2 -mb-2',
  monthsLabel: 'ml-1 text-12-18-em text-lavender-200',
  durationLabel: 'text-12-18-em text-neutral-700',
};

export const MONTHS_LABEL = '/ MO';

export const MONTHS_PRICE_PROPS: Partial<PriceProps> = {
  color: 'lavender-200',
  poundsClassName: 'text-12-18-em',
  centsClassName: 'text-12-18-em',
};

export const COST_PRICE_PROPS: Partial<PriceProps> = {
  color: 'neutral-600',
  poundsClassName: 'text-12-18-em',
  centsClassName: 'text-12-18-em',
};
