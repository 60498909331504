import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { URLS } from 'config';
import { selectors } from 'store';

import { PAGES, PAGE_KEYS } from './constants';
import type { UpdateYourWebsitePage } from './types';

export interface UpdateYourWebsiteHook {
  page: UpdateYourWebsitePage;
  Content?: FunctionComponent;
}

export default function useUpdateYourWebsite(): UpdateYourWebsiteHook {
  const { id } = useSelector(selectors.getPathKey);
  const { push } = useHistory();

  useEffect(() => {
    if (id && !PAGE_KEYS.includes(id || '')) {
      throw new Error(`Invalid page: ${id}`);
    }

    if (!id) {
      push(URLS.updateYourWebsite.replace(':id', 'start'));
    }
  }, [id, push]);

  const page = id as UpdateYourWebsitePage;

  const Content = PAGES[page];

  return {
    page,
    Content,
  };
}
