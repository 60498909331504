import { useCallback, useEffect, useState } from 'react';

export interface ScrollBottomButtonHook {
  onClick: () => void;
  visible: boolean;
}

export default function useScrollBottomButton(): ScrollBottomButtonHook {
  const [visible, setVisible] = useState<boolean>(true);

  const onClick = useCallback(
    () =>
      setTimeout(
        () =>
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          }),
        1,
      ),
    [],
  );

  const handleToggleVisibility = useCallback(() => {
    const position =
      document.documentElement.scrollTop || document.body.scrollTop;
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    const diff = bodyHeight - windowHeight - position;
    if (diff < 150) {
      setVisible(false);
      return;
    }
    setVisible(true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleToggleVisibility);
    return () => {
      window.removeEventListener('scroll', handleToggleVisibility);
    };
  }, [handleToggleVisibility]);

  return {
    onClick,
    visible,
  };
}
