import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { URLS } from 'config';

export interface ContainerComponentChildrenArgs {
  onClick: () => void;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { length, goBack, push } = useHistory();

  const onClick = useCallback(() => {
    if (length === 0) {
      push(URLS.home);
      return;
    }
    goBack();
  }, [length, goBack, push]);

  return children({ onClick });
};

ContainerComponent.displayName = 'MyAccount-container';

export default ContainerComponent;
