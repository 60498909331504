import { Widget, type ValidationItems } from '@kanda-libs/ks-component-ts';
import { Button, Text } from '@kanda-libs/ks-design-library';

import useTradeInfoForm from './useTradeInfoForm';

const ViewComponent = ({}) => {
  const {
    isLoading,
    isSoleTrader,
    tradeNumberValidation,
    otherTradeType,
    otherTradeBody,
    noneTradeBody,
    showTradeTypeFields,
  } = useTradeInfoForm();

  return (
    <>
      <Text
        text="Business & Insurance"
        className="text-14-22–em text-neutral-800 font-bold mb-2"
      />
      {showTradeTypeFields && (
        <>
          <Widget.CompanyCompanyInfoTradeType />
          {otherTradeType && <Widget.CompanyCompanyInfoTradeTypeName />}
        </>
      )}
      <Widget.CompanyCompanyInfoTradeBody
        validation={{
          required: { value: true, message: 'Trade body is required.' },
        }}
      />
      {otherTradeBody && <Widget.CompanyCompanyInfoTradeBodyName />}
      {!noneTradeBody && (
        <Widget.CompanyCompanyInfoTradeBodyNumber
          validation={tradeNumberValidation as unknown as ValidationItems}
        />
      )}
      {isSoleTrader && (
        <Widget.CompanySoleTraderInfoNationalInsuranceNumber
          validation={{
            required: {
              value: true,
              message: 'National insurance number is required',
            },
          }}
        />
      )}
      <div className="mt-auto md:mt-0">
        <Button.Text
          submit
          id="onboarding-trade-info-next"
          label="Next"
          isLoading={isLoading}
          className="w-full mt-6"
        />
      </div>
    </>
  );
};

ViewComponent.displayName = 'StandardTradeInfoForm-view';

export default ViewComponent;
