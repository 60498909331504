import { Button, SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import { Bullets } from 'components';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { type UpSell as UpSellInitProps } from './constants';

interface UpSellProps extends UpSellInitProps {
  isLoading?: boolean;
}

const UpSell: FunctionComponent<UpSellProps> = function ({
  id,
  pos,
  title,
  subtitle,
  points,
  button,
  imageSrc,
  isLoading,
}) {
  return (
    <div className="flex flex-col-reverse md:flex-row flex-1 w-full gap-x-8">
      <div className="flex flex-col w-full md:w-7/12">
        <Text
          isLoading={isLoading}
          text={pos}
          className="text-14-22-em md:text-18-28-em text-lavender-200 mb-2"
        />
        <Text
          isLoading={isLoading}
          text={title}
          className="text-28-32-em md:text-40-48-em text-neutral-900 mb-6"
        />
        <Text
          isLoading={isLoading}
          text={subtitle}
          className="text-18-28 text-neutral-900 mb-6"
        />
        <Bullets bullets={points} isLoading={isLoading} />
        <div className="flex flex-col max-w-80 mt-8">
          <Link to={button.link} className="w-full mb-3" disabled={isLoading}>
            <Button.Text
              id={id}
              label={button.label}
              className="w-full"
              isLoading={isLoading}
            />
          </Link>
          <Text
            isLoading={isLoading}
            text={button.comment}
            className="text-14-22 text-neutral-600 mx-auto"
          />
        </div>
      </div>
      <div className="flex w-full md:w-5/12 h-80 max-h-80 md:h-auto md:max-h-none">
        <SkeletonLoader
          isLoading={isLoading}
          wrapperClassName="w-full"
          className="w-full"
          height="100%"
          afterLoading={
            <img
              src={imageSrc}
              alt={id}
              className="w-full my-auto mt-2 mb-8 md:my-0 object-contain"
            />
          }
        />
      </div>
    </div>
  );
};

export default UpSell;
