import { useCallback, useEffect, useState } from 'react';

import { useFormContext } from '@kanda-libs/ks-component-ts';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import useCurrentCompany from '../../../../../../../hooks/useCurrentCompany';
import type { DirectorInfoFormProps } from './Form-view';

const parsePhone = (phone) => {
  if (!phone) return '';
  if (!phone.includes('+44')) return phone;
  return phone?.replace('+44', '0');
};

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  isSubmitting: boolean;
  limitedCompany: boolean;
  isMe: number | null;
  onClick: (arrayName: string, index: number) => void;
  onRemove: (arrayName: string, index: number) => void;
  company: Company | null;
}

export interface ContainerComponentProps extends DirectorInfoFormProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  me,
  isLoading = false,
  isSubmitting = false,
}: ContainerComponentProps) => {
  const [isMe, setIsMe] = useState<number | null>(null);
  const { handleContinue = () => {} } = useOnboardingContextValue();

  const { company } = useCurrentCompany();

  const { setValue } = useFormContext();

  const onClick = useCallback(
    (arrayName, index) => {
      if (isMe) return;
      setValue(`${arrayName}.${index}.email`, me?.email || '');
      setValue(`${arrayName}.${index}.mobile`, parsePhone(me?.phone) || '');
      setIsMe(index);
    },
    [isMe, me, setValue],
  );

  const onRemove = useCallback(
    (arrayName, index) => {
      setValue(`${arrayName}.${index}.email`, undefined);
      setValue(`${arrayName}.${index}.mobile`, undefined);
      setIsMe(null);
    },
    [setValue],
  );

  const limitedCompany = company?.company_type === 'limited_company';

  useEffect(() => {
    if (!isLoading && company && company.users?.length === 0 && company?.company_type !== 'sole_trader') {
      handleContinue({});
    }
  }, [company, isLoading, handleContinue]);

  return children({
    isLoading,
    isSubmitting,
    limitedCompany,
    isMe,
    onClick,
    onRemove,
    company,
  });
};

ContainerComponent.displayName = 'SetupCompany-DirectorInfo-Form-container';

export default ContainerComponent;
