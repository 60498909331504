import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import { useCurrentUser } from '@kanda-libs/ks-frontend-services';
import { useContext, useMemo } from 'react';
import useCurrentCompany from '../../hooks/useCurrentCompany';
import useMe from '../../hooks/useMe';
import { STAFF_IMITATION_MODAL_ID } from '../DesktopHeader/components/StaffImitationModal/constants';
import {
  LOGOUT_LINK,
  MENU_ADMIN_LINKS,
  MENU_LINKS,
  MENU_VERIFICATION_LINKS,
  STAFF_IMITATE_USER_MENU_LINK,
} from './Menu-constants';

const ContainerComponent = ({ children }) => {
  const { isValidating: isUserLoggedInIsValidating } = useCurrentUser();
  const { showModal } = useContext(ModalsWrapperContext);

  const { me, isValidating: meIsValidating } = useMe();

  const {
    company,
    isLoading: companyIsLoading,
    directorsAreVerified,
  } = useCurrentCompany();

  const name = me?.name || 'My Kanda';

  const companyLogo = company?.company_logo;
  const photoURL = me?.photoURL;

  const img = companyLogo || photoURL || null;

  const isLoading =
    isUserLoggedInIsValidating || meIsValidating || companyIsLoading;

  const menuLinks = useMemo(() => {
    const isStaff = me?.role === 'staff';
    const isAdmin = me?.role !== 'company-staff';
    if (!isStaff)
      return [
        ...MENU_LINKS,
        ...(isAdmin ? MENU_ADMIN_LINKS : []),
        ...(!directorsAreVerified && isAdmin ? [MENU_VERIFICATION_LINKS] : []),
        LOGOUT_LINK,
      ];

    return [
      ...MENU_LINKS,
      {
        ...STAFF_IMITATE_USER_MENU_LINK,
        onClick: () => {
          showModal(STAFF_IMITATION_MODAL_ID);
        },
      },
    ];
  }, [me, showModal, directorsAreVerified]);

  return children({ name, img, isLoading, menuLinks });
};

ContainerComponent.displayName = 'Menu-container';

export default ContainerComponent;
