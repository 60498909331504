import { Field } from '@kanda-libs/ks-component-ts';
import { AUTH_FIELD, AUTH_FIELD_VALIDATION } from './constants';
import useCheckBoxes from './useCheckBoxes';

const CheckBoxes: React.FunctionComponent = function () {
  const { fieldText } = useCheckBoxes();
  return (
    <div className="flex flex-col mt-5">
      <div className="flex flex-col mb-5 text-left field-wrapper w-full">
        <Field.Validator validation={AUTH_FIELD_VALIDATION}>
          <Field.FingerprintBooleanInput
            {...AUTH_FIELD}
            fieldText={fieldText}
          />
        </Field.Validator>
      </div>
    </div>
  );
};

export default CheckBoxes;

// IAR_FIELD,
// IAR_FIELD_VALIDATION,
// TAC_FIELD,
// TAC_FIELD_VALIDATION,

// {/* <Field.Validator validation={TAC_FIELD_VALIDATION}>
// <Field.BooleanInput {...TAC_FIELD} />
// </Field.Validator>
// <Field.Validator validation={IAR_FIELD_VALIDATION}>
// <Field.BooleanInput {...IAR_FIELD} />
// </Field.Validator> */}
