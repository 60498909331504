import { Widget } from '@kanda-libs/ks-component-ts';

import { DOB_VALIDATION } from 'common/constants';
import DirectorAddress from 'components/DirectorAddress';
import DirectorNameCard from '../../../../../../../../../../components/DirectorNameCard';
import { DATE_OF_BIRTH_NAME } from './constants';
import Container from './DirectorCard-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({
      name,
      number,
      buttonProps,
      index,
      firstName,
      show,
      emailValidation,
      mobileValidation,
      isLoading,
    }) => {
      if (!show) return <div />;
      return (
        <div className="flex flex-col mt-10 first:mt-0">
          <DirectorNameCard
            name={name}
            number={number}
            isLoading={isLoading}
            buttonProps={buttonProps}
          />
          <Widget.CompanyUsersMobileArrayInput
            index={index}
            label="Phone number"
            placeholder={`${firstName}'s mobile number`}
            validation={mobileValidation}
            isLoading={isLoading}
          />
          <Widget.CompanyUsersEmailArrayInput
            index={index}
            label="Email address"
            placeholder={`${firstName}'s email address`}
            validation={emailValidation}
            isLoading={isLoading}
          />
          <Widget.CompanyUsersDirectorInfoDateOfBirthArrayInput
            name={DATE_OF_BIRTH_NAME}
            index={index}
            validation={DOB_VALIDATION}
          />
          <DirectorAddress
            isArray
            widgetTagName="CompanyUsersDirectorInfoHomeAddress"
            labelPrefix="Home address"
            index={index}
          />
        </div>
      );
    }}
  </Container>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-Form-Limited-Card-view';

export default ViewComponent;
