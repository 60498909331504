import { matchPath } from 'react-router-dom';

/**
 * Checks if path is active
 * @param {string} path
 * @param {string} itemPath
 * @param {Object} options
 */
export const isActive = (path, itemPath, options) =>
  matchPath(path, {
    path: itemPath,
    exact: itemPath === '/',
    strict: false,
    ...options,
  });
