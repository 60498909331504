import { Button } from '@kanda-libs/ks-design-library';

import Container from './Header-container';
import { CLASS_NAMES, TITLE } from './Header-constants';
import { EDIT_BUTTON_PROPS } from '../../../../Summary-constants';

const ViewComponent = () => (
  <Container>
    {({ handleEdit }) => (
      <div className={CLASS_NAMES.container}>
        <p className={CLASS_NAMES.title}>{TITLE}</p>
        <Button.Icon onClick={handleEdit} {...EDIT_BUTTON_PROPS} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Header-view';

export default ViewComponent;
