import { Button } from '@kanda-libs/ks-design-library';
import Fields from './Fields';

interface ReferralsInviteFormReferralProps {
  arrayName?: string;
  index: number;
  remove: (index: number) => void;
  showRemove: boolean;
}

const ReferralsInviteFormReferral: React.FunctionComponent<ReferralsInviteFormReferralProps> =
  function ({ index, remove, showRemove }) {
    return (
      <div className="flex flex-col-reverse md:flex-row gap-x-4 border-t border-neutral-300 pt-4 md:pt-0 md:border-0">
        {showRemove && (
          <Button.Icon
            id="referral-invite-remove"
            onClick={() => remove(index)}
            icon="remove"
            variant="ghost-grey"
            size="28-16"
            iconProps={{ stroke: 'neutral-500' }}
            className="md:mb-auto md:mt-7"
          />
        )}
        <div className="flex flex-col md:flex-row gap-x-4 w-full">
          <Fields.Email index={index} />
          <Fields.Name index={index} />
          <Fields.Phone index={index} />
        </div>
      </div>
    );
  };

export default ReferralsInviteFormReferral;
