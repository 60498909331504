import {
  Icon,
  Text,
  Button,
  Loader,
  Header,
} from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';

import Container from './CloseModal-container';
import { MODAL_ID } from '../../Page-constants';

const ViewComponent = () => (
  <ModalContainer id={MODAL_ID}>
    {({ handleClose }) => (
      <Container handleClose={handleClose}>
        {({ onClickExit, onSaveClick, showSave, isSubmitting }) => (
          <>
            <ModalLayoutFullScreen
              noBorder
              header={
                <Header.Base
                  className="text-style-f-em text-neutral-700"
                  options={[
                    <Button.Icon
                      id="create-job-close-modal-close"
                      key="close"
                      onClick={handleClose}
                      icon="close"
                    />,
                  ]}
                />
              }
            >
              <div className="flex flex-col my-auto">
                <Icon
                  icon="delete-file"
                  stroke="red-200"
                  size={64}
                  className="mx-auto mb-6"
                />
                <Text
                  text="You’re about to lose all unsaved data"
                  className="w-full text-center text-24-28-em"
                />
                <div className="flex flex-col my-8">
                  {showSave ? (
                    <ul className="pl-5 list-disc text-16-24 text-neutral-600">
                      <li className="mb-2">Save the draft to carry on later</li>
                      <li>Exit without saving to abandon the job</li>
                    </ul>
                  ) : (
                    <Text
                      text="To save this job as a draft, you'll need to add at least a job title and fill out one item"
                      className="text-center text-16-24 text-neutral-600"
                    />
                  )}
                </div>

                {showSave && (
                  <Button.Text
                    id="create-job-close-modal-mobile-save-draft"
                    label="Save draft"
                    variant="gradient"
                    className="w-full mb-3"
                    onClick={onSaveClick}
                    disabled={isSubmitting}
                  />
                )}
                <Button.Text
                  id="create-job-close-modal-mobile-exit"
                  label="Exit without saving"
                  variant="outline"
                  className="w-full"
                  onClick={onClickExit}
                  disabled={isSubmitting}
                />
              </div>
            </ModalLayoutFullScreen>
            <Loader isLoading={isSubmitting} />
          </>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'CreateJob-CloseModal-view';

export default ViewComponent;
