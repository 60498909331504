const PreJob = () => (
  <div className="w-64 px-4 py-3 flex flex-col">
    <p className="text-12-18-em text-neutral-900 mb-3">Pre Job Notifications</p>
    <p className="text-12-18 text-neutral-500">
      Notifications related to jobs before the job has been accepted (with or
      without finance)
    </p>
  </div>
);

export default PreJob;
