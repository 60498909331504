import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@kanda-libs/ks-design-library';

import { URLS } from 'config';
import useCompleteWelcomePackSection from 'hooks/useCompleteWelcomePackSection';

import useFooter from './useFooter';

import type { UpdateYourWebsitePage } from '../types';
import { LABELS } from './constants';

export interface FooterProps {
  page: UpdateYourWebsitePage;
}

const Footer: FunctionComponent<FooterProps> = function ({ page }) {
  const { nextPage, showFinish } = useFooter(page);
  const { onFinish, isSubmitting } =
    useCompleteWelcomePackSection('update_website');

  if (nextPage === 'legal' || nextPage === 'invite-your-developer-success')
    return <></>;

  if (showFinish)
    return (
      <div className="w-full mt-4 md:mt-12">
        <Button.Text
          left
          id="update-your-website-finish"
          label="Finish"
          icon="check"
          variant="outline"
          className="w-full md:w-auto"
          disabled={isSubmitting}
          onClick={onFinish}
        />
      </div>
    );

  return (
    <div className="w-full mt-4 md:mt-12">
      <Link to={URLS.updateYourWebsite.replace(':id', nextPage as string)}>
        <Button.Text
          id="update-your-website-next"
          label={LABELS[page]}
          icon="arrow-right"
          variant="outline"
          className="w-full md:w-auto"
        />
      </Link>
    </div>
  );
};

export default Footer;
