import { Layout } from '@kanda-libs/ks-design-library';

import { Content } from '../components';
import Header from '../../components/Header';

const ViewComponent = () => (
  <Layout noBorder header={<Header />}>
    <Content />
  </Layout>
);

ViewComponent.displayName = 'Subscription-Mobile-view';

export default ViewComponent;
