import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';

const ContainerComponent = ({ children, name }) => {
  const optionsName = `job.checkout_options`;

  const { setValue } = useFormContext();

  const options = useWatch({ name: optionsName });
  const checked = options?.includes(name);
  // TODO: decide how to enable/disable this
  const disabled = true;

  const onClick = () => {
    if (disabled) return;
    if (checked) {
      const newOptions = options.filter((option) => option !== name);
      setValue(optionsName, newOptions);
      return;
    }
    const newOptions = [...options, name];
    setValue(optionsName, newOptions);
  };

  return children({
    paymentMethod: name,
    checked,
    disabled,
    onClick,
  });
};

ContainerComponent.displayName = 'PaymentMethods-Option-Desktop-container';

export default ContainerComponent;
