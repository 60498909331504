export const CLASS_NAMES = {
  container: 'flex  flex-col w-full',
  title: 'text-style-h-em lg:text-style-f-em text-neutral-800 mb-4 lg:mb-6',
  cardContainer: 'flex flex-col',
  card: 'radius-xl border border-neutral-300 flex flex-col rounded-xl shadow-card mb-8 lg:mb-12 p-4',
  mobileHeading: 'text-style-h-em text-neutral-800 mb-4 block lg:hidden',
};

export const TITLE = 'Finance Application';

export const SKELETONS = {
  title: {
    width: '60%',
  },
};
