import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import useSaveDraft from '../../../CreateJob-useSaveDraft';

const ContainerComponent = ({ children }) => {
  const {
    data: { job, isLoading, company },
  } = useCreateJobContextValue();

  const showBanner =
    job?.flow_type !== 'example' && company?.lifecycle === 'completed_setup';

  const { handleSubmit, isSubmitting } = useSaveDraft();

  const onClick = () => handleSubmit(null);

  return children({
    isLoading: isLoading || !company,
    onClick,
    showBanner,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'AccountCompletedBadge-container';

export default ContainerComponent;
