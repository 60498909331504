import { ErrorBoundary } from '@highlight-run/react';
import { AmplitudeProvider } from '@kanda-libs/ks-amplitude-provider';
import {
  actions,
  Company,
  createAction,
  createAppDispatchHook,
  createRoutedApp,
} from '@kanda-libs/ks-frontend-services';
import { getCreditId } from 'common/job/helpers';
import { URLS, type PageKeys } from 'config';
import type { FunctionComponent } from 'react';
import { store, type AppDispatch, type RootState } from 'store';

import AppWrapper from 'components/AppWrapper';
import StaffImitationModal from 'components/DesktopHeader/components/StaffImitationModal';
import KandaErrorBoundary from 'components/ErrorBoundary';

import AuthLink from 'pages/AuthLink';
import ClaimAccount from 'pages/ClaimAccount';
import ClaimResent from 'pages/ClaimResent';
import CreateJob from 'pages/CreateJob';
import CustomCode from 'pages/CustomCode';
import ExampleJob from 'pages/ExampleJob';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';
import Identify from 'pages/Identify';
import IdentifySuccess from 'pages/IdentifySuccess';
import JobDetails from 'pages/JobDetails';
import LeadAccept from 'pages/LeadAccept';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import MyAccount from 'pages/MyAccount';
import AccountSettings from 'pages/MyAccount/AccountSettings';
import ChangeEmail from 'pages/MyAccount/AccountSettings/ChangeEmail';
import ChangePassword from 'pages/MyAccount/AccountSettings/ChangePassword';
import CompanyInformation from 'pages/MyAccount/CompanyInformation';
import DirectorVerification from 'pages/MyAccount/DirectorVerification';
import JobSettings from 'pages/MyAccount/JobSettings';
import NotificationPreferences from 'pages/MyAccount/NotificationPreferences';
import Subscription from 'pages/MyAccount/Subscription';
import TeamSettings from 'pages/MyAccount/TeamSettings';
import Onboarding from 'pages/Onboarding';
import ReferralSignUp from 'pages/ReferralSignUp';
import ResetPassword from 'pages/ResetPassword';
import SetupCompany from 'pages/SetupCompany';
import SignUp from 'pages/SignUp';
import SSO from 'pages/SSO';
import Staff from 'pages/Staff';
import StaffLogin from 'pages/StaffLogin';
import SubscriptionReturn from 'pages/SubscriptionReturn';
import SubscriptionReturnSuccess from 'pages/SubscriptionReturn/Success';
import UpdateYourWebsite from 'pages/UpdateYourWebsite';
import Veriff from 'pages/Veriff';
import VerifyEmail from 'pages/VerifyEmail';

import CombinedStateWrapper from 'components/CombinedStateWrapper';
import NavMenu from 'components/NavMenu';
import useFacebookPixel from 'hooks/useFacebookPixel';
import AccountInReview from 'pages/AccountInReview';
import Advertising from 'pages/Advertising';
import BillingSuccess from 'pages/BillingSuccess';
import EnterpriseRates from 'pages/EnterpriseRates';
import EnterpriseRatesSuccess from 'pages/EnterpriseRates/Success';
import Invite from 'pages/Invite';
import Jobs from 'pages/Jobs';
import MarketingPackage from 'pages/MarketingPackage';
import ReferralsForm from 'pages/Referrals/Form';
import Referrals from 'pages/Referrals/Root';
import SetupAccount from 'pages/Setup/Account';
import SetupRates from 'pages/Setup/Rates';
import SetupSubscription from 'pages/Setup/Subscription';
import AnalyticsWrapper from '../AnalyticsWrapper';

const {
  pages,
  router: { Router },
} = createRoutedApp<RootState, PageKeys>(
  store,
  {
    home: {
      path: URLS.home,
      PageComponent: Home,
      requiredRoles: ['*'],
      loadingDependencies: [
        'company',
        'infoStats',
        'infoSearch',
        'infoOnboarding',
      ],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoStats),
        createAction(actions.infoOnboarding),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    jobs: {
      path: URLS.jobs,
      PageComponent: Jobs,
      requiredRoles: ['*'],
      loadingDependencies: ['infoSearch', 'company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoStats),
        createAction(actions.infoOnboarding),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    job: {
      path: URLS.job,
      PageComponent: JobDetails,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'job', 'credit'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.checkJob, {}, true),
        createAction(
          actions.getJob,
          {
            forceReload: true,
          },
          true,
          (job) => {
            if (job?.finance_status === 'not_applied') return [];
            return [
              createAction(actions.getCredit, {
                params: {
                  id: getCreditId(job),
                },
              }),
            ];
          },
        ),
      ],
    },
    createJob: {
      path: URLS.createJob,
      PageComponent: CreateJob,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'job'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getJobs),
      ],
    },
    createJobFromDraft: {
      path: URLS.createJobFromDraft,
      PageComponent: CreateJob,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'job'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getJob, {}, true),
      ],
    },
    login: {
      path: URLS.login,
      PageComponent: Login,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    loginSso: {
      path: URLS.loginSso,
      PageComponent: SSO,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    staff: {
      path: URLS.staff,
      PageComponent: Staff,
      requiredRoles: ['staff'],
      loadingDependencies: [],
      initialDataActions: [],
    },
    staffLogin: {
      path: URLS.staffLogin,
      PageComponent: StaffLogin,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    logout: {
      path: URLS.logout,
      PageComponent: Logout,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    forgotPassword: {
      path: URLS.forgotPassword,
      PageComponent: ForgotPassword.Request,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    forgotPasswordConfirmation: {
      path: URLS.forgotPasswordConfirmation,
      PageComponent: ForgotPassword.Confirmation,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    resetPassword: {
      path: URLS.resetPassword,
      PageComponent: ResetPassword.Landing,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    resetPasswordConfirmation: {
      path: URLS.resetPasswordConfirmation,
      PageComponent: ResetPassword.Confirmation,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    archive: {
      path: URLS.archive,
      PageComponent: Jobs,
      requiredRoles: ['*'],
      loadingDependencies: ['infoSearch', 'company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoStats),
        createAction(actions.infoOnboarding),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    authLink: {
      path: URLS.authLink,
      PageComponent: AuthLink,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    myAccount: {
      path: URLS.myAccount,
      PageComponent: MyAccount,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    directorVerification: {
      path: URLS.directorVerification,
      PageComponent: DirectorVerification,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    companyInformation: {
      path: URLS.companyInformation,
      PageComponent: CompanyInformation,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    jobSettings: {
      path: URLS.jobSettings,
      PageComponent: JobSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'rate', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoRate),
        createAction(actions.infoOnboarding),
      ],
    },
    accountSettings: {
      path: URLS.accountSettings,
      PageComponent: AccountSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    teamSettings: {
      path: URLS.teamSettings,
      PageComponent: TeamSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    changeEmail: {
      path: URLS.changeEmail,
      PageComponent: ChangeEmail,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    changePassword: {
      path: URLS.changePassword,
      PageComponent: ChangePassword,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    billingSuccess: {
      path: URLS.billingSuccess,
      PageComponent: BillingSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    subscription: {
      path: URLS.subscription,
      PageComponent: Subscription,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'subscription', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getSubscriptions),
        createAction(actions.infoOnboarding),
      ],
    },
    subscriptionReturn: {
      path: URLS.subscriptionReturn,
      PageComponent: SubscriptionReturn,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    subscriptionSuccess: {
      path: URLS.subscriptionSuccess,
      PageComponent: SubscriptionReturnSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    notificationPreferences: {
      path: URLS.notificationPreferences,
      PageComponent: NotificationPreferences,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    claimAccount: {
      path: URLS.claimAccount,
      PageComponent: ClaimAccount,
      requiredRoles: [],
      loadingDependencies: ['authUser', 'company'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    claimAccountResent: {
      path: URLS.claimAccountResent,
      PageComponent: ClaimResent,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    exampleJob: {
      path: URLS.exampleJob,
      PageComponent: ExampleJob,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    leadAccept: {
      path: URLS.leadAccept,
      PageComponent: LeadAccept,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['lead', 'company'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getLead, {}, true),
      ],
    },
    signUp: {
      path: URLS.signUp,
      PageComponent: SignUp,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    referrals: {
      path: URLS.referrals,
      PageComponent: Referrals,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'getCompanyReferrals'],
      initialDataActions: [
        createAction(
          actions.getCompanies,
          {},
          false,
          (companies: Company[]) => {
            const company = companies[0];
            const id = company?.id;
            if (!id) return [];
            return [
              createAction(actions.getCompanyReferrals, {
                params: {
                  id,
                },
                forceReload: true,
              }),
            ];
          },
        ),
      ],
    },
    referralsForm: {
      path: URLS.referralsForm,
      PageComponent: ReferralsForm,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'getCompanyReferrals'],
      initialDataActions: [
        createAction(
          actions.getCompanies,
          {},
          false,
          (companies: Company[]) => {
            const company = companies[0];
            if (!company || !company.id) return [];
            return [
              createAction(actions.getCompanyReferrals, {
                params: {
                  id: store.getState().auth.user?.cid || '',
                },
                forceReload: true,
              }),
            ];
          },
        ),
      ],
    },
    referralSignUp: {
      path: URLS.referralSignUp,
      PageComponent: ReferralSignUp,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    identify: {
      path: URLS.identify,
      PageComponent: Identify,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: ['company', 'getCompanyDirectorVerification'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getCompanyDirectorVerification, {}, true),
      ],
    },
    identifySuccess: {
      path: URLS.identifySuccess,
      PageComponent: IdentifySuccess,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    setupCompany: {
      path: URLS.setupCompany,
      PageComponent: SetupCompany,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    setupPartnerCompany: {
      path: URLS.setupPartnerCompany,
      PageComponent: SetupCompany,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    verifyEmail: {
      path: URLS.verifyEmail,
      PageComponent: VerifyEmail.Landing,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    verifyEmailSuccess: {
      path: URLS.verifyEmailSuccess,
      PageComponent: VerifyEmail.Confirmation,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    veriff: {
      path: URLS.veriff,
      PageComponent: Veriff,
      requiredRoles: ['*'],
      loadingDependencies: ['getCompanyDirectorVerification', 'company'],
      initialDataActions: [
        createAction(actions.getCompanyDirectorVerification),
        createAction(actions.getCompanies),
      ],
    },
    onboarding: {
      path: URLS.onboarding,
      PageComponent: Onboarding,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding', 'document'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
        createAction(actions.infoRate),
        createAction(actions.getDocuments),
      ],
    },
    onboardingSteps: {
      path: URLS.onboardingSteps,
      PageComponent: Onboarding,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding', 'document'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
        createAction(actions.getDocuments),
      ],
    },
    customCode: {
      path: URLS.customCode,
      PageComponent: CustomCode,
      loadingDependencies: [],
      initialDataActions: [],
    },
    updateYourWebsiteRedirect: {
      path: URLS.updateYourWebsiteRedirect,
      PageComponent: UpdateYourWebsite,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    updateYourWebsite: {
      path: URLS.updateYourWebsite,
      PageComponent: UpdateYourWebsite,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    developerRedirect: {
      path: URLS.developerRedirect,
      PageComponent: UpdateYourWebsite,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    developer: {
      path: URLS.developer,
      PageComponent: UpdateYourWebsite,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    advertisingRedirect: {
      path: URLS.advertisingRedirect,
      PageComponent: Advertising,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    advertising: {
      path: URLS.advertising,
      PageComponent: Advertising,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    invite: {
      path: URLS.invite,
      PageComponent: Invite.Welcome,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    inviteForm: {
      path: URLS.inviteForm,
      PageComponent: Invite.Form,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    inviteSummary: {
      path: URLS.inviteSummary,
      PageComponent: Invite.Summary,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    setupAccount: {
      path: URLS.setupAccount,
      PageComponent: SetupAccount,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    setupRates: {
      path: URLS.setupRates,
      PageComponent: SetupRates,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    setupSubscription: {
      path: URLS.setupSubscription,
      PageComponent: SetupSubscription,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'document', 'infoGetCache'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.infoGetCache),
        createAction(actions.getCompanies),
        createAction(actions.getDocuments),
        //   createAction(
        //     actions.getCompanies,
        //     {},
        //     false,
        //     (companies: Company[]) => {
        //       if (companies.length === 0) return [];
        //       const cid = companies[0]?.cid;
        //       if (!cid) return [];
        //       const name = `UNSIGNED-PREMIUMCREDIT_Kanda-T&C-${cid}.pdf`;
        //       return [
        //         createAction(actions.getDocuments, {
        //           params: { q: `name:${name}` },
        //         }),
        //       ];
        //     },
        //   ),
      ],
    },
    accountInReview: {
      path: URLS.accountInReview,
      PageComponent: AccountInReview,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    enterpriseRates: {
      path: URLS.enterpriseRates,
      PageComponent: EnterpriseRates,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'rate'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
        createAction(actions.infoRate, {
          params: {
            cid: 'enterprise',
          },
        }),
      ],
    },
    enterpriseRatesSuccess: {
      path: URLS.enterpriseRatesSuccess,
      PageComponent: EnterpriseRatesSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'rate'],
      initialDataActions: [
        createAction(actions.me, {
          forceReload: true,
        }),
        createAction(actions.getCompanies, {
          forceReload: true,
        }),
        createAction(actions.infoRate, {
          params: {
            cid: 'enterprise',
          },
          forceReload: true,
        }),
      ],
    },
    marketingPackage: {
      path: URLS.marketingPackage,
      PageComponent: MarketingPackage,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
  },
  undefined,
  CombinedStateWrapper,
);

const useAppDispatch = createAppDispatchHook<RootState, AppDispatch>();

export { pages, useAppDispatch };

const App: FunctionComponent = function () {
  useFacebookPixel();
  return (
    <AmplitudeProvider>
      <AnalyticsWrapper>
        <ErrorBoundary>
          <KandaErrorBoundary>
            <AppWrapper>
              <>
                <Router>
                  <NavMenu />
                </Router>
                <StaffImitationModal />
              </>
            </AppWrapper>
          </KandaErrorBoundary>
        </ErrorBoundary>
      </AnalyticsWrapper>
    </AmplitudeProvider>
  );
};

export default App;
