import type { StatusTagStatus } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from '../JobHeader-constants';
import { Title, Customer, Status, FinanceStatus } from '../components';
import { PriceStatus } from '../../../../../components';

interface ViewComponentProps {
  /**
   * Customer
   */
  customer?: string;
  /**
   * Job title
   */
  title?: string;
  /**
   * Job status
   */
  status?: StatusTagStatus;
  /**
   * Job finance status
   */
  financeStatus?: StatusTagStatus;
  /**
   * Job total price
   */
  price?: number;
  /**
   * Timestamp
   */
  timestamp?: Date;
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Job is archived
   */
  archived?: boolean;
}

const ViewComponent = ({
  customer,
  title,
  status,
  financeStatus,
  price,
  timestamp,
  isLoading,
  archived,
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <Customer isLoading={isLoading} name={customer} />
    <Title isLoading={isLoading} text={title} />
    <div className={CLASS_NAMES.footer}>
      <Status
        isLoading={isLoading}
        status={status}
        archived={archived}
        timestamp={timestamp}
      />
      <FinanceStatus
        isLoading={isLoading}
        financeStatus={financeStatus as StatusTagStatus}
      />
      <div className="mb-auto mt-1 ml-4">
        <PriceStatus
          isLoading={isLoading}
          total={price as number}
          status={status}
          financeStatus={financeStatus}
        />
      </div>
    </div>
  </div>
);

ViewComponent.displayName = 'Job-JobHeader-Mobile-view';

ViewComponent.defaultProps = {
  customer: undefined,
  title: undefined,
  status: undefined,
  financeStatus: undefined,
  price: undefined,
  timestamp: undefined,
  isLoading: undefined,
  archived: false,
};

export default ViewComponent;
