import { PAGES } from '../constants';
import type { AdvertisingPage } from '../types';

export interface FooterHook {
  nextPage?: AdvertisingPage;
  isLastPage: boolean;
}

export default function useFooter(page: AdvertisingPage): FooterHook {
  const pageIndex = Object.keys(PAGES).indexOf(page);
  const nextPageIndex = pageIndex + 1;

  const nextPage = Object.keys(PAGES)[nextPageIndex] as
    | AdvertisingPage
    | undefined;

  const isLastPage = nextPageIndex === Object.keys(PAGES).length;

  return {
    nextPage,
    isLastPage,
  };
}
