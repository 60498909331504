import { Form } from '@kanda-libs/ks-component-ts';

import useSolarTradeInfo from './useSolarTradeInfo';
import SolarInfoForm from './Form';

const ViewComponent = ({}) => {
  const { form, onSubmit } = useSolarTradeInfo();

  return (
    <Form
      id="onboarding-company-info-solar-company"
      form={form}
      onSubmit={onSubmit}
      className="w-full flex flex-col"
    >
      <SolarInfoForm />
    </Form>
  );
};

ViewComponent.displayName = 'SolarTradeInfo-view';

export default ViewComponent;
