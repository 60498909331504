import { useContext } from 'react';

import Context from '../../../Context';

const ContainerComponent = ({ children }) => {
  const { archive } = useContext(Context);

  return children({
    archive,
  });
};

ContainerComponent.displayName = 'Home-Mobile-Header-container';

export default ContainerComponent;
