import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

export const IF_DISPLAY_RATES: FinanceRate[] = [
  {
    name: 'INTEREST_FREE_10',
    fee: 975,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'propensio',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 10,
  },
  {
    name: 'INTEREST_FREE_24',
    fee: 1550,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'propensio',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 24,
  },
  {
    name: 'INTEREST_FREE_36',
    fee: 2200,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'propensio',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 36,
  },
];

export const IB_1490_DISPLAY_RATES: FinanceRate[] = [
  24, 36, 48, 60, 72, 84, 96, 108, 120, 180,
].map((term: number) => ({
  name: `INTEREST_BEARING_${term}_1490`,
  fee: 275,
  charge: 0,
  enabled: true,
  finance_types: ['primary'],
  provider: 'propensio',
  apr_type: 'INTEREST_BEARING',
  apr: 1490,
  duration: term,
}));

export const BNPL_DISPLAY_RATES: FinanceRate[] = ['6_60', '6_120'].map(
  (term: string) => {
    const [deferred, duration] = term.split('_');
    return {
      name: `BUYNOW_PAYLATER_${term}_1490`,
      fee: 1000,
      charge: 0,
      enabled: true,
      finance_types: ['primary'],
      provider: 'propensio',
      apr_type: 'BUYNOW_PAYLATER',
      apr: 1490,
      deferred_duration: parseInt(deferred, 10),
      duration: parseInt(duration, 10),
    };
  },
);

export const IB_1890_DISPLAY_RATES: FinanceRate[] = [
  24, 36, 48, 60, 72, 84, 96, 108, 120,
].map((term: number) => ({
  name: `INTEREST_BEARING_${term}_1890`,
  fee: 200,
  charge: 0,
  enabled: true,
  finance_types: ['secondary'],
  provider: 'propensio',
  apr_type: 'INTEREST_BEARING',
  apr: 1890,
  duration: term,
}));

export const HUMM_UNREG_IF_DISPLAY_RATES: FinanceRate[] = [
  {
    name: 'INTEREST_FREE_5',
    fee: 750,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 5,
  },
  {
    name: 'INTEREST_FREE_6',
    fee: 850,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 6,
  },
  {
    name: 'INTEREST_FREE_12',
    fee: 1100,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 12,
  },
];

export const HUMM_REG_IF_DISPLAY_RATES: FinanceRate[] = [
  {
    name: 'INTEREST_FREE_24',
    fee: 1650,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 24,
  },
  {
    name: 'INTEREST_FREE_36',
    fee: 2250,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 36,
  },
  {
    name: 'INTEREST_FREE_48',
    fee: 2750,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 48,
  },
  {
    name: 'INTEREST_FREE_60',
    fee: 3100,
    charge: 0,
    enabled: true,
    finance_types: ['primary'],
    provider: 'humm',
    apr_type: 'INTEREST_FREE',
    apr: 0,
    duration: 60,
  },
];

export const HUMM_REG_IB_DISPLAY_RATES: FinanceRate[] = [
  12, 24, 36, 48, 60,
].map((term: number) => ({
  name: `INTEREST_BEARING_${term}_1499`,
  fee: 550,
  charge: 0,
  enabled: true,
  finance_types: ['primary'],
  provider: 'humm',
  apr_type: 'INTEREST_BEARING',
  apr: 1499,
  duration: term,
}));

export const PROP_REG_DISPLAY_RATES: FinanceRate[] = [
  ...IF_DISPLAY_RATES,
  ...IB_1490_DISPLAY_RATES,
  ...BNPL_DISPLAY_RATES,
  ...IB_1890_DISPLAY_RATES,
];

export const PROP_UNREG_DISPLAY_RATES: FinanceRate[] = [
  ...IF_DISPLAY_RATES,
  ...IB_1490_DISPLAY_RATES,
  ...BNPL_DISPLAY_RATES,
  ...IB_1890_DISPLAY_RATES,
];

export const HUMM_UNREG_DISPLAY_RATES: FinanceRate[] = [
  ...HUMM_UNREG_IF_DISPLAY_RATES,
];

export const HUMM_REG_DISPLAY_RATES: FinanceRate[] = [
  ...HUMM_UNREG_IF_DISPLAY_RATES,
  ...HUMM_REG_IF_DISPLAY_RATES,
  ...HUMM_REG_IB_DISPLAY_RATES,
];

export const LENDER_DISPLAY_RATES: StringIndexedObject<
  StringIndexedObject<FinanceRate[]>
> = {
  propensio: {
    regulated: PROP_REG_DISPLAY_RATES,
    unregulated: PROP_UNREG_DISPLAY_RATES,
  },
  humm: {
    regulated: HUMM_REG_DISPLAY_RATES,
    unregulated: HUMM_UNREG_DISPLAY_RATES,
  },
};
