import { Button, DesktopLayoutBoxed } from '@kanda-libs/ks-design-library';

import Container from './Page-container';
import { CloseModal } from '../../../components';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <Container>
    {({ onClick }) => (
      <DesktopLayoutBoxed
        options={[
          <Button.Icon
            id="create-job-desktop-close"
            key="close"
            icon="close"
            onClick={onClick}
          />,
        ]}
      >
        <>
          {children}
          <CloseModal />
        </>
      </DesktopLayoutBoxed>
    )}
  </Container>
);
ViewComponent.displayName = 'CreateJob-Page-Desktop-view';

ViewComponent.defaultProps = {
  children: null,
};

export default ViewComponent;
