import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

import { MODAL_ID } from './Button-constants';
import type { PopoverButtonProps } from './Button-view';

export interface ContainerComponentChildrenArgs {
  onClick: () => void;
  modalId: string;
  bucket: string;
}

export interface ContainerComponentProps extends PopoverButtonProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children, bucket }: ContainerComponentProps) => {
  const { showModal } = useContext(ModalsWrapperContext);
  const modalId = MODAL_ID?.replace('bucket', bucket);

  /**
   * Function to show delete item modal
   */
  const onClick = useCallback(() => showModal(modalId), [modalId, showModal]);

  return children({
    onClick,
    modalId,
    bucket,
  });
};

ContainerComponent.displayName =
  'NotificationsModal-Bucket-Popover-Mobile-Container';

export default ContainerComponent;
