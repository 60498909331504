import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { useForm, type StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  actions,
  FirebaseAuthService,
  useCurrentUser,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../config';
import { cleanReturnUrl, getEmailFromUrl } from './AuthLink-functions';

const stripSearch = (rawSearch: string) => {
  if (rawSearch[0] === '?') return rawSearch.substring(1);
  return rawSearch;
};

const parseParams = (search?: string): StringIndexedObject => {
  if (!search) return {};
  const stripped = stripSearch(search);
  const parts = stripped.split('&');
  if (!parts) return {};
  return parts.reduce((final: StringIndexedObject, current: string) => {
    const split = current.split('=');
    if (split.length > 2) {
      const [key, ...rest] = split;
      return {
        ...final,
        [key]: rest.join('='),
      };
    }
    return {
      ...final,
      [split[0]]: split[1],
    };
  }, {});
};

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const url = window.location.href;
  const urlRef = useRef<string | null>(null);

  const [display, setDisplay] = useState<string | false>(false);
  const [actionCode, setActionCode] = useState<string>('');

  const { user, isValidating: userIsValidating } = useCurrentUser();

  const { reset } = useAmplitude();

  const { mutate: logout } = useMutate(FirebaseAuthService.logout);

  const { mutate: parseActionCode } = useMutate(
    FirebaseAuthService.parseActionCode,
  );
  const { mutate: signInWithEmailLink, isSubmitting: isSigningIn } = useMutate(
    FirebaseAuthService.signInWithEmailLink,
  );

  const { showError } = useToast();
  const onError = useApiError('Error sending new email - please contact Kanda');

  const { push } = useHistory();

  const form = useForm();

  const signIn = useCallback(
    (email: string, continueUrl: string) => {
      signInWithEmailLink(email, url).then(({ error: signInError }) => {
        if (signInError) {
          showError('Error signing in - new email sent');
          setDisplay('expired');
          dispatch(
            actions.infoAuth({
              body: {
                email,
                continue_url: continueUrl,
              },
              onError,
            }),
          );
          return;
        }
        window.location.href = cleanReturnUrl(continueUrl);
      });
    },
    [showError, signInWithEmailLink, url, dispatch, onError],
  );

  const onSubmit = useCallback(
    (formData) => {
      const { email } = formData;
      if (!email) return;
      signIn(email, actionCode);
    },
    [actionCode, signIn],
  );

  useEffect(() => {
    if (userIsValidating) return;
    if (url && url === urlRef.current) return;
    urlRef.current = url;
    parseActionCode(url).then(({ data, error }) => {
      if (error) {
        // DEV_NOTE: handle code error here
        showError('error');
        return;
      }
      const { operation, code } = data as StringIndexedObject<string>;

      const search = window?.location?.search;
      const params = parseParams(search);
      const continueUrl = params?.continueUrl;

      if (operation === 'EMAIL_SIGNIN') {
        setActionCode(code as unknown as string);
        const email = (continueUrl as string)?.includes('?')
          ? getEmailFromUrl(continueUrl)
          : null;
        if (user && email) {
          if (user.email === email) {
            window.location.href = cleanReturnUrl(continueUrl);
            return;
          }
          reset();
          logout(false).then(() => {
            signIn(email, continueUrl);
          });
          return;
        }
        if (email) {
          signIn(email, continueUrl);
          return;
        }
        setDisplay('form');
        return;
      }
      if (operation === 'PASSWORD_RESET') {
        push(URLS.resetPassword, { code, mode: operation });
        return;
      }
      if (operation === 'VERIFY_EMAIL') {
        push(URLS.verifyEmail, { code, mode: operation });
        return;
      }
      // DEV_NOTE: handle non-operation codes here
      push(URLS.login);
    });
  }, [
    url,
    parseActionCode,
    showError,
    push,
    onSubmit,
    signIn,
    userIsValidating,
    user,
    logout,
    reset,
  ]);

  return children({
    display,
    form,
    onSubmit,
    isSubmitting: isSigningIn,
    disabled: isSigningIn,
  });
};

ContainerComponent.displayName = 'Onboarding-container';

export default ContainerComponent;
