import { createContext } from 'react';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

export type LineItemContextValue = {
  title?: string;
  description?: string;
  isLoading?: boolean;
  quantity?: string;
  price?:
    | {
        amount?: number;
      }
    | number;
  vat?: {
    amount?: number;
  };
  vat_rate?: string;
  total?: number;
  classNames: StringIndexedObject;
  variant?: string;
};

const Context = createContext<LineItemContextValue>({
  classNames: {},
});

Context.displayName = 'LineItem-Context';

export default Context;
