export const CLASS_NAMES = {
  content: 'flex flex-col items-center py-16',
  title: 'text-style-b text-neutral-700 mb-6',
  description: 'text-style-f text-neutral-600 w-80',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'search-file',
  size: 64,
};

export const TITLE = 'No results';

export const DESCRIPTION = 'Try searching with another keyword';
