import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useCallback, useMemo } from 'react';
import { capitaliseWords } from 'utils';

import type { NotificationsModaBucketProps } from './Bucket-view';

export interface ContainerComponentChildrenArgs {
  name: string;
  fieldProps: StringIndexedObject;
  onClick: () => void;
}

export interface ContainerComponentProps extends NotificationsModaBucketProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  bucket,
  onClick,
  index,
  commPreferences,
}: ContainerComponentProps) => {
  const heading = bucket
    ?.split('_')
    ?.map((string) => capitaliseWords(string.toLowerCase()))
    ?.join(' ');

  const handleClick = useCallback(() => onClick(bucket), [bucket, onClick]);

  const fieldProps = useMemo(() => {
    const fieldName = `company.users.${index}.comm_preferences.${bucket}`;
    const enabled = commPreferences?.[bucket?.toLowerCase()] === 'enabled';

    return {
      id: fieldName,
      name: fieldName,
      defaultChecked: enabled,
    };
  }, [bucket, index, commPreferences]);

  if (!commPreferences?.[bucket?.toLowerCase()]) return null;

  return children({
    name: heading,
    onClick: handleClick,
    fieldProps,
  });
};

ContainerComponent.displayName = 'NotificationsModal-Bucket-Container';

export default ContainerComponent;
