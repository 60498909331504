import clsx from 'clsx';

import { CLASS_NAMES } from './Desktop-constants';

const ContainerComponent = ({ children, index, showRemove, remove }) => {
  const removeButtonClassName = clsx(
    CLASS_NAMES.option,
    showRemove ? 'block' : 'hidden',
  );

  /**
   * Handles remove
   */
  const handleRemove = () => remove(index);

  return children({
    handleRemove,
    removeButtonClassName,
  });
};

ContainerComponent.displayName = 'Item-Desktop-container';

export default ContainerComponent;
