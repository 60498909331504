import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { Veriff } from '@veriff/js-sdk';
import { URLS } from '../../config';
import { VERIFF_API_KEY } from './constants';

/**
 * Shows the veriff modal
 * @param {String} url
 * @param {Function} onSuccess
 */
export const showVeriffModal = (url, onSuccess) => {
  createVeriffFrame({
    url,
    onEvent: (event) => {
      if (event === MESSAGES.FINISHED) {
        onSuccess();
      }
    },
  });
};

/**
 * Creates the URL that veriff will redirect back to afterwards
 * @param {String} cid
 * @param {String} email
 * @param {Boolean} isUserLoggedIn
 */
export const getVeriffCallbackUrl = (cid, email, isUserLoggedIn) => {
  if (isUserLoggedIn) {
    return window.location.href.replace('http://', 'https://');
  }

  const { host } = window.location;

  return `https://${host}${URLS.identify.replace(
    ':cid',
    cid,
  )}/success?email=${email}`;
};

/**
 * Adds the veriff button to the page
 * @param {Object} director
 * @param {String} company ID
 * @param {Function} onSession
 * @param {Boolean} isUserLoggedIn
 */
export const createVeriffButton = (
  director,
  cid,
  onSession,
  isUserLoggedIn,
) => {
  if (!director) {
    return;
  }

  const veriff = Veriff({
    apiKey: VERIFF_API_KEY,
    parentId: 'veriff',
    onSession,
  });

  veriff.setParams({
    callback: getVeriffCallbackUrl(cid, director.email, isUserLoggedIn),
    person: {
      givenName: director.first_name,
      lastName: director.last_name,
    },
    vendorData: director.email,
  });

  veriff.mount();
};
