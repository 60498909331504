import { PHONE_REGEX } from '../../../../../../../../../constants';

export const EMAIL_PROPS = {
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'email',
  label: 'Email Address',
  placeholder: 'Their email address',
};

export const MOBILE_PROPS = {
  type: 'phone',
  autoCapitalize: 'off',
  autoCorrect: 'off',
  autoComplete: 'tel',
  label: 'Mobile Number',
  placeholder: 'Their mobile number',
};

export const ROLES = ['company-admin', 'company-manager', 'company-staff'];

export const BASE_PHONE_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};
