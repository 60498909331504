import type { FunctionComponent } from 'react';

import useIFrame from './useIFrame';

const IFrame: FunctionComponent = function () {
  const { url } = useIFrame();

  return (
    <div className="hidden md:block">
      <iframe
        title="Kanda Finance Calculator"
        src={url}
        className="w-full h-full mt-8 overflow-hidden md:mt-0 md:scale-75 min-h-180 aspect-video min-w-50"
      />
    </div>
  );
};

export default IFrame;
