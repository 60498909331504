import { useToast } from '@kanda-libs/ks-design-library';
import type { AsyncThunkActionError } from '@kanda-libs/ks-frontend-services/src/store/types';

export type ApiErrorHook = (error: AsyncThunkActionError) => void;

export default function useApiError(
  defaultMessage = 'An unknown error has occured',
): ApiErrorHook {
  const { showError } = useToast();

  return (error) => {
    showError(error.message || defaultMessage);
  };
}
