import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { calculateJobTotal } from '../../../../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { data } = useCreateJobContextValue();

  const lineItems = data?.job?.job_items;
  const deposit = data?.job?.deposit_value?.amount;

  const totals = calculateJobTotal(lineItems || []);

  return children({
    ...totals,
    deposit,
  });
};

ContainerComponent.displayName = 'JobDetails-Totals-container';

export default ContainerComponent;
