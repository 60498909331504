import { useMemo, useEffect } from 'react';
import { useForm } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { Job } from '@kanda-libs/ks-frontend-services';

const ContainerComponent = ({ children }) => {
  const { data, handleContinue } = useCreateJobContextValue();

  const defaultValues = useMemo(() => {
    if (!data?.job) return null;
    return {
      job: data.job,
    };
  }, [data]);

  const form = useForm({
    mode: 'onBlur',
  });

  const { reset } = form;

  const onSubmit = (submittedData) => {
    handleContinue({
      ...data,
      job: {
        ...(data.job as Job),
        deposit_type: submittedData.job.deposit_type,
        deposit_value: {
          ...data?.job?.deposit_value,
          amount: submittedData.job.deposit_value.amount,
          currency: 'GBP',
        },
      },
    });
  };

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    form,
    onSubmit,
  });
};

ContainerComponent.displayName = 'CreateJob-Deposit-container';

export default ContainerComponent;
