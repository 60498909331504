import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import {
  useReferralSignUpContext,
  type ReferralSignUpPage,
} from 'pages/ReferralSignUp/context';
import { useCallback, useEffect } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import type { ReferralSignUpFieldValues } from '../../types';

export interface ReferralSignUpFormHook {
  form: UseFormReturn<ReferralSignUpFieldValues>;
  onSubmit: (
    formValues: StringIndexedObject<ReferralSignUpFieldValues>,
  ) => void;
  page: ReferralSignUpPage;
  disabled: boolean;
  isSubmitting: boolean;
}

export default function useReferralSignUpForm(): ReferralSignUpFormHook {
  const { email, submit, page, disabled, isSubmitting } =
    useReferralSignUpContext();

  const form = useForm<ReferralSignUpFieldValues>({
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<ReferralSignUpFieldValues>) =>
      submit(formValues as any as ReferralSignUpFieldValues),
    [submit],
  );

  useEffect(() => {
    if (!email) return;
    form.setValue('email', email);
  }, [email, form]);

  return {
    form,
    onSubmit,
    page,
    disabled,
    isSubmitting,
  };
}
