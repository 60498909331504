import { Dropzone, DropzoneProps } from '@kanda-libs/ks-component-ts';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import type { FunctionComponent } from 'react';

interface WrapperHook {
  WrapperTag: string | FunctionComponent<DropzoneProps>;
  wrapperProps: DropzoneProps | object;
}

export default function useWrapper(): WrapperHook {
  const { currentStepIndex } = useOnboardingContextValue();

  const WrapperTag = currentStepIndex === 4 ? Dropzone : 'div';

  const wrapperProps =
    currentStepIndex === 4
      ? {
          accept: [
            'application/pdf',
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/gif',
          ],
          text: 'Drop public liability insurance document file here',
        }
      : {};

  return {
    WrapperTag,
    wrapperProps,
  };
}
