import { useFormContext, ValidationItems } from '@kanda-libs/ks-component-ts';
import type { AuthUser } from '@kanda-libs/ks-frontend-services';
import * as EmailValidator from 'email-validator';

export type UserEmailValidationHook = ValidationItems;

export default function useUserEmailValidation(
  index: number,
): UserEmailValidationHook {
  const { getValues } = useFormContext();

  return {
    validate: {
      value: (value?: string | number | boolean): boolean => {
        const directorsExcludingCurrent = getValues().company.users.filter(
          (_: AuthUser, userIndex: number) => userIndex !== index,
        );
        const directorEmailsExcludingCurrent = directorsExcludingCurrent.map(
          ({ email }) => email,
        );
        const validEmail = EmailValidator.validate(value as unknown as string);

        return (value &&
          validEmail &&
          directorEmailsExcludingCurrent.indexOf(value) === -1) as boolean;
      },
      message:
        "Please enter a valid email that hasn't been used for another user",
    },
  };
}
