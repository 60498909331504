import type { FunctionComponent } from 'react';

import { Button, CopyContent, Text } from '@kanda-libs/ks-design-library';

import OnboardingTitle from 'components/OnboardingTitle';

import { Field, Form } from '@kanda-libs/ks-component-ts';
import { COPIED_BUTTON_PROPS } from './constants';
import IFrame from './IFrame';
import useCalculator from './useCalculator';

const Calculator: FunctionComponent = function () {
  const { form, code } = useCalculator();

  return (
    <div className="flex flex-col items-center justify-center text-left md:grid grid-cols-2 gap-x-4 md:min-h-100">
      <div className="h-full md:order-0">
        <IFrame />
      </div>
      <div className="flex flex-col gap-y-2 max-w-100">
        <OnboardingTitle>
          Add the Finance Calculator to your website
        </OnboardingTitle>
        <Text
          text="Win more jobs by showing your rates to customers right on your website."
          className="block my-4"
        />

        <Form id="finance-calculator-code" form={form} onSubmit={() => {}}>
          <Field.TextAreaInput name="code" value={code} rows={3} />
        </Form>
        <CopyContent
          content={code}
          clickedView={<Button.Link {...COPIED_BUTTON_PROPS} size={14} />}
        >
          <Button.Text
            id="update-your-website-calculator-copy-code"
            label="Copy code"
            icon="copy"
            size={40}
            className="w-full"
          />
        </CopyContent>
      </div>
    </div>
  );
};

export default Calculator;
