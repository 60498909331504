import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect, useMemo, useState } from 'react';

import { ONBOARDING_STEPS } from '../../../Onboarding-constants';
import {
  ACCOUNT_VERIFICATION_KEY,
  BUSINESS_DETAILS_KEY,
  ONBOARDING_STEP_ITEMS,
  PAY_MONTHLY_CODES,
  SUBSCRIPTION_KEY,
} from './constants';

import { URLS } from '../../../../../config';
import useCurrentCompany from '../../../../../hooks/useCurrentCompany';
import useIsPending from '../../../../../hooks/useIsPending';
import useIsSubscribed from '../../../../../hooks/useIsSubscribed';
import { filterCompletedSteps, findNextStepToComplete } from './helpers';

export interface OnboardingStepsHook {
  steps: typeof ONBOARDING_STEP_ITEMS;
  activeStep: number;
  setStep?: (step: string) => void;
  isLoading: boolean;
  isPending: boolean;
  isPremiumCredit?: boolean;
  stepsCompleted: StringIndexedObject;
  nextStepToComplete: StringIndexedObject;
  completedSteps: StringIndexedObject[];
}

/**
 * A hook used for determining which step the user is on within the
 */
export default function useOnboardingSteps(): OnboardingStepsHook {
  const { setStep } = useOnboardingContextValue();
  const [activeStep, setActiveStep] = useState(0);
  const { isSubscribed, isLoading: subscriptionIsLoading } = useIsSubscribed();
  const { isPending } = useIsPending();
  const {
    company,
    directorsAreVerified,
    isLoading: companyIsLoading,
  } = useCurrentCompany();

  const { logEvent } = useAmplitude();

  const isLoading = subscriptionIsLoading || companyIsLoading;

  const isLegacy = company?.billing === 'legacy';

  const isPremiumCredit = useMemo(() => {
    if (!company) return false;
    const referral = company?.referral_code;
    if (!referral) return false;
    return PAY_MONTHLY_CODES.includes(referral);
  }, [company]);

  const steps = ONBOARDING_STEP_ITEMS;

  useEffect(() => {
    if (isLoading || !isSubscribed) {
      setActiveStep(0);
      return;
    }

    if (directorsAreVerified) {
      setActiveStep(2);
      return;
    }

    setActiveStep(1);
  }, [isLegacy, isSubscribed, isLoading, directorsAreVerified]);

  useEffect(() => {
    if (!isPending) return;
    if (!setStep) return;
    setStep(ONBOARDING_STEPS.SETUP_YOUR_SUBSCRIPTION);
  }, [isPending, setStep]);

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'next-steps');
    window.history.pushState({}, '', url);
    logEvent('page-view');
  }, [logEvent]);

  const stepsCompleted = useMemo(
    () => ({
      [SUBSCRIPTION_KEY]: isSubscribed,
      [ACCOUNT_VERIFICATION_KEY]: directorsAreVerified,
      [BUSINESS_DETAILS_KEY]: company?.lifecycle === 'verified',
    }),
    [isSubscribed, directorsAreVerified, company?.lifecycle],
  );

  const nextStepToComplete = useMemo(
    () => findNextStepToComplete(steps, stepsCompleted),
    [steps, stepsCompleted],
  );

  const completedSteps = useMemo(
    () => filterCompletedSteps(steps, stepsCompleted),
    [steps, stepsCompleted],
  );

  return {
    steps,
    activeStep,
    setStep,
    isLoading,
    isPending,
    isPremiumCredit,
    stepsCompleted,
    nextStepToComplete,
    completedSteps,
  };
}
