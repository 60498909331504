export const getEmailFromUrl = (url) => {
  if (!url.includes('email')) return null;
  const params = url
    .split('?')[1]
    .split('&')
    .reduce((allParams, param) => {
      const [key, value] = param.split('=');
      if (!key || !value) return allParams;
      return {
        ...allParams,
        [key]: value,
      };
    }, {});
  if (!params?.email) return null;
  return decodeURIComponent(params.email);
};

export const cleanReturnUrl = (url) => url;
// {
//   if (url.includes('claim-account'))
// } url.split('?')[0];
