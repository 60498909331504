import type { FunctionComponent } from 'react';

import { Button, Text, useIsDesktop } from '@kanda-libs/ks-design-library';

import { REQUEST_ADS_LINK } from './constants';

import socialMediaAdvertsImage from '../../../assets/social-media-adverts.png';

const SocialMedia: FunctionComponent = function () {
  const isDesktop = useIsDesktop();
  return (
    <div className="w-full grid md:grid-cols-2 gap-x-8">
      <div className="flex flex-row items-center justify-center ">
        <img
          src={socialMediaAdvertsImage}
          alt="Send your self an example job"
          className="md:max-w-[400px] w-auto"
        />
      </div>
      <div className="flex flex-col justify-center text-left md:w-72">
        <Text
          text="Get customised Finance adverts for social media"
          className="mt-4 mb-6 text-24-28-em md:mt-0"
        />
        <Text
          text="We’ll make you a set of adverts to help you get the word out to customers."
          className="mb-6 text-neutral-600 text-16-24"
        />
        <div>
          <a href={REQUEST_ADS_LINK} target="_blank" rel="noopener noreferrer">
            <Button.Text
              id="dashboard-example-job"
              label="Request adverts"
              icon="arrow-right"
              className="w-full mb-2 md:w-auto"
              size={isDesktop ? 40 : undefined}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
