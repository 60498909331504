import useCurrentCompany from 'hooks/useCurrentCompany';
import { useMemo } from 'react';
import { HUMM_TYPEFORM_LINK, PROPENSIO_TYPEFORM_LINK } from './constants';

export interface RequestNewRatesHook {
  isLoading: boolean;
  link?: string;
}

export default function useRequestNewRates(): RequestNewRatesHook {
  const { company } = useCurrentCompany();

  const isLoading = useMemo(() => !company, [company]);

  const link = useMemo(() => {
    if (!company) return PROPENSIO_TYPEFORM_LINK;
    const config = company?.business_config;
    if (!config) return PROPENSIO_TYPEFORM_LINK;
    const { lender, lender_rate_type: rateType } = config;
    if (lender === 'humm') {
      if (rateType === 'unregulated') return undefined;
      return HUMM_TYPEFORM_LINK;
    }
    return PROPENSIO_TYPEFORM_LINK;
  }, [company]);

  return {
    isLoading,
    link,
  };
}
