import type { MutableRefObject } from 'react';
import { Button } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';
import Container from './Modal-container';

interface ViewComponentProps {
  /**
   * ID for the selection modal
   */
  modalId: string;
  removeItem: () => void;
}

const ViewComponent = ({ modalId, ...restProps }: ViewComponentProps) => (
  <ModalContainer id={modalId}>
    {({ id, handleClose, ref }) => (
      <ModalLayoutSlideUp
        id={id}
        onClose={handleClose}
        ref={ref as MutableRefObject<HTMLDivElement>}
      >
        <Container handleClose={handleClose} {...restProps}>
          {({ deleteItem }) => (
            <div className="mt-4 mb-3">
              <Button.Link
                id="create-job-kanda-remove-line-item"
                label="Remove Item"
                variant="grey"
                icon="remove"
                onClick={deleteItem}
                className="mr-auto"
              />
            </div>
          )}
        </Container>
      </ModalLayoutSlideUp>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'Item-Options-Modal-view';

export default ViewComponent;
