import { Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { CLASS_NAMES, FROM_LABEL, COST_LABEL } from './constants';

export interface OptionGroupHeaderProps {
  isLoading: boolean;
  label: string;
}

const OptionGroupHeader: FunctionComponent<OptionGroupHeaderProps> = function ({
  isLoading,
  label,
}) {
  return (
    <div className={CLASS_NAMES.wrapper}>
      <div className={CLASS_NAMES.firstItem}>
        <Text
          textComponent="p"
          className={CLASS_NAMES.label}
          isLoading={isLoading}
          text={label}
        />
      </div>
      <div className={CLASS_NAMES.otherItems}>
        <Text
          textComponent="p"
          className={CLASS_NAMES.label}
          isLoading={isLoading}
          text={FROM_LABEL}
        />
      </div>
      <div className={CLASS_NAMES.otherItems}>
        <Text
          textComponent="p"
          className={CLASS_NAMES.label}
          isLoading={isLoading}
          text={COST_LABEL}
        />
      </div>
    </div>
  );
};

export default OptionGroupHeader;
