export const MENU_ITEMS = {
  download: {
    icon: 'download-file',
    name: 'Download as PDF',
  },
  duplicate: {
    icon: 'copy',
    name: 'Duplicate & edit',
  },
  duplicateArchive: {
    icon: 'copy-archive',
    name: 'Duplicate, edit & archive',
  },
  archive: {
    icon: 'archive',
    name: 'Archive job',
  },
};
