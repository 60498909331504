import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { IDS } from './SMSButton-constants';

const ContainerComponent = ({ children }) => {
  const { showModal } = useContext(ModalsWrapperContext);

  const { data } = useCreateJobContextValue();

  const number = data?.job?.customer?.phone;
  const customer = data?.job?.customer;

  /**
   * Function to show SMS modal
   */
  const handleShowModal = useCallback(() => showModal(IDS.modal), [showModal]);

  return children({
    customer,
    number,
    handleShowModal,
  });
};

ContainerComponent.displayName =
  'CreateJob-SendConfirmation-SMSButton-container';

export default ContainerComponent;
