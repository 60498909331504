import { Layout, Header, Loader } from '@kanda-libs/ks-design-library';
import { MultiStepFormRouter } from '@kanda-libs/ks-component-ts';

import { Footer } from './components';

import Pages from '../../Pages';

interface ViewComponentProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
  /**
   * isLoading
   */
  step?: number;
}

const ViewComponent = ({ step = 0, isLoading }: ViewComponentProps) => (
  <Layout
    noPadding
    noBorder
    footer={step < 3 ? <Footer isLoading={isLoading} /> : <></>}
    header={<Header.Base lines help />}
  >
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <div className="px-6 my-auto">
        <MultiStepFormRouter pages={Pages} />
      </div>
    </>
  </Layout>
);

ViewComponent.displayName = 'AccountVerificationExplanation-Mobile-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  step: undefined,
};

export default ViewComponent;
