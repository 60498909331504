import { TimeAgo } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from './Activity-constants';
import Container from './Activity-container';

export interface ViewComponentProps {
  date: string | Date;
  status: string;
  financeStatus: string;
  archived?: string;
  viewedFinance?: boolean;
}

const ViewComponent = ({
  archived = 'no',
  viewedFinance = false,
  ...props
}: ViewComponentProps) => (
  <Container archived={archived} viewedFinance={viewedFinance} {...props}>
    {({ label, timestamp }) => (
      <p className={CLASS_NAMES.label}>
        <span>{label}</span> <TimeAgo timestamp={timestamp} />
      </p>
    )}
  </Container>
);

ViewComponent.displayName = 'Activity-view';

export default ViewComponent;
