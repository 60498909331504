import type { ButtonTextVariant } from '@kanda-libs/ks-design-library';

export const ICON_PROPS = {
  icon: 'user-square-color',
  size: 64,
  className: 'my-auto hidden md:block mr-6',
};

export const BUTTON_SMALL_PROPS = {
  variant: 'gradient' as ButtonTextVariant,
  className: 'block md:hidden whitespace-nowrap',
  size: 32,
};

export const BUTTON_LARGE_PROPS = {
  variant: 'gradient' as ButtonTextVariant,
  className: 'hidden md:block whitespace-nowrap',
  size: 40,
};

export const CLASS_NAMES = {
  container: 'flex flex-col md:flex-row w-full',
  textWrapper: 'flex flex-col my-auto mr-auto mb-10 md:mb-auto',
  textWrapperReversed: 'flex flex-col-reverse my-auto mr-auto',
  title: 'text-18-22-em md:text-18-22-em mb-1',
  subTitle: 'text-14-22 text-neutral-700',
  link: 'mt-4 md:my-auto',
};
