import { useMemo } from 'react';

import useDirectorEmailValidation from './useDirectorEmailValidation';
import useDirectorMobileValidation from './useDirectorMobileValidation';

const ContainerComponent = ({ children, ...props }) => {
  const {
    first_name: firstName,
    last_name: lastName,
    director_info: directorInfo,
    arrayName,
    index,
    isMe,
    onRemove,
    onClick,
    isLoading,
  } = props;

  const name = `${firstName} ${lastName}`;

  const show = Boolean(directorInfo);

  const label = useMemo(() => {
    if (isMe == null) return 'This is me';
    if (isMe === index) return "This isn't me";
    return '';
  }, [index, isMe]);

  const buttonProps = {
    label,
    onClick: () =>
      isMe === index ? onRemove(arrayName, index) : onClick(arrayName, index),
  };

  const emailValidation = useDirectorEmailValidation(index);
  const mobileValidation = useDirectorMobileValidation(index);

  return children({
    name,
    number: index + 1,
    buttonProps,
    arrayName,
    index,
    firstName,
    show,
    emailValidation,
    mobileValidation,
    isLoading,
  });
};

ContainerComponent.displayName =
  'SetupCompany-DirectorInfo-Form-Limited-Card-container';

export default ContainerComponent;
