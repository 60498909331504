import useCurrentCompany from 'hooks/useCurrentCompany';
import { useMemo } from 'react';
import { useWatch } from '@kanda-libs/ks-component-ts';

const ContainerComponent = ({ children }) => {
  const { company } = useCurrentCompany();
  const formValues = useWatch();

  const isLoading = !company;

  const hasCommsPrefs = useMemo(() => {
    if (!formValues?.company?.users) return false;
    return formValues?.company?.users?.some((user) => user?.comm_preferences);
  }, [formValues]);

  return children({ isLoading, hasCommsPrefs });
};

ContainerComponent.displayName =
  'NotificationPreferences-Content-Desktop-Rows-container';

export default ContainerComponent;
