import { Icon, Text } from '@kanda-libs/ks-design-library';

import Container from './Mobile-container';
import PaymentInfoModal from './PaymentInfoModal';

const ViewComponent = () => (
  <Container>
    {({ onClick }) => (
      <>
        <button
          type="button"
          onClick={onClick}
          className="flex flex-row justify-between"
        >
          <p className="hidden">payment-info</p>
          <div className="flex flex-col my-auto text-left">
            <Text
              text="Payment info"
              className="text-16-20-em text-neutral-700 mb-0.5"
            />
            <Text
              text="Manage your payment details"
              className="text-14-22 text-neutral-600"
            />
          </div>
          <Icon
            icon="chevron-right"
            stroke="neutral-500"
            size={20}
            className="ml-3 my-auto"
          />
        </button>
        <PaymentInfoModal />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Subscription-Content-PaymentInfo-Mobile-view';

export default ViewComponent;
