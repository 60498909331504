// Possible billing states
export const BILLING_LEGACY = 'legacy';
export const BILLING_TRIAL = 'trial';
export const BILLING_PENDING = 'pending';
export const BILLING_SUBSCRIBED = 'subscribed';
export const BILLING_RECURRING_PAYMENT_FAILED_1 = 'recurring_payment_failed_1';
export const BILLING_RECURRING_PAYMENT_FAILED_2 = 'recurring_payment_failed_2';
export const BILLING_RECURRING_PAYMENT_FAILED_3 = 'recurring_payment_failed_3';
export const BILLING_SUSPENDED = 'suspended';
export const BILLING_CANCELLED = 'cancelled';
