import { Text, Tag, Price, Button } from '@kanda-libs/ks-design-library';
import type { Payment } from '@kanda-libs/ks-frontend-services';

import Container from './Payment-container';

export interface PaymentProps {
  payment: Payment;
}

const ViewComponent = (props: PaymentProps) => (
  <Container {...props}>
    {({ reference, status, color, price, dueText }) => (
      <div className="flex flex-row justify-between border-t border-neutral-300 first:border-t-0 py-4">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <Text
              text={reference}
              className="text-14-22-em text-neutral-600 mb-0.5 w-full max-w-24 md:max-w-36 mr-6"
            />
            <Tag label={status} color={color} variant="solid" size={21} />
          </div>
          <Text text={dueText} className="text-12-18 text-neutral-500" />
        </div>
        <div className="flex flex-row mb-auto h-6">
          <Price
            amount={price}
            poundsClassName="text-16-20-em"
            centsClassName="text-10-14-em"
            wrapperClassName="mr-4 md:mr-10"
          />
          <Button.Icon
            id="account-settings-payment-download"
            icon="download"
            size="32-20"
            className="-mt-1"
          />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'Subscription-Content-History-Payments-Payment-view';

export default ViewComponent;
