import { Text, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Container from './Content-container';
import { CompanyForm } from './components';
import { TITLE, CLASS_NAMES } from '../../CompanyInformation-constants';

const ViewComponent = () => (
  <Container>
    {({ isLoading, showForm, form, onSubmit, company, isSubmitting }) => (
      <div className="flex flex-col">
        <Text
          isLoading={isLoading}
          text={TITLE}
          className={CLASS_NAMES.title}
          skeletonProps={{
            width: 200,
          }}
        />
        <Form
          id="account-settings-company-info"
          form={form}
          onSubmit={onSubmit}
        >
          {showForm && (
            <CompanyForm company={company} isSubmitting={isSubmitting} />
          )}
        </Form>
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyInformation-Content-view';

export default ViewComponent;
