import { useForm } from '@kanda-libs/ks-component-ts';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useCurrentCompany from 'hooks/useCurrentCompany';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback, useEffect, useMemo } from 'react';

const getTradeTypeName = (company: Company): string => {
  const config = company?.business_config;
  if (!config) return 'undefined';
  if (config.trade_type !== 'other_trade') return config.trade_text;
  if (!config.trade_text.toLowerCase().includes('not listed'))
    return config.trade_text;
  return config?.extra || config.trade_text;
};

export default function useStandardTradeInfo() {
  const { data, handleContinue } = useOnboardingContextValue();
  const { company } = useCurrentCompany();

  const defaultValues = useMemo(
    () => ({
      company: {
        ...company,
        company_info: {
          ...company?.company_info,
          ...(company?.business_config?.trade_type
            ? {
                trade_type: company.business_config.trade_type,
                trade_type_name: getTradeTypeName(company),
              }
            : {}),
        },
      },
    }),
    [company],
  );

  const form = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (submittedData) => {
      const formattedData = {
        ...data,
        company: {
          company_info: {
            ...(company?.company_info || {}),
            ...(submittedData?.company?.company_info || {}),
          },
          ...((submittedData?.company?.sole_trader_info ||
            company?.sole_trader_info) && {
            sole_trader_info: {
              ...(company?.sole_trader_info || {}),
              ...(submittedData?.company?.sole_trader_info || {}),
            },
          }),
        },
      };
      handleContinue(formattedData);
    },
    [company, data, handleContinue],
  );

  useEffect(() => form.reset(defaultValues), [form, defaultValues]);

  return {
    form,
    onSubmit,
  };
}
