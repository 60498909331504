import {
  Button,
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  DesktopLayoutDefaultContentHeader,
} from '@kanda-libs/ks-design-library';
import { URLS } from 'config';

import {
  FinanceApplicationProgress,
  Items,
  JobHeader,
  Notes,
  PaymentAndFinanceOptions,
} from '../components';
import { CLASS_NAMES } from './Desktop-constants';

import { BackLink } from '../../../components';

const ViewComponent = ({}) => (
  <DesktopLayoutDefault>
    <DesktopLayoutDefaultContentHeader
      option={
        <BackLink backURL={URLS.jobs}>
          {({ handleClick }) => (
            <Button.Icon
              id="job-details-desktop-back-button"
              icon="arrow-left"
              onClick={handleClick}
              className="-mt-4"
            />
          )}
        </BackLink>
      }
    >
      <JobHeader />
    </DesktopLayoutDefaultContentHeader>
    <DesktopLayoutDefaultContent>
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.content}>
          <Items />
          <Notes />
        </div>
        <div className={CLASS_NAMES.side}>
          <FinanceApplicationProgress />
          <PaymentAndFinanceOptions />
        </div>
      </div>
    </DesktopLayoutDefaultContent>
  </DesktopLayoutDefault>
);

ViewComponent.displayName = 'Job-Desktop-view';

export default ViewComponent;
