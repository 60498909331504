import SignUpProvider from './context';
import SignUpForm from './Form';

const SignUp: React.FunctionComponent = function () {
  return (
    <SignUpProvider>
      <SignUpForm />
    </SignUpProvider>
  );
};

export default SignUp;
