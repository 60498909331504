export const ONBOARDING_STEPS = {
  SPLASH: 'splash',
  WELCOME: 'welcome',
  SETUP_YOUR_SUBSCRIPTION: 'setup-your-subscription',
  VERIFICATION: 'verification',
  IDENTIFICATION: 'identification',
  SETUP_COMPANY: 'setup-company',
};

export const STEPS = Object.values(ONBOARDING_STEPS);

export const CLASS_NAMES = {
  page: 'flex flex-1 flex-col justify-center items-center h-full md:px-8 md:py-16',
  content: 'flex flex-col items-center',
  title: 'text-24-28-em text-neutral-900 text-center mb-6 ',
  description: 'text-16-24 text-neutral-600 text-center',
  icon: 'mb-8',
};

export const ICON_PROPS = {
  size: 104,
  className: CLASS_NAMES.icon,
};

export const INITIAL_DATA = {
  jobType: 'kanda',
};
