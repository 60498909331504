import { useWatch, ValidationItems } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import {
  TYPE_NAME,
  DEPOSIT_TYPES,
  QUOTED_TO_NAME,
} from '../../Fields-constants';

import { calculateJobTotal } from '../../../../../../../utils';
import { getFixedDepositValidation } from './Fixed-helpers';

export interface ContainerComponentPropsChildrenArgs {
  isLoading: boolean;
  showFields: boolean;
  validation: ValidationItems;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentPropsChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    data: { isLoading = false },
  } = useCreateJobContextValue();

  const [lineItems, depositType, quotedTo] = useWatch({
    name: ['job.job_items', TYPE_NAME, QUOTED_TO_NAME],
  });

  const { totalIncVat } = calculateJobTotal(lineItems);

  const isQuote = !!quotedTo;

  const validation = getFixedDepositValidation(totalIncVat, isQuote);

  const showFields = depositType === DEPOSIT_TYPES.fixedDeposit;

  return children({
    isLoading,
    showFields,
    validation,
  });
};

ContainerComponent.displayName =
  'CreateJob-Desktop-Deposit-Fields-Fixed-container';

export default ContainerComponent;
