import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const EDIT_BUTTON_PROPS = {
  id: 'job-summary-edit',
  variant: 'ghost-grey' as ButtonIconVariant,
  size: '28-16' as unknown as number,
  icon: 'edit',
  stroke: 'neutral-600',
  className: 'absolute -right-1.5 -top-1.5',
};
