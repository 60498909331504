import { Layout } from '@kanda-libs/ks-design-library';

import TrainingBanner from 'components/TrainingBanner';
import { CompanyBanner } from '../../MyAccount/components';
import { Banner } from '../components';
import { Header } from './components';
import Container from './Mobile-container';

const ViewComponent = ({}) => (
  <Container>
    {({ Content }) => (
      <Layout stickyFooter noBorder stickyHeader scrollTop header={<Header />}>
        <CompanyBanner homeBanner />
        <Banner />
        <TrainingBanner />
        {Content && <Content />}
      </Layout>
    )}
  </Container>
);

ViewComponent.displayName = 'Jobs-Mobile-view';

export default ViewComponent;
