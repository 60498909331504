import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { Icon } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useEffect } from 'react';

import { VERIFY_TEXT } from './Verify-constants';

const ViewComponent = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'company-info');
    window.history.pushState({}, '', `${url}?step=verify`);
    logEvent('page-view');
  }, [logEvent]);

  return (
    <div className="flex flex-col flex-1 w-full px-6 mx-auto -mt-6 md:mt-0 md:flex-row md:p-16 lg:p-20 max-w-256">
      <div className="flex flex-col justify-center w-full h-full mx-auto -mt-10 max-w-96">
        <Icon icon="send-quote-color" size={104} className="mx-auto mb-8" />
        <span className="mb-4 text-center text-24-28-em">
          {VERIFY_TEXT.heading}
        </span>
        <span className="text-center text-16-24 text-neutral-600">
          {VERIFY_TEXT.subheading}
        </span>
      </div>
    </div>
  );
};

ViewComponent.displayName = 'Verify-view';

export default ViewComponent;
