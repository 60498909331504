import { useMemo } from 'react';
import type { ValidationItems } from '@kanda-libs/ks-component-ts';

import useCurrentCompany from '../../../../../../../../../../../hooks/useCurrentCompany';
import useSolarElectricalTradeBodyNumberValidation from '../../../../../../../../../../../hooks/useSolarElectricalTradeBodyNumberValidation';

interface SolarInfoFormHook {
  validation: ValidationItems;
  isSoleTrader: boolean;
}

export default function useSolarInfoForm(): SolarInfoFormHook {
  const { company } = useCurrentCompany();

  const isSoleTrader = useMemo(() => {
    if (!company) return false;
    return company?.company_type === 'sole_trader';
  }, [company]);

  const validation = useSolarElectricalTradeBodyNumberValidation();

  return {
    validation,
    isSoleTrader,
  };
}
