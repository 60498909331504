import VerifyingYourIdentity from './VerifyingYourIdentity';
import DirectorInfo from '../../../DirectorInfo';
import Identify from './Identify';
import VerificationStatus from './VerificationStatus';

import { ONBOARDING_STEPS } from '../../AccountVerificationExplanation-constants';

export default {
  [ONBOARDING_STEPS.VERIFYING_YOUR_IDENTITY]: VerifyingYourIdentity,
  [ONBOARDING_STEPS.VALIDATE_DIRECTOR_INFO]: DirectorInfo,
  [ONBOARDING_STEPS.IDENTIFY]: Identify,
  [ONBOARDING_STEPS.VERIFICATION_STATUS]: VerificationStatus,
};
