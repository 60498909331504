import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { AuthUser } from '@kanda-libs/ks-frontend-services';

export const userHasLoggedInWithSocialAuth = (user: AuthUser | null): boolean =>
  ['google.com', 'facebook.com'].indexOf(user?.provider || '') !== -1;

export const extractErrorMessage = (
  error: StringIndexedObject | string,
): string => {
  if (typeof error === 'string') return error;
  if (!error.message) return 'Unknown error';
  const mapping = error.message
    .split(', ')
    .reduce((errorObj: StringIndexedObject, part: string) => {
      const parts = part.split('=');
      if (parts.length !== 2) return errorObj;
      return {
        ...errorObj,
        [parts[0]]: parts[1],
      };
    }, {});
  if (Object.keys(mapping).length === 0) return 'Unknown error';
  if (!mapping.message) return 'Unknown error';
  return mapping.message;
};
