import { Text } from '@kanda-libs/ks-design-library';
import OnboardingTitle from 'components/OnboardingTitle';
import type { FunctionComponent } from 'react';
import LegalTextBox from './LegalTextBox';

const Legal: FunctionComponent = function () {
  return (
    <div className="flex flex-col gap-y-2 max-w-120">
      <OnboardingTitle>Add legal language to your website</OnboardingTitle>
      <Text
        text="To offer finance with Kanda, you are required to have the following legal language at the bottom of every page of your website. Please add this to your website’s footer:"
        className="my-6 block"
      />
      <LegalTextBox />
    </div>
  );
};

export default Legal;
