import { useContext, useRef, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import {
  BASE_CLASSNAME,
  FOCUS_CLASSNAME,
  UNFOCUS_CLASSNAME,
} from './Mobile-constants';

import Context from '../../../Context';

const ContainerComponent = ({ children, index, showRemove, remove }) => {
  // Focus and setFocus from context
  const { focus, setFocus } = useContext(Context);

  // Reference for item, aides div click function below
  const itemRef = useRef<HTMLInputElement | null>(null);

  // Boolean check for if in focus
  const inFocus = focus === index;

  // Form variable - determines form from focus
  const theme = inFocus ? 'narrow' : 'narrow-unfocus';

  // Classname generator for base div
  const className = clsx(
    BASE_CLASSNAME,
    inFocus ? FOCUS_CLASSNAME : UNFOCUS_CLASSNAME,
  );

  const removeButtonClassName = showRemove ? 'block' : 'hidden';

  /**
   * Handles remove
   */
  const handleRemove = () => remove(index);

  const onClick = useCallback(() => setFocus(index), [setFocus, index]);

  useEffect(() => {
    if (!itemRef.current) return () => {};
    const element = itemRef.current;
    element.addEventListener('click', onClick);
    element.addEventListener('onKeyDown', onClick);
    element.addEventListener('onTouch', onClick);
    return () => {
      element.removeEventListener('click', onClick);
      element.addEventListener('onKeyDown', onClick);
      element.addEventListener('onTouch', onClick);
    };
  }, [onClick]);

  return children({
    itemRef,
    theme,
    inFocus,
    className,
    removeButtonClassName,
    handleRemove,
  });
};

ContainerComponent.displayName = 'Item-Mobile--container';

export default ContainerComponent;
