import { Button, Card, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import {
  BUTTON_PROPS,
  CLASS_NAMES,
  DESCRIPTION,
  ICON_PROPS,
  TITLE,
} from './JobsPlaceholder-constants';
import Container from './JobsPlaceholder-container';

import { URLS } from '../../../../../config';

const ViewComponent = ({}) => (
  <Container>
    {({ showBanner }) => (
      <div className="flex my-auto">
        <Card>
          <div className={CLASS_NAMES.content}>
            <Icon {...ICON_PROPS} />
            <p className={CLASS_NAMES.title}>{TITLE}</p>
            {showBanner && (
              <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
            )}
            <Link to={URLS.createJob}>
              <Button.Text {...BUTTON_PROPS} />
            </Link>
          </div>
        </Card>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Jobs-Desktop-NoResults-view';

export default ViewComponent;
