import type { TableHeaderColumn } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';

import { CustomerName, PriceFilter } from './components';
import { Activity } from '../../../components';
import { PriceStatus } from '../../../../../components';

export const CLASS_NAMES = {
  table: '-mx-8',
};

export const TABLE_COLUMNS: Partial<TableHeaderColumn>[] = [
  {
    Header: 'Customer',
    accessor: 'customerDetails',
    subAccessors: ['status', 'flowType', 'quotedTo'],
    items: [
      {
        action: {
          type: 'customer-alphabetical',
        },
        icon: 'alphabet',
        name: 'Sort alphabetically',
      },
    ],
    renderComponent: {
      component: CustomerName,
      props: {
        className: {
          draft:
            'text-style-f-em text-neutral-900 overflow-hidden overflow-ellipsis draft-width',
          standard:
            'text-style-f-em text-neutral-900 overflow-hidden overflow-ellipsis customer-width',
        },
      },
      value: 'customer',
      optionalProps: ['status', 'flowType', 'quotedTo'],
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    subAccessors: ['status', 'financeStatus'],
    items: [
      {
        action: {
          type: 'open_panel',
          panel: PriceFilter,
        },
        icon: 'filter',
        name: 'Filter by price',
      },
      {
        action: {
          type: 'price-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'price-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    width: 120,
    renderComponent: {
      component: PriceStatus,
      value: 'total',
      optionalProps: ['status', 'financeStatus'],
    },
  },
  {
    Header: 'Job title',
    accessor: 'title',
    items: [],
    renderComponent: {
      component: Text,
      value: 'text',
      props: {
        className: 'text-14-22 text-neutral-700',
      },
    },
    width: 320,
    minWidth: 200,
    maxWidth: 2000,
  },
  {
    Header: 'Last Activity',
    accessor: 'updatedAt',
    items: [
      {
        action: {
          type: 'activity-ascending',
        },
        icon: 'sort-ascending',
        name: 'Sort ascending',
      },
      {
        action: {
          type: 'activity-descending',
        },
        icon: 'sort-descending',
        name: 'Sort descending',
      },
    ],
    subAccessors: ['status', 'financeStatus', 'archived', 'viewedFinance'],
    width: 184,
    renderComponent: {
      component: Activity,
      value: 'date',
      optionalProps: ['status', 'financeStatus', 'archived', 'viewedFinance'],
    },
  },
];

export const DEFAULT_COLUMN: Partial<TableHeaderColumn> = {
  width: 200,
  minWidth: 80,
  maxWidth: 400,
};
