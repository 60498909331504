import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { URLS } from 'config';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useCurrentCompany } from 'hooks';
import { LENDER_DISPLAY_RATES } from './constants';

export interface RatesHook {
  rates: FinanceRate[] | undefined;
  onClick: () => void;
}

export default function useRates(): RatesHook {
  const { push } = useHistory();
  const { company } = useCurrentCompany();

  const rates = useMemo(() => {
    if (!company) return LENDER_DISPLAY_RATES.propensio.regulated;
    const lender = company?.business_config?.lender;
    const lenderType = company?.business_config?.lender_rate_type;
    if (!lender || !lenderType) return LENDER_DISPLAY_RATES.propensio.regulated;
    return (
      LENDER_DISPLAY_RATES?.[lender]?.[lenderType] ||
      LENDER_DISPLAY_RATES.propensio.regulated
    );
  }, [company]);

  const onClick = useCallback(() => push(URLS.setupSubscription), [push]);

  return {
    rates,
    onClick,
  };
}
