import { useFormContext } from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library/src/types';
import { useCallback, useMemo } from 'react';

import type { RowProps } from './Row-view';

export interface ContainerComponentChildrenArgs {
  name: string;
  role: string;
  permissions: boolean[];
  isLoading?: boolean;
  fieldProps: StringIndexedObject;
  id: string;
}

export interface ContainerComponentProps extends RowProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const getName = (firstName?: string, lastName?: string) =>
  `${firstName || '--'} ${lastName}`;

const getRole = (role?: string) => {
  if (!role) return '--';
  if (role === 'company-admin') return 'Admin';
  if (role === 'company-manager') return 'Manager';
  return 'Member';
};

const getRolePermissions = (role) => {
  if (role === 'company-admin') return [true, true, true, true, true];
  if (role === 'company-manager') return [true, true, true, false, false];
  return [true, false, false, false, false];
};

const ContainerComponent = ({
  children,
  field,
  isLoading,
  arrayName,
  index,
  id,
}: ContainerComponentProps) => {
  const { getValues, setValue } = useFormContext();

  const name = useMemo(
    () => getName(field?.first_name, field?.last_name),
    [field],
  );
  const role = useMemo(() => getRole(field?.role), [field]);
  const permissions = useMemo(() => getRolePermissions(field?.role), [field]);

  const handleClick = useCallback(
    (prefType: string) => {
      const fieldName = `${arrayName}.${index}.comm_preferences.${prefType}`;
      const state = getValues(fieldName);
      if (state === 'enabled') {
        setValue(fieldName, 'disabled');
        return;
      }
      setValue(fieldName, 'enabled');
    },
    [getValues, setValue, arrayName, index],
  );

  const fieldProps = useMemo(
    () => ({
      preJob: {
        name: `${arrayName}.${index}.comm_preferences.pre_job`,
        id: `pre-job-notifications-user-${index}-select`,
        defaultChecked: field?.comm_preferences?.pre_job === 'enabled',
        onClick: () => handleClick('pre_job'),
      },
      postJob: {
        name: `${arrayName}.${index}.comm_preferences.post_job`,
        id: `post-job-notifications-user-${index}-select`,
        defaultChecked: field?.comm_preferences?.post_job === 'enabled',
        onClick: () => handleClick('post_job'),
      },
      other: {
        name: `${arrayName}.${index}.comm_preferences.other`,
        id: `other-notifications-user-${index}-select`,
        defaultChecked: field?.comm_preferences?.other === 'enabled',
        onClick: () => handleClick('other'),
      },
    }),
    [arrayName, index, handleClick, field],
  );

  return children({
    name,
    role,
    permissions,
    isLoading,
    fieldProps,
    id,
  });
};

ContainerComponent.displayName =
  'NotificationPreferences-Content-Desktop-Rows-Row-container';

export default ContainerComponent;
