import {
  Button,
  DesktopLayoutBoxedContentHeader,
} from '@kanda-libs/ks-design-library';
import { ONBOARDING_STEPS } from '../../../../../Onboarding-constants';

interface ContentHeaderProps {
  /**
   * prev step function
   */
  setStep: (step: string) => void;
  /**
   * prev step function
   */
  step?: number;
}

const ContentHeader: React.FunctionComponent<ContentHeaderProps> = ({
  setStep,
  step = 0,
}) => (
  <DesktopLayoutBoxedContentHeader>
    <>
      {step > 0 && (
        <Button.Icon
          id="onboarding-back"
          icon="arrow-left"
          className="absolute"
          onClick={() => {
            if (step === 1) {
              setStep(ONBOARDING_STEPS.SPLASH);
              return;
            }
            setStep(ONBOARDING_STEPS.WELCOME);
          }}
        />
      )}
    </>
  </DesktopLayoutBoxedContentHeader>
);

export default ContentHeader;
