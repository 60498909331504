import { BreakPoints, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import useLogin from './useLogin';
import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../components';

const Login = () => {
  const { form, onSubmit, isSubmitting, ...restProps } = useLogin();

  return (
    <>
      <Helmet title="Login" />
      <Form id="login" form={form} onSubmit={onSubmit}>
        <BreakPoints
          mobile={<Mobile {...restProps} />}
          desktop={<Desktop {...restProps} />}
        />
        <Loader isLoading={isSubmitting} />
      </Form>
    </>
  );
};

Login.displayName = 'Login-view';

export default Login;
