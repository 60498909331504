import { useMemo } from 'react';

import { TYPE } from './TradeInfo-constants';

import useCurrentCompany from '../../../../../../../../../hooks/useCurrentCompany';

export default function useCompanyIsSolar() {
  const { company, isLoading } = useCurrentCompany();

  const companyType = useMemo(() => {
    if (isLoading) return null;
    return company?.referral_code === 'SOLAR1' ? TYPE.SOLAR : TYPE.STANDARD;
  }, [company, isLoading]);

  return {
    companyType,
  };
}
