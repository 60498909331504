export const CLASS_NAMES = {
  container: 'flex flex-col mb-4 z-40',
  header: 'flex flex-row justify-between mb-6 items-center',
  options: 'flex flex-row',
  info: 'flex flex-row md:mb-2 border border-neutral-300 rounded-lg md:border-0',
};

export const BANNER_TYPE = {
  LOADING: 'loading',
  EMPTY: 'empty',
  FILTER: 'filter',
};

export const BANNER_CLASS_NAMES = {
  container:
    'flex flex-col border-r w-1/2 p-3 last:border-0 md:p-4 md:w-1/3 md:border border-neutral-300 md:rounded-lg md:mr-4 md:last:border',
  title: 'text-style-h text-neutral-600 mb-1 md:text-style-g',
  text: 'text-style-e text-neutral-900 md:text-style-d',
};

export const BANNER_SKELETONS = {
  title: {
    width: 120,
    height: 16,
  },
  text: {
    width: 90,
    height: 20,
  },
};
