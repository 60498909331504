import SetupSubscriptionContent from './Content';
import SubscriptionProvider from './context';

const SetupSubscription: React.FunctionComponent = function () {
  return (
    <SubscriptionProvider>
      <SetupSubscriptionContent />
    </SubscriptionProvider>
  );
};

export default SetupSubscription;
