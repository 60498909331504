// Finance statuses:
// - not_applied
// - under_review
// - applied_for_finance
// - finance_deposit_paid
// - finacne_not_approved
// - financed
// - sat_note_sent
// - sat_note_viewed
// - sat_note_signed
// - paid

// Statuses:
// - draft
// - sent
// - accepted
// - declined
// - archived

const financeColor = (financeStatus) => {
  if (!financeStatus) return 'neutral-600';
  if (financeStatus === 'not_applied') return 'neutral-600';
  if (financeStatus === 'finance_not_approved') return 'violet-200';
  if (
    financeStatus === 'under_review' ||
    financeStatus === 'applied_for_finance'
  )
    return 'blue-200';
  if (
    financeStatus === 'finance_deposit_paid' ||
    financeStatus === 'financed' ||
    financeStatus === 'sat_note_sent' ||
    financeStatus === 'sat_note_viewed' ||
    financeStatus === 'sat_note_signed' ||
    financeStatus === 'payout_pending' ||
    financeStatus === 'paid'
  )
    return 'green-500';
  return 'neutral-600';
};

export const getColor = (status, financeStatus) => {
  if (!status) return 'neutral-600';
  if (status === 'draft') return 'neutral-600';
  if (
    status === 'sent' ||
    status === 'accepted' ||
    status === 'archived' ||
    status === 'finished'
  )
    return financeColor(financeStatus);
  if (status === 'declined') return 'violet-200';
  return 'neutral-600';
};

export const getFinanceStatus = (financeStatus) => {
  const financed =
    financeStatus === 'under_review' ||
    financeStatus === 'applied_for_finance' ||
    financeStatus === 'finance_deposit_paid' ||
    financeStatus === 'finance_not_approved' ||
    financeStatus === 'financed' ||
    financeStatus === 'sat_note_sent' ||
    financeStatus === 'sat_note_viewed' ||
    financeStatus === 'sat_note_signed' ||
    financeStatus === 'paid' ||
    financeStatus === 'payout_pending';
  const paid = financeStatus === 'paid';
  return {
    financed,
    paid,
  };
};
