import { Icon, SkeletonLoader, Text } from '@kanda-libs/ks-design-library';

import Container from './CompanyLogo-container';

const ViewComponent = () => (
  <Container>
    {({ isLoading, dataUrl, alt, onClick }) => (
      <div className="flex flex-col mb-6">
        <Text
          text="Company Logo"
          isLoading={isLoading}
          className="text-12-18-em text-neutral-600 mb-2"
          skeletonProps={{
            width: 100,
          }}
        />
        <div className="rounded rounded-xl border border-green-500 p-6">
          <SkeletonLoader
            isLoading={isLoading}
            width={150}
            height={100}
            afterLoading={
              <div className="flex flex-row justify-between">
                <img src={dataUrl} alt={alt} className="w-28" />
                <button
                  type="button"
                  className="my-auto w-6 h-6 bg-neutral-600 rounded-full"
                  onClick={onClick}
                >
                  <Icon
                    icon="close"
                    stroke="neutral-000"
                    size={12}
                    className="m-auto"
                  />
                  <p className="hidden">close-logo</p>
                </button>
              </div>
            }
          />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLogo-view';

export default ViewComponent;
