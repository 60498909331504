import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';

import { DeleteModal, Existing, New, NotificationsModal } from './components';
import Container from './Users-container';

export interface ContentUsersProps {
  fields: {
    id: string;
    state: string;
    index: number;
  }[];
  arrayName: string;
  defaultValues: StringIndexedObject;
  remove?: (index: number) => void;
  showRemove?: boolean;
  isSubmitting: boolean;
  canUserEdit: boolean;
  prefillNewUser?: boolean;
  append?: (value: StringIndexedObject) => void;
}

const ViewComponent = ({ prefillNewUser, ...props }: ContentUsersProps) => (
  <Container {...props} prefillNewUser={prefillNewUser}>
    {({
      existingUsers,
      newUsers,
      arrayName,
      showUpdate,
      showNewUsers,
      isSubmitting,
      canUserEdit,
      userToDelete,
      setUserToDelete,
      remove,
      ...rest
    }) => (
      <>
        {!prefillNewUser && (
          <div className="flex flex-col w-full md:w-auto">
            {existingUsers.map((user) => (
              <Existing
                key={user.id}
                user={user as unknown as UserType}
                arrayName={arrayName}
                canUserEdit={canUserEdit}
                setUserToDelete={
                  setUserToDelete as unknown as (currentUser: UserType) => void
                }
              />
            ))}
          </div>
        )}
        {canUserEdit && showNewUsers && (
          <New
            users={newUsers}
            arrayName={arrayName}
            remove={remove}
            prefillNewUser={prefillNewUser}
            {...rest}
          />
        )}
        {showUpdate && canUserEdit && (
          <Button.Text
            id="team-settings-update"
            label="Update users"
            size={40}
            className="mt-6 mr-auto"
            left
            icon="check"
            submit
            disabled={isSubmitting}
          />
        )}
        <DeleteModal userToDelete={userToDelete} />
        <NotificationsModal userToManage={userToDelete} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Users-view';

export default ViewComponent;
