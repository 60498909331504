import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Notes-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = () => (
  <Container>
    {({ showNotes, note }) =>
      showNotes && (
        <BreakPoints
          mobile={<Mobile note={note} />}
          desktop={<Desktop note={note} />}
        />
      )
    }
  </Container>
);

ViewComponent.displayName = 'Job-Notes-view';

export default ViewComponent;
