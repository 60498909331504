import { Banner, Icon, Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';

const InviteBanner: FunctionComponent = function () {
  return (
    <div className="mt-8 w-full max-w-120 md:max-w-full mx-auto">
      <Banner hideBackgroundOnMobile>
        <div className="items-center w-full grid grid-cols-6">
          <div className="flex flex-row w-full grow col-span-6 md:col-span-4">
            <Icon
              icon="user-square-color"
              width={75}
              height={50}
              className="mr-4 my-auto"
            />
            <div className="flex flex-col w-full grow">
              <Text
                text="Invite members to join Kanda and earn rewards"
                className="mb-1 text-18-22-em text-neutral-900"
              />
              <Text
                text="Users you invite will get £50 off a yearly subscription - once they're approved with Kanda, we'll give you £50 as well!"
                className="text-14-22 text-neutral-700"
              />
            </div>
          </div>
        </div>
      </Banner>
    </div>
  );
};

export default InviteBanner;
