import { Button, Loader, Text } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import Container from './LoginDetails-container';

import { URLS } from '../../../../../../config';

const ViewComponent = () => (
  <Container>
    {({
      isLoading,
      email,
      password,
      socialProvider,
      disableEmail,
      emailClick,
      isSubmitting,
    }) => (
      <div className="flex flex-col">
        <p className="hidden mb-6 md:block text-16-20-em">Login Details</p>
        <div className="overflow-hidden border border-neutral-300 rounded-xl shadow-card">
          <div className="flex flex-row justify-between p-6">
            <div className="flex flex-col">
              <p className="text-16-20-em text-neutral-700 mb-0.5">Email</p>
              <Text
                isLoading={isLoading}
                text={email}
                className="text-14-22 text-neutral-600"
              />
            </div>
            {!disableEmail && (
              <Button.Text
                id="account-settings-send-verification"
                onClick={emailClick}
                label="Send verification"
                className="my-auto"
                variant="outline"
                size={40}
                disabled={isSubmitting}
              />
            )}
          </div>
          <div className="flex flex-row justify-between p-6 border-t border-neutral-300">
            <div className="flex flex-col">
              <p className="text-16-20-em text-neutral-700 mb-0.5">Password</p>
              <Text
                isLoading={isLoading}
                text={password}
                className="text-14-22 text-neutral-600"
              />
            </div>
            {!socialProvider && (
              <Link to={URLS.changePassword} className="my-auto">
                <Button.Text
                  id="account-settings-change-password"
                  label="Change password"
                  variant="outline"
                  size={40}
                />
              </Link>
            )}
          </div>
        </div>
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'MyAccount-AccountSettings-LoginDetails-view';

export default ViewComponent;
