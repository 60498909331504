export const PLACEHOLDER = [
  {
    reference: '#2022-0005',
    status: 'unpaid',
    due: '2022-07-14T09:57:54.753025568Z',
    price: 24400,
  },
  {
    reference: '#2022-0004',
    status: 'paid',
    due: '2022-06-14T09:57:54.753025568Z',
    price: 3000,
  },
  {
    reference: '#2022-0003',
    status: 'paid',
    due: '2022-05-14T09:57:54.753025568Z',
    price: 3000,
  },
  {
    reference: '#2022-0002',
    status: 'paid',
    due: '2022-04-14T09:57:54.753025568Z',
    price: 3000,
  },
  {
    reference: '#2022-0001',
    status: 'paid',
    due: '2022-03-14T09:57:54.753025568Z',
    price: 3000,
  },
];
