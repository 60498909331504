import { Icon, Text } from '@kanda-libs/ks-design-library';

import HistoryModal from './HistoryModal';
import Container from './Mobile-container';

const ViewComponent = ({ ...props }) => (
  <Container>
    {({ onClick }) => (
      <>
        <button
          type="button"
          onClick={onClick}
          className="flex flex-row justify-between mb-6"
        >
          <p className="hidden">billing-history</p>
          <div className="flex flex-col my-auto text-left">
            <Text
              text="Billing history"
              className="text-16-20-em text-neutral-700 mb-0.5"
            />
            <Text
              text="View your invoices from Kanda"
              className="text-14-22 text-neutral-600"
            />
          </div>
          <Icon
            icon="chevron-right"
            stroke="neutral-500"
            size={20}
            className="ml-3 my-auto"
          />
        </button>
        <HistoryModal {...props} />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Subscription-Content-History-Mobile-view';

export default ViewComponent;
