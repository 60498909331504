import { URLS } from 'config';

import type { TrainingBannerItem } from '.';

export const CLASS_NAMES = {
  wrapper: 'p-0 overflow-hidden border-neutral-300',
  header:
    'bg-green-100 px-7 py-8 flex flex-row items-center justify-start gap-x-6',
  icon: 'text-green-400 min-w-12',
  title: 'text-lg',
  items: 'p-4 md:py-8 md:px-2 flex flex-col gap-y-5',
};

export const ITEMS: TrainingBannerItem[] = [
  {
    url: URLS.invite,
    title: 'Invite your team',
    description: 'Add members to your Kanda account',
    welcomePackKey: 'invite_team_member',
    hideComplete: false,
  },
  {
    url: URLS.updateYourWebsite.replace(':id', 'start'),
    title: 'Update your website',
    description: 'Add our legal disclaimer to your website',
    welcomePackKey: 'update_website',
    hideComplete: false,
  },
];
