import { Button, Icon } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from '../../../../../config';

const ViewComponent = () => (
  <div className="flex flex-col w-full m-auto text-center max-w-96">
    <Icon icon="check-circle-color" size={104} className="mx-auto mb-8" />
    <h1 className="mb-4 text-24-28-em">Your password has changed</h1>
    <h2 className="text-16-24 text-neutral-600">
      You can now log in with your new password
    </h2>
    <Link to={URLS.login} className="mt-8">
      <Button.Text
        id="reset-password-confirmation-login"
        label="Continue to login"
        icon="arrow-right"
      />
    </Link>
  </div>
);

ViewComponent.displayName = 'ResetPasswordConfirmation-Content-view';

export default ViewComponent;
