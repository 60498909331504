import type { MutableRefObject } from 'react';
import { Button, Loader, Icon, Text } from '@kanda-libs/ks-design-library';
import { ModalContainer, ModalLayoutCenter } from '@kanda-libs/ks-component-ts';

import Container from './DeleteModal-container';
import { DELETE_USER_MODAL_ID } from '../../Users-constants';

export interface DeleteModalProps {
  userToDelete: number | null;
}

const ViewComponent = (props: DeleteModalProps) => (
  <ModalContainer id={DELETE_USER_MODAL_ID} opacity={30}>
    {({ id, handleClose, ref }) => (
      <Container {...props} handleClose={handleClose}>
        {({ name, email, mobile, role, onClick, isSubmitting }) => (
          <>
            <ModalLayoutCenter
              id={id}
              onClose={handleClose}
              ref={ref as MutableRefObject<HTMLDivElement>}
              className="w-10/12 md:w-160"
            >
              <div className="flex flex-col md:px-28 md:py-10">
                <Icon
                  icon="remove"
                  size={64}
                  stroke="red-200"
                  className="mx-auto mb-6"
                />
                <Text
                  text="Are you sure you want to remove the following user?"
                  className="mb-4 text-center text-20-24-em"
                />
                <Text
                  text={`Name: ${name}`}
                  className="text-14-22 text-neutral-700"
                />
                <Text
                  text={`Email: ${email}`}
                  className="text-14-22 text-neutral-700"
                />
                {mobile && (
                  <Text
                    text={`Mobile: ${mobile}`}
                    className="text-14-22 text-neutral-700"
                  />
                )}
                <Text
                  text={`Role: ${role}`}
                  className="text-14-22 text-neutral-700"
                />
                <Button.Text
                  id="team-settings-users-delete-modal-delete"
                  onClick={onClick}
                  label="Remove user"
                  className="mt-6"
                  disabled={isSubmitting}
                />
                <Button.Text
                  id="team-settings-users-delete-modal-close"
                  onClick={handleClose}
                  label="Cancel"
                  className="mt-4"
                  variant="outline"
                  disabled={isSubmitting}
                />
              </div>
            </ModalLayoutCenter>
            <Loader isLoading={isSubmitting} />
          </>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'CreateJob-CloseModal-view';

export default ViewComponent;
