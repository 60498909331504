export interface AlternateColorTextProps {
  text: string[];
  textSize?: string;
  textColor?: string;
  textDirection?: string;
  alternateTextColor?: string;
  className?: string;
}

const AlternateColourText: React.FunctionComponent<AlternateColorTextProps> =
  function ({
    text,
    textSize = 'text-24-28-em md:text-40-48-em',
    textColor = 'text-neutral-900',
    textDirection = 'text-left',
    alternateTextColor = 'text-green-600',
    className,
  }) {
    const finalClassName = [
      textSize,
      textColor,
      textDirection,
      alternateTextColor,
      className,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <span className={finalClassName}>
        {text.map((snippet: string, index: number) => {
          if (index % 2 === 0) return snippet;
          return (
            <span key={snippet} className="text-green-500">
              {snippet}
            </span>
          );
        })}
      </span>
    );
  };

export default AlternateColourText;
