import Info from './Info';
import FinanceOptions from './FinanceOptions';
import PaymentMethods from './PaymentMethods';
import LeadFinanceOption from './LeadFinanceOption';

export default {
  Info,
  FinanceOptions,
  PaymentMethods,
  LeadFinanceOption,
};
