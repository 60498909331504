export const CLASS_NAMES = {
  container: 'flex flex-col max-w-96 m-auto text-center',
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
};

export const TITLE = 'Verifying your email';

export const DESCRIPTION =
  "We're just verifying your email - this shouldn't take long";

export const INVALID_TITLE = 'This code is invalid';

export const INVALID_DESCRIPTION =
  'Please request a new link from your account settings';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  password: {
    name: 'password',
    label: 'New password',
    placeholder: 'Your new password',
    autoCapitalize: 'off',
    autoCorrect: 'off',
  },
  confirmPassword: {
    name: 'confirmPassword',
    label: 'Confirm new password',
    placeholder: 'Confirm your new password',
    autoCapitalize: 'off',
    autoCorrect: 'off',
  },
};
