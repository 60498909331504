import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';

import { useAppDispatch } from 'components/App';
import useCurrentCompany from 'hooks/useCurrentCompany';
import { selectors } from 'store';

export const SUBSCRIBE_MODAL_ID = 'subscribe-modal';

export default function useReverify() {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();

  const { showSuccess, showError } = useToast();

  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const onClick = useCallback(() => {
    dispatch(
      actions.directorCompany({
        params: { id: company?.id as string },
        onSuccess: (response) => {
          if (!response) {
            showError(
              'Error resending verification emails at this time - please try again later',
            );
            return;
          }
          showSuccess('Verification emails resent');
        },
      }),
    );
  }, [company, showError, showSuccess, dispatch]);

  return { onClick, isSubmitting };
}
