import clsx from 'clsx';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback, useEffect, useMemo } from 'react';
import { ONBOARDING_STEPS } from '../../Onboarding-constants';
import { LOGO_CLASS_NAME } from './constants';

import {
  BILLING_PENDING,
  BILLING_SUSPENDED,
  BILLING_TRIAL,
} from '../../../../constants/subscription';

import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import { allDirectorsVerified } from '../../../../utils';

export interface LayoutHook {
  setStep: ((step: string) => void) | undefined;
  logoProps: {
    className: string;
  };
  step?: number;
}

export default function useLayout(): LayoutHook {
  const { currentStepIndex, setStep } = useOnboardingContextValue();
  const { company, isLoading } = useCurrentCompany();

  const logoProps = useMemo(
    () => ({
      className: clsx(
        LOGO_CLASS_NAME,
        currentStepIndex === 0 ? 'opacity-0' : 'opacity-100',
      ),
    }),
    [currentStepIndex],
  );

  const goToStep = useCallback(
    (step: string) =>
      setTimeout(() => {
        if (setStep) setStep(step);
      }, 10),
    [setStep],
  );

  useEffect(() => {
    if (isLoading || !company) return;
    if (!company?.lifecycle || company?.lifecycle === 'registered') return;
    if (company?.lifecycle === 'subscribed') {
      if (
        [BILLING_TRIAL, BILLING_PENDING, BILLING_SUSPENDED].includes(
          company?.billing as string,
        )
      ) {
        goToStep(ONBOARDING_STEPS.SETUP_YOUR_SUBSCRIPTION);
        return;
      }
    }
    if (
      company?.lifecycle === 'director_info_provided' &&
      !allDirectorsVerified(company)
    ) {
      goToStep(ONBOARDING_STEPS.VERIFICATION);
      return;
    }
    if (company?.lifecycle === 'director_details_submitted') {
      goToStep(ONBOARDING_STEPS.SETUP_COMPANY);
      return;
    }
    if (
      company?.lifecycle === 'onboarded' &&
      !company?.company_info?.insurance_document
    ) {
      goToStep(ONBOARDING_STEPS.SETUP_COMPANY);
      return;
    }
    if (
      company?.lifecycle === 'insurance_uploaded' &&
      !company?.company_info?.contract_agreement
    ) {
      goToStep(ONBOARDING_STEPS.SETUP_COMPANY);
      return;
    }

    goToStep(ONBOARDING_STEPS.WELCOME);
  }, [company, isLoading, goToStep]);

  return {
    setStep,
    logoProps,
    step: currentStepIndex,
  };
}
