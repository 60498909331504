import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect, useState } from 'react';

import { URLS } from '../../../../../../../../config';

export default function useVerificationStatus() {
  const { data } = useOnboardingContextValue();

  const isSingleDirector = data?.isSingleDirector || false;

  const [showStatus, setShowStatus] = useState(!isSingleDirector);

  useEffect(() => {
    setShowStatus(!isSingleDirector);
  }, [isSingleDirector]);

  useEffect(() => {
    const url = URLS?.onboardingSteps?.replace(':id', 'verification');
    window.history.pushState({}, '', `${url}?step=verification-status`);
  }, []);

  return {
    showStatus,
    setShowStatus,
    email: data?.myEmail,
  };
}
