import { Icon, Text } from '@kanda-libs/ks-design-library';

const ViewOnlyNotice: React.FunctionComponent = function () {
  return (
    <div className="sticky top-24 md:top-32 flex flex-row px-3 py-2 md:px-4 md:py-5 mb-3 md:mb-4 bg-green-100 border border-green-400 rounded-lg z-50">
      <Icon
        icon="info"
        stroke="green-400"
        size={16}
        className="mt-px mb-auto mr-3"
      />
      <Text
        className="text-green-400 text-12-18-em"
        text="This document is view only - scroll to the bottom of this page to sign this document"
      />
    </div>
  );
};

export default ViewOnlyNotice;
