import Container from './JobDetails-container';
import { CLASS_NAMES } from './JobDetails-constants';
import {
  Title,
  Customer,
  LineItems,
  Custom,
  EPVSFile,
  Notes,
} from './components';

const ViewComponent = () => (
  <Container>
    {({ custom, isSolar, hasNotes }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.content}>
          <Title />
          <Customer />
          {custom ? <Custom /> : <LineItems />}
          {isSolar && <EPVSFile />}
          {hasNotes && <Notes />}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'JobDetails-view';

export default ViewComponent;
