export const TYPE = {
  SOLAR: 'SOLAR',
  STANDARD: 'STANDARD',
};

export const TITLE = 'Tell us about your business';

export const BULLETS = [
  {
    title: 'Pick your trade',
    subText: 'Select your trade and affiliations if applicable',
  },
  {
    title: 'Job details',
    subText: 'Tell us about the types of jobs you do and how often',
  },
  {
    title: 'Public liability',
    subText: 'You’ll need to upload a copy of your in-date insurance policy',
  },
];
