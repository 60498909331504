import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { ADD_A_JOB_STEPS } from '../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { setStep } = useCreateJobContextValue();

  return children({
    onClick: () => {
      if (setStep) setStep(ADD_A_JOB_STEPS.DETAILS);
    },
  });
};

ContainerComponent.displayName = 'JobDetails-Custom-container';

export default ContainerComponent;
