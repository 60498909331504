import { Icon, SkeletonLoader } from '@kanda-libs/ks-design-library';

import { ICON_PROPS, CLASS_NAMES, SKELETONS } from './Action-constants';

interface ViewComponentProps {
  /**
   * icon
   */
  icon: string;
  /**
   * title
   */
  title: string;
  /**
   * badge
   */
  badge?: number;
  /**
   * isLoading
   */
  isLoading?: boolean;
  /**
   * onClick
   */
  onClick(...args: unknown[]): unknown;
}

const ViewComponent = ({
  icon,
  title,
  badge,
  isLoading,
  onClick,
}: ViewComponentProps) => (
  <button type="button" className={CLASS_NAMES.container} onClick={onClick}>
    <SkeletonLoader
      {...SKELETONS.icon}
      isLoading={isLoading}
      afterLoading={<Icon {...ICON_PROPS} icon={icon} />}
    />
    <p className={CLASS_NAMES.title}>
      <SkeletonLoader
        {...SKELETONS.title}
        isLoading={isLoading}
        afterLoading={title}
      />
    </p>
    {!isLoading && badge && <p className={CLASS_NAMES.badge}>{badge}</p>}
  </button>
);

ViewComponent.displayName = 'Job-Action-view';

ViewComponent.defaultProps = {
  badge: undefined,
  isLoading: undefined,
};

export default ViewComponent;
