import Contract from './Contract';
import PayMonthly from './PayMonthly';
import Subscriptions from './Subscriptions';

const Pages = {
  Subscriptions,
  PayMonthly,
  Contract,
};

export default Pages;
