import { useForm } from '@kanda-libs/ks-component-ts';
import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { getIFrameUrl } from '../../UpdateYourWebsite/Calculator/IFrame/helpers';

export interface CalculatorHook {
  form: ReturnType<typeof useForm>;
  url: string;
}

export default function useCalculator(): CalculatorHook {
  const { company } = useCurrentCompany();
  const form = useForm();

  const url = useMemo(() => getIFrameUrl(company), [company]);

  return {
    form,
    url,
  };
}
