export const getRoleSubtext = (role) => {
  if (role === 'company-admin') return 'Full ownership and control of account';
  if (role === 'company-manager')
    return 'Can see all jobs but can’t edit settings';
  return 'Can only see and send their own jobs';
};

export const getRolePermissions = (role) => {
  if (role === 'company-admin') return [true, true, true, true, true];
  if (role === 'company-manager') return [true, true, true, false, false];
  return [true, false, false, false, false];
};
