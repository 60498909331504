import { Icon, Text, Button } from '@kanda-libs/ks-design-library';

import Container from './Custom-container';

const ViewComponent = () => (
  <Container>
    {({ name }) => (
      <div className="flex flex-row justify-between bg-neutral-000 border rounded-xl border-neutral-300 px-3 py-3.5">
        <div className="flex">
          <Icon
            icon="file"
            stroke="green-300"
            className="mr-3 my-auto"
            size={20}
          />
          <Text text={name} className="text-14-22 text-neutral-700 my-auto" />
        </div>
        <Button.Icon
          id="mobile-custom-quote-line-item"
          icon="chevron-right"
          variant="ghost-grey"
          className="ml-3 my-auto"
          size="28-16"
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Mobile-Custom-view';

export default ViewComponent;
