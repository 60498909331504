import { Button } from '@kanda-libs/ks-design-library';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

import Container from './Form-container';
import { Limited, Sole } from './components';

export interface DirectorInfoFormProps {
  me?: StringIndexedObject;
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const ViewComponent = (props: DirectorInfoFormProps) => (
  <Container {...props}>
    {({ limitedCompany, isSubmitting, ...rest }) => (
      <div className="flex flex-col h-full w-full md:w-7/12 max-w-120">
        {limitedCompany ? <Limited {...rest} /> : <Sole {...rest} />}
        <Button.Text
          id="director-info-confirm"
          label={limitedCompany ? 'Invite & Continue' : 'Confirm & Continue'}
          submit
          disabled={isSubmitting}
          className="mt-6 md:mt-12 mx-auto w-full"
          icon="arrow-right"
          variant="gradient"
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'SetupCompany-DirectorInfo-Form-view';

export default ViewComponent;
