import { Widget } from '@kanda-libs/ks-component-ts';
import { BreakPoints } from '@kanda-libs/ks-design-library';

import {
  PRICE_COMPONENT_PROPS,
  PRICE_COMPONENT_MOBILE_PROPS,
} from './Price-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <BreakPoints
    desktop={
      <Widget.JobJobItemsPriceAmountArrayInput
        {...PRICE_COMPONENT_PROPS}
        {...props}
        name="price.amount"
      />
    }
    mobile={
      <Widget.JobJobItemsPriceAmountArrayInput
        {...PRICE_COMPONENT_MOBILE_PROPS}
        {...props}
        name="price.amount"
      />
    }
  />
);
// DEV_NOTE: remove `name` above when widget ready

ViewComponent.displayName = 'Item-Price-view';

export default ViewComponent;
