import { Text } from '@kanda-libs/ks-design-library';

import Container from './Status-container';

const ViewComponent = () => (
  <Container>
    {({ status, subStatus }) =>
      status && (
        <div className="flex flex-col p-3 rounded-xl bg-blue-100 mt-4">
          <Text text={status} className="text-12-18-em text-blue-200 mb-1" />
          <Text text={subStatus} className="text-12-18 text-blue-200" />
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName =
  'Job-Desktop-FinanceApplicationProgress-Card-Status-view';

export default ViewComponent;
