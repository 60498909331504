import type { FunctionComponent } from 'react';
import { DesktopLayout } from '../../components';
import Content from '../Content';

const Desktop: FunctionComponent = function () {
  return (
    <DesktopLayout>
      <Content />
    </DesktopLayout>
  );
};

export default Desktop;
