import { Button, Card } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import {
  FINISH_SETUP_CTA,
  FINISH_SETUP_DESCRIPTION,
  FINISH_SETUP_TITLE,
} from './FinishSetup-constants';

import { URLS } from '../../../../../../config';
import { CARD_CLASS_NAMES } from '../../CompanyBanner-constants';

// DEV_NOTE: Change Link back to SETUP_COMPANY when setupc ompany pages ready

const ViewComponent = () => (
  <Card
    className={CARD_CLASS_NAMES.card}
    title={<p className={CARD_CLASS_NAMES.title}>{FINISH_SETUP_TITLE}</p>}
    footer={
      <Link to={URLS.setupAccount} className="w-full md:w-auto">
        <Button.Text
          id="account-settings-company-banner-finish"
          size={40}
          className="w-full"
          variant="gradient"
          icon="arrow-right"
          label={FINISH_SETUP_CTA}
        />
      </Link>
    }
  >
    <p className={CARD_CLASS_NAMES.description}>{FINISH_SETUP_DESCRIPTION}</p>
  </Card>
);

ViewComponent.displayName = 'FinishSetup-view';

export default ViewComponent;
