import { Helmet } from '../../components';
import Content from './Content';
import ReferralSignUpProvider from './context';

const ReferralSignUp: React.FunctionComponent = function () {
  return (
    <ReferralSignUpProvider>
      <Helmet title="Sign Up" />
      <Content />
    </ReferralSignUpProvider>
  );
};

export default ReferralSignUp;
