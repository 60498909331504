export const CLASS_NAMES = {
  wrapper: 'flex flex-col py-7 md:pb-0 md:ml-auto',
  container: 'flex justify-between',
  label: 'text-style-i-em-up text-neutral-600 my-auto',
  priceWrapper: 'ml-10 flex justify-end',
};

export const TOTAL_LABEL = {
  mobile: 'Total incl. VAT',
  desktop: 'Total',
};

export const LABELS = {
  total: 'total',
  vat: 'vat',
};

export const SKELETONS = {
  label: {
    width: 60,
  },
};
