import { useMemo, useEffect } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { calculateItemTotal } from '../../../../../../../../../../utils';

const ContainerComponent = ({ children, arrayName, index, inFocus }) => {
  const {
    data: { isLoading },
  } = useCreateJobContextValue();

  const { setValue } = useFormContext();

  const fieldNames = useMemo(() => {
    const arrayStruct = `${arrayName}.${index}`;
    return {
      quantity: `${arrayStruct}.quantity`,
      price: `${arrayStruct}.price.amount`,
      vat: `${arrayStruct}.vat_rate`,
      vatAmount: `${arrayStruct}.vat.amount`,
    };
  }, [arrayName, index]);

  const [quantity, price, vat] = useWatch({
    name: [fieldNames.quantity, fieldNames.price, fieldNames.vat],
  });

  const { totalVat, totalIncVat } = calculateItemTotal(quantity, price, vat);

  const className = inFocus ? 'mb-4' : 'mb-1.5 md:mb-5';

  useEffect(() => {
    setValue(fieldNames.vatAmount, totalVat);
  }, [fieldNames, totalVat, setValue]);

  return children({
    className,
    amount: totalIncVat,
    isLoading,
  });
};

ContainerComponent.displayName = 'Items-Total-container';

export default ContainerComponent;
