import { PHONE_REGEX } from '../../../../../../constants';

export const FIELD_NAMES = {
  phoneNumber: 'phoneNumber',
};

const PHONE_NUMBER = {
  phoneNumber: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'tel',
    label: 'Phone Number',
    name: FIELD_NAMES.phoneNumber,
    placeholder: 'Your phone number',
  },
};

export const PHONE_NUMBER_VALIDATION = {
  required: {
    value: true,
    message: 'Phone number is required',
  },
  pattern: {
    value: PHONE_REGEX,
    message: 'Phone number must be a valid UK phone number',
  },
};

export const WRAP_FIELDS = {
  ...PHONE_NUMBER,
};
