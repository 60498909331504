import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';

import { Popover } from './components';
import Container from './Role-container';

export interface NewRoleProps {
  role: string;
  arrayName: string;
  index: number;
}

const ViewComponent = (props: NewRoleProps) => (
  <Container {...props}>
    {({ role, subtext, roleName, onClick, permissions }) => (
      <div className="flex flex-row py-2.5 px-4 border-b last:border-0 border-neutral-300">
        <div className="my-auto">
          <FormTheme variant="clean">
            <FieldHandle.Checkbox id={role} name={role} onClick={onClick} />
          </FormTheme>
        </div>
        <div className="flex flex-row justify-between w-full ml-3 h-10">
          <Text
            text={roleName}
            className="w-20 min-w-20 text-14-22-em text-neutral-600 my-auto"
          />
          <Text
            text={subtext}
            className="hidden md:block text-14-22-em text-neutral-600 text-right break-words my-auto"
          />
        </div>
        <Popover id={roleName} role={roleName} permissions={permissions} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'New-Role-view';

export default ViewComponent;
