import { SkeletonLoader } from '@kanda-libs/ks-design-library';

import { SKELETONS, CLASS_NAMES } from '../../JobHeader-constants';

interface ViewComponentProps {
  /**
   * Is loading
   */
  isLoading?: boolean;
  /**
   * Title
   */
  text?: string;
  /**
   * Job id
   */
  jobId?: string;
  /**
   * Job id
   */
  status?: string;
}

const ViewComponent = ({ isLoading, text }: ViewComponentProps) => (
  <div className={CLASS_NAMES.titleWrapper}>
    <p className={CLASS_NAMES.title}>
      <SkeletonLoader
        {...SKELETONS.title}
        isLoading={isLoading}
        afterLoading={text}
      />
    </p>
  </div>
);

ViewComponent.displayName = 'JobHeader-Title-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  text: undefined,
  jobId: undefined,
  status: undefined,
};

export default ViewComponent;
