export const FIRST_NAME_PROPS = {
  name: 'first_name',
  label: 'First Name',
  placeholder: 'First name',
  autoCapitalize: 'on',
  autoCorrect: 'off',
  autoComplete: 'given-name',
};

export const FIRST_NAME_VALIDATION = {
  required: {
    value: true,
    message: 'First name is required',
  },
  minLength: {
    value: 1,
    message: 'First name number be longer than 1 character',
  },
  pattern: {
    value: /[a-zA-Z]/,
    message: 'First name cannot be blank',
  },
};
