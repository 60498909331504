import type { FunctionComponent } from 'react';
import Invalid from './Invalid';
import NoCode from './NoCode';
import useCustomCode from './useCustomCode';

const CustomCode: FunctionComponent = function () {
  const { isLoading, codeState } = useCustomCode();
  if (isLoading) return <></>;
  if (codeState === 'nocode') return <Invalid />;
  if (codeState === 'invalid') return <NoCode />;
  return <></>;
};

export default CustomCode;
