import { Text } from '@kanda-libs/ks-design-library';

import Container from './Content-container';
import { TITLE, CLASS_NAMES } from '../../Subscription-constants';
import { SubscribeForm } from '../../../components';
import { Card } from './components';

const ViewComponent = () => (
  <Container>
    {({ isLoading, showForm, company }) => (
      <div className="flex flex-col flex-1">
        <Text
          isLoading={isLoading}
          text={TITLE}
          className={CLASS_NAMES.title}
          skeletonProps={{
            width: 200,
          }}
        />
        {!isLoading && (
          <>
            {showForm ? (
              <SubscribeForm />
            ) : (
              <>
                <Card company={company} />
              </>
            )}
          </>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Subscription-Content-view';

export default ViewComponent;
