import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import {
  FirebaseAuthService,
  useMutate,
} from '@kanda-libs/ks-frontend-services';
import useMe from 'hooks/useMe';
import { useCallback, useMemo } from 'react';

import { URLS } from 'config';

export interface ContainerComponentChildrenArgs {
  handleLogout: () => void;
  redirect: string;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { mutate: logout } = useMutate(FirebaseAuthService.logout);
  const { reset } = useAmplitude();
  const { me } = useMe();

  const redirect = useMemo(() => {
    if (!me) return URLS.accountSettings;
    if (me?.role === 'company-staff') return URLS.accountSettings;
    return URLS.companyInformation;
  }, [me]);

  const handleLogout = useCallback(() => {
    reset();
    logout();
  }, [logout, reset]);

  return children({ handleLogout, redirect });
};

ContainerComponent.displayName = 'MyAccount-container';

export default ContainerComponent;
