import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';
import { BUTTON_CLASSNAME } from '../../Confirmation-constants';

export const SMS_BUTTON_PROPS = {
  variant: 'grey' as ButtonLinkVariant,
  size: 14,
  icon: 'sms',
  label: 'SMS',
  left: true,
  className: BUTTON_CLASSNAME,
};

export const IDS = {
  button: 'sms-button',
  modal: 'sms-modal',
};
