import { Card, Text, Icon, Button } from '@kanda-libs/ks-design-library';
import type { UserType } from '@kanda-libs/ks-frontend-services';

import Container from './DirectorCard-container';

export interface DirectorCardProps {
  resend?: () => void;
  isSubmitting?: boolean;
  user: UserType;
}

const ViewComponent = ({
  resend,
  isSubmitting,
  ...props
}: DirectorCardProps) => (
  <Container {...props}>
    {({ name, verified, email }) => (
      <Card className="mb-4 shadow-card">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <Text
              text={name as string}
              className="text-16-20-em text-neutral-700"
            />
            <div className="flex flex-row">
              {verified && (
                <Icon
                  icon="check"
                  stroke="green-500"
                  className="ml-2 my-auto"
                  size={16}
                />
              )}
              <Text
                text={verified ? 'Verified' : 'Not Verified'}
                className={`text-14-22-em ml-2 ${
                  verified ? 'text-green-500' : 'text-neutral-500'
                }`}
              />
            </div>
          </div>
          {!verified && (
            <>
              <Text
                text={email as string}
                className="text-14-22 text-neutral-500 mt-1"
              />
              <Button.InlineLink
                id="account-settings-resend-invite"
                label="Resend invite"
                className="mr-auto mt-2"
                onClick={resend}
                disabled={isSubmitting}
              />
            </>
          )}
        </div>
      </Card>
    )}
  </Container>
);

ViewComponent.displayName = 'DirectorVerification-Content-DirectorCard-view';

ViewComponent.defaultProps = {
  resend: undefined,
  isSubmitting: undefined,
};

export default ViewComponent;
