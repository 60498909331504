import { useIsDesktop, useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { type MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'store';
import { MENU_ITEMS } from './JobMenu-constants';
import { URLS } from '../../config';

const ContainerComponent = ({ children, jobId, status, downloadPdf }) => {
  const dispatch = useAppDispatch();
  const archiveIsSubmitting = useSelector(selectors.job.getIsSubmitting);
  const appIsLoading = useSelector(selectors.getIsLoading);

  const jobs = useSelector(selectors.job.getEntitiesAsArray);

  const isDesktop = useIsDesktop();

  const draft = status === 'draft';

  const { push } = useHistory();

  const { showSuccess } = useToast();

  const onError = useApiError(
    'Error archiving job - please refresh the page and try again',
  );

  /**
   * Handles job download as pdf logic
   * TODO: Implement download pdf logic
   */
  const handleDownload = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!downloadPdf) return;
      downloadPdf(jobId);
    },
    [jobId, downloadPdf],
  );

  /**
   * Handles job duplicate
   */
  const handleDuplicate = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const duplicate = jobs?.filter((j) => j?.id === jobId)?.[0];
      if (!duplicate) return;
      push(URLS.createJob, { duplicate });
    },
    [jobs, jobId, push],
  );

  /**
   * Handles job archive
   */
  const handleArchive = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        actions.archiveJob({
          params: { id: jobId },
          onError,
          onSuccess: () => {
            showSuccess('Job archived');
          },
        }),
      );
    },
    [dispatch, jobId, onError, showSuccess],
  );

  /**
   * Handles job duplicate and archive
   */
  const handleDuplicateArchvive = useCallback(
    (e: MouseEvent) => {
      handleDuplicate(e);
      handleArchive(e);
    },
    [handleDuplicate, handleArchive],
  );

  const isLoading = archiveIsSubmitting || appIsLoading;

  const items = useMemo(
    () =>
      [
        ...(!draft
          ? [
              {
                ...MENU_ITEMS.download,
                onClick: handleDownload,
              },
            ]
          : []),
        {
          ...MENU_ITEMS.duplicate,
          onClick: handleDuplicate,
        },
        {
          ...MENU_ITEMS.duplicateArchive,
          onClick: handleDuplicateArchvive,
        },
        { ...MENU_ITEMS.archive, onClick: handleArchive, disabled: isLoading },
      ].map((item) => ({ ...item, disabled: isLoading })),
    [
      handleArchive,
      handleDownload,
      handleDuplicate,
      handleDuplicateArchvive,
      draft,
      isLoading,
    ],
  );

  const size = isDesktop ? 'small' : 'big';

  return children({ items, size, isLoading });
};

ContainerComponent.displayName = 'Job-Menu-container';

export default ContainerComponent;
