import { useCallback, useContext, useMemo } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import { USER_MENU_MODAL_ID } from '../../Users-constants';
import { getRole } from '../../Users-functions';
import type { ExistingProps } from './Existing-view';

const formatMobile = (mobile) => {
  if (!mobile) return undefined;
  if (mobile[0] === '0') return `+44${mobile?.slice(1)}`;
  return `+44${mobile}`;
};

const formatUser = (user) => ({
  name: [user?.first_name, user?.last_name]?.filter(Boolean)?.join(' '),
  email: user?.email,
  mobile: formatMobile(user?.mobile),
  role: getRole(user?.role),
  director: !!user?.director_info,
  verified: user?.director_info?.verification_status === 'verified',
  index: user?.index,
});

export interface ContainerComponentChildrenArgs {
  name?: string;
  email?: string;
  mobile?: string;
  role?: string;
  director?: boolean;
  verified?: boolean;
  index?: number;
  arrayName: string;
  canUserEdit: boolean;
  setUserToDelete: (user: UserType) => void;
  handleShowModal: () => void;
  modalId: string;
}

export interface ContainerComponentProps extends ExistingProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  user,
  arrayName,
  canUserEdit,
  setUserToDelete,
}: ContainerComponentProps) => {
  const details = formatUser(user);

  const { showModal } = useContext(ModalsWrapperContext);

  const modalId = useMemo(() => {
    if (!details?.index) return USER_MENU_MODAL_ID;
    return `${USER_MENU_MODAL_ID}-${details.index}`;
  }, [details]);

  /**
   * Function to show delete item modal
   */
  const handleShowModal = useCallback(
    () => showModal(modalId),
    [showModal, modalId],
  );

  return children({
    ...details,
    arrayName,
    canUserEdit,
    setUserToDelete,
    handleShowModal,
    modalId,
  });
};

ContainerComponent.displayName = 'Users-Existing-container';

export default ContainerComponent;
