import type { FunctionComponent } from 'react';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import SlimOption from '../SlimOption';

export interface CombinedOptionProps {
  options: FinanceRate[];
  hideHandle?: boolean;
}

const CombinedOption: FunctionComponent<CombinedOptionProps> = ({
  options,
  hideHandle = false,
}) => <SlimOption financeOptions={options} hideHandle={hideHandle} />;

CombinedOption.displayName = 'FinanceOptions-CombinedOption-view';

export default CombinedOption;
