import { useContext } from 'react';

import Context from '../Context';

const ContainerComponent = ({ children }) => {
  const { classNames, style } = useContext(Context);

  const { smaller } = classNames.desktop;

  const kanda = style === 'kanda';

  return children({ classNames, smaller, kanda });
};

ContainerComponent.displayName = 'LineItem-Mobile-container';

export default ContainerComponent;
