import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

interface ViewComponentProps {
  /**
   * Payment method title
   */
  title: string;
  /**
   * Payment method field name
   */
  name: string;
  /**
   * Payment method description
   */
  subtitle?: string;
}

const ViewComponent = (props: ViewComponentProps) => (
  <BreakPoints
    desktop={<Desktop {...props} />}
    mobile={<Mobile {...props} />}
  />
);

//

ViewComponent.displayName = 'PaymentMethods-Option-view';

ViewComponent.defaultProps = {
  subtitle: null,
};

export default ViewComponent;
