import { getLabel } from './Activity-functions';
import type { ViewComponentProps } from './Activity-view';

export interface ContainerComponentChildrenArgs {
  label: string;
  timestamp: number;
}

export interface ContainerComponentProps extends ViewComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
  date: string | Date;
  status: string;
  financeStatus: string;
  archived?: string;
  viewedFinance?: boolean;
}

const ContainerComponent = ({
  children,
  date,
  status,
  financeStatus,
  archived,
  viewedFinance,
}: ContainerComponentProps) => {
  const timestamp = new Date(date).getTime();

  const label =
    archived === 'no'
      ? getLabel(status, financeStatus, viewedFinance)
      : 'Archived';

  return children({
    label,
    timestamp,
  });
};

ContainerComponent.displayName = 'Activity-container';

export default ContainerComponent;
