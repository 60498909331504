import type { Job } from '@kanda-libs/ks-frontend-services';
import useCurrentJob from 'pages/JobDetails/JobDetails-useCurrentJob';

export interface LeadBannerHook {
  showLeadBanner: boolean;
  customer?: Job['customer'];
  address?: string;
}

export default function useLeadBanner(): LeadBannerHook {
  const { job } = useCurrentJob();

  const showLeadBanner = !!job?.quoted_to;

  const customer = job?.customer;

  return {
    showLeadBanner,
    customer,
    address: [
      customer?.address?.line_1,
      customer?.address?.line_2,
      customer?.address?.city,
      customer?.address?.county,
      customer?.address?.postcode,
    ].join(', '),
  };
}
