import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { formatCompany } from './Content-functions';
import { cleanObject, parsePhoneNumber } from '../../../../../utils';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess } = useToast();

  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const defaultValues = useMemo(() => {
    if (!company) return null;
    const formattedCompany = cleanObject(formatCompany(company));
    return { ...formattedCompany };
  }, [company]);

  const showForm = useMemo(() => {
    if (!company || !company?.lifecycle) return false;
    if (
      ['onboarded', 'completed_setup', 'verified'].includes(company?.lifecycle)
    )
      return true;
    return false;
  }, [company]);

  const isLoading = useSelector(selectors.getIsLoading);

  const onSubmit = useCallback(
    (formData) => {
      const { id } = company || {};
      const body = cleanObject({
        ...company,
        ...formData.company,
        contact_info: {
          ...company?.contact_info,
          ...formData?.company?.contact_info,
          contact_phone: parsePhoneNumber(
            formData?.company?.contact_info?.contact_phone,
          ),
        },
        ...(company?.company_type === 'sole_trader' && {
          sole_trader_info: {
            ...company.sole_trader_info,
            national_insurance_number:
              formData?.company?.sole_trader_info?.national_insurance_number?.toUpperCase(),
          },
        }),
      });

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            showSuccess('Company Updated!');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const form = useForm({ mode: 'onBlur', defaultValues });

  const { reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return children({
    isLoading,
    showForm,
    form,
    onSubmit,
    company,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'CompanyInformation-Content-container';

export default ContainerComponent;
