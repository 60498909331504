import { Form } from '@kanda-libs/ks-component-ts';

import Container from './Details-container';

import { Page } from '../..';
import { Details } from '../../../../components';

const ViewComponent = () => (
  <Container>
    {({ form, onSubmit, nextButtonProps, isLoading, isSolar }) => (
      <Form
        id="create-job-details-mobile"
        form={form}
        onSubmit={onSubmit}
        isLoading={isLoading}
      >
        <Page nextButtonProps={nextButtonProps}>
          <div className="flex flex-col mt-4">
            <Details.KandaQuoteBanner />
            <Details.LeadBanner />
            <Details.ExampleJobAlertBanner />
            <Details.Title />
            <Details.Quote />
            {isSolar && <Details.EPVSCalculations />}
          </div>
        </Page>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Mobile-Details-view';

export default ViewComponent;
