import type {
  StringIndexedObject,
  TableProps,
} from '@kanda-libs/ks-component-ts';
import { URLS } from 'config';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Context from '../../../Context';
import { ORDER, SORTING } from '../../../Context/Provider/Provider-constants';

export interface ContainerComponentChildrenArgs
  extends Pick<
    TableProps,
    | 'data'
    | 'pageIndex'
    | 'totalPages'
    | 'setPage'
    | 'onRowClicked'
    | 'onAction'
  > {
  isLoading: boolean;
  rowClassName: string;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const {
    isLoading = false,
    data,
    pageIndex,
    totalPages,
    setPage,
    sorting,
    setSorting = () => {},
    order,
    setOrder = () => {},
    showingPlaceholderJobs = false,
  } = useContext(Context);

  const { push } = useHistory();

  const rowClassName =
    showingPlaceholderJobs && !isLoading ? 'bg-violet-100 rounded-xl px-4' : '';

  /**
   * Handles row click
   * @param {Object} row
   * @param {Object} row.original
   * @param {String} row.original.link
   */
  const onRowClicked = useCallback(
    (row) => {
      if (showingPlaceholderJobs) {
        push(URLS.exampleJob);
      } else {
        push(row.original.link);
      }
    },
    [push, showingPlaceholderJobs],
  );

  /**
   * Handles table actions
   * @param {Object} action
   * @param {String} action.type
   * @param {*} action.payload
   */
  const onAction = useCallback(
    (action: StringIndexedObject = {}) => {
      const { type } = action;
      if (type === 'customer-alphabetical') {
        if (sorting === SORTING.CUSTOMER) {
          if (order === ORDER.NORMAL) {
            setOrder(ORDER.INVERSE);
            return;
          }
          setOrder(ORDER.NORMAL);
          return;
        }
        setSorting(SORTING.CUSTOMER);
        setOrder(ORDER.NORMAL);
        return;
      }
      if (type === 'price-ascending') {
        setSorting(SORTING.PRICE);
        setOrder(ORDER.NORMAL);
        return;
      }
      if (type === 'price-descending') {
        setSorting(SORTING.PRICE);
        setOrder(ORDER.INVERSE);
        return;
      }
      if (type === 'activity-ascending') {
        setSorting(SORTING.UPDATED_AT);
        setOrder(ORDER.NORMAL);
        return;
      }
      if (type === 'activity-descending') {
        setSorting(SORTING.UPDATED_AT);
        setOrder(ORDER.INVERSE);
        return;
      }
      setSorting(SORTING.UPDATED_AT);
      setOrder(ORDER.NORMAL);
    },
    [order, setOrder, sorting, setSorting],
  );

  return children({
    isLoading,
    data: data as StringIndexedObject[],
    pageIndex: pageIndex as number,
    totalPages: totalPages as number,
    setPage: setPage as (page: number) => void,
    onRowClicked: onRowClicked as () => void,
    onAction: onAction as () => void,
    rowClassName,
  });
};

ContainerComponent.displayName = 'Home-Desktop-Table-container';

export default ContainerComponent;
