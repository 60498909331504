import { BreakPoints } from '@kanda-libs/ks-design-library';

import Container from './Layout-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
  /**
   * isLoading
   */
  isLoading?: boolean;
}

const ViewComponent = ({
  children,
  isLoading,
  ...restProps
}: ViewComponentProps) => (
  <Container {...restProps}>
    {(props) => (
      <>
        <BreakPoints
          mobile={<Mobile {...props} isLoading={isLoading} />}
          desktop={<Desktop {...props} isLoading={isLoading} />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Page-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
};

ViewComponent.defaultProps = { children: null };

export default ViewComponent;
