import { CompanyBanner } from 'pages/MyAccount/components';
import AccountInReviewLayout from './Layout';

const AccountInReview: React.FunctionComponent = function () {
  return (
    <AccountInReviewLayout>
      <div className="flex flex-col mt-8">
        <CompanyBanner homeBanner />
      </div>
    </AccountInReviewLayout>
  );
};

export default AccountInReview;
