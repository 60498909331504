import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

import { Helmet } from '../../components';
import MarkAsFinishedModal from './components/MarkAsFinishedModal';
import AddBankDetailsModal from './components/AddBankDetailsModal';

const ViewComponent = ({}) => (
  <>
    <Helmet title="Job Details" />
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
    <MarkAsFinishedModal />
    <AddBankDetailsModal />
  </>
);

ViewComponent.displayName = 'JobDetails-view';

export default ViewComponent;
