import { Dropzone } from '@kanda-libs/ks-component-ts';
import {
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  Loader,
} from '@kanda-libs/ks-design-library';

import { Banner } from '../components';
import Container from './Desktop-container';

const ViewComponent = ({}) => (
  <Container>
    {({ Content, dropZoneProps, pdfIsSubmitting }) => (
      <Dropzone {...dropZoneProps}>
        <DesktopLayoutDefault>
          <DesktopLayoutDefaultContent>
            <Banner />
            {Content && <Content />}
            <Loader isLoading={pdfIsSubmitting} />
          </DesktopLayoutDefaultContent>
        </DesktopLayoutDefault>
      </Dropzone>
    )}
  </Container>
);

ViewComponent.displayName = 'Jobs-Desktop-view';

export default ViewComponent;
