import { useFormContext } from '@kanda-libs/ks-component-ts';
import { useCallback } from 'react';
import { removeTrailingWhitespace, removeWhitespaceAndNewline } from 'utils';

interface MeFieldsHook {
  onNameBlur: (name: string) => void;
  onContactBlur: (name: string) => void;
}

export default function useMeFields(): MeFieldsHook {
  const { getValues, setValue } = useFormContext();
  const onNameBlur = useCallback(
    (name: string) => setValue(name, removeTrailingWhitespace(getValues(name))),
    [setValue, getValues],
  );

  const onContactBlur = useCallback(
    (name: string) =>
      setValue(name, removeWhitespaceAndNewline(getValues(name))),
    [setValue, getValues],
  );

  return { onNameBlur, onContactBlur };
}
