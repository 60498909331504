export const TERMS_PROPS = {
  name: 'terms_and_conditions',
  handle: 'checkbox',
};

export const TERMS_VALIDATION = {
  required: {
    value: true,
    message: 'You must agree to terms and conditions',
  },
};

export const CLASSNAMES = {
  container: 'flex flex-row',
  text: 'mr-1 my-auto text-14-22',
};

export const TERMS_TEXT = "I have read and agree to Kanda's ";

export const LINK_TEXT = 'Terms & Conditions of Service';

export const TERMS_BUTTON = {
  label: 'Terms & Conditions of Service',
  size: 14,
};
