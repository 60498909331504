import { useForm } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useCurrentCompany from 'hooks/useCurrentCompany';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const form = useForm({ mode: 'onBlur' });

  const { showSuccess } = useToast();
  const onError = useApiError('Error updating user notification preferences');

  const onSubmit = useCallback(
    (formValues) => {
      const body = {
        ...company,
        ...(formValues?.company || {}),
      };
      const { id } = company || {};

      dispatch(
        actions.putCompany({
          body,
          params: { id: id as string },
          onError,
          onSuccess: () => {
            showSuccess('User notification preferences updated');
          },
        }),
      );
    },
    [company, dispatch, onError, showSuccess],
  );

  const { reset, setValue, watch } = form;

  const formUsers = watch('company.users');

  const hasCommsPrefs = useMemo(() => {
    if (!formUsers) return false;
    return formUsers?.some((user) => user?.comm_preferences);
  }, [formUsers]);

  const defaultValues = useMemo(() => {
    if (!company) return [];
    return {
      company: {
        users: company?.users,
      },
    };
  }, [company]);

  const addPreferences = useCallback(() => {
    const users = company?.users?.map((user) => ({
      ...user,
      comm_preferences: {
        pre_job: 'enabled',
        post_job: ['company-admin', 'company-manager']?.includes(user?.role)
          ? 'enabled'
          : 'disabled',
        other: ['company-admin']?.includes(user?.role) ? 'enabled' : 'disabled',
      },
    }));
    setValue('company.users', users);
  }, [company, setValue]);

  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    isLoading: !company,
    form,
    onSubmit,
    isSubmitting,
    hasCommsPrefs,
    addPreferences,
  });
};

ContainerComponent.displayName = 'NotificationPreferences-Content-container';

export default ContainerComponent;
