export const CLASS_NAMES = {
  container: 'flex flex-1 justify-between items-center -mx-4 px-4 ',
  label: 'text-style-i-em-up text-neutral-600 min-w-18',
};

export const TOTAL_LABEL = 'Total';

export const PRICE_PROPS = {
  wrapperClassName: 'ml-auto',
  poundsClassName: 'text-style-f-em md:text-style-d',
  centsClassName: 'text-style-k-em md:text-style-h-em',
  color: 'neutral-500',
};
