import { FormTheme, Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import Container from './DesktopSearch-container';
import { FIELD, BUTTON } from './DesktopSearch-constants';

const ViewComponent = () => (
  <Container>
    {({ query, searchVisible, onSearch, onBlur, onChange, autoFocus }) => (
      <div className="flex flex-1 flex-row ml-2">
        <FormTheme variant="inline">
          {searchVisible ? (
            <Field.UncontrolledInput
              {...FIELD}
              defaultValue={query}
              onBlur={onBlur}
              autoFocus={autoFocus}
              onChange={onChange}
            />
          ) : (
            <Button.Icon onClick={onSearch} {...BUTTON} />
          )}
        </FormTheme>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopSearch-view';

export default ViewComponent;
