import { Header, Layout, Logo } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';
import { BookCallButton } from '..';

interface ReferralSignUpLayoutMobileProps {
  children: ReactNode;
  footer?: ReactNode;
}

const ReferralSignUpLayoutMobile: React.FunctionComponent<ReferralSignUpLayoutMobileProps> =
  function ({ children, footer }) {
    return (
      <Layout
        header={
          <Header.Base lines hideNumber>
            <div className="flex flex-row w-full justify-between">
              <Logo height={20} className="my-auto" />
              <BookCallButton />
            </div>
          </Header.Base>
        }
        footer={footer ? <>{footer}</> : undefined}
        stickyFooter
        stickyHeader
      >
        <>{children}</>
      </Layout>
    );
  };

export default ReferralSignUpLayoutMobile;
