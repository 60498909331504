import { Button, BreakPoints, Icon, Text } from '@kanda-libs/ks-design-library';
import type { Company } from '@kanda-libs/ks-frontend-services';
import {
  Widget,
  Field,
  RowFields,
  type AddressApiData,
} from '@kanda-libs/ks-component-ts';
import { DOB_VALIDATION } from 'common/constants';

import Container from './SoleTraderForm-container';

import { MOBLIE_VALIDATION } from './SoleTraderForm-constants';
import GetName from '../../../../../../../../../../common/GetName';
import { DATE_OF_BIRTH_NAME } from '../../Limited/DirectorCard/constants';

export interface SoleTraderFormProps {
  arrayName: string;
  index: number;
  company?: Company | null;
}

const ViewComponent = (props: SoleTraderFormProps) => (
  <Container {...props}>
    {({
      index,
      setPostcodeName,
      postcodeCallback,
      postcodeName,
      addresses,
      manual,
      enterManually,
      onSameClick,
      selectProps,
    }) => (
      <div className="flex flex-col w-full mx-auto">
        <div className="flex flex-row px-4 py-5 border border-neutral-300 rounded-xl mb-6">
          <Icon
            icon="account"
            size={20}
            stroke="neutral-700"
            className="my-auto mr-3"
          />
          <Text
            text="Your info"
            className="text-14-22-em text-neutral-800"
            skeletonProps={{
              width: '150px',
            }}
          />
        </div>
        <BreakPoints
          mobile={
            <>
              <Widget.CompanyUsersFirstNameArrayInput index={index} />
              <Widget.CompanyUsersLastNameArrayInput index={index} />
            </>
          }
          desktop={
            <RowFields>
              <Widget.CompanyUsersFirstNameArrayInput index={index} />
              <Widget.CompanyUsersLastNameArrayInput index={index} />
            </RowFields>
          }
        />
        <Widget.CompanyUsersMobileArrayInput
          index={index}
          label="Mobile number"
          placeholder="Your mobile number"
          validation={MOBLIE_VALIDATION}
        />
        <Widget.CompanyUsersEmailArrayInput index={index} />
        <Widget.CompanyUsersDirectorInfoDateOfBirthArrayInput
          name={DATE_OF_BIRTH_NAME}
          index={index}
          validation={DOB_VALIDATION}
        />
        <GetName callback={setPostcodeName}>
          <Widget.CompanyUsersDirectorInfoHomeAddressPostcodeArrayInput
            name="director_info.home_address.postcode"
            callback={postcodeCallback}
            index={index}
            label="Home Address Postcode"
            placeholder="Postcode"
            helperText={
              <Button.InlineLink
                id="director-info-sole-trader-same-address"
                label="Same as trading address"
                variant="turquoise"
                className="whitespace-nowrap mb-2"
                onClick={() => onSameClick()}
              />
            }
          />
        </GetName>
        {postcodeName && (
          <Field.Address.Select
            postcodeName={postcodeName}
            data={addresses as unknown as AddressApiData}
            {...selectProps}
          />
        )}
        {!manual ? (
          <Button.Link
            id="director-info-same-address"
            label="Enter address manually"
            size={14}
            variant="turquoise"
            className="mr-auto -mt-2 mb-4"
            onClick={enterManually}
          />
        ) : (
          <>
            <Widget.CompanyUsersDirectorInfoHomeAddressLine1ArrayInput
              name="director_info.home_address.line_1"
              label="Home Address Line 1"
              index={index}
            />
            <Widget.CompanyUsersDirectorInfoHomeAddressLine2ArrayInput
              name="director_info.home_address.line_2"
              label="Home Address Line 2"
              index={index}
            />
            <Widget.CompanyUsersDirectorInfoHomeAddressCityArrayInput
              name="director_info.home_address.city"
              label="Home Address City"
              index={index}
            />
            <Widget.CompanyUsersDirectorInfoHomeAddressCountyArrayInput
              name="director_info.home_address.county"
              label="Home Address County"
              index={index}
            />
            <Widget.CompanyUsersDirectorInfoHomeAddressCountryArrayInput
              name="director_info.home_address.country"
              label="Home Address Country"
              index={index}
            />
          </>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'SetupCompany-DirectorInfo-Form-Sole-SoleTraderForm-view';

export default ViewComponent;
