import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { BUTTON_PROPS } from './ArchiveButton-constants';

import { URLS } from '../../../../../../config';

const ViewComponent = () => (
  <Link to={URLS.archive} className="ml-2">
    <BreakPoints
      mobile={<Button.Icon {...BUTTON_PROPS.mobile} />}
      desktop={<Button.Text {...BUTTON_PROPS.desktop} />}
    />
  </Link>
);

ViewComponent.displayName = 'Banner-NewJobButton-view';

export default ViewComponent;
