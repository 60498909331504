import { Button, Loader } from '@kanda-libs/ks-design-library';

import Container from './SendReminder-container';
import SendReminderModal from './SendReminderModal';

const SendReminder = ({}) => (
  <>
    <Container>
      {({
        showButton,
        isSubmitting,
        onClick,
        onClickDontShowAgain,
        companyIsLoading,
      }) => (
        <>
          <SendReminderModal onClickDontShowAgain={onClickDontShowAgain} />
          {showButton && (
            <>
              <Button.Text
                id="job-details-send-reminder"
                onClick={onClick}
                label="Send reminder"
                size={40}
                icon="send"
                left
                className="w-full whitespace-nowrap md:mr-2"
                disabled={companyIsLoading || isSubmitting}
              />
            </>
          )}
          <Loader isLoading={isSubmitting} />
        </>
      )}
    </Container>
  </>
);

SendReminder.displayName = 'Job-SendReminder-view';

export default SendReminder;
