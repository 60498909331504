export const MANUAL_PAYMENT_OPTION = {
  icon: 'deposit',
  title: 'Payment on completion',
};

export const DECLINE_OPTION = {
  icon: 'delete-file',
  title: 'Decline job',
};

export const TEST_OPTION = {
  icon: 'delete-file',
  title: 'Decline job',
};
