import type { FunctionComponent, MutableRefObject, MouseEvent } from 'react';
import { PopoverMenuItem } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-component-ts';
import { FILTERS } from '../../constants';
import type { JobTableFilterPopoverItemType } from '../../JobTableFilterPopover/JobTableFilterPopoverItem/types';
import { MODAL_ID } from './constants';
import useJobTableFilterModal from './useJobTableFilterModal';
import JobTableFilterPopover from '../../JobTableFilterPopover';

const JobTableFilterModal: FunctionComponent = function () {
  const { filter, onSelectFilter } = useJobTableFilterModal();

  return (
    <ModalContainer id={MODAL_ID}>
      {({ id, handleClose, ref }) => (
        <ModalLayoutSlideUp
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          variant="MARKETING"
        >
          <div className="overflow-scroll max-h-60vh">
            {/* TODO: Abstract into two components for each list */}
            {!filter &&
              Object.keys(FILTERS).map((filterKey) => (
                <PopoverMenuItem
                  icon=""
                  key={filterKey}
                  className="w-full"
                  name={FILTERS[filterKey]}
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSelectFilter(filterKey as JobTableFilterPopoverItemType);
                  }}
                  selectedColorVariant="green"
                />
              ))}
            {filter && (
              <>
                <PopoverMenuItem
                  name="Back"
                  icon="arrow-left"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectFilter(undefined);
                  }}
                />
                <JobTableFilterPopover type={filter} />
              </>
            )}
          </div>
        </ModalLayoutSlideUp>
      )}
    </ModalContainer>
  );
};

export default JobTableFilterModal;
