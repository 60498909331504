import SplashScreen from '../../../../../components/SplashScreen';

const ViewComponent = () => (
  <SplashScreen
    icon="send-quote-color"
    title="Check your mail"
    subText="Please click on the link in the email we sent you to set up a new password"
  />
);

ViewComponent.displayName = 'ForgotPassword-Confirmation-Content-view';

export default ViewComponent;
