import { Icon } from '@kanda-libs/ks-design-library';

import Container from './Item-container';
import { CLASS_NAMES, ICON_PROPS } from './Item-constants';

interface ViewComponentProps {
  /**
   * Status
   */
  status: string;
  /**
   * HandleClose callback
   */
  handleClose: () => void;
}

const ViewComponent = ({ status }: ViewComponentProps) => (
  <Container status={status}>
    {({ label, onClick, active }) => (
      <button className={CLASS_NAMES.container} type="button" onClick={onClick}>
        <p className={CLASS_NAMES.label}>{label}</p>
        {active && <Icon {...ICON_PROPS} />}
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'StatusFilters-Item-view';

export default ViewComponent;
