import { useContext, useCallback } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

import { BILLING_MODAL_ID } from './Mobile-constants';

const ContainerComponent = ({ children }) => {
  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => {
    showModal(BILLING_MODAL_ID);
  }, [showModal]);

  return children({
    onClick,
  });
};

ContainerComponent.displayName =
  'Subscription-Content-History-Mobile-container';

export default ContainerComponent;
