import { Icon } from '@kanda-libs/ks-design-library';
import AccountBadge from '../../../../../../components/AccountBadge/AccountBadge-view';
import { URLS } from '../../../../../../config';

const ViewComponent = () => (
  <AccountBadge
    title="Upgrade your Kanda"
    subTitle="You can only draft jobs until you subscribe to Kanda"
    buttonLabel="Upgrade now"
    buttonPrepend={<Icon icon="star" className="w-4 mr-2" />}
    link={URLS.subscription}
  />
);

ViewComponent.displayName = 'UpgradeAccountBadge-view';

export default ViewComponent;
