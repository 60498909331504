import { Field, Form, FormTheme } from '@kanda-libs/ks-component-ts';

import Container from './PriceFilter-container';
import { FILTER_FIELDS, PRICE_FILTER_TYPE } from './PriceFilter-constants';

export interface ViewComponentProps {
  /**
   * HandleClose callback
   */
  handleClose: () => void;
}

const ViewComponent = ({ handleClose }: ViewComponentProps) => (
  <Container handleClose={handleClose}>
    {({ form, onSubmit, type, classNames, error }) => (
      <Form id="price-filter-desktop" form={form} onSubmit={onSubmit}>
        <div className={classNames.container}>
          <FormTheme variant="clean">
            <Field.DropDownInput {...FILTER_FIELDS.type} />
          </FormTheme>
          <FormTheme variant="outline-narrow">
            <div className={classNames.fields}>
              <Field.NumberInput type="price" name="from" />
              {type === PRICE_FILTER_TYPE.BETWEEN && (
                <>
                  <div className={classNames.seperator} />
                  <Field.NumberInput type="price" name="to" />
                </>
              )}
            </div>
          </FormTheme>
          {error && <p className="mt-3 text-red-200 text-style-h">{error}</p>}
        </div>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'PriceFilter-view';

export default ViewComponent;
