import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Widget } from '@kanda-libs/ks-component-ts';

import Container from './Title-container';
import { TITLE_COMPONENT_PROPS } from './Title-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Container {...props}>
    {(containerProps) => (
      <BreakPoints
        mobile={
          <Widget.JobJobItemsTitleArrayInput
            {...TITLE_COMPONENT_PROPS.mobile}
            {...props}
            {...containerProps}
          />
        }
        desktop={
          <Widget.JobJobItemsTitleArrayInput
            {...TITLE_COMPONENT_PROPS.desktop}
            {...props}
            {...containerProps}
          />
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Title-view';

export default ViewComponent;
