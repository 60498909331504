import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

const ContainerComponent = ({ children }) => {
  const {
    data: { job },
  } = useCreateJobContextValue();

  const financeOptions = job?.finance_options;

  const lineItems = job?.job_items;

  const showOptions = true;

  const jobType = job?.job_type || 'standard';

  const deposit = job?.deposit_value?.amount || null;

  return children({
    showOptions,
    lineItems,
    financeOptions,
    jobType,
    deposit,
  });
};

ContainerComponent.displayName =
  'PaymentMethods-Desktop-FinanceOptions-container';

export default ContainerComponent;
