import { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { CLASS_NAMES } from './Mobile-constants';
import useCollapsed from './Mobile-useCollapsed';

import useCurrentJob from '../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { job, isLoading } = useCurrentJob();

  const { collapsed, toggleCollapsed, removeCollapse, setInitialCollapse } =
    useCollapsed();

  const classNames = {
    ...CLASS_NAMES,
    container: clsx(
      CLASS_NAMES.baseContainer,
      collapsed && CLASS_NAMES.collapsed,
    ),
    gradient: clsx(collapsed && CLASS_NAMES.gradient),
  };

  const lineItems = useMemo(() => {
    if (isLoading || !job) return null;
    const items = job?.job_items;
    return items;
  }, [isLoading, job]);

  useEffect(() => {
    if (!lineItems) return;
    if (lineItems.length <= 1) removeCollapse();
    if (lineItems.length <= 2) setInitialCollapse();
  }, [lineItems, removeCollapse, setInitialCollapse]);

  const kanda = lineItems?.[0]?.style === 'kanda';

  return children({
    classNames,
    toggleCollapsed,
    isLoading,
    kanda,
  });
};

ContainerComponent.displayName = 'Job-Items-Mobile-container';

export default ContainerComponent;
