import type { MetaPixelInterface } from './types';

/* eslint-disable no-param-reassign, no-multi-assign, prefer-spread, prefer-rest-params, no-underscore-dangle */
export const addScript = (
  f: Window,
  b: Document,
  e: string,
  v: string,
  n?: any,
  t?: HTMLScriptElement,
  s?: HTMLScriptElement,
): MetaPixelInterface => {
  if (f.fbq) return f.fbq;

  n = f.fbq = function () {
    if (n.callMethod) {
      n.callMethod.apply(n, arguments);
    } else {
      n.queue.push(arguments);
    }
  } as unknown as MetaPixelInterface;

  if (!f._fbq) f._fbq = n;

  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];

  t = b.createElement(e) as HTMLScriptElement;
  t.async = !0;
  t.src = v;

  s = b.getElementsByTagName(e)[0] as HTMLScriptElement;
  s?.parentNode?.insertBefore(t, s);

  return n;
};

export const addDefaultScript = (): MetaPixelInterface =>
  addScript(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  );
