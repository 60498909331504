// - not_submitted
// - accepted_sign_document
// - accepted_deposit_required
// - accepted
// - declined
// - referred
// - action_lender
// - action_customer
// - pending
// - finished
// - paid_out
// - cancelled

export const getStatus = (job, status, xstatus) => {
  if (
    job?.finance_status === 'sat_note_signed' ||
    job?.finance_status === 'sat_note_lender_review'
  ) {
    if (xstatus?.includes('AG_LIVE')) return 'Status: Kanda will pay you soon';
    return 'Status: Satisfaction note in review';
  }
  if (
    job?.finance_status !== 'applied_for_finance' &&
    job?.finance_status !== 'financed'
  )
    return null;
  if (status === 'referred')
    return 'Status: Lender is reviewing the application';
  if (status === 'action_lender')
    return 'Status: Lender is reviewing the application';
  if (status === 'action_customer')
    return 'Status: Customer needs to send documents';
  if (status === 'accepted_sign_document')
    return 'Status: Customer needs to sign documents';
  const payment = [...(job?.payments || [])]
    ?.sort(
      (p1, p2) =>
        new Date(p2?.metadata?.created_at).getTime() -
        new Date(p1?.metadata?.created_at).getTime(),
    )
    ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
  if (!payment) return null;
  if (status === 'accepted_deposit_required')
    return 'Status: Customer needs to pay deposit';
  return null;
};
