import type { FunctionComponent } from 'react';
import type { OnboardingStage } from '@kanda-libs/ks-frontend-services';
import { formatReason } from './helpers';
import useInformationRequired from './useInformationRequired';
import Banner from './Banner';

export interface InformationRequiredBannerProps {
  stage: OnboardingStage;
}

const InformationRequiredBanner: FunctionComponent<InformationRequiredBannerProps> =
  function ({ stage }) {
    const { showBanner, reason, note } = useInformationRequired(stage);

    if (!showBanner) return <></>;

    return (
      <Banner>
        <>
          <div>
            We need more information from you to complete your application:
          </div>
          <div>{[formatReason(reason), note].filter(Boolean).join(' - ')}</div>
        </>
      </Banner>
    );
  };

export default InformationRequiredBanner;
