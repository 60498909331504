import {
  Form,
  type StringIndexedObject,
  Widget,
} from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import type { UseFormReturn } from 'react-hook-form';

import DirectorAddress from 'components/DirectorAddress';
import { DOB_VALIDATION } from 'common/constants';
import useDirectorForm from './useDirectorForm';
import { DATE_OF_BIRTH_NAME, MOBLIE_VALIDATION } from './constants';

interface DirectorFormProps {
  cid?: string;
  form: UseFormReturn<StringIndexedObject>;
  director?: object;
  onSubmit(...args: unknown[]): unknown;
}

const DirectorForm = ({
  cid,
  form,
  director,
  onSubmit: onSuccessfulSubmit,
}: DirectorFormProps) => {
  const { onSubmit, isSubmitting } = useDirectorForm(
    cid,
    director,
    onSuccessfulSubmit,
  );

  return (
    <Form id="director" form={form} onSubmit={onSubmit}>
      <div className="flex flex-col">
        <Widget.DirectorVerificationEmail
          label="Email address"
          placeholder="Your email address"
        />
        <Widget.DirectorVerificationMobile
          label="Mobile number"
          placeholder="Your mobile number"
          validation={MOBLIE_VALIDATION}
        />
        <Widget.DirectorVerificationDateOfBirth
          name={DATE_OF_BIRTH_NAME}
          validation={DOB_VALIDATION}
        />
        <DirectorAddress />
        <Button.Text
          id="identify-director-begin-verification"
          submit
          label="Begin verification"
          icon="arrow-right"
          isLoading={isSubmitting}
          className="mx-auto"
        />
      </div>
    </Form>
  );
};

DirectorForm.defaultProps = {
  cid: undefined,
  director: {},
};

export default DirectorForm;
