import { Button } from '@kanda-libs/ks-design-library';

import Container from './Side-container';
import { PaymentAndFinanceOptions } from '../../../../../../components';

import { CLASS_NAMES, TITLE, EDIT_BUTTON_PROPS } from '../../Desktop-constants';

const ViewComponent = () => (
  <Container>
    {({ prevStep }) => (
      <div className={CLASS_NAMES.side}>
        <PaymentAndFinanceOptions.Info
          title={
            <p className={CLASS_NAMES.title}>
              {TITLE}
              <Button.InlineLink {...EDIT_BUTTON_PROPS} onClick={prevStep} />
            </p>
          }
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PaymentMethods-Desktop-Side-view';

export default ViewComponent;
