import { Text } from '@kanda-libs/ks-design-library';
import { Title } from '@kanda-libs/ks-design-library-new';
import type { FunctionComponent } from 'react';

const InviteYourDeveloperSuccess: FunctionComponent = function () {
  return (
    <>
      <Title>Developer Invited!</Title>
      <Text text="We'll send them an email to join your account so they can update your website" />
    </>
  );
};

export default InviteYourDeveloperSuccess;
