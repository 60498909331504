import type { SelectOption } from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type { BaseConfig } from './constants';

export const getGroupedByBusinessSectorOptions = (
  base: BaseConfig[],
): StringIndexedObject<SelectOption[]> => {
  const sectors = base
    .map((config: BaseConfig) => config.business_sector)
    .filter(
      (sector: string, index: number, allSectors: string[]) =>
        allSectors.indexOf(sector) === index,
    );
  return sectors.reduce(
    (final: StringIndexedObject<SelectOption[]>, sector: string) => {
      const sectorOptions = base.filter(
        (config: BaseConfig) => config.business_sector === sector,
      );
      return {
        ...final,
        [sector]: sectorOptions
          .map((option) => ({
            value: option.trade_text,
            name: option.trade_text,
          }))
          .sort((option1: SelectOption, option2: SelectOption) => {
            if (option1.value.toLocaleLowerCase().includes('not listed'))
              return 1;
            if (option2.value.toLocaleLowerCase().includes('not listed'))
              return -1;
            return option1.value.localeCompare(option2.value);
          }),
      };
    },
    {},
  );
};

export const getBusinessSectorOptions = (
  base: BaseConfig[],
): SelectOption[] => {
  const sectors = base
    .map((config: BaseConfig) => config.business_sector)
    .filter(
      (sector: string, index: number, allSectors: string[]) =>
        allSectors.indexOf(sector) === index,
    );
  return sectors.map((sector: string) => ({
    name: sector,
    value: sector,
  }));
};

export const getTradeTypeFromTradeText = (
  base: BaseConfig[],
  tradeText: string,
): BaseConfig | undefined =>
  base.filter((config: BaseConfig) => config.trade_text === tradeText)?.[0];
