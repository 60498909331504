import { Button, Header } from '@kanda-libs/ks-design-library';

import Container from './Header-container';
import { LOGOUT } from './Header-constants';

interface ViewComponentProps {
  /**
   * Logout callback
   */
  handleLogout?(...args: unknown[]): unknown;
}

const ViewComponent = ({ handleLogout }: ViewComponentProps) => (
  <Container>
    {({ onClick }) => (
      <Header.Base
        options={
          handleLogout && [
            <Button.Link
              id="account-header-logout"
              key="logout"
              variant="light-grey"
              size={16}
              onClick={handleLogout}
              className="mr-3 mt-2"
            >
              {LOGOUT}
            </Button.Link>,
          ]
        }
      >
        <Button.Icon
          id="account-header-back"
          icon="chevron-left"
          className="-ml-4"
          onClick={onClick}
        />
      </Header.Base>
    )}
  </Container>
);

ViewComponent.displayName = 'Header-view';

ViewComponent.defaultProps = {
  handleLogout: undefined,
};

export default ViewComponent;
