import { Field } from '@kanda-libs/ks-component-ts';
import { TERMS_PROPS, TERMS_VALIDATION } from './constants';
import SignUpFormFieldTermsText from './TermsText';
import useSignUpFormFieldsTerms from './useSignUpFormFieldsTerms';

const SignUpFormFieldTerms: React.FunctionComponent = function () {
  useSignUpFormFieldsTerms();
  return (
    <Field.Validator validation={TERMS_VALIDATION}>
      <Field.BooleanInput
        {...TERMS_PROPS}
        fieldText={<SignUpFormFieldTermsText />}
      />
    </Field.Validator>
  );
};

export default SignUpFormFieldTerms;
