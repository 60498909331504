export const OMNI_RESTRICTED_EMAIL_ADDRESSES = [
  'sales@',
  'info@',
  'enquiries@',
  'admin@',
  'mail@',
  'office@',
  'head@',
  'headteacher@',
  'reception@',
  'enquiry@',
  'marketing@',
  'post@',
  'contact@',
  'email@',
  'accounts@',
  'london@',
  'general@',
  'postmaster@',
  'enquires@',
  'design@',
  'support@',
  'mailbox@',
  'law@',
  'service@',
  'reservations@',
  'information@',
  'schooladmin@',
  'secretary@',
  'pr@',
  'enq@',
  'advice@',
  'webmaster@',
  'studio@',
  'bristol@',
  'headoffice@',
  'bookings@',
  'help@',
  'jobs@',
  'manager@',
  'property@',
  'helpdesk@',
  'clerks@',
  'bursar@',
  'recruit@',
  'manchester@',
  'enquries@',
  'postbox@',
  'contactus@',
  'administrator@',
  'editor@',
  'enquire@',
  'all@',
  'recruitment@',
  'insurance@',
  'md@',
  'hq@',
  'schooloffice@',
  'services@',
  'customerservice@',
  'birmingham@',
  'print@',
  'hire@',
  'headmaster@',
  'architects@',
  'admissions@',
  'events@',
  'it@',
  'solicitors@',
  'lawyers@',
  'uk@',
  'training@',
  'lettings@',
  'info.uk@',
  'production@',
  'business@',
  'contracts@',
  'finance@',
  'orders@',
  'news@',
  'solutions@',
  'customerservices@',
  'partners@',
  'hello@',
  'cardiff@',
  'leeds@',
  'school@',
  'team@',
  'ask@',
  'accountants@',
  'consult@',
  'operations@',
  'holidays@',
  'inquiries@',
  'hotel@',
  'edinburgh@',
  'editorial@',
  'commercial@',
  'nottingham@',
  'action@',
  'group@',
  'swindon@',
  'customer.services@',
  'hr@',
  'insure@',
  'norwich@',
  'care@',
  'shop@',
  'travel@',
  'feedback@',
  'Director@',
  'adminoffice@',
  'uksales@',
  'legal@',
  'PRINCIPAL@',
  'engineers@',
  'traffic@',
  'mailroom@',
  'registrar@',
  'parts@',
  'ops@',
  'stay@',
  'advertising@',
  'sales.uk@',
  'glasgow@',
  'administration@',
  'conferences@',
  'clerk@',
  'central@',
  'personnel@',
  'aberdeen@',
  'hostmaster@',
  'liverpool@',
  'exeter@',
  'careers@',
  'properties@',
  'ukinfo@',
  'purchasing@',
  'agency@',
  'architect@',
  'bradford@',
  'conference@',
  'projects@',
  'salesuk@',
  'sale@',
  'main@',
  'oxford@',
  'systems@',
  'management@',
  'ideas@',
  'me@',
  'welcome@',
  'furniture@',
  'postroom@',
  'enqs@',
  'leicester@',
  'art@',
  'press@',
  'chambers@',
  'quality@',
  'export@',
  'connect@',
  'inquires@',
  'hull@',
  'dundee@',
  'inbox@',
  'plymouth@',
  'people@',
  'croydon@',
  'europe@',
  'online@',
  'midlands@',
  'staff@',
  'books@',
  'coventry@',
  'construction@',
  'e-mail@',
  'info-uk@',
  'graphics@',
  'theteam@',
  'library@',
  'invest@',
  'newcastle@',
  'technical@',
  'NOEMAIL@',
  'NOMAIL@',
  'noemailadress@',
  'leisure@',
  'surveys@',
  'tech@',
  'edit@',
  'tourism@',
  'office.admin@',
  'rental@',
  'arts@',
  'details@',
  'trust@',
  'townhall@',
  'customercare@',
  'sales-uk@',
  'delivery@',
  'NOEMAIL@',
  'NOMAIL@',
  'noemailadress@',
  'leisure@',
  'surveys@',
  'tech@',
  'edit@',
  'tourism@',
  'office.admin@',
  'rental@',
  'arts@',
  'details@',
  'trust@',
  'townhall@',
  'customercare@',
  'sales-uk@',
  'delivery@',
  'townclerk@',
  'kontakt@',
  'bicester@',
  'abingdon@',
  'commerciale@',
  'amministrazione@',
  'comercial@',
  'auctions@',
  'auction@',
  'web@',
  'technik@',
  'trade@',
  'trading@',
  'infos@',
  'occasion@',
  'helpline@',
  'chairman@',
  'surveying@',
  'planning@',
  'corporate@',
  'home@',
  'informatique@',
  'master@',
  'root@',
  'club@',
  'shipping@',
  'used@',
  'treasurer@',
  'security@',
  'sport@',
  'architecture@',
  'reservation@',
  'media@',
  'development@',
  'president@',
  'hiredesk@',
  'repairs@',
  'franchise@',
  'boss@',
  'technique@',
  'freight@',
  'Equipment@',
  'membership@',
  'estate@',
  'hospitality@',
  'infodesk@',
  'general.enquiries@',
  'server@',
  'member@',
  'uk-info@',
  'bury@',
  'institute@',
  'analysis@',
  'INFORMATICA@',
  'vets@',
  'users@',
  'generalenquiries@',
  'schoolmail@',
  'admin.office@',
  'learning@',
  'producer@',
  'farmer@',
  'officeadmin@',
  'education@',
  'supervisor@',
  'schoolinfo@',
  'contact.us@',
  'school.office@',
  'webadmin@',
  'mainoffice@',
  'academy@',
  'frontdesk@',
  'euroinfo@',
  'procurement@',
  'salesinfo@',
  'academic.administrator@',
  'assistant@',
  'theoffice@',
  'genoffice@',
  'webmail@',
  'main.office@',
  'gen.enquiries@',
];
