import {
  AddressApiData,
  useFormContext,
  useWatch,
  ValidationItems,
  ValidationProps,
} from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { useCallback, useEffect, useState } from 'react';
import {
  getValidationConditions,
  getValidationErrors,
  validateAddressSelect,
} from 'utils';
import { ADDRESS_NAME, POSTCODE_NAME } from './constants';

interface AddressFieldsHook {
  addresses: AddressApiData | undefined;
  postcodeCallback: (res: AddressApiData) => void;
  manual: boolean;
  addManualInput: () => void;
  showSelect: boolean;
  selectProps: ValidationProps;
}

export default function useAddressFields(): AddressFieldsHook {
  const [addresses, setAddresses] = useState<AddressApiData | undefined>(
    undefined,
  );
  const [manual, setManual] = useState(false);

  const { setValue, getValues } = useFormContext();

  const postcodeCallback = useCallback(
    (results: AddressApiData) => setAddresses(results),
    [],
  );

  const [postcode, address] = useWatch({
    name: [POSTCODE_NAME, ADDRESS_NAME],
  });

  const showSelect = postcode && postcode !== '' && addresses;

  const addManualInput = () => setManual(!manual);

  const validation = {
    validate: {
      value: () => validateAddressSelect(getValues(), ADDRESS_NAME),
      message:
        'You must select an address or enter the address details manually',
    },
  } as ValidationItems;

  const selectProps = {
    validationConditions: getValidationConditions(validation),
    validationErrors: getValidationErrors(validation),
  } as unknown as ValidationProps;

  useEffect(() => {
    if (
      !address?.line_1 ||
      !addresses?.addresses ||
      addresses?.addresses?.length === 0
    )
      return;
    const match = addresses.addresses.findIndex(
      (addr) => (addr as StringIndexedObject).line_1 === address.line_1,
    );
    if (match === address?.selected) return;
    setValue(`${ADDRESS_NAME}.selected`, match);
  }, [address, addresses, setValue]);

  return {
    addresses,
    postcodeCallback,
    manual,
    addManualInput,
    showSelect,
    selectProps,
  };
}
