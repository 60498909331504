import OnboardingStep from '../../../../../components/OnboardingStep';
import { PC_TEXT } from './constants';

/**
 * Takes onboarding steps and returns the first item that has not been completed
 * @param onboardingSteps: object[]
 * @parma onboardingStepProgress: object
 */
export function findNextStepToComplete(
  onboardingSteps,
  onboardingStepProgress,
) {
  let nextStepKeyToComplete: string | null = null;

  Object.keys(onboardingStepProgress).forEach((stepKey) => {
    const completed = onboardingStepProgress[stepKey];

    if (!completed && nextStepKeyToComplete === null) {
      nextStepKeyToComplete = stepKey;
    }
  });

  const [nextStepToComplete] = onboardingSteps.filter(
    ({ key }) => key === nextStepKeyToComplete,
  );

  return nextStepToComplete;
}

/**
 * Return just the complete onboarding steps
 * @param onboardingSteps
 * @param onboardingStepProgress
 */
export function filterCompletedSteps(onboardingSteps, onboardingStepProgress) {
  return onboardingSteps.filter((step) => onboardingStepProgress[step.key]);
}

/**
 * Render an individual step
 * @param step
 * @param isLoading
 * @param active
 * @param stepsCompleted
 * @param number
 * @param setStep
 * @param isPremiumCredit
 * @returns {JSX.Element}
 */
export function renderStep(
  step,
  isLoading,
  active,
  stepsCompleted,
  number,
  setStep,
  isPremiumCredit = false,
) {
  return (
    <OnboardingStep
      key={`onboarding-step-${step.key}`}
      active={!isLoading && active}
      completed={stepsCompleted[step.key]}
      number={number}
      onClickSetUp={() => {
        setStep(step.onboardingStep);
      }}
      isLoading={isLoading}
      alternateStepText={
        step.key === 'subscription' && isPremiumCredit ? PC_TEXT : undefined
      }
      {...step}
    />
  );
}
