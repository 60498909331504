export const BYPASS_OMNI_IDS = [
  'ebc97705-8c8b-46db-af5a-8861e4cb2161',
  '37df7405-1ceb-43e5-97f0-dc7396013093',
  '306af18d-8e72-4a7b-9099-af394ed8c57f',
  'c0cd66a9-f6c9-4dd8-96a3-f89d102757a4',
  '0bd2d9e0-5456-43e6-887f-52407433b881',
  '50002427-fc16-47b2-ab55-0b2f2566b03d',
  'cd88c7b6-5c71-4949-8746-9871e926dd97',
  '4b81f4fc-bc2a-4335-bafe-143a7bbc80a3',
  'de47ae38-fb48-44e5-9881-9e3af568786e',
  '17aa654c-efbe-4648-85d6-4138698d16d7',
  '8af5c1c9-ecde-4f57-bdf8-188fa3136015',
  '89835de4-de8e-4b4c-84b5-bcf2b263385c',
  '7fc16ce1-7ff1-4302-b740-a96812a0f067',
  '3291e682-933d-4d50-a20d-16397710c279',
  '5347b94c-7ff0-4a51-a558-0849889ba154',
  '1ca1877e-7c69-4cab-8317-f896546dc4a4',
  '3e22ad5f-3c26-4084-8c9c-869e321aa6bd',
  'f23d6951-dc4b-499c-9017-d2cdb359658c',
  '7ba1054e-257d-42ae-a154-386cbd635388',
  '50549d82-e5dc-4918-a843-b8cca514568a',
  '41c70dd9-097e-4053-858d-9ee9c3ef3a9f',
  'a19e9a46-5a41-4554-bdd6-8addfc4e78e5',
  'd5b2edda-36d4-4b11-96dc-e1d749c3be58',
  'e530559d-7087-46d4-8b37-09d99d6b7531',
  '9af1f5ba-7578-440c-bd61-4b59bdcd420e',
  '3e5777c6-894b-4ae8-a1bf-1a6d95cab3f5',
  '852149d6-01a1-4339-b3ef-3eedea545420',
  '2f39aae1-6a12-4a6c-a369-27238ed3fff9',
  '91fc481e-67bd-426c-b3e8-2b92f0dd6b65',
  'd03e5e05-7df6-4f5e-bddb-6b7c24e25178',
  '1d0b0272-c29b-4a9c-b4c0-bdc9c6c7e6cd',
  'e93dc618-3403-4192-94fb-0a93d96f7048',
  '9d47073d-030d-43d0-9fdc-273d5b832f65',
  '09968ff2-b54c-4991-a0a3-cd75e45ed6fe',
];
