import type { InfoOnboarding } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  IN_REVIEW_DESCRIPTION,
  IN_REVIEW_FCA_DESCRIPTION,
  IN_REVIEW_LENDER_DESCRIPTION,
} from './CompanyInReview-constants';

export interface InReviewStatusHook {
  activeKey: number;
  heading: string;
}

const getActiveKey = (companyOnboarding: InfoOnboarding): number => {
  if (
    ['fca_awaiting_approval', 'fca_approved'].includes(
      companyOnboarding?.current_stage || '',
    )
  )
    return 2;
  if (
    companyOnboarding?.current_stage === 'online_reviewed' &&
    companyOnboarding?.current_approval?.outcome === 'PASS'
  )
    return 2;
  return 1;
};

export default function useInReviewStatus(): InReviewStatusHook {
  const data = useSelector(selectors.infoOnboarding.getEntitiesAsArray);
  const { company } = useCurrentCompany();

  const companyOnboarding = useMemo(() => data?.[0], [data]);

  const activeKey = useMemo(
    () => getActiveKey(companyOnboarding),
    [companyOnboarding],
  );

  const heading = useMemo(() => {
    if (!activeKey || activeKey === 1) return IN_REVIEW_DESCRIPTION;
    const unreg = company?.business_config?.lender_rate_type === 'unregulated';
    return unreg ? IN_REVIEW_LENDER_DESCRIPTION : IN_REVIEW_FCA_DESCRIPTION;
  }, [activeKey, company]);

  return {
    activeKey,
    heading,
  };
}
