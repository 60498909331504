import {
  useForm,
  useWatch,
  type DropzoneProps,
} from '@kanda-libs/ks-component-ts';
import type {
  FinanceRate,
  Job,
  JobItem,
} from '@kanda-libs/ks-frontend-services';
import useMostRecentJob from 'hooks/useMostRecentJob';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { CreateJobContextValue } from 'pages/CreateJob/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  filterForValidRates,
  getDepositPercentage,
} from '../../../../../../utils';
import { BASE_FORM, BASE_ITEM } from '../../../../CreateJob-constants';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  form: UseFormReturn<CreateJobContextValue>;
  disabled: boolean;
  onSubmit: (data: CreateJobContextValue) => void;
  dropZoneProps: DropzoneProps;
  showDepositFields: boolean;
  isSolar: boolean;
  showCustomerDetails: boolean;
  refreshJobDetails: () => void;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const [showCustomerDetails, setShowCustomerDetails] = useState<boolean>(true);
  const { data, handleContinue } = useCreateJobContextValue();

  const recentJob = useMostRecentJob();

  const isLoading = useSelector(selectors.getIsLoading);
  const company = data?.company;

  const showDepositFields =
    (company && company?.skip_deposit === 'Yes') || false;

  const isSolar = useMemo(() => {
    if (!data) return false;
    return data?.job?.job_type === 'solar';
  }, [data]);

  const depositPercentage = useMemo(
    () => getDepositPercentage(data?.job),
    [data],
  );

  const formatJobItems = (jobItems: JobItem[]): JobItem[] => {
    if (!jobItems) return [BASE_ITEM];
    return jobItems.map((item) => ({
      ...BASE_ITEM,
      ...item,
      price: {
        ...BASE_ITEM.price,
        ...item?.price,
      },
      vat: {
        ...BASE_ITEM.vat,
        ...item?.vat,
      },
    }));
  };

  const defaultValues = useMemo(() => {
    if (!data?.job && !isLoading)
      return {
        ...BASE_FORM,
        work_type: recentJob?.work_type || '',
      } as CreateJobContextValue;

    const existingJobItems = data?.job?.job_items;
    const recentJobWorkType = !isLoading && recentJob?.work_type;

    return {
      job: {
        ...data.job,
        work_type: data?.job?.work_type || recentJobWorkType || '',
        job_items: formatJobItems(
          (existingJobItems && existingJobItems.length > 0
            ? data?.job?.job_items
            : [BASE_ITEM]) || [],
        ),
      },
      depositPercentage,
    };
  }, [data, depositPercentage, recentJob?.work_type, isLoading]);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues as CreateJobContextValue,
  });

  const { setValue, reset } = form;

  const onSubmit = useCallback(
    (submittedData: CreateJobContextValue) => {
      const formattedData = {
        ...data,
        ...submittedData,
      };

      handleContinue(formattedData);
    },
    [data, handleContinue],
  );

  const [workType, financeOptions = []]: [
    Job['work_type'] | undefined,
    FinanceRate[] | undefined,
  ] = useWatch({
    name: ['job.work_type', 'job.finance_options'],
    control: form.control,
  });

  const normalValidNonSelectableRates = useMemo(
    () =>
      filterForValidRates(
        company?.finance_rates || [],
        workType,
        false,
        true,
        true,
      ),
    [company, workType],
  );

  const buyNowPayLaterValidNonSelectableRates = useMemo(
    () =>
      filterForValidRates(
        company?.finance_rates || [],
        workType,
        false,
        true,
        true,
      ),
    [company, workType],
  );

  const disabled = useMemo(
    () =>
      [
        ...normalValidNonSelectableRates,
        ...buyNowPayLaterValidNonSelectableRates,
        ...financeOptions,
      ].length === 0,
    [
      normalValidNonSelectableRates,
      buyNowPayLaterValidNonSelectableRates,
      financeOptions,
    ],
  );

  const onDrop = useCallback(
    (droppedFile) => {
      const newQuote = droppedFile?.length ? droppedFile[0] : droppedFile;
      if (!newQuote) {
        return;
      }

      (setValue as (name: string, value: string) => void)(
        'droppedFile',
        newQuote,
      );
    },
    [setValue],
  );

  const dropZoneProps = {
    disabled: data?.jobType === 'kanda',
    onDrop,
  };

  const refreshJobDetails = useCallback(() => {
    setShowCustomerDetails(false);
    setTimeout(() => setShowCustomerDetails(true));
  }, []);

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues as CreateJobContextValue);
  }, [reset, defaultValues]);

  // DEV_NOTE: this was moved to default values, seems to be working, leaving
  // this here just in case as not fully tested yet
  // // Effect sets deposit value if company isn't allowed
  // useEffect(() => {
  //   // if (!showDepositFields) return;
  //   setValue('job.deposit_type', 'no_deposit');
  //   setValue('job.deposit_value', {
  //     currency: 'GBP',
  //     amount: 0,
  //   });
  // }, [showDepositFields, setValue]);
  // // Effect sets checkout options
  // useEffect(() => {
  //   setValue('job.checkout_options', ['decline_job', 'pay_on_completion']);
  // }, [setValue]);

  return children({
    isLoading,
    form,
    disabled,
    onSubmit,
    dropZoneProps,
    showDepositFields,
    isSolar,
    showCustomerDetails,
    refreshJobDetails,
  });
};

ContainerComponent.displayName = 'CreateJob-Desktop-Details-container';

export default ContainerComponent;
