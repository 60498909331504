import { makeIsAllowed } from '@kanda-utils/library';

const Append = () => (
  <span className="relative field-focus:hidden field-error:hidden field-loading:hidden">
    <span className="py-1 -ml-4 inline-block text-style-f mt-px absolute">
      x
    </span>
  </span>
);

export const QUANTITY_COMPONENT_PROPS = {
  autoWidth: true,
  label: 'Qty',
  placeholder: '1',
  symbol: '',
  fixedDecimalScale: false,
  wrapperProps: {
    className: 'mr-7',
  },
  append: <Append />,
  isAllowed: makeIsAllowed(0, 100000),
  autoSize: true,
  quantity: true,
  type: 'price',
};
