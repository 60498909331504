import { Icon, Text } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES, ICON_PROPS, SKELETONS } from './BullutPoint-constants';

interface ViewComponentProps {
  /**
   * Next button label
   */
  title: string;
  /**
   * Loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({ title, isLoading }: ViewComponentProps) => (
  <div className={CLASS_NAMES.wrapper}>
    <div>
      <Icon {...ICON_PROPS} isLoading={isLoading} />
    </div>
    <Text
      isLoading={isLoading}
      text={title}
      className={CLASS_NAMES.title}
      skeletonProps={SKELETONS.title}
    />
  </div>
);

ViewComponent.displayName = 'PaymentAndFinanceOptions-Header-BulletPoint-view';

ViewComponent.defaultProps = {
  isLoading: false,
};

export default ViewComponent;
