import { useMemo } from 'react';
import type { Company } from '@kanda-libs/ks-frontend-services';
import useCurrentCompany from 'hooks/useCurrentCompany';
import useMe from 'hooks/useMe';

import {
  MENU_ITEMS,
  ADMIN_ITEMS,
  VERIFICATION_ITEM,
} from '../../../../MyAccount-constants';
import { useActiveLinks } from '../../../../../../hooks';
import { checkVerification } from '../../../../../../utils';

const ContainerComponent = ({ children }) => {
  const { company } = useCurrentCompany();
  const { me } = useMe();

  // TODO: change this up to actual verification enabler when ready
  const showVerification = checkVerification(company as Company);

  const isAdmin = me?.role !== 'company-staff';

  // TODO: Put this back in once other settings pages are ready
  const items = useMemo(
    () => [
      ...MENU_ITEMS,
      ...(isAdmin ? ADMIN_ITEMS : []),
      ...(isAdmin && showVerification ? [VERIFICATION_ITEM] : []),
    ],
    [showVerification, isAdmin],
  );

  return children({ items: useActiveLinks(items) });
};

ContainerComponent.displayName = 'DesktopLayout-SideBar-container';

export default ContainerComponent;
