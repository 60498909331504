const Other = () => (
  <div className="w-64 px-4 py-3 flex flex-col">
    <p className="text-12-18-em text-neutral-900 mb-3">Other Notifications</p>
    <p className="text-12-18 text-neutral-500">
      Notifications related to the company setup and settings changes
    </p>
  </div>
);

export default Other;
