const RatesTableHeader: React.FunctionComponent = function () {
  return (
    <>
      <p className="text-16-24-em text-neutral-500 mb-4">Interest</p>
      <p className="text-16-24-em text-neutral-500 mb-4">Term</p>
      <p className="text-16-24-em text-neutral-500 mb-4">Fee</p>
    </>
  );
};

export default RatesTableHeader;
