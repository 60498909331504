import { Icon, Text } from '@kanda-libs/ks-design-library';
import { SendSSOEmailButton } from 'components';
import SignUpFormCard from '../Card';
import EditSignUpMethod from '../EditSignUpMethod';
import useSignUpFormSuccess from './useSignUpFormSuccess';

const SignUpSuccess: React.FunctionComponent = function () {
  const { email } = useSignUpFormSuccess();
  return (
    <>
      <EditSignUpMethod />
      <SignUpFormCard>
        <Icon icon="send-quote-color" size={48} className="mb-6 mx-auto" />
        <Text
          text="Confirm your email"
          className="text-16-24-em mb-2 text-center"
        />
        <span className="text-14-22 text-neutral-600 text-center mb-6">
          Follow the link we emailed to{' '}
          <span className="text-14-22-em">{email}</span> to finish setting up
          your account.
        </span>
        <SendSSOEmailButton email={email} />
      </SignUpFormCard>
    </>
  );
};

export default SignUpSuccess;
