export const BULLETS = [
  {
    title: 'Add contact details',
    subText: 'Add the contact details for each director/owner of the business',
  },
  {
    title: 'Add date of birth & address',
    subText:
      "You'll be asked for the date of birth and address of each director/owner of the business",
  },
];
