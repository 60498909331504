import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { ADD_A_JOB_STEPS } from '../../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { setStep } = useCreateJobContextValue();

  const handleEdit = () => {
    if (setStep) setStep(ADD_A_JOB_STEPS.PAYMENT_AND_FINANCE_OPTIONS);
  };

  return children({
    handleEdit,
  });
};

ContainerComponent.displayName = 'PaymentMethods-Header-container';

export default ContainerComponent;
