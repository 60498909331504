export const TAC_FIELD = {
  name: 'terms_and_conditions',
  handle: 'checkbox',
  fieldText:
    'I have read the above contract and agree to set up a 12-month subscription to Kanda backed by a personal guarantee',
};

export const TAC_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'You must agree to terms and conditions',
  },
};

export const IAR_FIELD = {
  name: 'iar',
  handle: 'checkbox',
  fieldText: 'I agree to become an IAR of Kanda ',
};

export const IAR_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'You must agree to become and IAR of Kanda',
  },
};
