import { Card, Text, Button, Icon } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './UpgradeBanner-constants';
import useIsSubscribed from '../../../../hooks/useIsSubscribed';
import { SubscribeModal } from '../../../../pages/MyAccount/components';
import useSubscribeModal from '../../../../hooks/useSubscribeModal';

const UpgradeBanner = ({}) => {
  const { isSubscribed } = useIsSubscribed();
  const onClick = useSubscribeModal();

  if (isSubscribed) {
    return <></>;
  }

  return (
    <>
      <Card className="shadow-md">
        <Text
          text="Upgrade to send unlimited jobs"
          className={CLASS_NAMES.label}
        />
        <Button.Text
          id="upgrade-banner-upgrade-now"
          label="Upgrade now"
          variant="gradient"
          className={CLASS_NAMES.button}
          append={
            <>
              <Text text="£30/mo" className={CLASS_NAMES.price} />
              <Icon icon="arrow-right" />
            </>
          }
          onClick={onClick}
        />
      </Card>
      <SubscribeModal />
    </>
  );
};

export default UpgradeBanner;
