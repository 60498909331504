import type { FunctionComponent } from 'react';

import { Text } from '@kanda-libs/ks-design-library';

import clsx from 'clsx';

export interface OnboardingTitleProps {
  children: string;
  className?: string;
}

const OnboardingTitle: FunctionComponent<OnboardingTitleProps> = function ({
  children,
  className,
}) {
  return <Text text={children} className={clsx(className, 'text-24-28-em')} />;
};

export default OnboardingTitle;
