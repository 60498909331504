import useCurrentCompany from './useCurrentCompany';

export default function useCompanyLifecycle() {
  const { company } = useCurrentCompany();

  if (
    company?.lifecycle === 'director_details_submitted' &&
    Boolean(company?.company_info?.use_subcontractor) &&
    Boolean(company?.company_info?.trade_type) &&
    company?.company_info?.trade_type_name !== '' &&
    Boolean(company?.company_info?.trade_body) &&
    company?.company_info?.trade_body_name !== ''
  )
    return 'online_presence';

  return company?.lifecycle;
}
