import Container from './SubscriptionReturn-container';

import { Helmet } from '../../components';

const ViewComponent = () => (
  <>
    <Helmet title="Subscription Return" />
    <Container />
  </>
);

ViewComponent.displayName = 'SubscriptionReturn-view';

export default ViewComponent;
