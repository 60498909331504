export const PRICE_PROPS = {
  poundsClassName: 'text-16-20-em',
  centsClassName: 'text-10-14-em mt-px',
};

export const PRICE_EMPHASISED_PROPS = {
  poundsClassName: 'text-20-24-em',
  centsClassName: 'text-10-14-em mt-px',
};

export const PERCENTAGE_CLASS_NAME = 'text-16-20-em text-green-400';

export const PERCENTAGE_EMPHASISED_CLASS_NAME = 'text-20-24-em text-green-400';
