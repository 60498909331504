import { AlertBanner } from '@kanda-libs/ks-design-library-new';
import useFieldDisabledProps from 'pages/CreateJob/Desktop/components/Pages/Form/components/Customer/Field/useFieldDisabledProps';
import type { FunctionComponent } from 'react';

export interface WrapperProps {
  children: JSX.Element | JSX.Element[];
}

const Wrapper: FunctionComponent<WrapperProps> = function ({ children }) {
  const { isExampleJob, isLeadJob, disabledProps } = useFieldDisabledProps();

  return (
    <>
      {isExampleJob && (
        <div className="mb-6">
          <AlertBanner>
            You cannot edit customer details on an example job
          </AlertBanner>
        </div>
      )}
      {isLeadJob && (
        <div className="mb-6">
          <AlertBanner>
            You cannot edit customer details on a job that has been sent to you
            by Kanda
          </AlertBanner>
        </div>
      )}
      <div {...disabledProps}>{children}</div>
    </>
  );
};

export default Wrapper;
