import { Loader } from '@kanda-libs/ks-design-library';

import { Content } from './components';

interface ViewComponentProps {
  /**
   * isLoading
   */
  isLoading?: boolean;
}

const ViewComponent = ({ isLoading }: ViewComponentProps) => (
  <>
    <Content />
    {isLoading && <Loader isLoading={isLoading} />}
  </>
);

ViewComponent.displayName = 'AccountVerificationExplanation-Desktop-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
};

export default ViewComponent;
