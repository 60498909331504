import { useEffect } from 'react';
import { useToast } from '@kanda-libs/ks-design-library';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsExampleJob from 'pages/CreateJob/CreateJob-useIsExampleJob';

export interface FooterPropsHook {
  isExampleJob: boolean;
}

export default function useFooterProps(): FooterPropsHook {
  const { data } = useCreateJobContextValue();
  const { showError } = useToast();
  const isExampleJob = useIsExampleJob();

  useEffect(() => {
    if (isExampleJob) {
      setTimeout(() => {
        if (!data?.job?.redirect_urls?.checkout_url) {
          showError(
            'There was an error opening the checkout page. Please click the link in your email',
          );
          return;
        }
        window.location.href = data?.job?.redirect_urls?.checkout_url;
      }, 1000);
    }
  }, [isExampleJob, data, showError]);

  return {
    isExampleJob,
  };
}
