import { FormTheme } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';

import { CLASS_NAMES, REMOVE_BUTTON_PROPS } from './Desktop-constants';
import Container from './Desktop-container';

import { Title, Description, VAT, Quantity, Price, Total } from '../components';

interface ViewComponentProps {
  /**
   * Index of the item in the array
   */
  arrayName: string;
  /**
   * Index of the item in the array
   */
  index: number;
  /**
   * Function to remove an item
   */
  remove(...args: unknown[]): unknown;
  /**
   * State of whether to show remove button or not
   */
  showRemove: boolean;
}

const ViewComponent = ({
  index,
  arrayName,
  showRemove,
  remove,
}: ViewComponentProps) => (
  <Container index={index} showRemove={showRemove} remove={remove}>
    {({ removeButtonClassName, handleRemove }) => (
      <FormTheme variant="narrow">
        <div className={CLASS_NAMES.container}>
          <div className="flex flex-row relative">
            <div>
              <Quantity arrayName={arrayName} index={index} />
            </div>
            <div className="flex flex-1 flex-col mr-4 min-w-64">
              <Title arrayName={arrayName} index={index} />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap items-start justify-start">
                <Price arrayName={arrayName} index={index} />

                <VAT arrayName={arrayName} index={index} />

                <div className="flex flex-row ml-1 min-w-24 justify-end h-full">
                  <Total arrayName={arrayName} index={index} inFocus={false} />
                </div>
                <div className={removeButtonClassName}>
                  <Button.Icon
                    {...REMOVE_BUTTON_PROPS}
                    onClick={handleRemove}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pl-14">
            <Description arrayName={arrayName} index={index} />
          </div>
        </div>
      </FormTheme>
    )}
  </Container>
);

ViewComponent.displayName = 'Item-Desktop-view';

export default ViewComponent;
