import { Widget } from '@kanda-libs/ks-component-ts';

import { QUANTITY_COMPONENT_PROPS } from './Quantity-constants';

interface ViewComponentProps {
  /**
   * Name of field
   */
  arrayName: string;
  /**
   * Default value for field
   */
  index: number;
}

const ViewComponent = ({ ...props }: ViewComponentProps) => (
  <Widget.JobJobItemsQuantityArrayInput
    {...QUANTITY_COMPONENT_PROPS}
    {...props}
  />
);

ViewComponent.displayName = 'Item-Quantity-view';

export default ViewComponent;
