import { Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import {
  CLASS_NAMES,
  DESCRIPTION,
  FIELDS,
  FORGOT_PASSWORD_BUTTON,
  LINK_TEXT,
  SIGNUP_TEXT,
  TITLE,
} from './Content-constants';
import Container from './Content-container';

import { URLS } from '../../../../config';

const ViewComponent = ({}) => (
  <Container>
    {({ to, emailValidation, onEmailBlur }) => (
      <>
        <div className={CLASS_NAMES.headerWrapper}>
          <p className={CLASS_NAMES.title}>{TITLE}</p>
          <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
        </div>
        <Field.Validator validation={emailValidation}>
          <Field.Input
            {...FIELDS.email}
            onBlur={() => onEmailBlur(FIELDS.email.name)}
          />
        </Field.Validator>
        <Field.PasswordInput
          {...FIELDS.password}
          helperText={
            <Link to={URLS.forgotPassword}>
              <Button.InlineLink {...FORGOT_PASSWORD_BUTTON} />
            </Link>
          }
        />
        <span className={CLASS_NAMES.text}>
          {SIGNUP_TEXT}
          <Link to={to} className={CLASS_NAMES.linktext}>
            {LINK_TEXT}
          </Link>
        </span>
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'Login-Content-view';

export default ViewComponent;
