import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Text,
} from '@kanda-libs/ks-design-library';
import StaffImitationForm from 'components/DesktopHeader/components/StaffImitationForm';
import type { FunctionComponent } from 'react';

const Staff: FunctionComponent = function () {
  return (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent>
        <Text className="mb-4 text-20-24-em" text="Staff portal" />
        <StaffImitationForm />
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );
};

export default Staff;
