import Container from './Mobile-container';

import { Title, Description, Quantity, Price, Vat, Total } from '../components';

const ViewComponent = () => (
  <Container>
    {({ classNames, smaller }) => (
      <div className={classNames.mobile.container}>
        <div className="flex flex-row w-full item-center justify-between">
          {smaller && <Quantity />}
          <Title />
          {smaller && <Total />}
        </div>
        <Description />
        {!smaller && (
          <div className={classNames.mobile.values}>
            <Quantity />
            <Price />
            <Vat />
            <Total />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Mobile-view';

export default ViewComponent;
