import { Tag } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './Title-constants';

import { PriceStatus } from '../../../../../../../components';

interface ViewComponentProps {
  /**
   * First name of customer
   */
  firstName?: string;
  /**
   * Last name of customer
   */
  lastName?: string;
  /**
   * Price
   */
  total?: number;
  /**
   * Job status
   */
  status?: string;
  /**
   * Finance status
   */
  financeStatus?: string;
  /**
   * ClassName for wrapper
   */
  skeletonClassName?: string;
  /**
   * Display Loading state
   */
  isLoading?: boolean;
  /**
   * Skeleton props
   */
  skeletonProps?: object;
  /**
   * Display placeholder jobs
   */
  showingPlaceholderJobs?: boolean;
}

const ViewComponent = ({
  firstName,
  lastName,
  total,
  status,
  financeStatus,
  showingPlaceholderJobs,
  ...rest
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    {showingPlaceholderJobs && (
      <Tag className="mr-3" label="Preview" size={21} variant="solid" />
    )}
    <span className={CLASS_NAMES.title}>
      {`${firstName || 'No name provided'} ${lastName || ''}`}
    </span>
    <PriceStatus
      total={total as number}
      status={status as string}
      financeStatus={financeStatus as string}
      {...rest}
    />
  </div>
);

ViewComponent.displayName = 'Mobile-Card-Title-view';

ViewComponent.defaultProps = {
  firstName: '',
  lastName: '',
  total: 0,
  status: 'draft',
  financeStatus: 'not_applied',
  skeletonClassName: '',
  skeletonProps: {},
  isLoading: false,
};

export default ViewComponent;
