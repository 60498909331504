import type { TableRow } from '@kanda-libs/ks-component-ts';
import Container from './HoverPopover-container';
import { JobMenu } from '../../../../../components';

interface ViewComponentProps {
  /**
   * Table column data
   */
  row: TableRow;
  /**
   * Handle close
   */
  handleClose?: () => void;
}

const ViewComponent = ({ row, handleClose }: ViewComponentProps) => (
  <Container row={row}>
    {({ ...props }) => <JobMenu {...props} handleClose={handleClose} />}
  </Container>
);

ViewComponent.displayName = 'HoverPopover-view';

export default ViewComponent;
