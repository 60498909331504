import {
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
} from '@kanda-libs/ks-design-library';

import { Content, Footer } from '../components';

interface ViewComponentProps {
  /**
   * Disabled state
   */
  disabled?: boolean;
  /**
   * Disabled state for social buttons
   */
  socialDisabled?: boolean;
  /**
   * Function to sign in with Google
   */
  signInWithGoogle?(...args: unknown[]): unknown;
  /**
   * Function to sign in with FB
   */
  signInWithFb?(...args: unknown[]): unknown;
}

const ViewComponent = ({ disabled, ...props }: ViewComponentProps) => (
  <DesktopLayoutBoxed>
    <DesktopLayoutBoxedContent>
      <Content />
      <Footer disabled={disabled} {...props} />
    </DesktopLayoutBoxedContent>
  </DesktopLayoutBoxed>
);

ViewComponent.displayName = 'Login-Desktop-view';

ViewComponent.defaultProps = {
  disabled: false,
  socialDisabled: undefined,
  signInWithGoogle: undefined,
  signInWithFb: undefined,
};

export default ViewComponent;
