import { CLASS_NAMES } from './CompanyBanner-constants';
import Container from './CompanyBanner-container';
import { CompanyInReview, FinishSetup } from './components';

interface ViewComponentProps {
  homeBanner?: boolean;
  className?: string;
}

const ViewComponent = ({ homeBanner, className }: ViewComponentProps) => (
  <Container>
    {({ isLoading, showBanner, showFca, setupInProgress, companyInReview }) => (
      <>
        {!isLoading && showBanner && (
          <div className={CLASS_NAMES.container}>
            {setupInProgress && !homeBanner && <FinishSetup />}
            {companyInReview && (
              <CompanyInReview
                showIcon={homeBanner}
                showFca={showFca}
                className={className}
              />
            )}
          </div>
        )}
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyBanner-view';

ViewComponent.defaultProps = {
  homeBanner: false,
};

export default ViewComponent;
