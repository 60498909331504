import type { ButtonLinkVariant } from '@kanda-libs/ks-design-library';
import { BUTTON_CLASSNAME } from '../../Confirmation-constants';

export const CALL_BUTTON_PROPS = {
  id: 'send-confirmation-call-button',
  variant: 'grey' as ButtonLinkVariant,
  size: 14,
  icon: 'phone',
  label: 'Call',
  left: true,
  className: `${BUTTON_CLASSNAME} mr-3`,
};
