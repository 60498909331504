import { createContext } from 'react';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';

export interface LineItemContextValue {
  price?: number;
  quantity?: number;
  title?: string;
  total?: number;
  vat?: number;
  isLoading?: boolean;
  description?: string;
  quoteDocument?: {
    name: string;
  };
  classNames: StringIndexedObject;
  style?: string;
}

const Context = createContext<LineItemContextValue>({
  classNames: {},
});

Context.displayName = 'LineItem-Context';

export default Context;
