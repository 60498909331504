import { Field, Form } from '@kanda-libs/ks-component-ts';
import { Button, CopyContent } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import { COPIED_BUTTON_PROPS } from './constants';
import useLegalText from './useLegalText';

const LegalTextBox: FunctionComponent = function () {
  const { form, text } = useLegalText();

  return (
    <Form id="update-your-website-legal" form={form} onSubmit={() => {}}>
      <Field.TextAreaInput type="textarea" name="legal" value={text} rows={5} />
      <div className="flex flex-row items-center justify-center gap-x-4">
        <CopyContent
          content={text}
          clickedView={<Button.Link {...COPIED_BUTTON_PROPS} size={14} />}
          className="w-full"
        >
          <Button.Text
            id="update-your-website-calculator-copy-code"
            label="Copy text"
            icon="copy"
            size={40}
            className="w-full"
          />
        </CopyContent>
      </div>
    </Form>
  );
};

export default LegalTextBox;
