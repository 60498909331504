import { Header, Button, Text } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';

import { PAYMENT_MODAL_ID } from '../Mobile-constants';

const ViewComponent = () => (
  <ModalContainer id={PAYMENT_MODAL_ID}>
    {({ handleClose }) => (
      <ModalLayoutFullScreen
        noBorder
        header={
          <Header.Base
            className="text-style-f-em text-neutral-700"
            options={[
              <Button.Icon
                id="account-settings-subscription-payment-modal-close"
                key="close"
                onClick={handleClose}
                icon="close"
              />,
            ]}
          />
        }
      >
        <Text text="Payment Info" className="mb-8 text-16-20-em" />
      </ModalLayoutFullScreen>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'PaymentInfoModal-view';

export default ViewComponent;
