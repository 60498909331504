import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import type { FinanceRatesHook } from 'hooks/useFinanceRates';

import CombinedOption from '../CombinedOption';
import OptionGroupHeader from '../OptionGroupHeader';
import SlimOption from '../SlimOption';

export type OptionsProps = FinanceRatesHook;

const Options = ({
  isLoading,
  hideHandle,
  combinedRates,
  keys,
}: // unregulated,
OptionsProps) => {
  /**
   * Function that is passed to options.map to render
   * individual option line items
   */
  function renderOptions(
    options: FinanceRate[],
    handleHidden: boolean,
  ): JSX.Element[] {
    return options.map((option) => (
      <SlimOption
        key={option.name}
        financeOptions={[option]}
        hideHandle={handleHidden}
      />
    ));
  }

  return (
    <div className="flex flex-col">
      {keys.map((rateKey) => (
        <div className="mt-3 first:mt-0" key={rateKey}>
          <OptionGroupHeader label={rateKey} isLoading={isLoading} />
          {Object.keys(combinedRates[rateKey]).map((key) => {
            const rates = combinedRates[rateKey][key];
            const combined = rates.length > 1;
            return combined ? (
              <CombinedOption
                key={key}
                options={rates}
                hideHandle={hideHandle}
              />
            ) : (
              renderOptions(rates, hideHandle)
            );
          })}
        </div>
      ))}
    </div>
  );
};

Options.displayName = 'PaymentAndFinanceOptions-Options-view';

export default Options;
