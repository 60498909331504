import { Form as KandaForm, Widget } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { Users } from 'pages/MyAccount/TeamSettings/components/Content/components';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import useInviteForm from '../Form/useInviteForm';
import Layout from '../Layout';

const Summary: FunctionComponent = function () {
  const { form, onSubmit, defaultValues, isSubmitting, onFinish } =
    useInviteForm();
  return (
    <Layout>
      <KandaForm form={form} onSubmit={onSubmit} id="welcome-invite-team">
        <div className="flex flex-col items-center justify-center text-left">
          <Widget.CompanyUsersArrayWrapper>
            {({ arrayName, fields, arrayProps: { append, ...rest } }) => (
              <>
                <Users
                  fields={fields}
                  arrayName={arrayName}
                  defaultValues={defaultValues}
                  canUserEdit={false}
                  isSubmitting={isSubmitting}
                  {...rest}
                />
              </>
            )}
          </Widget.CompanyUsersArrayWrapper>
          <Link to={URLS.inviteForm} className="w-full">
            <Button.Text
              left
              id="welcome-invite-summary-invite-team"
              label="Invite team member"
              icon="plus"
              className="w-full mx-auto mt-8"
            />
          </Link>
          <div className="w-full mt-4 text-center md:mt-24">
            <Button.Text
              left
              id="update-your-website-finish"
              label="Finish"
              icon="check"
              variant="outline"
              className="w-full md:w-auto"
              disabled={isSubmitting}
              onClick={onFinish}
            />
          </div>
        </div>
      </KandaForm>
    </Layout>
  );
};

export default Summary;
