import { Header, Button, Loader } from '@kanda-libs/ks-design-library';

import Container from './Header-container';
import { SlideUp } from './components';

// import { BackLink } from '../../../../../components';

const ViewComponent = () => (
  <Container>
    {({
      openSlideUp,
      jobId,
      status,
      handleClick,
      downloadPdf,
      isSubmitting,
    }) => (
      <Header.Base
        options={[
          <Button.Icon
            id="job-details-mobile-header-mobile"
            key="more"
            icon="more"
            onClick={openSlideUp}
          />,
        ]}
      >
        <Button.Icon
          id="job-details-mobile-header-back"
          onClick={handleClick}
          icon="chevron-left"
          className="-ml-3"
        />
        <SlideUp jobId={jobId} status={status} downloadPdf={downloadPdf} />
        <Loader isLoading={isSubmitting} />
      </Header.Base>
    )}
  </Container>
);

ViewComponent.displayName = 'Job-Header-view';

export default ViewComponent;
