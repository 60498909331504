export const NO_CUSTOMER_TEXT = 'No customer added...';

export const NO_EMAIL_TEXT = 'No email added...';

export const CLASS_NAMES = {
  body: 'flex flex-1 w-full h-full',
};

export const TITLE = 'Job sent to ';

export const EXAMPLE_JOB_TITLE = 'Your example job has been created';
export const EXAMPLE_JOB_EMAIL_TEXT =
  'You will be redirected into the checkout experience shortly';
