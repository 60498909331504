import { SkeletonLoader, StatusTag, Tag } from '@kanda-libs/ks-design-library';
import type { Job } from '@kanda-libs/ks-frontend-services';
import { useContext, type FunctionComponent, type HTMLAttributes } from 'react';

import Context from 'pages/Jobs/Context';
import { STATUS_TAG_PROPS } from './CustomerName-constants';

export interface ViewComponentProps {
  /**
   * Text to display
   */
  customer?: {
    firstName: string;
    lastName: string;
  };
  /**
   * Job status
   */
  status?: string;
  /**
   * Job flow type
   */
  flowType?: Job['flow_type'];
  /**
   * Job quoted to
   */
  quotedTo?: Job['quoted_to'];
  /**
   * ClassName for wrapper
   */
  className: {
    draft?: string;
    standard?: string;
  };
  /**
   * ClassName for wrapper
   */
  skeletonClassName?: string;
  /**
   * Display Loading state
   */
  isLoading?: boolean;
  /**
   * linkComponent
   */
  textComponent?: React.ElementType;
  /**
   * Skeleton props
   */
  skeletonProps?: object;
}

const ViewComponent = ({
  customer,
  status = 'status',
  flowType,
  quotedTo,
  isLoading = false,
  textComponent: TextComponent = 'span',
  className = {
    draft: 'text-16-20-em',
    standard: 'text-16-20-em',
  },
  skeletonClassName = '',
  skeletonProps = {},
}: ViewComponentProps) => {
  const TextComponentTag = TextComponent as FunctionComponent<
    HTMLAttributes<HTMLDivElement>
  >;
  const { showingPlaceholderJobs } = useContext(Context);
  const isDraftJob = status === 'draft';
  const isExampleJob = flowType === 'example';
  const isLeadJob = isDraftJob && !!quotedTo;

  return (
    <>
      <SkeletonLoader
        isLoading={isLoading}
        className={skeletonClassName}
        {...skeletonProps}
        afterLoading={
          <div className="flex flex-row max-w-full">
            {showingPlaceholderJobs && (
              <Tag
                {...STATUS_TAG_PROPS}
                label="Preview"
                size={21}
                variant="solid"
              />
            )}
            {isExampleJob && (
              <Tag
                {...STATUS_TAG_PROPS}
                label="Example"
                size={21}
                color="lavender"
                variant="solid"
              />
            )}
            {isDraftJob && (
              <StatusTag
                {...STATUS_TAG_PROPS}
                status="draft"
                size={21}
                variant="solid"
              />
            )}
            {isLeadJob && (
              <StatusTag
                {...STATUS_TAG_PROPS}
                status="lead"
                size={21}
                variant="solid"
              />
            )}
            <TextComponentTag
              className={
                status === 'draft' ? className.draft : className.standard
              }
            >
              <>
                {`${customer?.firstName || 'No name provided'} ${
                  customer?.lastName || ''
                }`}
              </>
            </TextComponentTag>
          </div>
        }
      />
    </>
  );
};

ViewComponent.displayName = 'CustomerName-view';

export default ViewComponent;
