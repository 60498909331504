import { FormTheme, Widget } from '@kanda-libs/ks-component-ts';
import type { UserType } from '@kanda-libs/ks-frontend-services';

import {
  BreakPoints,
  Button,
  Popover,
  Tag,
} from '@kanda-libs/ks-design-library';
import UserMenu from '../UserMenu';
import UserModal from '../UserModal';
import Container from './Existing-container';

export interface ExistingProps {
  user: UserType;
  arrayName: string;
  canUserEdit: boolean;
  setUserToDelete: (user: UserType) => void;
}

const ViewComponent = (props: ExistingProps) => (
  <Container {...props}>
    {({
      name,
      email,
      mobile,
      director,
      role,
      verified,
      index,
      arrayName,
      canUserEdit,
      setUserToDelete,
      handleShowModal,
      modalId,
    }) => (
      <div className="flex flex-row justify-between border-0 border-b md:border md:border-b-0 md:last:border-b border-neutral-300 rounded-none md:first:rounded-t-lg md:last:rounded-b-lg py-4 md:py-2.5 px-0 md:pl-4 md:pr-2 text-neutral-700">
        <div className="flex flex-col mr-3">
          <div className="flex flex-row">
            <p className="text-14-22-em">{name}</p>
            {director && (
              <Tag
                label="owner"
                color={verified ? 'green' : 'violet'}
                size={21}
                variant="solid"
                className="ml-3"
              />
            )}
          </div>
          {email && (
            <p className="break-all text-10-14 md:text-14-22">{email}</p>
          )}
          {mobile && (
            <p className="break-all text-10-14 md:text-14-22">{mobile}</p>
          )}
          <div className="block w-full mt-2 md:hidden min-w-28 max-w-28">
            {!director && canUserEdit ? (
              <FormTheme variant="inline">
                <Widget.CompanyUsersRoleArraySelect
                  arrayName={arrayName}
                  index={index}
                  type="Select"
                  options={[
                    { name: 'Admin', value: 'company-admin' },
                    { name: 'Manager', value: 'company-manager' },
                    { name: 'Member', value: 'company-staff' },
                  ]}
                />
              </FormTheme>
            ) : (
              <p className="w-full ml-auto text-14-22 ">{role}</p>
            )}
          </div>
        </div>
        <div className="flex flex-row md:min-w-38">
          <div className="hidden w-full my-auto md:block min-w-28 max-w-28">
            {!director && canUserEdit ? (
              <FormTheme variant="inline">
                <Widget.CompanyUsersRoleArraySelect
                  arrayName={arrayName}
                  index={index}
                  type="Select"
                  options={[
                    { name: 'Admin', value: 'company-admin' },
                    { name: 'Manager', value: 'company-manager' },
                    { name: 'Member', value: 'company-staff' },
                  ]}
                />
              </FormTheme>
            ) : (
              <p className="w-full ml-auto text-14-22 ">{role}</p>
            )}
          </div>
          {canUserEdit && (
            <BreakPoints
              desktop={
                !director ? (
                  <div className="my-auto">
                    <Popover.Standard
                      id={`team-settings-existing-more-${index}`}
                      className="my-auto"
                      right
                      button={
                        <Button.Icon
                          id="team-settings-existing-more"
                          icon="more"
                          size="32-16"
                        />
                      }
                    >
                      {({ handleClose }) => (
                        <UserMenu
                          index={index as number}
                          setUserToDelete={setUserToDelete}
                          handleClose={handleClose}
                          director={director}
                        />
                      )}
                    </Popover.Standard>
                  </div>
                ) : (
                  <></>
                )
              }
              mobile={
                <>
                  <Button.Icon
                    id={`team-settings-existing-more-${index}`}
                    onClick={handleShowModal}
                    icon="more"
                    className="my-auto"
                    size="32-16"
                  />
                  <UserModal
                    id={modalId}
                    index={index as number}
                    setUserToDelete={setUserToDelete}
                    director={director}
                  />
                </>
              }
            />
          )}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Users-Existing-view';

export default ViewComponent;
