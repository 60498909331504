import { Field } from '@kanda-libs/ks-component-ts';

import {
  TITLE,
  DESCRIPTION,
  WRAP_FIELDS,
  EMAIL_VALIDATION,
} from './Form-constants';

import { FORM_CLASS_NAMES } from '../../../AccountSettings-constants';
import { SubPageFooter } from '../../../components';

export interface ViewComponentProps {
  /**
   * Is loading flag
   */
  isLoading?: boolean;
  /**
   * Disabled
   */
  passwordValidation?: object;
  /**
   * On submit function
   */
  confirmPasswordValidation?: object;
  /**
   * Is loading flag
   */
  disabled?: boolean;
}

const ViewComponent = ({ isLoading, disabled }: ViewComponentProps) => (
  <div className="flex flex-col md:w-96">
    <p className={FORM_CLASS_NAMES.title}>{TITLE}</p>
    <p className={FORM_CLASS_NAMES.description}>{DESCRIPTION}</p>
    <div className={FORM_CLASS_NAMES.fields}>
      <Field.Validator validation={EMAIL_VALIDATION}>
        <Field.Input {...WRAP_FIELDS.email} isLoading={isLoading} />
      </Field.Validator>
      <Field.PasswordInput
        {...WRAP_FIELDS.currentPassword}
        isLoading={isLoading}
      />
    </div>
    <SubPageFooter disabled={disabled} />
  </div>
);

ViewComponent.defaultProps = {
  disabled: false,
  isLoading: false,
};

ViewComponent.displayName = 'ChangePasswordForm-view';

export default ViewComponent;
