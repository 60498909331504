import { BreakPoints, Icon } from '@kanda-libs/ks-design-library';

import {
  ICON_PROPS,
  HEADING,
  SUBHEADING,
  CLASS_NAMES,
} from './Header-constants';

const ViewComponent = ({}) => (
  <div className={CLASS_NAMES.container}>
    <BreakPoints
      mobile={<Icon {...ICON_PROPS.mobile} />}
      desktop={<Icon {...ICON_PROPS.desktop} />}
    />
    <div className={CLASS_NAMES.text}>
      <p className={HEADING.className}>{HEADING.text}</p>
      <p className={SUBHEADING.className}>{SUBHEADING.text}</p>
    </div>
  </div>
);

ViewComponent.displayName = 'Header-view';

export default ViewComponent;
