import { Button, Text, Loader } from '@kanda-libs/ks-design-library';
import { Form } from '@kanda-libs/ks-component-ts';

import Container from './Content-container';
import { TITLE, CLASS_NAMES } from '../../NotificationPreferences-constants';
import Desktop from './Desktop';

const ViewComponent = () => (
  <Container>
    {({
      isLoading,
      form,
      onSubmit,
      isSubmitting,
      hasCommsPrefs,
      addPreferences,
    }) => (
      <div className="flex flex-col flex-1">
        <Text
          isLoading={isLoading}
          text={TITLE}
          className={CLASS_NAMES.title}
          skeletonProps={{
            width: 200,
          }}
        />
        <Form
          id="notifications-preferences"
          form={form}
          onSubmit={onSubmit}
          className="w-full flex flex-col"
        >
          {hasCommsPrefs && (
            <>
              <Desktop />
              <Button.Text
                id="update-notifications-preferences"
                label="Save"
                icon="send"
                left
                submit
                className="-mt-8 w-40 mr-auto"
                disabled={isSubmitting}
              />
            </>
          )}
        </Form>
        {!hasCommsPrefs && (
          <Button.Text
            id="add-notifications-preferences"
            label="Manage notifications preferences"
            icon="user"
            left
            className="mt-8 mr-auto"
            onClick={addPreferences}
          />
        )}
        <Loader isLoading={isSubmitting} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'NotificationPreferences-Content-view';

export default ViewComponent;
