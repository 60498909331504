import { makeIsAllowed } from '@kanda-utils/library';

const Append = () => (
  <span className="hidden opacity-0 field-focus:inline-block field-error:inline-block">
    £
  </span>
);

export const PRICE_VALIDATION = {
  required: {
    value: true,
    message: 'Price is required',
  },
  min: {
    value: 0,
    message: 'Price is required',
  },
};

export const PRICE_COMPONENT_PROPS = {
  autoWidth: true,
  label: 'Price',
  placeholder: '1.00',
  prefix: '',
  wrapperProps: {
    className: 'mr-2',
  },
  append: <Append />,
  isAllowed: makeIsAllowed(0, 1000000),
  autoSize: true,
  type: 'price',
  validation: PRICE_VALIDATION,
};
