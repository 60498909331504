export const TITLE_FIELD_PROPS = {
  mobile: {
    label: 'Name your job',
    placeholder: 'Title of the job, e.g. New Boiler',
  },
  desktop: {
    label: 'Job title',
    placeholder: 'Title of the job, e.g. New Boiler',
  },
};

export const FORM_THEME = {
  mobile: 'emphasized',
  desktop: 'large',
};
