import { useMemo } from 'react';
import { STEPS, STEP_CLASSNAMES, type StepBasis } from './constants';

interface IndicatorStep {
  label: string;
  className: string;
}

interface IndicatorClassNames {
  wrapper: string;
  progress: string;
}

export interface StepIndicatorHook {
  steps: IndicatorStep[];
  indicatorClassNames: IndicatorClassNames;
}

export default function useStepIndicator(): StepIndicatorHook {
  const steps: IndicatorStep[] = useMemo(
    () =>
      STEPS.map((step: StepBasis, index: number) => ({
        label: step.label,
        className:
          index === 0 ? STEP_CLASSNAMES.active : STEP_CLASSNAMES.default,
      })),
    [],
  );

  const indicatorClassNames = useMemo(
    () => ({
      wrapper: 'w-full rounded-full h-2 bg-neutral-200',
      progress: 'rounded-full h-2 bg-green-500 w-4',
    }),
    [],
  );

  return {
    steps,
    indicatorClassNames,
  };
}
