import Header from './Header';
import CompanyDetails from './CompanyDetails';
import JobDetails from './JobDetails';
import PaymentMethods from './PaymentMethods';
import CompleteAccountBadge from './CompleteAccountBadge';
import UpgradeAccountBadge from './UpgradeAccountBadge';
import AccountCompletedBadge from './AccountCompletedBadge';

export default {
  Header,
  CompanyDetails,
  JobDetails,
  PaymentMethods,
  CompleteAccountBadge,
  UpgradeAccountBadge,
  AccountCompletedBadge,
};
