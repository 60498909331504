import { Field, PasswordStrengthIndicator } from '@kanda-libs/ks-component-ts';
import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import SignUpFormCard from '../Card';
import {
  CONFIRM_PASSWORD_FIELD_PROPS,
  PASSWORD_FIELD_PROPS,
} from './constants';
import useClaimAccountFormFields from './useClaimAccountFormFields';

interface ClaimAccountFormFieldsProps {
  isSubmitting: boolean;
  isLoading: boolean;
  disabled: boolean;
}

const ClaimAccountFormFields: React.FunctionComponent<ClaimAccountFormFieldsProps> =
  function ({ isSubmitting, isLoading, disabled }) {
    const { passwordValidation, confirmPasswordValidation } =
      useClaimAccountFormFields();
    return (
      <SignUpFormCard>
        <p className="text-18-22-em mb-4">Set a password</p>
        <div className="flex flex-col mb-auto">
          <Field.Validator validation={passwordValidation}>
            <Field.PasswordInput
              {...PASSWORD_FIELD_PROPS}
              isLoading={isLoading}
            />
          </Field.Validator>
          <PasswordStrengthIndicator
            passwordFieldName="password"
            passwordScoreFieldName="password_strength"
            description="Your password must have a strength of at least 'Good'"
          />
          <Field.Validator validation={confirmPasswordValidation}>
            <Field.PasswordInput
              {...CONFIRM_PASSWORD_FIELD_PROPS}
              isLoading={isLoading}
            />
          </Field.Validator>
          <BreakPoints
            desktop={
              <Button.Text
                id="sign-up-submit"
                submit
                className="w-full mt-3"
                label="Save Password"
                icon="arrow-right"
                variant="gradient"
                disabled={isSubmitting || disabled}
              />
            }
          />
        </div>
      </SignUpFormCard>
    );
  };

export default ClaimAccountFormFields;
