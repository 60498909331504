import { API_DOCS_LINK } from 'components/DesktopHeader/DesktopHeader-constants';
import { URLS } from '../../config/application';

export const NAV_MENU_ID = 'menu';

export const CLASS_NAMES = {
  header: {
    container: 'flex flex-1 flex-row items-center bg-neutral-000 pr-20',
    defaultAvatar:
      'w-12 h-12 my-auto lavender-200 rounded-full flex justify-center items-center',
    loader: 'w-12 h-12 my-auto rounded-full bg-neutral-100',
    companyProfilePicture: 'w-12 h-12 my-auto rounded-full object-contain',
    infoContainer: 'flex flex-1 flex-col ml-2 justify-center my-auto',
    companyName: 'text-style-f-em text-neutral-900',
    plan: 'text-style-g text-neutral-600',
  },
};

export const DEFAULT_AVATAR_ICON_PROPS = {
  icon: 'user-square',
  size: 24,
  stroke: 'neutral-500',
};

export const SKELETONS = {
  banner: {
    height: 60,
  },
  companyName: {
    width: 160,
  },
  plan: {
    width: 120,
  },
};

export const MENU_LINKS = [
  { icon: 'home', stroke: 'neutral-600', name: 'Home', to: URLS.home },
  { icon: 'job', stroke: 'neutral-600', name: 'Jobs', to: URLS.jobs },
  {
    icon: 'currency',
    stroke: 'neutral-600',
    name: 'Refer another company',
    to: URLS.referrals,
  },
  {
    icon: 'settings',
    name: 'Account settings',
    to: URLS.myAccount,
    stroke: 'neutral-600',
  },
];

export const MENU_ADMIN_LINKS = [
  {
    icon: 'note',
    name: 'API Docs',
    to: window.location.pathname,
    onClick: () => window.open(API_DOCS_LINK, '_blank')?.focus(),
    stroke: 'neutral-600',
  },
];

export const LOGOUT_LINK = {
  icon: 'logout',
  name: 'Log Out',
  to: URLS.logout,
  stroke: 'neutral-600',
};

export const MENU_VERIFICATION_LINKS = {
  icon: 'verification',
  name: 'Director verification',
  to: URLS.directorVerification,
  stroke: 'neutral-600',
};

export const STAFF_IMITATE_USER_MENU_LINK = {
  name: 'Imitate user',
  icon: 'user',
  to: URLS.staffLogin,
};
