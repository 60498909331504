import { Widget } from '@kanda-libs/ks-component-ts';

import useTradeInfoForm from './useTradeInfoForm';

const ViewComponent = ({}) => {
  const {
    tradeNumberValidation,
    otherTradeType,
    otherTradeBody,
    noneTradeBody,
  } = useTradeInfoForm();

  return (
    <>
      <Widget.CompanyCompanyInfoTradeType />
      {otherTradeType && <Widget.CompanyCompanyInfoTradeTypeName />}
      <Widget.CompanyCompanyInfoTradeBody
        validation={{
          required: { value: true, message: 'Trade body is required.' },
        }}
      />
      {otherTradeBody && <Widget.CompanyCompanyInfoTradeBodyName />}
      {!noneTradeBody && (
        <Widget.CompanyCompanyInfoTradeBodyNumber
          validation={tradeNumberValidation}
        />
      )}
    </>
  );
};

ViewComponent.displayName = 'StandardTradeInfoForm-view';

export default ViewComponent;
