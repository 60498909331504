import type { Job } from '@kanda-libs/ks-frontend-services';

export const getCustomerDeposit = (job?: Job): number => {
  if (!job) return 0;

  const payments = job.payments || [];

  const nonLoanPayments = [...payments]
    .sort(
      (p1, p2) =>
        (new Date(
          p2?.metadata?.created_at as unknown as number,
        ) as unknown as number) -
        (new Date(
          p1?.metadata?.created_at as unknown as number,
        ) as unknown as number),
    )
    .filter((payment) => payment.payment_option.payment_method !== 'loan');

  const [firstNonLoanPayment] = nonLoanPayments;

  if (firstNonLoanPayment) {
    return firstNonLoanPayment.payment_option.amount.amount;
  }

  if (job.customer_options?.deposit_value?.amount) {
    return job.customer_options.deposit_value.amount;
  }

  return 0;
};
