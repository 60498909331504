import {
  BreakPoints,
  ConfigWrapper,
  DesktopFooter,
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
  Header,
  HelpButton,
  Layout,
  Logo,
} from '@kanda-libs/ks-design-library';

interface AccountInReviewLayoutProps {
  children: React.ReactNode;
}

const DesktopHeader: React.FunctionComponent = function () {
  return (
    <div className="w-full flex flex-row justify-between">
      <Logo height={20} className="my-auto" />
      <HelpButton />
    </div>
  );
};

const AccountInReviewLayout: React.FunctionComponent<AccountInReviewLayoutProps> =
  function ({ children }) {
    return (
      <BreakPoints
        mobile={
          <Layout
            stickyHeader
            header={
              <Header.Base lines hideNumber help>
                <div className="flex flex-row w-full justify-between">
                  <Logo height={20} className="my-auto" />
                </div>
              </Header.Base>
            }
          >
            <>{children}</>
          </Layout>
        }
        desktop={
          <ConfigWrapper
            desktopFooter={DesktopFooter}
            desktopHeader={DesktopHeader}
          >
            <DesktopLayoutDefault>
              <DesktopLayoutDefaultContent>
                <>{children}</>
              </DesktopLayoutDefaultContent>
            </DesktopLayoutDefault>
          </ConfigWrapper>
        }
      />
    );
  };

export default AccountInReviewLayout;
