import type { Credit } from '@kanda-libs/ks-frontend-services';
import { getCreditId } from 'common/job/helpers';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface CurrentJobCreditHook {
  credit?: Credit;
}

export default function useCurrentJobCredit(): CurrentJobCreditHook {
  const job = useSelector(selectors.job.getEntity);
  const credits = useSelector(selectors.credit.getEntities);

  const credit = credits[getCreditId(job)];

  return {
    credit,
  };
}
