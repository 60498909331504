import { Component } from 'react';

interface ErrorBoundaryProps {
  children: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="bg-blue-100 p-2 m-2 rounded-xl text-neutral-800">
          An unexpected error has occurred. Please try refreshing the page. If
          you are using an older browser, please try updating to the latest
          version. Contact support on{' '}
          <a href="tel:03308187491" className="underline">
            0330 818 7491
          </a>{' '}
          or{' '}
          <a href="mailto:help@kanda.co.uk" className="underline">
            help@kanda.co.uk
          </a>{' '}
          if the problem persists.
        </div>
      );
    }

    const { children } = props;

    return children;
  }
}

export default ErrorBoundary;
