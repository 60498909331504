import { Price, Text } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { PRICE_EMPHASISED_PROPS, PRICE_PROPS } from '../constants';
import LineItem from './LineItem';

interface ContentProps {
  financeOption: FinanceRate;
  title?: string;
  totalLabel?: string;
  totalCost: number;
  percentage: number;
  baseRate: number;
  baseFee: number;
  emphasised?: boolean;
  rateWithinBounds: boolean;
  depositIsAboveMinimum: boolean;
}

const Content = ({
  financeOption,
  title,
  totalLabel,
  totalCost,
  percentage,
  baseRate,
  baseFee,
  emphasised,
  rateWithinBounds,
  depositIsAboveMinimum,
}: ContentProps) => (
  <div className="relative mx-4 my-5 min-w-60">
    <div className="mb-2">
      <Text
        text={title}
        className={
          emphasised
            ? 'text-12-18-em text-neutral-500'
            : 'text-12-18-em text-neutral-900'
        }
      />
    </div>
    {rateWithinBounds && (
      <>
        {!depositIsAboveMinimum && (
          <>
            <p className="text-12-18 text-neutral-700 mb-2">
              Customer will have to pay a higher minimum deposit than you have
              selected if this option is chosen
            </p>
            <LineItem
              percentage
              emphasised={emphasised}
              label="Min deposit %"
              price={financeOption.min_deposit_pct || 0}
            />
          </>
        )}

        <LineItem
          emphasised={emphasised}
          label={`${percentage}% of the job value`}
          price={baseRate}
        />
        <LineItem emphasised={emphasised} label="Success fee" price={baseFee} />
      </>
    )}
    {!rateWithinBounds && (
      <>
        {financeOption.min_deposit_value &&
        financeOption.min_deposit_value > 0 ? (
          <LineItem
            emphasised={emphasised}
            label="Min deposit value"
            price={financeOption.min_deposit_value || 0}
          />
        ) : (
          <></>
        )}
        {financeOption.max_deposit_value &&
        financeOption.max_deposit_value > 0 ? (
          <LineItem
            emphasised={emphasised}
            label="Max deposit value"
            price={financeOption.max_deposit_value || 0}
          />
        ) : (
          <></>
        )}
        <LineItem
          percentage
          emphasised={emphasised}
          label="Min deposit %"
          price={financeOption.min_deposit_pct || 0}
        />
        <LineItem
          percentage
          emphasised={emphasised}
          label="Max deposit %"
          price={financeOption.max_deposit_pct || 0}
        />
        <LineItem
          emphasised={emphasised}
          label="Min job value"
          price={financeOption.min_job_value || 0}
        />
        <LineItem
          emphasised={emphasised}
          label="Max job value"
          price={financeOption.max_job_value || 0}
        />
      </>
    )}
    {rateWithinBounds && (
      <>
        <hr className="my-2 text-neutral-200" />
        <div className="flex items-center justify-between">
          <Text
            text={totalLabel}
            className={
              emphasised ? 'text-14-22-em' : 'text-12-18-em text-neutral-600'
            }
          />
          <Price
            amount={totalCost}
            {...(emphasised ? PRICE_EMPHASISED_PROPS : PRICE_PROPS)}
          />
        </div>
      </>
    )}
  </div>
);

Content.defaultProps = {
  title: 'Cost breakdown',
  totalLabel: 'Total',
  emphasised: false,
};

export default Content;
