import Form from './Form';
import Summary from './Summary';
import Welcome from './Welcome';

const Invite = {
  Welcome,
  Form,
  Summary,
};

export default Invite;
