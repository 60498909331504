import { useCallback, useContext } from 'react';
import { ModalsWrapperContext } from '@kanda-libs/ks-component-ts';

import { MODAL_ID } from './Mobile-constants';
import type { PopoverMobileProps } from './Mobile-view';

export interface ContainerComponentChildrenArgs {
  onClick: () => void;
  permissions: boolean[];
  role: string;
  modalId: string;
}

export interface ContainerComponentProps extends PopoverMobileProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  permissions,
  role,
}: ContainerComponentProps) => {
  const { showModal } = useContext(ModalsWrapperContext);
  const modalId = MODAL_ID?.replace('role', role);

  /**
   * Function to show delete item modal
   */
  const onClick = useCallback(() => showModal(modalId), [modalId, showModal]);

  return children({
    onClick,
    permissions,
    role,
    modalId,
  });
};

ContainerComponent.displayName = 'New-Role-Popover-Mobile-Container';

export default ContainerComponent;
