import {
  useClasses,
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import {
  actions,
  Company,
  WelcomeSkipBanner,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { CLASS_NAMES } from './constants';

export interface TrainingItemHook {
  classNames: StringIndexedObject;
  isSubmitting: boolean;
  markAsCompleted: (key: keyof WelcomeSkipBanner) => void;
}

export default function useTrainingItem(completed: boolean): TrainingItemHook {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showError } = useToast();

  const onError = useApiError(
    'There was an error dismissing this training item',
  );

  const classNames = useClasses(CLASS_NAMES, {
    variants: {
      completed,
    },
  });

  const markAsCompleted = useCallback(
    (key: keyof WelcomeSkipBanner) => {
      if (!company?.id) {
        showError('Company not found');
        return;
      }

      dispatch(
        actions.putCompany({
          body: {
            ...company,
            welcome_pack: {
              ...(company.welcome_pack || {}),
              [key]: 'yes',
            },
          } as Company,
          onError,
          params: {
            id: company.id,
          },
        }),
      );
    },
    [company, dispatch, onError, showError],
  );

  return { classNames, isSubmitting, markAsCompleted };
}
