import { Field, FormTheme } from '@kanda-libs/ks-component-ts';

import { SEARCH_PROPS } from './SelectCustomer-constants';

interface ViewComponentProps {
  /**
   * Search customer function
   */
  showSearch?(...args: unknown[]): unknown;
}

const ViewComponent = ({ showSearch }: ViewComponentProps) => (
  <FormTheme variant="emphasized">
    <Field.UncontrolledInput {...SEARCH_PROPS} onFocus={showSearch} />
  </FormTheme>
);

ViewComponent.displayName = 'SelectCustomer-view';

ViewComponent.defaultProps = {
  showSearch: undefined,
};

export default ViewComponent;
