import { Form } from '@kanda-libs/ks-component-ts';
import {
  Button,
  DesktopLayoutBoxedContent,
  DesktopLayoutBoxedContentHeader,
  Loader,
} from '@kanda-libs/ks-design-library';

import { CLASS_NAMES } from './Summary-constants';
import Container from './Summary-container';

import { SaveAsDraftButton, Summary } from '../../../../components';
import Page from '../../Page';

const ViewComponent = () => (
  <Container>
    {({
      nextButtonProps,
      isSubmitting,
      showSubmit,
      prevStep,
      form,
      handleSubmit,
    }) => (
      <Form id="create-job-summary-desktop" form={form} onSubmit={handleSubmit}>
        <Page>
          <DesktopLayoutBoxedContentHeader>
            <Button.Icon
              id="create-job-summary-back"
              icon="arrow-left"
              onClick={prevStep}
            />
          </DesktopLayoutBoxedContentHeader>
          <DesktopLayoutBoxedContent
            width="w-180 lg:min-w-180"
            footer={
              <div className={CLASS_NAMES.footer}>
                <SaveAsDraftButton />
                {showSubmit && (
                  <Button.Text id="send-to-customer" {...nextButtonProps} />
                )}
              </div>
            }
          >
            <div className={CLASS_NAMES.container}>
              <Summary.Header />
              <Summary.CompanyDetails />
              <Summary.JobDetails />
              <Summary.PaymentMethods />
              <Summary.CompleteAccountBadge />
              <Summary.UpgradeAccountBadge />
              <Summary.AccountCompletedBadge />
            </div>
          </DesktopLayoutBoxedContent>
          <Loader isLoading={isSubmitting} />
        </Page>
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'CreateJob-Summary-Desktop-view';

export default ViewComponent;
