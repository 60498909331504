import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';
import { APP_ENV } from 'config';
import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface AnalyticsWrapperProps {
  children: JSX.Element;
}

const AnalyticsWrapper: FunctionComponent<AnalyticsWrapperProps> = function ({
  children,
}) {
  const { logEvent } = useAmplitude();
  const pathKey = useSelector(selectors.getPathKey);

  // On every route change, log the page-view event
  useEffect(() => {
    logEvent('page-view');

    if (APP_ENV === 'qa') {
      // eslint-disable-next-line no-console
      console.log('Route change:', pathKey.path);
    }
  }, [pathKey.path, logEvent]);

  return children;
};

export default AnalyticsWrapper;
