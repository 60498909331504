import { useEffect } from 'react';
import { Text } from '@kanda-libs/ks-design-library';
import { useForm } from '@kanda-libs/ks-component-ts';
import type { DirectorVerification } from '@kanda-libs/ks-frontend-services';

import { DESKTOP_CLASS_NAMES, SKELETONS } from './constants';
import DirectorNameCard from '../../../components/DirectorNameCard';
import DirectorForm from './DirectorForm';

export interface IdentityFormProps {
  cid?: string;
  isLoading: boolean;
  director?: DirectorVerification;
  onClickButton: () => void;
}

const IdentityForm = ({
  cid,
  director,
  isLoading,
  onClickButton,
}: IdentityFormProps) => {
  const loadingProps = {
    isLoading,
  };

  const form = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!isLoading && director) {
      form.reset({
        director_verification: {
          email: director?.email,
          mobile: director?.mobile,
          date_of_birth: director?.date_of_birth,
        },
      });
    }
  }, [isLoading, director, form]);

  return (
    <div className={DESKTOP_CLASS_NAMES.wrapper}>
      <div id="veriff" className="hidden" />
      <Text
        text="Confirm your details"
        className={DESKTOP_CLASS_NAMES.title}
        skeletonProps={SKELETONS.secondTitle}
        {...loadingProps}
      />
      <Text
        text="Please confirm your home address before proceeding with the ID check"
        className={DESKTOP_CLASS_NAMES.subTitle}
      />
      <div className="w-full md:min-w-120">
        <DirectorNameCard
          name={`${director?.first_name} ${director?.last_name}`}
          {...loadingProps}
        />
        <DirectorForm
          cid={cid}
          form={form}
          director={director}
          onSubmit={onClickButton}
        />
      </div>
    </div>
  );
};

export default IdentityForm;
