import { Card, StringIndexedObject } from '@kanda-libs/ks-design-library';
import Highlighter from 'react-highlight-words';

import Container from './Item-container';
import { HIGHLIGHT_PROPS, SKELETONS } from './Item-constants';

interface ViewComponentProps {
  /**
   * Handle select customer
   */
  handleSelect(...args: unknown[]): unknown;
  /**
   * Loading state
   */
  isLoading?: boolean;
  /**
   * Search words
   */
  searchWords?: string[];
  /**
   * Customer
   */
  customer?: {
    id?: string;
    name?: string;
    address?: string;
    email?: string;
    phoneNumber?: string;
  };
}

const ViewComponent = ({
  isLoading,
  customer,
  searchWords,
  ...restProps
}: ViewComponentProps) => (
  <Container customer={customer} isLoading={isLoading} {...restProps}>
    {({ classNames, onSelect }) => (
      <Card
        onClick={onSelect}
        className={classNames.card}
        isLoading={isLoading}
        title={
          <Highlighter
            {...HIGHLIGHT_PROPS}
            searchWords={searchWords}
            textToHighlight={customer?.name || ''}
          />
        }
        footer={
          <Highlighter
            {...HIGHLIGHT_PROPS}
            searchWords={searchWords}
            textToHighlight={customer?.email || ''}
            className={classNames.footer}
          />
        }
        footerOptions={[
          <Highlighter
            key="phone"
            {...HIGHLIGHT_PROPS}
            searchWords={searchWords}
            textToHighlight={customer?.phoneNumber || ''}
            className={classNames.footerOptions}
          />,
        ]}
        showLoadingSkeleton={SKELETONS as StringIndexedObject}
      >
        <Highlighter
          {...HIGHLIGHT_PROPS}
          searchWords={searchWords}
          textToHighlight={customer?.address || ''}
          className={classNames.content}
        />
      </Card>
    )}
  </Container>
);

ViewComponent.displayName = 'SearchCustomerModal-Item-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  customer: undefined,
  searchWords: undefined,
};

export default ViewComponent;
