export const CLASS_NAMES = {
  container: {
    base: 'flex flex-col p-5 pt-3',
    '@type': {
      from: 'w-48',
      between: 'w-64',
    },
  },
  fields: 'flex flex-1 flex-row mt-4 -mb-4 items-center',
  seperator: 'h-px w-2 mx-2 bg-neutral-600 mb-4',
};

export const PRICE_FILTER_TYPE = {
  FROM: 'from',
  BETWEEN: 'between',
};

export const FILTER_FIELDS = {
  type: {
    name: 'filter-type',
    options: [
      {
        value: PRICE_FILTER_TYPE.FROM,
        name: 'From',
      },
      {
        value: PRICE_FILTER_TYPE.BETWEEN,
        name: 'Between',
      },
    ],
  },
  from: {
    name: 'from',
  },
  to: {
    name: 'to',
  },
};

export const DEFAULT_VALUES = {
  'filter-type': PRICE_FILTER_TYPE.FROM,
};

export const DEBOUNCE_INTERVAL = 600;
