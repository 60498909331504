import { BreakPoints } from '@kanda-libs/ks-design-library';
import BulletListSidebar from 'components/BulletListSidebar';

interface SubSidebarProps {
  showForm: boolean;
}

const Sidebar = ({ showForm }: SubSidebarProps) => {
  if (!showForm) return <></>;
  return (
    <BreakPoints
      mobile={
        <BulletListSidebar
          title="Get started by setting up your subscription"
          bullets={[
            {
              title: 'Offer finance within 5 days',
              subText: '',
            },
            {
              title: '95% of trades are approved ',
              subText: '',
            },
            {
              title: 'Cancel anytime with no fees!',
              subText: '',
            },
          ]}
        />
      }
      desktop={
        <BulletListSidebar
          title="Set up your subscription"
          bullets={[
            {
              title: 'Offer finance',
              subText:
                'Offer all our finance options within 14 days of applying to Kanda',
            },
            {
              title: 'Refund Guarantee',
              subText:
                "Get a full redund if you aren't approved. 95% of trades approved in just 3 days. ",
            },
          ]}
        />
      }
    />
  );
};

export default Sidebar;
