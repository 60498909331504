import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { BASE_URL } from '../../config';

const ContainerComponent = ({ children, email }) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.infoAuth.getIsSubmitting);
  const { showSuccess } = useToast();

  const onError = useApiError(
    'Cannot resend email at this time - please try again later',
  );

  const body = useMemo(
    () => ({
      email,
      continue_url: `${BASE_URL}claim-account?email=${email}`,
    }),
    [email],
  );

  const onClick = useCallback(
    () =>
      dispatch(
        actions.infoAuth({
          body,
          onError,
          onSuccess: () => {
            showSuccess('Email sent to your email address');
          },
        }),
      ),
    [onError, showSuccess, body, dispatch],
  );

  return children({
    onClick,
    isLoading: !email,
    isSubmitting,
  });
};

export default ContainerComponent;
