import { useCallback } from 'react';
import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import type { UserType } from '@kanda-libs/ks-frontend-services';
import { BASE_USER } from './AddUserButton-constants';
import type { ViewComponentProps } from './AddUserButton-view';

export interface ContainerChildrenArgs {
  showButton: boolean;
  handleAddUser: () => void;
}

export interface ContainerProps extends ViewComponentProps {
  children: (args: ContainerChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children, append, fields }: ContainerProps) => {
  /**
   * Function to add another item
   */
  const handleAddUser = useCallback(
    () => append(BASE_USER as UserType),
    [append],
  );

  const showButton =
    fields.filter((user) => (user as StringIndexedObject)?.state === 'new')
      ?.length === 0;

  return children({
    showButton,
    handleAddUser,
  });
};

ContainerComponent.displayName = 'AddUserButton-container';

export default ContainerComponent;
