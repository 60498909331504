import {
  BreakPoints,
  Button,
  DesktopLayoutBoxed,
  DesktopLayoutBoxedContent,
  Header,
  Layout,
  Logo,
} from '@kanda-libs/ks-design-library';
import { Title } from '@kanda-libs/ks-design-library-new';
import { URLS } from 'config';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export interface InviteProps {
  children: JSX.Element | JSX.Element[];
}

const Invite: FunctionComponent<InviteProps> = function ({ children }) {
  const content = (
    <>
      <div className="mb-8">
        <Title>Invite your team</Title>
      </div>
      <div>{children}</div>
    </>
  );

  const desktop = (
    <DesktopLayoutBoxed>
      <DesktopLayoutBoxedContent width="w-full max-w-120">
        {content}
      </DesktopLayoutBoxedContent>
    </DesktopLayoutBoxed>
  );

  const mobile = (
    <Layout
      header={
        <Header.Base lines help>
          <Link to={URLS.home}>
            <Button.Icon
              id="home-mobile-back"
              icon="chevron-left"
              className="-ml-4"
            />
          </Link>
          <Logo />
        </Header.Base>
      }
    >
      {content}
    </Layout>
  );

  return <BreakPoints mobile={mobile} desktop={desktop} />;
};

export default Invite;
