import { useDownloadPdf } from 'hooks';
import { useMemo } from 'react';
import { CUSTOMER_PLACEHOLDER, TITLE_PLACEHOLDER } from './JobHeader-constants';

import useCurrentJob from '../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { job, isLoading, checkJob } = useCurrentJob();

  const { downloadPdf, isSubmitting } = useDownloadPdf();

  const id = job?.id || '';
  const title = job?.title || TITLE_PLACEHOLDER;
  const firstName = job?.customer?.first_name || null;
  const lastName = job?.customer?.last_name || null;
  const status = job?.status || 'draft';
  const price = job?.total?.amount_vat_inclusive?.amount || 0;
  const timestamp = job?.metadata?.updated_at;
  const archived = job?.archived === 'yes';

  const customerName = useMemo(() => {
    if (!firstName) return CUSTOMER_PLACEHOLDER;
    if (firstName && !lastName) return firstName;
    return `${firstName} ${lastName}`;
  }, [firstName, lastName]);

  const financeStatus = useMemo(() => {
    if (!job || !checkJob) return 'not_applied';
    if (job?.finance_status === 'financed') {
      const payment = [...(job?.payments || [])]
        ?.sort(
          (p1, p2) =>
            (new Date(
              p2?.metadata?.created_at as unknown as number,
            ) as unknown as number) -
            (new Date(
              p1?.metadata?.created_at as unknown as number,
            ) as unknown as number),
        )
        ?.filter((p) => p?.payment_option?.payment_method === 'card')?.[0];
      if (!payment) return job?.finance_status;
      if (
        ['accepted_sign_document', 'accepted_deposit_required']?.includes(
          checkJob?.current_status || '',
        )
      )
        return 'applied_for_finance';
      return job?.finance_status;
    }
    return job?.finance_status;
  }, [job, checkJob]);

  return children({
    customer: customerName,
    title,
    status,
    financeStatus,
    price,
    id,
    isLoading,
    timestamp,
    archived,
    downloadPdf,
    pdfIsSubmitting: isSubmitting,
  });
};

ContainerComponent.displayName = 'Job-Title-container';

export default ContainerComponent;
