import { FieldHandle, FormTheme } from '@kanda-libs/ks-component-ts';
import { Icon, Price, Tag, Text } from '@kanda-libs/ks-design-library';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import type { FunctionComponent } from 'react';
import OptionPopover from '../OptionPopover';
import {
  CLASS_NAMES,
  COST_PRICE_PROPS,
  MONTHS_LABEL,
  MONTHS_PRICE_PROPS,
} from './constants';
import useOptionProps from './useOptionProps';

export interface SlimOptionProps {
  financeOptions: FinanceRate[];
  label?: string;
  hideHandle: boolean;
  checkIfRateWithinBounds?: boolean;
}

const SlimOption: FunctionComponent<SlimOptionProps> = function ({
  financeOptions,
  label,
  hideHandle = false,
  checkIfRateWithinBounds = true,
}) {
  const {
    financeOption,
    isLoading,
    onClick,
    durationLabel,
    cost,
    monthly,
    percentage,
    baseRate,
    baseFee,
    interestLabel,
    name,
    disabled,
    comingSoon,
    selectable,
    rateWithinBounds,
    iconClasses,
    isSelected,
    icon,
    depositIsAboveMinimum,
  } = useOptionProps(
    financeOptions,
    label,
    hideHandle,
    checkIfRateWithinBounds,
  );

  return (
    <div className={CLASS_NAMES.wrapper}>
      <label htmlFor={name} className={CLASS_NAMES.firstItem}>
        {!hideHandle && selectable && rateWithinBounds && (
          <div className="mr-2">
            <FormTheme variant="clean">
              <FieldHandle.Checkbox
                readOnly
                id={name}
                name={name}
                onClick={() => onClick(financeOption)}
                disabled={disabled}
                checked={isSelected}
              />
            </FormTheme>
          </div>
        )}
        {!hideHandle && selectable && !rateWithinBounds && (
          <Icon icon="error" className={iconClasses} />
        )}
        {!selectable && !comingSoon && !hideHandle && (
          <Icon icon={icon} className={iconClasses} />
        )}
        <Text className={CLASS_NAMES.durationLabel} text={durationLabel} />
      </label>
      {comingSoon ? (
        <div className={CLASS_NAMES.comingSoon}>
          <Tag variant="solid" size={21} label="coming soon" color="orange" />
        </div>
      ) : (
        <>
          {rateWithinBounds && (
            <div className={CLASS_NAMES.otherItems}>
              <Price
                isLoading={isLoading}
                amount={monthly}
                {...MONTHS_PRICE_PROPS}
              />
              <Text
                className={CLASS_NAMES.monthsLabel}
                text={MONTHS_LABEL}
                isLoading={isLoading}
              />
            </div>
          )}
          <div className={CLASS_NAMES.otherItems}>
            {rateWithinBounds && (
              <Price
                isLoading={isLoading}
                amount={cost}
                {...COST_PRICE_PROPS}
              />
            )}
            {!rateWithinBounds && (
              <Text
                text="Not available"
                className="-ml-8 text-12-18-em text-neutral-600"
              />
            )}
            <div className={CLASS_NAMES.popover}>
              <OptionPopover
                financeOption={financeOption}
                totalCost={cost}
                percentage={percentage}
                baseRate={baseRate}
                baseFee={baseFee}
                interestLabel={interestLabel}
                durationLabel={durationLabel}
                rateWithinBounds={rateWithinBounds}
                depositIsAboveMinimum={depositIsAboveMinimum}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SlimOption;
