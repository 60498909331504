import type { ValidationItems } from '@kanda-libs/ks-component-ts';
import { useFormContext } from 'react-hook-form';

export interface ClaimAccountFormFieldsHook {
  passwordValidation: ValidationItems;
  confirmPasswordValidation: ValidationItems;
}

export default function useClaimAccountFormFields(): ClaimAccountFormFieldsHook {
  const { getValues } = useFormContext();

  const passwordValidation: ValidationItems = {
    required: {
      value: true,
      message: 'Password is required',
    },
    validate: {
      value: () => getValues()?.password_strength > 2,
      message: 'Password is not strong enough',
    },
  };

  const confirmPasswordValidation: ValidationItems = {
    required: {
      value: true,
      message: 'Password confirmation is required',
    },
    validate: {
      value: (confirmation) => getValues()?.password === confirmation,
      message: "Passwords don't match",
    },
  };

  return {
    passwordValidation,
    confirmPasswordValidation,
  };
}
