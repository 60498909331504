import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

interface ViewComponentProps {
  /**
   * Title
   */
  title?: string;
  /**
   * Customer's name
   */
  name?: string;
  /**
   * Customer's email
   */
  email?: string;
}

const ViewComponent = (props: ViewComponentProps) => (
  <BreakPoints
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

ViewComponent.defaultProps = {
  title: undefined,
  name: undefined,
  email: undefined,
};

ViewComponent.displayName = 'CreateJob-SendConfirmation-Confirmation-view';

export default ViewComponent;
