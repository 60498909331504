import type { MutableRefObject } from 'react';
import useOptionHover from '../useOptionHover';
import type { PopoverDesktopProps } from './Desktop-view';

export interface ContainerComponentChildrenArgs {
  visible: boolean;
  elementRef: MutableRefObject<HTMLDivElement | undefined>;
  permissions: boolean[];
  id: string;
}

export interface ContainerComponentProps extends PopoverDesktopProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({
  children,
  permissions,
  id,
}: ContainerComponentProps) => {
  const { visible, elementRef } = useOptionHover();

  return children({
    visible,
    elementRef,
    permissions,
    id,
  });
};

export default ContainerComponent;
