import { useCallback, useState, useMemo } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';
import type { FinanceRate, JobItem } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';

import {
  DEPOSIT_TYPES,
  FINANCE_OPTIONS_NAME,
  TYPE_NAME,
  VALUE_NAME,
  OPTIONS,
  FIXED_DEPOSIT_OPTION,
} from './Fields-constants';
import { calculateJobTotal } from '../../../../../utils';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  onClick: (depositType: string) => void;
  financeOptionsError: boolean;
  options?: {
    depositType: string;
    title: string;
    children?: JSX.Element;
  }[];
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { data } = useCreateJobContextValue();
  const isLeadJob = useIsLeadJob();

  const isLoading = data?.isLoading || false;
  const jobType = data?.job?.job_type;

  const [financeOptionsError, setFinanceOptionsError] = useState(false);

  const financeOptions: FinanceRate[] = useWatch({
    name: FINANCE_OPTIONS_NAME,
  });

  const { setValue } = useFormContext();

  const [lineItems]: [JobItem[]] = useWatch({
    name: ['job.job_items'],
  });

  const totals = calculateJobTotal(lineItems);

  const options = useMemo(() => {
    // Lead jobs should only have fixed deposit option
    if (isLeadJob) return [FIXED_DEPOSIT_OPTION];
    if (!jobType || jobType === 'standard') return OPTIONS;
    return OPTIONS.slice(1);
  }, [jobType, isLeadJob]);

  const onClick = useCallback(
    (depositType: string) => {
      setValue(TYPE_NAME, depositType);
      if (depositType === DEPOSIT_TYPES.noDeposit) {
        setValue(VALUE_NAME, 0);
        const financeOptionsSelected = financeOptions?.length > 0;
        if (financeOptionsSelected) {
          setFinanceOptionsError(financeOptionsSelected);
          return;
        }
        return;
      }
      setFinanceOptionsError(false);
      setValue(VALUE_NAME, Math.floor(totals.totalIncVat * 0.1));
    },
    [financeOptions, setValue, totals.totalIncVat],
  );

  return children({
    isLoading,
    onClick,
    financeOptionsError,
    options,
  });
};

ContainerComponent.displayName = 'CreateJob-Desktop-Deposit-Info-container';

export default ContainerComponent;
