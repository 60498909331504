const Label = () => (
  <span>
    Description
    <span className="ml-1 text-style-h">(optional)</span>
  </span>
);

export const DESCRIPTION_COMPONENT_PROPS = {
  placeholder: 'Add description',
  label: <Label />,
  autosize: true,
  textarea: true,
  autoSize: true,
};
