import { Button, Text } from '@kanda-libs/ks-design-library';
import { Widget } from '@kanda-libs/ks-component-ts';

import useSolarInfoForm from './useSolarInfoForm';

const ViewComponent = ({}) => {
  const { validation, isSoleTrader } = useSolarInfoForm();

  return (
    <>
      <Text
        text="Business & Insurance"
        className="text-14-22–em text-neutral-800 font-bold mb-2"
      />
      <Text
        text="You're signing up as a Solar PV company. If this is incorrect, please contact Kanda and we'll assist you in changing your company's trade type"
        className="text-12-18 text-neutral-600 mb-6"
      />
      <Widget.CompanySolarCompanyInfoEpvsRegistrationNumber />
      <Widget.CompanySolarCompanyInfoSolarTradeAssociation />
      <Widget.CompanySolarCompanyInfoSolarTradeAssociationNumber />
      <Widget.CompanySolarCompanyInfoElectricalTradeAssociation />
      <Widget.CompanySolarCompanyInfoElectricalTradeAssociationNumber
        validation={validation}
      />
      {isSoleTrader && (
        <Widget.CompanySoleTraderInfoNationalInsuranceNumber
          validation={{
            required: {
              value: true,
              message: 'National insurance number is required',
            },
          }}
        />
      )}
      <div className="mt-auto md:mt-0">
        <Button.Text
          id="onboarding-solar-info-next"
          submit
          label="Next"
          className="w-full mt-6"
        />
      </div>
    </>
  );
};

ViewComponent.displayName = 'SolarTradeInfoForm-view';

export default ViewComponent;
