import { Button } from '@kanda-libs/ks-design-library';

import Container from './Button-container';
import { Modal } from '../components';

export interface PopoverButtonProps {
  bucket: string;
}

const ViewComponent = (props: PopoverButtonProps) => (
  <Container {...props}>
    {({ onClick, ...rest }) => (
      <div className="flex absolute w-10 h-10">
        <Button.Icon
          id="notification-settings-bucket-permissions"
          onClick={onClick}
          icon="info"
          className="my-auto"
          size="32-16"
        />
        <Modal {...rest} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'NotificationsModal-Bucket-Popover-Button-view';

export default ViewComponent;
