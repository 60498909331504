import { Widget } from '@kanda-libs/ks-component-ts';

import { Provider } from './Context';
import { Title, Item, AddItemButton, JobTotal } from './components';

import { BASE_ITEM } from '../../../../CreateJob-constants';

const ViewComponent = () => (
  <>
    <Title />
    <Provider>
      <Widget.JobJobItemsArrayWrapper initialData={[BASE_ITEM]}>
        {({ arrayName, fields, arrayProps: { append, ...restProps } }) => (
          <>
            {fields.map((field, index) => (
              <Item
                key={field.id}
                index={index}
                arrayName={arrayName}
                {...restProps}
              />
            ))}
            <AddItemButton arrayName={arrayName} append={append} />
          </>
        )}
      </Widget.JobJobItemsArrayWrapper>
    </Provider>
    <JobTotal />
  </>
);

ViewComponent.displayName = 'Createjob-Quote-Kanda-view';

export default ViewComponent;
