import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { CLASS_NAMES } from './DesktopLayout-constants';

import { URLS } from '../../../../config';

const ContainerComponent = ({ children, fullWidthContent }) => {
  const { pathname } = useLocation();

  const dropZoneProps = useMemo(
    () => ({
      disabled: pathname !== URLS.companyInformation,
      text: 'Drop image to upload as company logo',
      accept: ['image/png', 'image/jpg', 'image/jpeg'],
    }),
    [pathname],
  );

  const classNames = {
    ...CLASS_NAMES,
    content: CLASS_NAMES.content.replace(
      '$width',
      fullWidthContent ? 'w-full overflow-hidden' : 'max-w-130',
    ),
  };

  return children({
    dropZoneProps,
    classNames,
  });
};

ContainerComponent.displayName = 'MyAccount-DesktopLayout-container';

export default ContainerComponent;
