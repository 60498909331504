import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export interface SignUpFormSuccessHook {
  email: string;
}

export default function useSignUpFormSuccess(): SignUpFormSuccessHook {
  const formEmail = useWatch({ name: 'email' });

  const email = useMemo(() => formEmail || 'your email address', [formEmail]);

  return {
    email,
  };
}
