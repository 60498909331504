import { useState, useMemo } from 'react';

import { PLACEHOLDER } from './Payments-constants';

const ContainerComponent = ({ children }) => {
  const [showMore, setShowMore] = useState(false);

  const payments = useMemo(() => {
    if (!showMore) return PLACEHOLDER.slice(0, 3);
    return PLACEHOLDER;
  }, [showMore]);

  return children({
    payments,
    showMore,
    onClick: () => {
      if (setShowMore) setShowMore(true);
    },
  });
};

ContainerComponent.displayName = 'Subscription-Content-container';

export default ContainerComponent;
