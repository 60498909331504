import { Button } from '@kanda-libs/ks-design-library';
import { DEFAULT_NEXT_ICON } from './constants';

interface NextButtonProps {
  disabled?: boolean;
  showIcon?: boolean;
}

const NextButton = ({ disabled, showIcon = false }: NextButtonProps) => (
  <div className="flex flex-col gap-y-3">
    <Button.Text
      submit
      id="create-job-next-button"
      disabled={disabled}
      label="Next"
      icon={showIcon ? DEFAULT_NEXT_ICON : ''}
    />
  </div>
);

export default NextButton;
