import { useIsDesktop } from '@kanda-libs/ks-design-library';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { LABEL } from './Title-constants';

const ContainerComponent = ({ children }) => {
  const {
    data: { isLoading },
  } = useCreateJobContextValue();

  const isDesktop = useIsDesktop();

  return children({
    isLoading,
    label: isDesktop ? LABEL.desktop : LABEL.mobile,
  });
};

ContainerComponent.displayName = 'Title-container';

export default ContainerComponent;
