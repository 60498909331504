export const CLASS_NAMES = {
  container: 'flex flex-1 flex-col',
  title: 'text-style-e text-neutral-900 mb-4',
  description: 'text-style-g text-neutral-600',
};

export const TITLE = 'Customer';

export const DESCRIPTION =
  'Set the job’s customer. They’ll receive the job & checkout link';
