import {
  LayoutFooter,
  Button,
  BreakPoints,
} from '@kanda-libs/ks-design-library';

import { BUTTON_PROPS } from './SubPageFooter-constants';

import { FORM_CLASS_NAMES } from '../../AccountSettings-constants';

interface ViewComponentProps {
  /**
   * Is disabled
   */
  disabled?: boolean;
}

const ViewComponent = ({ disabled = false }: ViewComponentProps) => (
  <BreakPoints
    mobile={
      <LayoutFooter>
        <div className={FORM_CLASS_NAMES.footer}>
          <Button.Text {...BUTTON_PROPS} disabled={disabled} />
        </div>
      </LayoutFooter>
    }
    desktop={
      <div className={FORM_CLASS_NAMES.footer}>
        <Button.Text {...BUTTON_PROPS} disabled={disabled} />
      </div>
    }
  />
);

ViewComponent.displayName = 'SubPageFooter-view';

export default ViewComponent;
