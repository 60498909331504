import { Icon, Popover } from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import Container from './Desktop-container';

export interface PopoverDesktopProps {
  content: JSX.Element;
  id: string;
}

const ViewComponent = (props: PopoverDesktopProps) => (
  <Container {...props}>
    {({ visible, elementRef, Content, id }) => (
      <div
        ref={elementRef as MutableRefObject<HTMLDivElement>}
        className="flex absolute w-10 h-10"
      >
        <div className="m-auto">
          <Popover.Standard
            id={`notification-preferences-header-${id}`}
            button={
              <Icon
                icon="info"
                className="w-3 -mt-1 cursor-pointer min-w-3 text-neutral-500"
              />
            }
            right
            visible={visible}
            xMargin="mr-6"
            yMargin="-mt-12"
          >
            {() => <>{Content}</>}
          </Popover.Standard>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName =
  'NotificationPreferences-Content-Desktop-Header-Popover';

export default ViewComponent;
