import { Field } from '@kanda-libs/ks-component-ts';
import { EMAIL_VALIDATION } from './constants';

export interface ReferralsInviteFormFieldEmailProps {
  index: number;
}

const ReferralsInviteFormFieldEmail: React.FunctionComponent<ReferralsInviteFormFieldEmailProps> =
  function ({ index }) {
    return (
      <Field.Array.Input name="email" index={index}>
        <Field.Validator validation={EMAIL_VALIDATION}>
          <Field.Input label="Email" placeholder="Email address" />
        </Field.Validator>
      </Field.Array.Input>
    );
  };

export default ReferralsInviteFormFieldEmail;
