import type { ButtonLinkProps } from '@kanda-libs/ks-design-library';

export const ADDRESS_NAME = 'guarantor.address';

export const POSTCODE_NAME = `${ADDRESS_NAME}.postcode`;

export const POSTCODE_PROPS = {
  field: {
    placeholder: 'Home address postcode',
    label: 'Home Address Postcode',
    autoComplete: 'none',
    name: POSTCODE_NAME,
  },
  validation: {
    required: { value: true, message: 'Home address postcode is required.' },
    pattern: {
      value:
        /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
      message: 'Home address postcode input is invalid',
    },
  },
};

export const LINE_1_PROPS = {
  field: {
    label: 'Home Address Line 1',
    placeholder: 'Home address line 1',
    name: `${ADDRESS_NAME}.line_1`,
  },
  validation: {
    required: {
      value: true,
      message: 'Home address line 1 is required.',
    },
  },
};

export const LINE_2_PROPS = {
  field: {
    label: 'Home Address Line 2',
    placeholder: 'Home address line 2',
    name: `${ADDRESS_NAME}.line_2`,
  },
  validation: {},
};

export const CITY_PROPS = {
  field: {
    label: 'Home Address City',
    placeholder: 'Home address city',
    name: `${ADDRESS_NAME}.city`,
  },
  validation: {
    required: {
      value: true,
      message: 'Home address city is required.',
    },
  },
};

export const COUNTY_PROPS = {
  field: {
    label: 'Home Address County',
    placeholder: 'Home address county',
    name: `${ADDRESS_NAME}.county`,
  },
  validation: {},
};

export const COUNTRY_PROPS = {
  field: {
    label: 'Home Address Country',
    placeholder: 'Home address country',
    name: `${ADDRESS_NAME}.country`,
  },
  validation: {
    required: {
      value: true,
      message: 'Home address country is required.',
    },
  },
};

export const MANUAL_BUTTON_PROPS: ButtonLinkProps = {
  id: 'subscription-monthly-enter-address',
  className: 'mr-auto',
  variant: 'light-grey',
  size: 14,
  label: 'Manually enter address',
};
