import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany } from 'hooks';
import useApiError from 'hooks/useApiError';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

const ContainerComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const { company } = useCurrentCompany();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);

  const { showSuccess } = useToast();

  const onError = useApiError(
    'Error resending verification emails at this time - please try again later',
  );

  const directors = useMemo(() => {
    if (!company) return [];
    const users = company?.users || [];
    return users.filter((user) => user?.director_info);
  }, [company]);

  const isLoading = !company;

  const onClick = useCallback(() => {
    dispatch(
      actions.directorCompany({
        params: { id: company?.id as string },
        onError,
        onSuccess: () => {
          showSuccess('Verirication emails resent');
        },
      }),
    );
  }, [company, onError, showSuccess, dispatch]);

  return children({
    isLoading,
    directors,
    onClick,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'DirectorVerification-Content-container';

export default ContainerComponent;
