import { Price, StatusTag, Text } from '@kanda-libs/ks-design-library';
import PropTypes from 'prop-types';
import type { FinanceRate } from '@kanda-libs/ks-frontend-services';

import {
  CLASS_NAMES,
  COST_LABEL,
  SKELETONS,
} from '../../FinanceOptionNarrow-constants';
import OptionPopover from '../../../../pages/CreateJob/components/PaymentAndFinanceOptions/FinanceOptions/components/OptionPopover';

interface ViewComponentProps {
  /**
   * Cost label
   */
  cost?: number;
  /**
   * Duration label
   */
  durationLabel?: string;
  /**
   * Interest label
   */
  interestLabel?: string;
  /**
   * Hide cost
   */
  hideCost?: boolean;
  /**
   * Loading state
   */
  isLoading?: boolean;
  /**
   * finance option string
   */
  financeOption: FinanceRate;
  /**
   * finance option string
   */
  percentage?: number;
  /**
   * finance option string
   */
  baseRate?: number;
  /**
   * finance option string
   */
  baseFee?: number;
  /**
   * finance option string
   */
  declined?: boolean;
  /**
   * Is a second line rate
   */
  isSecondLine?: boolean;
}

const ViewComponent = ({
  financeOption,
  cost,
  hideCost,
  durationLabel,
  interestLabel,
  isLoading,
  percentage,
  baseRate,
  baseFee,
  declined = false,
  isSecondLine = false,
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.header}>
    <Text
      className={CLASS_NAMES.headerItem}
      skeletonProps={SKELETONS.interest}
      text={interestLabel}
      isLoading={isLoading}
    />
    <span className={CLASS_NAMES.bullet} />
    {!isSecondLine && (
      <Text
        className={CLASS_NAMES.headerItem}
        skeletonProps={SKELETONS.duration}
        text={durationLabel}
        isLoading={isLoading}
      />
    )}
    {isSecondLine && (
      <Text
        className={CLASS_NAMES.headerItem}
        skeletonProps={SKELETONS.duration}
        text="2nd Line"
        isLoading={isLoading}
      />
    )}
    {!hideCost && (
      <>
        <span className={CLASS_NAMES.bullet} />

        <div className="flex flex-row">
          <Text
            className={CLASS_NAMES.cost}
            skeletonProps={SKELETONS.cost}
            text={COST_LABEL}
            isLoading={isLoading}
          />
          <Price
            {...CLASS_NAMES.costPrice}
            amount={cost}
            isLoading={isLoading}
          />
          <OptionPopover
            financeOption={financeOption}
            totalCost={cost as number}
            percentage={percentage as number}
            baseRate={baseRate as number}
            baseFee={baseFee as number}
            interestLabel={interestLabel as string}
            durationLabel={durationLabel as string}
            rateWithinBounds={false}
          />
        </div>
      </>
    )}
    {declined && (
      <StatusTag status="declined" size={21} variant="solid" className="ml-1" />
    )}
  </div>
);

ViewComponent.defaultProps = {
  cost: undefined,
  durationLabel: undefined,
  interestLabel: PropTypes.string,
  hideCost: false,
  isLoading: false,
  percentage: undefined,
  baseRate: undefined,
  baseFee: undefined,
};

ViewComponent.displayName = 'FinanceOptions-Option-Header-view';

export default ViewComponent;
