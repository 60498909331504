import { useFormContext } from '@kanda-libs/ks-component-ts';
import { services, useSubmit } from '@kanda-libs/ks-frontend-services';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

const createSuggestion = (verify) => {
  if (!verify || verify?.verdict === 'valid') return null;
  if (!verify?.suggestion)
    return 'This email address may not be correct. Please check it before submission';
  const email = [verify?.email?.split('@')[0], verify?.suggestion]?.join('@');
  return `This email may not be correct. Did you mean to enter ${email}?`;
};

const ContainerComponent = ({ children }) => {
  const [emailName, setEmailName] = useState(null);

  const { watch, setValue, trigger } = useFormContext();

  const email = emailName ? watch(emailName) : null;

  const [query] = useDebounce(email, 2000);

  const {
    data: verify,
    submit: validateEmail,
    isSubmitting,
  } = useSubmit(services.infoValidation.infoValidateEmail);

  const suggestion = createSuggestion(verify);
  const iconProps = useMemo(() => {
    if (!verify)
      return {
        icon: 'check',
        stroke: 'green-500',
      };
    if (verify?.verdict === 'invalid')
      return {
        icon: 'error',
        stroke: 'red-200',
      };
    if (verify?.verdict === 'risky')
      return {
        icon: 'info',
        stroke: 'orange-200',
      };
    return {
      icon: 'check',
      stroke: 'green-500',
    };
  }, [verify]);
  const showIcon = useMemo(() => {
    if (!query) return false;
    if (isSubmitting) return false;
    return true;
  }, [query, isSubmitting]);

  useEffect(() => {
    if (!query) return;
    validateEmail({
      params: { email: query as unknown as string },
    }).then(({ data: verifiedEmail, error }) => {
      const name = (emailName as unknown as string)?.replace(
        'email',
        'email_validation',
      );
      const verdict = error ? 'valid' : verifiedEmail?.verdict;
      setValue(name, verdict);
      trigger(emailName as unknown as string);
    });
  }, [query, validateEmail, emailName, setValue, trigger]);

  return children({
    setEmailName,
    isSubmitting,
    suggestion,
    iconProps,
    showIcon,
  });
};

ContainerComponent.displayName = 'ValidateEmail-container';

export default ContainerComponent;
