import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import type {
  FinanceRate,
  FinanceType,
  Job,
} from '@kanda-libs/ks-frontend-services';

import {
  BUY_NOW_PAY_LATER_APR_TYPE,
  COMING_SOON_1190_RATES,
  COMING_SOON_1390_RATES,
} from 'constants/finance';
import { map } from 'fp-ts/Array';
import { flow, pipe } from 'fp-ts/lib/function';
import { groupBy } from 'fp-ts/NonEmptyArray';
import {
  financeRateIsSelectable,
  financeRateIsWithinBounds,
} from 'pages/CreateJob/components/PaymentAndFinanceOptions/FinanceOptions/components/SlimOption/helpers';

import { getFinanceOptionDetails } from './Finance-functions';
import { pluralise } from './String-functions';

export const rateIsInterestFree = (rate: FinanceRate) =>
  rate.name.includes('_FREE');

export const rateIsBuyNowPayLater = (rate: FinanceRate) =>
  rate.apr_type === BUY_NOW_PAY_LATER_APR_TYPE;

export const rateIsUnregulated = (rate: FinanceRate) =>
  rate.apr_type === 'INTEREST_FREE' && (rate?.duration || 12) <= 10;

export const ratesIncludesBuyNowPayLater = (rates: FinanceRate[]): boolean =>
  pipe(
    rates,
    map(rateIsBuyNowPayLater),
    (value) => value.filter(Boolean).length > 0,
  );

const eitherUnitShouldUseMonths = (min: number, max: number | null) =>
  min < 12 || (max !== null && max < 12);

const eitherUnitYearIsNotWholeNumber = (min: number, max: number | null) =>
  min % 12 !== 0 || (max !== null && max % 12 !== 0);

const getLabelUnit = (
  minMonths: number,
  maxMonths: number | null,
): 'month' | 'year' => {
  if (eitherUnitShouldUseMonths(minMonths, maxMonths)) return 'month';
  if (eitherUnitYearIsNotWholeNumber(minMonths, maxMonths)) return 'month';
  return 'year';
};

export const getFormattedUnit = (
  value: number,
  unit: 'month' | 'year',
): number => (unit === 'month' ? value : value / 12);

export const getLabelValue = (min: number, max: number): string =>
  min === max ? min.toString() : [min, max].filter(Boolean).join('-');

export const getLabel = ([min, max]: [number, number | null]): string =>
  pipe(
    [min, max] as [number, number | null],
    ([currentMin, currentMax]) =>
      [currentMin, currentMax, getLabelUnit(currentMin, currentMax)] as [
        number,
        number,
        'month' | 'year',
      ],
    ([currentMin, currentMax, unit]) =>
      [
        getFormattedUnit(currentMin, unit),
        getFormattedUnit(currentMax, unit),
        unit,
      ] as [number, number, 'month' | 'year'],
    ([currentMin, currentMax, unit]) => [
      getLabelValue(currentMin, currentMax),
      pluralise(currentMin, unit, `${unit}s`),
    ],
    (value) => value.join(' '),
  );

export const getFinanceRateInterestRate = (rate: FinanceRate): number => {
  if (rateIsInterestFree(rate)) {
    return 0;
  }

  return Number(rate.name.split('_').pop());
};

export const getFinanceRateDuration = (rate: FinanceRate): number => {
  if (rate?.duration) return rate.duration;
  return Number(rate.name.split('_', 3).pop());
};

export interface FinanceRateDetails {
  key: string;
  apr: number;
  duration: number;
  financeTypes: FinanceType[];
}

export const getFinanceRateKey = (rate: FinanceRate): string => {
  const interestRate = getFinanceRateInterestRate(rate);
  const duration = getFinanceRateDuration(rate);
  const { provider, fee, charge } = rate;

  return [interestRate, duration, provider, fee, charge].join('-');
};

export const getFinanceRateDetails = (
  rate: FinanceRate,
): FinanceRateDetails => ({
  key: getFinanceRateKey(rate),
  apr: getFinanceRateInterestRate(rate),
  duration: getFinanceRateDuration(rate),
  financeTypes: rate.finance_types,
});

export const getMinMaxDurations = (durations: number[]): [number, number] =>
  pipe(durations, (value) => [Math.min(...value), Math.max(...value)]);
export interface DurationUnits {
  value: number;
  units: string;
}

export const getDurationMinMax = (durations: number[]): [number, number] =>
  pipe(durations, (value) => [Math.min(...value), Math.max(...value)]);

// If the duration in years, divided by 12 is not a whole number, return years
// otherwise return months. This is so we don't display 18 months as 1.5 years
export const getDurationMonthsAsWholeNumberOrYears = (
  durationMonths: number,
): number => (durationMonths % 12 === 0 ? durationMonths : durationMonths * 12);

export const getFormattedMinMaxDuration = ([min, max]: [number, number]): [
  number,
  number,
] => {
  const formattedMin = min < 12 ? min : min / 12;
  const formattedMax = min < 12 ? max : max / 12;

  return pipe(
    [formattedMin, formattedMax],
    (value) =>
      value.map(getDurationMonthsAsWholeNumberOrYears) as [number, number],
  );
};

export const getDurationMinMaxLabel = (durations: [number, number]): string =>
  pipe(
    durations,
    getFormattedMinMaxDuration,
    ([min, max]) =>
      [min, min, getLabel([min, max])] as [number, number, string],
    ([min, max, units]) => [[min, max].join('-'), units].join(' '),
  );

export const getCombinedFinanceRatesDurations = map(getFinanceRateDuration);

export const getBnplLabel = (rates: FinanceRate[], label: string): string => {
  if (!rates.some((rate) => rate?.apr_type === 'BUYNOW_PAYLATER')) return label;
  return `BNPL ${label}`;
};

export const getCombinedFinanceRatesDurationLabel = (
  rates: FinanceRate[],
): string =>
  pipe(
    rates,
    getCombinedFinanceRatesDurations,
    getDurationMinMax,
    getLabel,
    (label) => getBnplLabel(rates, label),
  );

export const getBuyNowPayLaterRateLabel = (
  rate: FinanceRate,
  hasPrefix = false,
): string =>
  [
    hasPrefix ? 'BNPL' : 'BNPL',
    [rate.deferred_duration, rate.duration].join(' + '),
    'months',
  ]
    .filter(Boolean)
    .join(' ');

export const getFinanceRateLabel = (rate: FinanceRate): string =>
  pipe(
    rate,
    (currentRate) =>
      [currentRate, rateIsBuyNowPayLater(rate)] as [FinanceRate, boolean],
    ([currentRate, isBuyNowPayLater]) => {
      if (isBuyNowPayLater) {
        return getBuyNowPayLaterRateLabel(currentRate);
      }

      const { duration } = getFinanceOptionDetails(currentRate);

      return getLabel([duration, null]);
    },
  );

export const getFinanceRateGroupName = (
  { apr, financeTypes }: FinanceRateDetails,
  indictateSecondLine = false,
  suppressInterestLabel = false,
): string => {
  if (indictateSecondLine && financeTypes.includes('secondary'))
    return `${apr / 100}% (2nd line)`;
  return `${apr / 100}% APR${suppressInterestLabel ? '' : ' interest'}`;
};

export const getFinanceRateFeeChargeProviderBnplKey = (
  rate: FinanceRate,
): string =>
  [
    rate.fee,
    rate.charge,
    rate.provider,
    rate?.deferred_duration ? 'bnpl' : undefined,
  ]
    .filter(Boolean)
    .join('-');

// DEV_NOTE: Below is temporary to show badge for coming soon. When etika
// is ready, remove this and uncomment function below this one
export const filterForEnabledRates = (
  rates: FinanceRate[],
  hideComingSoon = false,
): FinanceRate[] => {
  const initial = rates.filter((rate) => rate.enabled);
  if (hideComingSoon) return initial;
  const combined = [
    ...initial,
    ...COMING_SOON_1390_RATES,
    ...(initial.some((rate: FinanceRate) => rate.apr === 1190)
      ? []
      : COMING_SOON_1190_RATES),
  ];
  return combined;
};

export const filterForPrimaryRates = (rates: FinanceRate[]): FinanceRate[] =>
  rates.filter((rate) => rate.finance_types.includes('primary'));

export const filterForBuyNowPayLater = (
  rates: FinanceRate[],
  buyNowPayLater = false,
): FinanceRate[] =>
  rates.filter((rate) => {
    const isBuyNowPayLater = rateIsBuyNowPayLater(rate);

    return buyNowPayLater ? isBuyNowPayLater : !isBuyNowPayLater;
  });

export const filterForUnregulated = (
  rates: FinanceRate[],
  unregulated = false,
): FinanceRate[] =>
  rates.filter((rate) => {
    const isUnregulated = rateIsUnregulated(rate);
    return unregulated ? isUnregulated : !isUnregulated;
  });

export const filterForRatesWithJobTotal = (
  rates: FinanceRate[],
  jobTotal: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_job_value && !rate.max_job_value) return true;

    return (
      jobTotal >= ((rate.min_job_value as number) || 0) &&
      jobTotal <= (rate.max_job_value as number)
    );
  });

export const filterForRatesWithDepositPercentage = (
  rates: FinanceRate[],
  depositPercentage: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_deposit_pct && !rate.max_deposit_pct) return true;

    // Deposit percentage is stored as a pence value to avoid
    // issues with floating point numbers
    const formattedDepositPercentage = depositPercentage * 100;

    return (
      formattedDepositPercentage >= ((rate.min_deposit_pct as number) || 0) &&
      formattedDepositPercentage <= (rate.max_deposit_pct as number)
    );
  });

export const filterForRatesWithDepositAmount = (
  rates: FinanceRate[],
  depositAmount: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_deposit_value && !rate.max_deposit_value) return true;

    return (
      depositAmount >= ((rate.min_deposit_value as number) || 0) &&
      depositAmount <= (rate.max_deposit_value as number)
    );
  });

export const filterForRatesByWorkType = (
  rates: FinanceRate[],
  workType?: Job['work_type'],
): FinanceRate[] =>
  rates.filter((rate) => {
    if ((rate?.work_types || []).length === 0) return true;
    return !workType || !rate.work_types || rate.work_types.includes(workType);
  });

export const getCompanyInitialRates = (rates: FinanceRate[]) =>
  pipe(
    rates,
    // Only return enabled rates
    filterForEnabledRates,
    // Only return rates that are available for primary finance
    filterForPrimaryRates,
  );

export const filterForNonSelectableRates = (
  rates: FinanceRate[],
): FinanceRate[] => rates.filter((rate) => !financeRateIsSelectable(rate));

export const filterForValidRates = (
  rates: FinanceRate[],
  workType?: Job['work_type'],
  unregulated = false,
  hideComingSoon = false,
  excludeSelectableRates = false,
): FinanceRate[] =>
  pipe(
    rates,
    // Only return enabled rates
    (currentRates) => filterForEnabledRates(currentRates, hideComingSoon),
    // Only return rates that are available for primary finance
    filterForPrimaryRates,
    (currentRates) => filterForRatesByWorkType(currentRates, workType),
    // Filter for rates depending on the buyNowPayLater flag
    (currentRates) => filterForUnregulated(currentRates, unregulated),
    // If excludeSelectableRates is true, then filter out selectable rates
    (currentRates) =>
      excludeSelectableRates
        ? filterForNonSelectableRates(currentRates)
        : currentRates,
  );

const sortInterestRateGroupByRateWithinBounds = (
  group: StringIndexedObject<FinanceRate[]>,
  total: number,
  depositPercentage: number,
  depositAmount: number,
): StringIndexedObject<FinanceRate[]> => {
  const keys = Object.keys(group);

  return keys.reduce(
    (rates, key) => ({
      ...rates,
      [key]: [...(group[key] || [])].sort((i, j) => {
        const iWithinBounds = financeRateIsWithinBounds(
          i,
          total,
          depositPercentage,
          depositAmount,
        )
          ? 1
          : 0;
        const jWithinBounds = financeRateIsWithinBounds(
          j,
          total,
          depositPercentage,
          depositAmount,
        )
          ? 1
          : 0;

        return jWithinBounds - iWithinBounds;
      }),
    }),
    {} as StringIndexedObject<FinanceRate[]>,
  );
};

export const filterForRequestableRates = (
  availableRates: FinanceRate[],
  companyRates: FinanceRate[],
) => {
  const availableEnabledRates = filterForEnabledRates(availableRates);
  const companyEnabledRates = filterForEnabledRates(companyRates);

  return availableEnabledRates.filter(
    (rate) =>
      !companyEnabledRates.some(
        (companyRate) => rate.name === companyRate.name,
      ),
  );
};

export const groupByInterestRate = (
  rates: FinanceRate[],
  indictateSecondLine = false,
  suppressInterestLabel = false,
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    // Group the rates by the group name (interest rate) & provider
    groupBy(
      flow(getFinanceRateDetails, (details) =>
        getFinanceRateGroupName(
          details,
          indictateSecondLine,
          suppressInterestLabel,
        ),
      ),
    ),
  );

export const groupValidRatesByInterestRate = (
  rates: FinanceRate[],
  total: number,
  depositPercentage: number,
  depositAmount: number,
  workType?: Job['work_type'],
  buyNowPayLater = false,
  hideComingSoon = false,
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    (currentRates) =>
      filterForValidRates(
        currentRates,
        workType,
        buyNowPayLater,
        hideComingSoon,
      ),
    // Group the rates by the group name (interest rate) & provider
    groupByInterestRate,
    (currentRates) =>
      sortInterestRateGroupByRateWithinBounds(
        currentRates,
        total,
        depositPercentage,
        depositAmount,
      ),
  );

const getMinimumDuration = (financeRates: FinanceRate[]): number =>
  [...(financeRates || [])]
    .map(({ duration }) => duration || 0)
    .sort()
    .shift() || 0;

// Sort the finance rates by the minimum duration
// so that each group is displayed in the correct order
const sortFinanceRateChargeAndProviderKeys = (
  group: StringIndexedObject<FinanceRate[]>,
): StringIndexedObject<FinanceRate[]> => {
  const keys = Object.keys(group);
  const sortedKeys = keys.sort((i, j) => {
    const minimumI = getMinimumDuration(group[i]);
    const minimumJ = getMinimumDuration(group[j]);

    return minimumI - minimumJ;
  });

  return sortedKeys.reduce(
    (rates, key) => ({
      ...rates,
      [key]: group[key],
    }),
    {} as StringIndexedObject<FinanceRate[]>,
  );
};

export const groupByFeeChargeProviderBnpl = (
  rates: FinanceRate[],
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    groupBy(getFinanceRateFeeChargeProviderBnplKey),
    sortFinanceRateChargeAndProviderKeys,
  );

export type CombinedRates = StringIndexedObject<
  StringIndexedObject<FinanceRate[]>
>;

export const getSortedFinanceRateKeys = (
  rates: StringIndexedObject<FinanceRate[]>,
): string[] =>
  Object.keys(rates).sort(
    (i, j) => Number(i.split('%').shift()) - Number(j.split('%').shift()),
  );

export const findCombinedRates = (
  rates: StringIndexedObject<FinanceRate[]>,
): CombinedRates =>
  getSortedFinanceRateKeys(rates).reduce(
    (formattedRates, rateKey) => ({
      ...formattedRates,
      [rateKey]: groupByFeeChargeProviderBnpl(rates[rateKey]),
    }),
    {} as CombinedRates,
  );
