import type { Job } from '@kanda-libs/ks-frontend-services';
import useCurrentJob from 'pages/JobDetails/JobDetails-useCurrentJob';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface FetchDraftHook {
  draft: Job | null;
  hasDraft: boolean;
  isValidating: boolean;
}

/**
 * Handles job submit
 */
const useFetchDraft = (): FetchDraftHook => {
  const { id } = useSelector(selectors.getPathKey);
  const { refetchJobFromId } = useCurrentJob();

  const isLoading = useSelector(selectors.getIsLoading);
  const jobs = useSelector(selectors.job.getEntitiesAsArray);
  const job = useSelector(selectors.job.getEntity);

  // Fetch stored job from cache
  const storedJob = useMemo(() => {
    if (!id || !jobs) return null;
    const filtered = jobs.filter((current) => current?.id === id);
    if (filtered.length !== 1) return null;
    return filtered[0];
  }, [id, jobs]);

  // Ensure fetched job is null until revalidated
  const fetchedJob = useMemo(() => {
    if (!id || !job || isLoading) return null;
    if (job?.id !== id) {
      refetchJobFromId();
      return null;
    }
    return job;
  }, [id, job, isLoading, refetchJobFromId]);

  const draft = useMemo(() => {
    if (!storedJob && !fetchedJob) return null;
    if (!storedJob) return null;
    if (!fetchedJob) return storedJob;
    return fetchedJob;
  }, [fetchedJob, storedJob]);

  return {
    draft,
    hasDraft: !!id,
    isValidating: (id && !draft) || false,
  };
};

export default useFetchDraft;
