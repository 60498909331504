export const SEND_REMINDER_MODAL_ID = 'send-reminder';

export const CLASS_NAMES = {
  modal: 'w-full p-2 md:p-0 max-w-120',
  wrapper: 'flex flex-col items-center text-center md:min-w-75 md:m-14',
  icon: 'mb-6 w-15 h-15 text-green-300',
  title: 'text-24-28-em mb-8',
  list: 'text-16-34 text-neutral-600 mb-8 text-left list-disc mx-4 max-w-80',
  listItem: 'mb-2',
  button: 'w-full mb-4',
  link: 'w-full text-16-20-em text-neutral-600 underline cursor-pointer',
};

export const DONT_SHOW_AGAIN_LOCAL_STORAGE_KEY = 'dontShowAgain';

export const LAST_SENT_LOCAL_STORAGE_KEY = 'reminderLastSent';

export const LAST_SENT_LOCAL_STORAGE_EXPIRY = 1000 * 60 * 60 * 24; // 24 HOURS
