import clsx from 'clsx';
import { useMemo } from 'react';

import { CLASS_NAMES } from './SideBarItem-constants';

const ContainerComponent = ({ children, active }) => {
  const variant = active ? CLASS_NAMES.active : CLASS_NAMES.inactive;

  const classNames = useMemo(
    () =>
      Object.keys(CLASS_NAMES.base).reduce(
        (acc, key) => ({
          ...acc,
          [key]: clsx(CLASS_NAMES.base[key], variant[key]),
        }),
        {},
      ),
    [variant],
  );

  return children({ classNames });
};

ContainerComponent.displayName = 'SideBarItem-container';

export default ContainerComponent;
