import type { MutableRefObject } from 'react';
import { Icon, Text, Button, Loader } from '@kanda-libs/ks-design-library';
import { ModalContainer, ModalLayoutCenter } from '@kanda-libs/ks-component-ts';
import Container from './CloseModal-container';
import { CLOSE_MODAL_ID } from '../../CreateJob-constants';

const ViewComponent = ({}) => (
  <ModalContainer id={CLOSE_MODAL_ID} opacity={30}>
    {({ id, handleClose, ref }) => (
      <Container handleClose={handleClose}>
        {({ onClickExit, onSaveClick, showSave, isSubmitting }) => (
          <>
            <ModalLayoutCenter
              id={id}
              onClose={handleClose}
              ref={ref as MutableRefObject<HTMLDivElement>}
              className="w-160"
            >
              <div className="px-32 py-14">
                <div className="flex flex-col">
                  <Icon
                    icon="delete-file"
                    stroke="red-200"
                    size={64}
                    className="mx-auto mb-6"
                  />
                  <Text
                    text="You’re about to lose all unsaved data"
                    className="w-full text-center text-24-28-em"
                  />
                  <div className="flex flex-col my-8">
                    {showSave ? (
                      <ul className="pl-5 list-disc text-16-24 text-neutral-600">
                        <li className="mb-2">
                          Save the draft to carry on later
                        </li>
                        <li>Exit without saving to abandon the job</li>
                      </ul>
                    ) : (
                      <Text
                        text="To save this job as a draft, you'll need to add at least a job title and fill out one item"
                        className="text-center text-16-24 text-neutral-600"
                      />
                    )}
                  </div>

                  {showSave && (
                    <Button.Text
                      id="create-job-close-modal-save-draft"
                      label="Save draft"
                      variant="gradient"
                      className="w-full mb-3"
                      onClick={onSaveClick}
                      disabled={isSubmitting}
                    />
                  )}
                  <Button.Text
                    id="create-job-close-modal-exit"
                    label="Exit without saving"
                    variant="outline"
                    className="w-full"
                    onClick={onClickExit}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            </ModalLayoutCenter>
            <Loader isLoading={isSubmitting} />
          </>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'CreateJob-CloseModal-view';

export default ViewComponent;
