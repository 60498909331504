export const COMPANY_HEADER_TITLE = 'Your company';

export const CLASS_NAMES = {
  container: 'flex flex-row items-center mb-10 md:mb-0',
  title: 'text-18-22-em md:text-32-40-em text-neutral-900 mb-1',
  companyName: 'text-14-22-em text-neutral-500 md:hidden',
  subscriptionStatus: 'text-14-22-em text-neutral-500',
  upgradeButton: 'text-neutral-900 max-h-6 max-w-40 ml-2 w-33',
  banner: 'md:hidden',
};

export const SKELETONS = {
  logo: {
    wrapperClassName: 'w-full h-full block md:w-14 md:h-14',
    width: '100%',
    height: '100%',
    className: 'leading-unset',
    circle: true,
  },
  title: {
    width: 160,
  },
  companyName: {
    width: 250,
    height: 20,
  },
};

export const AVATAR = {
  mobile: {
    size: 14,
    icon: 'toolbox',
    stroke: 'lavender-200',
  },
  desktop: {
    size: 24,
    icon: 'toolbox',
    stroke: 'lavender-200',
  },
};

export const PROGRESS_PROPS = {
  width: '124%',
  height: '124%',
  style: { marginLeft: '4%' },
  className:
    'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
};
