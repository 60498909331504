import { Icon, Text } from '@kanda-libs/ks-design-library';
import clsx from 'clsx';

interface BulletListSidebarProps {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  bullets: {
    title: string;
    subText?: string;
  }[];
  children?: JSX.Element;
}

const BulletListSidebar = ({
  title,
  description,
  bullets,
  children,
}: BulletListSidebarProps) => (
  <div className="relative flex flex-col flex-1 mb-10 mr-auto md:max-w-80 md:pr-12">
    <Text
      text={title}
      className="mb-6 text-28-32-em text-neutral-900 md:mb-8"
    />
    {description && (
      <Text
        text={description}
        className="mb-6 -mt-2 text-18-27 text-neutral-900"
      />
    )}
    {bullets.map((bullet) => {
      const bulletWrapperClasses = clsx(
        'flex flex-col mb-3',
        bullet.subText ? 'md:mb-8' : '',
      );

      return (
        <div key={bullet.title} className={bulletWrapperClasses}>
          <div className="flex flex-row md:mb-3">
            <div className="flex p-1.5 rounded-full bg-green-200 mr-3 mb-auto">
              <Icon icon="check" stroke="green-600" size={12} />
            </div>
            <Text
              text={bullet.title}
              className="text-16-20-em mt-0.5 mb-auto text-neutral-700 w-full"
            />
          </div>
          {bullet.subText && (
            <Text
              text={bullet.subText}
              className="hidden md:block text-16-24 text-neutral-700"
            />
          )}
        </div>
      );
    })}
    {children}
  </div>
);

export default BulletListSidebar;
