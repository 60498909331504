import type { Job, Payment } from '@kanda-libs/ks-frontend-services';

const getLatestLoan = (payments: Payment[]) => {
  const loans = payments
    .filter((payment) => payment?.payment_option?.payment_method === 'loan')
    .sort(
      (loan1, loan2) =>
        (new Date(
          loan2?.metadata?.created_at as unknown as number,
        ) as unknown as number) -
        (new Date(
          loan1?.metadata?.created_at as unknown as number,
        ) as unknown as number),
    );
  if (loans?.length === 0) return null;
  return loans[0];
};

export const getCreditId = (job: Job | undefined): string => {
  if (!job) return '';
  if (job?.finance_status === 'not_applied') return '';
  const payments = job?.payments;
  if (!payments || payments?.length === 0) return '';
  const latestLoan = getLatestLoan(payments);
  if (!latestLoan?.id) return '';
  return latestLoan.id;
};
