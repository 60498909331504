import { URLS } from 'config';

export interface StepBasis {
  label: string;
  url: string;
}

export const STEPS: StepBasis[] = [
  {
    label: 'Account',
    url: URLS.signUp,
  },
  {
    label: 'Rates',
    url: URLS.setupRates,
  },
  {
    label: 'Subscription',
    url: URLS.setupSubscription,
  },
];

export const STEP_BASE_CLASSNAME =
  'text-center first:text-left last:text-right text-14-22-em';

export const STEP_CLASSNAMES = {
  active: [STEP_BASE_CLASSNAME, 'text-green-600'].join(' '),
  default: [STEP_BASE_CLASSNAME, 'text-neutral-500'].join(' '),
};
