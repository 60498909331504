import { URLS } from 'config';
import Container from './BackLink-container';

interface ViewComponentChildrenArgs {
  handleClick: () => void;
}

interface ViewComponentProps {
  /**
   * Children
   */
  children(args: ViewComponentChildrenArgs): JSX.Element;
  backURL?: string;
}

const ViewComponent = ({
  children,
  backURL = URLS.home,
}: ViewComponentProps) => (
  <Container backURL={backURL}>
    {({ handleClick }) => {
      if (!children) return <></>;
      return children({ handleClick });
    }}
  </Container>
);

ViewComponent.displayName = 'BackLink';

export default ViewComponent;
