import {
  Field,
  Widget,
  type AddressApiData,
} from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import { GetName } from 'common';
import type { FunctionComponent } from 'react';
import useDirectorAddress from './useDirectorAddress';

export interface DirectorAddressProps {
  name?: string;
  widgetTagName?: string;
  labelPrefix?: string;
  isArray?: boolean;
  index?: number;
}

const DirectorAddress: FunctionComponent<DirectorAddressProps> = function ({
  name = 'director_verification.home_address',
  widgetTagName = 'DirectorVerificationHomeAddress',
  labelPrefix = 'Director Address',
  isArray = false,
  index,
}) {
  const {
    setPostcodeName,
    postcodeCallback,
    postcodeName,
    addresses,
    manual,
    enterManually,
    selectProps,
  } = useDirectorAddress(name, isArray, index);

  const arrayPrefix = isArray ? 'ArrayInput' : '';

  const DirectorVerificationHomeAddressPostcodeTag =
    Widget[`${widgetTagName}Postcode${arrayPrefix}`];

  const DirectorVerificationHomeAddressLine1Tag =
    Widget[`${widgetTagName}Line1${arrayPrefix}`];
  const DirectorVerificationHomeAddressLine2Tag =
    Widget[`${widgetTagName}Line2${arrayPrefix}`];
  const DirectorVerificationHomeAddressCityTag =
    Widget[`${widgetTagName}City${arrayPrefix}`];
  const DirectorVerificationHomeAddressCountyTag =
    Widget[`${widgetTagName}County${arrayPrefix}`];
  const DirectorVerificationHomeAddressCountryTag =
    Widget[`${widgetTagName}Country${arrayPrefix}`];

  return (
    <>
      <GetName callback={setPostcodeName}>
        <DirectorVerificationHomeAddressPostcodeTag
          callback={postcodeCallback}
          label={`${labelPrefix} postcode`}
          index={index}
        />
      </GetName>
      {postcodeName && (
        <Field.Address.Select
          postcodeName={postcodeName}
          data={addresses as AddressApiData}
          {...selectProps}
        />
      )}
      {!manual ? (
        <Button.Link
          id="identify-director-manually-enter-address"
          label="Enter address manually"
          size={14}
          variant="turquoise"
          className="mb-6 mr-auto -mt-4"
          onClick={enterManually}
        />
      ) : (
        <>
          <DirectorVerificationHomeAddressLine1Tag
            label={`${labelPrefix} line 1`}
            index={index}
          />
          <DirectorVerificationHomeAddressLine2Tag
            label={`${labelPrefix} line 2`}
            index={index}
          />
          <DirectorVerificationHomeAddressCityTag
            label={`${labelPrefix} city`}
            index={index}
          />
          <DirectorVerificationHomeAddressCountyTag
            label={`${labelPrefix} county`}
            index={index}
          />
          <DirectorVerificationHomeAddressCountryTag
            label={`${labelPrefix} country`}
            index={index}
          />
        </>
      )}
    </>
  );
};

export default DirectorAddress;
