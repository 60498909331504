import { Field } from '@kanda-libs/ks-component-ts';
import { Button } from '@kanda-libs/ks-design-library';
import {
  IAR_FIELD,
  IAR_FIELD_VALIDATION,
  TAC_FIELD,
  TAC_FIELD_VALIDATION,
} from './constants';

interface SignDocumentProps {
  disabled: boolean;
  isSubmitting: boolean;
}

const SignDocument: React.FunctionComponent<SignDocumentProps> = function ({
  disabled,
  isSubmitting,
}) {
  return (
    <div className="w-full max-w-120 mx-auto flex flex-col mt-5 md:mt-8">
      <div className="flex flex-col mb-5 text-left field-wrapper w-full">
        <Field.Validator validation={TAC_FIELD_VALIDATION}>
          <Field.BooleanInput
            {...TAC_FIELD}
            fieldText={
              <p>
                {TAC_FIELD.fieldText}.{' '}
                <a
                  href="https://www.getkanda.com/subscription-personal-guarantee"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-blue-200"
                >
                  What is the personal guarantee for?
                </a>
              </p>
            }
          />
        </Field.Validator>
        <Field.Validator validation={IAR_FIELD_VALIDATION}>
          <Field.BooleanInput {...IAR_FIELD} />
        </Field.Validator>
      </div>
      <Button.Text
        id="sign-subscription-contract"
        label="Sign Contract"
        disabled={disabled || isSubmitting}
        variant="gradient"
        icon="check"
        submit
      />
    </div>
  );
};

export default SignDocument;
