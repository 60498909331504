import type { Contract } from 'pages/Setup/Subscription/context';
import { useCallback } from 'react';
import { downloadBase64PDF } from 'utils';

interface ActionBarHook {
  onDownloadClick: () => void;
}

export default function useActionBar(contract: Contract): ActionBarHook {
  const onDownloadClick = useCallback(() => {
    if (!contract) return;
    const { content, name } = contract;
    downloadBase64PDF(content, name);
  }, [contract]);

  return {
    onDownloadClick,
  };
}
