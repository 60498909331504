import { useCallback, useMemo, useEffect } from 'react';
import { useForm } from '@kanda-libs/ks-component-ts';
import type { UseFormReturn } from 'react-hook-form';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import type { CreateJobContextValue } from 'pages/CreateJob/types';
import type { Job } from '@kanda-libs/ks-frontend-services';

export interface ExtraDetailsContainerChildrenArgs {
  form: UseFormReturn;
  onSubmit: (data: CreateJobContextValue) => void;
  nextButtonProps: { submit: boolean };
}

export interface ExtraDetailsContainerProps {
  children: (args: ExtraDetailsContainerChildrenArgs) => JSX.Element;
}

const ExtraDetailsContainer = ({ children }: ExtraDetailsContainerProps) => {
  const form = useForm({
    mode: 'onBlur',
  });

  const { reset } = form;

  const { data: formData, handleContinue } = useCreateJobContextValue();

  const defaultValues = useMemo(() => {
    if (!formData?.job) return null;
    return {
      job: formData.job,
    };
  }, [formData]);

  const onSubmit = useCallback(
    (data: CreateJobContextValue) => {
      const { job } = data;

      handleContinue({
        ...formData,
        job: {
          ...(formData.job as Job),
          notes: job?.notes || [],
          work_type: job?.work_type,
        },
      });
    },
    [formData, handleContinue],
  );

  const nextButtonProps = { submit: true };

  // Effect updates the form with new default values after a draft has loaded
  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [reset, defaultValues]);

  return children({
    form,
    onSubmit,
    nextButtonProps,
  });
};

ExtraDetailsContainer.displayName = 'CreateJob-Customer-Mobile-container';

export default ExtraDetailsContainer;
