import { Button } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { CLASS_NAMES, LOGIN_BUTTON, SSO_BUTTON } from './Footer-constants';

import {
  FacebookSignupButton,
  GoogleSignupButton,
} from '../../../../components';
import { URLS } from '../../../../config';

interface ViewComponentProps {
  /**
   * Disabled state for login button
   */
  disabled?: boolean;
  /**
   * Disabled state for social buttons
   */
  socialDisabled?: boolean;
  /**
   * Function to sign in with Google
   */
  signInWithGoogle?(...args: unknown[]): unknown;
  /**
   * Function to sign in with FB
   */
  signInWithFb?(...args: unknown[]): unknown;
}

const ViewComponent = ({
  disabled,
  socialDisabled,
  signInWithGoogle,
  signInWithFb,
}: ViewComponentProps) => (
  <div className={CLASS_NAMES.container}>
    <Button.Text {...LOGIN_BUTTON} disabled={disabled} />
    <div className={CLASS_NAMES.socialContainer}>
      <GoogleSignupButton
        socialDisabled={socialDisabled}
        signInWithGoogle={signInWithGoogle}
      />
      <FacebookSignupButton
        socialDisabled={socialDisabled}
        signInWithFb={signInWithFb}
      />
    </div>
    <Link to={URLS.loginSso} className="mx-auto">
      <Button.Link {...SSO_BUTTON} />
    </Link>
  </div>
);

ViewComponent.defaultProps = {
  disabled: undefined,
  socialDisabled: undefined,
  signInWithGoogle: undefined,
  signInWithFb: undefined,
};

ViewComponent.displayName = 'Login-Footer-view';

export default ViewComponent;
