import { Button, Header } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import Container from './Header-container';

import { URLS } from '../../../../../config';

const ViewComponent = ({}) => (
  <Container>
    {({ archive }) =>
      archive ? (
        <Header.Base>
          <Link to={URLS.home}>
            <Button.Icon
              id="home-mobile-back"
              icon="chevron-left"
              className="-ml-4"
            />
          </Link>
        </Header.Base>
      ) : (
        <Header.Main linkComponent={Link} />
      )
    }
  </Container>
);

ViewComponent.displayName = 'Home-Mobile-view';

export default ViewComponent;
