import { Text } from '@kanda-libs/ks-design-library';
import type { FunctionComponent } from 'react';
import OptionGroupHeader from '../FinanceOptions/components/OptionGroupHeader';
import SlimOption from '../FinanceOptions/components/SlimOption';
import { CLASS_NAMES } from '../PaymentAndFinanceOptions-constants';
import useLeadFinanceOption from './useLeadFinanceOption';

const LeadFinanceOption: FunctionComponent = function () {
  const { isLeadJob, financeOptions, isLoading } = useLeadFinanceOption();

  if (!isLeadJob || !financeOptions.length) return <></>;

  return (
    <div className={CLASS_NAMES.optionWrapper}>
      <Text
        className={CLASS_NAMES.optionTitle}
        text="Customer pre-approved for these rates"
        isLoading={isLoading}
      />
      <OptionGroupHeader isLoading={isLoading} label="11.9% APR Interest" />
      <SlimOption
        financeOptions={financeOptions}
        hideHandle={false}
        checkIfRateWithinBounds={false}
      />
    </div>
  );
};

export default LeadFinanceOption;
