import { URLS } from 'config';
import useQueryParam from 'hooks/useQueryParam';
import { Redirect } from 'react-router-dom';

const SetupCompany: React.FunctionComponent = function () {
  const [pid] = useQueryParam('pid');

  if (pid && pid !== 'null')
    return (
      <Redirect
        to={{
          pathname: URLS.setupAccount,
          search: `?pid=${pid}`,
        }}
      />
    );

  return (
    <Redirect
      to={{
        pathname: URLS.setupAccount,
      }}
    />
  );
};

export default SetupCompany;
