import Address from './Address';
import BankDetails from './BankDetails';
import CheckBoxes from './CheckBoxes';
import DateOfBirth from './DateOfBirth';
import Name from './Name';

const Fields = {
  Address,
  BankDetails,
  CheckBoxes,
  DateOfBirth,
  Name,
};

export default Fields;
