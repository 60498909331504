export const BUTTON_PROPS = {
  id: 'team-settings-add-user',
  left: true,
  icon: 'plus',
  label: 'Invite team member',
  className: 'mr-auto mt-6',
  size: 40,
};

export const BASE_USER = {
  first_name: '',
  last_name: '',
  role: 'company-staff',
  email: '',
  state: 'new',
};
