import { Field } from '@kanda-libs/ks-component-ts';
import { NAME_VALIDATION } from './constants';

const Name: React.FunctionComponent = function () {
  return (
    <Field.Validator validation={NAME_VALIDATION}>
      <Field.Input
        name="guarantor.name"
        label="Full legal name as it appears on ID documentation"
        placeholder="Full legal name"
      />
    </Field.Validator>
  );
};

export default Name;
