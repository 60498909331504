import { useMemo } from 'react';
import { useWatch } from '@kanda-libs/ks-component-ts';

import { FIELD_NAMES } from './Form-constants';

export default function useOnlinePresenceForm() {
  const [qPresence, qGoogle, qFacebook] = useWatch({
    name: [FIELD_NAMES.presence, FIELD_NAMES.google, FIELD_NAMES.facebook],
  });

  const showFields = useMemo(() => {
    if (!qPresence) return false;
    return qPresence === 'Yes, I can provide the details';
  }, [qPresence]);

  const showGoogle = useMemo(() => {
    if (!qGoogle) return false;
    return qGoogle === 'yes';
  }, [qGoogle]);

  const showFacebook = useMemo(() => {
    if (!qFacebook) return false;
    return qFacebook === 'yes';
  }, [qFacebook]);

  return {
    showFields,
    showGoogle,
    showFacebook,
  };
}
