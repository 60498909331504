import type { MutableRefObject } from 'react';
import { Button, Icon, useIsDesktop } from '@kanda-libs/ks-design-library';
import {
  ModalContainer,
  ModalLayoutCenter,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';
import { CLASS_NAMES, SEND_REMINDER_MODAL_ID } from './constants';

interface SendReminderModalProps {
  onClickDontShowAgain(...args: unknown[]): unknown;
}

const SendReminderModal = ({
  onClickDontShowAgain,
}: SendReminderModalProps) => {
  const isDesktop = useIsDesktop();

  const ModalLayout = isDesktop ? ModalLayoutCenter : ModalLayoutFullScreen;

  return (
    <ModalContainer id={SEND_REMINDER_MODAL_ID}>
      {({ id, handleClose, ref }) => (
        <>
          <ModalLayout
            id={id}
            onClose={handleClose}
            ref={ref as MutableRefObject<HTMLDivElement>}
            className={CLASS_NAMES.modal}
          >
            <div className={CLASS_NAMES.wrapper}>
              <Icon icon="send" className={CLASS_NAMES.icon} size={60} />
              <span className={CLASS_NAMES.title}>
                Reminder sent to customer
              </span>
              <ul className={CLASS_NAMES.list}>
                <li className={CLASS_NAMES.listItem}>
                  Tell your customer to check their SPAM folder
                </li>
                <li className={CLASS_NAMES.listItem}>
                  Search for an email from KANDA
                </li>
              </ul>
              <Button.Text
                id="job-details-send-reminder-modal-confirm"
                label="Ok! Got it"
                onClick={handleClose}
                className={CLASS_NAMES.button}
              />
              <button
                className={CLASS_NAMES.link}
                onClick={onClickDontShowAgain}
                type="button"
              >
                Don&apos;t show me this again
              </button>
            </div>
          </ModalLayout>
        </>
      )}
    </ModalContainer>
  );
};

export default SendReminderModal;
