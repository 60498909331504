import { Field, Form, Widget } from '@kanda-libs/ks-component-ts';
import { Button, Loader } from '@kanda-libs/ks-design-library';

import BasePage from '../BasePage';
import ActionBar from './ActionBar';
import Document from './Document';
import ScrollBottomButton from './ScrollBottomButton';
import {
  AUTH_FIELD,
  AUTH_FIELD_VALIDATION,
  BULLETS,
  IAR_FIELD,
  IAR_FIELD_VALIDATION,
  SIGNEE_FIELD,
  TAC_FIELD,
  TAC_FIELD_VALIDATION,
  TITLE,
} from './TermsAndConditions-constants';
import useTermsAndConditions from './useTermsAndConditions';

const ViewComponent = ({}) => {
  const { form, onSubmit, contract, isSubmitting, isLoading, disabled } =
    useTermsAndConditions();

  return (
    <Form
      id="onboarding-company-info-terms-and-conditions"
      form={form}
      onSubmit={onSubmit}
    >
      <BasePage sidebarBullets={BULLETS} sidebarTitle={TITLE}>
        <div className="flex flex-col md:-mt-10 relative">
          <span className="text-14-22-em mb-4">Contract</span>
          <ActionBar contract={contract} />
          <Document contract={contract} />
          <ScrollBottomButton />
          <Widget.CompanyCompanyInfoContractAgreementPrintName
            {...SIGNEE_FIELD}
            isLoading={isLoading}
          />
          <Field.Validator validation={AUTH_FIELD_VALIDATION}>
            <Field.BooleanInput {...AUTH_FIELD} isLoading={isLoading} />
          </Field.Validator>
          <div className="mb-6">
            <Field.Validator validation={TAC_FIELD_VALIDATION}>
              <Field.FingerprintBooleanInput
                {...TAC_FIELD}
                isLoading={isLoading}
              />
            </Field.Validator>
          </div>
          <Field.Validator validation={IAR_FIELD_VALIDATION}>
            <Field.BooleanInput {...IAR_FIELD} isLoading={isLoading} />
          </Field.Validator>
          <Button.Text
            submit
            id="onboarding-terms-sign"
            disabled={isSubmitting || disabled}
            label="Sign"
            left
            icon="check"
          />
        </div>
      </BasePage>
      <Loader isLoading={isSubmitting} />
    </Form>
  );
};

ViewComponent.displayName = 'TermsAndConditions-view';

export default ViewComponent;
