export const isSessionError = (error) => {
  if (!error?.code) return false;
  return error.code === 'auth/requires-recent-login';
};

/**
 * Handles error extraction from error object
 * @param {Object} error Error object from API
 * @returns {String} Error string
 */
export const getError = (error) => {
  const key = Object.keys(error)[0];
  if (!key) return 'There was an error with your request';
  return error[key][0];
};
