import { useForm } from '@kanda-libs/ks-component-ts';
import { useCurrentCompany } from 'hooks';

export interface CalculatorHook {
  form: ReturnType<typeof useForm>;
  code: string;
}

export default function useCalculator(): CalculatorHook {
  const { company } = useCurrentCompany();
  const form = useForm();

  const code = `<iframe title="Kanda Finance Calculator" src="https://finance-calculator.kanda.co.uk/?cid=${
    company?.id
  }&amp;iar=${company?.contact_info?.trading_name?.replace(
    / /g,
    '+',
  )}" class="h-full w-full" style="height: 800px"></iframe>`;

  return {
    form,
    code,
  };
}
