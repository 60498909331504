import {
  Header,
  Button,
  Loader,
  Tag,
  Text,
} from '@kanda-libs/ks-design-library';
import {
  Form,
  ModalContainer,
  ModalLayoutFullScreen,
} from '@kanda-libs/ks-component-ts';

import Container from './NotificationsModal-container';
import { BUCKETS } from './NotificationsModal-constants';
import Bucket from './Bucket';

import { NOTIFICATIONS_MODAL_ID } from '../../Users-constants';

export interface NotificationsModalProps {
  userToManage: number | null;
}

const ViewComponent = (props: NotificationsModalProps) => (
  <ModalContainer id={NOTIFICATIONS_MODAL_ID} opacity={30}>
    {({ handleClose }) => (
      <Container {...props} handleClose={handleClose}>
        {({
          isSubmitting,
          form,
          onSubmit,
          onClick,
          name,
          email,
          mobile,
          director,
          verified,
          index,
          commPreferences,
          hasCommsPrefs,
          addPreferences,
        }) => (
          <>
            <ModalLayoutFullScreen
              noBorder
              header={
                <Header.Base
                  className="text-style-f-em text-neutral-700"
                  options={[
                    <Button.Icon
                      id="notification-preference-close-modal-close"
                      key="close"
                      onClick={handleClose}
                      icon="close"
                    />,
                  ]}
                />
              }
            >
              <div className="flex flex-col">
                <Text text="Team member" className="mb-4 text-16-20-em" />
                <div className="flex flex-row justify-between py-4 mb-6 border-b border-neutral-300">
                  <div className="flex flex-col">
                    <Text
                      text={name}
                      className="text-14-22-em text-neutral-700"
                    />
                    <Text
                      text={email}
                      className="text-10-14 text-neutral-600"
                    />
                    {mobile && (
                      <Text
                        text={mobile}
                        className="text-10-14 text-neutral-600"
                      />
                    )}
                  </div>
                  {director && (
                    <Tag
                      label="owner"
                      color={verified ? 'green' : 'violet'}
                      size={28}
                      variant="solid"
                      className="ml-3"
                    />
                  )}
                </div>
                {hasCommsPrefs ? (
                  <Form
                    id="notification-preference-mobile-form"
                    form={form}
                    onSubmit={() => {}}
                    className="flex flex-col w-full"
                  >
                    <Text
                      text="Notifications"
                      className="text-12-18-em-up text-neutral-500"
                    />
                    {BUCKETS.map((bucket) => (
                      <Bucket
                        key={bucket}
                        bucket={bucket}
                        onClick={onClick}
                        index={index}
                        commPreferences={commPreferences}
                      />
                    ))}
                    <Button.Text
                      id="notification-preference-submit"
                      label="Save"
                      icon="send"
                      size={40}
                      left
                      className="w-40 mt-8 mr-auto"
                      onClick={onSubmit}
                      disabled={isSubmitting}
                    />
                  </Form>
                ) : (
                  <Button.Text
                    id="add-notifications-preferences"
                    label="Add notification preferences"
                    icon="user"
                    size={40}
                    left
                    className="mt-8 mr-auto"
                    onClick={addPreferences}
                  />
                )}
              </div>
            </ModalLayoutFullScreen>
            <Loader isLoading={isSubmitting} />
          </>
        )}
      </Container>
    )}
  </ModalContainer>
);

ViewComponent.displayName = 'NotificationsModal-view';

export default ViewComponent;
