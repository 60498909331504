import { useForm } from '@kanda-libs/ks-component-ts';
import { actions, Company } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import useCurrentCompany from 'hooks/useCurrentCompany';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { cleanObject } from 'utils';

export default function useJobInfo() {
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(selectors.company.getIsSubmitting);
  const form = useForm({
    mode: 'onBlur',
  });

  const { data, nextStep } = useOnboardingContextValue();
  const { company } = useCurrentCompany();
  const onError = useApiError(
    'Error updating company info at this time - please try again later',
  );

  const isLoading = !company;

  const onSubmit = useCallback(
    (submittedData) => {
      if (!company?.company_type) return;
      const formattedData = {
        company: {
          ...data.company,
          company_type: company.company_type,
          company_info: {
            ...data?.company?.company_info,
            ...submittedData?.company?.company_info,
          },
          quote_preference: 'custom',
        },
      };

      const body = cleanObject({
        ...company,
        ...formattedData.company,
      });

      dispatch(
        actions.putCompany({
          params: { id: company?.id as string },
          body: body as unknown as Company,
          onError,
          onSuccess: () => {
            nextStep();
          },
        }),
      );
    },
    [data, company, nextStep, dispatch, onError],
  );

  return {
    form,
    onSubmit,
    isLoading,
    isSubmitting,
  };
}
