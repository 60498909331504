import { useMemo, useEffect } from 'react';
import { useWatch, useFormContext } from '@kanda-libs/ks-component-ts';
import { calculateItemTotal } from 'utils';

const ContainerComponent = ({ children, arrayName, index }) => {
  const { setValue } = useFormContext();

  const fieldNames = useMemo(() => {
    const arrayStruct = `${arrayName}.${index}`;
    return {
      quantity: `${arrayStruct}.quantity`,
      price: `${arrayStruct}.price.amount`,
      vat: `${arrayStruct}.vat_rate`,
      vatAmount: `${arrayStruct}.vat.amount`,
    };
  }, [arrayName, index]);

  const [quantity, price, vat, vatAmount] = useWatch({
    name: [
      fieldNames.quantity,
      fieldNames.price,
      fieldNames.vat,
      fieldNames.vatAmount,
    ],
  });

  const totals = useMemo(
    () => calculateItemTotal(quantity, price, vat, vatAmount),
    [price, quantity, vat, vatAmount],
  );

  useEffect(() => {
    if (vat === 'custom') return;
    setValue(fieldNames.vatAmount, totals.totalVat);
  }, [fieldNames, totals, setValue, vat]);

  return children({
    total: totals.totalIncVat,
  });
};

ContainerComponent.displayName = 'Items-Total-container';

export default ContainerComponent;
