import { Button } from '@kanda-libs/ks-design-library';

import Container from './AddCustomer-container';

interface ViewComponentProps {
  /**
   * addCustomer
   */
  addCustomer(...args: unknown[]): unknown;
}

const ViewComponent = (props: ViewComponentProps) => (
  <Container {...props}>
    {({ buttonProps }) => <Button.Link {...buttonProps} />}
  </Container>
);

ViewComponent.displayName = 'AddCustomer-view';

export default ViewComponent;
