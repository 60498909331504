import {
  ConfigWrapper,
  DesktopFooter,
  DesktopLayoutDefault,
  DesktopLayoutDefaultContent,
} from '@kanda-libs/ks-design-library';
import { DesktopHeader } from '../components';

interface SetupLayoutDesktopProps {
  children: React.ReactNode;
}

const SetupLayoutDesktop: React.FunctionComponent<SetupLayoutDesktopProps> =
  function ({ children }) {
    return (
      <ConfigWrapper desktopFooter={DesktopFooter} desktopHeader={undefined}>
        <DesktopLayoutDefault>
          <DesktopLayoutDefaultContent>
            <div className="-mt-22">
              <DesktopHeader />
              {children}
            </div>
          </DesktopLayoutDefaultContent>
        </DesktopLayoutDefault>
      </ConfigWrapper>
    );
  };

export default SetupLayoutDesktop;
