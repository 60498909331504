import { useEffect, useMemo, useRef } from 'react';
import { BILLING_PENDING } from '../constants/subscription';
import useCurrentCompany from './useCurrentCompany';

export interface IsPendingHook {
  isPending: boolean;
  isLoading: boolean;
}

/**
 * Returns a boolean as to whether the current user has a Kanda subscription
 */
export default function useIsPending(): IsPendingHook {
  const { company, isLoading, getCompanies } = useCurrentCompany();
  const billing = company?.billing;

  const isPending = useMemo(() => {
    if (!billing) return false;
    return billing === BILLING_PENDING;
  }, [billing]);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (isLoading || !billing) return;
    if (billing !== BILLING_PENDING) {
      if (!timerRef.current) return;
      clearTimeout(timerRef.current);
      return;
    }
    timerRef.current = setInterval(() => getCompanies(), 5000);
  }, [getCompanies, isLoading, billing]);

  return { isPending, isLoading: isLoading || !company };
}
