import type { Company } from '@kanda-libs/ks-frontend-services';
import { useCurrentCompany } from 'hooks';
import useOnboardingContextValue from 'pages/Onboarding/Onboarding-useOnboardingContextValue';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { COMPANY_INFO_STEPS } from '../../Form-constants';

// There isn't a specific lifecycle for online presence. So when the Onboarding
// is 'online_reviewed', we need this code to kick in and manually redirect the
// user to the relevant step
export default function useOnlinePresenceRedirect(): void {
  const { company } = useCurrentCompany();
  const { setStep, setData } = useOnboardingContextValue();
  const [data] = useSelector(selectors.infoOnboarding.getEntitiesAsArray);
  const isLoading = useSelector(selectors.getIsLoading);

  useEffect(() => {
    if (!isLoading && setStep && data?.current_stage === 'online_reviewed') {
      setData({ company: company as Company });
      setStep(COMPANY_INFO_STEPS.ONLINE_PRESENCE);
    }
  }, [data, isLoading, setStep, company, setData]);
}
