import { TERMS_URL } from 'config';
import { CLASSNAMES, LINK_TEXT, TERMS_TEXT } from './constants';

const SignUpFormFieldTermsText: React.FunctionComponent = function () {
  return (
    <div className={CLASSNAMES.container}>
      <p className={CLASSNAMES.text}>
        {TERMS_TEXT}
        <a
          href={TERMS_URL}
          className="text-green-500"
          target="_blank"
          rel="noreferrer"
        >
          {LINK_TEXT}
        </a>
      </p>
    </div>
  );
};

export default SignUpFormFieldTermsText;
