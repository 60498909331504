import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';

import { ADD_A_JOB_STEPS } from '../../../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { data, setStep } = useCreateJobContextValue();

  const title = data?.job?.title || 'No title set...';

  return children({
    title,
    onClick: () => {
      if (setStep) setStep(ADD_A_JOB_STEPS.DETAILS);
    },
  });
};

ContainerComponent.displayName = 'JobDetails-Title-container';

export default ContainerComponent;
