import type { Contract } from 'pages/Setup/Subscription/context';
import { Document, Page, pdfjs } from 'react-pdf';

import { CLASS_NAMES, PLACEHOLDER_TEXT } from './constants';
import type { DocumentPdfProps } from './types';
import useDocumentPages from './useDocumentPages';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface DocumentPagesProps {
  contract: Contract;
}

const DocumentPages: React.FunctionComponent<DocumentPagesProps> = function ({
  contract,
}) {
  const { showContent, pdfProps, pages } = useDocumentPages(contract);

  return (
    <div className="flex flex-col">
      <div className={CLASS_NAMES.documentContainer}>
        <div className={CLASS_NAMES.documentPadding}>
          {showContent ? (
            <Document
              {...(pdfProps as DocumentPdfProps)}
              className={CLASS_NAMES.document}
            >
              {pages &&
                pages.map((page) => (
                  <Page
                    key={page.key}
                    {...page}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    renderForms={false}
                    devicePixelRatio={Math.min(2, window.devicePixelRatio)}
                  />
                ))}
            </Document>
          ) : (
            <p className="mx-auto mt-6 text-center max-w-60 text-16-20">
              {PLACEHOLDER_TEXT}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentPages;
