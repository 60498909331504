export const CLASS_NAMES = {
  container:
    'flex flex-1 flex-row px-3 py-2.5 items-center hover:bg-neutral-100 rounded-lg',
  label: 'flex flex-1 text-12-18 text-neutral-900',
};

export const ICON_PROPS = {
  icon: 'check',
  size: 16,
  stroke: 'neutral-500',
};
