import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  FirebaseAuthService,
  useSubmit,
  type Service,
} from '@kanda-libs/ks-frontend-services';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { URLS } from '../../../config';

const ContainerComponent = ({ children }) => {
  const { state } = useLocation();
  const { code, mode } = state || { code: null, mode: null };

  const {
    submit: applyActionCode,
    error: codeError,
    isSubmitting: codeIsValidating,
  } = useSubmit(
    FirebaseAuthService.applyActionCode as unknown as Service<
      StringIndexedObject,
      StringIndexedObject,
      StringIndexedObject
    >,
  );

  const { push } = useHistory();

  const { showError } = useToast();

  const submittedRef = useRef(false);
  useEffect(() => {
    if (!code || codeIsValidating || submittedRef.current) return;
    submittedRef.current = true;
    applyActionCode(code).then(({ error }) => {
      if (error) {
        showError('Code is invalid - please request a new code');
        push(URLS.accountSettings);
        return;
      }
      push(URLS.verifyEmailSuccess);
    });
  }, [code, codeIsValidating, applyActionCode, push, showError]);

  useEffect(() => {
    if (mode && mode === 'VERIFY_EMAIL') return;
    showError('Please request an email verification link from this page');
    push(URLS.accountSettings);
  }, [mode, push, showError]);

  return children({
    disabled: codeIsValidating,
    isLoading: codeIsValidating,
    isInvalid: !!codeError,
  });
};

ContainerComponent.displayName = 'VerifyEmail-Landing-container';

export default ContainerComponent;
