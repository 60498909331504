import { useFormContext } from '@kanda-libs/ks-component-ts';
import { ToastId, useToast } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useMe } from 'hooks';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { TOAST_MESSAGES } from './KandaQuoteBanner-constants';

import { BASE_ITEM } from '../../../CreateJob-constants';

const ContainerComponent = ({ children }) => {
  const { data, setData } = useCreateJobContextValue();
  const { showWarning, dismiss } = useToast();
  const { push } = useHistory();
  const { getValues, setValue } = useFormContext();
  const { me } = useMe();

  const isAdmin = useMemo(() => {
    if (!me) return false;
    return me?.role === 'company-admin';
  }, [me]);

  const toastId = useRef<ToastId | undefined>();
  const showToast = useCallback(() => {
    const id = showWarning(
      isAdmin ? TOAST_MESSAGES.admin : TOAST_MESSAGES.other,
      {},
      {
        position: 'top-center',
        autoClose: 10000,
        ...(isAdmin && {
          onClick: () => {
            if (toastId.current) dismiss(toastId.current);
            push(URLS.jobSettings);
          },
        }),
      },
    );
    toastId.current = id;
  }, [isAdmin, push, showWarning, dismiss]);

  const handleChange = useCallback(() => {
    const values = getValues('job');

    const { job_items: jobItems, ...jobDetails } = values;
    const customItem = jobItems?.[0];
    const initialItem = {
      ...BASE_ITEM,
      ...(customItem && {
        price: customItem?.price,
        ...(customItem?.vat_rate === 'custom'
          ? {
              vat_rate: 'vat_not_set',
              vat: {
                amount: 0,
                currency: 'GBP',
              },
            }
          : {
              vat_rate: customItem?.vat_rate,
              vat: customItem?.vat,
            }),
      }),
    };
    const job = {
      ...data?.job,
      ...(jobDetails && jobDetails),
      job_items: [initialItem],
    };
    setData({
      ...data,
      jobType: 'kanda',
      job,
    });

    if (customItem?.title === 'quote document') {
      setValue('job.job_items.0.title', '');
    }

    showToast();
  }, [data, getValues, setValue, setData, showToast]);

  const showBanner = useMemo(() => {
    if (!data?.jobType) return false;
    return data?.jobType !== 'kanda';
  }, [data]);

  return children({
    showBanner,
    handleChange,
  });
};

ContainerComponent.displayName = 'CreateJob-KandaQuoteBanner-container';

export default ContainerComponent;
