import type { ButtonIconVariant } from '@kanda-libs/ks-design-library';

export const SEARCH_PROPS = {
  name: 'select',
  icon: 'search',
  iconVariant: 'search',
  label: 'Customer',
  placeholder: 'Search previous customers',
};

export const ADD_CUSTOMER_PROPS = {
  icon: 'plus',
  variant: 'grey' as ButtonIconVariant,
  size: 14,
  left: true,
  className: 'py-4 border border-neutral-300 rounded-xl',
};

export const ADD_CUSTOMER_LABEL = 'Add a new customer';
