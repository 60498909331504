import { useEffect, useMemo } from 'react';
import { useFormContext } from '@kanda-libs/ks-component-ts';
import get from 'lodash.get';

import { getError } from './File-functions';

const ContainerComponent = ({ children, arrayName, index }) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const errorName = `${arrayName}.${index}`;
  const errorObject = get(errors, errorName);
  const error = getError(errorObject);

  const docName = useMemo(() => `${arrayName}.${index}`, [arrayName, index]);

  useEffect(() => {
    setValue(docName, undefined);
  }, [docName, setValue]);

  return children({
    error,
  });
};

ContainerComponent.displayName = 'EPVSCalculations-File-container';

export default ContainerComponent;
