import type {
  ButtonLinkVariant,
  ButtonTextVariant,
} from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  container: 'flex flex-col w-full mt-6',
  footer: 'flex flex-row justify-center mt-6',
  signup: 'text-style-g text-neutral-700 mr-2 my-auto',
  socialContainer: 'flex flex-col md:flex-row mt-4',
};

export const LOGIN_BUTTON = {
  id: 'login-footer-login',
  submit: true,
  variant: 'gradient' as ButtonTextVariant,
  label: 'Login',
};

export const GOOGLE_BUTTON = {
  submit: false,
  variant: 'gradient' as ButtonTextVariant,
  label: 'Continue with Google',
  className: 'mt-4 md:mt-10',
};

export const FB_BUTTON = {
  submit: false,
  variant: 'gradient' as ButtonTextVariant,
  label: 'Continue with FB',
  className: 'mt-4 md:mt-10',
};

export const SIGNUP = 'Don’t have an account?';

export const SIGNUP_BUTTON = {
  label: 'Sign up',
  size: 14,
};

export const SSO_BUTTON = {
  id: 'login-sso',
  label: 'Resend verification email',
  variant: 'turquoise' as ButtonLinkVariant,
  className: 'mx-auto mt-6 underline',
};
