import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  CopyContent,
  Text,
  useIsDesktop,
} from '@kanda-libs/ks-design-library';

import OnboardingTitle from 'components/OnboardingTitle';
import { URLS } from 'config';

import { COPIED_BUTTON_PROPS } from './constants';
import useCalculator from './useCalculator';

import IFrame from '../../UpdateYourWebsite/Calculator/IFrame';

const Calculator: FunctionComponent = function () {
  const { url } = useCalculator();
  const isDesktop = useIsDesktop();

  return (
    <div className="flex flex-col items-center justify-center text-left md:grid grid-cols-2 gap-x-4 md:min-h-100">
      <div className="h-full">
        <IFrame />
      </div>
      <div className="flex flex-col gap-y-2 max-w-120">
        <OnboardingTitle>
          Get your personalised finance calculator.
        </OnboardingTitle>
        <Text
          text="The finance calculator will show all active rates on your account and display your name. Let customers see what their repayments could be."
          className="block my-4"
        />
        <div className="flex flex-col gap-y-4 md:gap-y-6">
          <CopyContent
            content={url}
            clickedView={<Button.Link {...COPIED_BUTTON_PROPS} size={14} />}
            className="w-full"
          >
            <Button.Text
              left
              id="update-your-website-calculator-copy-code"
              label="Copy link"
              icon="copy"
              size={isDesktop ? 40 : undefined}
              className="w-full"
            />
          </CopyContent>
          <Link to={URLS.updateYourWebsite.replace(':id', 'calculator')}>
            <Button.Text
              left
              id="update-your-website-calculator-send-to-developer"
              label="Invite developer"
              icon="email"
              variant="outline"
              size={isDesktop ? 40 : undefined}
              className="w-full"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
