import { Field } from '@kanda-libs/ks-component-ts';
import { PHONE_NUMBER_PROPS, PHONE_NUMBER_VALIDATION } from './constants';
import useSignUpFormFieldPhone from './useSignUpFormFieldPhone';

const SignUpFormFieldFirstName: React.FunctionComponent = function () {
  const { onBlur } = useSignUpFormFieldPhone();
  return (
    <Field.Validator validation={PHONE_NUMBER_VALIDATION}>
      <Field.PhoneNumberInput {...PHONE_NUMBER_PROPS} onBlur={onBlur} />
    </Field.Validator>
  );
};

export default SignUpFormFieldFirstName;
