import { useContext } from 'react';
import type { TableRow } from '@kanda-libs/ks-component-ts';

import Context from '../../../Context';

export interface ContainerComponentChildrenArgs {
  jobId: string;
  status: string;
}

export interface ContainerComponentProps {
  row: TableRow;
  children: (args: ContainerComponentChildrenArgs) => void;
}

const ContainerComponent = ({ children, row }) => {
  const { downloadPdf } = useContext(Context);

  const jobId = row?.original?.id;
  const status = row?.original?.status;

  return children({
    jobId,
    status,
    downloadPdf,
  });
};

ContainerComponent.displayName = 'HoverPopover-container';

export default ContainerComponent;
