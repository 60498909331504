import { Field } from '@kanda-libs/ks-component-ts';
import useDateValidation from './useDateValidation';

const DateOfBirth: React.FunctionComponent = function () {
  const validation = useDateValidation();
  return (
    <Field.Validator validation={validation}>
      <Field.DatePickerInput
        label="Date Of Birth"
        placeholder="date of birth"
        name="guarantor.date_of_birth"
      />
    </Field.Validator>
  );
};

export default DateOfBirth;
