import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { URLS } from 'config';
import { selectors } from 'store';

import { PAGES, PAGE_KEYS } from './constants';
import type { AdvertisingPage } from './types';

export interface AdvertisingHook {
  page: AdvertisingPage;
  Content?: FunctionComponent;
}

export default function useAdvertising(): AdvertisingHook {
  const { id } = useSelector(selectors.getPathKey);
  const { push } = useHistory();

  useEffect(() => {
    if (id && !PAGE_KEYS.includes(id || '')) {
      throw new Error(`Invalid page: ${id}`);
    }

    if (!id) {
      push(URLS.advertising.replace(':id', 'social-media'));
    }
  }, [id, push]);

  const page = id as AdvertisingPage;

  const Content = PAGES[page];

  return {
    page,
    Content,
  };
}
