import { useEffect, useRef } from 'react';

interface TrustPilotEmbedHook {
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
}

export function useTrustPilotEmbed(): TrustPilotEmbedHook {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (window.Trustpilot)
      window.Trustpilot.loadFromElement(elementRef.current, true);
  }, []);

  return {
    elementRef,
  };
}
