import type { ButtonInlineLinkVariant } from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
  text: 'mb-auto mx-auto text-14-22',
  linktext: 'text-14-22-em text-green-400 ml-1',
};

export const TITLE = 'Login';

export const LINK_TEXT = 'Sign up';

export const SIGNUP_TEXT = "Don't have an account?";

export const DESCRIPTION =
  'Welcome back to Kanda! Please login\nto your account to continue';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  email: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'username',
    name: 'email',
    label: 'Email',
    placeholder: 'Your email',
  },
  password: {
    name: 'password',
    label: 'Password',
    placeholder: 'Your password',
    autoComplete: 'current-password',
  },
};

export const FORGOT_PASSWORD_BUTTON = {
  id: 'login-password-forgot-password',
  label: 'Forgot password',
  className: 'whitespace-nowrap',
  variant: 'turquoise' as ButtonInlineLinkVariant,
};
