import { BreakPoints, Layout } from '@kanda-libs/ks-design-library';
import { Redirect } from 'react-router-dom';

import { CompanyHeader, Header, Menu } from './components';
import Container from './MyAccount-container';
import { CLASS_NAMES } from './MyAccount-constants';

import { Helmet } from '../../components';

const ViewComponent = ({}) => (
  <Container>
    {({ handleLogout, redirect }) => (
      <>
        <BreakPoints
          desktop={<Redirect to={redirect} />}
          mobile={
            <Layout
              headerBg="neutral-100"
              bg="neutral-100"
              noBorder
              header={<Header handleLogout={handleLogout} />}
            >
              <Helmet title="My Account" />
              <CompanyHeader />
              <div className={CLASS_NAMES.container}>
                <div className={CLASS_NAMES.content}>
                  <Menu />
                </div>
              </div>
            </Layout>
          }
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'MyAccount-view';

export default ViewComponent;
