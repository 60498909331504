import type { FunctionComponent } from 'react';

import HomeContent from './Content';
import HomeProvider from './context';
import HomeLayout from './Layout';

const Home: FunctionComponent = function () {
  return (
    <HomeProvider>
      <HomeLayout>
        <HomeContent />
      </HomeLayout>
    </HomeProvider>
  );
};

export default Home;
