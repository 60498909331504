import Container from './Mobile-container';
import { NOTES_MODAL } from './Mobile-constants';

import { Action } from '../../../components';
import NoteModal from './NoteModal';

interface NotesMobileProps {
  note: string;
}

const ViewComponent = ({ note }: NotesMobileProps) => (
  <Container>
    {({ onClick }) => (
      <>
        <Action icon="paper" title="Notes" onClick={onClick} badge={1} />
        <NoteModal note={note} modalId={NOTES_MODAL} />
      </>
    )}
  </Container>
);
ViewComponent.displayName = 'Job-Notes-Mobile-view';

export default ViewComponent;
