import { Button, Header } from '@kanda-libs/ks-design-library';
import { Link } from 'react-router-dom';

import { URLS } from '../../../../../config/application';

const ViewComponent = () => (
  <Header.Base
    options={[
      <Link key="close" to={URLS.accountSettings}>
        <Button.Icon id="account-settings-header-close" icon="close" />
      </Link>,
    ]}
  />
);

ViewComponent.displayName = 'SubPageHeader-view';

export default ViewComponent;
