import { Button, Icon, Text } from '@kanda-libs/ks-design-library';
import { CLASS_NAMES } from './OnboardingStep-constants';
import StepNumber from './StepNumber';

interface OnboardingStepProps {
  number?: number;
  completed?: boolean;
  active?: boolean;
  title: string;
  subText: string;
  required: string;
  onClickSetUp?(...args: unknown[]): unknown;
  isLoading?: boolean;
  alternateStepText?: string;
}

const OnboardingStep = ({
  number,
  completed,
  active,
  title,
  subText,
  required,
  onClickSetUp,
  isLoading,
  alternateStepText,
}: OnboardingStepProps) => (
  <div
    className={completed ? CLASS_NAMES.wrapperCompleted : CLASS_NAMES.wrapper}
  >
    {!completed && number && (
      <StepNumber
        active={active as boolean}
        number={number}
        isLoading={isLoading}
      />
    )}
    {completed && (
      <Icon
        icon="check-circle"
        stroke="green-500"
        size={24}
        className={completed ? CLASS_NAMES.iconCompleted : CLASS_NAMES.icon}
      />
    )}
    <Text
      text={title}
      className={completed ? CLASS_NAMES.titleCompleted : CLASS_NAMES.title}
      isLoading={isLoading}
      skeletonProps={{ width: 100 }}
    />
    {!completed && (
      <Text
        text={subText}
        className={CLASS_NAMES.subText}
        isLoading={isLoading}
        skeletonProps={{ width: 160, count: 2 }}
      />
    )}
    {completed && (
      <Text
        text={alternateStepText || 'You have completed this step'}
        className={CLASS_NAMES.subTextCompleted}
        isLoading={isLoading}
        skeletonProps={{ width: 160, count: 2 }}
      />
    )}
    {!completed && (
      <Text
        text={required}
        className={CLASS_NAMES.required}
        isLoading={isLoading}
        skeletonProps={{ width: 160, count: 2 }}
      />
    )}
    {!completed && (
      <Button.Text
        id="onboarding-step-set-up"
        disabled={!active}
        label="Set Up"
        icon="arrow-right"
        size={40}
        onClick={() => {
          if (onClickSetUp) onClickSetUp();
        }}
        isLoading={isLoading}
      />
    )}
    {completed && <div className="md:mt-10" />}
  </div>
);

OnboardingStep.defaultProps = {
  number: null,
  completed: false,
  active: false,
  onClickSetUp: () => {},
  isLoading: false,
};

export default OnboardingStep;
