import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import BusinessConfig from './components/BusinessConfig';
import Header from './components/Header';
import LimitedCompanyFields from './components/LimitedCompanyFields';
import MeFields from './components/MeFields';
import SoleTraderFields from './components/SoleTraderFields';
import useCompanyForm from './useCompanyForm';

export interface SetupAccountCompanyFormProps {
  isSubmitting: boolean;
  disabled: boolean;
  schemaError: boolean;
}

const SetupAccountCompanyForm: React.FunctionComponent<SetupAccountCompanyFormProps> =
  function ({ isSubmitting, disabled, schemaError }) {
    const { companyType, showMeForm, showCompanyForm } = useCompanyForm();
    return (
      <div className="flex flex-col flex-1 md:border border-neutral-300 rounded-lg md:p-16">
        <div className="w-full flex-1 max-w-120 mx-auto">
          {showMeForm && <MeFields />}
          <Header companyType={companyType} />
          <BusinessConfig />
          {showCompanyForm ? (
            <>
              {companyType === 'limited_company' && (
                <LimitedCompanyFields schemaError={schemaError} />
              )}
              {companyType === 'sole_trader' && <SoleTraderFields />}
              <BreakPoints
                desktop={
                  <Button.Text
                    id="setup-account-submit"
                    submit
                    className="w-full mt-12"
                    label="Next"
                    icon="arrow-right"
                    variant="gradient"
                    disabled={isSubmitting || disabled}
                  />
                }
              />
            </>
          ) : null}
        </div>
      </div>
    );
  };

export default SetupAccountCompanyForm;
