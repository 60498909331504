import { Button, Header, Layout } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import type { HomeLayoutProps } from '.';

const HomeLayoutMobile: FunctionComponent<HomeLayoutProps> = function ({
  children,
}) {
  return (
    <Layout
      stickyFooter
      noBorder
      stickyHeader
      header={<Header.Main linkComponent={Link} />}
      footer={
        <div className="flex w-full">
          <Link to={URLS.jobs} className="ml-auto">
            <Button.Text
              id="home-go-to-jobs"
              label="Go To Jobs"
              icon="arrow-right"
              variant="gradient"
            />
          </Link>
        </div>
      }
    >
      <div className="z-0">{children}</div>
    </Layout>
  );
};

export default HomeLayoutMobile;
