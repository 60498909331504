/* eslint import/no-cycle: 0 */

import Container from './Provider-container';

import Context from '../context';

interface ViewComponentProps {
  /**
   * Children
   */
  children?: JSX.Element[] | JSX.Element;
}

const ViewComponent = ({ children }: ViewComponentProps) => (
  <Container>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = { children: null };

ViewComponent.displayName = 'Createjob-Quote-Kanda-Provider-view';

export default ViewComponent;
