import { Field, PasswordStrengthIndicator } from '@kanda-libs/ks-component-ts';

import Container from './Content-container';
import { CLASS_NAMES, TITLE, DESCRIPTION, FIELDS } from './Content-constants';

interface ViewComponentProps {
  /**
   * is loading state
   */
  isLoading?: boolean;
}

const ViewComponent = ({ isLoading }: ViewComponentProps) => (
  <Container>
    {({ passwordValidation, confirmPasswordValidation }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.headerWrapper}>
          <p className={CLASS_NAMES.title}>{TITLE}</p>
          <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
        </div>

        <Field.Validator validation={passwordValidation}>
          <Field.PasswordInput {...FIELDS.password} isLoading={isLoading} />
        </Field.Validator>
        <PasswordStrengthIndicator
          passwordFieldName="password"
          passwordScoreFieldName="password_strength"
          description="Your password must have a strength of at least 'Good'"
        />
        <Field.Validator validation={confirmPasswordValidation}>
          <Field.PasswordInput
            {...FIELDS.confirmPassword}
            isLoading={isLoading}
          />
        </Field.Validator>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ResetPassword-Landing-Content-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
};

export default ViewComponent;
